<template>
  <div class="d-flex h-100 align-items-center justify-content-center">
    <form class="auth-form" @submit.prevent="sendResetPasswordInstructions({ email })">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="auth-logo" />
        BRANDS
      </div>

      <input
        v-model="email"
        type="email"
        class="form-control"
        :class="sendResetPasswordInstructionsError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.email')"
        required
        autofocus
      >

      <div class="d-grid mt-3">
        <button class="btn btn-primary" type="submit" :disabled="sendResetPasswordInstructionsLoading">
          <div v-if="sendResetPasswordInstructionsLoading" class="position-absolute">
            <md-spinner md-indeterminate :diameter="24" :stroke-width="5" />
          </div>
          {{ $t('auth.passwords.newAction') }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }">
          {{ $t('auth.links.login') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import C2bLogo from '../shared/C2bLogo.vue'
import { mapGetters, mapActions } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: {
    C2bLogo,
    MdSpinner
  },
  data: function() {
    return {
      email: null
    }
  },
  computed: {
    ...mapGetters({
      sendResetPasswordInstructionsLoading: 'auth/sendResetPasswordInstructionsLoading',
      sendResetPasswordInstructionsError: 'auth/sendResetPasswordInstructionsError'
    })
  },
  methods: {
    ...mapActions({
      sendResetPasswordInstructions: 'auth/sendResetPasswordInstructions'
    })
  }
}
</script>

<style lang="scss">
@import '../../assets/auth.scss';
</style>
