<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="abTestLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="abTestError" class="alert alert-danger">
        <template v-if="abTestError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="abTest.name" type="text" class="form-control" :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.abTest.name')">
              <div v-if="abTestSubmitErrors && abTestSubmitErrors.name" class="invalid-feedback">{{ tErrors('abTest', 'name', abTestSubmitErrors.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="abTest.group"
                root-group
                :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="abTestSubmitErrors && abTestSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('abTest', 'group', abTestSubmitErrors.group) }}</div>
            </div>
          </div>

          <!-- Service -->
          <div class="form-group row">
            <label for="multiselect-service" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.service') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <multiselect
                v-model="abTest.service"
                :options="services"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :placeholder="$t('shared.placeholders.select')"
                :custom-label="service => $t(`views.abTests.services.${service}`)"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.abTest.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="abTest.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="daterange-picker" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.dateRange') }}</label>
            <div class="col-sm-10 col-12">
              <date-range-picker v-model="abTest.dateRange" :time-picker="true">
                <div slot="input" slot-scope="picker" class="btn btn-block btn-primary">
                  {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
                </div>
              </date-range-picker>
            </div>
          </div>

          <div class="form-group row">
            <label for="uniform-checkbox" class="col-sm-2">{{ $t('attributes.abTest.uniform') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="abTest.uniform" type="checkbox" class="custom-control-input" id="uniform-checkbox">
                <label class="custom-control-label" for="uniform-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="daterange-picker" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.abTestsVariants') }}</label>
            <div class="col-sm-10 col-12">
              <div class="alert alert-warning" v-if="abTest.abTestsVariants.length === 0">
                {{ $t('shared.warnings.noVariant') }}
              </div>

              <div class="alert alert-danger" v-if="abTestSubmitErrors && abTestSubmitErrors.abTestsVariants">
                {{ tErrors('abTest', 'abTestsVariants', abTestSubmitErrors.abTestsVariants) }}
              </div>

              <table class="table table-hover mb-0 table-valign-middle">
                <tr
                  v-for="abTestsVariant in abTest.abTestsVariants"
                  :key="abTestsVariant.id"
                  :class="{ 'table-danger': abTestsVariant._destroy }">
                  <td class="font-weight-semibold">{{ abTestsVariant.variant.name }}</td>
                  <td>
                    <!-- Widget customisation selection -->
                    <multiselect
                      v-if="abTestsVariant.variant.id === variantWithCustomisationId"
                      v-model="abTestsVariant.customisation"
                      :options="customisations"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      label="name"
                      track-by="id"
                      :placeholder="$t('shared.placeholders.select')"
                      :show-labels="false"
                      :loading="customisationsLoading">
                      <template v-slot:singleLabel="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                      <template v-slot:option="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                    </multiselect>

                    <!-- Multiproduct widget customisation selection -->
                    <multiselect
                      v-else-if="abTestsVariant.variant.id === variantWithMultiproductWidgetCustomisationId"
                      v-model="abTestsVariant.multiproductWidgetCustomisation"
                      :options="multiproductWidgetCustomisations"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      label="name"
                      track-by="id"
                      :placeholder="$t('shared.placeholders.select')"
                      :show-labels="false"
                      :loading="customisationsLoading">
                      <template v-slot:singleLabel="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                      <template v-slot:option="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                    </multiselect>

                    <!-- Michelin widget customisation selection -->
                    <multiselect
                      v-else-if="abTestsVariant.variant.id === variantWithMichelinWidgetCustomisationId"
                      v-model="abTestsVariant.michelinWidgetCustomisation"
                      :options="michelinWidgetCustomisations"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      label="name"
                      track-by="id"
                      :placeholder="$t('shared.placeholders.select')"
                      :show-labels="false"
                      :loading="customisationsLoading">
                      <template v-slot:singleLabel="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                      <template v-slot:option="{ option }">
                        #{{ option.id }} - {{ option.name }}
                      </template>
                    </multiselect>
                  </td>
                  <td class="td-shrink text-nowrap">
                    <template v-if="!abTestsVariant._destroy">
                      <template v-if="abTest.uniform">
                        {{ uniformPercentage | percentage }}
                      </template>
                      <template v-else>
                        <input
                          type="number"
                          v-model.number="abTestsVariant.weight"
                          class="form-control d-inline"
                          style="width: 70px !important;"
                          :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.abTestsVariants }">
                        %
                      </template>
                    </template>
                  </td>
                  <td class="td-shrink text-nowrap">
                    <template v-if="abTestsVariant.variant.id !== 1">
                      <template v-if="!abTestsVariant._destroy">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteVariant(abTestsVariant)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-sm btn-success" @click="restoreVariant(abTestsVariant)">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          {{ $t('shared.actions.restore') }}
                        </button>
                      </template>
                    </template>
                  </td>
                </tr>
              </table>

              <multiselect
                v-if="availableVariants.length > 0"
                @input="addVariant"
                :options="availableVariants"
                :custom-label="variant => variant.name"
                :clear-on-select="true"
                :close-on-select="true"
                open-direction="bottom"
                :placeholder="$t('shared.placeholders.addVariant')"
                :loading="variantsLoading">
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </multiselect>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="abTestSubmitLoading">
              <md-spinner v-if="abTestSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  components: { Multiselect, MdSpinner, GroupModalSelect, DateRangePicker },
  props: ['action'],
  data: function() {
    return {
      abTest: {
        name: null,
        active: true,
        group: null,
        dateRange: {
          startDate: moment.utc().startOf('day').add(1, 'days').toDate(),
          endDate: moment.utc().startOf('day').add(30, 'days').toDate()
        },
        service: 'widget',
        uniform: true,
        abTestsVariants: []
      },
      abTestLoading: false,
      abTestError: null,
      services: ['widget', 'multiproduct_widget', 'michelin_widget'],
      variants: [],
      variantsLoading: false,
      variantsError: null,
      customisations: [],
      multiproductWidgetCustomisations: [],
      michelinWidgetCustomisations: [],
      customisationsLoading: false,
      customisationsError: null,
      abTestSubmitLoading: false,
      abTestSubmitErrors: null,
      variantWithCustomisationId: 4, // Variant allowing customisation association
      variantWithMichelinWidgetCustomisationId: 6, // Variant allowing Michelin widget customisation association
      variantWithMultiproductWidgetCustomisationId: 7, // Variant allowing multiproduct widget customisation association
      deprecatedVariantIds: [2, 3, 5] // Variants that are no longer in use
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'auth/group',
      roles: 'auth/roles'
    }),
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    groupIsUserGroup: function() {
      return this.group.id === this.user.group.id
    },
    // Available variants
    availableVariants: function() {
      const usedVariantIds = this.abTest.abTestsVariants.map(abTestsVariant => abTestsVariant.variant.id)
      return this.variants.filter(variant => {
        if (variant.id === this.variantWithCustomisationId || variant.id === this.variantWithMultiproductWidgetCustomisationId) {
          return this.abTest.group && this.abTest.group.rootGroup.id !== 919
        } else if (variant.id === this.variantWithMichelinWidgetCustomisationId) {
          return this.abTest.group && this.abTest.group.rootGroup.id === 919
        } else {
          return !usedVariantIds.includes(variant.id) && !this.deprecatedVariantIds.includes(variant.id)
        }
      })
    },
    // Uniform percentage value
    uniformPercentage: function() {
      return 1 / this.abTest.abTestsVariants.filter(abTestsVariant => !abTestsVariant._destroy).length
    }
  },
  methods: {
    // Load data
    loadData: function({ abTest = true } = {}) {
      this.variantsLoading = true
      this.variantsError = null
      if (abTest) {
        this.abTestLoading = true
        this.abTestError = null
      }

      const query = `query abTestsForm ($abTest: Boolean = true, $abTestId: Int = null) {
        abTest(id: $abTestId) @include(if: $abTest) {
          id
          name
          active
          startDate
          endDate
          service
          group {
            id
            name
            region {
              id
              code
            }
            rootGroup {
              id
            }
          }
          uniform
          abTestsVariants {
            id
            weight
            variant {
              id
              name
            }
            customisation {
              id
              name
              group {
                id
                name
                region {
                  id
                  code
                }
              }
            }
            multiproductWidgetCustomisation {
              id
              name
              group {
                id
                name
                region {
                  id
                  code
                }
              }
            }
            michelinWidgetCustomisation {
              id
              name
              group {
                id
                name
                region {
                  id
                  code
                }
              }
            }
          }
        }
        variants {
          id
          name
        }
      }`

      const variables = {
        abTest,
        abTestId: abTest ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.variantsLoading = false
          if (abTest) this.abTestLoading = false

          if (data.errors) {

          } else {
            this.variants = Object.freeze(data.data.variants)
            if (abTest) {
              this.abTest = {
                ...data.data.abTest,
                dateRange: {
                  startDate: data.data.abTest.startDate,
                  endDate: data.data.abTest.endDate
                }
              }
            }
          }
        })
    },
    // Load AB test group customisations
    loadCustomisations: async function() {
      this.customisationsLoading = true
      this.customisationsError = null

      const query = `query abTestFormCustomisations ($groupId: Int!) {
        customisations(groupId: $groupId) {
          id
          name
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
        multiproductWidgetCustomisations(groupId: $groupId) {
          id
          name
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
        michelinWidgetCustomisations(groupId: $groupId) {
          id
          name
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
      }`

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: this.abTest.group.id
          }
        })
      })
      const json = await res.json()

      this.customisationsLoading = false

      if (json.errors) {
        this.customisationsError = { status: res.status, errors: json.errors }
      } else {
        this.customisations = Object.freeze(json.data.customisations)
        this.multiproductWidgetCustomisations = Object.freeze(json.data.multiproductWidgetCustomisations)
        this.michelinWidgetCustomisations = Object.freeze(json.data.michelinWidgetCustomisations)
      }
    },
    // Load AB test group Michelin widget customisations
    loadMichelinWidgetCustomisations: async function() {
      this.customisationsLoading = true
      this.customisationsError = null

      const query = `query abTestFormMichelinWidgetCustomisations ($groupId: Int!) {
        michelinWidgetCustomisations(groupId: $groupId) {
          id
          name
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
      }`

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: this.abTest.group.id
          }
        })
      })
      const json = await res.json()

      this.customisationsLoading = false

      if (json.errors) {
        this.customisationsError = { status: res.status, errors: json.errors }
      } else {
        this.michelinWidgetCustomisations = Object.freeze(json.data.michelinWidgetCustomisations)
      }
    },
    // Add variant
    addVariant: function(variant) {
      this.abTest.abTestsVariants.push({
        weight: 0,
        customisation: null,
        multiproductWidgetCustomisation: null,
        michelinWidgetCustomisation: null,
        variant
      })
    },
    // Delete variant
    deleteVariant: function(abTestsVariant) {
      if (abTestsVariant.id) {
        // If variant was persisted, set its _destroy to true
        this.$set(abTestsVariant, '_destroy', true)
      } else {
        // Otherwise, remove array item
        const index = this.abTest.abTestsVariants.indexOf(abTestsVariant)
        this.abTest.abTestsVariants.splice(index, 1)
      }
    },
    // Restore variant
    restoreVariant: function(abTestsVariant) {
      this.$set(abTestsVariant, '_destroy', false)
    },
    // Form submit
    submit: function() {
      this.abTestSubmitLoading = true
      this.abTestSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            name: this.abTest.name,
            active: this.abTest.active,
            groupId: this.abTest.group.id,
            startDate: this.abTest.dateRange.startDate,
            endDate: this.abTest.dateRange.endDate,
            service: this.abTest.service,
            uniform: this.abTest.uniform,
            abTestsVariantsAttributes: this.abTest.abTestsVariants.map(abTestsVariant => {
              return {
                id: abTestsVariant.id,
                weight: abTestsVariant.weight,
                variantId: abTestsVariant.variant.id,
                customisation2Id: abTestsVariant.customisation ? abTestsVariant.customisation.id : null,
                multiproductWidgetCustomisationId: abTestsVariant.multiproductWidgetCustomisation ? abTestsVariant.multiproductWidgetCustomisation.id : null,
                michelinWidgetCustomisationId: abTestsVariant.michelinWidgetCustomisation ? abTestsVariant.michelinWidgetCustomisation.id : null,
                _destroy: abTestsVariant._destroy
              }
            })
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateAbTestInput!) {
          createAbTest(input: $input) {
            abTest {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.abTestSubmitLoading = false

            if (data.data.createAbTest.errors) {
              this.abTestSubmitErrors = data.data.createAbTest.errors
            } else {
              this.redirectToIndex()
              this.$root.$bvToast.toast(this.$t('shared.success.abTest.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.abTestId = this.$route.params.id

        const mutation = `mutation($input: UpdateAbTestInput!) {
          updateAbTest(input: $input) {
            abTest {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.abTestSubmitLoading = false

            if (data.data.updateAbTest.errors) {
              this.abTestSubmitErrors = data.data.updateAbTest.errors
            } else {
              this.redirectToIndex()
              this.$root.$bvToast.toast(this.$t('shared.success.abTest.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    },
    redirectToIndex: function() {
      if (this.userIsAdmin && this.groupIsUserGroup) {
        this.$router.push({ name: 'abTests' })
      } else {
        this.$router.push({ name: 'groupAbTests', params: { groupId: this.group.id } })
      }
    }
  },
  watch: {
    // Load group customisations when group changes
    'abTest.group': function(group) {
      if (group) {
        if (this.userIsAdmin) {
          this.loadCustomisations()
        } else {
          this.loadMichelinWidgetCustomisations()
        }
      } else {
        this.customisations = []
        this.multiproductWidgetCustomisations = []
        this.michelinWidgetCustomisations = []
      }
    }
  },
  filters: {
    percentage: function(percentage) {
      return percentage.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ abTest: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-valign-middle {
  td {
    vertical-align: middle;
  }
}
</style>
