
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError && eventAggregatesError.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- First row with group selector & date range picker -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelect"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range pickers -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Empty right col -->
    <div class="flex-fill flex-basis-0 text-right">
      <button @click="exportXLSX" type="button" class="btn btn-primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </div>
  </div>

  <!-- Pie charts -->
  <div class="card mb-3">
    <div class="card-body">
      <div class="position-relative">
        <div
          v-if="groupsLoading || eventAggregatesLoading"
          class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
          style="z-index: 1;">
          <md-spinner md-indeterminate />
        </div>
        <div class="d-flex gutter">
          <div class="flex-fill flex-basis-0">
            <highcharts
              :options="chartOptions.print"
              class="chart"
              :aria-busy="eventAggregatesLoading"
              style="width: 100%; height: 300px;" />
            <div class="text-center">
              <b>{{ $t('shared.eventActions.widget.print') }}</b>
            </div>
          </div>
          <div class="flex-fill flex-basis-0">
            <highcharts
              :options="chartOptions.redirection"
              class="chart"
              :aria-busy="eventAggregatesLoading"
              style="width: 100%; height: 300px;" />
            <div class="text-center">
              <b>{{ $t('shared.eventActions.widget.redirection') }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-vertical-align-top" id="table-stats-parent-sites">
      <thead>
        <tr>
          <th class="cell-lg">{{ $t('views.stats.parentSites.parentHostname') | capitalize }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.print_button') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.print') }}</th>
          <th class="data-cell" v-if="hasStoreActivated">{{ $t('shared.eventActions.widget.store_selection') | capitalize }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.redirection') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.engagement_rate') | capitalize }}</th>
          <template v-if="userIsAdmin || userIsMichelin">
            <th class="data-cell th-shield" v-if="!international">
              {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
          <template v-if="userCanReadValidations">
            <th class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation') }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation_rate') | capitalize }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th class="data-cell th-shield" v-if="!international">
              {{ $t('shared.eventActions.widget.revenue') | capitalize }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="groupsLoading || eventAggregatesLoading">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="font-weight-semibold bg-light" v-if="tableRows.length > 1">
            <td>Total</td>
            <td class="text-right">{{ tableData.totals.print_button.count | number }}</td>
            <td class="text-right">{{ tableData.totals.print.count | number }}</td>
            <td class="text-right" v-if="hasStoreActivated">{{ tableData.totals.store_selection.count | number }}</td>
            <td class="text-right">{{ tableData.totals.redirection.count | number }}</td>
            <td class="text-right">{{ tableData.totals.redirection_rate | percentage }}</td>
            <template v-if="userIsAdmin || userIsMichelin">
              <td class="text-right" v-if="!international">{{ tableData.totals.redirection_product.amount | price(group.region) }}</td>
            </template>
            <template v-if="userCanReadValidations">
              <td class="text-right">{{ tableData.totals.validation.count | number }}</td>
              <td class="text-right">{{ tableData.totals.validation_rate | percentage }}</td>
              <td class="text-right" v-if="!international">{{ tableData.totals.validation_product.amount | price(group.region) }}</td>
            </template>
          </tr>
          <template v-for="row in tableRows">
            <tr :key="row.parentHostname" :class="{ 'bg-light': row.sourceGroup }">
              <td v-if="row.sourceGroup" class="cursor-pointer" @click="toggleSourceGroup(row.sourceGroup.id)">
                <div class="d-flex gutter-sm justify-content-between align-items-center">
                  <div>{{ row.sourceGroup.name }}</div>
                  <svg v-if="!showSourceGroups[row.sourceGroup.id]" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                  <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                </div>
              </td>
              <td v-else>
                <svg v-if="row.sourceGroupId" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                <code v-if="row.parentHostname === null">{{ String(row.parentHostname) }}</code>
                <template v-else>{{ row.parentHostname }}</template>
              </td>
              <td class="text-right">{{ row.print_button.count | number }}</td>
              <td class="text-right">{{ row.print.count | number }}</td>
              <td class="text-right" v-if="hasStoreActivated">{{ row.store_selection.count | number }}</td>
              <td class="text-right">{{ row.redirection.count | number }}</td>
              <td class="text-right">{{ row.redirection_rate | percentage }}</td>
              <template v-if="userIsAdmin || userIsMichelin">
                <td class="text-right" v-if="!international">{{ row.redirection_product.amount | price(group.region) }}</td>
              </template>
              <template v-if="userCanReadValidations">
                <td class="text-right">{{ row.validation.count | number }}</td>
                <td class="text-right">{{ row.validation_rate | percentage }}</td>
                <td class="text-right" v-if="!international">{{ row.validation_product.amount | price(group.region) }}</td>
              </template>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Source groups list -->
  <template v-if="userIsAdmin">
    <div class="d-flex align-items-end justify-content-between mb-3">
      <h4 class="mb-0">
        {{ $t('views.stats.parentSites.sourceGroups') }}
        <sup class="text-warning">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
        </sup>
      </h4>
      <button type="button" class="btn btn-success" @click="newSourceGroup()">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newSourceGroup') }}
      </button>
    </div>

    <template v-if="sourceGroups.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ $t('attributes.sourceGroup.name') }}</th>
            <th>{{ $t('attributes.sourceGroup.sources') }}</th>
            <th class="shrink"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sourceGroup in sourceGroups" :key="sourceGroup.id">
            <td>{{ sourceGroup.name }}</td>
            <td>
              <template v-if="sourceGroup.sources">
                <div class="d-flex flex-wrap gutter-xs">
                  <span v-for="source in sourceGroup.sources" :key="source" class="badge badge-primary">{{ source }}</span>
                </div>
              </template>
            </td>
            <td class="text-nowrap">
              <div class="gutter-x-xs">
                <button type="button" class="btn btn-sm btn-warning" @click="editSourceGroup(sourceGroup)">
                  {{ $t('shared.actions.edit') }}
                </button>
                <button type="button" @click="deleteSourceGroup(sourceGroup)" class="btn btn-sm btn-danger">
                  {{ $t('shared.actions.delete') }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="alert alert-warning">
        {{ $t('shared.warnings.noSourceGroup') }}
      </div>
    </template>
  </template>

  <!-- Source group modal (form) -->
  <b-modal
    id="sourceGroupModal"
    ref="sourceGroupModal"
    size="lg"
    :title="$t(`shared.actions.${sourceGroupModalAction}SourceGroup`)"
    @ok="sourceGroupModalOk"
    @shown="$refs.sourceGroupModalNameInput.focus()"
    no-fade>
    <form @submit.stop.prevent="sourceGroupModalSubmit()">
      <div class="form-group row">
        <label for="name-input" class="col-sm-2 col-form-label">{{ $t('attributes.sourceGroup.name') }}</label>
        <div class="col-lg-6 col-sm-8 col-12">
          <input
            v-model="sourceGroupModal.name"
            type="text"
            class="form-control"
            :class="{ 'is-invalid' : sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.mail }"
            id="name-input"
            :placeholder="$t('attributes.sourceGroup.name')"
            ref="sourceGroupModalNameInput">
          <div v-if="sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.name" class="invalid-feedback">{{ tErrors('sourceGroup', 'name', sourceGroupModalSubmitErrors.name) }}</div>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label for="sources-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.sourceGroup.sources') }}</label>
        <div class="col-lg-6 col-sm-8 col-12">
          <multiselect
            :multiple="true"
            v-model="sourceGroupModal.sources"
            :options="parentHostnamesWithoutSourceGroup"
            :close-on-select="false"
            open-direction="bottom"
            :allow-empty="false"
            :placeholder="$t('shared.placeholders.select')">
          </multiselect>
          <div v-if="sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.name" class="invalid-feedback">{{ tErrors('sourceGroup', 'name', sourceGroupModalSubmitErrors.name) }}</div>
        </div>
      </div>
    </form>
  </b-modal>
</div>
