
<multiselect
  v-model="customisation"
  :options="customisations"
  :multiple="false"
  :close-on-select="true"
  :clear-on-select="true"
  label="name"
  track-by="id"
  :placeholder="$t('shared.placeholders.select')"
  :show-labels="false"
  :loading="customisationsLoading"
  id="multiselect-customisations"
>
  <template v-slot:singleLabel="{ option }">
    {{ option.name }}
  </template>
  <template v-slot:option="{ option }">
    {{ option.name }}
  </template>
</multiselect>
