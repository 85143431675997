
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError && eventAggregatesError.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- First row with group selector & date range picker -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelect"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range picker -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Empty right col -->
    <div class="flex-fill flex-basis-0 text-right"></div>
  </div>

  <!-- Pie charts -->
  <div class="card mb-3">
    <div class="card-body">
      <div class="position-relative">
        <div
          v-if="groupsLoading || eventAggregatesLoading"
          class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
          style="z-index: 1;">
          <md-spinner md-indeterminate />
        </div>
        <div class="d-flex gutter" style="min-height: 240px;">
          <div v-for="(pieChart, index) in pieChartList" :key="index" class="flex-fill flex-basis-0">
            <highcharts
              :options="chartOptions[index]"
              class="chart"
              :aria-busy="eventAggregatesLoading"
              style="width: 100%; height: 200px;" />
            <div class="text-center">
              <b>{{ pieChart.label }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Export button -->
  <div class="mb-3 text-right">
    <span class="d-inline-block" v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
      <button @click="exportXLSX" type="button" variant="primary" class="btn btn-primary"  :disabled="demoMode">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </span>
  </div>

  <!-- Table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-vertical-align-top" id="table-stats-devices">
      <thead>
        <tr>
          <th class="cell-md">{{ $t('views.stats.devices.groupsAndDevices') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.print_button') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.print') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.print_rate') | capitalize }}</th>
          <th class="data-cell" v-if="hasStoreActivated">{{ $t('shared.eventActions.widget.store_selection') | capitalize }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.redirection') }}</th>
          <th class="data-cell">{{ $t('shared.eventActions.widget.redirection_rate') | capitalize }}</th>
          <template v-if="userCanReadValidations">
            <th class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation') }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation_rate') | capitalize }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
          <!--<th class="data-cell th-shield" v-if="userIsAdmin || userIsMichelin">
            {{ $t('shared.eventActions.widget.avg_prices') | capitalize }}
            <div v-if="userIsAdmin" class="shield-wrapper text-warning">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
            </div>
          </th>
          <th class="data-cell th-shield" v-if="userIsAdmin || userIsMichelin">
            {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize }}
            <div v-if="userIsAdmin" class="shield-wrapper text-warning">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
            </div>
          </th>-->
        </tr>
      </thead>
      <tbody>
        <template v-if="groupsLoading || eventAggregatesLoading">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </template>
        <template v-else v-for="group in displayedGroups">
          <tr class="font-weight-semibold bg-light" :key="group.id">
            <td @click="toggleExpandedGroup(group.id)" class="cursor-pointer">
              <div class="d-flex gutter-sm justify-content-between align-items-center">
                <div v-if="group.depth > 0">
                  <template v-for="n in group.depth">
                    <svg :key="n" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                  </template>
                </div>

                <div class="flex-fill">
                  <region-flag v-if="international" :code="group.region.code" />
                  {{ group.name }}
                </div>

                <template v-if="!expandedGroup(group.id)">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                </template>
                <template v-else>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                </template>
              </div>
            </td>
            <td class="text-right">{{ groupActionCount(group.id, 'print_button') | number }}</td>
            <td class="text-right">{{ groupActionCount(group.id, 'print') | number }}</td>
            <td class="text-right">{{ groupActionRate(group.id, 'print', 'print_button') | percentage }}</td>
            <td class="text-right" v-if="hasStoreActivated">{{ groupActionCount(group.id, 'store_selection') | number }}</td>
            <td class="text-right">{{ groupActionCount(group.id, 'redirection') | number }}</td>
            <td class="text-right">{{ groupActionRate(group.id, 'redirection', 'print') | percentage }}</td>
            <template v-if="userCanReadValidations">
              <td class="text-right">{{ groupActionCount(group.id, 'validation') | number }}</td>
              <td class="text-right">{{ groupActionRate(group.id, 'validation', 'redirection') | percentage }}</td>
            </template>
            <!--<td class="text-right" v-if="userIsAdmin || userIsMichelin">{{ groupWeightedAveragePrice(group.id) | price(group.region) }}</td>
            <td class="text-right" v-if="userIsAdmin || userIsMichelin">{{ groupEngagedRevenue(group.id) | price(group.region) }}</td>-->
          </tr>
          <template v-if="expandedGroup(group.id)">
            <tr v-for="device in devices" v-bind:key="`${group.id}-${device.key}`">
              <td>
                <span :style="{ color: device.color }">
                  <svg v-if="device.key === 'desktop'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-fw"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
                  <svg v-else-if="device.key === 'mobile'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-mobile-alt fa-fw"><path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
                  <svg v-else-if="device.key === 'tablet'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tablet-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-tablet-alt fa-fw"><path fill="currentColor" d="M400 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM224 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm176-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h328c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
                  <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-fw"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                </span>
                {{ device.key | capitalize }}
              </td>
              <td class="text-right">{{ groupDeviceActionCount(group.id, device.key, 'print_button') | number }}</td>
              <td class="text-right">{{ groupDeviceActionCount(group.id, device.key, 'print') | number }}</td>
              <td class="text-right">{{ groupDeviceActionRate(group.id, device.key, 'print', 'print_button') | percentage }}</td>
              <td class="text-right" v-if="hasStoreActivated">{{ groupDeviceActionCount(group.id, device.key, 'store_selection') | number }}</td>
              <td class="text-right">{{ groupDeviceActionCount(group.id, device.key, 'redirection') | number }}</td>
              <td class="text-right">{{ groupDeviceActionRate(group.id, device.key, 'redirection', 'print') | percentage }}</td>
              <template v-if="userCanReadValidations">
                <td class="text-right">{{ groupDeviceActionCount(group.id, device.key, 'validation') | number }}</td>
                <td class="text-right">{{ groupDeviceActionRate(group.id, device.key, 'validation', 'redirection') | percentage }}</td>
              </template>
              <!--<td class="text-right" v-if="userIsAdmin || userIsMichelin">{{ groupDeviceWeightedAveragePrice(group.id, device.key) | price(group.region) }}</td>
              <td class="text-right" v-if="userIsAdmin || userIsMichelin">{{ groupDeviceEngagedRevenue(group.id, device.key) | price(group.region) }}</td>-->
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</div>
