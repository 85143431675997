<template>
  <div class="position-relative">
    <!-- Modal for users without role -->
    <div
      v-if="!userCanReadProductDistributionStats"
      class="modal d-block position-absolute"
      style="z-index: 1;"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-flag fa-fw" style="height: 1em;"><path fill="currentColor" d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"/></svg>
              {{ $t('views.stats.distribution.restrictionModal.title') }}
            </h5>
          </div>
          <div class="modal-body">
            {{ $t('views.stats.distribution.restrictionModal.body') }}
          </div>
        </div>
      </div>
    </div>

    <div :class="{ blurred: !userCanReadProductDistributionStats }">
      <!-- Alert for unauthorized users -->
      <div
        v-if="productsError && productsError.status === 403"
        class="alert alert-danger"
      >
        {{ $t('errors.unauthorized.manage.all') }}
      </div>

      <!-- Groups/retailers selectors -->
      <div class="custom-grid mb-3">
        <!-- Name -->
        <div>
          <label for="filter-name">{{ $t('views.stats.distribution.productName') }}</label>
          <input
            v-model="filter"
            type="text"
            class="form-control"
            id="filter-name"
            :placeholder="$t('attributes.productLanguageData.name')">
        </div>

        <!-- Active -->
        <div>
          <label for="filter-active">{{ $t('views.stats.distribution.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <!-- Groups -->
        <div>
          <label for="filter-group">{{ $t('views.stats.distribution.group') }}</label>
          <!-- todo:class="custom-select" -->
          <treeselect
            class="custom-treeselect"
            :limit-text="groupsTreeselectLimitText"
            :limit="0"
            :multiple="true"
            :options="groupsTreeSelectOptions"
            :searchable="false"
            style="display: inline-block; max-width: 275px;"
            v-model="selectedGroupIds"
            value-consists-of="ALL_WITH_INDETERMINATE"
            :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
            <template slot="option-label" slot-scope="{ node }">
              <region-flag v-if="international" :code="node.label.regionCode" />
              {{ node.label.name }}
            </template>
          </treeselect>
        </div>

        <!-- Retailers -->
        <div>
          <label for="filter-group">{{ $t('views.stats.distribution.retailer') }}</label>
          <treeselect
            class="custom-treeselect"
            :limit-text="retailersTreeselectLimitText"
            :limit="0"
            :multiple="true"
            :options="retailersTreeselectOptions"
            :searchable="false"
            style="display: inline-block; max-width: 275px;"
            v-model="selectedRetailerIds"
            value-consists-of="LEAF_PRIORITY"
            :placeholder="productsLoading || retailersLoading ? 'Loading...' : 'Select...'">
            <template v-slot:option-label="{ node }">
              <template v-if="node.isBranch">
                {{ $t(`shared.retailerDistributions.${node.label}`) }}
              </template>
              <template v-else>
                <region-flag v-if="international" :code="node.label.region.code" />
                <img :src="node.label.img_small_url" style="margin-left: 5px; max-height: 20px;">
                {{ node.label.name }}
                <sup v-if="node.label.groupException" class="text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </sup>
              </template>
            </template>
          </treeselect>
        </div>
      </div>

      <!-- Columns/export buttons -->
      <div class="mb-3 d-flex justify-content-between align-items-center gutter">
        <button type="button" class="btn btn-primary" v-b-modal.columns-modal>
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM160 448H48c-8.837 0-16-7.163-16-16v-80h128v96zm0-128H32v-96h128v96zm0-128H32V96h128v96zm160 256H192v-96h128v96zm0-128H192v-96h128v96zm0-128H192V96h128v96zm160 160v80c0 8.837-7.163 16-16 16H352v-96h128zm0-32H352v-96h128v96zm0-128H352V96h128v96z" class=""></path></svg>
          {{ $t('shared.actions.manageColumns') }}
        </button>
        <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
          <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
            {{ $t('shared.actions.xlsxExport') }}
          </button>
        </span>
      </div>

      <!-- Columns modal -->
      <b-modal
        id="columns-modal"
        size="sm"
        :title="$t('shared.actions.manageColumns')"
        hide-footer
        scrollable
        no-fade>
        <template v-for="(value, column) in columnSettings">
          <div class="form-group" :key="column">
            <div class="custom-control custom-checkbox">
              <input
                v-model="columnSettings[column]"
                type="checkbox" class="custom-control-input" :id="`column-${column}`">
              <label class="custom-control-label" :for="`column-${column}`">
                <template v-if="column === 'rawPackaging'">
                  {{ $t(`attributes.productLanguageData.${column}`) }}
                </template>
                <template v-else-if="['reference', 'ean', 'upc'].includes(column)">
                  {{ $t(`attributes.product.${column}`) }}
                </template>
              </label>
            </div>
          </div>
        </template>
      </b-modal>

      <!-- Table -->
      <div class="table-responsive mb-3">
        <table class="table table-distribution table-hover table-bordered">
          <thead>
            <tr>
              <th @click="setSort('id')" :aria-sort="ariaSort('id')" rowspan="2" class="text-center">{{ $t('views.stats.distribution.id') }}</th>
              <th rowspan="2" class="text-center">{{ $t('views.stats.distribution.active') }}</th>
              <th @click="setSort('groupName')" :aria-sort="ariaSort('groupName')" rowspan="2">{{ $t('views.stats.distribution.group') }}</th>
              <th @click="setSort('name')" :aria-sort="ariaSort('name')" rowspan="2">{{ $t('views.stats.distribution.productName') }}</th>
              <th v-if="columnSettings.rawPackaging" @click="setSort('rawPackaging')" :aria-sort="ariaSort('rawPackaging')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.rawPackaging') }}</th>
              <th v-if="columnSettings.reference" @click="setSort('reference')" :aria-sort="ariaSort('reference')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.reference') }}</th>
              <th v-if="columnSettings.ean" @click="setSort('ean')" :aria-sort="ariaSort('ean')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.ean') }}</th>
              <th v-if="columnSettings.upc" @click="setSort('upc')" :aria-sort="ariaSort('upc')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.upc') }}</th>
              <th
                v-for="(retailers, service, serviceIndex) in selectedRetailersByService"
                :key="service"
                :colspan="retailers.length"
                class="text-nowrap"
                :class="{ 'border-right-thick': serviceIndex < selectedServices.length - 1 }">
                {{ $t(`shared.retailerDistributions.${service}`) }}
              </th>
            </tr>
            <tr>
              <template v-for="(retailers, service, serviceIndex) in selectedRetailersByService">
                <th
                  v-for="(retailer, retailerIndex) in retailers"
                  :key="retailer.id"
                  @click="setSort(`retailer-${retailer.id}`)"
                  :aria-sort="ariaSort(`retailer-${retailer.id}`)"
                  class="text-center text-nowrap"
                  :class="{ 'border-right-thick': retailerIndex === retailers.length - 1 && serviceIndex < selectedServices.length - 1 }"
                  :title="`${retailer.name} (${$t(`shared.retailerDistributions.${retailer.service}`)})`"
                  v-b-tooltip.hover.top.viewport="retailer.name">
                  <region-flag v-if="international" :code="retailer.region.code" class="th-flag" />
                  <img :src="retailer.imgSmallUrl" style="height: 20px;">
                  <sup v-if="retailer.groupException" class="text-warning">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                  </sup>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-if="groupsErrors || (productsError && productsError.status === 500) || retailersError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                {{ $t('errors.internalServerError') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="groupsLoading || productsLoading || retailersLoading">
            <tr>
              <td :colspan="colspan" class="text-center">
                <md-spinner md-indeterminate />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="totalRows === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noProduct') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="product in productsPage" :key="product.id">
              <td class="text-center">{{ product.id }}</td>
              <td class="ellipsis text-center"><dot :active="product.active" /></td>
              <td class="ellipsis">
                <region-flag v-if="international" :code="product.group.region.code" />
                {{ product.group.name }}
              </td>
              <td class="ellipsis">{{ product.product_language_datas[0].name }}</td>
              <td v-if="columnSettings.rawPackaging" class="ellipsis">{{ product.product_language_datas[0].raw_packaging || '–' }}</td>
              <td v-if="columnSettings.reference" class="ellipsis">{{ product.reference || '–' }}</td>
              <td v-if="columnSettings.ean" class="ellipsis">{{ product.ean || '–' }}</td>
              <td v-if="columnSettings.upc" class="ellipsis">{{ product.upc || '–' }}</td>
              <template v-for="(retailers, service, serviceIndex) in selectedRetailersByService">
                <td
                  v-for="(retailer, retailerIndex) in retailers"
                  :key="retailer.id"
                  class="text-right"
                  :class="{ 'border-right-thick': retailerIndex === retailers.length - 1 && serviceIndex < selectedServices.length - 1 }">
                  {{ product.productRetailersMap[retailer.id] ? product.productRetailersMap[retailer.id].count : 0 | number }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="d-flex flex-wrap justify-content-between align-items-center gutter">
        <div class="overflow-auto">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div>
          {{ pageEntriesInfo }}
        </div>
      </div>

      <!-- Export date -->
      <div class="text-secondary" v-if="lastExportDate">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" class=""></path></svg>
        {{ $t('views.stats.distribution.dnGeneratedAt') }}
        <span :title="lastExportDate.toISOString()">
          {{ lastExportDateFormatted }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dot from '../shared/Dot.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import Treeselect from '@riophae/vue-treeselect'
import { BPagination, VBTooltip, BModal, VBModal } from 'bootstrap-vue'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import * as XLSX from 'xlsx'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { Dot, MdSpinner, RegionFlag, Treeselect, BPagination, BModal },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal
  },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsErrors: null,
      products: [],
      productsLoading: false,
      productsError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      lastSuccessfulMongoExportLog: null,
      lastSuccessfulMongoExportLogLoading: false,
      lastSuccessfulMongoExportLogErrors: null,
      selectedGroupIds: [],
      selectedRetailerIds: [],
      filter: '',
      filters: {
        active: null
      },
      sortKey: null,
      sortDirection: null,
      perPage: 15,
      currentPage: 1,
      columnSettings: {
        rawPackaging: true,
        reference: false,
        ean: false,
        upc: false
      }
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userIsProductDistributionReader: function() {
      return this.roles.includes('product_distribution_reader')
    },
    demoMode: function() {
      return this.$route.query.demo === 'true'
    },
    userCanReadProductDistributionStats: function() {
      return this.userIsAdmin || this.userIsProductDistributionReader
    },
    // Group
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Table colspan
    colspan: function() {
      let colspan = 4

      if (this.columnSettings.rawPackaging) colspan++
      if (this.columnSettings.reference) colspan++
      if (this.columnSettings.ean) colspan++
      if (this.columnSettings.upc) colspan++

      return colspan
    },
    // Group helpers
    groupIds: function() {
      return this.groups.map(group => group.id)
    },
    groupsTree: function() {
      let groupsTree = {}

      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })[0]
      }

      return groupsTree
    },
    // Format group tree for vue treeselect component
    groupsTreeSelectOptions: function() {
      let groupsTreeSelectOptions = []

      if (this.groups.length > 0) {
        groupsTreeSelectOptions = arrayToTree(this.groups.map(group => {
          return {
            id: group.id,
            label: {
              name: group.name,
              regionCode: group.region.code
            },
            parent_id: group.parentId
          }
        }))
      }

      return groupsTreeSelectOptions
    },
    // Set to get selected group ids
    selectedGroupIdsSet: function() {
      return new Set(this.selectedGroupIds)
    },
    // Selected productIds
    selectedProductIds: function() {
      return this.products.filter(product => this.selectedGroupIdsSet.has(product.group.id)).map(product => product.id)
    },
    // Set to get selected product ids
    selectedProductIdsSet: function() {
      return new Set(this.selectedProductIds)
    },
    // Table data filtered
    productsFilteredAndSorted: function() {
      let productsFilteredAndSorted = cloneDeep(this.products)

      // Apply groups filter
      productsFilteredAndSorted = productsFilteredAndSorted.filter(product => this.selectedProductIdsSet.has(product.id))

      // Apply text input filter
      if (this.filter !== '') {
        productsFilteredAndSorted = productsFilteredAndSorted.filter(product => {
          return product.product_language_datas[0].name.search(new RegExp(this.filter, 'i')) !== -1 ||
            (product.product_language_datas[0].raw_packaging ? product.product_language_datas[0].raw_packaging.search(new RegExp(this.filter, 'i')) !== -1 : false) ||
            (product.ean ? product.ean.search(new RegExp(this.filter, 'i')) !== -1 : false)
        })
      }

      // Apply active/inactive filter
      if (this.filters.active !== null) {
        productsFilteredAndSorted = productsFilteredAndSorted.filter(product => {
          return product.active === this.filters.active
        })
      }

      // Add retailers map
      productsFilteredAndSorted.forEach(product => {
        product.productRetailersMap = {}
        product.productRetailers.forEach(productRetailer => {
          product.productRetailersMap[productRetailer.retailerId] = productRetailer
        })
      })

      // Sort
      if (this.sortKey) {
        const sortDirectionFactor = this.sortDirection === 'asc' ? 1 : -1
        productsFilteredAndSorted.sort((a, b) => {
          switch (this.sortKey) {
            case 'groupName':
              return a.group.name.localeCompare(b.group.name) * sortDirectionFactor
            case 'name':
              return a.product_language_datas[0].name.localeCompare(b.product_language_datas[0].name) * sortDirectionFactor
            case 'rawPackaging':
              if (!a.product_language_datas[0].raw_packaging) return sortDirectionFactor
              else if (!b.product_language_datas[0].raw_packaging) return -sortDirectionFactor
              else return a.product_language_datas[0].raw_packaging.localeCompare(b.product_language_datas[0].raw_packaging) * sortDirectionFactor
            case 'id':
              return (a[this.sortKey] - b[this.sortKey]) * sortDirectionFactor
            default: { // retailer-${retailerId}
              const retailerId = parseInt(this.sortKey.match(/\d+$/)[0])
              const accessor = (item, id) => item.productRetailersMap[id] ? item.productRetailersMap[id].count : 0
              return (accessor(a, retailerId) - accessor(b, retailerId)) * sortDirectionFactor
            }
          }
        })
      }

      return productsFilteredAndSorted
    },
    // Table data filtered current page
    productsPage: function() {
      const from = (this.currentPage - 1) * this.perPage
      const to = Math.min(this.currentPage * this.perPage, this.totalRows)
      return this.productsFilteredAndSorted.slice(from, to)
    },
    // Row count
    totalRows: function() {
      return this.productsFilteredAndSorted.length
    },
    // Retailer ids with data for the selected products
    retailerIdsWithData: function() {
      const retailerIdsWithData = new Set()

      this.productsFilteredAndSorted.forEach(product => {
        product.productRetailers.forEach(productRetailer => {
          retailerIdsWithData.add(productRetailer.retailerId)
        })
      })

      return retailerIdsWithData
    },
    // Retailer options Array
    retailerOptions: function() {
      const groupRetailerExceptionIds = this.group ? this.group.allRetailerExceptions.map(retailer => retailer.id) : []

      let retailerOptions = this.retailers
        .filter(retailer => this.retailerIdsWithData.has(retailer.id))
        .map(retailer => ({
          ...retailer,
          groupException: groupRetailerExceptionIds.includes(retailer.id)
        }))

      // Remove group exceptions for non-admin users
      if (!this.userIsAdmin || (this.userIsAdmin && this.demoMode)) {
        retailerOptions = retailerOptions.filter(retailer => !retailer.groupException)
      }

      return retailerOptions
    },
    // Retailer distribution methods
    distributionMethods: function() {
      const distributionMethodsSet = new Set()
      this.retailers.forEach(retailer => {
        distributionMethodsSet.add(retailer.service)
      })
      return [...distributionMethodsSet]
    },
    // Retailers with data, grouped by distributionMethod, sorted by name for treeselect
    retailersTreeselectOptions: function() {
      const retailersTreeselectOptions = []

      this.distributionMethods.forEach((distributionMethod, index) => {
        const retailers = this.retailerOptions
          .filter(retailer => retailer.service === distributionMethod)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(retailer => {
            return {
              id: retailer.id,
              label: retailer
            }
          })

        if (retailers.length) {
          retailersTreeselectOptions.push({
            id: `dm-${index}`,
            label: distributionMethod,
            children: retailers
          })
        }
      })

      return retailersTreeselectOptions
    },
    // Selected retailer ids as Set
    selectedRetailerIdsSet: function() {
      return new Set(this.selectedRetailerIds)
    },
    // Selected retailers as Array
    selectedRetailers: function() {
      return this.retailerOptions.filter(retailer => this.selectedRetailerIdsSet.has(retailer.id))
    },
    // Selected retailers by service
    selectedRetailersByService: function() {
      const selectedRetailersByService = {}

      // Extract retailers by service
      this.selectedRetailers.forEach(retailer => {
        selectedRetailersByService[retailer.service] = selectedRetailersByService[retailer.service] || []
        selectedRetailersByService[retailer.service].push(retailer)
      })

      // Sort retailers
      for (const service in selectedRetailersByService) {
        selectedRetailersByService[service].sort((a, b) => a.name.localeCompare(b.name))
      }

      // Sort services
      const servicesOrdered = ['drive', 'delivery', 'store']
      const selectedRetailersByServiceSorted = {}
      Object.keys(selectedRetailersByService).sort((a, b) => {
        return servicesOrdered.indexOf(a) - servicesOrdered.indexOf(b)
      }).forEach(key => {
        selectedRetailersByServiceSorted[key] = selectedRetailersByService[key]
      })

      return selectedRetailersByServiceSorted
    },
    // Retailer services selected
    selectedServices: function() {
      return Object.keys(this.selectedRetailersByService)
    },
    // Pagination infos
    pageEntriesInfo: function() {
      const from = (this.currentPage - 1) * this.perPage + 1
      const to = Math.min(this.currentPage * this.perPage, this.totalRows)
      return this.$t('pagination.pageEntriesInfo.multiPage', { from: from, to: to, count: this.totalRows })
    },
    // Last export date
    lastExportDate: function() {
      return this.lastSuccessfulMongoExportLog ? new Date(this.lastSuccessfulMongoExportLog.dnGeneratedAt) : null
    },
    // Last export date formatted
    lastExportDateFormatted: function() {
      return this.lastExportDate ? this.lastExportDate.toLocaleString() : null
    }
  },
  methods: {
    // Treeselect text limit formatter
    groupsTreeselectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    retailersTreeselectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.retailers', count)
    },
    // Export table as XLSX
    exportXLSX: function() {
      const date = new Date().toISOString().split('T')[0]
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_distribution_${date}.xlsx`

      // Build list of retailers (with same order as on the table)
      const retailers = []
      for (const service in this.selectedRetailersByService) {
        this.selectedRetailersByService[service].forEach(retailer => {
          retailers.push(retailer)
        })
      }

      // Build headers
      const headerKeys = ['id', 'active', 'group', 'region', 'name']
      if (this.columnSettings.rawPackaging) headerKeys.push('raw_packaging')
      if (this.columnSettings.reference) headerKeys.push('reference')
      if (this.columnSettings.ean) headerKeys.push('ean')
      if (this.columnSettings.upc) headerKeys.push('upc')

      retailers.forEach(retailer => {
        headerKeys.push(`retailer-${retailer.id}`)
      })

      // Build json data
      const jsonData = this.productsFilteredAndSorted.map(product => {
        const rowData = {
          id: product.id,
          active: product.active,
          group: product.group.name,
          region: product.group.region.code,
          name: product.product_language_datas[0].name
        }

        if (this.columnSettings.rawPackaging) rowData.raw_packaging = product.product_language_datas[0].raw_packaging
        if (this.columnSettings.ean) rowData.ean = product.ean
        if (this.columnSettings.upc) rowData.upc = product.upc
        if (this.columnSettings.reference) rowData.reference = product.reference

        retailers.forEach(retailer => {
          rowData[`retailer-${retailer.id}`] = product.productRetailersMap[retailer.id] ? product.productRetailersMap[retailer.id].count : 0
        })

        return rowData
      })

      // Build xlsx
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(jsonData, { header: headerKeys })

      // Format headers
      const headerTranslations = {
        id: this.$t('views.stats.distribution.id'),
        active: this.$t('attributes.product.active'),
        group: this.$t('views.stats.distribution.group'),
        region: this.$t('attributes.group.region'),
        name: this.$t('views.stats.distribution.productName'),
        raw_packaging: this.$t('attributes.productLanguageData.rawPackaging'),
        reference: this.$t('attributes.product.reference'),
        ean: this.$t('attributes.product.ean'),
        upc: this.$t('attributes.product.upc')
      }

      retailers.forEach((retailer, index) => {
        let retailerHeader = `${retailer.name} (${retailer.service})`
        if (this.international) {
          retailerHeader += ` (${retailer.region.code})`
        }
        headerTranslations[`retailer-${retailer.id}`] = retailerHeader
      })

      const range = XLSX.utils.decode_range(ws['!ref'])
      for (let C = range.s.c; C <= range.e.c; ++C) {
        ws[XLSX.utils.encode_col(C) + '1'].v = headerTranslations[ws[XLSX.utils.encode_col(C) + '1'].v]
      }

      XLSX.utils.book_append_sheet(wb, ws)
      XLSX.writeFile(wb, filename)
    },
    // Load data
    loadData: async function() {
      this.groupsLoading = true
      this.groupsErrors = null
      this.lastSuccessfulMongoExportLogLoading = false
      this.lastSuccessfulMongoExportLogErrors = null

      const query = `query statsDistributionGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
          allRetailerExceptions {
            id
          }
        }
        lastSuccessfulMongoExportLog {
          dnGeneratedAt
          dnSnapshotAt
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: { id: parseInt(this.$route.params.groupId) }
          })
        })

        const json = await res.json()
        if (json.errors) {
          this.groupsErrors = json.errors
          this.lastSuccessfulMongoExportLogErrors = json.errors
        } else {
          if (this.demoMode) {
            this.groups = Object.freeze(json.data.groups.map((group, index) => {
              group.name = `group-${index + 1}`

              return group
            }))
          } else {
            this.groups = Object.freeze(json.data.groups)
          }

          this.lastSuccessfulMongoExportLog = json.data.lastSuccessfulMongoExportLog

          // Init group treeselect value
          this.selectedGroupIds = this.groups.map(group => group.id)
        }
      } catch (e) {
        this.groupsErrors = [e]
        this.lastSuccessfulMongoExportLogErrors = [e]
        throw e
      } finally {
        this.groupsLoading = false
        this.lastSuccessfulMongoExportLogLoading = true
      }
    },
    // Load products with retailers distribution
    loadProducts: async function() {
      this.productsLoading = true
      this.productsError = null

      const body = {
        group_id: this.$route.params.groupId,
        date: this.lastSuccessfulMongoExportLog.dnSnapshotAt
      }

      try {
        const res = await fetch('/api/interface/stats/distribution', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const data = await res.json()

          // Merge product distribution on products
          const productRetailersMap = new Map(data.product_retailers.map(productRetailer => [productRetailer.id, productRetailer.retailers || []]))
          const products = data.products.map(product => {
            const productRetailers = productRetailersMap.get(product.id) || []
            return {
              ...product,
              productRetailers: productRetailers.map(productRetailer => ({ retailerId: productRetailer.id, count: productRetailer.count }))
            }
          })

          if (this.demoMode) {
            this.products = Object.freeze(products.map((product, index) => {
              const detectedGroup = this.groups.find(group => group.id === product.group.id)
              product.group.name = detectedGroup ? detectedGroup.name : 'group'
              product.reference = `reference-${index + 1}`
              product.ean = `ean-${index + 1}`
              product.upc = `upc-${index + 1}`
              product.product_language_datas[0].name = `name-${index + 1}`
              product.product_language_datas[0].raw_packaging = `raw-packaging-${index + 1}`

              return product
            }))
          } else {
            this.products = Object.freeze(products)
          }

          // Extract retailer ids from products product_retailers
          const retailerIdsSet = new Set()
          products.forEach(product => {
            product.productRetailers.forEach(productRetailer => {
              retailerIdsSet.add(productRetailer.retailerId)
            })
          })
          const retailerIds = Array.from(retailerIdsSet)
          await this.loadRetailers(retailerIds)
        }
      } catch (error) {
        this.productsError = error
      } finally {
        this.productsLoading = false
      }
    },
    // Load retailers
    loadRetailers: async function(retailerIds) {
      this.retailersLoading = true
      this.retailersError = null

      const query = gql`
        query statsDistributionRetailers ($ids: [Int!]) {
          retailers(ids: $ids)  {
            id
            name
            service
            imgSmallUrl
            region {
              id
              code
            }
          }
        }
      `

      const variables = {
        ids: retailerIds
      }

      try {
        const { data } = await client.query({ query, variables })

        this.retailers = Object.freeze(data.retailers)
        this.selectedRetailerIds = this.retailers.map(retailer => retailer.id)
      } catch (error) {
        this.retailersError = error
      } finally {
        this.retailersLoading = false
      }
    },
    // Set sort function for table th click
    setSort: function(key) {
      if (this.sortKey !== key) {
        this.sortKey = key
        this.sortDirection = 'asc'
      } else if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    // aria-sort value for table th
    ariaSort: function(key) {
      if (this.sortKey !== key) {
        return 'none'
      } else if (this.sortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    }
  },
  created: async function() {
    await this.loadData()
    await this.loadProducts()
  },
  watch: {
    // Triggered when a group selection change induced a change in retailers with data
    retailerIdsWithData: function(retailerIds, oldRetailerIds) {
      // Make sure that selectedRetailerIds do not include unselectable retailers
      // ex: a group has been deselected => a retailer has no data and is not selectable anymore
      this.selectedRetailerIds = this.selectedRetailerIds.filter(retailerId => this.retailerIdsWithData.has(retailerId))

      // If a new retailer has data, select it
      const retailerIdsToAdd = [...this.retailerOptions.map(retailer => retailer.id)]
        .filter(retailerId => !oldRetailerIds.has(retailerId) && !this.selectedRetailerIdsSet.has(retailerId))

      if (retailerIdsToAdd.length) {
        this.selectedRetailerIds = this.selectedRetailerIds.concat(retailerIdsToAdd)
      }
    }
  }
}
</script>

<style lang="scss">
.blurred {
  filter: blur(3px);
}

.border-right-thick {
  border-right: 2px solid #ddd !important;
}

.th-flag {
  position: absolute;
  bottom: 5px;
  right: calc(50% - 13px);
  width: 16px;
  height: 12px;
}

// Custom treeselect
.custom-treeselect.vue-treeselect {
  .vue-treeselect__control {
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    &:hover {
      border-color: #ced4da !important;
    }

    .vue-treeselect__placeholder, .vue-treeselect__limit-tip-text {
      color:#495057 !important;
      font-size: 0.9rem !important;
    }

    .vue-treeselect__control-arrow-container > .vue-treeselect__control-arrow,
    .vue-treeselect__x-container > .vue-treeselect__x {
      color: #343a3f !important;
    }
  }
}

// Custom table layout
.table-distribution {
  table-layout: auto;
  margin-bottom: 0;

  tr th {
    min-width: 60px;
  }

  // Id
  tr td:nth-child(1),
  tr:nth-child(1) th:nth-child(1) {
    min-width: 60px;
  }

  // Active
  tr td:nth-child(2),
  tr:nth-child(2) th:nth-child(2) {
    min-width: 30px;
  }

  // Group
  tr td:nth-child(3),
  tr:nth-child(1) th:nth-child(3) {
    max-width: 120px;
  }

  // Product
  tr td:nth-child(4),
  tr:nth-child(1) th:nth-child(4) {
    min-width: 150px;
    max-width: 180px;
  }

  // Raw packaging
  tr td:nth-child(5),
  tr:nth-child(1) th:nth-child(5) {
    min-width: 145px;
    max-width: 145px;
  }
}
</style>
