<template>
  <multiselect
    v-model="customisation"
    :options="customisations"
    :multiple="false"
    :close-on-select="true"
    :clear-on-select="true"
    label="name"
    track-by="id"
    :placeholder="$t('shared.placeholders.select')"
    :show-labels="false"
    :loading="customisationsLoading"
    id="multiselect-customisations"
  >
    <template v-slot:singleLabel="{ option }">
      {{ option.name }}
    </template>
    <template v-slot:option="{ option }">
      {{ option.name }}
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  name: 'CustomisationModalSelect',
  inheritAttrs: false,
  components: { Multiselect },
  props: {
    initialCustomisationId: Number,
    groupId: {
      type: Number,
      default: undefined
    }
  },
  data: function() {
    return {
      customisation: null,
      customisations: [],
      customisationsLoading: false,
      customisationsError: null
    }
  },
  methods: {
    loadCustomisations: async function() {
      this.customisationsLoading = true
      this.customisationsError = null

      const query = gql`
        query customisationSelectCustomisations ($groupId: Int!) {
          customisations(groupId: $groupId) {
            id
            name
          }
        }
      `

      const variables = { groupId: this.groupId }

      const res = await client.query({ query, variables })

      this.customisationsLoading = false

      if (res.errors) {
        this.customisationsError = res.errors
      } else {
        this.customisations = res.data.customisations
      }
    }
  },
  watch: {
    customisation: function(customisation) {
      this.$emit('input', customisation)
    }
  },
  created: async function() {
    await this.loadCustomisations()

    if (this.initialCustomisationId) {
      this.customisation = this.customisations.find(customisation => customisation.id === this.initialCustomisationId)
    }
  }
}
</script>
