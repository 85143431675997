
<div>
  <transition name="fade" mode="out-in">
    <div v-if="oneClickLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="oneClickError" class="alert alert-danger">
      <template v-if="oneClickError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <router-link
          :to="{ name: 'editOneClick', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <div class="form-group row">
        <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.active') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <dot :active="oneClick.active" />
        </div>
      </div>

      <div class="form-group row">
        <label for="all-groups-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allGroups') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <dot :active="oneClick.allGroups" />
        </div>
      </div>

      <div class="form-group row" v-if="!oneClick.allGroups">
        <label for="multiselect-groups" class="col-sm-2">{{ $t('attributes.oneClick.group') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <router-link :to="{ name: 'group', params: { id: oneClick.group.id } }">
            <region-flag :code="oneClick.group.region.code" />
            {{ oneClick.group.name }}
          </router-link>
        </div>
      </div>

      <div class="form-group row">
        <label for="all-retailers-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allRetailers') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <dot :active="oneClick.allRetailers" />
        </div>
      </div>

      <div class="form-group row" v-if="!oneClick.allRetailers">
        <label for="multiselect-retailers" class="col-sm-2">{{ $t('attributes.oneClick.retailer') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <router-link :to="{ name: 'retailer', params: { id: oneClick.retailer.id } }">
            <region-flag :code="oneClick.retailer.region.code" />
            {{ oneClick.retailer.name }}
          </router-link>
        </div>
      </div>

      <div class="form-group row">
        <label for="priority-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.priority') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <dot :active="oneClick.priority" />
        </div>
      </div>

      <hr>

      <div class="form-group row">
        <label for="expert-mode-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.expertMode') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <dot :active="oneClick.expertMode" />
        </div>
      </div>

      <!-- Basic mode -->
      <template v-if="!oneClick.expertMode">
        <!-- Colors -->
        <div class="form-group row">
          <label for="input-colors-main-text" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.main_text') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            {{ oneClick.colors.main_text }}
          </div>
        </div>

        <div class="form-group row">
          <label for="input-colors-loader" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.loader') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            {{ oneClick.colors.loader }}
          </div>
        </div>

        <!-- Images -->
        <div class="form-group row">
          <label for="retailer-logo-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.retailerLogo') }}</label>
          <div class="col-sm-10 col-12">
            <template v-if="oneClick.retailerLogo.url">
              <div class="border mb-3 d-inline-block">
                <img :src="oneClick.retailerLogo.url" class="img-fluid">
              </div>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="background-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.background') }}</label>
          <div class="col-sm-10 col-12">
            <template v-if="oneClick.background.url">
              <div class="border mb-3 d-inline-block">
                <img :src="oneClick.background.url" class="img-fluid">
              </div>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="background-mobile-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.backgroundMobile') }}</label>
          <div class="col-sm-10 col-12">
            <template v-if="oneClick.backgroundMobile.url">
              <div class="border mb-3 d-inline-block">
                <img :src="oneClick.backgroundMobile.url" class="img-fluid">
              </div>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label for="banner-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.banner') }}</label>
          <div class="col-sm-10 col-12">
            <template v-if="oneClick.banner.url">
              <div class="border mb-3 d-inline-block">
                <img :src="oneClick.banner.url" class="img-fluid">
              </div>
            </template>
          </div>
        </div>

        <!-- Raw CSS -->
        <div class="form-group row">
          <label for="input-raw-css" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.rawCss') }}</label>
          <div class="col-sm-10 col-12">
            <codemirror
              :value="oneClick.rawCss"
              class="codemirror-readonly"
              :options="{ mode: 'text/css', lineNumbers: true, tabSize: 2, autoRefresh: true, readOnly: true }" />
          </div>
        </div>

        <!-- Title -->
        <div class="form-group row">
          <label for="title" class="col-sm-2">{{ $t('attributes.oneClick.title') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">{{ oneClick.title }}</div>
        </div>
      </template>

      <!-- Expert mode -->
      <template v-else>
        <!-- HTML file -->
        <div class="form-group row">
          <label for="html-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.htmlFile') }}</label>
          <div class="col-sm-10 col-12">
            <div v-if="oneClick.htmlFile.url" class="mb-3">
              <a :href="oneClick.htmlFile.url" class="btn btn-primary" target="_blank">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
              </a>
            </div>
          </div>
        </div>

        <!-- CSS file -->
        <div class="form-group row">
          <label for="css-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.cssFile') }}</label>
          <div class="col-sm-10 col-12">
            <div v-if="oneClick.cssFile.url" class="mb-3">
              <a :href="oneClick.cssFile.url" class="btn btn-primary" target="_blank">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
              </a>
            </div>
          </div>
        </div>

        <!-- JS file -->
        <div class="form-group row">
          <label for="js-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.jsFile') }}</label>
          <div class="col-sm-10 col-12">
            <div v-if="oneClick.jsFile.url" class="mb-3">
              <a :href="oneClick.jsFile.url" class="btn btn-primary" target="_blank">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
              </a>
            </div>
          </div>
        </div>

        <!-- Show loader -->
        <div class="form-group row">
          <label for="show-loader-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.showLoader') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <dot :active="oneClick.showLoader" />
          </div>
        </div>
      </template>
    </div>
  </transition>
</div>
