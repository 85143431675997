
<div class="position-relative">
  <!-- Modal for users without role -->
  <div
    v-if="!userCanReadProductDistributionStats"
    class="modal d-block position-absolute"
    style="z-index: 1;"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-flag fa-fw" style="height: 1em;"><path fill="currentColor" d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"/></svg>
            {{ $t('views.stats.distribution.restrictionModal.title') }}
          </h5>
        </div>
        <div class="modal-body">
          {{ $t('views.stats.distribution.restrictionModal.body') }}
        </div>
      </div>
    </div>
  </div>

  <div :class="{ blurred: !userCanReadProductDistributionStats }">
    <!-- Alert for unauthorized users -->
    <div
      v-if="productsError && productsError.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Groups/retailers selectors -->
    <div class="custom-grid mb-3">
      <!-- Name -->
      <div>
        <label for="filter-name">{{ $t('views.stats.distribution.productName') }}</label>
        <input
          v-model="filter"
          type="text"
          class="form-control"
          id="filter-name"
          :placeholder="$t('attributes.productLanguageData.name')">
      </div>

      <!-- Active -->
      <div>
        <label for="filter-active">{{ $t('views.stats.distribution.active') }}</label>
        <select v-model="filters.active" class="custom-select">
          <option :value="null">{{ $t('shared.placeholders.select') }}</option>
          <option :value="false">Inactive</option>
          <option :value="true">Active</option>
        </select>
      </div>

      <!-- Groups -->
      <div>
        <label for="filter-group">{{ $t('views.stats.distribution.group') }}</label>
        <!-- todo:class="custom-select" -->
        <treeselect
          class="custom-treeselect"
          :limit-text="groupsTreeselectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelectOptions"
          :searchable="false"
          style="display: inline-block; max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Retailers -->
      <div>
        <label for="filter-group">{{ $t('views.stats.distribution.retailer') }}</label>
        <treeselect
          class="custom-treeselect"
          :limit-text="retailersTreeselectLimitText"
          :limit="0"
          :multiple="true"
          :options="retailersTreeselectOptions"
          :searchable="false"
          style="display: inline-block; max-width: 275px;"
          v-model="selectedRetailerIds"
          value-consists-of="LEAF_PRIORITY"
          :placeholder="productsLoading || retailersLoading ? 'Loading...' : 'Select...'">
          <template v-slot:option-label="{ node }">
            <template v-if="node.isBranch">
              {{ $t(`shared.retailerDistributions.${node.label}`) }}
            </template>
            <template v-else>
              <region-flag v-if="international" :code="node.label.region.code" />
              <img :src="node.label.img_small_url" style="margin-left: 5px; max-height: 20px;">
              {{ node.label.name }}
              <sup v-if="node.label.groupException" class="text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </sup>
            </template>
          </template>
        </treeselect>
      </div>
    </div>

    <!-- Columns/export buttons -->
    <div class="mb-3 d-flex justify-content-between align-items-center gutter">
      <button type="button" class="btn btn-primary" v-b-modal.columns-modal>
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM160 448H48c-8.837 0-16-7.163-16-16v-80h128v96zm0-128H32v-96h128v96zm0-128H32V96h128v96zm160 256H192v-96h128v96zm0-128H192v-96h128v96zm0-128H192V96h128v96zm160 160v80c0 8.837-7.163 16-16 16H352v-96h128zm0-32H352v-96h128v96zm0-128H352V96h128v96z" class=""></path></svg>
        {{ $t('shared.actions.manageColumns') }}
      </button>
      <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
        <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </span>
    </div>

    <!-- Columns modal -->
    <b-modal
      id="columns-modal"
      size="sm"
      :title="$t('shared.actions.manageColumns')"
      hide-footer
      scrollable
      no-fade>
      <template v-for="(value, column) in columnSettings">
        <div class="form-group" :key="column">
          <div class="custom-control custom-checkbox">
            <input
              v-model="columnSettings[column]"
              type="checkbox" class="custom-control-input" :id="`column-${column}`">
            <label class="custom-control-label" :for="`column-${column}`">
              <template v-if="column === 'rawPackaging'">
                {{ $t(`attributes.productLanguageData.${column}`) }}
              </template>
              <template v-else-if="['reference', 'ean', 'upc'].includes(column)">
                {{ $t(`attributes.product.${column}`) }}
              </template>
            </label>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Table -->
    <div class="table-responsive mb-3">
      <table class="table table-distribution table-hover table-bordered">
        <thead>
          <tr>
            <th @click="setSort('id')" :aria-sort="ariaSort('id')" rowspan="2" class="text-center">{{ $t('views.stats.distribution.id') }}</th>
            <th rowspan="2" class="text-center">{{ $t('views.stats.distribution.active') }}</th>
            <th @click="setSort('groupName')" :aria-sort="ariaSort('groupName')" rowspan="2">{{ $t('views.stats.distribution.group') }}</th>
            <th @click="setSort('name')" :aria-sort="ariaSort('name')" rowspan="2">{{ $t('views.stats.distribution.productName') }}</th>
            <th v-if="columnSettings.rawPackaging" @click="setSort('rawPackaging')" :aria-sort="ariaSort('rawPackaging')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.rawPackaging') }}</th>
            <th v-if="columnSettings.reference" @click="setSort('reference')" :aria-sort="ariaSort('reference')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.reference') }}</th>
            <th v-if="columnSettings.ean" @click="setSort('ean')" :aria-sort="ariaSort('ean')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.ean') }}</th>
            <th v-if="columnSettings.upc" @click="setSort('upc')" :aria-sort="ariaSort('upc')" rowspan="2" class="text-nowrap">{{ $t('views.stats.distribution.upc') }}</th>
            <th
              v-for="(retailers, service, serviceIndex) in selectedRetailersByService"
              :key="service"
              :colspan="retailers.length"
              class="text-nowrap"
              :class="{ 'border-right-thick': serviceIndex < selectedServices.length - 1 }">
              {{ $t(`shared.retailerDistributions.${service}`) }}
            </th>
          </tr>
          <tr>
            <template v-for="(retailers, service, serviceIndex) in selectedRetailersByService">
              <th
                v-for="(retailer, retailerIndex) in retailers"
                :key="retailer.id"
                @click="setSort(`retailer-${retailer.id}`)"
                :aria-sort="ariaSort(`retailer-${retailer.id}`)"
                class="text-center text-nowrap"
                :class="{ 'border-right-thick': retailerIndex === retailers.length - 1 && serviceIndex < selectedServices.length - 1 }"
                :title="`${retailer.name} (${$t(`shared.retailerDistributions.${retailer.service}`)})`"
                v-b-tooltip.hover.top.viewport="retailer.name">
                <region-flag v-if="international" :code="retailer.region.code" class="th-flag" />
                <img :src="retailer.imgSmallUrl" style="height: 20px;">
                <sup v-if="retailer.groupException" class="text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </sup>
              </th>
            </template>
          </tr>
        </thead>
        <tbody v-if="groupsErrors || (productsError && productsError.status === 500) || retailersError">
          <tr>
            <td :colspan="colspan" class="text-center alert-danger">
              {{ $t('errors.internalServerError') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="groupsLoading || productsLoading || retailersLoading">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="totalRows === 0">
          <tr>
            <td :colspan="colspan" class="text-center alert-warning">
              {{ $t('shared.warnings.noProduct') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="product in productsPage" :key="product.id">
            <td class="text-center">{{ product.id }}</td>
            <td class="ellipsis text-center"><dot :active="product.active" /></td>
            <td class="ellipsis">
              <region-flag v-if="international" :code="product.group.region.code" />
              {{ product.group.name }}
            </td>
            <td class="ellipsis">{{ product.product_language_datas[0].name }}</td>
            <td v-if="columnSettings.rawPackaging" class="ellipsis">{{ product.product_language_datas[0].raw_packaging || '–' }}</td>
            <td v-if="columnSettings.reference" class="ellipsis">{{ product.reference || '–' }}</td>
            <td v-if="columnSettings.ean" class="ellipsis">{{ product.ean || '–' }}</td>
            <td v-if="columnSettings.upc" class="ellipsis">{{ product.upc || '–' }}</td>
            <template v-for="(retailers, service, serviceIndex) in selectedRetailersByService">
              <td
                v-for="(retailer, retailerIndex) in retailers"
                :key="retailer.id"
                class="text-right"
                :class="{ 'border-right-thick': retailerIndex === retailers.length - 1 && serviceIndex < selectedServices.length - 1 }">
                {{ product.productRetailersMap[retailer.id] ? product.productRetailersMap[retailer.id].count : 0 | number }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter">
      <div class="overflow-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </div>
      <div>
        {{ pageEntriesInfo }}
      </div>
    </div>

    <!-- Export date -->
    <div class="text-secondary" v-if="lastExportDate">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" class=""></path></svg>
      {{ $t('views.stats.distribution.dnGeneratedAt') }}
      <span :title="lastExportDate.toISOString()">
        {{ lastExportDateFormatted }}
      </span>
    </div>
  </div>
</div>
