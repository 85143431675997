<template>
  <li class="nav-item">
    <a href="javascript:void(0);" class="nav-link" :class="linkClasses" @click="expand = !expand">
      <div class="d-flex align-items-center gutter-sm">
        <slot name="text"></slot>
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-right fa-w-6 ml-auto" :class="{ 'icon-rotate': expand }"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>
      </div>
    </a>
    <transition-expand>
      <ul v-if="expand" class="nav nav-pills flex-column flex-nowrap">
        <slot></slot>
      </ul>
    </transition-expand>
  </li>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'

// https://github.com/vuejs/vue-router/blob/dev/src/util/route.js#L115
const trailingSlashRE = /\/?$/

function isIncludedRoute(current, target) {
  return (
    current.path.replace(trailingSlashRE, '/').indexOf(
      target.path.replace(trailingSlashRE, '/')
    ) === 0
  )
}

export default {
  components: { TransitionExpand },
  data: function() {
    // Gather children side-menu-item vNodes
    const vNodes = []

    this.$slots.default.forEach(vNode => {
      if (vNode.componentOptions && vNode.componentOptions.tag === 'side-menu-item') {
        vNodes.push(vNode)
      } else if (vNode.componentOptions && vNode.componentOptions.tag === 'side-menu-item-group') {
        vNode.componentOptions.children.forEach(childVNode => {
          if (childVNode.componentOptions && childVNode.componentOptions.tag === 'side-menu-item') {
            vNodes.push(childVNode)
          }
        })
      }
    })

    // Extract routes
    const childRoutes = []

    vNodes.forEach(vNode => {
      const path = vNode.componentOptions.propsData.path
      childRoutes.push(this.$router.resolve(path).route)
    })

    return {
      childRoutes: childRoutes,
      expand: childRoutes.some(childRoute => isIncludedRoute(this.$route, childRoute))
    }
  },
  computed: {
    linkClasses: function() {
      const linkClasses = []

      if (this.childRoutes.some(childRoute => isIncludedRoute(this.$route, childRoute))) {
        linkClasses.push('active')
      }

      if (this.expand) {
        linkClasses.push('opened')
      }

      return linkClasses
    }
  }
}
</script>
