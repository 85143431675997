
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="analyticsDataError.validations"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- Groups selector + daterangepicker -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelectOptions"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range picker -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Right zone - Export button -->
    <div class="flex-fill flex-basis-0 text-right">
      <!-- Export button -->
      <button @click="exportXLSX" type="button" class="btn btn-primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </div>
  </div>

  <!-- Table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-vertical-align-top" id="table-stats-custom-services">
      <thead>
        <tr>
          <th>{{ $t('views.stats.customServices.sales.date') }}</th>
          <th>{{ $t('views.stats.customServices.sales.retailer') }}</th>
          <th>{{ $t('views.stats.customServices.sales.customService') }}</th>
          <th>{{ $t('attributes.productLanguageData.name') }}</th>
          <th>{{ $t('attributes.product.reference') }}</th>
          <th>{{ $t('attributes.product.ean') }}</th>
          <th>{{ $t('attributes.product.upc') }}</th>
          <th>{{ $t('views.stats.customServices.sales.quantity') }}</th>
          <th>{{ $t('views.stats.customServices.sales.amount') }}</th>
        </tr>
      </thead>
      <tbody v-if="groupsLoading || analyticsDataLoading.validations">
        <tr>
          <td :colspan="colspan" class="text-center">
            <md-spinner md-indeterminate />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="groupsWithCustomServices.length === 0">
        <tr>
          <td :colspan="colspan" class="alert-warning">
            {{ $t('shared.warnings.noCustomService') }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-if="validationsFiltered.length === 0">
          <td :colspan="colspan" class="alert-warning">
            {{ $t('shared.warnings.noValidationEvent') }}
          </td>
        </tr>
        <template v-else v-for="validation in validationsFiltered">
          <tr v-for="(transferredProduct, index) in validation.dimensions.transferredProducts" :key="transferredProduct.id">
            <td v-if="index === 0" :rowspan="validation.dimensions.transferredProducts.length">
              <small>
                {{ validation.date | formatDate }}
              </small>
            </td>
            <td v-if="index === 0" :rowspan="validation.dimensions.transferredProducts.length">
              <region-flag v-if="international" :code="validation.dimensions.retailer.region.code" />
              <img :src="validation.dimensions.retailer.imgSmallUrl" style="max-height: 20px;">
              {{ validation.dimensions.retailer.name }}
              <span class="badge badge-light">
                {{ $t(`shared.retailerDistributions.${validation.dimensions.retailer.service}`) }}
              </span>
            </td>
            <td
              v-if="index === 0"
              :rowspan="validation.dimensions.transferredProducts.length"
              :data-custom-service-id="validation.dimensions.mediumId">
              {{ validation.customService.name }}
            </td>
            <td>{{ transferredProduct.product.productLanguageDatas[0].name }}</td>
            <td>{{ transferredProduct.product.reference || '–' }}</td>
            <td>{{ transferredProduct.product.ean || '–' }}</td>
            <td>{{ transferredProduct.product.upc || '–' }}</td>
            <td class="text-right">{{ transferredProduct.quantity }}</td>
            <td class="text-right">{{ transferredProduct.amount * transferredProduct.quantity | currency(transferredProduct.currency) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</div>
