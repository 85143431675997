<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="pimDataSourceLoading || groupsLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="pimDataSourceError" class="alert alert-danger">
        <template v-if="pimDataSourceError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else-if="groupsError" class="alert alert-danger">
        <template>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-3 d-flex">
          <h5 class="ml-2"> {{ pimDataSource.name }}</h5>
          <router-link :to="{ name: 'showPimDataSource', params: { id: $route.params.id } }"
            class="btn btn-warning ml-auto">
            {{ $t('shared.actions.back') }}
          </router-link>
        </div>
        <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.name') }}</th>
              <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.active') }}</th>
              <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.isBrand') }}</th>
              <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.prospect') }}</th>
              <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.parent') }}</th>
              <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.hubStatus') }}</th>
              <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.action') }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(unknownGroup, index) in unknownGroupsNotCreated" :key="index">
                <td>
                  <region-flag :code="unknownGroup.region.code" />
                  {{ unknownGroup.name }}
                </td>
                <td class="text-center"><dot :active="unknownGroup.active" /></td>
                <td class="text-center"><dot :active="unknownGroup.is_brand" /></td>
                <td class="text-center"><dot :active="unknownGroup.prospect" /></td>
                <td>
                  <region-flag :code="unknownGroup.region.code" />
                  {{ unknownGroup.parent_name }}
                </td>
                <td class="text-center">
                    <span class="badge" :class="unknownGroup.status.badge"> {{ unknownGroup.status.code }}</span>
                  </td>
                <td class="text-center">
                  <button
                    class="btn btn-primary"
                    :disabled="(unknownGroup.status.alreadyCreated || groupCreationLoading) === true"
                    @click="createGroup(unknownGroup)">
                    {{ $t('attributes.pimDataSource.unknownGroups.create') }}
                  </button>
                </td>
              </tr>
          </tbody>
        </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Dot from '../shared/Dot.vue'
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'

export default {
  components: { MdSpinner, Dot, RegionFlag },
  data: function() {
    return {
      pimDataSource: {
        name: '',
        unknownGroups: []
      },
      groupCreationLoading: false,
      groupCreationErrors: null,
      groups: [],
      groupsLoading: false,
      groupsError: null,
      pimDataSourceLoading: false,
      pimDataSourceError: null
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/user'
    }),
    unknownGroupsNotCreated: function() {
      return this.pimDataSource.unknownGroups.list.filter(unknownGroup => !unknownGroup.status.alreadyCreated)
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.pimDataSourceLoading = true
      this.pimDataSourceError = null

      const query = `query PimDataSourcesForm ($pimDataSourceId: Int!) {
        pimDataSource(id: $pimDataSourceId) {
          id
          name
          unknownGroups
        }
      }`

      const variables = {
        pimDataSourceId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.pimDataSourceLoading = false

          if (data.errors) {
            this.pimDataSourceError = data.errors
          } else {
            this.pimDataSource = data.data.pimDataSource
          }
        })
    },
    // Load groups
    loadGroups: function() {
      this.groupsLoading = true
      this.groupsError = null

      return fetch('/api/interface/pim_data_sources/retrieve_groups', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false

          if (data.error) {
            this.groupsError = data.error
          } else {
            this.groups = data.data

            for (const unknownGroup of this.pimDataSource.unknownGroups.list) {
              const isGroupAlreadyCreated = this.groups.find(group => group.name === unknownGroup.name && group.region.id === unknownGroup.region.id)

              if (isGroupAlreadyCreated) {
                unknownGroup.status = { code: 'ok', alreadyCreated: true, badge: 'badge-success' }
              } else {
                unknownGroup.status = { code: 'nok', alreadyCreated: false, badge: 'badge-danger' }
              }
            }
          }
        })
    },
    // Create group through hub api
    createGroup: async function(unknownGroup) {
      this.groupCreationLoading = true
      this.groupCreationErrors = null
      const body = {
        name: unknownGroup.name,
        parent_name: unknownGroup.parent_name,
        region_id: unknownGroup.region.id,
        is_brand: unknownGroup.is_brand,
        active: unknownGroup.active,
        prospect: unknownGroup.prospect
      }

      return fetch('/api/interface/pim_data_sources/create_group', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ data: { brand: body } })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupCreationLoading = false
          if (data.error) {
            this.groupCreationErrors = data.error
            const errorMessage = data.status_code === 409 ? this.$t('shared.warnings.noParentGroup') : this.$t('shared.warnings.noGroupCreation')
            this.$root.$bvToast.toast(errorMessage, {
              variant: 'warning',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          } else {
            unknownGroup.status = { code: 'ok', alreadyCreated: true, badge: 'badge-success' }
            this.$root.$bvToast.toast(this.$t('shared.success.pimDataSource.createUnknownGroup'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    }
  },
  created: function() {
    this.loadData()
    this.loadGroups()
  }
}
</script>
