
<div>
  <transition name="fade" mode="out-in">
    <div v-if="pimDataSourceLoading || groupsLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="pimDataSourceError" class="alert alert-danger">
      <template v-if="pimDataSourceError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else-if="groupsError" class="alert alert-danger">
      <template>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-3 d-flex">
        <h5 class="ml-2"> {{ pimDataSource.name }}</h5>
        <router-link :to="{ name: 'showPimDataSource', params: { id: $route.params.id } }"
          class="btn btn-warning ml-auto">
          {{ $t('shared.actions.back') }}
        </router-link>
      </div>
      <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.name') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.active') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.isBrand') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.unknownGroups.prospect') }}</th>
            <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.parent') }}</th>
            <th class="th-shrink">{{ $t('attributes.pimDataSource.unknownGroups.hubStatus') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.action') }}</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="(unknownGroup, index) in unknownGroupsNotCreated" :key="index">
              <td>
                <region-flag :code="unknownGroup.region.code" />
                {{ unknownGroup.name }}
              </td>
              <td class="text-center"><dot :active="unknownGroup.active" /></td>
              <td class="text-center"><dot :active="unknownGroup.is_brand" /></td>
              <td class="text-center"><dot :active="unknownGroup.prospect" /></td>
              <td>
                <region-flag :code="unknownGroup.region.code" />
                {{ unknownGroup.parent_name }}
              </td>
              <td class="text-center">
                  <span class="badge" :class="unknownGroup.status.badge"> {{ unknownGroup.status.code }}</span>
                </td>
              <td class="text-center">
                <button
                  class="btn btn-primary"
                  :disabled="(unknownGroup.status.alreadyCreated || groupCreationLoading) === true"
                  @click="createGroup(unknownGroup)">
                  {{ $t('attributes.pimDataSource.unknownGroups.create') }}
                </button>
              </td>
            </tr>
        </tbody>
      </table>
      </div>
    </div>
  </transition>
</div>
