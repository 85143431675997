
<div>
  <transition name="fade" mode="out-in">
    <div v-if="customisationLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="customisationError" class="alert alert-danger">
      <template v-if="customisationError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ customisation.name }}</h5>
        <router-link
          :to="{ name: 'editCustomisation', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <persistent-tabs content-class="mt-3">
        <!-- Infos tab -->
        <b-tab :title="$t('views.customisations.tabs.infos')">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2">{{ $t('attributes.customisation.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              {{ customisation.name }}
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-groups" class="col-sm-2">{{ $t('attributes.customisation.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <router-link :to="{ name: 'group', params: { id: customisation.group.id } }">
                <region-flag :code="customisation.group.region.code" />
                {{ customisation.group.name }}
              </router-link>
            </div>
          </div>

          <div class="form-group row">
            <label for="default-checkbox" class="col-sm-2">{{ $t('attributes.customisation.default') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.default" />
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-domains" class="col-sm-2">{{ $t('attributes.customisation.domains') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <ul class="mb-0">
                <li v-for="domain in customisation.domains" :key="domain">{{ domain }}</li>
              </ul>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-landing-pages" class="col-sm-2">{{ $t('attributes.customisation.multiproductLandingPages') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <ul class="mb-0">
                <li v-for="landingPage in customisation.multiproductLandingPages" :key="landingPage.id">
                  <router-link :to="{ name: 'multiproductLandingPage', params: { id: landingPage.id } }">
                    {{ landingPage.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </b-tab>

        <!-- Features tab -->
        <b-tab :title="$t('views.customisations.tabs.features')">
          <div class="form-group row">
            <label for="btn-radios-layout" class="col-sm-3">{{ $t('attributes.customisation.layout') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <b-form-radio-group
                id="btn-radios-layout"
                :checked="customisation.layout"
                buttons
                button-variant="outline-primary">
                <b-form-radio
                  v-for="value in ['columns', 'tabs']"
                  :key="value"
                  :value="value"
                  :disabled="customisation.layout !== value">
                  <div class="d-flex flex-column align-items-center">
                    <big>
                      <svg v-if="value === 'columns'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 h -112 c -8.837 0 -16 -7.163 -16 -16 v -250 h 128 v 266 z M 320 448 h -128 v -266 h 128 v 266 z M 480 182 v 250 c 0 8.837 -7.163 16 -16 16 h -112 v -266 h 128 z M 480 152 h -448 v -72 h 448 v 72 z" class=""></path></svg>
                      <svg v-else-if="value === 'tabs'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 H 48 c -8.837 0 -16 -7.163 -16 -16 v -160 h 448 v 160 c 0 8.837 -7.163 16 -16 16 h -416 z M 32 184 h 128 v 56 h -128 z M 192 184 h 128 v 56 h -128 z M 352 184 h 128 v 56 h -128 z M 32 80 h 448 v 72 h -448 z" class=""></path></svg>
                    </big>
                    <small>{{ $t(`attributes.customisation.layouts.${value}`) }}</small>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>

          <div class="form-group row">
            <label for="header-popin-checkbox" class="col-sm-3">{{ $t('attributes.customisation.headerPopin') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.headerPopin" />
            </div>
          </div>

          <div class="form-group row">
            <label for="header-inline-checkbox" class="col-sm-3">{{ $t('attributes.customisation.headerInline') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.headerInline" />
            </div>
          </div>

          <div class="form-group row">
            <label for="show-reference-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showReference') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.showReference" />
            </div>
          </div>

          <div class="form-group row">
            <label for="is-vertically-centered-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isVerticallyCentered') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.isVerticallyCentered" />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="retailer-price-filter-checkbox"
              class="col-sm-3"
              v-b-tooltip.hover :title="$t('views.products.tooltip.retailerPriceFilter')">
              {{ $t('attributes.customisation.retailerPriceFilter') }}
              <sup>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
              </sup>
            </label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.retailerPriceFilter" />
            </div>
          </div>

          <div class="form-group row">
            <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.mapFirst') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.mapFirst" />
            </div>
          </div>

          <div class="form-group row">
            <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayStorePhoneOnList') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.displayStorePhoneOnList" />
            </div>
          </div>

          <div class="form-group row">
            <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayPhoneInInfoWindow') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.displayPhoneInInfoWindow" />
            </div>
          </div>

          <div class="form-group row">
            <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayLoadingPlaceholders') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.displayLoadingPlaceholders" />
            </div>
          </div>

          <div class="form-group row">
            <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showDriveDirections') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.showDriveDirections" />
            </div>
          </div>

          <div class="form-group row">
            <label for="browser-geolocation-checkbox" class="col-sm-3">{{ $t('attributes.customisation.browserGeolocation') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.browserGeolocation" />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="use-geoip-checkbox"
              class="col-sm-3"
            >
              <span v-b-tooltip.hover :title="$t('views.products.tooltip.geoIp')">
                {{ $t('attributes.customisation.useGeoip') }}
                <sup>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                </sup>
              </span>
            </label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.useGeoip" />
            </div>
          </div>

          <div class="form-group row">
            <label for="suggest-biggest-cities-checkbox" class="col-sm-3">{{ $t('attributes.customisation.suggestBiggestCities') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.suggestBiggestCities" />
            </div>
          </div>

          <div class="form-group row">
            <label for="side-opening-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isSideOpening') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.isSideOpening" />
            </div>
          </div>

          <div class="form-group row">
            <label for="slider-search-radius" class="col-sm-3">{{ $t('attributes.customisation.radius') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
                {{ customisation.radius }} km
            </div>
          </div>

          <hr>

          <h5 class="mb-3">{{ $t('views.customisations.additionalFields') }}</h5>

          <div class="form-group row">
            <label for="price-checkbox" class="col-sm-3">{{ $t('attributes.customisation.price') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.price" />
            </div>
          </div>

          <template v-if="customisation.layout === 'tabs'">
            <div class="form-group row">
              <label for="show-shipping-cost-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingCost') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="customisation.showShippingCost" />
              </div>
            </div>

            <div class="form-group row">
              <label for="show-shipping-delay-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingDelay') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="customisation.showShippingDelay" />
              </div>
            </div>
          </template>
        </b-tab>

        <!-- Style tab -->
        <b-tab :title="$t('views.customisations.tabs.style')">
          <div class="form-group row">
            <label for="input-color" class="col-sm-2">{{ $t('attributes.customisation.color') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              {{ customisation.color }}
            </div>
          </div>

          <div class="form-group row">
            <label for="input-border-radius" class="col-sm-2">{{ $t('attributes.customisation.borderRadius') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              {{ customisation.borderRadius }}
            </div>
          </div>

          <div class="form-group row">
            <label for="font-input" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.font') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="customisation.font.url" class="mb-3">
                <a :href="customisation.font.url" class="btn btn-primary" target="_blank">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" class=""></path></svg>
                  {{ filenameFromUrl(customisation.font.url) }}
                </a>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="logo-input" class="col-sm-2">{{ $t('attributes.customisation.logo') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="customisation.logo.url" class="border mb-3 d-inline-block">
                <img :src="customisation.logo.url" class="img-fluid">
              </div>
            </div>
          </div>
        </b-tab>

        <!-- Retailers tab -->
        <b-tab :title="$t('views.customisations.tabs.retailers')">
          <!-- Distribution methods -->
          <div class="form-group row">
            <label class="col-sm-2">{{ $t('attributes.customisation.distributionMethods') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="!customisation.distributionMethods.length" class="alert alert-warning" role="alert">
                {{ $t('shared.warnings.noDistributionMethod') }}
              </div>
              <table class="table" v-else>
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t('views.groups.inheritedFrom') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="distributionMethod in distributionMethodsWithStatus"
                    :key="distributionMethod.name"
                    :class="{
                      'table-danger alert-danger': !distributionMethod.active
                    }"
                  >
                    <td>{{ $t(`shared.retailerDistributions.${distributionMethod.name}`) }}</td>
                    <td>
                      <ul v-if="distributionMethod.inheritedFrom" class="list-unstyled mb-0">
                        <li v-for="group in distributionMethod.inheritedFrom" :key="group.id">
                          <router-link :to="{ name: 'group', params: { id: group.id } }" class="alert-link">
                            <region-flag :code="group.region.code" />
                            {{ group.name }}
                          </router-link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Ordered deliveries -->
          <div class="form-group row">
            <label class="col-sm-2">{{ $t('attributes.customisation.orderedDeliveryIds') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="customisation.orderedDeliveries.length === 0" class="alert alert-warning" role="alert">
                {{ $t('shared.warnings.noRetailer') }}
              </div>
              <table class="table table-hover" v-else>
                <thead>
                  <tr>
                    <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                    <th>{{ $t('attributes.retailer.name') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="retailer in customisation.orderedDeliveries" :key="retailer.id">
                    <th>{{ retailer.id }}</th>
                    <td>
                      <region-flag :code="retailer.region.code" />
                      <img :src="retailer.img_small_url" style="max-height: 20px;">
                      {{ retailer.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Delivery tab sort -->
          <div class="form-group row">
            <label class="col-sm-2">{{ $t('attributes.customisation.deliveryTabSort') }}</label>
            <div class="col-sm-10 col-12">
              <b-form-radio-group
                id="btn-radios-delivery-tab-sort"
                :checked="customisation.deliveryTabSort"
                stacked
                disabled
              >
                <b-form-radio
                  v-for="value in deliveryTabSorts"
                  :key="value"
                  :value="value"
                >
                  {{ $t(`attributes.customisation.deliveryTabSorts.${value}`) }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </b-tab>

        <!-- Tags tab -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.customisations.tabs.tags') }}
            <span class="badge badge-primary">{{ customisation.customisationTags.length }}</span>
          </template>

          <div class="form-group row">
            <label for="filter-tags-checkbox" class="col-sm-3">{{ $t('attributes.customisation.filterTags') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <dot :active="customisation.filterTags" />
            </div>
          </div>

          <!-- Tags list -->
          <div v-if="customisation.customisationTags.length === 0" class="alert alert-warning">
            {{ $t('shared.warnings.noTags') }}
          </div>
          <table v-else class="table table-hover">
            <thead>
              <tr>
                <th class="th-shrink">{{ $t('attributes.customisation.tags.id') }}</th>
                <th>{{ $t('attributes.customisation.tags.name') }}</th>
                <th class="th-shrink">{{ $t('attributes.customisation.tags.active') }}</th>
                <th class="th-shrink">{{ $t('attributes.customisation.tags.retailers') }}</th>
                <th class="th-shrink"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tag in customisation.customisationTags"
                :key="tag.id">
                <th>{{ tag.id }}</th>
                <td>{{ tag.name }}</td>
                <td class="text-center"><dot :active="tag.active" /></td>
                <td class="text-center">{{ tag.retailers.length }}</td>
                <td class="text-center">
                  <button type="button" class="btn btn-sm btn-primary" @click="showTag(tag)">
                    {{ $t('shared.actions.show') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Tag modal -->
          <b-modal
            id="tagModal"
            ref="tagModal"
            size="xl"
            :title="$t('views.customisations.show.tag')"
            ok-only
            no-fade
          >
            <div class="form-group row">
              <label for="input-category-name" class="col-sm-2">{{ $t('attributes.customisation.tags.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">{{ tagModal.name }}</div>
            </div>

            <div class="form-group row">
              <label for="category-active-checkbox" class="col-sm-2">{{ $t('attributes.customisation.tags.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="tagModal.active" />
              </div>
            </div>

            <!-- Tag Retailers -->
            <div class="form-group row">
              <label class="col-sm-2">{{ $t('attributes.customisation.tags.retailers') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="tagModal.retailers.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table v-else class="table table-hover">
                  <thead>
                      <tr>
                        <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                        <th>{{ $t('attributes.retailer.name') }}</th>
                      </tr>
                    </thead>
                  <tbody>
                    <tr
                      v-for="retailer in tagModal.retailers"
                      :key="retailer.id"
                    >
                      <th>{{ retailer.id }}</th>
                      <td>
                        <div class="gutter-x-xs">
                          <region-flag :code="retailer.region.code" />
                          <img :src="retailer.img_small_url" style="max-height: 20px;">
                          {{ retailer.name }}
                          <span class="badge badge-light">{{ $t(`shared.retailerDistributions.${retailer.service}`) }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-modal>
        </b-tab>

        <!-- Texts tab -->
        <b-tab :title="$t('views.customisations.tabs.texts')">
          <b-tabs content-class="mt-3" v-if="customisation.texts">
            <b-tab
              v-for="(translations, locale) in customisation.texts"
              :key="locale">
              <template v-slot:title>
                <region-flag :code="locale.slice(-2)" />
                {{ locale }}
              </template>

              <div
                v-for="(textKey, value) in translations"
                :key="textKey"
                class="form-group row">
                <label :for="`input-${textKey}`" class="col-sm-2">{{ textKey }}</label>
                <div class="col-lg-4 col-sm-6 col-12">{{ value }}</div>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- Tracking tab -->
        <b-tab :title="$t('views.customisations.tabs.tracking')">
          <div class="form-group row">
            <label for="select-tracking-consent-request" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.trackingConsentRequest') }}</label>
            <b-form-radio-group
              class="d-flex"
              id="btn-radios-tracking-consent-request"
              v-model="customisation.trackingConsentRequest"
              stacked
            >
              <b-form-radio
                v-for="value in trackingConsentRequests"
                :key="value"
                :value="value"
              >
                {{ $t(`attributes.customisation.trackingConsentRequests.${value}`) }}
                <small class="form-text text-muted mb-2">{{ $t(`attributes.customisation.trackingConsentRequestsHelp.${value}`) }}</small>
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form-group row">
            <label for="input-trackers-html" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.trackersHtml') }}</label>
            <div class="col-12 mt-2">
              <AceEditor
                :style="{ height: '60vh'}"
                v-model="customisation.trackersHtml"
                lang="html"
                :options="editorOptions"
              ></AceEditor>
            </div>
          </div>
        </b-tab>

        <!-- CSS tab -->
        <b-tab :title="$t('views.customisations.tabs.css')">
          <div class="form-group row">
            <label for="input-raw-css" class="col-sm-4 col-form-label">
              {{ $t('attributes.customisation.rawCss') }}
              <button type="button" class="btn btn-primary btn-sm ml-auto" @click="updateLiveRawCss()" v-if="false">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sync-alt fa-w-16"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" class=""></path></svg>
              </button>
            </label>
            <div class="col-12 mt-2">
              <AceEditor
                :style="{ height: '60vh' }"
                v-model="customisation.rawCss"
                lang="css"
                :options="editorOptions"
              ></AceEditor>
            </div>
          </div>
        </b-tab>

        <!-- JS tab -->
        <b-tab :title="$t('views.customisations.tabs.javascript')">
          <div class="form-group row">
            <label for="input-raw-js" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawJs') }}</label>
            <div class="col-12 mt-2">
              <AceEditor
                :style="{ height: '60vh' }"
                v-model="customisation.rawJs"
                lang="javascript"
                :options="editorOptions"
              ></AceEditor>
            </div>
          </div>
        </b-tab>
      </persistent-tabs>
    </div>
  </transition>
</div>
