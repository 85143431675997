
<div>
  <transition name="fade" mode="out-in">
    <div v-if="popinImplementationLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="popinImplementationError" class="alert alert-danger">
      <template v-if="popinImplementationError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ popinImplementation.name }}</h5>
        <router-link :to="{ name: 'editPopinImplementation', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <persistent-tabs content-class="mt-3">
        <!-- Infos tab -->
        <b-tab :title="$t('views.popinImplementations.tabs.infos')">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.id') }}</th>
                <td>{{ popinImplementation.id }}</td>
              </tr>
              <tr>
                <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.key') }}</th>
                <td><code>{{ popinImplementation.key }}</code></td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.name') }}</th>
                <td>{{ popinImplementation.name }}</td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.group') }}</th>
                <td>
                  <router-link :to="{ name: 'group', params: { id: popinImplementation.group.id } }">
                    <region-flag :code="popinImplementation.group.region.code" />
                    {{ popinImplementation.group.name }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.groupActive') }}</th>
                <td>
                  <dot :active="popinImplementation.group.active" />
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.active') }}</th>
                <td><dot :active="popinImplementation.active" /></td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.scriptUrl') }}</th>
                <td>
                  <a :href="popinImplementationScriptUrl" target="_blank">
                    {{ popinImplementationScriptUrl }}
                  </a>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.scriptTag') }}</th>
                <td>
                  <code>
                    &lt;script src="{{ popinImplementationScriptUrl }}"&gt;&lt;/script&gt;
                  </code>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.autoMigration') }}</th>
                <td><dot :active="popinImplementation.autoMigration" /></td>
              </tr>
            </table>
          </div>
        </b-tab>

        <!-- Popins tab -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.popinImplementations.tabs.popins') }}
            <span class="badge badge-primary">{{ popinImplementation.popins.length }}</span>
          </template>

          <!-- Popins list -->
          <div v-if="popinImplementation.popins.length === 0" class="alert alert-warning">
            {{ $t('shared.warnings.noPopin') }}
          </div>
          <table v-else class="table table-hover">
            <thead>
              <tr>
                <th class="th-shrink">{{ $t('attributes.popin.id') }}</th>
                <th>{{ $t('attributes.popin.name') }}</th>
                <th class="th-shrink">{{ $t('attributes.popin.key') }}</th>
                <th class="th-shrink">{{ $t('attributes.popin.active') }}</th>
                <th class="th-shrink">{{ $t('attributes.popin.widgets') }}</th>
                <th>{{ $t('attributes.popin.scriptTag') }}</th>
                <th>{{ $t('attributes.popin.multiproductWidget') }}</th>
                <th>{{ $t('views.popinImplementations.show.popinPreview') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="popin in popinImplementation.popins" :key="popin.id">
                <th>{{ popin.id }}</th>
                <td>{{ popin.name }}</td>
                <td class="text-nowrap"><code>{{ popin.key }}</code></td>
                <td class="text-center"><dot :active="popin.active" /></td>
                <td class="text-center">{{ popin.widgetsCount }}</td>
                <td>
                  <code>
                    &lt;div class="C2B_Widget_Key" data-key="{{ popin.key }}"&gt;Where to buy&lt;/div&gt;
                  </code>
                </td>
                <td>
                  <router-link v-if="popin.multiproductWidgetId" :to="{ name: 'multiproductWidget', params: { id: popin.multiproductWidgetId } }">
                    #{{ popin.multiproductWidgetId }}
                  </router-link>
                  <button v-else class="btn btn-primary text-nowrap" @click="createMultiproductWidget(popin)" :disabled="createMultiproductWidgetForPopinLoading[popin.id]">
                    <md-spinner v-if="createMultiproductWidgetForPopinLoading[popin.id]" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
                    <span>{{ $t('shared.submit.create') }}</span>
                  </button>
                </td>
                <td>
                  <div class="C2B_Widget_Key" :data-key="popin.key">
                    <button class="btn btn-primary text-nowrap">
                      Where to buy
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- Style tab -->
        <b-tab :title="$t('views.popinImplementations.tabs.style')">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_background_color') }}</th>
                <td>
                  <span v-if="popinImplementation.customisation['button-background-color']" :style="`color: ${popinImplementation.customisation['button-background-color']};`">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                  </span>
                  {{ popinImplementation.customisation['button-background-color'] }}
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_text_color') }}</th>
                <td>
                  <span v-if="popinImplementation.customisation['button-text-color']" :style="`color: ${popinImplementation.customisation['button-text-color']};`">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                  </span>
                  {{ popinImplementation.customisation['button-text-color'] }}
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_cart_color') }}</th>
                <td>
                  <span v-if="popinImplementation.customisation['button-cart-color']" :style="`color: ${popinImplementation.customisation['button-cart-color']};`">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                  </span>
                  {{ popinImplementation.customisation['button-cart-color'] }}
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.ctaText') }}</th>
                <td>{{ popinImplementation.ctaText }}</td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.ctaIcon') }}</th>
                <td>
                  <div v-if="popinImplementation.ctaIcon.url" class="border mb-3 d-inline-block">
                    <img :src="popinImplementation.ctaIcon.url" class="img-fluid">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">{{ $t('attributes.popinImplementation.rawCss') }}</th>
                <td>
                  <AceEditor
                    :style="{ height: '30vh' }"
                    v-model="popinImplementation.rawCss"
                    lang="css"
                    :options="editorOptions"
                  ></AceEditor>
                </td>
              </tr>
            </table>
          </div>
        </b-tab>
      </persistent-tabs>
    </div>
  </transition>
</div>
