<template>
  <div class="h-100">
    <transition name="fade" mode="out-in">
      <div v-if="customPageLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="customPageError" class="alert alert-danger">
        <template v-if="customPageError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <iframe v-else :src="customPage.url" width="100%" height="100%" frameborder="0" class="custom-page-iframe"></iframe>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: { MdSpinner },
  data: function() {
    return {
      customPage: null,
      customPageLoading: false,
      customPageError: null
    }
  },
  inject: ['setCustomBreadcrumb'],
  methods: {
    // Load data
    loadData: function() {
      this.customPageLoading = true
      this.customPageError = null

      const query = `query customPagesShow ($id: Int!) {
        customPage(id: $id) {
          id
          name
          icon
          url
          active
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.customPageLoading = false

          if (data.errors) {
            this.customPageError = { status: res.status, errors: data.errors }
          } else {
            this.customPage = Object.freeze(data.data.customPage)
            this.setCustomBreadcrumb(this.customPage.name)
          }
        })
    }
  },
  created: function() {
    this.loadData()
  },
  destroyed: function() {
    this.setCustomBreadcrumb(null)
  }
}
</script>
