<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="postLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="postError" class="alert alert-danger">
        <template v-if="postError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit()">
          <div class="form-group row">
            <label for="title-input" class="col-sm-2 col-form-label">{{ $t('attributes.post.title') }}</label>
            <div class="col-lg-6 col-sm-8 col-12">
              <input
                v-model="post.title"
                type="text"
                class="form-control"
                :class="{ 'is-invalid' : postSubmitError && postSubmitError.data && postSubmitError.data.title }"
                id="title-input"
                :placeholder="$t('attributes.post.title')">
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.title" class="invalid-feedback">{{ tErrors('post', 'title', postSubmitError.data.title) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="body-input" class="col-sm-2 col-form-label">{{ $t('attributes.post.body') }}</label>
            <div class="col-lg-6 col-sm-8 col-12">
              <textarea v-model="post.body" class="form-control" :class="{ 'is-invalid' : postSubmitError && postSubmitError.data && postSubmitError.data.body }" id="body-input" :placeholder="$t('attributes.post.body')"></textarea>
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.body" class="invalid-feedback">{{ tErrors('post', 'body', postSubmitError.data.body) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="category-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.post.category') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <multiselect
                v-model="post.category"
                :options="categories"
                open-direction="bottom"
                :allow-empty="false"
                :clear-on-select="true"
                :placeholder="$t('attributes.post.category')"
                :close-on-select="true"
                :max-height="200"
                :showLabels="false"
                :class="{ 'is-invalid' : postSubmitError && postSubmitError.data && postSubmitError.data.category }">
                  <template slot="option" slot-scope="props">
                    <span></span>
                    {{ $t(`attributes.post.categories.${props.option}`) }}
                  </template>
                  <template slot="singleLabel" slot-scope="props">
                    <span></span>
                    {{ props.option }}
                  </template>
              </multiselect>
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.category" class="invalid-feedback d-block">{{ tErrors('post', 'category', postSubmitError.data.category) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="status-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.post.status') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <multiselect
                v-model="post.status"
                :options="status"
                open-direction="bottom"
                :allow-empty="true"
                :clear-on-select="true"
                :placeholder="$t('attributes.post.status')"
                :close-on-select="true"
                :max-height="200">
                <template slot="option" slot-scope="props">
                  {{ $t(`attributes.post.statuses.${props.option}`) }}
                </template>
                <template slot="singleLabel" slot-scope="props">
                  {{ $t(`attributes.post.statuses.${props.option}`) }}
                </template>
              </multiselect>
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.status" class="invalid-feedback d-block">{{ tErrors('post', 'status', postSubmitError.data.status) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="region-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.post.region') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="post.regionId"
                :options="regionOptions"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="regionsLoading"
                :class="{ 'is-invalid' : postSubmitError && postSubmitError.data && postSubmitError.data.region }"
                id="region-multiselect">
                <template slot="singleLabel" slot-scope="{ option }">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </template>
              </key-multiselect>
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.region" class="invalid-feedback d-block">{{ tErrors('post', 'region', postSubmitError.data.region) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="is-group-post-checkbox" class="col-sm-2">{{ $t('attributes.post.isGroupPost') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="isGroupPost" type="checkbox" class="custom-control-input" id="is-group-post-checkbox">
                <label class="custom-control-label" for="is-group-post-checkbox"></label>
              </div>
            </div>
          </div>

          <div v-if="isGroupPost" class="form-group row">
            <label for="group-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.post.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="post.group"
                :class="{ 'is-invalid' : postSubmitError && postSubmitError.data && postSubmitError.data.group }"
                id="multiselect-group" />
              <div v-if="postSubmitError && postSubmitError.data && postSubmitError.data.group" class="invalid-feedback d-block">{{ tErrors('post', 'group', postSubmitError.data.group) }}</div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="postSubmitLoading">
              <md-spinner v-if="postSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: { MdSpinner, RegionFlag, KeyMultiselect, GroupModalSelect, Multiselect },
  props: ['action'],
  data: function() {
    return {
      post: {
        title: null,
        body: null,
        status: null,
        category: null,
        regionId: null,
        group: null
      },
      postLoading: false,
      postError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      postSubmitLoading: false,
      postSubmitError: null,
      isGroupPost: false,
      errors: {},
      groupError: null,
      status: ['in_progress', 'solved'],
      categories: ['info', 'injection', 'feed']
    }
  },
  methods: {
    // Load data
    loadData: function({ post = true } = {}) {
      this.regionsLoading = true
      this.regionsError = null
      if (post) {
        this.postLoading = true
        this.postError = null
      }

      const query = `query postsForm ($post: Boolean = true, $postId: Int = null) {
        post(id: $postId) @include(if: $post) {
          id
          title
          body
          category
          status
          region {
            id
          }
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
        regions {
          id
          code
        }
      }`

      const variables = {
        post,
        postId: post ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.regionsLoading = false
          if (post) this.postLoading = false

          if (data.errors) {

          } else {
            this.regions = Object.freeze(data.data.regions)
            if (post) {
              this.post = {
                ...data.data.post,
                regionId: data.data.post.region.id
              }
            }
          }
        })
    },
    // Form submit
    submit: function() {
      this.postSubmitLoading = true
      this.postSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            title: this.post.title,
            body: this.post.body,
            status: this.post.status,
            category: this.post.category,
            regionId: this.post.regionId,
            groupId: this.isGroupPost ? this.post.group.id : null
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreatePostInput!) {
          createPost(input: $input) {
            post {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.postSubmitLoading = false

            if (data.data.createPost.errors) {
              this.postSubmitErrors = data.data.createPost.errors
            } else {
              this.$router.push({ name: 'posts' })
              this.$root.$bvToast.toast(this.$t('shared.success.post.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.postId = this.$route.params.id

        const mutation = `mutation($input: UpdatePostInput!) {
          updatePost(input: $input) {
            post {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.postSubmitLoading = false

            if (data.data.updatePost.errors) {
              this.postSubmitErrors = data.data.updatePost.errors
            } else {
              this.$router.push({ name: 'posts' })
              this.$root.$bvToast.toast(this.$t('shared.success.post.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    }
  },
  filters: {
    capitalize: function(value) {
      if (value) {
        return value.charAt(0).toUpperCase() + value.substring(1)
      } else return value
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ post: false })
    }
  }
}
</script>
