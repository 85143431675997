// A dirty patch to have nice data label connectors on highcharts pie charts
export default function(H) {
  H.wrap(H.seriesTypes.pie.prototype, 'drawDataLabels', function(p) {
    var xOffset = 5
    var yOffset = 12

    p.call(this)

    H.each(this.points, function(p) {
      if (p.dataLabel && p.connector) {
        p.connector.attr({
          d: [
            'M',
            p.dataLabel._pos.x + (p.labelPos[6] === 'left' ? -xOffset : xOffset),
            p.dataLabel._pos.y + yOffset,
            'L',
            p.labelPos[4],
            p.dataLabel._pos.y + yOffset,
            'L',
            p.labelPos[4],
            p.labelPos[5]
          ]
        })
      }
    })
  })
}
