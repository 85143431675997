
<div>
  <transition name="fade" mode="out-in">
    <div v-if="multiproductWidgetLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="multiproductWidgetError" class="alert alert-danger">
      <template v-if="multiproductWidgetError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <!-- Tabs -->
        <b-tabs content-class="mt-3">
          <!-- Infos -->
          <b-tab :title="$t('views.multiproductWidgets.tabs.infos')" active>
            <!-- Name -->
            <div class="form-group row">
              <label for="input-name" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.multiproductWidget.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="multiproductWidget.name" type="text" class="form-control" :class="{ 'is-invalid' : multiproductWidgetSubmitErrors && multiproductWidgetSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.multiproductWidget.name')">
                <div v-if="multiproductWidgetSubmitErrors && multiproductWidgetSubmitErrors.name" class="invalid-feedback">{{ tErrors('multiproductWidget', 'name', multiproductWidgetSubmitErrors.name) }}</div>
              </div>
            </div>

            <!-- Active -->
            <div class="form-group row">
              <label for="active-checkbox" class="col-md-2 col-sm-3">{{ $t('attributes.multiproductWidget.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="multiproductWidget.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                  <label class="custom-control-label" for="active-checkbox"></label>
                </div>
              </div>
            </div>

            <!-- Group -->
            <div class="form-group row">
              <label for="multiselect-group" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.multiproductWidget.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="multiproductWidget.group"
                  :multiple="false"
                  :class="{ 'is-invalid' : multiproductWidgetSubmitErrors && multiproductWidgetSubmitErrors.group }"
                  id="multiselect-group" />
                <div v-if="multiproductWidgetSubmitErrors && multiproductWidgetSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('multiproductWidget', 'group', multiproductWidgetSubmitErrors.group) }}</div>
              </div>
            </div>

            <!-- Multiproduct widget customisation -->
            <div class="form-group row">
              <label for="multiselect-multiproduct-widget-customisation" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.multiproductWidget.multiproductWidgetCustomisation') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <multiselect
                  v-model="multiproductWidget.multiproductWidgetCustomisation"
                  :options="multiproductWidgetCustomisations"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="name"
                  track-by="id"
                  :placeholder="$t('shared.placeholders.select')"
                  :show-labels="false"
                  :loading="multiproductWidgetCustomisationsLoading">
                  <template v-slot:singleLabel="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                  <template v-slot:option="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                </multiselect>
                <div v-if="multiproductWidgetSubmitErrors && multiproductWidgetSubmitErrors.multiproductWidgetCustomisation" class="invalid-feedback d-block">{{ tErrors('multiproductWidget', 'multiproductWidgetCustomisation', multiproductWidgetSubmitErrors.multiproductWidgetCustomisation) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Products -->
          <b-tab>
            <template v-slot:title>
              {{ $t('views.multiproductWidgets.tabs.products') }}
              <span class="badge badge-primary">{{ multiproductWidget.multiproductWidgetsProducts.length }}</span>
            </template>

            <div v-if="multiproductWidget.multiproductWidgetsProducts.length === 0" class="alert alert-warning" role="alert">
              {{ $t('shared.warnings.noProduct') }}
            </div>

            <table
              class="table table-hover"
              v-else>
              <draggable
                tag="tbody"
                v-model="multiproductWidget.multiproductWidgetsProducts"
                handle=".handle">
                <tr
                  v-for="multiproductWidgetsProduct in multiproductWidget.multiproductWidgetsProducts"
                  :key="multiproductWidgetsProduct.product.id"
                  class="table-col-shrink"
                  :class="{ 'table-danger': multiproductWidgetsProduct._destroy }">
                  <!-- Drag & drop handle -->
                  <th class="table-col-shrink text-center handle cursor-move">
                    <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                  </th>
                  <!-- Product infos -->
                  <th class="table-col-shrink">
                    <dot :active="multiproductWidgetsProduct.product.active" />
                  </th>
                  <th class="table-col-shrink">{{ multiproductWidgetsProduct.product.id }}</th>
                  <td class="table-col-shrink">
                    <img
                      v-if="multiproductWidgetsProduct.product.img"
                      :src="multiproductWidgetsProduct.product.img.url"
                      class="multiproduct-widgets-product-product-img"
                    />
                  </td>
                  <td>
                    <div>{{ multiproductWidgetsProduct.product.productLanguageDatas[0].name }}</div>
                    <div class="product-raw-packaging">{{ multiproductWidgetsProduct.product.productLanguageDatas[0].rawPackaging }}</div>

                    <!-- Product expanded form -->
                    <div
                      v-if="productsExpanded[multiproductWidgetsProduct.product.id]"
                      class="mt-2">
                      <!-- Label HTML -->
                      <div class="form-group row">
                        <div class="col-sm-2">
                          {{ $t('attributes.multiproductWidgetsProduct.labelHtml') }}
                        </div>
                        <div class="col-sm-10">
                          <div class="codemirror-wrapper-xs">
                            <codemirror
                              v-model="multiproductWidgetsProduct.labelHtml"
                              :options="{ mode: 'text/html', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
                          </div>
                        </div>
                      </div>

                      <!-- Retailer exceptions -->
                      <div class="form-group row">
                        <div class="col-sm-2">
                          {{ $t('attributes.multiproductWidgetsProduct.retailerExceptions') }}
                        </div>
                        <div class="col-sm-10">
                          <div v-for="distributionMethod in distributionMethods" :key="distributionMethod">
                            {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                            <span class="badge badge-primary">
                              {{ productsRetailerExceptionsCount.get(multiproductWidgetsProduct.product.id).get(distributionMethod) }}
                            </span>
                          </div>

                          <button
                            type="button"
                            class="btn btn-warning mt-3"
                            @click="$refs[`product-retailer-exceptions-modal-${multiproductWidgetsProduct.product.id}`][0].show()"
                          >
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-edit fa-w-18"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class=""></path></svg>
                            {{ $t('shared.actions.edit') }}
                          </button>

                          <!-- Retailer exceptions modal -->
                          <b-modal
                            :ref="`product-retailer-exceptions-modal-${multiproductWidgetsProduct.product.id}`"
                            size="xl"
                            :title="$t('views.productModalSelect.title')"
                            hide-footer
                            no-fade>
                            <b-tabs content-class="mt-3">
                              <b-tab v-for="distributionMethod in distributionMethods" :key="distributionMethod">
                                <template v-slot:title>
                                  {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                                  <span class="badge badge-primary">
                                    {{ productsRetailerExceptionsCount.get(multiproductWidgetsProduct.product.id).get(distributionMethod) }}
                                  </span>
                                </template>

                                <div
                                  v-if="productsRetailerExceptionsCount.get(multiproductWidgetsProduct.product.id).get(distributionMethod) === 0"
                                  class="alert alert-danger">
                                  {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                                </div>

                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                                      <th class="th-shrink"></th>
                                      <th class="th-shrink"></th>
                                      <th>{{ $t('attributes.retailer.name') }}</th>
                                      <th class="th-shrink"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="retailer in productsRetailers.get(multiproductWidgetsProduct.product.id).get(distributionMethod)"
                                      :key="retailer.id"
                                      :class="{ 'table-danger alert-danger': retailer.exception }">
                                      <th>{{ retailer.id }}</th>
                                      <td><region-flag :code="retailer.region.code" /></td>
                                      <td><img :src="retailer.img_small_url" style="max-height: 20px;"></td>
                                      <td>{{ retailer.name }}</td>
                                      <td class="text-right">
                                        <button
                                          v-if="retailer.exception"
                                          type="button"
                                          class="btn btn-sm btn-success text-nowrap"
                                          @click="removeProductRetailer(multiproductWidgetsProduct, retailer)">
                                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
                                          {{ $t('shared.actions.enable') }}
                                        </button>
                                        <button
                                          v-else
                                          type="button"
                                          class="btn btn-sm btn-danger text-nowrap"
                                          @click="addProductRetailer(multiproductWidgetsProduct, retailer)">
                                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                                          {{ $t('shared.actions.disable') }}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-tab>
                            </b-tabs>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="table-col-shrink">
                    <template v-if="!multiproductWidgetsProduct.id">
                      <span class="badge badge-warning">New</span>
                    </template>
                  </td>
                  <!-- Expand button -->
                  <td class="table-col-shrink">
                    <button type="button" class="btn btn-secondary btn-sm" @click="toggleProductExpanded(multiproductWidgetsProduct.product)">
                      <template v-if="!productsExpanded[multiproductWidgetsProduct.product.id]">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                      </template>
                      <template v-else>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                      </template>
                    </button>
                  </td>
                  <!-- Delete/restore button -->
                  <td class="text-nowrap table-col-shrink">
                    <template v-if="!multiproductWidgetsProduct._destroy">
                      <button type="button" class="btn btn-sm btn-danger" @click="removeMultiproductWidgetsProduct(multiproductWidgetsProduct)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success" @click="restoreMultiproductWidgetsProduct(multiproductWidgetsProduct)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </td>
                </tr>
              </draggable>
            </table>

            <div class="form-group row">
              <div class="col-sm-6">
                <product-modal-select
                  v-if="multiproductWidget.group"
                  :group-id="multiproductWidget.group.id"
                  @input="addMultiproductWidgetsProduct"
                  :except-product-ids="productIds"
                  :close-on-select="false">
                  <template v-slot:element>
                    <button type="button" class="btn btn-success">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                      {{ $t('views.michelinOffers.form.addProduct') }}
                    </button>
                  </template>
                </product-modal-select>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="multiproductWidgetSubmitLoading">
            <md-spinner v-if="multiproductWidgetSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
