
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError && eventAggregatesError.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- First row with group selector, date range picker, export button -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelect"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range picker -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Export button -->
    <div class="flex-fill flex-basis-0 text-right">
      <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
        <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </span>
    </div>
  </div>

  <!-- Drive/delivery table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover" id="table-stats-retailers">
      <thead>
        <tr>
          <th @click="setSort('retailer')" :aria-sort="ariaSort('retailer')">{{ $t('views.stats.retailers.retailer') | capitalize }}</th>
          <!-- Add hidden region column only for export  -->
          <th class="d-none">{{ $t('views.stats.retailers.region') }}</th>
          <th @click="setSort('service')" :aria-sort="ariaSort('service')">{{ $t('views.stats.retailers.service') | capitalize }}</th>
          <th @click="setSort('redirection')" :aria-sort="ariaSort('redirection')" class="data-cell">{{ $t('shared.eventActions.widget.redirection') }}</th>
          <th @click="setSort('redirection_percentage')" :aria-sort="ariaSort('redirection_percentage')" class="data-cell">{{ $t('shared.eventActions.widget.redirection_percentage') }}</th>
          <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
            <th @click="setSort('redirection_revenues')" :aria-sort="ariaSort('redirection_revenues')" class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
          <template v-if="userCanReadValidations">
            <th @click="setSort('validation')" :aria-sort="ariaSort('validation')" class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th @click="setSort('validation_percentage')" :aria-sort="ariaSort('validation_percentage')" class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation_percentage') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th @click="setSort('validation_rate')" :aria-sort="ariaSort('validation_rate')" class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.validation_rate') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th @click="setSort('validation_revenues')" :aria-sort="ariaSort('validation_revenues')" class="data-cell th-shield">
              {{ $t('shared.eventActions.widget.revenue') | capitalize }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
          <template v-if="userCanReadEstimations">
            <th @click="setSort('estimated_sales')" :aria-sort="ariaSort('estimated_sales')" class="data-cell th-shield">
              {{ $t('views.stats.retailers.estimatedSales') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th @click="setSort('estimated_sales_rate')" :aria-sort="ariaSort('estimated_sales_rate')" class="data-cell th-shield">
              {{ $t('views.stats.retailers.estimatedSalesRate') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <th @click="setSort('estimated_revenues')" :aria-sort="ariaSort('estimated_revenues')" class="data-cell th-shield">
              {{ $t('views.stats.retailers.estimatedRevenues') }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="groupsLoading || tableDataLoading">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="font-weight-semibold bg-light">
            <td>Total</td>
            <td class="d-none"></td>
            <td></td>
            <td class="text-right">{{ tableDataTotals.redirection | number }}</td>
            <td class="text-right">{{ 1 | percentage }}</td>
            <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
              <td class="text-right">
                <!-- can't sum amounts with different currencies -->
                <template v-if="group && !international">
                  {{ tableDataTotals.redirection_revenues | price(group.region) }}
                </template>
              </td>
            </template>
            <template v-if="userCanReadValidations">
              <td class="text-right">{{ tableDataTotals.validation | number }}</td>
              <td class="text-right">{{ 1 | percentage }}</td>
              <td class="text-right">{{ tableDataTotals.validation_rate | percentage }}</td>
              <td class="text-right">
                <!-- can't sum amounts with different currencies -->
                <template v-if="group && !international">
                  {{ tableDataTotals.validation_revenues | price(group.region) }}
                </template>
              </td>
            </template>
            <template v-if="userCanReadEstimations">
              <td class="text-right">
                {{ tableDataTotals.estimated_sales | number }}
              </td>
              <td class="text-right">
                {{ tableDataTotals.estimated_sales_rate | percentage }}
              </td>
              <td class="text-right">
                <!-- can't sum amounts with different currencies -->
                <template v-if="group && !international">
                  {{ tableDataTotals.estimated_revenues | price(group.region) }}
                </template>
              </td>
            </template>
          </tr>
          <tr v-for="retailer in onlineRetailersSorted" :key="retailer.id">
            <td>
              <div class="d-inline-block position-relative mr-1">
                <img :src="retailer.imgSmallUrl" class="td-img-sm">
                <region-flag v-if="international || hasForeignRetailers" :code="retailer.region.code" class="sub-flag" />
              </div>
              {{ retailer.name }}
            </td>
            <!-- Add hidden data region column only for export -->
            <td class="d-none">
              {{ retailer.region.code }}
            </td>
            <td><span class="badge badge-light">{{ retailer.service | retailerService }}</span></td>
            <td class="text-right">
              {{ retailer.stats.redirection | number }}
            </td>
            <td class="text-right">
              {{ retailer.stats.redirection_percentage | percentage }}
            </td>
            <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
              <td class="text-right">
                {{ retailer.stats.redirection_revenues | price(retailer.region) }}
              </td>
            </template>
            <template v-if="userCanReadValidations">
              <td class="text-right">
                {{ retailer.stats.validation | number }}
              </td>
              <td class="text-right">
                {{ retailer.stats.validation_percentage | percentage }}
              </td>
              <td class="text-right">
                {{ retailer.stats.validation_rate | percentage }}
              </td>
              <td class="text-right">
                {{ retailer.stats.validation_revenues | price(retailer.region) }}
              </td>
            </template>
            <template v-if="userCanReadEstimations">
              <td class="text-right">
                {{ retailer.stats.estimated_sales | number }}
              </td>
              <td class="text-right">
                {{ retailer.stats.estimated_sales_rate | percentage }}
              </td>
              <td class="text-right">
                {{ retailer.stats.estimated_revenues | price(retailer.region) }}
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Store Export button -->
  <div v-if="hasStoreActivated" class="mb-3 d-flex">
    <div class="flex-fill flex-basis-0 text-right">
      <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
        <button @click="storeExportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </span>
    </div>
  </div>

  <!-- Store table -->
  <div v-if="hasStoreActivated" class="table-responsive">
    <table class="table table-bordered table-hover" id="table-stats-retailers-store">
      <thead>
        <tr>
          <th @click="setStoresSort('retailer')" :aria-sort="storesAriaSort('retailer')">{{ $t('views.stats.retailers.retailer') | capitalize }}</th>
          <!-- Add hidden region column only for export  -->
          <th class="d-none">{{ $t('views.stats.retailers.region') }}</th>
          <th @click="setStoresSort('store_selection')" :aria-sort="storesAriaSort('store_selection')" class="data-cell">{{ $t('shared.eventActions.widget.store_selection') | capitalize }}</th>
          <th @click="setStoresSort('store_selection_percentage')" :aria-sort="storesAriaSort('store_selection_percentage')" class="text-center data-cell">{{ $t('shared.eventActions.widget.store_selection_percentage') | capitalize }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="groupsLoading || tableDataLoading">
          <tr>
            <td colspan="3" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="font-weight-semibold bg-light">
            <td>Total</td>
            <td class="d-none"></td>
            <td class="text-right">{{ tableDataTotals.store_selection | number }}</td>
            <td class="text-right">{{ 1 | percentage }}</td>
          </tr>
          <tr v-for="retailer in offlineRetailersSorted" :key="retailer.id">
            <td>
              <div class="d-inline-block position-relative mr-1">
                <img :src="retailer.imgSmallUrl" class="td-img-sm">
                <region-flag v-if="international || hasForeignRetailers" :code="retailer.region.code" class="sub-flag" />
              </div>
              {{ retailer.name }}
            </td>
            <td class="d-none">
              {{ retailer.region.code }}
            </td>
            <td class="text-right">
              {{ retailer.stats.store_selection | number }}
            </td>
            <td class="text-right">
              {{ retailer.stats.store_selection_percentage | percentage }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Pie charts -->
  <div class="card">
    <div class="card-body">
      <div class="position-relative">
        <div
          v-if="groupsLoading"
          class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
          style="z-index: 1;">
        <md-spinner md-indeterminate />
        </div>
        <div class="d-flex flex-column gutter" style="min-height: 240px;">
          <div v-for="(pieChart, index) in pieChartList" :key="index">
            <highcharts
              :options="chartOptions[index]"
              class="chart"
              :aria-busy="eventAggregatesLoading"
              style="width: 100%; height: 350px;" />
            <div class="text-center">
              <b>
                {{ pieChart.label }}
                <sup v-if="pieChart.action === 'validation' && userIsAdmin" class="text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </sup>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
