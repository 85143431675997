<template>
  <div>
    <div class="d-flex justify-content-between align-items-end mb-3">
      <h4 class="mb-0">
        <span>Widget status:</span>
        <span v-if="statusesLoading">
          <md-spinner md-indeterminate :diameter="20" :stroke-width="5" />
        </span>
        <span v-else :class="status === 1 ? 'text-success' : 'text-danger'">
          <template v-if="status === 1">Online</template>
          <template v-else>Down</template>
        </span>
      </h4>
      <div>
        <multiselect
          v-model="selectedRegion"
          :options="regions"
          open-direction="bottom"
          :allow-empty="false"
          :clear-on-select="false"
          :close-on-select="true"
          select-label=""
          deselect-label=""
          :max-height="200">
        </multiselect>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <highcharts :options="chartOptions" style="width: 100%;"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import moment from 'moment'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import xrange from 'highcharts/modules/xrange'
xrange(Highcharts)

export default {
  components: { MdSpinner, highcharts: Chart, Multiselect },
  data: function() {
    return {
      dailyAvailabilities: [],
      statuses: [],
      selectedRegion: 'Moscow - RU',
      statusesLoading: false
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.statusesLoading = true

      const query = `query postsIndexMonitoring($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
        widgetStatusConnection(last: 1) {
          nodes {
            id
            date
            statuses
          }
        }

        widgetDailyAvailabilities(startDate: $startDate, endDate: $endDate) {
          id
          date
          summaries
        }
      }`

      const variables = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.statusesLoading = false

          if (data.errors) {
            console.error(data.errors)
          } else {
            this.statuses = Object.freeze(data.data.widgetStatusConnection.nodes[0].statuses)
            this.dailyAvailabilities = Object.freeze(data.data.widgetDailyAvailabilities)
          }
        })
    },
    getColor(downCount) {
      return downCount === 0 ? '#26C281' : '#cb5a5e'
    }
  },
  computed: {
    status: function() {
      const statusItem = this.statuses.find(status => status.region === this.selectedRegion)
      return statusItem ? statusItem.status : null
    },
    summaries() {
      return this.dailyAvailabilities.map(dailyAvailability => {
        return {
          date: dailyAvailability.date,
          ...dailyAvailability.summaries.find(summary => summary.region === this.selectedRegion)
        }
      })
    },
    chartData: function() {
      return this.summaries.map(summary => {
        return {
          x: new Date(summary.date).valueOf(),
          x2: new Date(summary.date).valueOf() + 86400000,
          y: 0,
          color: this.getColor(summary.down_count),
          downtimeDuration: summary.downtime_duration,
          downCount: summary.down_count
        }
      })
    },
    chartOptions: function() {
      return {
        chart: {
          type: 'xrange',
          margin: [0, 0, 20, 0],
          height: '12%'
        },
        title: null,
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            const date = new Date(this.point.x).toLocaleDateString()
            if (this.point.downCount > 0) {
              return `${date}<br>Downtime duration: ${this.point.downtimeDuration}`
            } else {
              return `${date}<br>No downtime`
            }
          }
        },
        xAxis: {
          type: 'datetime',
          lineWidth: 0,
          tickWidth: 0,
          height: '90%',
          min: moment.utc().startOf('day').subtract(30, 'day').valueOf(),
          max: moment.utc().startOf('day').valueOf()
        },
        yAxis: {
          title: null,
          gridLineWidth: 0,
          categories: ['']
        },
        series: [{
          borderRadius: 0,
          pointWidth: 30,
          zones: [{ color: '#0275d8' }],
          data: this.chartData
        }]
      }
    },
    regions() {
      return this.statuses.map(status => status.region)
    }
  },
  created() {
    this.loadData()
  }
}
</script>
