<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="importTrackerLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="importTrackerError" class="alert alert-danger">
        <template v-if="importTrackerError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <!-- Image upload -->
          <div class="d-flex gutter-sm mb-3">
            <file-upload
              :multiple="true"
              v-model="files"
              :extensions="/\.(gif|jpe?g|png|webp)$/i"
              :drop="true"
              @input-filter="inputFilter">
              <button type="button" class="btn btn-secondary">
                <template v-if="files.length === 0">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-upload fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
                  {{ $t('views.importTrackers.images.selectImages') }}
                </template>
                <template v-else>
                  {{ $tc('views.importTrackers.images.imagesSelected', files.length) }}
                </template>
              </button>
            </file-upload>

            <button
              type="submit"
              v-if="files.length - imageFilesWithoutProduct.length > 0"
              class="btn btn-primary"
              :disabled="importSubmitLoading">
              <md-spinner v-if="importSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              {{ $t('shared.actions.import') }}
            </button>
          </div>

          <!-- Images with name not matching any product imageTmpName -->
          <div v-if="imageFilesWithoutProduct.length > 0" class="alert alert-danger">
            {{ $t('views.importTrackers.images.noMatchWarning') }}
            <ul class="mb-0 font-weight-bold">
              <li v-for="imageFile in imageFilesWithoutProduct" :key="imageFile.name">
                {{ imageFile.name }}
              </li>
            </ul>
          </div>

          <!-- Import products -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.product.id') }}</th>
                  <th class="table-col-shrink"></th>
                  <th class="table-col-shrink">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                  </th>
                  <th class="table-col-shrink"></th>
                  <th>{{ $t('attributes.product.imageTmpName') }}</th>
                  <th>{{ $t('attributes.productLanguageData.name') }}</th>
                  <th class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                  <th>{{ $t('attributes.product.reference') }}</th>
                  <th>{{ $t('attributes.product.ean') }}</th>
                  <th>{{ $t('attributes.product.upc') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.product.widget') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.product.group') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in importTracker.products"
                  :key="product.id">
                  <th>{{ product.id }}</th>
                  <td class="text-center">
                    <dot :active="product.active" />
                  </td>
                  <td :id="`product-${product.id}-img`">
                    <span v-if="product.hasImg">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                      <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                        <img :src="product.img.medium.url" style="height: 200px;" />
                      </b-tooltip>
                    </span>
                    <span v-else class="text-danger">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </span>
                  </td>
                  <td :id="`product-${product.id}-new-img`">
                    <span v-if="productImageFile(product)" class="text-success">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                      <b-tooltip :target="`product-${product.id}-new-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                        <img :src="productImageFile(product).blob" style="height: 200px;" />
                      </b-tooltip>
                    </span>
                  </td>
                  <td class="text-nowrap"><code>{{ product.imageTmpName }}</code></td>
                  <td class="ellipsis" style="max-width: 200px;">
                    {{ product.productLanguageDatas[0].name }}
                  </td>
                  <td class="ellipsis" style="max-width: 120px;">
                    <small>{{ product.productLanguageDatas[0].rawPackaging }}</small>
                  </td>
                  <td><small>{{ product.reference }}</small></td>
                  <td><small>{{ product.ean }}</small></td>
                  <td><small>{{ product.upc }}</small></td>
                  <td class="text-nowrap">
                    <template v-if="product.widget">
                      <router-link :to="{ name: 'widget', params: { id: product.widget.id } }" class="small">
                        {{ product.widget.key }}
                      </router-link>
                    </template>
                  </td>
                  <td class="ellipsis" style="max-width: 150px;">
                    <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                      <region-flag :code="product.group.region.code" />
                      {{ product.group.name }}
                    </router-link>
                  </td>
                  <td>
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                      </template>
                      <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTooltip, BDropdown } from 'bootstrap-vue'
import FileUpload from 'vue-upload-component'
import jsonToFormData from 'json-form-data'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTooltip, BDropdown, FileUpload },
  data: function() {
    return {
      importTracker: null,
      importTrackerLoading: false,
      importTrackerError: null,
      files: [],
      importSubmitLoading: false,
      importSubmitError: null
    }
  },
  computed: {
    imageFilesWithoutProduct: function() {
      const imageTmpNames = this.importTracker.products.map(product => product.imageTmpName)
      return this.files.filter(file => !imageTmpNames.includes(file.name))
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.importTrackerLoading = true
      this.importTrackerError = null

      const query = `query importTrackersShow ($id: Int!) {
        importTracker(id: $id) {
          id
          products {
            id
            active
            reference
            ean
            upc
            img
            hasImg
            imageTmpName
            productLanguageDatas {
              id
              name
              rawPackaging
            }
            widget {
              id
              key
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.importTrackerLoading = false

          if (data.errors) {
            this.importTrackerError = { status: res.status, errors: data.errors }
          } else {
            this.importTracker = Object.freeze(data.data.importTracker)
          }
        })
    },
    // Try to get uploaded image file for a product, based on its imageTmpName
    productImageFile: function(product) {
      return this.files.find(file => file.name === product.imageTmpName)
    },
    // Process selected image files
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Create the 'blob' field for thumbnail preview
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      }
    },
    // Form submit
    submit: function() {
      this.importSubmitLoading = true
      this.importSubmitError = null

      const productsAttributes = []
      this.importTracker.products.map(product => {
        if (this.productImageFile(product)) {
          productsAttributes.push({
            id: product.id,
            img: this.productImageFile(product).file
          })
        }
      })

      const body = {
        import_tracker: {
          products_attributes: productsAttributes
        }
      }

      return fetch(`/api/interface/import_trackers/${this.$route.params.id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json'
        },
        body: jsonToFormData(body, { showLeafArrayIndexes: false })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.importSubmitLoading = false

          if (!res.ok) {
            this.importSubmitError = { status: res.status, data }
          } else {
            this.$router.push({ name: 'importTracker', params: { id: data.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.importTracker.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
