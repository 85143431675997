<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="pimDataSourceLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="pimDataSourceError" class="alert alert-danger">
        <template v-if="pimDataSourceError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>

        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="pim-data-source-name" class="col-sm-3">{{ $t('attributes.pimDataSource.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <p>{{ pimDataSource.name }}</p>
            </div>
          </div>
          <hr>

          <!-- Pim Data Source data -->
          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-3">{{ $t('attributes.pimDataSource.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="pimDataSource.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="disable-products-checkbox" class="col-sm-3">{{ $t('attributes.pimDataSource.disableProducts') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="pimDataSource.disableProducts" type="checkbox" class="custom-control-input" id="disable-products-checkbox">
                <label class="custom-control-label" for="disable-products-checkbox"></label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="input-disable-threshold"
              class="col-sm-3 col-form-label"
              v-b-tooltip.hover :title="$t('views.pimDataSources.index.tooltip.disableThreshold')"
            >
              {{ $t('attributes.pimDataSource.disableThreshold') }}
              <sup>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                </sup>
            </label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input
                v-model="pimDataSource.disableThreshold"
                type="text"
                class="form-control"
                :disabled="!pimDataSource.disableProducts"
                :class="{ 'is-invalid' : pimDataSourceSubmitErrors && pimDataSourceSubmitErrors.disableThreshold }"
                id="input-disable-threshold" :placeholder="$t('attributes.pimDataSource.disableThreshold')">
              <div v-if="pimDataSourceSubmitErrors && pimDataSourceSubmitErrors.disableThreshold" class="invalid-feedback">{{ tErrors('pimDataSource', 'disableThreshold', pimDataSourceSubmitErrors.disableThreshold) }}</div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="pimDataSourceSubmitLoading">
              <md-spinner v-if="pimDataSourceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: { MdSpinner },
  directives: { 'b-tooltip': VBTooltip },
  props: ['action'],
  data: function() {
    return {
      pimDataSource: {
        name: '',
        active: true,
        disableProducts: true,
        disableThreshold: 100
      },
      pimDataSourceLoading: false,
      pimDataSourceError: null,
      pimDataSourceSubmitLoading: false,
      pimDataSourceSubmitErrors: null
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/user'
    })
  },
  methods: {
    // Load data
    loadData: function() {
      this.pimDataSourceLoading = true
      this.pimDataSourceError = null

      const query = `query PimDataSourcesForm ($pimDataSourceId: Int!) {
        pimDataSource(id: $pimDataSourceId) {
          id
          name
          active
          disableProducts
          disableThreshold
        }
      }`

      const variables = {
        pimDataSourceId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.pimDataSourceLoading = false

          if (data.errors) {
            this.pimDataSourceError = data.errors
          } else {
            this.pimDataSource = data.data.pimDataSource
          }
        })
    },
    // Form submit
    submit: async function() {
      this.pimDataSourceSubmitLoading = true
      this.pimDataSourceSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            active: this.pimDataSource.active,
            disableThreshold: Number(this.pimDataSource.disableThreshold),
            disableProducts: this.pimDataSource.disableProducts
          }
        }
      }

      if (this.action === 'edit') {
        variables.input.pimDataSourceId = this.$route.params.id

        const mutation = `mutation($input: UpdatePimDataSourceInput!) {
          updatePimDataSource(input: $input) {
            pimDataSource {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.pimDataSourceSubmitLoading = false
            console.log(data)
            if (data.data.updatePimDataSource.errors) {
              this.pimDataSourceSubmitErrors = data.data.updatePimDataSource.errors
            } else {
              this.$router.push({ name: 'pimDataSources' })
              this.$root.$bvToast.toast(this.$t('shared.success.pimDataSource.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    }
  }
}
</script>
