
<div>
  <!-- Filters -->
  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.customisation.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            :placeholder="$t('attributes.customisation.name')">
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.customisation.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.customisation.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>

        <div>
          <label for="filter-features">{{ $t('attributes.customisation.features') }}</label>
          <multiselect
              v-model="filters.featureIds"
              :multiple="true"
              :close-on-select="false"
              :options="featureOptions"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false">
            <template slot="option" slot-scope="props">
              {{ $t(`attributes.customisation.featuresList.${props.option}`) }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  {{ $t(`attributes.customisation.featuresList.${option}`) }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="gutter-x-xs">
        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <!-- Global buttons -->
  <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
    <div></div>
    <div>
      <!-- TODO: export button -->
      <!-- New button -->
      <router-link :to="{ name: 'newMultiproductWidgetCustomisation' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newCustomisation') }}
      </router-link>
    </div>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="multiproductWidgetCustomisationsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="multiproductWidgetCustomisationsLoading">
        <thead>
          <tr>
            <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.customisation.id') }}</th-sortable>
            <th-sortable sort-key="name">{{ $t('attributes.customisation.name') }}</th-sortable>
            <th-sortable sort-key="group_id">{{ $t('attributes.customisation.group') }}</th-sortable>
            <th class="th-shrink">{{ $t('attributes.customisation.default') }}</th>
            <th class="th-shrink">{{ $t('attributes.customisation.domains') }}</th>
            <th class="th-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="multiproductWidgetCustomisationsError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="multiproductWidgetCustomisationsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!multiproductWidgetCustomisationsLoading && multiproductWidgetCustomisations.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noCustomisation') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="multiproductWidgetCustomisation in multiproductWidgetCustomisations" :key="multiproductWidgetCustomisation.id">
              <th>{{ multiproductWidgetCustomisation.id }}</th>
              <td>{{ multiproductWidgetCustomisation.name }}</td>
              <td class="text-nowrap">
                <router-link :to="{ name: 'group', params: { id: multiproductWidgetCustomisation.group.id } }">
                  <region-flag :code="multiproductWidgetCustomisation.group.region.code" />
                  {{ multiproductWidgetCustomisation.group.name }}
                </router-link>
              </td>
              <td class="text-center"><dot :active="multiproductWidgetCustomisation.default" /></td>
              <td class="text-center">{{ multiproductWidgetCustomisation.domains.length }}</td>
              <td>
                <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                  <template v-slot:button-content>
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                  </template>
                  <router-link :to="{ name: 'multiproductWidgetCustomisation', params: { id: multiproductWidgetCustomisation.id } }" class="dropdown-item">
                    {{ $t('shared.actions.show') }}
                  </router-link>
                  <router-link :to="{ name: 'editMultiproductWidgetCustomisation', params: { id: multiproductWidgetCustomisation.id } }" class="dropdown-item">
                    {{ $t('shared.actions.edit') }}
                  </router-link>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />
</div>
