
<div>
  <!-- Filters -->
  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-region">{{ $t('attributes.importTracker.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.importTracker.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>

        <!-- TODO: user filter -->
      </div>

      <div class="gutter-x-xs">
        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <!-- Global buttons -->
  <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
    <div></div>
    <div>
      <!-- New button -->
      <router-link :to="{ name: 'newImportTracker' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newImportTracker') }}
      </router-link>
    </div>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="importTrackersLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="importTrackersLoading">
        <thead>
          <tr>
            <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.importTracker.id') }}</th-sortable>
            <th>{{ $t('attributes.importTracker.createdAt') }}</th>
            <th-sortable sort-key="user_id">{{ $t('attributes.importTracker.user') }}</th-sortable>
            <th class="th-shrink">{{ $t('attributes.importTracker.products') }}</th>
            <th class="th-shrink">{{ $t('attributes.importTracker.widgets') }}</th>
            <th class="th-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="importTrackersError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="importTrackersError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!importTrackersLoading && importTrackers.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noImportTracker') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="importTracker in importTrackers" :key="importTracker.id">
              <th>{{ importTracker.id }}</th>
              <td>{{ importTracker.createdAt | date }}</td>
              <td>
                <template v-if="importTracker.user">
                  <router-link :to="{ name: 'user', params: { id: importTracker.user.id } }">
                    {{ importTracker.user.email }}
                  </router-link>
                </template>
              </td>
              <td class="text-center">{{ importTracker.productsCount }}</td>
              <td class="text-center">{{ importTracker.widgetsCount }}</td>
              <td>
                <router-link :to="{ name: 'importTracker', params: { id: importTracker.id } }" class="btn btn-primary btn-sm text-nowrap">
                  {{ $t('shared.actions.show') }}
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                </router-link>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />
</div>
