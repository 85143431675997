import WidgetModalSelect from './WidgetModalSelect.vue'
import CustomisationSelect from './CustomisationSelect.vue'
import Vue from 'vue'
import router from '../../router'
import i18n from '../../i18n'

export default function c2bWidgetPlugin(editor, options) {
  // Trait for widget selection
  editor.Traits.addType('widget', {
    createInput({ component }) {
      // Get widget key from component attributes
      const widgetKey = component.getAttributes()['data-widget-id'] || ''
      const vueInst = new Vue({
        router,
        i18n,
        render: h => h(WidgetModalSelect, {
          props: {
            groupId: options.groupId(),
            initialWidgetKey: widgetKey
          }
        })
      }).$mount()
      const selectInst = vueInst.$children[0]
      selectInst.$on('input', ev => { this.onChange(ev) })
      return vueInst.$el
    },
    onEvent({ event, component }) {
      component.addAttributes({ 'data-widget-id': event.key })
    }
  })

  // Trait for customisation selection
  editor.Traits.addType('customisation', {
    createInput({ component }) {
      // Get customisation id from component attributes
      const customisationId = component.getAttributes()['data-widget-customisation-id'] || null
      const vueInst = new Vue({
        router,
        i18n,
        render: h => h(CustomisationSelect, {
          props: {
            groupId: options.groupId(),
            initialCustomisationId: customisationId
          }
        })
      }).$mount()
      const selectInst = vueInst.$children[0]
      selectInst.$on('input', ev => { this.onChange(ev) })
      return vueInst.$el
    },
    onEvent({ event, component }) {
      if (event) {
        component.addAttributes({ 'data-widget-customisation-id': event.id })
      } else {
        component.removeAttributes('data-widget-customisation-id')
      }
    }
  })

  // Js script for widget component
  const widgetScript = function() {
    // If the widget key is set, initialize the widget
    if (this.getAttribute('data-widget-id') && window.c2bWidget) {
      window.c2bWidget.init()
    }

    // Else observe the container for data-widget-id change

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, attributeFilter: ['data-widget-id'], attributeOldValue: true }

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          if (!mutation.oldValue && window.c2bWidget) {
            window.c2bWidget.init()
          }
        }
      }
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(this, config)
  }

  // Component for widget (inline auto open)
  editor.Components.addType('c2b-widget', {
    isComponent: el => el.tagName === 'DIV' && el.getAttribute('data-inline-container-auto-open') === '',
    model: {
      defaults: {
        script: widgetScript,
        traits: [
          {
            type: 'widget',
            name: 'data-widget-id',
            label: 'Widget'
          },
          {
            type: 'customisation',
            name: 'data-widget-customisation-id',
            label: 'Customisation widget'
          }
        ]
      }
    }
  })

  // Block for widget
  editor.BlockManager.add('widget', {
    id: 'widget',
    label: 'C2B Widget',
    category: 'C2B',
    type: 'c2b-widget',
    media: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"><path fill="currentColor" d="M8 14h11v2H8Zm0 5h13v2H8Z"/><path fill="currentColor" d="M28 4H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 2v2H4V6ZM4 26V10h24v16Z"/></svg>',
    content: '<div data-inline-container-auto-open></div>',
    select: true
  })

  // Add property for cursor
  editor.on('load', () => {
    editor.StyleManager.addProperty('extra', {
      label: 'Cursor',
      property: 'cursor',
      type: 'select',
      default: 'default',
      options: [
        { id: 'default', label: 'default' },
        { id: 'pointer', label: 'pointer' },
        { id: 'crosshair', label: 'crosshair' },
        { id: 'move', label: 'move' },
        { id: 'text', label: 'text' }
      ]
    }, { at: 1 })
  })

  // Js script for widget button component
  const widgetButtonScript = function() {
    // If the widget key is set, initialize the widget
    if (this.getAttribute('data-widget-id') && window.c2bWidget) {
      window.c2bWidget.init()
    }

    // Else observe the container for data-widget-id change

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, attributeFilter: ['data-widget-id'], attributeOldValue: true }

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          if (!mutation.oldValue && window.c2bWidget) {
            window.c2bWidget.init()
          }
        }
      }
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(this, config)

    // Override the default addEventListener to disable callback if body has a specific class
    this.addEventListener = function(type, callback, options) {
      const initialCallback = callback
      const newCallback = function() {
        if (document.body.classList.contains('c2b-widget-lock')) {
          return
        }
        initialCallback()
      }

      return EventTarget.prototype.addEventListener.apply(this, [type, newCallback, options])
    }
  }

  // Component for widget button
  editor.Components.addType('c2b-widget-button', {
    isComponent: el => el.tagName === 'DIV' && el.getAttribute('data-widget-element') === '',
    model: {
      defaults: {
        script: widgetButtonScript,
        traits: [
          {
            type: 'widget',
            name: 'data-widget-id',
            label: 'Widget'
          },
          {
            type: 'customisation',
            name: 'data-widget-customisation-id',
            label: 'Customisation widget'
          }
        ]
      }
    }
  })

  // Block for widget button
  editor.BlockManager.add('widget-button', {
    id: 'widget-button',
    label: 'C2B Widget Button',
    category: 'C2B',
    type: 'c2b-widget-button',
    media: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"><path fill="currentColor" d="M9 15h14v2H9z"/><path fill="currentColor" d="M28 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2M4 12v8h24v-8Z"/></svg>',
    content: '<div data-widget-element style="cursor: pointer;"><div style="display: inline-block; background-color: #ea5631; color: #fff; border-radius: 5px; padding: 0.5rem 1rem;">Where to buy?</div></div>',
    select: true
  })

  // Add button to actions panel to toggle iframe pointer-events
  editor.Panels.addButton('options', {
    id: 'toggle-c2b-widget-lock',
    command: 'toggle-c2b-widget-lock',
    label: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path fill="currentColor" d="M17 8V7c0-2.757-2.243-5-5-5S7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2H9V7c0-1.654 1.346-3 3-3s3 1.346 3 3v1zm1 4l.002 8H6v-8z"/></svg>'
  })

  // Add command to toggle interactions with widgets
  editor.Commands.add('toggle-c2b-widget-lock', {
    run: editor => {
      editor.Canvas.getBody().classList.remove('c2b-widget-lock')
    },
    stop: editor => {
      editor.Canvas.getBody().classList.add('c2b-widget-lock')
    }
  })

  // Add CSS for placeholder & disable click events
  editor.config.canvasCss += `
    /* Display a placeholder only in the editor */
    [data-inline-container-auto-open]:not([data-widget-id])::after {
      content: 'Please select the widget to display here in the attributes menu on the right.';
      display: block;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #cb5a5e;
      color: #cb5a5e;
    }

    /* Display widget button even if it has display none */
    [data-widget-element] {
      display: block !important;
    }

    /* Display a placeholder if no widget is selected */
    [data-widget-element]:not([data-widget-id]) {
      border: 2px dashed #cb5a5e;
    }
    [data-widget-element]:not([data-widget-id])::after {
      content: 'Please select the widget to open in the attributes menu on the right.';
      display: block;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #cb5a5e;
    }

    /* Disable click events inside widget iframe */
    .c2b-widget-lock div[data-widget-id] > iframe {
      pointer-events: none;
    }
  `
  // Add class by default
  editor.on('load', () => {
    editor.Canvas.getBody().classList.add('c2b-widget-lock')
  })
}
