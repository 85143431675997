
<div>
  <!-- Filters -->
  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.group.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            :placeholder="$t('attributes.group.name')">
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.group.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.group.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.michelinOffer.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>
      </div>

      <div class="gutter-x-xs">
        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <!-- Global buttons -->
  <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
    <div></div>
    <div>
      <!-- New button -->
      <router-link :to="{ name: 'newMichelinOffer' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newMichelinOffer') }}
      </router-link>
    </div>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="michelinOffersLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="michelinOffersLoading">
        <thead>
          <tr>
            <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.michelinOffer.id') }}</th-sortable>
            <th class="table-col-shrink"></th>
            <th class="table-col-shrink"></th>
            <th-sortable sort-key="name">{{ $t('attributes.michelinOffer.name') }}</th-sortable>
            <th class="th-shrink">{{ $t('attributes.michelinOffer.dateRange') }}</th>
            <th-sortable sort-key="group_id" class="th-shrink">{{ $t('attributes.michelinOffer.group') }}</th-sortable>
            <th-sortable sort-key="retailer_id" class="th-shrink">{{ $t('attributes.michelinOffer.retailer') }}</th-sortable>
            <th class="th-shrink">{{ $t('attributes.michelinOffer.products') }}</th>
            <th class="th-shrink">{{ $t('attributes.michelinOffer.michelinWidgetCustomisations') }}</th>
            <th class="table-col-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="michelinOffersError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="michelinOffersError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-if="!michelinOffersLoading && michelinOffers.length === 0">
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noOffer') }}
              </td>
            </tr>
            <tr v-else v-for="michelinOffer in michelinOffers" :key="michelinOffer.id">
              <th>{{ michelinOffer.id }}</th>
              <td class="text-center">
                <dot :active="michelinOffer.active" />
              </td>
              <td :id="`michelinOffer-${michelinOffer.id}-img`">
                <span v-if="michelinOffer.imgUrl">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                  <b-tooltip :target="`michelinOffer-${michelinOffer.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                    <img :src="michelinOffer.imgUrl" style="max-width: 250px; max-height: 250px;" />
                  </b-tooltip>
                </span>
                <span v-else class="text-danger">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                </span>
              </td>
              <td>{{ michelinOffer.name }}</td>
              <td class="text-nowrap">{{ michelinOffer.startDate | date }} - {{ michelinOffer.endDate | date }}</td>
              <td class="text-nowrap">
                <router-link :to="{ name: 'group', params: { id: michelinOffer.group.id } }">
                  <region-flag :code="michelinOffer.group.region.code" />
                  {{ michelinOffer.group.name }}
                </router-link>
              </td>
              <td class="text-nowrap">
                <div class="gutter-x-xs">
                  <region-flag :code="michelinOffer.retailer.region.code" />
                  <img :src="michelinOffer.retailer.imgSmallUrl" style="max-height: 20px;">
                  {{ michelinOffer.retailer.name }}
                </div>
              </td>
              <td class="text-nowrap">
                <template v-if="michelinOffer.allProducts">
                  {{ $t('attributes.michelinOffer.allProducts') }}
                </template>
                <template v-else>
                  {{ michelinOffer.productsCount }}
                </template>
              </td>
              <td class="text-nowrap">
                <span :id="`michelinOffer-${michelinOffer.id}-michelin-widget-customisations`">{{ michelinOffer.michelinWidgetCustomisations.length }}</span>
                <b-tooltip
                  v-if="michelinOffer.michelinWidgetCustomisations.length > 0"
                  :target="`michelinOffer-${michelinOffer.id}-michelin-widget-customisations`"
                  boundary="viewport"
                  triggers="hover click"
                  custom-class="tooltip-lg">
                  <ul class="list-unstyled mb-0">
                    <li
                      v-for="michelinWidgetCustomisation in michelinOffer.michelinWidgetCustomisations"
                      :key="michelinWidgetCustomisation.id">
                      {{ michelinWidgetCustomisation.name }}
                    </li>
                  </ul>
                </b-tooltip>
              </td>
              <td>
                <router-link
                  :to="{ name: 'editMichelinOffer', params: { id: michelinOffer.id } }"
                  class="btn btn-sm btn-warning">
                  {{ $t('shared.actions.edit') }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />
</div>
