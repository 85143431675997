
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError && eventAggregatesError.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- First row with date range picker & export button -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Empty left col -->
    <div class="flex-fill flex-basis-0"></div>

    <!-- Date range picker -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Export button -->
    <div class="flex-fill flex-basis-0 text-right">
      <button @click="exportXLSX" type="button" class="btn btn-primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </div>
  </div>

  <div v-if="!groupsInitialized" class="text-center">
    <md-spinner md-indeterminate></md-spinner>
  </div>
  <!-- Pivot -->
  <pivot
    v-else
    :fields="fields"
    :available-field-keys="availableFieldKeys"
    :row-field-keys="rowFieldKeys"
    :col-field-keys="colFieldKeys"
    :data="eventAggregatesWithZeroes"
    :is-data-loading="globalLoading"
    :reducer="reducer"
    :available-fields-label-text="$t('views.stats.abTests.availableFieldsLabelText')"
    :rows-label-text="$t('views.stats.abTests.rowsLabelText')"
    :cols-label-text="$t('views.stats.abTests.colsLabelText')"
    :hide-settings-text="$t('views.stats.abTests.hideSettings')"
    :show-settings-text="$t('views.stats.abTests.showSettings')"
    :no-data-warning-text="$t('views.stats.abTests.noData')">
    <!-- Action labels -->
    <template v-slot:action="{ value }">
      <template v-if="actions.includes(value)">
        <div class="text-nowrap" :style="userIsAdmin ? 'padding-right: 20px;' : ''">
          {{ $t(`shared.eventActions.widget.${value}`) }}
          <template v-if="value === 'validation'">
            <div v-if="userIsAdmin" class="shield-wrapper text-warning">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        {{ value || '–' }}
      </template>
    </template>

    <!-- Retailer labels -->
    <template v-slot:retailer="{ value }">
      <div style="min-width: 120px;">
        <template v-if="retailersMap.get(value)">
          <div class="d-flex align-items-center">
            <img :src="retailersMap.get(value).imgSmallUrl" class="td-img-sm" style="padding-right: 8px" v-if="retailersMap.get(value)"/>
            {{ retailersMap.get(value).name }}
          </div>
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </div>
    </template>

    <!-- Widget labels -->
    <template v-slot:widgetProductName="{ value }">
      <template v-if="widgetsMap.get(value)">
        <div class="d-flex justify-content-between align-items-center" style="min-width: 200px;">
          <div>
            {{ widgetsMap.get(value).product.productLanguageDatas[0].name }}
          </div>
          <div v-if="userIsAdmin">
            <router-link :to="{ name: 'widget', params: { id: widgetsMap.get(value).id } }" class="text-light">
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16"><path fill="currentColor" d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" class=""></path></svg>
            </router-link>
          </div>
        </div>
      </template>
      <span v-else-if="value" class="text-danger">
        Widget #{{ value }}
      </span>
      <template v-else>–</template>
    </template>
    <template v-slot:widgetProductRawPackaging="{ value }">
      <small v-if="widgetsMap.get(value)" class="text-nowrap">{{ widgetsMap.get(value).product.productLanguageDatas[0].rawPackaging || '–' }}</small>
      <small v-else>–</small>
    </template>
    <template v-slot:widgetProductReference="{ value }">
      <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.reference || '–' }}</small>
      <small v-else>–</small>
    </template>
    <template v-slot:widgetProductEan="{ value }">
      <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.ean || '–' }}</small>
      <small v-else>–</small>
    </template>
    <template v-slot:widgetProductUpc="{ value }">
      <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.upc || '–' }}</small>
      <small v-else>–</small>
    </template>

    <!-- Device labels -->
    <template v-slot:device="{ value }">
      <div class="text-nowrap">
        <span :style="{ color: deviceColor(value) }">
          <svg v-if="value === 'desktop'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-fw"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
          <svg v-else-if="value === 'mobile'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-mobile-alt fa-fw"><path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
          <svg v-else-if="value === 'tablet'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tablet-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-tablet-alt fa-fw"><path fill="currentColor" d="M400 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM224 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm176-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h328c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
          <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-fw"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
        </span>
        {{ value ? value : 'unknown' | capitalize }}
      </div>
    </template>

    <!-- Tracking params labels -->
    <template
      v-for="trackingParam in trackingParams"
      v-slot:[`tracking_param_${trackingParam}`]
    >
      <span class="text-nowrap" :key="trackingParam">
        {{ trackingParam }}
        <sup v-b-tooltip.hover :title="$t('views.stats.pivotTable.tooltip.trackingParams')">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.125rem" height="1.125rem" viewBox="0 0 32 32"><path fill="currentColor" d="M24 21V9h-2v14h8v-2zm-4-6v-4c0-1.103-.897-2-2-2h-6v14h2v-6h1.48l2.335 6h2.145l-2.333-6H18c1.103 0 2-.897 2-2m-6-4h4v4h-4zM8 23H4c-1.103 0-2-.897-2-2V9h2v12h4V9h2v12c0 1.103-.897 2-2 2"/></svg>
        </sup>
      </span>
    </template>

    <!-- A/B test labels -->
    <template v-slot:ab-test="{ value }">
      <template v-if="abTestsMap.get(value)">
        {{ abTestsMap.get(value).name }}
      </template>
      <template v-else>
        {{ value || '–' }}
      </template>
    </template>
    <template v-slot:variant="{ value }">
      <template v-if="variantsMap.get(value)">
        {{ variantsMap.get(value).name }}
      </template>
      <template v-else>
        {{ value || '–' }}
      </template>
    </template>
    <template v-slot:customisation="{ value }">
      <template v-if="customisationsMap.get(value)">
        {{ customisationsMap.get(value).name }}
      </template>
      <template v-else>
        {{ value || '–' }}
      </template>
    </template>
    <template v-slot:michelinWidgetCustomisation="{ value }">
      <template v-if="michelinWidgetCustomisationsMap.get(value)">
        {{ michelinWidgetCustomisationsMap.get(value).name }}
      </template>
      <template v-else>
        {{ value || '–' }}
      </template>
    </template>

    <!-- Shared slot for simple labels/filters -->
    <template v-slot:simpleValue="{ value }">
      <template v-if="value === undefined || value === null">
        <code style="word-break: normal;">{{ String(value) }}</code>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>

    <!-- Value slot -->
    <template v-slot:value="{ value }">
      {{ value | number }}
    </template>

    <!-- Loading slot -->
    <template v-slot:loading>
      <div class="text-center">
        <md-spinner md-indeterminate />
      </div>
    </template>
  </pivot>
</div>
