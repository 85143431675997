
<div>
  <b-tabs v-model="tabIndex" content-class="mt-3">
    <!-- File selection -->
    <b-tab :title="$t('views.michelinImportMsrp.form.tabs.fileSelection')">
      <form @submit.prevent="submit({ preview: true })">
        <div class="form-group row">
          <label for="file-input" class="col-sm-2 col-form-label">{{ $t('attributes.michelinImportMsrp.file') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <b-form-file
              v-model="input.file"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
              :state="fieldsError.includes('file') ? false : null"
              id="file-input"
            />
          </div>

          <div class="col-lg-6 col-sm-4 col-12">
            <button type="button" class="btn btn-secondary" @click="downloadXlsxTemplate">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-csv" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-csv fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
              {{ $t('shared.actions.downloadXlsxTemplate') }}
            </button>
          </div>
        </div>

        <div class="form-group row">
          <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.michelinImportMsrp.group') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <group-modal-select
              v-model="input.group"
              languages
              id="multiselect-group"
              :is-invalid="fieldsError.includes('groupId')"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="reference-type" class="col-sm-2 col-form-label">{{ $t('attributes.michelinImportMsrp.productReferenceType') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <multiselect
              v-model="input.productReferenceType"
              :options="productReferenceTypes"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              id="reference-type"
              :class="{ 'is-invalid': fieldsError.includes('productReferenceType') }"
            >
            </multiselect>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="importLoading">
            <md-spinner v-if="importLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            {{ $t('views.michelinImportMsrp.form.actions.checkFile') }}
          </button>
        </div>
      </form>
    </b-tab>

    <!-- Summary before submit -->
    <b-tab :title="$t('views.michelinImportMsrp.form.tabs.summary')" :disabled="importErrors !== null || summary == null">
      <form @submit.prevent="submit">
        <p>{{ $t('views.michelinImportMsrp.form.summary.description') }}</p>

        <table v-if="summary != null" class="table">
          <tr>
            <th>{{ $t('views.michelinImportMsrp.form.summary.updated') }}</th>
            <td>{{ summary.updated + summary.updated_from_nil }}</td>
          </tr>
          <tr>
            <th>{{ $t('views.michelinImportMsrp.form.summary.skipped') }}</th>
            <td>{{ summary.skipped }}</td>
          </tr>
          <tr>
            <th>{{ $t('views.michelinImportMsrp.form.summary.removed') }}</th>
            <td>{{ summary.removed_msrp + summary.removed_msrp_not_in_file }}</td>
          </tr>
          <tr>
            <th>
              <div
                class="pointer"
                @click="summaryExpanded = !summaryExpanded"
              >
                <svg v-if="summaryExpanded" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10 fa-2x fa-fw"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6 fa-2x fa-fw"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>

                {{ $t('views.michelinImportMsrp.form.summary.productNotFound') }}
              </div>

              <textarea v-if="summaryExpanded" readonly :value="summaryReferenceNotFound" class="mt-1 form-control" style="resize: none;" rows="8"></textarea>
            </th>
            <td>{{ summary.reference_not_found_length }}</td>
          </tr>
          <tr>
            <th>{{ $t('views.michelinImportMsrp.form.summary.referenceNotFound') }}</th>
            <td>{{ summary.no_reference_in_file }}</td>
          </tr>
        </table>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="submitLoading || summary == null">
            <md-spinner v-if="submitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            {{ $t('shared.submit.import') }}
          </button>
        </div>
      </form>
    </b-tab>

    <b-tab :title="$t('views.michelinImportMsrp.form.tabs.result')" :disabled="submitErrors !== null || result == null">
      <table v-if="result != null" class="table">
        <tr>
          <th>{{ $t('views.michelinImportMsrp.form.summary.updated') }}</th>
          <td>{{ result.updated + result.updated_from_nil }}</td>
        </tr>
        <tr>
          <th>{{ $t('views.michelinImportMsrp.form.summary.skipped') }}</th>
          <td>{{ result.skipped }}</td>
        </tr>
        <tr>
          <th>{{ $t('views.michelinImportMsrp.form.summary.removed') }}</th>
          <td>{{ result.removed_msrp + result.removed_msrp_not_in_file }}</td>
        </tr>
        <tr>
          <th>
            <div
              class="pointer"
              @click="resultExpanded = !resultExpanded"
            >
              <svg v-if="resultExpanded" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10 fa-2x fa-fw"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6 fa-2x fa-fw"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>

              {{ $t('views.michelinImportMsrp.form.summary.productNotFound') }}
            </div>

            <textarea v-if="resultExpanded" readonly :value="resultReferenceNotFound" class="mt-1 form-control" style="resize: none;" rows="8"></textarea>
          </th>
          <td>{{ summary.reference_not_found_length }}</td>
        </tr>
        <tr>
          <th>{{ $t('views.michelinImportMsrp.form.summary.referenceNotFound') }}</th>
          <td>{{ result.no_reference_in_file }}</td>
        </tr>
      </table>

      <button type="button" class="btn btn-primary" :disabled="result == null" @click="back()">
        {{ $t('shared.actions.back') }}
      </button>
    </b-tab>
  </b-tabs>
</div>
