
<div>
  <transition name="fade" mode="out-in">
    <div v-if="importTrackerLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="importTrackerError" class="alert alert-danger">
      <template v-if="importTrackerError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-6">
          <table class="table">
            <tbody>
              <tr>
                <th>{{ $t('attributes.importTracker.createdAt') }}</th>
                <td>{{ importTracker.createdAt | date }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.importTracker.user') }}</th>
                <td>
                  <template v-if="importTracker.user">
                    <router-link :to="{ name: 'user', params: { id: importTracker.user.id } }">
                      {{ importTracker.user.email }}
                    </router-link>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6 text-right">
          <div class="gutter-x-xs">
            <!-- Import images button -->
            <router-link :to="{ name: 'importTrackerImages', params: { id: $route.params.id } }" class="btn btn-primary">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
              {{ $t('shared.actions.importImages') }}
            </router-link>

            <!-- Export button -->
            <button type="button" class="btn btn-secondary" v-b-modal.export-modal>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
              {{ $t('shared.actions.xlsxExport') }}
            </button>
          </div>
        </div>
      </div>

      <b-tabs content-class="mt-3">
        <!-- Products -->
        <b-tab>
          <template v-slot:title>
            {{ $t('attributes.importTracker.products') }}
            <span class="badge badge-primary">
              {{ importTracker.products.length }}
            </span>
          </template>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.product.id') }}</th>
                  <th class="table-col-shrink"></th>
                  <th class="table-col-shrink">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                  </th>
                  <th>{{ $t('attributes.productLanguageData.name') }}</th>
                  <th class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                  <th>{{ $t('attributes.product.reference') }}</th>
                  <th>{{ $t('attributes.product.ean') }}</th>
                  <th>{{ $t('attributes.product.upc') }}</th>
                  <th class="ellipsis" style="max-width: 60px;">{{ $t('attributes.product.declensions') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.product.widget') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.product.group') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in importTracker.products" :key="product.id">
                  <th>{{ product.id }}</th>
                  <td class="text-center">
                    <dot :active="product.active" />
                  </td>
                  <td :id="`product-${product.id}-img`">
                    <span v-if="product.hasImg">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                      <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                        <img :src="product.img.medium.url" style="height: 200px;" />
                      </b-tooltip>
                    </span>
                    <span v-else class="text-danger">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </span>
                  </td>
                  <td class="ellipsis" style="max-width: 200px;">
                    {{ product.productLanguageDatas[0].name }}
                  </td>
                  <td class="ellipsis" style="max-width: 120px;">
                    <small>{{ product.productLanguageDatas[0].rawPackaging }}</small>
                  </td>
                  <td><small>{{ product.reference }}</small></td>
                  <td><small>{{ product.ean }}</small></td>
                  <td><small>{{ product.upc }}</small></td>
                  <td class="text-center">
                    {{ product.declensions_count }}
                  </td>
                  <td class="text-nowrap">
                    <template v-if="product.widget">
                      <router-link :to="{ name: 'widget', params: { id: product.widget.id } }" class="small">
                        {{ product.widget.key }}
                      </router-link>
                    </template>
                  </td>
                  <td class="ellipsis" style="max-width: 150px;">
                    <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                      <region-flag :code="product.group.region.code" />
                      {{ product.group.name }}
                    </router-link>
                  </td>
                  <td>
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                      </template>
                      <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <!-- Widgets -->
        <b-tab>
          <template v-slot:title>
            {{ $t('attributes.importTracker.widgets') }}
            <span class="badge badge-primary">
              {{ importTracker.widgets.length }}
            </span>
          </template>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.widget.id') }}</th>
                  <th class="table-col-shrink"></th>
                  <th class="table-col-shrink"></th>
                  <th>{{ $t('attributes.widget.name') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.widget.group') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.widget.product') }}</th>
                  <th class="table-col-shrink">{{ $t('attributes.widget.key') }}</th>
                  <th class="table-col-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="widget in importTracker.widgets" :key="widget.id">
                  <th>{{ widget.id }}</th>
                  <td class="text-center">
                    <dot :active="widget.active" />
                  </td>
                  <td :id="`widget-${widget.id}-img`">
                    <template v-if="widget.product && widget.product.img && widget.product.img.url">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                      <b-tooltip :target="`widget-${widget.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                        <img :src="widget.product.img.medium.url" style="height: 200px;" />
                      </b-tooltip>
                    </template>
                  </td>
                  <td class="ellipsis" style="max-width: 200px;">
                    {{ widget.name }}
                  </td>
                  <td class="ellipsis" style="max-width: 150px;">
                    <template v-if="widget.product">
                      <router-link :to="{ name: 'group', params: { id: widget.group.id } }">
                        <region-flag :code="widget.group.region.code" />
                        {{ widget.group.name }}
                      </router-link>
                    </template>
                  </td>
                  <td class="ellipsis" style="max-width: 150px;">
                    <template v-if="widget.product">
                      <router-link :to="{ name: 'product', params: { id: widget.product.id } }">
                        {{ widget.product.productLanguageDatas[0].name }}
                      </router-link>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <code>{{ widget.key }}</code>
                  </td>
                  <td>
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                      </template>
                      <router-link :to="{ name: 'widget', params: { id: widget.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link :to="{ name: 'editWidget', params: { id: widget.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>

      <!-- Export modal -->
      <b-modal
        id="export-modal"
        size="sm"
        title="Select attributes..."
        scrollable
        no-fade>
        <div class="form-group text-center">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-secondary" @click="selectAllExportAttributes">Select all</button>
            <button type="button" class="btn btn-secondary" @click="unselectAllExportAttributes">Unselect all</button>
          </div>
        </div>
        <template v-for="(attributeValue, attribute) in selectedExportAttributes">
          <div :key="attribute" class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                v-model="selectedExportAttributes[attribute]"
                type="checkbox" class="custom-control-input" :id="`export-attribute-${attribute}`">
              <label class="custom-control-label" :for="`export-attribute-${attribute}`">
                {{ attribute }}
              </label>
            </div>
          </div>
        </template>
        <template v-slot:modal-footer="{ close }">
          <a :href="exportUrl" class="btn btn-primary" @click="close()">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
            {{ $t('shared.actions.xlsxExport') }}
          </a>
        </template>
      </b-modal>
    </div>
  </transition>
</div>
