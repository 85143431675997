
<div>
  <transition name="fade" mode="out-in">
    <div v-if="oneClickLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="oneClickError" class="alert alert-danger">
      <template v-if="oneClickError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="form-group row">
          <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="oneClick.active" type="checkbox" class="custom-control-input" id="active-checkbox">
              <label class="custom-control-label" for="active-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="all-groups-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allGroups') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="oneClick.allGroups" type="checkbox" class="custom-control-input" id="all-groups-checkbox">
              <label class="custom-control-label" for="all-groups-checkbox"></label>
            </div>
            <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.allGroups" class="invalid-feedback d-block">{{ tErrors('oneClick', 'allGroups', oneClickSubmitErrors.allGroups) }}</div>
          </div>
        </div>

        <div class="form-group row" v-if="!oneClick.allGroups">
          <label for="multiselect-groups" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.group') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <group-modal-select
              v-model="oneClick.group"
              :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.group }"
              id="multiselect-group" />
            <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('oneClick', 'group', oneClickSubmitErrors.group) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="all-retailers-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allRetailers') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="oneClick.allRetailers" type="checkbox" class="custom-control-input" id="all-retailers-checkbox">
              <label class="custom-control-label" for="all-retailers-checkbox"></label>
            </div>
            <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.allRetailers" class="invalid-feedback d-block">{{ tErrors('oneClick', 'allRetailers', oneClickSubmitErrors.allRetailers) }}</div>
          </div>
        </div>

        <div class="form-group row" v-if="!oneClick.allRetailers">
          <label for="multiselect-retailers" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.retailer') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <key-multiselect
              v-model="oneClick.retailerId"
              :multiple="false"
              :options="retailers"
              :loading="retailersLoading"
              :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.retailer }"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false">
              <template v-slot:option="{ option }">
                <div class="gutter-x-xs">
                  <region-flag :code="option.region.code" />
                  <img :src="option.img_small_url" style="max-height: 20px;">
                  {{ option.name }}
                  <span class="badge badge-light">
                    {{ $t(`shared.retailerDistributions.${option.service}`) }}
                  </span>
                </div>
              </template>
              <template v-slot:singleLabel="{ option }">
                <div class="gutter-x-xs">
                  <region-flag :code="option.region.code" />
                  <img :src="option.img_small_url" style="max-height: 20px;">
                  {{ option.name }}
                  <span class="badge badge-light">
                    {{ $t(`shared.retailerDistributions.${option.service}`) }}
                  </span>
                </div>
              </template>
            </key-multiselect>
            <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.retailer" class="invalid-feedback d-block">{{ tErrors('oneClick', 'retailer', oneClickSubmitErrors.retailer) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="priority-checkbox" class="col-sm-2">
            <div>{{ $t('attributes.oneClick.priority') }}</div>
          </label>
          <div class="col col-auto">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="oneClick.priority" type="checkbox" class="custom-control-input" id="priority-checkbox">
              <label class="custom-control-label" for="priority-checkbox"></label>
            </div>
            <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.priority" class="invalid-feedback d-block">{{ tErrors('oneClick', 'priority', oneClickSubmitErrors.priority) }}</div>
          </div>
          <small class="col text-muted">{{ $t('attributes.oneClick.priorityHelp') }}</small>
        </div>

        <hr>

        <div class="form-group row">
          <label for="expert-mode-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.expertMode') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="oneClick.expertMode" type="checkbox" class="custom-control-input" id="expert-mode-checkbox">
              <label class="custom-control-label" for="expert-mode-checkbox"></label>
            </div>
          </div>
        </div>

        <!-- Basic mode -->
        <template v-if="!oneClick.expertMode">
          <!-- Colors -->
          <div class="form-group row">
            <label for="input-colors-main-text" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.main_text') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="oneClick.colors.main_text" type="text" class="form-control" :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.colors && oneClickSubmitErrors.colors.main_text }" id="input-colors-main-text" :placeholder="$t('attributes.oneClick.colors.main_text')">
            </div>
          </div>

          <div class="form-group row">
            <label for="input-colors-loader" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.loader') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="oneClick.colors.loader" type="text" class="form-control" :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.colors && oneClickSubmitErrors.colors.loader }" id="input-colors-loader" :placeholder="$t('attributes.oneClick.colors.loader')">
            </div>
          </div>

          <!-- Images -->
          <div class="form-group row">
            <label for="retailer-logo-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.retailerLogo') }}</label>
            <div class="col-sm-10 col-12">
              <template v-if="oneClick.retailerLogo.url">
                <div class="border mb-3 d-inline-block">
                  <img :src="oneClick.retailerLogo.url" class="img-fluid">
                </div>
                <div class="mb-3">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="oneClick.removeRetailerLogo" type="checkbox" class="custom-control-input" id="remove-retailer-logo-checkbox">
                    <label class="custom-control-label" for="remove-retailer-logo-checkbox">
                      {{ $t('attributes.oneClick.removeRetailerLogo') }}
                    </label>
                  </div>
                </div>
              </template>
              <b-form-file
                v-model="oneClick.retailerLogoFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="retailer-logo-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.retailerLogo" class="invalid-feedback d-block">{{ tErrors('oneClick', 'retailerLogo', oneClickSubmitErrors.retailerLogo) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="background-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.background') }}</label>
            <div class="col-sm-10 col-12">
              <template v-if="oneClick.background.url">
                <div class="border mb-3 d-inline-block">
                  <img :src="oneClick.background.url" class="img-fluid">
                </div>
                <div class="mb-3">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="oneClick.removeBackground" type="checkbox" class="custom-control-input" id="remove-background-checkbox">
                    <label class="custom-control-label" for="remove-background-checkbox">
                      {{ $t('attributes.oneClick.removeBackground') }}
                    </label>
                  </div>
                </div>
              </template>
              <b-form-file
                v-model="oneClick.backgroundFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="background-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.background" class="invalid-feedback d-block">{{ tErrors('oneClick', 'background', oneClickSubmitErrors.background) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="background-mobile-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.backgroundMobile') }}</label>
            <div class="col-sm-10 col-12">
              <template v-if="oneClick.backgroundMobile.url">
                <div class="border mb-3 d-inline-block">
                  <img :src="oneClick.backgroundMobile.url" class="img-fluid">
                </div>
                <div class="mb-3">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="oneClick.removeBackgroundMobile" type="checkbox" class="custom-control-input" id="remove-background-mobile-checkbox">
                    <label class="custom-control-label" for="remove-background-mobile-checkbox">
                      {{ $t('attributes.oneClick.removeBackgroundMobile') }}
                    </label>
                  </div>
                </div>
              </template>
              <b-form-file
                v-model="oneClick.backgroundMobileFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="background-mobile-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.backgroundMobile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'backgroundMobile', oneClickSubmitErrors.backgroundMobile) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="banner-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.banner') }}</label>
            <div class="col-sm-10 col-12">
              <template v-if="oneClick.banner.url">
                <div class="border mb-3 d-inline-block">
                  <img :src="oneClick.banner.url" class="img-fluid">
                </div>
                <div class="mb-3">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="oneClick.removeBanner" type="checkbox" class="custom-control-input" id="remove-banner-checkbox">
                    <label class="custom-control-label" for="remove-banner-checkbox">
                      {{ $t('attributes.oneClick.removeBanner') }}
                    </label>
                  </div>
                </div>
              </template>
              <b-form-file
                v-model="oneClick.bannerFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="banner-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.banner" class="invalid-feedback d-block">{{ tErrors('oneClick', 'banner', oneClickSubmitErrors.banner) }}</div>
            </div>
          </div>

          <!-- Raw CSS -->
          <div class="form-group row">
            <label for="input-raw-css" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.rawCss') }}</label>
            <div class="col-sm-10 col-12">
              <codemirror
                v-model="oneClick.rawCss"
                :options="{ mode: 'text/css', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('oneClick', 'rawCss', oneClickSubmitErrors.rawCss) }}</div>
            </div>
          </div>

          <!-- Title -->
          <div class="form-group row">
            <label for="input-title" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.title') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="oneClick.title" type="string" class="form-control" id="input-title" :placeholder="$t('attributes.oneClick.title')">
            </div>
          </div>
          </template>

        <!-- Expert mode -->
        <template v-else>
          <!-- HTML file -->
          <div class="form-group row">
            <label for="html-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.htmlFile') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="oneClick.htmlFile.url" class="mb-3">
                <a :href="oneClick.htmlFile.url" class="btn btn-primary" target="_blank">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                </a>
              </div>
              <b-form-file
                v-model="oneClick.htmlFileFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="html-file-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.htmlFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'htmlFile', oneClickSubmitErrors.htmlFile) }}</div>
            </div>
          </div>

          <!-- CSS file -->
          <div class="form-group row">
            <label for="css-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.cssFile') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="oneClick.cssFile.url" class="mb-3">
                <a :href="oneClick.cssFile.url" class="btn btn-primary" target="_blank">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                </a>
              </div>
              <b-form-file
                v-model="oneClick.cssFileFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="css-file-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.cssFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'cssFile', oneClickSubmitErrors.cssFile) }}</div>
            </div>
          </div>

          <!-- JS file -->
          <div class="form-group row">
            <label for="js-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.jsFile') }}</label>
            <div class="col-sm-10 col-12">
              <div v-if="oneClick.jsFile.url" class="mb-3">
                <a :href="oneClick.jsFile.url" class="btn btn-primary" target="_blank">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                </a>
              </div>
              <b-form-file
                v-model="oneClick.jsFileFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="js-file-input" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.jsFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'jsFile', oneClickSubmitErrors.jsFile) }}</div>
            </div>
          </div>

          <!-- Show loader -->
          <div class="form-group row">
            <label for="show-loader-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.showLoader') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.showLoader" type="checkbox" class="custom-control-input" id="show-loader-checkbox">
                <label class="custom-control-label" for="show-loader-checkbox"></label>
              </div>
            </div>
          </div>
        </template>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="oneClickSubmitLoading">
            <md-spinner v-if="oneClickSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
