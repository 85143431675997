
<div>
  <transition name="fade" mode="out-in">
    <div v-if="storeLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="storeError" class="alert alert-danger">
      <template v-if="storeError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>

      <form @submit.prevent="submit">
        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.stores.form.tabs.infos')">
            <div class="form-group row">
              <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.store.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="store.name" type="text" class="form-control" :class="{ 'is-invalid' : storeSubmitErrors && storeSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.store.name')">
                <div v-if="storeSubmitErrors && storeSubmitErrors.name" class="invalid-feedback">{{ tErrors('store', 'name', storeSubmitErrors.name) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.store.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="store.group"
                  :class="{ 'is-invalid' : storeSubmitErrors && storeSubmitErrors.group }"
                  id="multiselect-group" />
                <div v-if="storeSubmitErrors && storeSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('store', 'group', storeSubmitErrors.group) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label
                for="injectable-checkbox"
                class="col-sm-2"
                v-b-tooltip.hover
                :title="$t('views.stores.form.tooltip.injectable')">
                {{ $t('attributes.store.injectable') }}
                <sup>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                </sup>
              </label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="store.injectable" type="checkbox" class="custom-control-input" id="injectable-checkbox">
                  <label class="custom-control-label" for="injectable-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.store.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="store.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                  <label class="custom-control-label" for="active-checkbox"></label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Customizations tab -->
          <b-tab :title="$t('views.stores.form.tabs.customizations')">
            <div class="form-group row">
              <label for="input-color" class="col-sm-2 col-form-label">{{ $t('attributes.store.color') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="store.color" type="text" class="form-control" :class="{ 'is-invalid' : storeSubmitErrors && storeSubmitErrors.color }" id="input-color" :placeholder="$t('attributes.store.color')">
                <div v-if="storeSubmitErrors && storeSubmitErrors.color" class="invalid-feedback">{{ tErrors('store', 'color', storeSubmitErrors.color) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-border-radius" class="col-sm-2 col-form-label">{{ $t('attributes.store.borderRadius') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model.number="store.borderRadius" type="number" class="form-control" :class="{ 'is-invalid' : storeSubmitErrors && storeSubmitErrors.borderRadius }" id="input-border-radius" :placeholder="$t('attributes.store.borderRadius')">
                <div v-if="storeSubmitErrors && storeSubmitErrors.borderRadius" class="invalid-feedback">{{ tErrors('store', 'borderRadius', storeSubmitErrors.borderRadius) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-custom-css" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawCss') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '30rem' }"
                  v-model="store.customCss"
                  lang="css"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="storeSubmitErrors && storeSubmitErrors.customCss" class="invalid-feedback">{{ tErrors('store', 'customCss', storeSubmitErrors.customCss) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Products tab -->
          <b-tab :title="$t('views.stores.form.tabs.products')">
            <div v-if="productsLoading" class="text-center">
              <md-spinner md-indeterminate></md-spinner>
            </div>
            <template v-else>
              <div class="form-group row">
                <label for="has-categories-checkbox" class="col-sm-2">{{ $t('attributes.store.hasCategories') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input v-model="store.hasCategories" type="checkbox" class="custom-control-input" id="has-categories-checkbox">
                    <label class="custom-control-label" for="has-categories-checkbox"></label>
                  </div>
                </div>
              </div>

              <!-- Product categories -->
              <template v-if="store.hasCategories">
                <form @submit.prevent>
                  <div class="form-group row">
                    <div class="col-sm-6" :class="{'has-error': categoryError !== null }">
                      <input
                        type="text"
                        class="form-control"
                        v-model="category.name"
                        @keydown.enter="addCategory()"
                        :class="{ 'is-invalid': categoryError }"
                        :placeholder="$t('views.stores.form.categoryNamePlaceholder')">
                      <div v-if="categoryError" class="invalid-feedback">{{ tErrors('storeCategory', 'name', categoryError) }}</div>
                    </div>
                    <div class="col-sm-6">
                      <button type="button" class="btn btn-success" @click="addCategory()">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                        {{ $t('views.stores.form.createCategory') }}
                      </button>
                    </div>
                  </div>
                </form>

                <table
                  class="table table-hover"
                  v-if="products.length > 0 && store.productStoreCategories.length > 0">
                  <thead>
                    <tr>
                      <th class="th-shrink"></th>
                      <th>{{ $t('attributes.store.name') }}</th>
                      <th class="th-shrink text-nowrap">{{ $t('attributes.store.productsCount') }}</th>
                      <th class="th-shrink"></th>
                      <th class="th-shrink"></th>
                    </tr>
                  </thead>
                  <draggable
                    tag="tbody"
                    v-model="store.productStoreCategories"
                    handle=".handle">
                    <tr
                      v-for="category in store.productStoreCategories"
                      :key="category.name"
                      class="category-row"
                      :class="{ 'table-danger': category._destroy }">
                      <td class="text-center handle cursor-move">
                        <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                      </td>
                      <td class="d-flex flex-row justify-content-between align-items-center store-category-item">
                        <div>
                          <b v-if="edit !== category.name">{{ category.name }}</b>
                          <input v-else class="form-control" @keydown.enter="updateCategoryName(category)" type="text"
                            v-model="newCategory.name">
                          <span class="help-block with-errors text-danger" v-if="categoryEditError && edit === category.name">{{ categoryEditError }}</span>
                        </div>
                        <div v-if="edit !== category.name" class="edit-store-category">
                          <button type="button" class="btn btn-sm btn-warning" @click="editCategoryName(category)">
                            {{ $t('shared.actions.edit') }}
                          </button>
                        </div>
                        <div v-else class="edit-store-category-name">
                          <button type="button" class="btn btn-sm btn-primary" @click="updateCategoryName(category)">
                            {{ $t('shared.actions.ok') }}
                          </button>
                          <button type="button" class="btn btn-sm btn-danger" @click="edit = null">
                            {{ $t('shared.actions.cancel') }}
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <div>{{ category.productStores.filter(productStore => !productStore._destroy).length }}</div>
                      </td>
                      <td>
                        <template v-if="!category.id">
                          <span class="badge badge-warning">New</span>
                        </template>
                      </td>
                      <td class="text-nowrap">
                        <button type="button" class="btn btn-sm btn-primary" @click="manageCategoryProducts(category)">
                          <i class="fa fa-tags"></i>
                          {{ $t('shared.actions.manageProducts') | capitalize }}
                        </button>
                        <template v-if="!category._destroy">
                          <button type="button" class="btn btn-sm btn-danger" @click="removeCategory(category)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') | capitalize }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-success" @click="restoreCategory(category)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </td>
                    </tr>
                  </draggable>
                </table>
                <div v-else class="alert alert-warning" role="alert">
                  {{ $t('views.stores.form.noCategory') }}
                </div>

                <!-- Category modal -->
                <b-modal
                  v-if="modalCategory"
                  id="categoryModal"
                  ref="categoryModal"
                  size="lg"
                  :title="modalCategory.name"
                  ok-only
                  no-fade>
                  <div class="form-group">
                    <multiselect
                      v-model="selectedProduct"
                      @input="addProductStore"
                      :options="availableProducts"
                      open-direction="bottom"
                      :allow-empty="false"
                      :clear-on-select="false"
                      :placeholder="$t('views.stores.form.addProduct')"
                      :close-on-select="false"
                      :max-height="200"
                      :custom-label="customLabel">
                      <template v-slot:option="{ option }">
                        {{ option.productLanguageDatas[0].name }} - <span class="small">{{ option.productLanguageDatas[0].rawPackaging }}</span> - <span class="small" v-if="option.ean">{{ option.ean }}</span>
                      </template>
                    </multiselect>
                  </div>
                  <div>
                    <draggable
                      class="d-flex flex-row flex-wrap gutter"
                      group="products"
                      v-model="modalCategory.productStores"
                      draggable=".store-product-wrapper">
                      <div
                        v-for="productStore in modalCategory.productStores"
                        :key="productStore.product.id"
                        class="store-product-wrapper"
                        :class="{ 'table-danger': productStore._destroy }">
                        <div class="image-wrapper text-center">
                          <img :src="productStore.product.img.medium.url">
                        </div>
                        <div class="product-infos d-flex flex-column justify-content-center align-items-center">
                          <div class="text-center">
                            {{ productStore.product.productLanguageDatas[0].name }}
                          </div>
                          <div class="small text-center">
                            {{ productStore.product.productLanguageDatas[0].rawPackaging }}
                          </div>
                        </div>
                        <div class="new-product">
                          <template v-if="!productStore.id">
                            <span class="badge badge-warning">New</span>
                          </template>
                        </div>
                        <div class="remove-product">
                          <template v-if="!productStore._destroy">
                            <button type="button" class="btn btn-sm btn-link text-danger" @click="removeProductStore(productStore)">
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            </button>
                          </template>
                          <template v-else>
                            <button type="button" class="btn btn-sm btn-link text-success" @click="restoreProductStore(productStore)">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            </button>
                          </template>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </b-modal>
              </template>

              <!-- Products without categories -->
              <template v-else>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <multiselect
                      v-model="selectedProduct"
                      :custom-label="customLabel"
                      @input="addProductStore"
                      :options="availableProducts"
                      open-direction="bottom"
                      :allow-empty="false"
                      :clear-on-select="true"
                      :placeholder="$t('views.stores.form.addProduct')"
                      :close-on-select="false"
                      :max-height="200">
                      <template v-slot:option="{ option }">
                        {{ option.productLanguageDatas[0].name }} - {{ option.productLanguageDatas[0].rawPackaging}}  <span v-if="option.ean">- {{ option.ean }}</span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-if="store.productStores.length > 0" class="form-group">
                  <draggable
                    class="d-flex flex-row flex-wrap gutter"
                    group="products"
                    v-model="store.productStores"
                    draggable=".store-product-wrapper">
                    <div
                      v-for="productStore in store.productStores"
                      :key="productStore.product.id"
                      class="store-product-wrapper"
                      :class="{ 'table-danger': productStore._destroy }">
                      <div class="image-wrapper text-center">
                        <img :src="productStore.product.img.medium.url">
                      </div>
                      <div class="product-infos d-flex flex-column justify-content-center align-items-center">
                        <div class="text-center">
                          {{ productStore.product.productLanguageDatas[0].name }}
                        </div>
                        <div class="small text-center">
                          {{ productStore.product.productLanguageDatas[0].rawPackaging }}
                        </div>
                      </div>
                      <div class="new-product">
                        <template v-if="!productStore.id">
                          <span class="badge badge-warning">New</span>
                        </template>
                      </div>
                      <div class="remove-product">
                        <template v-if="!productStore._destroy">
                          <button type="button" class="btn btn-sm btn-link text-danger" @click="removeProductStore(productStore)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-link text-success" @click="restoreProductStore(productStore)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          </button>
                        </template>
                      </div>
                    </div>
                  </draggable>
                </div>
                <div v-else>
                  <div class="alert alert-warning" role="alert">{{ $t('shared.warnings.noProduct') }}</div>
                </div>
              </template>
            </template>
          </b-tab>
        </persistent-tabs>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="storeSubmitLoading">
            <md-spinner v-if="storeSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
