import Vue from 'vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'
import router from '../../router'
import i18n from '../../i18n'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  namespaced: true,
  state: {
    user: null,
    userLoading: false,
    userError: null,
    loginLoading: false,
    loginError: null,
    logoutLoading: false,
    logoutError: null,
    sendResetPasswordInstructionsLoading: false,
    sendResetPasswordInstructionsError: null,
    resetPasswordByTokenLoading: false,
    resetPasswordByTokenError: null,
    group: null,
    roles: []
  },
  getters: {
    user: state => state.user,
    userLoading: state => state.userLoading,
    userError: state => state.userError,
    loginLoading: state => state.loginLoading,
    loginError: state => state.loginError,
    logoutLoading: state => state.logoutLoading,
    logoutError: state => state.logoutError,
    sendResetPasswordInstructionsLoading: state => state.sendResetPasswordInstructionsLoading,
    sendResetPasswordInstructionsError: state => state.sendResetPasswordInstructionsError,
    resetPasswordByTokenLoading: state => state.resetPasswordByTokenLoading,
    resetPasswordByTokenError: state => state.resetPasswordByTokenError,
    group: state => state.group,
    roles: state => state.roles
  },
  mutations: {
    setUser: function(state, user) {
      state.user = user
    },
    setUserDemoGroup: function(state, group) {
      state.user.actAsGroup = group
    },
    setUserLoading: function(state, userLoading) {
      state.userLoading = userLoading
    },
    setUserError: function(state, userError) {
      state.userError = userError
    },
    setLoginLoading: function(state, loginLoading) {
      state.loginLoading = loginLoading
    },
    setLoginError: function(state, loginError) {
      state.loginError = loginError
    },
    setLogoutLoading: function(state, logoutLoading) {
      state.logoutLoading = logoutLoading
    },
    setLogoutError: function(state, logoutError) {
      state.logoutError = logoutError
    },
    setSendResetPasswordInstructionsLoading: function(state, sendResetPasswordInstructionsLoading) {
      state.sendResetPasswordInstructionsLoading = sendResetPasswordInstructionsLoading
    },
    setSendResetPasswordInstructionsError: function(state, sendResetPasswordInstructionsError) {
      state.sendResetPasswordInstructionsError = sendResetPasswordInstructionsError
    },
    setResetPasswordByTokenLoading: function(state, resetPasswordByTokenLoading) {
      state.resetPasswordByTokenLoading = resetPasswordByTokenLoading
    },
    setResetPasswordByTokenError: function(state, resetPasswordByTokenError) {
      state.resetPasswordByTokenError = resetPasswordByTokenError
    },
    setGroup: function(state, group) {
      state.group = group
    },
    setRoles: function(state, roles) {
      state.roles = [...roles]
    },
    toggleRole: function(state, role) {
      const index = state.roles.indexOf(role)
      if (index === -1) state.roles.push(role)
      else state.roles.splice(index, 1)
    }
  },
  actions: {
    // Check if user is authenticated
    authenticate: async function({ commit }) {
      commit('setUserLoading', true)
      commit('setUserError', null)

      const query = gql`
        query {
          currentUser {
            id
            email
            roles
            rules
            locale
            hasMultiproductLandingPages
            hasMultiproductWidgets
            hasPopinImplementations
            hasCustomServices
            hasGroupApiKeys
            hasAbTests
            group {
              id
              name
              img
              region {
                id
                code
              }
              customPages {
                id
                name
                icon
                url
                active
              }
            }
            actAsGroup {
              id
              name
              imgUrl
              region {
                id
                code
              }
              customPages {
                id
                name
                icon
                url
                active
              }
            }
          }
        }
      `

      try {
        const { data } = await client.query({ query, fetchPolicy: 'network-only' })

        if (data.currentUser) {
          commit('setUser', cloneDeep(data.currentUser))
          commit('setGroup', data.currentUser.group)
          commit('setRoles', data.currentUser.roles)
          if (data.currentUser.locale) {
            i18n.locale = data.currentUser.locale
          }
        } else {
          commit('setUserError', 'unauthenticated')
        }
      } catch (error) {
        commit('setUserError', error)
      } finally {
        commit('setUserLoading', false)
      }
    },
    // Login
    login: async function({ commit }, { email, password, rememberMe }) {
      commit('setLoginLoading', true)
      commit('setLoginError', null)

      const mutation = gql`
        mutation($input: LoginInput!) {
          login(input: $input) {
            user {
              id
              email
              roles
              rules
              locale
              hasMultiproductLandingPages
              hasMultiproductWidgets
              hasPopinImplementations
              hasCustomServices
              hasGroupApiKeys
              hasAbTests
              group {
                id
                name
                img
                region {
                  id
                  code
                }
                customPages {
                  id
                  name
                  icon
                  url
                  active
                }
              }
              actAsGroup {
                id
                name
                imgUrl
                region {
                  id
                  code
                }
                customPages {
                  id
                  name
                  icon
                  url
                  active
                }
              }
            }
            errors
          }
        }
      `

      const variables = {
        input: {
          email,
          password,
          rememberMe
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.login.errors) {
          commit('setLoginError', data.login.errors)
        } else {
          commit('setUser', cloneDeep(data.login.user))
          commit('setGroup', data.login.user.group)
          commit('setRoles', data.login.user.roles)
          if (data.login.user.locale) {
            i18n.locale = data.login.user.locale
          }
          router.push(router.currentRoute.query.redirect || '/')
          new Vue().$bvToast.toast(i18n.t('auth.success.loginNotification'), {
            title: i18n.t('shared.notification'),
            variant: 'success',
            autoHideDelay: 3000
          })
        }
      } catch (error) {
        commit('setLoginError', error)
      } finally {
        commit('setLoginLoading', false)
      }
    },
    // Logout
    logout: async function({ commit }) {
      commit('setLogoutLoading', true)
      commit('setLogoutError', null)

      const mutation = gql`
        mutation($input: LogoutInput!) {
          logout(input: $input) {
            user {
              email
            }
          }
        }
      `

      const variables = {
        input: {}
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.logout.errors) {
          commit('setLogoutError', data.logout.errors)
        } else {
          commit('setUser', null)
          router.push({ name: 'login' })
          new Vue().$bvToast.toast(i18n.t('auth.success.logoutNotification'), {
            title: i18n.t('shared.notification'),
            variant: 'success',
            autoHideDelay: 3000
          })
        }
      } catch (error) {
        commit('setLogoutError', error)
      } finally {
        commit('setLogoutLoading', false)
      }
    },
    // Send reset password instructions
    sendResetPasswordInstructions: async function({ commit }, { email }) {
      commit('setSendResetPasswordInstructionsLoading', true)
      commit('setSendResetPasswordInstructionsError', null)

      const mutation = gql`
        mutation ($input: SendResetPasswordInstructionsInput!) {
          sendResetPasswordInstructions(input: $input) {
            success
            errors
          }
        }
      `

      const variables = {
        input: {
          email
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.sendResetPasswordInstructions.success) {
          router.push({ name: 'login' })
          new Vue().$bvToast.toast(i18n.t('auth.passwords.newNotification'), {
            title: i18n.t('shared.notification'),
            variant: 'success',
            autoHideDelay: 3000
          })
        } else {
          commit('setSendResetPasswordInstructionsError', data.sendResetPasswordInstructions.errors)
        }
      } catch (error) {
        commit('setSendResetPasswordInstructionsError', error)
      } finally {
        commit('setSendResetPasswordInstructionsLoading', false)
      }
    },
    // Reset password by token
    resetPasswordByToken: async function({ commit }, { password, passwordConfirmation, resetPasswordToken }) {
      commit('setResetPasswordByTokenLoading', true)
      commit('setResetPasswordByTokenError', null)

      const mutation = gql`
        mutation ($input: ResetPasswordByTokenInput!) {
          resetPasswordByToken(input: $input) {
            success
            errors
          }
        }
      `

      const variables = {
        input: {
          password,
          passwordConfirmation,
          resetPasswordToken
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.resetPasswordByToken.success) {
          router.push({ name: 'login' })
          new Vue().$bvToast.toast(i18n.t('auth.passwords.editNotification'), {
            title: i18n.t('shared.notification'),
            variant: 'success',
            autoHideDelay: 3000
          })
        } else {
          commit('setResetPasswordByTokenError', data.resetPasswordByToken.errors)
        }
      } catch (error) {
        commit('setResetPasswordByTokenError', error)
      } finally {
        commit('setResetPasswordByTokenLoading', false)
      }
    },
    // Load group from groupId
    loadGroup: ({ commit }, groupId) => {
      const query = `query actAsGroup ($id: Int!) {
        group(id: $id) {
          id
          name
          region {
            id
            code
          }
          customPages {
            id
            name
            icon
            url
            active
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(groupId)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          if (data.errors) {
            console.error({ status: res.status, data })
          } else {
            commit('setGroup', data.data.group)
          }
        })
    }
  }
}
