
<div class="pb-2">
  <transition name="fade" mode="out-in">
    <div v-if="landingPageLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="landingPageError" class="alert alert-danger">
      <template v-if="landingPageError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <persistent-tabs content-class="mt-3" :value="autoselectEditorTab ? 2 : undefined">
          <!-- Infos tab -->
          <b-tab :title="$t('views.landingPages.tabs.infos')">
            <!-- Name -->
            <div class="form-group row">
              <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="landingPage.name" type="text" class="form-control" :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.landingPage.name')">
                <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.name" class="invalid-feedback">{{ tErrors('landingPage', 'name', landingPageSubmitErrors.name) }}</div>
              </div>
            </div>

            <!-- Group -->
            <div class="form-group row">
              <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="landingPage.group"
                  :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.group }"
                  id="multiselect-group" />
                <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('landingPage', 'group', landingPageSubmitErrors.group) }}</div>
              </div>
            </div>

            <!-- Active -->
            <div class="form-group row">
              <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.landingPage.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="landingPage.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                  <label class="custom-control-label" for="active-checkbox"></label>
                </div>
              </div>
            </div>

            <!-- Title -->
            <div class="form-group row">
              <label for="input-title" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.title') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="landingPage.title" type="text" class="form-control" :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.title }" id="input-title" :placeholder="$t('attributes.landingPage.title')">
                <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.title" class="invalid-feedback">{{ tErrors('landingPage', 'title', landingPageSubmitErrors.title) }}</div>
              </div>
            </div>

            <!-- Favicon -->
            <div class="form-group row">
              <label for="favicon-input" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.favicon') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <template v-if="landingPage.favicon.url">
                  <div class="border mb-3 d-inline-block">
                    <img
                      :src="landingPage.favicon.url"
                      class="img-fluid"
                      :class="{ 'opacity-50': landingPage.removeFavicon }">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="landingPage.removeFavicon" type="checkbox" class="custom-control-input" id="remove-favicon-checkbox">
                      <label class="custom-control-label" for="remove-favicon-checkbox">
                        {{ $t('attributes.landingPage.removeFavicon') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="landingPage.faviconFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="favicon-input" />
                <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.favicon" class="invalid-feedback d-block">{{ tErrors('landingPage', 'favicon', landingPageSubmitErrors.favicon) }}</div>
              </div>
            </div>

            <!-- Path -->
            <div class="form-group row">
              <label for="input-custom-path" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.customPath') }}</label>
              <div class="col-sm-10 col-12">
                <input
                  v-model="landingPage.customPath"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.customPath }"
                  id="input-custom-path"
                  :placeholder="$t('attributes.landingPage.customPath')"
                >
                <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.customPath" class="invalid-feedback">
                  {{ tErrors('landingPage', 'customPath', landingPageSubmitErrors.customPath) }}
                </div>
                <small class="form-text text-muted">
                  {{ $t('attributes.landingPage.customPathHelp') }}
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-10 col-12">
              </div>
            </div>
          </b-tab>

          <!-- Fonts tab -->
          <b-tab :title="$t('views.landingPages.tabs.fonts')">
            <!-- Fonts list -->
            <div v-if="landingPage.fonts.length === 0" class="alert alert-warning">
              {{ $t('attributes.landingPage.fonts.noFile') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th>{{ $t('attributes.landingPage.fonts.name') }}</th>
                  <th>{{ $t('attributes.landingPage.fonts.url') }}</th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(font, index) in landingPage.fonts"
                  :key="index"
                  :class="{ 'table-danger': font._destroy }"
                >
                  <td class="align-middle" style="max-width: 40%; word-break: break-all;">
                    {{ font.identifier }}
                  </td>
                  <td class="align-middle" style="max-width: 40%; word-break: break-all;">
                    {{ font.url }}
                  </td>
                  <td class="align-middle text-nowrap">
                    <template v-if="!font._destroy">
                      <button type="button" class="btn btn-sm btn-danger" @click="deleteFont(font)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success" @click="restoreFont(font)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Fonts form-->
            <div class="form-group row">
              <label for="font-files-input" class="col-form-label col-sm-2">{{ $t('attributes.landingPage.fonts.inputLabel') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-file
                  v-model="landingPage.fontFiles"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="font-files-input"
                  multiple />
              </div>
            </div>
          </b-tab>

          <!-- Editor tab -->
          <b-tab :title="$t('views.landingPages.tabs.editor')" :disabled="action === 'new'">
            <div id="gjs" class="mb-3"></div>
          </b-tab>
        </persistent-tabs>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="landingPageSubmitLoading">
            <md-spinner v-if="landingPageSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('views.landingPages.submit.createAndAccessEditor') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
