
<div>
  <transition name="fade" mode="out-in">
    <div v-if="multiproductWidgetLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="multiproductWidgetError" class="alert alert-danger">
      <template v-if="multiproductWidgetError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ multiproductWidget.name }}</h5>
        <router-link :to="{ name: 'editMultiproductWidget', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <!-- Tabs -->
      <b-tabs content-class="mt-3">
        <!-- Infos -->
        <b-tab :title="$t('views.multiproductWidgets.tabs.infos')" active>
          <div class="d-flex flex-wrap gutter">
            <div class="flex-fill">
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.id') }}</th>
                    <td>{{ multiproductWidget.id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.key') }}</th>
                    <td><code>{{ multiproductWidget.key }}</code></td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.url') }}</th>
                    <td>
                      <a :href="multiproductWidget.url" target="_blank">{{ multiproductWidget.url }}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.name') }}</th>
                    <td>{{ multiproductWidget.name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.active') }}</th>
                    <td>
                      <dot :active="multiproductWidget.active" />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.group') }}</th>
                    <td>
                      <router-link :to="{ name: 'group', params: { id: multiproductWidget.group.id } }">
                        <region-flag :code="multiproductWidget.group.region.code" />
                        {{ multiproductWidget.group.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.groupActive') }}</th>
                    <td>
                      <dot :active="multiproductWidget.group.active" />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.multiproductWidget.multiproductWidgetCustomisation') }}</th>
                    <td>
                      <router-link
                        v-if="multiproductWidget.multiproductWidgetCustomisation"
                        :to="{ name: 'multiproductWidgetCustomisation', params: { id: multiproductWidget.multiproductWidgetCustomisation.id } }"
                      >
                        {{ multiproductWidget.multiproductWidgetCustomisation.name }}
                      </router-link>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </b-tab>

        <!-- Products -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.multiproductWidgets.tabs.products') }}
            <span class="badge badge-primary">{{ multiproductWidget.multiproductWidgetsProducts.length }}</span>
          </template>

          <div
            v-if="multiproductWidget.multiproductWidgetsProducts.length === 0"
            class="alert alert-warning"
            role="alert">
            {{ $t('shared.warnings.noProduct') }}
          </div>

          <table v-else class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t('attributes.product.img') }}</th>
                <th>{{ $t('attributes.productLanguageData.name') }}</th>
                <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                <th>{{ $t('attributes.product.ean') }}</th>
                <th>{{ $t('attributes.product.upc') }}</th>
                <th>{{ $t('attributes.multiproductWidgetsProduct.labelHtml') }}</th>
                <th>{{ $t('attributes.multiproductWidgetsProduct.retailerExceptions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="multiproductWidgetsProduct in multiproductWidget.multiproductWidgetsProducts"
                :key="multiproductWidgetsProduct.product.id"
                class="table-col-shrink">
                <th class="table-col-shrink">{{ multiproductWidgetsProduct.product.id }}</th>
                <td class="table-col-shrink">
                  <img
                    v-if="multiproductWidgetsProduct.product.img"
                    :src="multiproductWidgetsProduct.product.img.url"
                    class="multiproduct-widgets-product-product-img"
                  />
                </td>
                <td>
                  <router-link :to="{ name: 'product', params: { id: multiproductWidgetsProduct.product.id } }">
                    {{ multiproductWidgetsProduct.product.productLanguageDatas[0].name }}
                  </router-link>
                </td>
                <td>{{ multiproductWidgetsProduct.product.productLanguageDatas[0].rawPackaging }}</td>
                <td>{{ multiproductWidgetsProduct.product.ean }}</td>
                <td>{{ multiproductWidgetsProduct.product.upc }}</td>
                <td><code>{{ multiproductWidgetsProduct.labelHtml }}</code></td>
                <td>{{ multiproductWidgetsProduct.retailerExceptionsCount }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
