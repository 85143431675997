<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="statExportsError"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div></div>
      <div>
        <!-- New button -->
        <button type="button" class="btn btn-success" v-b-modal.newStatExportModal>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newStatExport') }}
        </button>
      </div>
    </div>

    <!-- Table -->
    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="statExportsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="statExportsLoading">
          <thead>
            <tr>
              <th class="th-shrink">{{ $t('attributes.statExport.id') }}</th>
              <th>{{ $t('attributes.statExport.createdAt') }}</th>
              <th>{{ $t('attributes.statExport.interval') }}</th>
              <th>{{ $t('attributes.statExport.user') }}</th>
              <th>{{ $t('attributes.statExport.mode') }}</th>
              <th>{{ $t('attributes.statExport.group') }}</th>
              <th>{{ $t('attributes.statExport.tags') }}</th>
              <th class="th-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="statExportsError && statExportsError.status === 500">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  {{ $t('errors.internalServerError') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!statExportsLoading && statExports.length === 0">
              <tr>
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.noStatExport') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="statExport in statExportsSorted" :key="statExport.id">
                <th>{{ statExport.id }}</th>
                <td>{{ statExport.createdAt | date }}</td>
                <td>
                  {{ new Date(statExport.params.start_date).toLocaleDateString() }}
                  -
                  {{ new Date(statExport.params.end_date).toLocaleDateString() }}
                </td>
                <td>{{ statExport.user.email }}</td>
                <td>
                  <span class="badge badge-secondary">{{ $t(`attributes.statExport.modes.${statExport.mode}`) }}</span>
                </td>
                <td>
                  <template v-if="statExport.group">
                    <router-link :to="{ name: 'group', params: { id: statExport.group.id } }">
                      <region-flag :code="statExport.group.region.code" />
                      {{ statExport.group.name }}
                    </router-link>
                  </template>
                </td>
                <td :id="`statExport-${statExport.id}`">
                    <template v-if="statExport.mode === 'group_tags'">
                      {{ statExport.tags.length }}
                      <b-tooltip :target="`statExport-${statExport.id}`" boundary="viewport" placement="right" triggers="hover click">
                        <ul class="list-unstyled mb-0">
                          <li v-for="tag in statExport.tags" :key="tag.id">
                            {{ tag.key }}
                          </li>
                        </ul>
                      </b-tooltip>
                  </template>
                </td>
                <td class="text-center">
                  <template v-if="statExport.error">
                    <span class="text-danger">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </span>
                  </template>
                  <template v-else-if="!statExport.csvUrl">
                    <button type="button" class="btn btn-secondary">
                      <md-spinner md-indeterminate :diameter="18" :stroke-width="5" />
                    </button>
                  </template>
                  <template v-else>
                    <a :href="statExport.csvUrl" class="btn btn-primary">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <!-- New stat export modal -->
    <b-modal
      id="newStatExportModal"
      ref="newStatExportModal"
      :title="$t('shared.actions.newStatExport')"
      @ok="newStatExportModalOk"
      no-fade
      :ok-disabled="newStatExport.mode === 'group_tags' && tagsSelected.length === 0">
      <form @submit.stop.prevent="newStatExportModalSubmit()">

        <div class="row mb-2">
          <label for="stat-export-mode" class="col-sm-2 col-form-label">{{ $t('attributes.statExport.mode') }}</label>
          <div class="col-sm-10 col-12">
            <b-form-radio-group buttons button-variant="outline-primary" v-model="newStatExport.mode">
              <b-form-radio value="groups">{{ $t('attributes.statExport.modes.groups') }}</b-form-radio>
              <b-form-radio value="tags">{{ $t('attributes.statExport.modes.tags') }}</b-form-radio>
              <b-form-radio value="group_tags">{{ $t('attributes.statExport.modes.group_tags') }}</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <template v-if="newStatExport.mode === 'group_tags'">
          <div class="row mb-2">
            <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.statExport.group') }}</label>
            <div class="col-sm-10 col-12">
              <group-modal-select
                v-model="group"
                tags
                id="multiselect-group"
              />
            </div>
          </div>

          <div class="row mb-2" v-if="group !== null">
            <label for="multiselect-tag" class="col-sm-2 col-form-label">{{ $t('attributes.statExport.tags') }}</label>
            <div class="col-sm-10 col-12">
              <multiselect
                :multiple="true"
                v-model="tagsSelected"
                :options="group.tags"
                track-by="id"
                id="multiselect-tag"
              >
                <template v-slot:tag="{ option, remove }">
                  <div class="btn-group" style="margin: 2.5px 5px;">
                    <div class="btn btn-light btn-xs text-left">
                      {{ option.key }}
                    </div>
                    <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </div>
                  </div>
                </template>
                <template v-slot:option="{ option }">
                  {{ option.key }}
                </template>
              </multiselect>
            </div>
          </div>
        </template>

        <div class="row">
          <label for="input-stat-export-interval" class="col-sm-2 col-form-label">{{ $t('attributes.statExport.interval') }}</label>
          <div class="col-sm-10 col-12">
            <date-range-picker v-model="newStatExport.dateRange" :ranges="ranges">
              <div slot="input" slot-scope="picker" class="btn btn-block btn-primary">
                {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
              </div>
            </date-range-picker>
          </div>
        </div>

      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import Multiselect from 'vue-multiselect'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BModal, VBModal, BFormRadioGroup, BFormRadio, BTooltip } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import i18n from '../../i18n'

export default {
  components: { MdSpinner, RegionFlag, Multiselect, GroupModalSelect, BModal, DateRangePicker, BFormRadioGroup, BFormRadio, BTooltip },
  directives: { 'b-modal': VBModal },
  data: function() {
    return {
      statExports: [],
      statExportsLoading: false,
      statExportsError: null,
      newStatExport: {
        dateRange: {
          startDate: moment.utc().startOf('day').subtract(30, 'days').toDate(),
          endDate: moment.utc().startOf('day').subtract(1, 'days').toDate()
        },
        mode: 'groups'
      },
      newStatExportSubmitLoading: false,
      newStatExportSubmitErrors: null,
      ranges: {
        'This month': [moment.utc().startOf('month'), moment.utc().endOf('month').startOf('day')],
        'Last month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month').startOf('day')],
        'This year': [moment.utc().startOf('year'), moment.utc().endOf('year').startOf('day')],
        'Last year': [moment.utc().subtract(1, 'year').startOf('year'), moment.utc().subtract(1, 'year').endOf('year').startOf('day')]
      },
      group: null,
      tagsSelected: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    // Table colspan
    colspan: function() {
      return 8
    },
    // Get stat exports sorted by id desc
    statExportsSorted: function() {
      return [...this.statExports].sort((a, b) => {
        return b.id - a.id
      })
    }
  },
  methods: {
    // Load data
    loadData: async function() {
      this.statExportsLoading = true
      this.statExportsError = null

      const query = `query statsExportsIndex {
        statExports {
          id
          createdAt
          params
          user {
            id
            email
          }
          mode
          error
          csvUrl
          group {
            id
            name
            region {
              id
              code
            }
          }
          tags {
            id
            key
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query })
        })

        const json = await res.json()
        if (json.errors) {
          throw json.errors
        } else {
          this.statExports = json.data.statExports
        }
      } catch (err) {
        this.statExportsError = err
      } finally {
        this.statExportsLoading = false
      }
    },
    // Modal close
    newStatExportModalHide: function() {
      this.$refs.newStatExportModal.hide()
    },
    // Modal ok click
    newStatExportModalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.newStatExportModalSubmit()
    },
    // Modal form submit
    newStatExportModalSubmit() {
      this.newStatExportSubmitLoading = true
      this.newStatExportSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            params: {
              start_date: this.newStatExport.dateRange.startDate.valueOf(),
              end_date: this.newStatExport.dateRange.endDate.valueOf()
            },
            userId: this.user.id,
            mode: this.newStatExport.mode,
            groupId: this.group ? this.group.id : null,
            tagIds: this.tagsSelected.map(tag => tag.id)
          }
        }
      }

      const mutation = `mutation($input: CreateStatExportInput!) {
        createStatExport(input: $input) {
          statExport {
            id
            createdAt
            params
            user {
              id
              email
            }
            mode
            error
            csvUrl
            group {
              id
              name
              region {
                id
                code
              }
            }
            tags {
              id
              key
            }
          }
          errors
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.newStatExportSubmitLoading = false

          if (data.data.createStatExport.errors) {
            this.newStatExportSubmitErrors = data.data.createStatExport.errors
          } else {
            this.statExports.push(data.data.createStatExport.statExport)
            this.$refs.newStatExportModal.hide()
            this.$root.$bvToast.toast(this.$t('shared.success.statExport.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    }
  },
  watch: {
    group: function() {
      this.tagsSelected = []
    }
  },
  filters: {
    date: function(dateString) {
      return dateString ? new Date(dateString).toLocaleString(i18n.locale) : '–'
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
