
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError && eventAggregatesError.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- Groups selector + daterangepicker -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelectOptions"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range picker -->
    <div class="flex-fill flex-basis-0 text-center">
      <stats-date-range-picker v-model="dateRange" />
    </div>

    <!-- Right zone (empty) -->
    <div class="flex-fill flex-basis-0 text-right"></div>
  </div>

  <!-- Linechart -->
  <div class="mb-3">
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <highcharts
            :options="chartOptions"
            class="chart"
            :aria-busy="eventAggregatesLoading"
            style="width: 100%; height: 300px;" />
          <div v-if="groupsLoading || eventAggregatesLoading" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
            <md-spinner md-indeterminate />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Export button -->
  <div class="mb-3 text-right">
    <button @click="exportXLSX" type="button" class="btn btn-primary">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
      {{ $t('shared.actions.xlsxExport') }}
    </button>
  </div>

  <!-- Table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover-tbody table-vertical-align-top" id="table-stats-landing-pages">
      <thead>
        <tr>
          <th class="cell-md">
            {{ $t('views.stats.landingPages.group') }}
          </th>
          <th>
            {{ $t('views.stats.landingPages.landingPage') }}
          </th>
          <th class="data-cell" v-for="action in landingActions" :key="`landing-${action}`">
            {{ $t(`shared.eventActions.landingPage.${action}`) }}
          </th>
          <th class="cell-md">
            {{ $t('views.stats.landingPages.landingPageCategory') }}
          </th>
          <th class="data-cell" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
            {{ $t(`shared.eventActions.landingCategory.${action}`) }}
          </th>
          <th class="cell-md">
            {{ $t('views.stats.landingPages.widget') }}
          </th>
          <th class="data-cell text-nowrap th-shield" v-for="action in widgetActions" :key="`widget-${action}`">
            {{ $t(`shared.eventActions.widget.${action}`) }}
            <div v-if="userIsAdmin" class="shield-wrapper text-warning">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="groupsLoading || eventAggregatesLoading">
        <tr>
          <td :colspan="colspan" class="text-center">
            <md-spinner md-indeterminate />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="groupsWithLandingPages.length === 0">
        <tr>
          <td :colspan="colspan" class="alert-warning">
            {{ $t('shared.warnings.noMultiproductLandingPage') }}
          </td>
        </tr>
      </tbody>
      <template v-else v-for="group in displayedGroups">
        <!-- Group total -->
        <tbody :key="group.id">
          <tr class="font-weight-semibold bg-light">
            <td
              @click="toggleExpandedGroup(group.id)"
              :class="{ 'cursor-pointer': group.children || group.multiproductLandingPages.length > 0 }">
              <div class="d-flex gutter-sm justify-content-between align-items-center">
                <div v-if="group.depth > 0">
                  <template v-for="n in group.depth">
                    <svg :key="n" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                  </template>
                </div>

                <div class="flex-fill">
                  <region-flag v-if="international" :code="group.region.code" />
                  {{ group.name }}
                </div>

                <template v-if="group.children || group.multiproductLandingPages.length > 0">
                  <template v-if="!expandedGroup(group.id)">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                  </template>
                  <template v-else>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                  </template>
                </template>
              </div>
            </td>
            <td>Total</td>
            <td class="text-right" v-for="action in landingActions" :key="`landing-${action}`">
              {{ groupLandingActionCount(group.id, action) | number }}
            </td>
            <td>Total</td>
            <td class="text-right" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
              {{ groupLandingCategoryActionCount(group.id, action) | number }}
            </td>
            <td>Total</td>
            <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
              {{ groupLandingCategoryWidgetActionCount(group.id, action) | number }}
            </td>
          </tr>
        </tbody>

        <!-- Group landing pages -->
        <template v-if="expandedGroup(group.id)">
          <tbody v-for="landingPage in group.multiproductLandingPages" :key="`landing-${landingPage.id}`">
            <template v-for="(category, categoryIndex) in landingPage.multiproductLandingPageCategories">
              <!-- Category row -->
              <tr :key="`category-${category.id}`">
                <template v-if="categoryIndex === 0">
                  <td :rowspan="landingPageRowspan(landingPage)" class="ellipsis" style="max-width: 150px;">{{ group.name }}</td>
                  <td :rowspan="landingPageRowspan(landingPage)" class="ellipsis" style="max-width: 150px;">{{ landingPage.name }}</td>
                  <td :rowspan="landingPageRowspan(landingPage)" class="text-right" v-for="action in landingActions" :key="`landing-${action}`">
                    {{ mediumActionCount('landing', landingPage.id, action) | number }}
                  </td>
                </template>
                <td :rowspan="categoryRowspan(category)" class="ellipsis" style="max-width: 150px;">{{ category.name }}</td>
                <td :rowspan="categoryRowspan(category)" class="text-right" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
                  {{ mediumActionCount('landing_category', category.id, action) | number }}
                </td>
                <td @click="toggleExpandedCategory(category.id)" class="cursor-pointer">
                  <div class="d-flex gutter-sm justify-content-between align-items-center">
                    <div class="flex-fill">Total</div>

                    <template v-if="!expandedCategoryIdsSet.has(category.id)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                    </template>
                  </div>
                </td>
                <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
                  {{ categoryWidgetsActionCount(category, action, landingPage.id) }}
                </td>
              </tr>
              <!-- Category widgets rows -->
              <template v-if="expandedCategoryIdsSet.has(category.id)">
                <tr v-for="multiproductLandingPageCategoryWidget in category.multiproductLandingPageCategoryWidgets" :key="`category-${category.id}-widget-${multiproductLandingPageCategoryWidget.widget.id}`">
                  <td class="ellipsis" style="max-width: 150px;">{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                  <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
                    {{ mediumActionCount('widget', multiproductLandingPageCategoryWidget.widget.id, action, landingPage.id) | number }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </template>
    </table>
  </div>
</div>
