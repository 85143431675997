
<div class="nps-container">
  <div class="nps-form" v-show="showForm">
    <button class="nps-header btn btn-primary" @click="showFormToggle">
      {{ $t('views.npsPopin.title') }}

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa">
        <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
      </svg>
    </button>

    <div class="nps-body">
      <p>{{ $t('views.npsPopin.p-1') }}</p>

      <div class="nps-notes">
        <div
          v-for="ratingIndex in 11"
          :key="ratingIndex"
          @click="rating = ratingIndex - 1"
          class="btn btn-sm nps-note-btn mb-2"
          v-bind:class="{ 'btn-outline-primary': rating !== ratingIndex - 1, 'btn-primary': rating === ratingIndex - 1 }"
        >
          {{ratingIndex - 1}}
        </div>
      </div>

      <p>{{ $t('views.npsPopin.p-2') }}</p>

      <textarea class="form-control mb-3" v-model="comment" :placeholder="$t('views.npsPopin.textarea')"></textarea>

      <button class="btn btn-primary" @click="sendNps" :disabled="rating === null">
        {{ $t('views.npsPopin.submit') }}
      </button>
    </div>
  </div>

  <button type="button" class="btn btn-primary" v-show="!showForm" @click="showFormToggle">
    {{ $t('views.npsPopin.show') }}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa">
      <path fill="currentColor" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
    </svg>
  </button>
</div>
