
<multiselect
  :options="availableRetailers"
  :custom-label="retailer => retailer.name"
  :allow-empty="false"
  :clear-on-select="false"
  :placeholder="placeholder"
  :close-on-select="false"
  :loading="retailersLoading"
  @select="emitSelectEvent"
  @search-change="handleSearchChange"
  @open="handleOpen"
>
  <template v-slot:option="{ option }">
    <div class="gutter-x-xs">
      #{{ option.id }}
      <region-flag :code="option.region.code" />
      <img :src="option.img_small_url" style="max-height: 20px;">
      {{ option.name }}
      <span class="badge badge-light">{{ $t(`shared.retailerDistributions.${option.service}`) }}</span>
    </div>
  </template>
  <template slot="afterList" v-if="retailersHasNextPage">
    <button class="btn btn-primary col-12" type="button" @click="loadMoreClick" :disabled="retailersLoading">
      {{ $t('views.retailerMultiselect.loadMore') }}
    </button>
  </template>
</multiselect>
