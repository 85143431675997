<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="eventAggregatesError && eventAggregatesError.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- First row with date range picker & export button -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Empty left col -->
      <div class="flex-fill flex-basis-0"></div>

      <!-- Date range picker -->
      <div class="flex-fill flex-basis-0 text-center">
        <stats-date-range-picker v-model="dateRange" />
      </div>

      <!-- Export button -->
      <div class="flex-fill flex-basis-0 text-right">
        <button @click="exportXLSX" type="button" class="btn btn-primary">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </div>
    </div>

    <div v-if="!groupsInitialized" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <!-- Pivot -->
    <pivot
      v-else
      :fields="fields"
      :available-field-keys="availableFieldKeys"
      :row-field-keys="rowFieldKeys"
      :col-field-keys="colFieldKeys"
      :data="eventAggregatesWithZeroes"
      :is-data-loading="globalLoading"
      :reducer="reducer"
      :available-fields-label-text="$t('views.stats.abTests.availableFieldsLabelText')"
      :rows-label-text="$t('views.stats.abTests.rowsLabelText')"
      :cols-label-text="$t('views.stats.abTests.colsLabelText')"
      :hide-settings-text="$t('views.stats.abTests.hideSettings')"
      :show-settings-text="$t('views.stats.abTests.showSettings')"
      :no-data-warning-text="$t('views.stats.abTests.noData')">
      <!-- Action labels -->
      <template v-slot:action="{ value }">
        <template v-if="actions.includes(value)">
          <div class="text-nowrap" :style="userIsAdmin ? 'padding-right: 20px;' : ''">
            {{ $t(`shared.eventActions.widget.${value}`) }}
            <template v-if="value === 'validation'">
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </template>

      <!-- Retailer labels -->
      <template v-slot:retailer="{ value }">
        <div style="min-width: 120px;">
          <template v-if="retailersMap.get(value)">
            <div class="d-flex align-items-center">
              <img :src="retailersMap.get(value).imgSmallUrl" class="td-img-sm" style="padding-right: 8px" v-if="retailersMap.get(value)"/>
              {{ retailersMap.get(value).name }}
            </div>
          </template>
          <template v-else>
            {{ value || '–' }}
          </template>
        </div>
      </template>

      <!-- Widget labels -->
      <template v-slot:widgetProductName="{ value }">
        <template v-if="widgetsMap.get(value)">
          <div class="d-flex justify-content-between align-items-center" style="min-width: 200px;">
            <div>
              {{ widgetsMap.get(value).product.productLanguageDatas[0].name }}
            </div>
            <div v-if="userIsAdmin">
              <router-link :to="{ name: 'widget', params: { id: widgetsMap.get(value).id } }" class="text-light">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16"><path fill="currentColor" d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" class=""></path></svg>
              </router-link>
            </div>
          </div>
        </template>
        <span v-else-if="value" class="text-danger">
          Widget #{{ value }}
        </span>
        <template v-else>–</template>
      </template>
      <template v-slot:widgetProductRawPackaging="{ value }">
        <small v-if="widgetsMap.get(value)" class="text-nowrap">{{ widgetsMap.get(value).product.productLanguageDatas[0].rawPackaging || '–' }}</small>
        <small v-else>–</small>
      </template>
      <template v-slot:widgetProductReference="{ value }">
        <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.reference || '–' }}</small>
        <small v-else>–</small>
      </template>
      <template v-slot:widgetProductEan="{ value }">
        <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.ean || '–' }}</small>
        <small v-else>–</small>
      </template>
      <template v-slot:widgetProductUpc="{ value }">
        <small v-if="widgetsMap.get(value)">{{ widgetsMap.get(value).product.upc || '–' }}</small>
        <small v-else>–</small>
      </template>

      <!-- Device labels -->
      <template v-slot:device="{ value }">
        <div class="text-nowrap">
          <span :style="{ color: deviceColor(value) }">
            <svg v-if="value === 'desktop'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-fw"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
            <svg v-else-if="value === 'mobile'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-mobile-alt fa-fw"><path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
            <svg v-else-if="value === 'tablet'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tablet-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-tablet-alt fa-fw"><path fill="currentColor" d="M400 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM224 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm176-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h328c6.6 0 12 5.4 12 12v312z" class=""></path></svg>
            <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-fw"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
          </span>
          {{ value ? value : 'unknown' | capitalize }}
        </div>
      </template>

      <!-- Tracking params labels -->
      <template
        v-for="trackingParam in trackingParams"
        v-slot:[`tracking_param_${trackingParam}`]
      >
        <span class="text-nowrap" :key="trackingParam">
          {{ trackingParam }}
          <sup v-b-tooltip.hover :title="$t('views.stats.pivotTable.tooltip.trackingParams')">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.125rem" height="1.125rem" viewBox="0 0 32 32"><path fill="currentColor" d="M24 21V9h-2v14h8v-2zm-4-6v-4c0-1.103-.897-2-2-2h-6v14h2v-6h1.48l2.335 6h2.145l-2.333-6H18c1.103 0 2-.897 2-2m-6-4h4v4h-4zM8 23H4c-1.103 0-2-.897-2-2V9h2v12h4V9h2v12c0 1.103-.897 2-2 2"/></svg>
          </sup>
        </span>
      </template>

      <!-- A/B test labels -->
      <template v-slot:ab-test="{ value }">
        <template v-if="abTestsMap.get(value)">
          {{ abTestsMap.get(value).name }}
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </template>
      <template v-slot:variant="{ value }">
        <template v-if="variantsMap.get(value)">
          {{ variantsMap.get(value).name }}
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </template>
      <template v-slot:customisation="{ value }">
        <template v-if="customisationsMap.get(value)">
          {{ customisationsMap.get(value).name }}
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </template>
      <template v-slot:michelinWidgetCustomisation="{ value }">
        <template v-if="michelinWidgetCustomisationsMap.get(value)">
          {{ michelinWidgetCustomisationsMap.get(value).name }}
        </template>
        <template v-else>
          {{ value || '–' }}
        </template>
      </template>

      <!-- Shared slot for simple labels/filters -->
      <template v-slot:simpleValue="{ value }">
        <template v-if="value === undefined || value === null">
          <code style="word-break: normal;">{{ String(value) }}</code>
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>

      <!-- Value slot -->
      <template v-slot:value="{ value }">
        {{ value | number }}
      </template>

      <!-- Loading slot -->
      <template v-slot:loading>
        <div class="text-center">
          <md-spinner md-indeterminate />
        </div>
      </template>
    </pivot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'
import MdSpinner from '../../shared/MdSpinner.vue'
import StatsDateRangePicker from '../../shared/StatsDateRangePicker.vue'
import { Pivot } from '@click2buy/vue-pivot-table'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import i18n from '../../../i18n'

export default {
  components: { MdSpinner, StatsDateRangePicker, Pivot },
  directives: {
    'b-tooltip': VBTooltip
  },
  data: function() {
    const actions = ['print', 'store_selection', 'redirection', 'validation']

    return {
      actions,
      eventAggregates: [],
      eventAggregatesLoading: false,
      eventAggregatesError: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      widgets: [],
      widgetsLoading: false,
      widgetsError: null,
      abTests: [],
      abTestsLoading: false,
      abTestsError: null,
      customisations: [],
      customisationsLoading: false,
      customisationsError: null,
      michelinWidgetCustomisations: [],
      michelinWidgetCustomisationsLoading: false,
      michelinWidgetCustomisationsError: null,
      groups: [],
      groupsLoading: false,
      groupsError: null,
      groupsInitialized: false,
      baseFields: [{
        key: 'action',
        getter: item => item._id.action,
        label: this.$t('views.stats.abTests.fieldLabels.tunnel'),
        sort: (a, b) => {
          if (actions.indexOf(a) < 0) {
            return 1
          } else if (actions.indexOf(b) < 0) {
            return -1
          } else {
            return actions.indexOf(a) - actions.indexOf(b)
          }
        },
        headerSlotName: 'action'
      }, {
        key: 'widget',
        getter: item => item._id.mediumId,
        label: this.$t('views.stats.pivotTable.fieldLabels.widgets'),
        headers: [{
          slotName: 'widgetName',
          label: this.$t('attributes.widget.name'),
          checked: false
        }, {
          slotName: 'widgetProductName',
          label: this.$t('views.stats.pivotTable.headerLabels.widgetProductName'),
          checked: true
        }, {
          slotName: 'widgetProductRawPackaging',
          label: this.$t('attributes.productLanguageData.rawPackaging'),
          checked: true
        }, {
          slotName: 'widgetProductReference',
          label: this.$t('attributes.product.reference'),
          checked: false
        }, {
          slotName: 'widgetProductEan',
          label: this.$t('attributes.product.ean'),
          checked: false
        }, {
          slotName: 'widgetProductUpc',
          label: this.$t('attributes.product.upc'),
          checked: false
        }],
        headerAttributeFilter: true,
        valueFilter: true,
        valueFilterSlotName: 'widgetProductName'
      }, {
        key: 'retailer',
        getter: item => item._id.retailerId,
        label: this.$t('views.stats.abTests.fieldLabels.retailers'),
        headerSlotName: 'retailer'
      }, {
        key: 'parent_hostname',
        getter: item => item._id.parentHostname,
        label: this.$t('views.stats.abTests.fieldLabels.parentHostname')
      }, {
        key: 'device',
        getter: item => item._id.device,
        label: this.$t('views.stats.abTests.fieldLabels.devices'),
        headerSlotName: 'device'
      }, {
        key: 'origin_widget',
        getter: item => item._id.originWidgetId,
        label: this.$t('views.stats.pivotTable.fieldLabels.originWidget'),
        headers: [{
          slotName: 'widgetName',
          label: this.$t('attributes.widget.name'),
          checked: false
        }, {
          slotName: 'widgetProductName',
          label: this.$t('views.stats.pivotTable.headerLabels.widgetProductName'),
          checked: true
        }, {
          slotName: 'widgetProductRawPackaging',
          label: this.$t('attributes.productLanguageData.rawPackaging'),
          checked: true
        }, {
          slotName: 'widgetProductReference',
          label: this.$t('attributes.product.reference'),
          checked: false
        }, {
          slotName: 'widgetProductEan',
          label: this.$t('attributes.product.ean'),
          checked: false
        }, {
          slotName: 'widgetProductUpc',
          label: this.$t('attributes.product.upc'),
          checked: false
        }],
        headerAttributeFilter: true,
        valueFilter: true,
        valueFilterSlotName: 'widgetProductName'
      }, {
        key: 'ab_test',
        getter: item => item._id.abTestId,
        label: this.$t('views.stats.abTests.fieldLabels.abTests'),
        headerSlotName: 'ab-test'
      }, {
        key: 'variant',
        getter: item => item._id.variantId,
        label: this.$t('views.stats.abTests.fieldLabels.variants'),
        headerSlotName: 'variant'
      }, {
        key: 'customisation',
        getter: item => item._id.customisationId,
        label: this.$t('views.stats.abTests.fieldLabels.customisations'),
        headerSlotName: 'customisation'
      }, {
        key: 'michelin_widget_customisation',
        getter: item => item._id.michelinWidgetCustomisationId,
        label: this.$t('views.stats.abTests.fieldLabels.michelinWidgetCustomisations'),
        headerSlotName: 'michelinWidgetCustomisation'
      }],
      baseFieldKeys: ['action', 'retailer', 'widget', 'parent_hostname', 'device', 'origin_widget'],
      colFieldKeys: ['action', 'retailer'],
      reducer: (sum, item) => sum + item.count
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userIsMichelin: function() {
      return this.roles.includes('michelin')
    },
    // Pivot table
    rowFieldKeys: function() {
      const rowFieldKeys = ['ab_test', 'variant']

      if (!this.userIsMichelin) {
        rowFieldKeys.push('customisation')
      }

      if (this.userIsMichelin || this.userIsAdmin) {
        rowFieldKeys.push('michelin_widget_customisation')
      }

      return rowFieldKeys
    },
    // Pivot table available fields
    availableFieldKeys: function() {
      return this.fieldKeys.filter(key => !this.colFieldKeys.includes(key) && !this.rowFieldKeys.includes(key))
    },
    // Global loading status
    globalLoading: function() {
      return this.eventAggregatesLoading || this.retailersLoading
    },
    // Widgets as a map
    widgetsMap: function() {
      return new Map(this.widgets.map(widget => [widget.id, widget]))
    },
    // Retailers as a map
    retailersMap: function() {
      return new Map(this.retailers.map(retailer => [retailer.id, retailer]))
    },
    // A/B tests as map
    abTestsMap: function() {
      return new Map(this.abTests.map(abTest => [abTest.id, abTest]))
    },
    // Variants map to get variant from its id
    variantsMap: function() {
      const variants = this.abTests.map(abTest => {
        return abTest.abTestsVariants.map(abTestsVariant => abTestsVariant.variant)
      }).flat()
      return new Map(variants.map(variant => [variant.id, variant]))
    },
    // Customisations map to get customisation from its id
    customisationsMap: function() {
      return new Map(this.customisations.map(customisation => [customisation.id, customisation]))
    },
    // Michelin widget customisations map to get Michelin widget customisation from its id
    michelinWidgetCustomisationsMap: function() {
      return new Map(this.michelinWidgetCustomisations.map(michelinWidgetCustomisation => [michelinWidgetCustomisation.id, michelinWidgetCustomisation]))
    },
    // A/B tests for the selected date range
    dateRangeAbTests: function() {
      const dateRangeAbTests = []

      if (this.abTests) {
        // Check if abTest date range overlaps with selected date range
        this.abTests.forEach(abTest => {
          if (new Date(abTest.startDate) <= this.dateRange.endDate && new Date(abTest.endDate) >= this.dateRange.startDate) {
            dateRangeAbTests.push(abTest)
          }
        })
      }

      return dateRangeAbTests
    },
    // Events with 0 values on A/B tests without stats
    // This allows to display A/B tests & variants even if there is no event
    eventAggregatesWithZeroes: function() {
      const eventAggregates = [...this.eventAggregates]

      // Add zero values for every A/B test + variant
      this.dateRangeAbTests.forEach(abTest => {
        abTest.abTestsVariants.forEach(abTestVariant => {
          const hasEvent = eventAggregates.some(event => {
            return event._id.abTestId === abTest.id && event._id.variantId === abTestVariant.variant.id
          })
          if (!hasEvent) {
            eventAggregates.push({
              _id: {
                abTestId: abTest.id,
                variantId: abTestVariant.variant.id,
                action: 'print'
              },
              amount: 0,
              count: 0
            })
          }
        })
      })

      return eventAggregates
    },
    trackingParams: function() {
      return [...new Set(this.groups.map(group => group.allTrackingParamKeys).flat())]
    },
    fields: function() {
      const fields = this.trackingParams.map(field => ({
        key: field,
        getter: item => item._id[field],
        label: field,
        labelSlotName: `tracking_param_${field}`,
        headerSlotName: 'simpleValue',
        valueFilter: true,
        valueFilterSlotName: 'simpleValue'
      }))

      return [...this.baseFields, ...fields]
    },
    fieldKeys: function() {
      return [...this.baseFieldKeys, ...this.trackingParams]
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Load groups
    loadGroups: async function() {
      if (this.groupsInitialized) return

      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsAbTestsPivotTableGroups ($groupId: Int!) {
        groups(id: $groupId) {
          id
          allTrackingParamKeys
        }
      }`

      const variables = {
        groupId: parseInt(this.$route.params.groupId)
      }

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables })
        })
        const json = await res.json()

        if (json.errors) {
          console.error(json.errors)
        } else {
          this.groups = Object.freeze(json.data.groups)
          this.groupsInitialized = true
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.groupsLoading = false
      }
    },
    // Update data when the date range has changed
    async loadData() {
      await this.loadEventAggregates()
      await Promise.all([this.loadRetailers(), this.loadMichelinWidgetCustomisations(), this.loadCustomisations()])
    },
    // Load event aggregates
    loadEventAggregates: async function() {
      this.eventAggregatesLoading = true
      this.eventAggregatesError = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRange.startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRange.endDate).endOf('day').format('x'),
        interval: 'total',
        group_keys: ['action', 'mediumId', 'retailerId', 'parentHostname', 'device', 'originWidgetId', 'abTestId', 'variantId', 'customisationId', 'michelinWidgetCustomisationId', ...this.trackingParams],
        group_id: this.$route.params.groupId,
        ab_tests_only: true
      }

      const loadClassicActions = async function() {
        const body = {
          ...baseBody,
          actions: ['print_button', 'print', 'redirection', 'validation']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadStoreSelections = async function() {
        const body = {
          ...baseBody,
          actions: ['retail_outlet_selection'],
          store: true
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [data, storeSelectionData] = await Promise.all([loadClassicActions(), loadStoreSelections()])

        storeSelectionData.forEach(item => {
          item._id.action = 'store_selection'
        })

        this.eventAggregates = Object.freeze(data.concat(storeSelectionData))
      } catch (err) {
        this.eventAggregatesError = err
      } finally {
        this.eventAggregatesLoading = false
      }
    },
    // Load retailers
    loadRetailers: function() {
      this.retailersLoading = true
      this.retailersError = null

      const retailerIds = this.eventAggregates
        .map(eventAggregate => eventAggregate._id.retailerId)
        .filter((elem, pos, arr) => arr.indexOf(elem) === pos)

      const query = `query statsRetailersGroups($retailerIds: [Int!]) {
        retailers(ids: $retailerIds) {
          id
          name
          service
          imgSmallUrl
          region {
            id
            code
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { retailerIds }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          if (!res.ok) {
            this.retailersError = { status: res.status, data }
          } else {
            this.retailers = Object.freeze(data.data.retailers)
          }
        })
    },
    // Load group widgets
    loadWidgets: function() {
      this.widgetsLoading = true
      this.widgetsError = null

      const query = `query statsAbTestsWidgets ($groupId: Int!) {
        widgets(groupId: $groupId) {
          id
          name
          product {
            id
            reference
            ean
            upc
            productLanguageDatas {
              id
              name
              rawPackaging
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: parseInt(this.$route.params.groupId)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.widgetsLoading = false

          if (data.errors) {
            this.widgetsError = { status: res.status, errors: data.errors }
          } else {
            this.widgets = Object.freeze(data.data.widgets)
          }
        })
    },
    // Load A/B tests
    loadAbTests: function() {
      this.abTestsLoading = true
      this.abTestsError = null

      const query = `query statsAbTestsAbTests ($groupId: Int!) {
        abTests(groupId: $groupId) {
          id
          name
          active
          startDate
          endDate
          abTestsVariants {
            id
            variant {
              id
              name
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: parseInt(this.$route.params.groupId)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.abTestsLoading = false

          if (data.errors) {
            this.abTestsError = { status: res.status, errors: data.errors }
          } else {
            this.abTests = Object.freeze(data.data.abTests)
          }
        })
    },
    loadCustomisations: function() {
      this.customisationsLoading = true
      this.customisationsError = null

      const customisationIds = this.eventAggregates
        .map(eventAggregate => eventAggregate._id.customisationId)
        .filter((elem, pos, arr) => arr.indexOf(elem) === pos).filter(item => item !== null)

      if (customisationIds.length > 0) {
        const query = `query statsAbTestCustomisations($customisationIds: [Int!]) {
          customisations(ids: $customisationIds) {
            id
            name
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: { customisationIds }
          })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.customisationsLoading = false

            if (data.errors) {
              this.customisationsError = { status: res.status, errors: data.errors }
            } else {
              this.customisations = Object.freeze(data.data.customisations)
            }
          })
      }
    },
    // Load Michelin widget customisations
    loadMichelinWidgetCustomisations: function() {
      this.michelinWidgetCustomisationsLoading = true
      this.michelinWidgetCustomisationsError = null

      const michelinWidgetCustomisationIds = this.eventAggregates
        .map(eventAggregate => eventAggregate._id.michelinWidgetCustomisationId)
        .filter((elem, pos, arr) => arr.indexOf(elem) === pos).filter(item => item !== null)

      if (michelinWidgetCustomisationIds.length > 0) {
        const query = `query statsAbTestMichelinWidgetCustomisation($michelinWidgetCustomisationIds: [Int!]) {
          michelinWidgetCustomisations(ids: $michelinWidgetCustomisationIds) {
            id
            name
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: { michelinWidgetCustomisationIds }
          })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.michelinWidgetCustomisationsLoading = false

            if (data.errors) {
              this.michelinWidgetCustomisationsError = { status: res.status, errors: data.errors }
            } else {
              this.michelinWidgetCustomisations = Object.freeze(data.data.michelinWidgetCustomisations)
            }
          })
      }
    },
    // Pivot table helpers
    deviceColor: function(device) {
      switch (device) {
        case 'desktop':
          return '#578ebe'
        case 'mobile':
          return '#26c281'
        case 'tablet':
          return '#e7505a'
        case undefined:
          return '#dbdbdb'
      }
    },
    // Export table as xlsx file
    exportXLSX: function() {
      const filename = `${snakeCase(this.$route.params.groupId)}_ab_tests_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('table.table'))
      XLSX.writeFile(wb, filename)
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    percentage: function(value) {
      return value !== undefined ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '–'
    },
    dateRange: function(value) {
      return value[0] === value[1] ? value[0] : `${value[0]} - ${value[1]}`
    },
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    }
  },
  watch: {
    // Load data when date range changes
    dateRange: async function() {
      await this.loadGroups()
      await this.loadData()
    }
  },
  created: function() {
    this.loadWidgets()
    this.loadAbTests()
  }
}
</script>
