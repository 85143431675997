<template>
  <span :class="active ? 'text-success' : 'text-danger'">
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="m498,250c-137,0 -248,111 -248,248s111,248 248,248s248,-111 248,-248s-111,-248 -248,-248z"></path></svg>
  </span>
</template>

<script>
export default {
  props: ['active']
}
</script>
