<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="customServiceLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="customServiceError" class="alert alert-danger">
        <template v-if="customServiceError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ customService.name }}</h5>
          <router-link :to="{ name: 'editCustomService', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <div class="table-responsive">
          <table class="table">
            <tr>
              <th>{{ $t('attributes.customService.id') }}</th>
              <td>{{ customService.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.customService.key') }}</th>
              <td><code>{{ customService.key }}</code></td>
            </tr>
            <tr>
              <th>{{ $t('attributes.customService.name') }}</th>
              <td>{{ customService.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.customService.active') }}</th>
              <td><dot :active="customService.active" /></td>
            </tr>
            <tr>
              <th>{{ $t('attributes.customService.group') }}</th>
              <td>
                <router-link :to="{ name: 'group', params: { id: customService.group.id } }">
                  <region-flag :code="customService.group.region.code" />
                  {{ customService.group.name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.customService.groupActive') }}</th>
              <td><dot :active="customService.group.active" /></td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'

export default {
  components: { MdSpinner, Dot, RegionFlag },
  data: function() {
    return {
      customService: null,
      customServiceLoading: false,
      customServiceError: null
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.customServiceLoading = true
      this.customServiceError = null

      const query = `query customServicesShow ($id: Int!) {
        customService(id: $id) {
          id
          name
          key
          active
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.customServiceLoading = false

          if (data.errors) {
            this.customServiceError = { status: res.status, errors: data.errors }
          } else {
            this.customService = Object.freeze(data.data.customService)
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
