<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.widget.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.widget.name')">
          </div>

          <div>
            <label for="filter-key">{{ $t('attributes.widget.key') }}</label>
            <input
              v-model="filters.key"
              type="text"
              class="form-control"
              id="filter-key"
              :placeholder="$t('attributes.widget.key')">
          </div>

          <div>
            <label for="filter-active">{{ $t('attributes.widget.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">Inactive</option>
              <option :value="true">Active</option>
            </select>
          </div>

          <div>
            <label for="filter-region">{{ $t('attributes.widget.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </key-multiselect>
          </div>

          <div>
            <label for="filter-group">{{ $t('attributes.widget.group') }}</label>
            <group-modal-select v-model="filters.groups" multiple />
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div></div>
      <div class="gutter-x-xs">
        <!-- Export button -->
        <button type="button" class="btn btn-secondary" :disabled="!hasFilters" v-b-modal.export-modal>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
        <!-- Bulk update -->
        <router-link :to="{ name: 'widgetBulkUpdates' }" class="btn btn-primary">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-upload fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
          {{ $t('shared.actions.widgetBulkUpdates') }}
        </router-link>
        <!-- New button -->
        <router-link :to="{ name: 'newWidget' }" class="btn btn-success">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newWidget') }}
        </router-link>
      </div>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="widgetsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="widgetsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.widget.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink"></th>
              <th-sortable sort-key="name">{{ $t('attributes.widget.name') }}</th-sortable>
              <th-sortable sort-key="group_id" class="table-col-shrink">{{ $t('attributes.widget.group') }}</th-sortable>
              <th class="table-col-shrink">{{ $t('attributes.widget.product') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.widget.key') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="widgetsError">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="widgetsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internalServerError') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!widgetsLoading && widgets.length === 0">
              <tr>
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.noWidget') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="widget in widgets" :key="widget.id">
                <th>{{ widget.id }}</th>
                <td class="text-center">
                  <dot :active="widget.active" />
                </td>
                <td :id="`widget-${widget.id}-img`">
                  <template v-if="widget.product && widget.product.img && widget.product.img.url">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                    <b-tooltip :target="`widget-${widget.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                      <img :src="widget.product.img.medium.url" style="height: 200px;" />
                    </b-tooltip>
                  </template>
                </td>
                <td class="ellipsis" style="max-width: 200px;">
                  {{ widget.name }}
                </td>
                <td class="ellipsis" style="max-width: 150px;">
                  <template v-if="widget.product">
                    <router-link :to="{ name: 'group', params: { id: widget.group.id } }">
                      <region-flag :code="widget.group.region.code" />
                      {{ widget.group.name }}
                    </router-link>
                  </template>
                </td>
                <td class="ellipsis" style="max-width: 150px;">
                  <template v-if="widget.product">
                    <router-link :to="{ name: 'product', params: { id: widget.product.id } }">
                      {{ widget.product.productLanguageDatas[0].name }}
                    </router-link>
                  </template>
                </td>
                <td class="text-nowrap">
                  <code>{{ widget.key }}</code>
                </td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                    </template>
                    <router-link :to="{ name: 'widget', params: { id: widget.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link :to="{ name: 'editWidget', params: { id: widget.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />

    <!-- Export modal -->
    <b-modal
      id="export-modal"
      size="lg"
      title="Export widgets"
      scrollable
      no-fade>
      <div class="form-group">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-secondary" @click="selectAllExportAttributes">{{ $t('shared.actions.selectAll') }}</button>
          <button type="button" class="btn btn-secondary" @click="unselectAllExportAttributes">{{ $t('shared.actions.unselectAll') }}</button>
        </div>
      </div>
      <div class="widget-export-attributes-grid">
        <div v-for="(attributeValue, attribute) in selectedExportAttributes" :key="attribute" class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              v-model="selectedExportAttributes[attribute]"
              type="checkbox" class="custom-control-input" :id="`export-attribute-${attribute}`"
              :disabled="attribute === 'id'">
            <label class="custom-control-label" :for="`export-attribute-${attribute}`">
              {{ attribute }}
            </label>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <template v-slot:modal-footer="{ close }">
        <a :href="exportUrl" class="btn btn-primary" @click="close()">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </a>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import Pagination from '../shared/Pagination.vue'
import { BTooltip, BDropdown, BModal, VBModal } from 'bootstrap-vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'

export default {
  components: { MdSpinner, KeyMultiselect, GroupModalSelect, Pagination, BTooltip, BDropdown, BModal, Dot, RegionFlag, ThSortable },
  directives: { 'b-modal': VBModal },
  data: function() {
    const exportAttributes = ['id', 'key', 'name', 'active', 'product_id', 'product_name', 'product_raw_packaging', 'product_reference', 'product_ean', 'product_upc', 'group_id', 'group_name', 'group_region_code', 'alternative_widget_ids']
    const preselectedAttributes = ['id', 'key', 'name', 'active', 'product_ean', 'group_id', 'group_name', 'group_region_code', 'alternative_widget_ids']

    // Default export attributes selected
    const selectedExportAttributes = {}
    exportAttributes.forEach(attribute => {
      selectedExportAttributes[attribute] = preselectedAttributes.includes(attribute)
    })

    return {
      widgets: [],
      widgetsLoading: false,
      widgetsError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        key: null,
        active: null,
        regionIds: [],
        groups: []
      },
      selectedExportAttributes
    }
  },
  computed: {
    currentPage: function() {
      return parseInt(this.$route.query.page) || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Table colspan
    colspan: function() {
      return 12
    },
    // Check if there is at least a filter
    hasFilters: function() {
      return !!((
        this.$route.query.name ||
        this.$route.query.key ||
        this.$route.query.active ||
        this.$route.query.regionIds ||
        this.$route.query.groupIds ||
        this.$route.params.groupId
      ))
    },
    // Get CSV export url
    exportUrl: function() {
      const url = new URL('/api/interface/widgets/export', window.location.origin)

      // Filters
      if (this.$route.query.name) url.searchParams.set('name', this.$route.query.name)
      if (this.$route.query.key) url.searchParams.set('key', this.$route.query.key)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)
      if (this.$route.query.groupIds) url.searchParams.set('group_ids', this.$route.query.groupIds)
      if (this.$route.params.groupId) url.searchParams.set('group_id', this.$route.params.groupId)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      // Attributes
      const attributes = Object.entries(this.selectedExportAttributes).filter(entry => entry[1] === true).map(entry => entry[0])
      url.searchParams.set('export_attributes', attributes)

      return url.href
    }
  },
  methods: {
    // Load data
    loadData: function({ regions = true } = {}) {
      this.widgetsLoading = true
      this.widgetsError = null
      if (regions) {
        this.regionsLoading = true
        this.regionsError = null
      }

      const query = `query widgetsIndex ($page: Int!, $perPage: Int!, $name: String, $key: String, $active: Boolean, $groupId: Int, $groupIds: [Int!], $regionIds: [Int!], $sort: String, $sortDirection: String, $regions: Boolean = true) {
        widgetsPage(page: $page, perPage: $perPage, name: $name, key: $key, active: $active, groupId: $groupId, groupIds: $groupIds, regionIds: $regionIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          nodes {
            id
            key
            name
            active
            group {
              id
              name
              region {
                id
                code
              }
            }
            product {
              id
              productLanguageDatas {
                id
                name
              }
            }
          }
        }
        regions @include(if: $regions) {
          id
          code
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            page: this.currentPage,
            perPage: this.perPage,
            name: this.filters.name,
            key: this.filters.key,
            active: this.filters.active,
            groupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : undefined,
            groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
            regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
            sort: this.$route.query.sort,
            sortDirection: this.$route.query.sort_direction,
            regions
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.widgetsLoading = false
          if (regions) this.regionsLoading = false

          if (data.errors) {
            this.widgetsError = { status: res.status, errors: data.errors }
            if (regions) this.regionsError = { status: res.status, errors: data.errors }
          } else {
            this.totalRows = data.data.widgetsPage.nodesCount
            this.widgets = Object.freeze(data.data.widgetsPage.nodes)
            if (regions) this.regions = Object.freeze(data.data.regions)
          }
        })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.key) query.key = this.filters.key
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.groups.length > 0) query.groupIds = this.filters.groups.map(group => group.id)

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: async function() {
      this.filters.name = this.$route.query.name
      this.filters.key = this.$route.query.key
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.groupIds) {
        let groupIds
        if (this.$route.query.groupIds instanceof Array) {
          groupIds = this.$route.query.groupIds.map(groupId => parseInt(groupId))
        } else {
          groupIds = [parseInt(this.$route.query.groupIds)]
        }

        // Load groups from url groupIds
        const query = `query groupsFilter ($groupIds: [Int!]) {
          groups(ids: $groupIds) {
            id
            name
            active
            region {
              id
              code
            }
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables: { groupIds } })
        })
        const json = await res.json()
        this.filters.groups = json.data.groups
      } else {
        this.filters.groups = []
      }
    },
    // Select all export attributes
    selectAllExportAttributes: function() {
      Object.keys(this.selectedExportAttributes).forEach(attribute => {
        this.selectedExportAttributes[attribute] = true
      })
    },
    // Unselect all export attributes
    unselectAllExportAttributes: function() {
      Object.keys(this.selectedExportAttributes).forEach(attribute => {
        this.selectedExportAttributes[attribute] = false
      })
    }
  },
  created: async function() {
    await this.updateFiltersFromQuery()
    await this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: async function(to, from) {
      await this.updateFiltersFromQuery()
      await this.loadData({ regions: false })
    }
  }
}
</script>

<style lang="scss">
.widget-export-attributes-grid {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-auto-flow: column;
}
</style>
