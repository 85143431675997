<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="facebookAppLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="facebookAppError" class="alert alert-danger">
        <template v-if="facebookAppError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ facebookApp.name }}</h5>
          <router-link :to="{ name: 'editFacebookApp', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.facebookApps.tabs.infos')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th>{{ $t('attributes.facebookApp.id') }}</th>
                  <td>{{ facebookApp.id }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.facebookPageId') }}</th>
                  <td>{{ facebookApp.facebookPageId }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.url') }}</th>
                  <td>
                    <a :href="facebookApp.url" target="_blank">{{ facebookApp.url }}</a>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.name') }}</th>
                  <td>{{ facebookApp.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.group') }}</th>
                  <td>
                    <router-link :to="{ name: 'group', params: { id: facebookApp.group.id } }">
                      <region-flag :code="facebookApp.group.region.code" />
                      {{ facebookApp.group.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.groupActive') }}</th>
                  <td>
                    <dot :active="facebookApp.group.active" />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.multiproductLandingPage') }}</th>
                  <td>
                    <template v-if="facebookApp.multiproductLandingPage">
                      <router-link :to="{ name: 'multiproductLandingPage', params: { id: facebookApp.multiproductLandingPage.id } }">
                        {{ facebookApp.multiproductLandingPage.name }}
                      </router-link>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.facebookApp.showPublicName') }}</th>
                  <td><dot :active="facebookApp.showPublicName" /></td>
                </tr>
              </table>
            </div>

            <h5>{{ $t('attributes.facebookApp.banner') }}</h5>

            <div v-if="!facebookApp.banner.url" class="alert alert-warning">
              {{ $t('views.facebookApps.show.noBanner') }}
            </div>

            <div v-else class="border mb-3 d-inline-block">
              <img :src="facebookApp.banner.url">
            </div>
          </b-tab>

          <!-- Style tab -->
          <b-tab :title="$t('views.facebookApps.tabs.style')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.facebookApp.btnColor') }}</th>
                  <td>
                    <span v-if="facebookApp.btnColor" :style="`color: ${facebookApp.btnColor};`">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                    </span>
                    {{ facebookApp.btnColor }}
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.facebookApp.btnText') }}</th>
                  <td>{{ facebookApp.btnText }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.facebookApp.rawCss') }}</th>
                  <td>
                    <AceEditor
                      :style="{ height: '30rem' }"
                      v-model="facebookApp.rawCss"
                      lang="css"
                      :options="editorOptions"
                    ></AceEditor>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Widgets tab -->
          <b-tab>
            <template v-slot:title>
              {{ $t('views.facebookApps.tabs.widgets') }}
              <span class="badge badge-primary">{{ facebookApp.facebookAppWidgets.length }}</span>
            </template>
            <div v-if="facebookApp.facebookAppWidgets.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noWidget') }}
            </div>
            <div v-else class="table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t('attributes.widget.id') }}</th>
                    <th>{{ $t('attributes.widget.name') }}</th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <tr v-for="facebookAppWidget in facebookApp.facebookAppWidgets" :key="facebookAppWidget.widget.id">
                  <th>{{ facebookAppWidget.widget.id }}</th>
                  <td>{{ facebookAppWidget.widget.name }}</td>
                  <td>
                    <router-link :to="{ name: 'widget', params: { id: facebookAppWidget.widget.id } }" class="btn btn-primary btn-sm">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
        </persistent-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTab } from 'bootstrap-vue'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/less'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTab, AceEditor, PersistentTabs },
  data: function() {
    return {
      editorOptions: {
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false,
        readOnly: true
      },
      facebookApp: null,
      facebookAppLoading: false,
      facebookAppError: null
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.facebookAppLoading = true
      this.facebookAppError = null

      const query = `query facebookAppsShow ($id: Int!) {
        facebookApp(id: $id) {
          id
          name
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
          banner
          btnColor
          btnText
          rawCss
          facebookPageId
          showPublicName
          url
          multiproductLandingPage {
            id
            name
          }
          facebookAppWidgets {
            id
            active
            orderState
            widget {
              id
              name
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.facebookAppLoading = false

          if (data.errors) {
            this.facebookAppError = { status: res.status, errors: data.errors }
          } else {
            data.data.facebookApp.facebookAppWidgets = data.data.facebookApp.facebookAppWidgets.sort((a, b) => a.orderState - b.orderState)
            this.facebookApp = Object.freeze(data.data.facebookApp)
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
