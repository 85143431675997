<template>
  <div class="nps-container">
    <div class="nps-form" v-show="showForm">
      <button class="nps-header btn btn-primary" @click="showFormToggle">
        {{ $t('views.npsPopin.title') }}

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa">
          <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
        </svg>
      </button>

      <div class="nps-body">
        <p>{{ $t('views.npsPopin.p-1') }}</p>

        <div class="nps-notes">
          <div
            v-for="ratingIndex in 11"
            :key="ratingIndex"
            @click="rating = ratingIndex - 1"
            class="btn btn-sm nps-note-btn mb-2"
            v-bind:class="{ 'btn-outline-primary': rating !== ratingIndex - 1, 'btn-primary': rating === ratingIndex - 1 }"
          >
            {{ratingIndex - 1}}
          </div>
        </div>

        <p>{{ $t('views.npsPopin.p-2') }}</p>

        <textarea class="form-control mb-3" v-model="comment" :placeholder="$t('views.npsPopin.textarea')"></textarea>

        <button class="btn btn-primary" @click="sendNps" :disabled="rating === null">
          {{ $t('views.npsPopin.submit') }}
        </button>
      </div>
    </div>

    <button type="button" class="btn btn-primary" v-show="!showForm" @click="showFormToggle">
      {{ $t('views.npsPopin.show') }}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa">
        <path fill="currentColor" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data: function() {
    return {
      showForm: false,
      rating: null,
      comment: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  mounted: function() {
    if (this.user.roles.includes('admin')) {
      this.showForm = false
    } else if (!this.user.nps_answer_date) {
      this.showForm = true
    } else {
      const npsAnswerDate = moment(new Date(this.user.nps_answer_date))
      const today = moment(new Date(Date.now()))
      this.showForm = today.diff(npsAnswerDate, 'months', true) >= 3
    }
  },
  methods: {
    showFormToggle: function() {
      this.showForm = !this.showForm
    },
    sendNps: async function() {
      if (this.rating === null) return

      try {
        await this.sendNpsToExternalApi()
        await this.updateUserNps()

        this.showFormToggle()
        this.$root.$bvToast.toast(this.$t('views.npsPopin.success'), {
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      } catch (err) {
        console.error(err)
        this.$root.$bvToast.toast(this.$t('views.npsPopin.error'), {
          variant: 'danger',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      }
    },
    sendNpsToExternalApi: async function() {
      const url = new URL('https://www.click2buy.com/nps2/nps-notification.php')
      url.searchParams.set('email', this.user.email)
      url.searchParams.set('rating', this.rating)
      url.searchParams.set('source', 'bbo')
      url.searchParams.set('comment', this.comment)

      await fetch(url)
    },
    updateUserNps: async function() {
      const mutation = `mutation($input: UpdateUserInput!) {
          updateUser(input: $input) {
            user {
              id
              npsAnswerDate
            }
            errors
          }
        }`

      const variables = {
        input: {
          userId: this.user.id,
          attributes: {
            npsAnswerDate: new Date(Date.now())
          }
        }
      }

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })

      const data = await res.json()

      if (data.errors) {
        throw new Error(data.errors)
      }
    }
  }
}
</script>

<style lang="scss">
.nps-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 1rem;
  z-index: 1050;
}

.nps-form {
  background-color: #fff;
  border: solid 1px #dee2e6;
  width: auto;
  max-width: 500px;
  margin: 1rem 0;

  & .nps-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  & .nps-body {
    padding: 0.5rem;

    & .nps-notes {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.25rem;

      & .nps-note-btn {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & textarea {
      font-size: 14px;
      resize: vertical;
      height: 40px;
      max-height: 100px;
      min-height: 40px;
    }
  }
}
</style>
