<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="popinImplementationLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="popinImplementationError" class="alert alert-danger">
        <template v-if="popinImplementationError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ popinImplementation.name }}</h5>
          <router-link :to="{ name: 'editPopinImplementation', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.popinImplementations.tabs.infos')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.id') }}</th>
                  <td>{{ popinImplementation.id }}</td>
                </tr>
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.key') }}</th>
                  <td><code>{{ popinImplementation.key }}</code></td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.name') }}</th>
                  <td>{{ popinImplementation.name }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.group') }}</th>
                  <td>
                    <router-link :to="{ name: 'group', params: { id: popinImplementation.group.id } }">
                      <region-flag :code="popinImplementation.group.region.code" />
                      {{ popinImplementation.group.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.groupActive') }}</th>
                  <td>
                    <dot :active="popinImplementation.group.active" />
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.active') }}</th>
                  <td><dot :active="popinImplementation.active" /></td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.scriptUrl') }}</th>
                  <td>
                    <a :href="popinImplementationScriptUrl" target="_blank">
                      {{ popinImplementationScriptUrl }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.scriptTag') }}</th>
                  <td>
                    <code>
                      &lt;script src="{{ popinImplementationScriptUrl }}"&gt;&lt;/script&gt;
                    </code>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.autoMigration') }}</th>
                  <td><dot :active="popinImplementation.autoMigration" /></td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Popins tab -->
          <b-tab>
            <template v-slot:title>
              {{ $t('views.popinImplementations.tabs.popins') }}
              <span class="badge badge-primary">{{ popinImplementation.popins.length }}</span>
            </template>

            <!-- Popins list -->
            <div v-if="popinImplementation.popins.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noPopin') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.popin.id') }}</th>
                  <th>{{ $t('attributes.popin.name') }}</th>
                  <th class="th-shrink">{{ $t('attributes.popin.key') }}</th>
                  <th class="th-shrink">{{ $t('attributes.popin.active') }}</th>
                  <th class="th-shrink">{{ $t('attributes.popin.widgets') }}</th>
                  <th>{{ $t('attributes.popin.scriptTag') }}</th>
                  <th>{{ $t('attributes.popin.multiproductWidget') }}</th>
                  <th>{{ $t('views.popinImplementations.show.popinPreview') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="popin in popinImplementation.popins" :key="popin.id">
                  <th>{{ popin.id }}</th>
                  <td>{{ popin.name }}</td>
                  <td class="text-nowrap"><code>{{ popin.key }}</code></td>
                  <td class="text-center"><dot :active="popin.active" /></td>
                  <td class="text-center">{{ popin.widgetsCount }}</td>
                  <td>
                    <code>
                      &lt;div class="C2B_Widget_Key" data-key="{{ popin.key }}"&gt;Where to buy&lt;/div&gt;
                    </code>
                  </td>
                  <td>
                    <router-link v-if="popin.multiproductWidgetId" :to="{ name: 'multiproductWidget', params: { id: popin.multiproductWidgetId } }">
                      #{{ popin.multiproductWidgetId }}
                    </router-link>
                    <button v-else class="btn btn-primary text-nowrap" @click="createMultiproductWidget(popin)" :disabled="createMultiproductWidgetForPopinLoading[popin.id]">
                      <md-spinner v-if="createMultiproductWidgetForPopinLoading[popin.id]" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
                      <span>{{ $t('shared.submit.create') }}</span>
                    </button>
                  </td>
                  <td>
                    <div class="C2B_Widget_Key" :data-key="popin.key">
                      <button class="btn btn-primary text-nowrap">
                        Where to buy
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>

          <!-- Style tab -->
          <b-tab :title="$t('views.popinImplementations.tabs.style')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_background_color') }}</th>
                  <td>
                    <span v-if="popinImplementation.customisation['button-background-color']" :style="`color: ${popinImplementation.customisation['button-background-color']};`">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                    </span>
                    {{ popinImplementation.customisation['button-background-color'] }}
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_text_color') }}</th>
                  <td>
                    <span v-if="popinImplementation.customisation['button-text-color']" :style="`color: ${popinImplementation.customisation['button-text-color']};`">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                    </span>
                    {{ popinImplementation.customisation['button-text-color'] }}
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.customisation.button_cart_color') }}</th>
                  <td>
                    <span v-if="popinImplementation.customisation['button-cart-color']" :style="`color: ${popinImplementation.customisation['button-cart-color']};`">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                    </span>
                    {{ popinImplementation.customisation['button-cart-color'] }}
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.ctaText') }}</th>
                  <td>{{ popinImplementation.ctaText }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.ctaIcon') }}</th>
                  <td>
                    <div v-if="popinImplementation.ctaIcon.url" class="border mb-3 d-inline-block">
                      <img :src="popinImplementation.ctaIcon.url" class="img-fluid">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.popinImplementation.rawCss') }}</th>
                  <td>
                    <AceEditor
                      :style="{ height: '30vh' }"
                      v-model="popinImplementation.rawCss"
                      lang="css"
                      :options="editorOptions"
                    ></AceEditor>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
        </persistent-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTab } from 'bootstrap-vue'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/less'

export default {
  props: {
    action: String
  },
  components: { MdSpinner, Dot, RegionFlag, BTab, AceEditor, PersistentTabs },
  data() {
    return {
      editorOptions: {
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false,
        readOnly: true
      },
      popinImplementation: null,
      popinImplementationLoading: false,
      popinImplementationError: null,
      createMultiproductWidgetForPopinLoading: {}
    }
  },
  computed: {
    // Get popin implementation script url
    popinImplementationScriptUrl: function() {
      return `${window.location.origin}/popin_implementations/${this.popinImplementation.key}.js`
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.popinImplementationLoading = true
      this.popinImplementationError = null

      const query = `query popinImplementationsShow ($id: Int!) {
        popinImplementation(id: $id) {
          id
          name
          key
          active
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
          customisation
          ctaText
          ctaIcon
          rawCss
          popins {
            id
            name
            key
            active
            widgetsCount
            multiproductWidgetId
          }
          autoMigration
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.popinImplementationLoading = false

          if (data.errors) {
            this.popinImplementationError = { status: res.status, errors: data.errors }
          } else {
            this.popinImplementation = Object.freeze(data.data.popinImplementation)
            this.loadPopinImplementationScript()
          }
        })
    },
    // Add popin implementation js script for preview
    loadPopinImplementationScript: function() {
      // Remove popin implementation script previously loaded
      if (window.c2bPopin) {
        delete window.c2bPopin
        document.querySelector('.C2BC_popin')?.remove()
        document.querySelector('.C2BC_popin_under')?.remove()
        document.querySelector('#productTplC2B')?.remove()
      }

      // Load script
      const script = document.createElement('script')
      script.src = this.popinImplementationScriptUrl
      document.head.appendChild(script)
    },
    // Create multiproduct widget
    createMultiproductWidget: async function(popin) {
      this.$set(this.createMultiproductWidgetForPopinLoading, popin.id, true)

      const mutation = `mutation generateMultiproductWidgetForPopin($input: GenerateMultiproductWidgetForPopinInput!) {
        generateMultiproductWidgetForPopin(input: $input) {
          popin {
            id
            multiproductWidgetId
          }
        }
      }`

      const variables = {
        input: {
          popinId: popin.id
        }
      }

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })

      const json = await res.json()

      this.$set(this.createMultiproductWidgetForPopinLoading, popin.id, false)

      if (!json.data.generateMultiproductWidgetForPopin.errors) {
        this.popinImplementation.popins.find(p => p.id === popin.id).multiproductWidgetId = json.data.generateMultiproductWidgetForPopin.popin.multiproductWidgetId

        this.$root.$bvToast.toast(this.$t('shared.success.multiproductWidget.create'), {
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      }
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
