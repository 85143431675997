
<div v-if="numberOfPages > 0" class="d-flex flex-wrap justify-content-between align-items-center gutter">
  <div class="overflow-auto">
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="numberOfPages"
      use-router
    ></b-pagination-nav>
  </div>
  <div>
    {{ pageEntriesInfo }}
  </div>
</div>
