<template>
  <div>
    <!-- Form element -->
    <div @click="showModal">
      <div class="form-control form-control-sm h-auto cursor-pointer">
        <div class="d-flex align-items-center justify-content-between gutter-x-sm">
          <div v-if="!value">
            {{ $t('shared.placeholders.select') }}
          </div>
          <div v-else class="d-flex flex-column flex-fill justify-content-between">
            <div>{{ value.product.productLanguageDatas[0].name }}</div>
            <small>
              <region-flag :code="value.group.region.code" />
              {{ value.group.name }}
            </small>
          </div>
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      :id="`widget-modal-${_uid}`"
      size="xl"
      :title="$t('views.widgetModalSelect.title')"
      hide-footer
      no-fade
      @shown="$refs.filterName.focus()"
    >
      <!-- Filters -->
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.productLanguageData.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            ref="filterName"
            :placeholder="$t('attributes.productLanguageData.name')"
          >
        </div>

        <div>
          <label for="filter-raw_packaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</label>
          <input
            v-model="filters.raw_packaging"
            type="text"
            class="form-control"
            id="filter-raw_packaging"
            :placeholder="$t('attributes.productLanguageData.rawPackaging')"
          >
        </div>

        <div>
          <label for="filter-reference">{{ $t('attributes.product.reference') }}</label>
          <input
            v-model="filters.reference"
            type="text"
            class="form-control"
            id="filter-reference"
            :placeholder="$t('attributes.product.reference')"
          >
        </div>

        <div>
          <label for="filter-ean">{{ $t('attributes.product.ean') }}</label>
          <input
            v-model="filters.ean"
            type="text"
            class="form-control"
            id="filter-ean"
            :placeholder="$t('attributes.product.ean')"
          >
        </div>

        <div>
          <label for="filter-upc">{{ $t('attributes.product.upc') }}</label>
          <input
            v-model="filters.upc"
            type="text"
            class="form-control"
            id="filter-upc"
            :placeholder="$t('attributes.product.upc')"
          >
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.product.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-has-img">{{ $t('attributes.product.img') }}</label>
          <select v-model="filters.hasImg" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">{{ $t('views.products.index.withoutImage') }}</option>
            <option :value="true">{{ $t('views.products.index.withImage') }}</option>
          </select>
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.product.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading"
          >
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <span class="multiselect__tag">
                <region-flag :code="option.code" />
                {{ option.name }}
                <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
              </span>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.product.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>
      </div>

      <hr>

      <!-- Widgets -->
      <h6>{{ $tc('views.widgetModalSelect.results', widgetsCount) }}</h6>

      <div class="table-responsive">
        <table class="table table-hover" :aria-busy="widgetsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.widget.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
              </th>
              <th-sortable sort-key="name">{{ $t('attributes.productLanguageData.name') }}</th-sortable>
              <th-sortable sort-key="raw_packaging" class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th-sortable>
              <th-sortable sort-key="reference">{{ $t('attributes.product.reference') }}</th-sortable>
              <th-sortable sort-key="ean">{{ $t('attributes.product.ean') }}</th-sortable>
              <th-sortable sort-key="upc">{{ $t('attributes.product.upc') }}</th-sortable>
              <th class="table-col-shrink">{{ $t('attributes.product.group') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <tbody v-if="widgetsError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="widgetsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!widgetsLoading && widgets.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noWidget') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <!-- Product rows -->
            <tr
              v-for="{ widget, selected } in widgetsWithStatus"
              :key="widget.id"
              @click="widgetClick(widget)"
              class="cursor-pointer"
              :class="{ 'bg-light': selected }">
              <th>{{ widget.id }}</th>
              <td class="text-center">
                <dot :active="widget.active" />
              </td>
              <td :id="`widget-${widget.id}-img`">
                <span v-if="widget.product.hasImg">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                  <b-tooltip :target="`widget-${widget.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                    <img :src="widget.product.img.medium.url" style="height: 200px;" />
                  </b-tooltip>
                </span>
                <span v-else class="text-danger">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                </span>
              </td>
              <b-tooltip :target="`widget-${widget.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                <img :src="widget.product.img.medium.url" style="height: 200px;" />
              </b-tooltip>
              <td class="ellipsis" style="max-width: 200px;">
                {{ widget.product.productLanguageDatas[0].name }}
              </td>
              <td class="ellipsis" style="max-width: 120px;">
                <small>{{ widget.product.productLanguageDatas[0].rawPackaging }}</small>
              </td>
              <td><small>{{ widget.product.reference }}</small></td>
              <td><small>{{ widget.product.ean }}</small></td>
              <td><small>{{ widget.product.upc }}</small></td>
              <td class="ellipsis" style="max-width: 150px;">
                <region-flag :code="widget.group.region.code" />
                {{ widget.group.name }}
              </td>
              <td class="text-nowrap text-right">
                <template v-if="selected">
                  <button type="button" class="btn btn-sm btn-primary" disabled>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
                    {{ $t('views.widgetModalSelect.selected') }}
                  </button>
                </template>
                <template v-else>
                  <button type="button" class="btn btn-sm btn-primary">
                    {{ $t('views.widgetModalSelect.select') }}
                  </button>
                </template>
              </td>
            </tr>

            <!-- Loading -->
            <template v-if="widgetsLoading">
              <tr>
                <td :colspan="colspan" class="text-center">
                  <md-spinner md-indeterminate></md-spinner>
                </td>
              </tr>
            </template>

            <!-- Next page -->
            <tr v-if="widgetsHasNextPage && !widgetsLoading">
              <td :colspan="colspan" class="bg-primary text-white cursor-pointer" @click="loadWidgetsPage">
                {{ $t('views.widgetModalSelect.loadMore') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import Dot from '../shared/Dot.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BModal, VBModal, BTooltip } from 'bootstrap-vue'
import ThSortable from '../shared/ThSortable.vue'
import debounce from 'lodash-es/debounce'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  name: 'WidgetModalSelect',
  inheritAttrs: false,
  components: { MdSpinner, RegionFlag, Dot, KeyMultiselect, GroupModalSelect, BModal, BTooltip, ThSortable },
  directives: { 'b-modal': VBModal },
  props: {
    initialWidgetKey: String,
    disabled: Boolean,
    groupId: {
      type: Number,
      default: undefined
    },
    filterGroup: {
      type: Object,
      default: undefined
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      value: null,
      modalInitialized: false,
      widgets: [],
      widgetsLoading: false,
      widgetsError: null,
      widgetsCount: 0,
      widgetsPage: 1,
      widgetsPerPage: 20,
      widgetsHasNextPage: false,
      widgetsAbortController: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      filters: {
        name: null,
        raw_packaging: null,
        reference: null,
        ean: null,
        upc: null,
        active: null,
        hasImg: null,
        regionIds: [],
        groups: []
      },
      colspan: 10
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Products with selected status
    widgetsWithStatus: function() {
      return this.widgets.map(widget => {
        const selected = this.value && this.value.id === widget.id

        return {
          widget,
          selected
        }
      })
    }
  },
  methods: {
    // Show modal
    showModal: function() {
      this.$root.$emit('bv::show::modal', `widget-modal-${this._uid}`)
      if (!this.modalInitialized) {
        this.modalInitialized = true
        this.loadRegions()
        this.loadWidgetsPage()
      }
    },
    // Load regions
    loadRegions: async function() {
      this.regionsLoading = true
      this.regionsError = null

      const query = `query widgetModalSelectRegions {
        regions {
          id
          code
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query })
        })
        const json = await res.json()

        if (!res.ok) {
          this.regionsError = json.errors
        } else {
          this.regions = Object.freeze(json.data.regions)
        }
      } catch (err) {
        this.error = err
      } finally {
        this.regionsLoading = false
      }
    },
    // Load widgets page
    loadWidgetsPage: async function() {
      if (this.widgetsAbortController) {
        this.widgetsAbortController.abort()
      }
      this.widgetsAbortController = new AbortController()

      this.widgetsLoading = true
      this.widgetsError = null

      const query = `query widgetModalSelectProducts ($page: Int!, $perPage: Int!, $groupId: Int, $name: String, $productEan: String, $productUpc: String, $productReference: String, $active: Boolean, $regionIds: [Int!], $groupIds: [Int!], $sort: String, $sortDirection: String) {
        widgetsPage(page: $page, perPage: $perPage, groupId: $groupId, name: $name, productEan: $productEan, productUpc: $productUpc, productReference: $productReference, active: $active, regionIds: $regionIds, groupIds: $groupIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          hasNextPage
          nodes {
            id
            key
            name
            active
            product {
              id
              active
              ean
              upc
              reference
              img
              hasImg
              productLanguageDatas {
                id
                name
                rawPackaging
              }
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          signal: this.widgetsAbortController.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: {
              page: this.widgetsPage,
              perPage: this.widgetsPerPage,
              groupId: this.groupId,
              name: this.filters.name,
              rawPackaging: this.filters.raw_packaging,
              productEan: this.filters.ean,
              productUpc: this.filters.upc,
              productReference: this.filters.reference,
              active: this.filters.active,
              hasImg: this.filters.hasImg,
              regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
              groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
              sort: this.$route.query.sort,
              sortDirection: this.$route.query.sort_direction
            }
          })
        })
        const json = await res.json()

        if (!res.ok) {
          this.widgetsError = json.errors
        } else {
          this.widgets = this.widgets.concat(json.data.widgetsPage.nodes)
          this.widgetsCount = json.data.widgetsPage.nodesCount
          this.widgetsHasNextPage = json.data.widgetsPage.hasNextPage
          if (this.widgetsHasNextPage) this.widgetsPage++
        }
      } catch (err) {
        this.error = err
      } finally {
        this.widgetsLoading = false
      }
    },
    // Load widgets debounced
    loadWidgetsPageDebounced: debounce(function() {
      this.widgets = []
      this.widgetsPage = 1
      this.loadWidgetsPage()
    }, 200),
    // Widget click
    widgetClick: function(widget) {
      this.value = widget

      // Emit input & close
      this.$emit('input', widget)

      if (this.closeOnSelect) {
        this.$root.$emit('bv::hide::modal', `widget-modal-${this._uid}`)
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function() {
        this.loadWidgetsPageDebounced()
      }
    },
    filterGroup: function(group) {
      this.filters.groups = [group]
    },
    $route: function() {
      this.loadWidgetsPageDebounced()
    }
  },
  created: async function() {
    if (this.initialWidgetKey) {
      // Load widget data to set initial value
      const widgetId = parseInt(this.initialWidgetKey.split('-')[0])

      const query = gql`
        query widgetModalSelectWidget ($id: Int!) {
          widget(id: $id) {
            id
            key
            name
            active
            product {
              id
              active
              ean
              upc
              reference
              img
              hasImg
              productLanguageDatas {
                id
                name
                rawPackaging
              }
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      `

      const variables = { id: widgetId }

      const { data } = await client.query({ query, variables })

      this.value = data.widget
    }
  }
}
</script>
