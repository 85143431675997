<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="groupLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="groupError" class="alert alert-danger">
        <template v-if="groupError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>
            <region-flag :code="group.region.code" />
            {{ group.name }}
          </h5>
          <router-link
            :to="{ name: 'editGroup', params: { id: group.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <b-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.groups.show.tabs.infos')" active>
            <template v-if="groupLoading">
              <div class="text-center">
                <md-spinner md-indeterminate></md-spinner>
              </div>
            </template>
            <template v-else>
              <table class="table">
                <tr>
                  <th class="table-col-shrink">{{ $t('attributes.group.id') }}</th>
                  <td>{{ group.id }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.name') }}</th>
                  <td>{{ group.name }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.group.displayName') }}</th>
                  <td>
                    <template v-if="group.displayName">{{ group.displayName }}</template>
                    <template v-else>–</template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.region') }}</th>
                  <td>
                    <region-flag :code="group.region.code" />
                    {{ regionName(group.region.code) }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.parent') }}</th>
                  <td>
                    <template v-if="group.parent">
                      <router-link :to="{ name: 'group', params: { id: group.parent.id } }">
                        <region-flag :code="group.parent.region.code" />
                        {{ group.parent.name }}
                      </router-link>
                    </template>
                    <template v-else>–</template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.children') }}</th>
                  <td>
                    <template v-if="group.children">
                      {{ group.childrenCount }}
                    </template>
                    <template v-else>–</template>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span
                      v-b-tooltip.hover
                      :title="$t('views.groups.tooltip.baselineValidationRate')"
                    >
                      {{ $t('attributes.group.baselineValidationRate') }}
                      <sup>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                      </sup>
                    </span>
                  </th>
                  <td>
                    <template v-if="group.baselineValidationRate">
                      {{ group.baselineValidationRate | percentage }}
                    </template>
                    <template v-else-if="group.effectiveBaselineValidationGroup">
                      <div class="d-flex flex-column" style="row-gap: 0.25rem;">
                        <span>
                          {{ group.effectiveBaselineValidationGroup.baselineValidationRate | percentage }}
                        </span>

                        <span>
                          {{ $t('views.groups.inheritedFrom') }}
                          <router-link :to="{ name: 'group', params: { id: group.effectiveBaselineValidationGroup.id } }">
                            <region-flag :code="group.effectiveBaselineValidationGroup.region.code" />
                            {{ group.effectiveBaselineValidationGroup.name }}
                          </router-link>
                        </span>
                      </div>
                    </template>
                    <template v-else>–</template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.active') }}</th>
                  <td>
                    <dot :active="group.active" />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.group.img') }}</th>
                  <td>
                    <template v-if="group.img.url">
                      <img :src="group.img.url" class="border" />
                    </template>
                    <template v-else>-</template>
                  </td>
                </tr>
              </table>
            </template>
          </b-tab>

          <!-- Tree view tab -->
          <b-tab :title="$t('views.groups.show.tabs.treeView')" @click.once="brandTreeInitialized = true">
            <tree
              v-if="brandTreeInitialized"
              :node="group.rootGroup"
              class="tree"
              :load-children="handleOpenTreeNode"
            >
              <template v-slot:default="{ node }">
                <router-link :to="{ name: 'group', params: { id: node.id } }" :class="{ 'font-weight-bold': node.id == id }">
                  <dot :active="node.active" />
                  <region-flag v-if="internationalRoot" :code="node.region.code" />
                  {{ node.name }}
                </router-link>
              </template>
            </tree>
          </b-tab>

          <!-- Users tab -->
          <b-tab>
            <template v-slot:title>
              {{ $t('views.groups.show.tabs.users') }}
              <span v-if="!groupLoading" class="badge badge-primary">{{ group.users.length }}</span>
            </template>

            <template v-if="groupLoading">
              <md-spinner md-indeterminate></md-spinner>
            </template>
            <template v-else>
              <div v-if="group.users.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noUser') }}
              </div>
              <table class="table" v-else>
                <thead>
                  <tr>
                    <th class="table-col-shrink">{{ $t('attributes.user.id') }}</th>
                    <th class="table-col-shrink"></th>
                    <th>{{ $t('attributes.user.email') }}</th>
                    <th>{{ $t('attributes.user.roles') }}</th>
                    <th class="table-col-shrink"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in group.users" :key="user.id">
                    <th>{{ user.id }}</th>
                    <td class="text-center">
                      <dot :active="user.active" />
                    </td>
                    <td>{{ user.email }}</td>
                    <td class="gutter-xs">
                      <span class="badge badge-secondary" v-for="role in user.roles" :key="role">
                        {{ role }}
                      </span>
                    </td>
                    <td>
                      <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                        <template v-slot:button-content>
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                        </template>
                        <router-link :to="{ name: 'user', params: { id: user.id } }" class="dropdown-item">
                          {{ $t('shared.actions.show') }}
                        </router-link>
                        <router-link :to="{ name: 'editUser', params: { id: user.id } }" class="dropdown-item">
                          {{ $t('shared.actions.edit') }}
                        </router-link>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-tab>

          <!-- Retailers tab -->
          <b-tab :title="$t('views.groups.show.tabs.retailers')" @click.once="loadRetailers">
            <!-- Distribution methods -->
            <div class="form-group row">
              <label class="col-sm-2">{{ $t('attributes.group.distributionMethods') }}</label>
              <div class="col-sm-10 col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="th-shrink"></th>
                      <th></th>
                      <th>{{ $t('views.groups.inheritedFrom') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="groupDistributionMethod in groupDistributionMethodsWithStatus" :key="groupDistributionMethod.id">
                      <td><dot :active="groupDistributionMethod.active" /></td>
                      <td>{{ $t(`shared.retailerDistributions.${groupDistributionMethod.deliveryMethod.name}`) }}</td>
                      <td>
                        <ul v-if="groupDistributionMethod.inheritedFrom" class="list-unstyled mb-0">
                          <li v-for="group in groupDistributionMethod.inheritedFrom" :key="group.id">
                            <router-link :to="{ name: 'group', params: { id: group.id } }">
                              <region-flag :code="group.region.code" />
                              {{ group.name }}
                            </router-link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Retailer exceptions -->
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ $t('attributes.group.retailerExceptions') }}</label>
              <div class="col-sm-10 col-12">
                <template v-if="retailersLoading">
                  <div class="text-center">
                    <md-spinner md-indeterminate></md-spinner>
                  </div>
                </template>
                <template v-else>
                  <button type="button" class="btn btn-primary mb-3" @click="exportRetailerExceptionsAsXLSX">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
                    {{ $t('shared.actions.xlsxExport') }}
                  </button>
                  <b-tabs content-class="mt-3">
                    <b-tab v-for="distributionMethod in group.distributionMethods" :key="distributionMethod">
                      <template v-slot:title>
                        {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                        <span class="badge badge-primary">
                          {{ retailerExceptionsCount[distributionMethod] }}
                        </span>
                      </template>

                      <div
                        v-if="retailerExceptionsCount[distributionMethod] === 0"
                        class="alert alert-danger">
                        {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                      </div>

                      <table class="table">
                        <thead>
                          <tr>
                            <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                            <th class="th-shrink"></th>
                            <th>{{ $t('attributes.retailer.name') }}</th>
                            <th>{{ $t('views.groups.inheritedFrom') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="retailer in retailersWithStatus[distributionMethod]"
                            :key="retailer.id"
                            :class="{ 'table-danger alert-danger': retailer.exception }">
                            <th>{{ retailer.id }}</th>
                            <td><img :src="retailer.imgSmallUrl" style="max-height: 20px;"></td>
                            <td>{{ retailer.name }}</td>
                            <td>
                              <ul v-if="retailer.inheritedFrom" class="list-unstyled mb-0">
                                <li v-for="group in retailer.inheritedFrom" :key="group.id">
                                  <router-link :to="{ name: 'group', params: { id: group.id } }" class="alert-link">
                                    <region-flag :code="group.region.code" />
                                    {{ group.name }}
                                  </router-link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab>
                  </b-tabs>
                </template>
              </div>
            </div>
          </b-tab>

          <!-- Report tab -->
          <b-tab :title="$t('views.groups.show.tabs.report')">
            <template v-if="groupLoading">
              <div class="text-center">
                <md-spinner md-indeterminate></md-spinner>
              </div>
            </template>
            <template v-else>
              <!-- Report activation checkbox -->
              <div class="form-group row">
                <label for="checkboxSendReport" class="col-sm-2">{{ $t('attributes.group.sendReport') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch">
                    <input
                      v-model="group.sendReport"
                      @change="updateGroupSendReport()"
                      type="checkbox"
                      class="custom-control-input"
                      id="checkboxSendReport">
                    <label class="custom-control-label" for="checkboxSendReport"></label>
                  </div>
                </div>
              </div>

              <template v-if="group.sendReport">
                <!-- Report recipients -->
                <div class="mb-2 d-flex justify-content-between align-items-end">
                  <h5>Report recipients</h5>
                  <button type="button" class="btn btn-success" @click="toggleReportRecipientModal('create')">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                    {{ $t('shared.actions.newReportRecipient') }}
                  </button>
                </div>

                <!-- New report recipient modal -->
                <b-modal
                  id="reportRecipientModal"
                  ref="reportRecipientModal"
                  :title="reportRecipient.action === 'create' ? $t('shared.actions.newReportRecipient') : $t('shared.actions.updateReportRecipient')"
                  @shown="$refs.reportRecipientMailInput.focus()"
                  @ok="handleOk"
                  no-fade>
                  <form @submit.stop.prevent="handleSubmit()">
                    <div class="form-group row mb-0">
                      <label for="mail-input" class="col-sm-2 col-form-label">{{ $t('attributes.reportRecipient.mail') }}</label>
                      <div class="col-lg-6 col-sm-8 col-12">
                        <input
                          v-model="reportRecipient.mail"
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid' : reportRecipientSubmitErrors && reportRecipientSubmitErrors.mail }"
                          id="mail-input"
                          :placeholder="$t('attributes.reportRecipient.mail')"
                          ref="reportRecipientMailInput">
                        <div v-if="reportRecipientSubmitErrors && reportRecipientSubmitErrors.mail" class="invalid-feedback">{{ tErrors('reportRecipient', 'mail', reportRecipientSubmitErrors.mail) }}</div>
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="select-locale" class="col-sm-2 col-form-label">{{ $t('attributes.reportRecipient.language') }}</label>
                      <div class="col-lg-6 col-sm-8 col-12">
                        <select v-model="reportRecipient.locale" class="custom-select">
                          <option v-for="locale in locales" :key="locale.code" :value="locale.code">{{ locale.name }}</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </b-modal>

                <div v-if="group.reportRecipients.length === 0" class="alert alert-warning">
                  {{ $t('shared.warnings.noReportRecipient') }}
                </div>
                <table class="table" v-else>
                  <thead>
                    <tr>
                      <th>{{ $t('attributes.reportRecipient.mail') }}</th>
                      <th>{{ $t('attributes.reportRecipient.language') }}</th>
                      <th class="table-col-shrink"></th>
                      <th class="table-col-shrink"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="recipient in group.reportRecipients" :key="recipient.id">
                      <td>{{ recipient.mail }}</td>
                      <td>{{ getLocaleName(recipient.locale) }}</td>
                      <td>
                        <button type="button" class="btn btn-warning" @click="toggleReportRecipientModal('update', recipient)">{{ $t('shared.actions.edit') }}</button>
                      </td>
                      <td>
                        <button type="button" class="btn btn-danger" @click="deleteReportRecipient(recipient.id)">
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </b-tab>

          <!-- Custom pages tab -->
          <b-tab :title="$t('views.groups.show.tabs.customPages')">
            <template v-slot:title>
              {{ $t('views.groups.show.tabs.customPages') }}
              <span class="badge badge-primary">
                {{ group.customPages.length }}
              </span>
            </template>

            <template v-if="groupLoading">
              <div class="text-center">
                <md-spinner md-indeterminate></md-spinner>
              </div>
            </template>
            <template v-else>
              <table v-if="group.customPages.length" class="table">
                <thead>
                  <tr>
                    <th class="table-col-shrink">{{ $t('attributes.customPage.id') }}</th>
                    <th class="table-col-shrink"></th>
                    <th>{{ $t('attributes.customPage.name') }}</th>
                    <th>{{ $t('attributes.customPage.icon') }}</th>
                    <th>{{ $t('attributes.customPage.url') }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="customPage of group.customPages" :key="customPage.id">
                    <td>{{ customPage.id }}</td>
                    <td>
                      <dot :active="customPage.active" />
                    </td>
                    <td>{{ customPage.name }}</td>
                    <td>
                      <span class="custom-page-icon" v-html="customPage.icon"></span>
                    </td>
                    <td>
                      <a :href="customPage.url" target="_blank">
                        {{ customPage.url }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="alert alert-warning">
                {{ $t('shared.warnings.noCustomPage') }}
              </div>
            </template>
          </b-tab>

          <!-- Tracking Params tab -->
          <b-tab :title="$t('views.groups.show.tabs.trackingParams')">
            <template v-slot:title>
              {{ $t('views.groups.show.tabs.trackingParams') }}
              <span class="badge badge-primary">
                {{ group.allTrackingParams.length }}
              </span>
            </template>

            <template v-if="groupLoading">
              <div class="text-center">
                <md-spinner md-indeterminate></md-spinner>
              </div>
            </template>
            <template v-else>
              <table v-if="group.allTrackingParams.length" class="table">
                <thead>
                  <tr>
                    <th class="table-col-shrink">{{ $t('attributes.trackingParam.id') }}</th>
                    <th>{{ $t('attributes.trackingParam.key') }}</th>
                    <th>{{ $t('views.groups.inheritedFrom') }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="trackingParam of trackingParamsSorted" :key="trackingParam.id">
                    <td>{{ trackingParam.id }}</td>
                    <td>{{ trackingParam.key }}</td>
                    <td>
                      <router-link
                        v-if="trackingParam.group.id !== group.id"
                        :to="{ name: 'group', params: { id: trackingParam.group.id } }"
                        class="alert-link"
                      >
                        <region-flag :code="trackingParam.group.region.code" />
                        {{ trackingParam.group.name }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="alert alert-warning">
                {{ $t('shared.warnings.noTrackingParam') }}
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import Tree from '../shared/Tree.vue'
import { BTabs, BTab, BDropdown, BModal, VBModal, VBTooltip } from 'bootstrap-vue'
import * as XLSX from 'xlsx'
import snakeCase from 'lodash-es/snakeCase'

export default {
  components: { MdSpinner, Dot, RegionFlag, Tree, BTabs, BTab, BDropdown, BModal },
  directives: { 'b-modal': VBModal, 'b-tooltip': VBTooltip },
  props: ['id'],
  data: function() {
    return {
      group: null,
      groupLoading: false,
      groupError: null,
      retailers: null,
      retailersLoading: false,
      retailersError: null,
      groupSendReportUpdateLoading: false,
      groupSendReportUpdateErrors: null,
      reportRecipient: {
        id: null,
        mail: null,
        locale: 'fr',
        action: null
      },
      reportRecipientSubmitLoading: false,
      reportRecipientSubmitErrors: null,
      distributionMethods: ['drive', 'delivery', 'store'],
      expandBrands: {},
      brandTreeInitialized: false,
      locales: [
        {
          code: 'fr',
          name: 'Français'
        },
        {
          code: 'en',
          name: 'English'
        },
        {
          code: 'de',
          name: 'Deutsch'
        },
        {
          code: 'es',
          name: 'Español'
        }
      ]
    }
  },
  computed: {
    internationalRoot: function() {
      return this.group ? this.group.rootGroup.region.code === 'INTERNATIONAL' : false
    },
    // Group distribution methods with inactive inheritance
    groupDistributionMethodsWithStatus: function() {
      return this.group.groupDeliveryMethods.slice(0)
        .sort((a, b) => a.orderState - b.orderState)
        .map(groupDeliveryMethod => {
          const ancestorInactiveDeliveryMethod = this.group.ancestorsInactiveDeliveryMethods.find(deliveryMethod => deliveryMethod.name === groupDeliveryMethod.deliveryMethod.name)

          groupDeliveryMethod.active = groupDeliveryMethod.active && !ancestorInactiveDeliveryMethod

          if (ancestorInactiveDeliveryMethod) {
            groupDeliveryMethod.inheritedFrom = ancestorInactiveDeliveryMethod.groups
          }

          return groupDeliveryMethod
        })
    },
    // Retailers with exception info
    retailersWithStatus: function() {
      const retailersWithStatus = {}
      if (this.retailers) {
        this.distributionMethods.forEach(distributionMethod => {
          retailersWithStatus[distributionMethod] = this.retailers.filter(retailer => {
            return retailer.service === distributionMethod &&
              retailer.region.id === this.group.region.id
          }).map(retailer => {
            const retailerWithStatus = { ...retailer }

            const directException = this.group.retailerExceptions.map(retailer => retailer.id).includes(retailer.id)
            const ancestorRetailerException = this.group.ancestorsRetailerExceptions.find(ancestorRetailerException => ancestorRetailerException.id === retailer.id)

            retailerWithStatus.exception = directException || !!ancestorRetailerException

            if (ancestorRetailerException) {
              retailerWithStatus.inheritedFrom = ancestorRetailerException.groups
            }

            return retailerWithStatus
          }).sort((a, b) => {
            if ((a.exception && b.exception) || (!a.exception && !b.exception)) {
              return a.name.localeCompare(b.name)
            } else {
              return b.exception - a.exception
            }
          })
        })
      }
      return retailersWithStatus
    },
    // Retailer exceptions count by distribution method
    retailerExceptionsCount: function() {
      const retailerExceptionsCount = {}
      if (this.retailers) {
        this.distributionMethods.forEach(distributionMethod => {
          retailerExceptionsCount[distributionMethod] = this.retailersWithStatus[distributionMethod].filter(retailer => retailer.exception).length
        })
      }
      return retailerExceptionsCount
    },
    trackingParamsSorted: function() {
      if (!this.group) return []

      // Sort tracking params by group and key
      return this.group.allTrackingParams.slice(0).sort((a, b) => {
        if (a.group.id !== this.group.id && b.group.id === this.group.id) {
          return 1
        } else if (a.group.id === this.group.id && b.group.id !== this.group.id) {
          return -1
        } else {
          return a.key.localeCompare(b.key)
        }
      })
    }
  },
  methods: {
    toggleReportRecipientModal: function(action, reportRecipient) {
      if (action === 'create') {
        this.reportRecipient = {
          mail: null,
          locale: 'fr',
          action: 'create'
        }
      } else {
        this.reportRecipient = {
          id: reportRecipient.id,
          mail: reportRecipient.mail,
          locale: reportRecipient.locale,
          action: 'update'
        }
      }

      this.$refs.reportRecipientModal.show()
    },
    getLocaleName: function(code) {
      const locale = this.locales.find(locale => locale.code === code)

      if (locale) {
        return locale.name
      } else {
        return ''
      }
    },
    // Load data
    loadData: function() {
      this.groupLoading = true
      this.groupError = null
      this.retailersLoading = true
      this.retailersError = null

      const query = `query groupsShow ($id: Int!) {
        group(id: $id) {
          id
          name
          displayName
          distributionMethods
          baselineValidationRate
          region {
            id
            code
          }
          parent {
            id
            name
            region {
              id
              code
            }
          }
          rootGroup {
            id
            name
            childrenCount
            active
            region {
              id
              code
            }
          }
          childrenCount
          active
          img
          users {
            id
            email
            active
            roles
          }
          groupDeliveryMethods {
            id
            active
            orderState
            deliveryMethod {
              id
              name
            }
          }
          ancestorsInactiveDeliveryMethods {
            id
            name
            groups {
              id
              name
              region {
                id
                code
              }
            }
          }
          sendReport
          reportRecipients {
            id
            mail
            locale
          }
          customPages {
            id
            name
            icon
            url
            active
          }
          effectiveBaselineValidationGroup {
            id
            name
            baselineValidationRate
            region {
              code
            }
          }
          allTrackingParams {
            id
            key
            group {
              id
              name
              region {
                code
              }
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupLoading = false
          this.retailersLoading = false

          if (data.errors) {
            this.groupError = { status: res.status, errors: data.errors }
          } else {
            this.group = data.data.group
          }
        })
    },
    // Update group sendReport
    updateGroupSendReport: function() {
      this.groupSendReportUpdateLoading = true
      this.groupSendReportUpdateErrors = null

      const variables = {
        input: {
          groupId: this.$route.params.id,
          attributes: {
            sendReport: this.group.sendReport
          }
        }
      }

      const mutation = `mutation($input: UpdateGroupInput!) {
        updateGroup(input: $input) {
          group {
            id
          }
          errors
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupSendReportUpdateLoading = false

          if (data.data.updateGroup.errors) {
            this.groupSendReportUpdateErrors = data.data.updateGroup.errors
          } else {
            this.$root.$bvToast.toast(this.$t('shared.success.group.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    },
    // Modal ok click
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    // Modal form submit
    handleSubmit() {
      if (this.reportRecipient.action === 'create') {
        this.createReportRecipient()
      } else {
        this.updateReportRecipient()
      }
    },
    // Create a report recipient
    createReportRecipient: function() {
      this.reportRecipientSubmitLoading = true
      this.reportRecipientSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            groupId: this.$route.params.id,
            mail: this.reportRecipient.mail,
            locale: this.reportRecipient.locale
          }
        }
      }

      const mutation = `mutation($input: CreateReportRecipientInput!) {
        createReportRecipient(input: $input) {
          reportRecipient {
            id
          }
          errors
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.reportRecipientSubmitLoading = false

          if (data.data.createReportRecipient.errors) {
            this.reportRecipientSubmitErrors = data.data.createReportRecipient.errors
          } else {
            this.loadData()
            this.$root.$bvToast.toast(this.$t('shared.success.reportRecipient.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    },
    // Update report recipient
    updateReportRecipient: async function() {
      this.reportRecipientSubmitLoading = true
      this.reportRecipientSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            mail: this.reportRecipient.mail,
            locale: this.reportRecipient.locale
          },
          reportRecipientId: this.reportRecipient.id
        }
      }

      const mutation = `mutation($input: UpdateReportRecipientInput!) {
        updateReportRecipient(input: $input) {
          reportRecipient {
            id
          }
          errors
        }
      }`

      const response = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })
      const data = await response.json()
      this.reportRecipientSubmitLoading = false

      if (data.data.updateReportRecipient.errors) {
        this.reportRecipientSubmitErrors = data.data.createReportRecipient.errors
      } else {
        this.loadData()
        this.$root.$bvToast.toast(this.$t('shared.success.reportRecipient.update'), {
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      }
    },
    // Delete a report recipient
    deleteReportRecipient: function(reportRecipientId) {
      if (confirm(this.$t('shared.confirm.delete'))) {
        const variables = {
          input: {
            reportRecipientId
          }
        }

        const mutation = `mutation($input: DestroyReportRecipientInput!) {
          destroyReportRecipient(input: $input) {
            reportRecipient {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            if (data.data.destroyReportRecipient.errors) {
              const text = data.data.createReportRecipient.errors
              this.$root.$bvToast.toast(text, {
                variant: 'danger',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            } else {
              this.loadData()
              this.$root.$bvToast.toast(this.$t('shared.success.reportRecipient.delete'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    },
    // Export retailer exceptions as XLSX
    exportRetailerExceptionsAsXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_retailers.xlsx`

      // Create xlsx
      const wb = XLSX.utils.book_new()

      // Create a sheet for each distribution method
      for (const [distributionMethod, retailers] of Object.entries(this.retailersWithStatus)) {
        const retailersFormatted = retailers.map(retailer => {
          return {
            id: retailer.id,
            name: retailer.name,
            exception: retailer.exception,
            inherited_from_group_id: retailer.inheritedFrom ? retailer.inheritedFrom[0].id : null,
            inherited_from_group_name: retailer.inheritedFrom ? retailer.inheritedFrom[0].name : null
          }
        })
        const ws = XLSX.utils.json_to_sheet(retailersFormatted)
        XLSX.utils.book_append_sheet(wb, ws, distributionMethod)
      }

      // Download xlsx file
      XLSX.writeFile(wb, filename)
    },
    handleOpenTreeNode: async function(id) {
      const query = `query groupsShowChildren ($id: Int!) {
        group(id: $id) {
          children {
            id
            name
            region {
              id
              code
            }
            childrenCount
            active
          }
        }
      }`

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id
          }
        })
      })

      const json = await res.json()

      if (json.errors) {
        this.groupError = { status: res.status, errors: json.errors }
      } else {
        return json.data.group.children
      }
    },
    loadRetailers: async function() {
      const query = `query retailersLoading ($id: Int!) {
        group(id: $id) {
          retailerExceptions {
              ...retailerFields
          }
          ancestorsRetailerExceptions {
            ...retailerFields
            groups {
              id
              name
              region {
                id
                code
              }
            }
          }
        }

        retailers(disabled: false) {
          ...retailerFields
        }
      }

      fragment retailerFields on Retailer {
        id
        name
        imgSmallUrl
        service
        region {
          id
          code
        }
      }`

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })

      const json = await res.json()

      if (json.errors) {
        this.retailersError = { status: res.status, errors: json.errors }
      } else {
        this.group.retailerExceptions = json.data.group.retailerExceptions
        this.group.ancestorsRetailerExceptions = json.data.group.ancestorsRetailerExceptions
        this.retailers = Object.freeze(json.data.retailers)
      }
    }
  },
  filters: {
    percentage: function(percentage) {
      return percentage.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>

<style>
.tree ul{
  padding-left: 1.5rem;
}
.tree{
  margin-left: 0.7rem;
}
</style>
