<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="multiproductLandingPageLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="multiproductLandingPageError" class="alert alert-danger">
        <template v-if="multiproductLandingPageError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ multiproductLandingPage.name }}</h5>
          <router-link :to="{ name: 'editMultiproductLandingPage', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.infos')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPage.id') }}</th>
                  <td>{{ multiproductLandingPage.id }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.key') }}</th>
                  <td><code>{{ multiproductLandingPage.key }}</code></td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.url') }}</th>
                  <td>
                    <a :href="multiproductLandingPage.url" target="_blank">{{ multiproductLandingPage.url }}</a>
                  </td>
                </tr>
                <tr>
                  <!-- todo url v2 -->
                  <th>{{ $t('attributes.multiproductLandingPage.urlV2') }}</th>
                  <td>
                    <a :href="multiproductLandingPage.urlV2" target="_blank">{{ multiproductLandingPage.urlV2 }}</a>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.name') }}</th>
                  <td>{{ multiproductLandingPage.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.group') }}</th>
                  <td>
                    <router-link :to="{ name: 'group', params: { id: multiproductLandingPage.group.id } }">
                      <region-flag :code="multiproductLandingPage.group.region.code" />
                      {{ multiproductLandingPage.group.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.groupActive') }}</th>
                  <td>
                    <dot :active="multiproductLandingPage.group.active" />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.multiproduct') }}</th>
                  <td><dot :active="multiproductLandingPage.multiproduct" /></td>
                </tr>
                <tr v-if="!multiproductLandingPage.multiproduct">
                  <th>{{ $t('attributes.multiproductLandingPage.defaultWidget') }}</th>
                  <td>
                    <template v-if="multiproductLandingPage.defaultWidget">
                      <router-link :to="{ name: 'widget', params: { id: multiproductLandingPage.defaultWidget.id } }">
                        {{ multiproductLandingPage.defaultWidget.name }}
                      </router-link>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.active') }}</th>
                  <td><dot :active="multiproductLandingPage.active" /></td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.customisation') }}</th>
                  <td>
                    <template v-if="multiproductLandingPage.customisation">
                      <router-link :to="{ name: 'customisation', params: { id: multiproductLandingPage.customisation.id } }">
                        {{ multiproductLandingPage.customisation.name }}
                      </router-link>
                    </template>
                    <template v-else>–</template>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Categories tab -->
          <b-tab v-if="multiproductLandingPage.multiproduct">
            <template v-slot:title>
              {{ $t('views.multiproductLandingPages.tabs.categories') }}
              <span class="badge badge-primary">{{ multiproductLandingPage.rootCategories.length }}</span>
            </template>

            <!-- Root categories list -->
            <div v-if="multiproductLandingPage.rootCategories.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noMultiproductLandingPageCategory') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.id') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPageCategory.name') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.active') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.subcategories') }}</th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="category in multiproductLandingPage.rootCategories"
                  :key="category.id">
                  <th>{{ category.id }}</th>
                  <td class="text-center">
                    <img v-if="category.categoryImage.url" :src="category.categoryImage.url" class="td-img">
                  </td>
                  <td>{{ category.name }}</td>
                  <td class="text-center"><dot :active="category.active" /></td>
                  <td class="text-center">{{ category.multiproductLandingPageCategoryWidgets.length }}</td>
                  <td class="text-center">{{ category.subcategories.length }}</td>
                  <td class="text-center">
                    <button type="button" class="btn btn-sm btn-primary" @click="showCategory(category)">
                      {{ $t('shared.actions.show') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Category modal -->
            <b-modal
              id="categoryModal"
              ref="categoryModal"
              size="xl"
              :title="$t('views.multiproductLandingPages.show.category')"
              ok-only
              no-fade>
              <div class="form-group row">
                <label for="input-category-name" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">{{ categoryModal.name }}</div>
              </div>

              <div class="form-group row">
                <label for="category-image-input" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div v-if="categoryModal.categoryImage.url" class="border mb-3 d-inline-block">
                    <img :src="categoryModal.categoryImage.url" class="img-fluid">
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="category-active-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <dot :active="categoryModal.active" />
                </div>
              </div>

              <!-- Category widgets -->
              <div class="form-group row">
                <label class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</label>
                <div class="col-sm-10 col-12">
                  <div v-if="categoryModal.multiproductLandingPageCategoryWidgets.length === 0" class="alert alert-warning" role="alert">
                    {{ $t('shared.warnings.noWidget') }}
                  </div>
                  <table
                    class="table table-hover"
                    v-else>
                    <tbody>
                      <tr
                        v-for="multiproductLandingPageCategoryWidget in categoryModal.multiproductLandingPageCategoryWidgets"
                        :key="multiproductLandingPageCategoryWidget.widget.id"
                        class="table-col-shrink">
                        <th class="table-col-shrink">{{ multiproductLandingPageCategoryWidget.widget.id }}</th>
                        <td>
                          <table
                            v-if="widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]"
                            class="table m-n2">
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.widget.name') }}</th>
                              <td>{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                            </tr>
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.widget.publicName') }}</th>
                              <td>{{ multiproductLandingPageCategoryWidget.widget.publicName }}</td>
                            </tr>
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.multiproductLandingPageCategoryWidget.labelHtml') }}</th>
                              <td>
                                <AceEditor
                                  :style="{ height: '8vh' }"
                                  v-model="multiproductLandingPageCategoryWidget.labelHtml"
                                  lang="html"
                                  :options="editorOptions"
                                ></AceEditor>
                              </td>
                            </tr>
                          </table>
                          <template v-else>
                            {{ multiproductLandingPageCategoryWidget.widget.name }}
                          </template>
                        </td>
                        <td class="table-col-shrink">
                          <button type="button" class="btn btn-secondary btn-sm" @click="toggleWidgetExpanded(multiproductLandingPageCategoryWidget.widget.id)">
                            <template v-if="!widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                            </template>
                            <template v-else>
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                            </template>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Category subcategories -->
              <div class="form-group row">
                <label class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.subcategories') }}</label>
                <div class="col-sm-10 col-12">
                  <div v-if="categoryModal.subcategories.length === 0" class="alert alert-warning">
                    {{ $t('shared.warnings.noMultiproductLandingPageCategory') }}
                  </div>
                  <table v-else class="table table-hover">
                    <thead>
                      <tr>
                        <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.id') }}</th>
                        <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</th>
                        <th>{{ $t('attributes.multiproductLandingPageCategory.name') }}</th>
                        <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.active') }}</th>
                        <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</th>
                        <th class="th-shrink"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="category in categoryModal.subcategories"
                        :key="category.id">
                        <th>{{ category.id }}</th>
                        <td class="text-center">
                          <img v-if="category.categoryImage.url" :src="category.categoryImage.url" class="td-img">
                        </td>
                        <td>{{ category.name }}</td>
                        <td class="text-center"><dot :active="category.active" /></td>
                        <td class="text-center">{{ category.multiproductLandingPageCategoryWidgets.length }}</td>
                        <td class="text-nowrap">
                          <button type="button" class="btn btn-sm btn-primary" @click="showSubcategory(category)">
                            {{ $t('shared.actions.show') }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-modal>

            <!-- Subcategory modal -->
            <b-modal
              id="subcategoryModal"
              ref="subcategoryModal"
              size="xl"
              :title="$t('views.multiproductLandingPages.show.subcategory')"
              ok-only
              no-fade>
              <div class="form-group row">
                <label for="input-subcategory-name" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">{{ subcategoryModal.name }}</div>
              </div>

              <div class="form-group row">
                <label for="subcategory-image-input" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div v-if="subcategoryModal.categoryImage.url" class="border mb-3 d-inline-block">
                    <img :src="subcategoryModal.categoryImage.url" class="img-fluid">
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="subcategory-active-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <dot :active="subcategoryModal.active" />
                </div>
              </div>

              <!-- Subcategory widgets -->
              <div class="form-group row">
                <label class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</label>
                <div class="col-sm-10 col-12">
                  <div v-if="subcategoryModal.multiproductLandingPageCategoryWidgets.length === 0" class="alert alert-warning" role="alert">
                    {{ $t('shared.warnings.noWidget') }}
                  </div>
                  <table
                    class="table table-hover"
                    v-else>
                    <tbody>
                      <tr
                        v-for="multiproductLandingPageCategoryWidget in subcategoryModal.multiproductLandingPageCategoryWidgets"
                        :key="multiproductLandingPageCategoryWidget.widget.id"
                        class="table-col-shrink">
                        <th class="table-col-shrink">{{ multiproductLandingPageCategoryWidget.widget.id }}</th>
                        <td>
                          <table
                            v-if="widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]"
                            class="table m-n2">
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.widget.name') }}</th>
                              <td>{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                            </tr>
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.widget.publicName') }}</th>
                              <td>{{ multiproductLandingPageCategoryWidget.widget.publicName }}</td>
                            </tr>
                            <tr>
                              <th class="text-nowrap th-shrink">{{ $t('attributes.multiproductLandingPageCategoryWidget.labelHtml') }}</th>
                              <td>
                                <AceEditor
                                  :style="{ height: '8vh' }"
                                  v-model="multiproductLandingPageCategoryWidget.labelHtml"
                                  lang="html"
                                  :options="editorOptions"
                                ></AceEditor>
                              </td>
                            </tr>
                          </table>
                          <template v-else>
                            {{ multiproductLandingPageCategoryWidget.widget.name }}
                          </template>
                        </td>
                        <td class="table-col-shrink">
                          <button type="button" class="btn btn-secondary btn-sm" @click="toggleWidgetExpanded(multiproductLandingPageCategoryWidget.widget.id)">
                            <template v-if="!widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                            </template>
                            <template v-else>
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                            </template>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-modal>
          </b-tab>

          <!-- Images tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.images')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPage.backgroundImage') }}</th>
                  <td>
                    <div v-if="multiproductLandingPage.backgroundImage.url" class="border mb-3 d-inline-block">
                      <img :src="multiproductLandingPage.backgroundImage.url" class="img-fluid">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.headerImage') }}</th>
                  <td>
                    <div v-if="multiproductLandingPage.headerImage.url" class="border mb-3 d-inline-block">
                      <img :src="multiproductLandingPage.headerImage.url" class="img-fluid">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.footerLogo') }}</th>
                  <td>
                    <div v-if="multiproductLandingPage.footerLogo.url" class="border mb-3 d-inline-block">
                      <img :src="multiproductLandingPage.footerLogo.url" class="img-fluid">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.footerLogoLink') }}</th>
                  <td>
                    {{ multiproductLandingPage.footerLogoLink }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('attributes.multiproductLandingPage.favicon') }}</th>
                  <td>
                    <div v-if="multiproductLandingPage.favicon.url" class="border mb-3 d-inline-block">
                      <img :src="multiproductLandingPage.favicon.url" class="img-fluid">
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Texts tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.texts')">
            <div class="table-responsive">
              <table class="table">
                <tr v-for="textKey in textKeys" :key="textKey">
                  <th class="th-shrink text-nowrap">{{ $t(`attributes.multiproductLandingPage.textOverload.${textKey}`) }}</th>
                  <td>{{ multiproductLandingPage.textOverload[textKey] }}</td>
                </tr>
              </table>
            </div>

            <!-- Texts V2 -->
            <div class="hr-text">
              <span style="padding: 0 8px;">
                {{ $t('views.multiproductLandingPages.v2') }}
              </span>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tr v-for="textKey in textKeysV2" :key="textKey">
                  <th class="th-shrink text-nowrap">{{ $t(`attributes.multiproductLandingPage.textOverload.${textKey}`) }}</th>
                  <td>{{ multiproductLandingPage.textOverload[textKey] }}</td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Theme tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.theme')">
            <div class="hr-text">
              <span style="padding: 0 8px;">
                {{ $t('views.multiproductLandingPages.v2') }}
              </span>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.multiproductLandingPage.transitionEffect') }}</th>
                  <td>{{ multiproductLandingPage.transitionEffect }}</td>
                </tr>
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.multiproductLandingPage.darkMode') }}</th>
                  <td><dot :active="multiproductLandingPage.darkMode" /></td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- HTML tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.html')">
            <div class="form-group row">
              <label for="input-header-block" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.headerBlock') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '20vh' }"
                  v-model="multiproductLandingPage.headerBlock"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-top-section-html" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.topSectionHtml') }}</label>
              <div class="col-12 mt-2">
                  <AceEditor
                    :style="{ height: '20vh' }"
                    v-model="multiproductLandingPage.topSectionHtml"
                    lang="html"
                    :options="editorOptions"
                  ></AceEditor>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-footer-block" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.footerBlock') }}</label>
              <div class="col-12 mt-2">
                  <AceEditor
                    :style="{ height: '20vh' }"
                    v-model="multiproductLandingPage.footerBlock"
                    lang="html"
                    :options="editorOptions"
                  ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- CSS tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.css')">
            <div class="form-group row">
              <label for="input-raw-css" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.rawCss') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.rawCss"
                  lang="css"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- JS tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.js')">
            <div class="form-group row">
              <label for="input-raw-js" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.rawJs') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.rawJs"
                  lang="javascript"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- Tracking tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.tracking')">
            <div class="alert alert-info">
              {{ $t('views.multiproductLandingPages.trackersGdprWarning') }}
            </div>
            <div class="form-group row">
              <label for="input-trackers-html" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.trackersHtml') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.trackersHtml"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- Assets tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.assets')">
            <!-- Assets list -->
            <div v-if="assetsEnhanced.length === 0" class="alert alert-warning">
              {{ $t('attributes.multiproductLandingPage.assets.noFile') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPage.assets.id') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.name') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.preview') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.code') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="asset in assetsEnhanced" :key="asset.index">
                  <th>{{ asset.index }}</th>
                  <td>{{ asset.identifier }}</td>
                  <td class="align-middle">
                    <div v-if="imgFileType.includes(asset.fileType)" >
                      <a :href="asset.url" target= '_outblank'>
                        <img :src="asset.url" class="img-assets">
                      </a>
                    </div>
                    <div v-else class="font">
                      <a :href="asset.url">
                        <span :style="`font-family:${asset.originalName}`">
                          <div>abcdefghijklm</div>
                          <div>nopqrstuvwxyz</div>
                          <div>ABCDEFGHIJKLM</div>
                          <div>NOPQRSTUVWXYZ</div>
                          <div>0123456789 :?!.</div>
                        </span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div v-if="imgFileType.includes(asset.fileType)" class="asset-code">
                      <AceEditor
                        :style="{ height: '16vh', width: '55vh' }"
                        v-model="asset.htmlCode"
                        lang="html"
                        :options="{ ...editorOptions, readOnly: true, wrap: true }"
                      ></AceEditor>
                        <button
                          type="button"
                          v-clipboard:copy="asset.htmlCode"
                          v-clipboard:success="onCopy"
                          class="btn rounded-circle btn-sm assets-clipboard-btn"
                          :id="`copy-btn-${asset.identifier}`">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
                        </button>
                    </div>
                    <div v-if="fontFileType.includes(asset.fileType)" class="asset-code">
                      <AceEditor
                        :style="{ height: '16vh', width: '55vh' }"
                        v-model="asset.cssCode"
                        lang="css"
                        :options="{ ...editorOptions, readOnly: true, wrap: true }"
                      ></AceEditor>
                        <button
                          type="button"
                          v-clipboard:copy="asset.cssCode"
                          v-clipboard:success="onCopy"
                          class="btn rounded-circle btn-sm assets-clipboard-btn"
                          :id="`copy-btn-${asset.identifier}`">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
                        </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </persistent-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTab, BModal } from 'bootstrap-vue'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/less'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTab, BModal, AceEditor, PersistentTabs },
  data: function() {
    return {
      editorOptions: {
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false,
        readOnly: true
      },
      imgFileType: ['.gif', '.jpeg', '.jpg', '.png', '.svg'],
      fontFileType: ['.eot', '.ttf', '.woff', '.woff2'],
      multiproductLandingPage: null,
      multiproductLandingPageLoading: false,
      multiproductLandingPageError: null,
      categoryModal: {
        name: null,
        categoryImage: { url: null },
        active: true,
        multiproductLandingPageCategoryWidgets: [],
        subcategories: []
      },
      subcategoryModal: {
        name: null,
        categoryImage: { url: null },
        active: true,
        multiproductLandingPageCategoryWidgets: []
      },
      widgetsExpanded: {},
      textKeys: ['page_title', 'call_to_action', 'button_call_to_action'],
      textKeysV2: ['back', 'viewProducts', 'viewSubcategories', 'whereToBuy']
    }
  },
  computed: {
    assetsEnhanced: function() {
      const assetsEnhanced = [...this.multiproductLandingPage.assets]
      assetsEnhanced.forEach(asset => {
        asset.index = assetsEnhanced.indexOf(asset)
        asset.fileType = asset.url.replace(/^(?=).*(?=\.)/g, '')
        asset.originalName = asset.identifier.replace(/(?=-\D*).*(?=\.).*/g, '')
        if (this.fontFileType.includes(asset.fileType)) {
          let format
          switch (asset.fileType) {
            case '.ttf':
              format = 'truetype'
              break
            case '.woff':
              format = 'woff'
              break
            case '.woff2':
              format = 'woff2'
              break
            case '.eot':
              format = 'embedded-opentype'
              break
          }

          asset.cssCode =
`@font-face {
  font-family: ${asset.originalName};
  src: url('${asset.url}') format('${format}');
}`
        }
        if (this.imgFileType.includes(asset.fileType)) {
          asset.htmlCode = `<img src="${asset.url}"/>`
        }
      })
      return assetsEnhanced
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.multiproductLandingPageLoading = true
      this.multiproductLandingPageError = null

      const query = `query multiproductLandingPagesShow ($id: Int!) {
        multiproductLandingPage(id: $id) {
          id
          name
          key
          active
          transitionEffect
          darkMode
          multiproduct
          url
          urlV2
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
          assets {
            identifier
            url
          }
          backgroundImage
          headerImage
          footerLogo
          footerLogoLink
          favicon
          textOverload
          headerBlock
          topSectionHtml
          footerBlock
          rawCss
          rawJs
          trackersHtml
          customisation {
            id
            name
          }
          defaultWidget {
            id
            name
          }
          rootCategories {
            id
            name
            active
            categoryImage
            multiproductLandingPageCategoryWidgets {
              id
              position
              labelHtml
              widget {
                id
                name
                publicName
              }
            }
            subcategories {
              id
              name
              active
              categoryImage
              multiproductLandingPageCategoryWidgets {
                id
                position
                labelHtml
                widget {
                  id
                  name
                  publicName
                }
              }
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.multiproductLandingPageLoading = false

          if (data.errors) {
            this.multiproductLandingPageError = { status: res.status, errors: data.errors }
          } else {
            this.multiproductLandingPage = Object.freeze(data.data.multiproductLandingPage)

            const style = document.createElement('style')

            this.assetsEnhanced.forEach(asset => {
              if (this.fontFileType.includes(asset.fileType)) {
                style.innerHTML += asset.cssCode
              }
            })

            document.head.appendChild(style)
          }
        })
    },
    // Show category in modal
    showCategory: function(category) {
      this.categoryModal = category
      this.$refs.categoryModal.show()
    },
    // Show subcategory in modal
    showSubcategory: function(category) {
      this.subcategoryModal = category
      this.$refs.subcategoryModal.show()
    },
    // Toggle expand widget
    toggleWidgetExpanded: function(widgetId) {
      this.$set(this.widgetsExpanded, widgetId, !this.widgetsExpanded[widgetId])
    },
    // Copy tooltip
    onCopy: function(e) {
      this.$root.$bvToast.toast(this.$t('shared.success.multiproductLandingPage.copyCode'), {
        variant: 'success',
        noCloseButton: true,
        autoHideDelay: 1000
      })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
<style>
.assets-clipboard-btn {
  position: absolute;
  bottom: 12% !important;
  right: 0.5% !important;
  z-index: 9999 !important;
}

.asset-code {
  position: relative;
}
</style>
