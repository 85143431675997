
<div class="h-100">
  <transition name="fade" mode="out-in">
    <div v-if="customPageLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="customPageError" class="alert alert-danger">
      <template v-if="customPageError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <iframe v-else :src="customPage.url" width="100%" height="100%" frameborder="0" class="custom-page-iframe"></iframe>
  </transition>
</div>
