
<div>
  <transition name="fade" mode="out-in">
    <div v-if="multiproductLandingPageLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="multiproductLandingPageError" class="alert alert-danger">
      <template v-if="multiproductLandingPageError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>

      <form @submit.prevent="submit">
        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.infos')">
            <div class="form-group row">
              <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="multiproductLandingPage.name" type="text" class="form-control" :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.multiproductLandingPage.name')">
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.name" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'name', multiproductLandingPageSubmitErrors.name) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="multiproductLandingPage.group"
                  :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.group }"
                  id="multiselect-group" />
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'group', multiproductLandingPageSubmitErrors.group) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiproduct-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPage.multiproduct') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="multiproductLandingPage.multiproduct" type="checkbox" class="custom-control-input" id="multiproduct-checkbox">
                  <label class="custom-control-label" for="multiproduct-checkbox"></label>
                </div>
              </div>
            </div>

            <div v-if="!multiproductLandingPage.multiproduct" class="form-group row">
              <label for="multiselectDefaultWidget" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.defaultWidget') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="multiproductLandingPage.defaultWidgetId"
                  :options="widgets"
                  :custom-label="widgetLabel"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="name"
                  track-by="id"
                  :placeholder="$t('shared.placeholders.select')"
                  :show-labels="false"
                  :loading="widgetsLoading"
                  :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.defaultWidget }"
                  id="multiselectDefaultWidget">
                  <template v-slot:singleLabel="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                  <template v-slot:option="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                </key-multiselect>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.defaultWidget" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'defaultWidget', multiproductLandingPageSubmitErrors.defaultWidget) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPage.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="multiproductLandingPage.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                  <label class="custom-control-label" for="active-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-customisations" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.customisation') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="multiproductLandingPage.customisation2Id"
                  :options="customisations"
                  :multiple="false"
                  :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.customisation }"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="name"
                  track-by="id"
                  :placeholder="$t('shared.placeholders.select')"
                  :show-labels="false"
                  :loading="customisationsLoading"
                  id="multiselect-customisations">
                  <template v-slot:singleLabel="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                  <template v-slot:option="{ option }">
                    #{{ option.id }} - {{ option.name }}
                  </template>
                </key-multiselect>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.customisation" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'customisation', multiproductLandingPageSubmitErrors.customisation) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Categories tab -->
          <b-tab v-if="multiproductLandingPage.multiproduct">
            <template v-slot:title>
              {{ $t('views.multiproductLandingPages.tabs.categories') }}
              <span class="badge badge-primary">{{ multiproductLandingPage.rootCategories.length }}</span>
            </template>

            <div class="mb-3">
              <button type="button" class="btn btn-success" @click="newCategory()">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                {{ $t('shared.actions.newMultiproductLandingPageCategory') }}
              </button>
            </div>

            <!-- Root categories list -->
            <div v-if="multiproductLandingPage.rootCategories.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noMultiproductLandingPageCategory') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink"></th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.id') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPageCategory.name') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.active') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</th>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.subcategories') }}</th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                v-model="multiproductLandingPage.rootCategories"
                handle=".handle">
                <tr
                  v-for="category in multiproductLandingPage.rootCategories"
                  :key="category.id"
                  :class="{ 'table-danger': category._destroy }">
                  <th class="text-center handle cursor-move">
                    <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                  </th>
                  <th>{{ category.id }}</th>
                  <td class="text-center">
                    <img v-if="category.categoryImage.url" :src="category.categoryImage.url" class="td-img">
                  </td>
                  <td>{{ category.name }}</td>
                  <td class="text-center"><dot :active="category.active" /></td>
                  <td class="text-center">{{ category.multiproductLandingPageCategoryWidgets.length }}</td>
                  <td class="text-center">{{ category.subcategories.length }}</td>
                  <td>
                    <template v-if="!category.id">
                      <span class="badge badge-warning">New</span>
                    </template>
                  </td>
                  <td class="text-center">
                    <template v-if="categoryHasError(category)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <div class="gutter-x-xs">
                      <button type="button" class="btn btn-sm btn-warning" @click="editCategory(category)">
                        {{ $t('shared.actions.edit') }}
                      </button>
                      <template v-if="!category._destroy">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteCategory(category)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-sm btn-success" @click="restoreCategory(category)">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          {{ $t('shared.actions.restore') }}
                        </button>
                      </template>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>

            <!-- Category modal -->
            <b-modal
              id="categoryModal"
              ref="categoryModal"
              size="xl"
              :title="$t(`shared.actions.${categoryModalAction}MultiproductLandingPageCategory`)"
              @ok="categoryModalOk"
              @shown="$refs.modalCategoryNameInput.focus()"
              no-fade>
              <form @submit.stop.prevent="categoryModalSubmit()">
                <div class="form-group row">
                  <label for="input-category-name" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.name') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input
                      v-model="categoryModal.name"
                      type="text"
                      class="form-control"
                      id="input-category-name"
                      ref="modalCategoryNameInput"
                      :placeholder="$t('attributes.multiproductLandingPageCategory.name')">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="category-image-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div v-if="categoryModal.categoryImage.url" class="border mb-3 d-inline-block">
                      <img :src="categoryModal.categoryImage.url" class="img-fluid">
                    </div>
                    <b-form-file
                      v-model="categoryModal.categoryImageFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="category-image-input"
                      :state="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].categoryImage`] ? false : null" />
                    <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].categoryImage`]" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPageCategory', 'categoryImage', multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].categoryImage`]) }}</div>
                    <div v-if="categoryImageFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="category-active-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.active') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="categoryModal.active" type="checkbox" class="custom-control-input" id="category-active-checkbox">
                      <label class="custom-control-label" for="category-active-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Category widgets -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</label>
                  <div class="col-sm-10 col-12">
                    <multiselect
                      @input="addWidgetToModalCategory"
                      :options="availableWidgets(categoryModal)"
                      :custom-label="widgetLabel"
                      :allow-empty="false"
                      :clear-on-select="false"
                      :placeholder="$t('shared.placeholders.select')"
                      :close-on-select="false"
                      :loading="widgetsLoading"
                      class="mb-3">
                      <template v-slot:option="{ option }">
                        <div class="d-table">
                          <div class="d-table-cell" style="padding-right: 10px;">#{{ option.id }}</div>
                          <div class="d-table-cell" style="padding-right: 10px;">
                            <template v-if="option.product.ean !== ''">
                              EAN {{ option.product.ean }}
                            </template>
                          </div>
                          <div class="d-table-cell">{{ option.name }}</div>
                        </div>
                      </template>
                    </multiselect>

                    <div v-if="categoryModal.multiproductLandingPageCategoryWidgets.length === 0" class="alert alert-warning" role="alert">
                      {{ $t('shared.warnings.noWidget') }}
                    </div>
                    <table
                      class="table table-hover"
                      v-else>
                      <draggable
                        tag="tbody"
                        v-model="categoryModal.multiproductLandingPageCategoryWidgets"
                        handle=".handle">
                        <tr
                          v-for="multiproductLandingPageCategoryWidget in categoryModal.multiproductLandingPageCategoryWidgets"
                          :key="multiproductLandingPageCategoryWidget.widget.id"
                          class="table-col-shrink"
                          :class="{ 'table-danger': multiproductLandingPageCategoryWidget._destroy }">
                          <th class="text-center handle cursor-move">
                            <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                          </th>
                          <th class="table-col-shrink">{{ multiproductLandingPageCategoryWidget.widget.id }}</th>
                          <td>
                            <table
                              v-if="widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]"
                              class="table m-n2">
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.widget.name') }}</th>
                                <td>{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                              </tr>
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.widget.publicName') }}</th>
                                <td>{{ multiproductLandingPageCategoryWidget.widget.publicName }}</td>
                              </tr>
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.multiproductLandingPageCategoryWidget.labelHtml') }}</th>
                                <td>
                                  <AceEditor
                                    :style="{ height: '8vh' }"
                                    v-model="multiproductLandingPageCategoryWidget.labelHtml"
                                    lang="html"
                                    :options="editorOptions"
                                  ></AceEditor>
                                </td>
                              </tr>
                            </table>
                            <template v-else>
                              {{ multiproductLandingPageCategoryWidget.widget.name }}
                            </template>
                          </td>
                          <td class="table-col-shrink">
                            <button type="button" class="btn btn-secondary btn-sm" @click="toggleWidgetExpanded(multiproductLandingPageCategoryWidget.widget.id)">
                              <template v-if="!widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                              </template>
                              <template v-else>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                              </template>
                            </button>
                          </td>
                          <td class="table-col-shrink">
                            <template v-if="!multiproductLandingPageCategoryWidget.id">
                              <span class="badge badge-warning">New</span>
                            </template>
                          </td>
                          <td class="text-nowrap table-col-shrink">
                            <template v-if="!multiproductLandingPageCategoryWidget._destroy">
                              <button type="button" class="btn btn-sm btn-danger" @click="removeWidgetFromModalCategory(multiproductLandingPageCategoryWidget)">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                                {{ $t('shared.actions.delete') }}
                              </button>
                            </template>
                            <template v-else>
                              <button type="button" class="btn btn-sm btn-success" @click="restoreWidgetFromModalCategory(multiproductLandingPageCategoryWidget)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                                {{ $t('shared.actions.restore') }}
                              </button>
                            </template>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>

                <!-- Category subcategories -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.subcategories') }}</label>
                  <div class="col-sm-10 col-12">
                    <button type="button" class="btn btn-success mb-3" @click="newSubcategory()">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                      {{ $t('shared.actions.newMultiproductLandingPageSubcategory') }}
                    </button>
                    <div v-if="categoryModal.subcategories.length === 0" class="alert alert-warning">
                      {{ $t('shared.warnings.noMultiproductLandingPageCategory') }}
                    </div>
                    <table v-else class="table table-hover">
                      <thead>
                        <tr>
                          <th class="th-shrink"></th>
                          <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.id') }}</th>
                          <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</th>
                          <th>{{ $t('attributes.multiproductLandingPageCategory.name') }}</th>
                          <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.active') }}</th>
                          <th class="th-shrink">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</th>
                          <th class="th-shrink"></th>
                          <th class="th-shrink"></th>
                          <th class="th-shrink"></th>
                        </tr>
                      </thead>
                      <draggable
                        tag="tbody"
                        v-model="categoryModal.subcategories"
                        handle=".handle">
                        <tr
                          v-for="category in categoryModal.subcategories"
                          :key="category.id"
                          :class="{ 'table-danger': category._destroy }">
                          <th class="text-center handle cursor-move">
                            <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                          </th>
                          <th>{{ category.id }}</th>
                          <td class="text-center">
                            <img v-if="category.categoryImage.url" :src="category.categoryImage.url" class="td-img">
                          </td>
                          <td>{{ category.name }}</td>
                          <td class="text-center"><dot :active="category.active" /></td>
                          <td class="text-center">{{ category.multiproductLandingPageCategoryWidgets.length }}</td>
                          <td>
                            <template v-if="!category.id">
                              <span class="badge badge-warning">New</span>
                            </template>
                          </td>
                          <td class="text-center">
                            <template v-if="subCategoryHasError(category)">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                            </template>
                          </td>
                          <td class="text-nowrap">
                            <div class="gutter-x-xs">
                              <button type="button" class="btn btn-sm btn-warning" @click="editSubcategory(category)">
                                {{ $t('shared.actions.edit') }}
                              </button>
                              <template v-if="!category._destroy">
                                <button type="button" class="btn btn-sm btn-danger" @click="deleteSubcategory(category)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                                  {{ $t('shared.actions.delete') }}
                                </button>
                              </template>
                              <template v-else>
                                <button type="button" class="btn btn-sm btn-success" @click="restoreSubcategory(category)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                                  {{ $t('shared.actions.restore') }}
                                </button>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>
              </form>
            </b-modal>

            <!-- Subcategory modal -->
            <b-modal
              id="subcategoryModal"
              ref="subcategoryModal"
              size="xl"
              :title="$t(`shared.actions.${subcategoryModalAction}MultiproductLandingPageSubcategory`)"
              @ok="subcategoryModalOk"
              @shown="$refs.modalSubcategoryNameInput.focus()"
              no-fade>
              <form @submit.stop.prevent="subcategoryModalSubmit()">
                <div class="form-group row">
                  <label for="input-subcategory-name" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.name') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input
                      v-model="subcategoryModal.name"
                      type="text"
                      class="form-control"
                      id="input-subcategory-name"
                      ref="modalSubcategoryNameInput"
                      :placeholder="$t('attributes.multiproductLandingPageCategory.name')">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="subcategory-image-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.categoryImage') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div v-if="subcategoryModal.categoryImage.url" class="border mb-3 d-inline-block">
                      <img :src="subcategoryModal.categoryImage.url" class="img-fluid">
                    </div>
                    <b-form-file
                      v-model="subcategoryModal.categoryImageFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="subcategory-image-input"
                      :state="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].subcategories[${subCategoryModalIndex}].categoryImage`] ? false : null" />
                    <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].subcategories[${subCategoryModalIndex}].categoryImage`]" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPageCategory', 'categoryImage', multiproductLandingPageSubmitErrors[`multiproductLandingPageCategories[${categoryModalIndex}].subcategories[${subCategoryModalIndex}].categoryImage`]) }}</div>
                    <div v-if="subcategoryImageFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="subcategory-active-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPageCategory.active') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="subcategoryModal.active" type="checkbox" class="custom-control-input" id="subcategory-active-checkbox">
                      <label class="custom-control-label" for="subcategory-active-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Subcategory widgets -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPageCategory.widgets') }}</label>
                  <div class="col-sm-10 col-12">
                    <multiselect
                      @input="addWidgetToModalSubcategory"
                      :options="availableWidgets(subcategoryModal)"
                      :custom-label="widgetLabel"
                      :allow-empty="false"
                      :clear-on-select="false"
                      :placeholder="$t('shared.placeholders.select')"
                      :close-on-select="false"
                      :loading="widgetsLoading"
                      class="mb-3">
                      <template v-slot:option="{ option }">
                        #{{ option.id }}
                        -
                        <template v-if="option.product.ean !== ''">
                          {{ option.product.ean }}
                          -
                        </template>
                        {{ option.name }}
                      </template>
                    </multiselect>

                    <div v-if="subcategoryModal.multiproductLandingPageCategoryWidgets.length === 0" class="alert alert-warning" role="alert">
                      {{ $t('shared.warnings.noWidget') }}
                    </div>
                    <table
                      class="table table-hover"
                      v-else>
                      <thead>
                        <tr>
                          <th class="th-shrink"></th>
                          <th class="th-shrink">{{ $t('attributes.widget.id') }}</th>
                          <th>{{ $t('attributes.widget.name') }}</th>
                          <th class="th-shrink"></th>
                          <th class="th-shrink"></th>
                        </tr>
                      </thead>
                      <draggable
                        tag="tbody"
                        v-model="subcategoryModal.multiproductLandingPageCategoryWidgets"
                        handle=".handle">
                        <tr
                          v-for="multiproductLandingPageCategoryWidget in subcategoryModal.multiproductLandingPageCategoryWidgets"
                          :key="multiproductLandingPageCategoryWidget.widget.id"
                          :class="{ 'table-danger': multiproductLandingPageCategoryWidget._destroy }">
                          <th class="text-center handle cursor-move">
                            <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                          </th>
                          <th>{{ multiproductLandingPageCategoryWidget.widget.id }}</th>
                          <td>
                            <table
                              v-if="widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]"
                              class="table m-n2">
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.widget.name') }}</th>
                                <td>{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                              </tr>
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.widget.publicName') }}</th>
                                <td>{{ multiproductLandingPageCategoryWidget.widget.publicName }}</td>
                              </tr>
                              <tr>
                                <th class="text-nowrap th-shrink">{{ $t('attributes.multiproductLandingPageCategoryWidget.labelHtml') }}</th>
                                <td>
                                  <AceEditor
                                    :style="{ height: '8vh' }"
                                    v-model="multiproductLandingPageCategoryWidget.labelHtml"
                                    lang="html"
                                    :options="editorOptions"
                                  ></AceEditor>
                                </td>
                              </tr>
                            </table>
                            <template v-else>{{ multiproductLandingPageCategoryWidget.widget.name }}</template>
                          </td>
                          <td class="table-col-shrink">
                            <button type="button" class="btn btn-secondary btn-sm" @click="toggleWidgetExpanded(multiproductLandingPageCategoryWidget.widget.id)">
                              <template v-if="!widgetsExpanded[multiproductLandingPageCategoryWidget.widget.id]">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                              </template>
                              <template v-else>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                              </template>
                            </button>
                          </td>
                          <td class="text-nowrap">
                            <button type="button" class="btn btn-sm btn-danger" @click="removeWidgetFromModalSubcategory(multiproductLandingPageCategoryWidget)">
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                              {{ $t('shared.actions.delete') }}
                            </button>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-tab>

          <!-- Images tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.images')">
            <div class="form-group row">
              <label for="background-image-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.backgroundImage') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <template v-if="multiproductLandingPage.backgroundImage.url">
                  <div class="border mb-3 d-inline-block">
                    <img
                      :src="multiproductLandingPage.backgroundImage.url"
                      class="img-fluid"
                      :class="{ 'opacity-50': multiproductLandingPage.removeBackgroundImage }">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductLandingPage.removeBackgroundImage" type="checkbox" class="custom-control-input" id="remove-background-image-checkbox">
                      <label class="custom-control-label" for="remove-background-image-checkbox">
                        {{ $t('attributes.multiproductLandingPage.removeBackgroundImage') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="multiproductLandingPage.backgroundImageFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="background-image-input" />
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.backgroundImage" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'backgroundImage', multiproductLandingPageSubmitErrors.backgroundImage) }}</div>
                <div v-if="backgroundImageFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="header-image-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.headerImage') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <template v-if="multiproductLandingPage.headerImage.url">
                  <div class="border mb-3 d-inline-block">
                    <img
                      :src="multiproductLandingPage.headerImage.url"
                      class="img-fluid"
                      :class="{ 'opacity-50': multiproductLandingPage.removeHeaderImage }">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductLandingPage.removeHeaderImage" type="checkbox" class="custom-control-input" id="remove-header-image-checkbox">
                      <label class="custom-control-label" for="remove-header-image-checkbox">
                        {{ $t('attributes.multiproductLandingPage.removeHeaderImage') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="multiproductLandingPage.headerImageFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="header-image-input" />
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.headerImage" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'headerImage', multiproductLandingPageSubmitErrors.headerImage) }}</div>
                <div v-if="headerImageFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="footer-logo-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.footerLogo') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <template v-if="multiproductLandingPage.footerLogo.url">
                  <div class="border mb-3 d-inline-block">
                    <img
                      :src="multiproductLandingPage.footerLogo.url"
                      class="img-fluid"
                      :class="{ 'opacity-50': multiproductLandingPage.removeFooterLogo }">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductLandingPage.removeFooterLogo" type="checkbox" class="custom-control-input" id="remove-footer-logo-checkbox">
                      <label class="custom-control-label" for="remove-footer-logo-checkbox">
                        {{ $t('attributes.multiproductLandingPage.removeFooterLogo') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="multiproductLandingPage.footerLogoFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="footer-logo-input" />
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.footerLogo" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'footerLogo', multiproductLandingPageSubmitErrors.footerLogo) }}</div>
                <div v-if="footerLogoFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-footer-logo-link" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.footerLogoLink') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="multiproductLandingPage.footerLogoLink" type="text" class="form-control" :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.footerLogoLink }" id="input-footer-logo-link" :placeholder="$t('attributes.multiproductLandingPage.footerLogoLink')">
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.footerLogoLink" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'footerLogoLink', multiproductLandingPageSubmitErrors.footerLogoLink) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="favicon-input" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.favicon') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <!--<div v-if="multiproductLandingPage.favicon.url" class="border mb-3 d-inline-block">
                  <img :src="multiproductLandingPage.favicon.url" class="img-fluid">
                </div>-->
                <template v-if="multiproductLandingPage.favicon.url">
                  <div class="border mb-3 d-inline-block">
                    <img
                      :src="multiproductLandingPage.favicon.url"
                      class="img-fluid"
                      :class="{ 'opacity-50': multiproductLandingPage.removeFavicon }">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductLandingPage.removeFavicon" type="checkbox" class="custom-control-input" id="remove-favicon-checkbox">
                      <label class="custom-control-label" for="remove-favicon-checkbox">
                        {{ $t('attributes.multiproductLandingPage.removeFavicon') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="multiproductLandingPage.faviconFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="favicon-input" />
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.favicon" class="invalid-feedback d-block">{{ tErrors('multiproductLandingPage', 'favicon', multiproductLandingPageSubmitErrors.favicon) }}</div>
                <div v-if="faviconFileSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Texts tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.texts')">
            <template v-for="textKey in textKeys" >
              <div :key="textKey" class="form-group row mb-10">
                <label :for="`input-${textKey}`" class="col-sm-2 col-form-label">{{ $t(`attributes.multiproductLandingPage.textOverload.${textKey}`) }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input
                    v-model="multiproductLandingPage.textOverload[textKey]"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[textKey] }"
                    :id="`input-${textKey}`"
                    :placeholder="$t(`attributes.multiproductLandingPage.textOverload.${textKey}`)">
                </div>
              </div>
            </template>

            <!-- Texts V2 -->
            <div class="hr-text">
              <span>
                {{ $t('views.multiproductLandingPages.v2') }}
              </span>
            </div>
            <template v-for="textKey in textKeysV2">
              <div :key="textKey" class="form-group row">
                <label :for="`input-${textKey}`" class="col-sm-2 col-form-label">{{ $t(`attributes.multiproductLandingPage.textOverload.${textKey}`) }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input
                    v-model="multiproductLandingPage.textOverload[textKey]"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid' : multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors[textKey] }"
                    :id="`input-${textKey}`"
                    :placeholder="$t(`attributes.multiproductLandingPage.textOverload.${textKey}`)">
                </div>
              </div>
            </template>
          </b-tab>

          <!-- Theme tab (V2)-->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.theme')">
            <div class="hr-text">
              <span>
                {{ $t('views.multiproductLandingPages.v2') }}
              </span>
            </div>
            <div class="form-group row">
              <label for="transitionEffect-customisations" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.transitionEffect') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="multiproductLandingPage.transitionEffect"
                  :options="transitionEffects"
                  track-by="value"
                  label="name"
                  :multiple="false"
                  :allow-empty="false"
                  :placeholder="$t('shared.placeholders.select')"
                  id="transitionEffect-customisations"/>
              </div>
            </div>

            <div class="form-group row">
              <label for="darkMode-checkbox" class="col-sm-2">{{ $t('attributes.multiproductLandingPage.darkMode') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="multiproductLandingPage.darkMode" type="checkbox" class="custom-control-input" id="darkMode-checkbox">
                  <label class="custom-control-label" for="darkMode-checkbox"></label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- HTML tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.html')">
            <div class="form-group row">
              <label for="input-header-block" class="col-sm-4 col-form-label">{{ $t('attributes.multiproductLandingPage.headerBlock') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '20vh' }"
                  v-model="multiproductLandingPage.headerBlock"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.headerBlock" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'headerBlock', multiproductLandingPageSubmitErrors.headerBlock) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-top-section-html" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.topSectionHtml') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '20vh' }"
                  v-model="multiproductLandingPage.topSectionHtml"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.topSectionHtml" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'topSectionHtml', multiproductLandingPageSubmitErrors.topSectionHtml) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-footer-block" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.footerBlock') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '20vh' }"
                  v-model="multiproductLandingPage.footerBlock"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.footerBlock" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'footerBlock', multiproductLandingPageSubmitErrors.footerBlock) }}</div>
              </div>
            </div>

          </b-tab>

          <!-- CSS tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.css')">
            <div class="form-group row">
              <label for="input-raw-css" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawCss') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.rawCss"
                  lang="css"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'rawCss', multiproductLandingPageSubmitErrors.rawCss) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- JS tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.js')">
            <div class="form-group row">
              <label for="input-raw-js" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.rawJs') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.rawJs"
                  lang="javascript"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.rawJs" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'rawJs', multiproductLandingPageSubmitErrors.rawJs) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Tracking tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.tracking')">
            <div class="alert alert-info">
              {{ $t('views.multiproductLandingPages.trackersGdprWarning') }}
            </div>
            <div class="form-group row">
              <label for="input-trackers-html" class="col-sm-2 col-form-label">{{ $t('attributes.multiproductLandingPage.trackersHtml') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductLandingPage.trackersHtml"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="multiproductLandingPageSubmitErrors && multiproductLandingPageSubmitErrors.trackersHtml" class="invalid-feedback">{{ tErrors('multiproductLandingPage', 'trackersHtml', multiproductLandingPageSubmitErrors.trackersHtml) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Assets tab -->
          <b-tab :title="$t('views.multiproductLandingPages.tabs.assets')">
            <!-- Assets list -->
            <div v-if="assetsEnhanced.length === 0" class="alert alert-warning">
              {{ $t('attributes.multiproductLandingPage.assets.noFile') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.multiproductLandingPage.assets.id') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.name') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.preview') }}</th>
                  <th>{{ $t('attributes.multiproductLandingPage.assets.code') }}</th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="asset in assetsEnhanced"
                  :key="asset.index"
                  :class="{ 'table-danger': asset._destroy }">
                  <th>{{ asset.index }}</th>
                  <td>{{ asset.identifier }}</td>
                  <td class="align-middle">
                    <div v-if="imgFileType.includes(asset.fileType)">
                      <a :href="asset.url" target= '_outblank'>
                        <img :src="asset.url" class="img-assets">
                      </a>
                    </div>
                    <div v-else class="font">
                      <a :href="asset.url">
                        <span :style="`font-family:${asset.originalName}`">
                          <div>abcdefghijklm</div>
                          <div>nopqrstuvwxyz</div>
                          <div>ABCDEFGHIJKLM</div>
                          <div>NOPQRSTUVWXYZ</div>
                          <div>0123456789 :?!.</div>
                        </span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div v-if="imgFileType.includes(asset.fileType)" class="asset-code">
                      <AceEditor
                        :style="{ height: '16vh', width: '55vh' }"
                        v-model="asset.htmlCode"
                        lang="html"
                        :options="{ ...editorOptions, readOnly: true, wrap: true }"
                      ></AceEditor>
                        <button
                          type="button"
                          v-clipboard:copy="asset.htmlCode"
                          v-clipboard:success="onCopy"
                          class="btn rounded-circle btn-sm assets-clipboard-btn"
                          :id="`copy-btn-${asset.identifier}`">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
                        </button>
                    </div>
                    <div v-if="fontFileType.includes(asset.fileType)" class="asset-code">
                      <AceEditor
                        :style="{ height: '16vh', width: '55vh' }"
                        v-model="asset.cssCode"
                        lang="css"
                        :options="{ ...editorOptions, readOnly: true, wrap: true }"
                      ></AceEditor>
                        <button
                          type="button"
                          v-clipboard:copy="asset.cssCode"
                          v-clipboard:success="onCopy"
                          class="btn rounded-circle btn-sm assets-clipboard-btn"
                          :id="`copy-btn-${asset.identifier}`">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
                        </button>
                    </div>
                  </td>
                  <td class="align-middle">
                    <template v-if="!asset._destroy">
                      <button type="button" class="btn btn-sm btn-danger btn-assets" @click="deleteAsset(asset)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success btn-assets" @click="restoreAsset(asset)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Assets form-->
            <div class="form-group row">
              <label for="assets-file" class="col-form-label col-sm-2">{{ $t('attributes.multiproductLandingPage.assets.inputLabel') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-file
                  v-model="multiproductLandingPage.assetsFiles"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="assets-input"
                  multiple="" />
                <div v-if="assetsFilesSizeWarning" class="alert alert-warning mt-2">{{ $t('shared.warnings.assetSize') }}</div>
              </div>
            </div>
          </b-tab>
        </persistent-tabs>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="multiproductLandingPageSubmitLoading">
            <md-spinner v-if="multiproductLandingPageSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
