
<div>
  <!-- Filters -->
  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.group.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            :placeholder="$t('attributes.group.name')">
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.group.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-services">{{ $t('attributes.abTest.service') }}</label>
          <multiselect
            v-model="filters.services"
            :multiple="true"
            :close-on-select="false"
            :options="['widget', 'multiproduct_widget', 'michelin_widget']"
            :placeholder="$t('shared.placeholders.select')"
            :custom-label="service => $t(`views.abTests.services.${service}`)"
            />
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.group.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.abTest.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>
      </div>

      <div class="gutter-x-xs">
        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <!-- Global buttons -->
  <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
    <div></div>
    <div>
      <!-- New button -->
      <router-link :to="{ name: 'newAbTest' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newAbTest') }}
      </router-link>
    </div>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="abTestsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="abTestsLoading">
        <thead>
          <tr>
            <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.abTest.id') }}</th-sortable>
            <th class="table-col-shrink"></th>
            <th-sortable sort-key="name">{{ $t('attributes.abTest.name') }}</th-sortable>
            <th-sortable sort-key="start_date">{{ $t('attributes.abTest.startDate') }}</th-sortable>
            <th-sortable sort-key="end_date">{{ $t('attributes.abTest.endDate') }}</th-sortable>
            <th-sortable sort-key="group_id" class="th-shrink">{{ $t('attributes.abTest.group') }}</th-sortable>
            <th-sortable sort-key="service">{{ $t('attributes.abTest.service') }}</th-sortable>
            <th class="table-col-shrink"></th>
            <th class="table-col-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="abTestsError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="abTestsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-if="!abTestsLoading && abTests.length === 0">
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noAbTest') }}
              </td>
            </tr>
            <tr v-else v-for="abTest in abTests" :key="abTest.id">
              <th>{{ abTest.id }}</th>
              <td class="text-center">
                <dot :active="abTest.active" />
              </td>
              <td>
                <span :id="`ab-test-${abTest.id}`">{{ abTest.name }}</span>
                <b-popover :target="`ab-test-${abTest.id}`" triggers="hover click" placement="right" boundary="window">
                  <table class="table mb-0">
                    <tbody>
                      <tr v-for="abTestsVariant in abTest.abTestsVariants" :key="abTestsVariant.id">
                        <td class="font-weight-semibold">{{ abTestsVariant.variant.name }}</td>
                        <td>
                          <template v-if="abTest.uniform">{{ uniformPercentage(abTest) | percentage }}</template>
                          <template v-else>{{ abTestsVariant.weight / 100 | percentage }}</template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-popover>
              </td>
              <td>{{ abTest.startDate | date }}</td>
              <td>{{ abTest.endDate | date }}</td>
              <td class="text-nowrap">
                <router-link :to="{ name: 'group', params: { id: abTest.group.id } }">
                  <region-flag :code="abTest.group.region.code" />
                  {{ abTest.group.name }}
                </router-link>
              </td>
              <td>{{ $t(`views.abTests.services.${abTest.service}`) }}</td>
              <td>
                <router-link
                  :to="{ name: 'editAbTest', params: { id: abTest.id } }"
                  class="btn btn-sm btn-warning">
                  {{ $t('shared.actions.edit') }}
                </router-link>
              </td>
              <td>
                <router-link :to="abTestStatsUrl(abTest)" class="btn btn-sm btn-primary">
                  {{ $t('shared.actions.stats') }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />
</div>
