<template>
  <div class="md-spinner" :class="mdIndeterminate ? 'md-indeterminate' : ''" :style="styles">
    <svg class="md-spinner-draw" viewBox="25 25 50 50">
      <circle class="md-spinner-path" cx="50" cy="50" r="20" stroke="currentColor" :stroke-width="strokeWidth" :stroke-dasharray="dashProgress"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    diameter: {
      type: Number,
      default: 50
    },
    strokeWidth: {
      type: Number,
      default: 3.5
    },
    mdIndeterminate: Boolean,
    mdProgress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    styles() {
      const newSize = this.diameter + 'px'

      return {
        width: newSize,
        height: newSize
      }
    },
    dashProgress() {
      let progress = this.mdProgress * 125 / 100

      if (this.mdIndeterminate) {
        return false
      }

      if (progress >= 125) {
        progress = 130
      }

      return `${progress}, 200`
    }
  }
}
</script>

<style lang="scss">
$primary: #343a40;
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';

$swift-ease-out-duration: .4s !default;
$swift-ease-out-timing-function: cubic-bezier(.25, .8, .25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

.md-spinner {
  display: inline-block;
  position: relative;
  pointer-events: none;
  will-change: transform, opacity;

  &.md-indeterminate {
    .md-spinner-draw {
      animation: spinner-rotate 1.9s linear infinite;
      transform: rotate(0deg) translateZ(0);
    }

    .md-spinner-path {
      stroke-dasharray: 89, 200;
      animation: spinner-dash 1.425s ease-in-out infinite;
    }
  }
}

.md-spinner-draw {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(270deg) translateZ(0);
  transform-origin: center center;
  will-change: transform, opacity;
}

.md-spinner-path {
  fill: none;
  stroke-dashoffset: 0;
  stroke-miterlimit: 10;
  transition: $swift-ease-out;
}

@keyframes spinner-rotate {
  to {
    transform: rotate(360deg) translateZ(0);
  }
}

@keyframes spinner-initial-rotate {
  0% {
    opacity: 0;
    transform: rotate(-90deg) translateZ(0);
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: rotate(270deg) translateZ(0);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 2, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
