
<div>
  <transition name="fade" mode="out-in">
    <div v-if="facebookAppLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="facebookAppError" class="alert alert-danger">
      <template v-if="facebookAppError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.facebookApps.tabs.infos')">
            <div class="form-group row">
              <label for="input-name" class="col-sm-3 col-form-label">{{ $t('attributes.facebookApp.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="facebookApp.name" type="text" class="form-control" :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.facebookApp.name')">
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.name" class="invalid-feedback">{{ tErrors('facebookApp', 'name', facebookAppSubmitErrors.name) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-group" class="col-sm-3 col-form-label">{{ $t('attributes.facebookApp.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="facebookApp.group"
                  :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.group }"
                  id="multiselect-group" />
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('facebookApp', 'group', facebookAppSubmitErrors.group) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-facebook-page-id" class="col-sm-3 col-form-label">{{ $t('attributes.facebookApp.facebookPageId') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input
                  type="text"
                  v-model="facebookApp.facebookPageId"
                  class="form-control"
                  :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.facebookPageId }"
                  id="input-facebook-page-id"
                  :placeholder="$t('attributes.facebookApp.facebookPageId')">
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.facebookPageId" class="invalid-feedback">{{ tErrors('facebookApp', 'facebookPageId', facebookAppSubmitErrors.facebookPageId) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselectLandingPage" class="col-sm-3 col-form-label">{{ $t('attributes.facebookApp.multiproductLandingPage') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="facebookApp.multiproductLandingPageId"
                  :options="multiproductLandingPages"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="name"
                  track-by="id"
                  :placeholder="$t('shared.placeholders.select')"
                  :show-labels="false"
                  :loading="multiproductLandingPagesLoading"
                  :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.multiproduct_landing_page }"
                  id="multiselectLandingPage">
                </key-multiselect>
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.multiproduct_landing_page" class="invalid-feedback d-block">{{ tErrors('facebookApp', 'multiproduct_landing_page', facebookAppSubmitErrors.multiproduct_landing_page) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="show-public-name-checkbox" class="col-sm-3">{{ $t('attributes.facebookApp.showPublicName') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="facebookApp.showPublicName" type="checkbox" class="custom-control-input" id="show-public-name-checkbox">
                  <label class="custom-control-label" for="show-public-name-checkbox"></label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Style tab -->
          <b-tab :title="$t('views.facebookApps.tabs.style')">
            <div class="form-group row">
              <label for="banner-input" class="col-sm-2 col-form-label">{{ $t('attributes.facebookApp.banner') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div v-if="facebookApp.banner.url" class="border mb-3 d-inline-block">
                  <img :src="facebookApp.banner.url" class="img-fluid">
                </div>
                <b-form-file
                  v-model="facebookApp.bannerFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="banner-input" />
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.banner" class="invalid-feedback d-block">{{ tErrors('facebookApp', 'banner', facebookAppSubmitErrors.banner) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-btn-color" class="col-sm-2 col-form-label">{{ $t('attributes.facebookApp.btnColor') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="facebookApp.btnColor" type="text" class="form-control" :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.btnColor }" id="input-btn-color" :placeholder="$t('attributes.facebookApp.btnColor')">
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.btnColor" class="invalid-feedback">{{ tErrors('facebookApp', 'color', facebookAppSubmitErrors.btnColor) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-border-radius" class="col-sm-2 col-form-label">{{ $t('attributes.facebookApp.btnText') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="facebookApp.btnText" type="text" class="form-control" :class="{ 'is-invalid' : facebookAppSubmitErrors && facebookAppSubmitErrors.btnText }" id="input-btn-text" :placeholder="$t('attributes.facebookApp.btnText')">
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.btnText" class="invalid-feedback">{{ tErrors('facebookApp', 'btnText', facebookAppSubmitErrors.btnText) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-raw-css" class="col-sm-4 col-form-label">{{ $t('attributes.facebookApp.rawCss') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '30rem' }"
                  v-model="facebookApp.rawCss"
                  lang="css"
                  :options="editorOptions"
                ></AceEditor>
                <div v-if="facebookAppSubmitErrors && facebookAppSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('facebookApp', 'rawCss', facebookAppSubmitErrors.rawCss) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Widgets tab -->
          <b-tab>
            <template v-slot:title>
              {{ $t('views.facebookApps.tabs.widgets') }}
              <span class="badge badge-primary">{{ facebookApp.facebookAppWidgets.length }}</span>
            </template>
            <div class="form-group">
              <multiselect
                @input="addFacebookAppWidget"
                :options="availableWidgets"
                :custom-label="widgetLabel"
                :allow-empty="false"
                :clear-on-select="false"
                :placeholder="$t('shared.placeholders.select')"
                :close-on-select="false">
                <template slot="option" slot-scope="props">
                  #{{ props.option.id }} - {{ props.option.name }}
                </template>
              </multiselect>
            </div>

            <table
              class="table table-hover"
              v-if="facebookApp.facebookAppWidgets.length > 0">
              <thead>
                <tr>
                  <th class="th-shrink"></th>
                  <th class="th-shrink">{{ $t('attributes.widget.id') }}</th>
                  <th>{{ $t('attributes.widget.name') }}</th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                v-model="facebookApp.facebookAppWidgets"
                handle=".handle">
                <tr
                  v-for="facebookAppWidget in facebookApp.facebookAppWidgets"
                  :key="facebookAppWidget.widget.id"
                  :class="{ 'table-danger': facebookAppWidget._destroy }"
                  >
                  <th class="text-center handle cursor-move">
                    <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                  </th>
                  <th>{{ facebookAppWidget.widget.id }}</th>
                  <td>{{ facebookAppWidget.widget.name }}</td>
                  <td>
                    <template v-if="!facebookAppWidget.id">
                      <span class="badge badge-warning">New</span>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <template v-if="!facebookAppWidget._destroy">
                      <button type="button" class="btn btn-sm btn-danger" @click="removeFacebookAppWidget(facebookAppWidget)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success" @click="restoreFacebookAppWidget(facebookAppWidget)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </td>
                </tr>
              </draggable>
            </table>
            <div v-else class="alert alert-warning" role="alert">
              {{ $t('shared.warnings.noWidget') }}
            </div>
          </b-tab>
        </persistent-tabs>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="facebookAppSubmitLoading">
            <md-spinner v-if="facebookAppSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
