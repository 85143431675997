
<div>
  <transition name="fade" mode="out-in">
    <div v-if="customServiceLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="customServiceError" class="alert alert-danger">
      <template v-if="customServiceError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ customService.name }}</h5>
        <router-link :to="{ name: 'editCustomService', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <div class="table-responsive">
        <table class="table">
          <tr>
            <th>{{ $t('attributes.customService.id') }}</th>
            <td>{{ customService.id }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.customService.key') }}</th>
            <td><code>{{ customService.key }}</code></td>
          </tr>
          <tr>
            <th>{{ $t('attributes.customService.name') }}</th>
            <td>{{ customService.name }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.customService.active') }}</th>
            <td><dot :active="customService.active" /></td>
          </tr>
          <tr>
            <th>{{ $t('attributes.customService.group') }}</th>
            <td>
              <router-link :to="{ name: 'group', params: { id: customService.group.id } }">
                <region-flag :code="customService.group.region.code" />
                {{ customService.group.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.customService.groupActive') }}</th>
            <td><dot :active="customService.group.active" /></td>
          </tr>
        </table>
      </div>
    </div>
  </transition>
</div>
