
<multiselect
  :value="value"
  :options="landingPages"
  :multiple="true"
  track-by="id"
  :custom-label="landingPages => landingPages.name"
  :allow-empty="true"
  :clear-on-select="false"
  :placeholder="placeholder"
  :close-on-select="false"
  :loading="landingPagesLoading"
  @search-change="handleSearchChange"
  @open="handleOpen"
  @input="emitSelectEvent"
>
  <template v-slot:option="{ option }">
    <region-flag :code="option.group.region.code" />
    {{ option.group.name }}
    -
    {{ option.name }}
  </template>
  <template slot="afterList" v-if="landingPagesHasNextPage">
    <button class="btn btn-primary col-12" type="button" @click="loadMoreClick" :disabled="landingPagesLoading">
      {{ $t('views.retailerMultiselect.loadMore') }}
    </button>
  </template>
  <template v-slot:tag="{ option, remove }">
    <div class="btn-group" style="margin: 2.5px 5px;">
      <div class="btn btn-light btn-xs text-left">
        <region-flag :code="option.group.region.code" />
        {{ option.group.name }}
        -
        {{ option.name }}
      </div>
      <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
      </div>
    </div>
  </template>
</multiselect>
