import 'core-js/stable'
import 'whatwg-fetch'

import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from '../App.vue'
import router from '../router'
import store from '../store'
import i18n from '../i18n'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from '../store/ability'
import { ToastPlugin } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import * as Sentry from '@sentry/vue'

Vue.use(VueCompositionAPI)
Vue.use(abilitiesPlugin, ability)
Vue.use(ToastPlugin)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.mixin({
  methods: {
    tErrors: function(model, attribute, errors) {
      const tErrors = []
      errors.forEach(error => {
        let message
        if (error.count) {
          message = i18n.tc(`errors.messages.${error.error}`, error.count)
        } else {
          message = i18n.t(`errors.messages.${error.error}`, { ...error })
        }

        tErrors.push(i18n.t('errors.format', {
          attribute: i18n.t(`attributes.${model}.${attribute}`),
          message: message
        }))
      })
      return tErrors.join(', ')
    },
    // Get a region name from its code
    regionName: function(code) {
      if (code === 'INTERNATIONAL') {
        return 'International'
      } else {
        const regionNames = new Intl.DisplayNames([i18n.languageCode()], { type: 'region' })
        return regionNames.of(code)
      }
    },
    // Get a language name in a language
    languageName: function(languageNameCode, languageCode) {
      const languageName = new Intl.DisplayNames([languageCode], { type: 'language' }).of(languageNameCode)
      return languageName.charAt(0).toUpperCase() + languageName.slice(1)
    }
  }
})

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.MODE,
    logErrors: true,
    tracesSampleRate: 1.0
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('div'))
  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    i18n,
    el,
    render: h => h(App)
  })
})
