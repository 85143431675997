<template>
  <div class="d-flex h-100 align-items-center justify-content-center">
    <form class="auth-form" @submit.prevent="login({ email, password, rememberMe })">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="auth-logo" />
        BRANDS
      </div>

      <input
        v-model="email"
        type="email"
        class="form-control"
        :class="loginError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.email')"
        required
        autofocus
      >

      <input
        v-model="password"
        type="password"
        class="form-control"
        :class="loginError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.password')"
        required
      >

      <div v-if="loginError" class="invalid-feedback">
        {{ $t(`auth.failure.${loginError}`) }}
      </div>

      <div class="custom-control custom-checkbox mt-2">
        <input
          id="remember-me-checkbox"
          v-model="rememberMe"
          class="custom-control-input"
          type="checkbox"
        >
        <label class="custom-control-label" for="remember-me-checkbox">
          {{ $t('attributes.user.rememberMe') }}
        </label>
      </div>

      <div class="d-grid mt-3">
        <button class="btn btn-lg btn-primary btn-block position-relative" type="submit" :disabled="loginLoading">
          <div v-if="loginLoading" class="position-absolute">
            <md-spinner md-indeterminate :diameter="32" :stroke-width="5"></md-spinner>
          </div>
          {{ $t('shared.actions.login') }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'newPassword' }">
          {{ $t('auth.links.newPassword') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import C2bLogo from '../shared/C2bLogo.vue'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: {
    C2bLogo,
    MdSpinner
  },
  data: function() {
    return {
      email: null,
      password: null,
      rememberMe: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loginLoading: 'auth/loginLoading',
      loginError: 'auth/loginError'
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    })
  }
}
</script>

<style lang="scss">
@import '../../assets/auth.scss';
</style>
