<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="oneClickLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="oneClickError" class="alert alert-danger">
        <template v-if="oneClickError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="all-groups-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allGroups') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.allGroups" type="checkbox" class="custom-control-input" id="all-groups-checkbox">
                <label class="custom-control-label" for="all-groups-checkbox"></label>
              </div>
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.allGroups" class="invalid-feedback d-block">{{ tErrors('oneClick', 'allGroups', oneClickSubmitErrors.allGroups) }}</div>
            </div>
          </div>

          <div class="form-group row" v-if="!oneClick.allGroups">
            <label for="multiselect-groups" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="oneClick.group"
                :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('oneClick', 'group', oneClickSubmitErrors.group) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="all-retailers-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.allRetailers') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.allRetailers" type="checkbox" class="custom-control-input" id="all-retailers-checkbox">
                <label class="custom-control-label" for="all-retailers-checkbox"></label>
              </div>
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.allRetailers" class="invalid-feedback d-block">{{ tErrors('oneClick', 'allRetailers', oneClickSubmitErrors.allRetailers) }}</div>
            </div>
          </div>

          <div class="form-group row" v-if="!oneClick.allRetailers">
            <label for="multiselect-retailers" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.retailer') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="oneClick.retailerId"
                :multiple="false"
                :options="retailers"
                :loading="retailersLoading"
                :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.retailer }"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false">
                <template v-slot:option="{ option }">
                  <div class="gutter-x-xs">
                    <region-flag :code="option.region.code" />
                    <img :src="option.img_small_url" style="max-height: 20px;">
                    {{ option.name }}
                    <span class="badge badge-light">
                      {{ $t(`shared.retailerDistributions.${option.service}`) }}
                    </span>
                  </div>
                </template>
                <template v-slot:singleLabel="{ option }">
                  <div class="gutter-x-xs">
                    <region-flag :code="option.region.code" />
                    <img :src="option.img_small_url" style="max-height: 20px;">
                    {{ option.name }}
                    <span class="badge badge-light">
                      {{ $t(`shared.retailerDistributions.${option.service}`) }}
                    </span>
                  </div>
                </template>
              </key-multiselect>
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.retailer" class="invalid-feedback d-block">{{ tErrors('oneClick', 'retailer', oneClickSubmitErrors.retailer) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="priority-checkbox" class="col-sm-2">
              <div>{{ $t('attributes.oneClick.priority') }}</div>
            </label>
            <div class="col col-auto">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.priority" type="checkbox" class="custom-control-input" id="priority-checkbox">
                <label class="custom-control-label" for="priority-checkbox"></label>
              </div>
              <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.priority" class="invalid-feedback d-block">{{ tErrors('oneClick', 'priority', oneClickSubmitErrors.priority) }}</div>
            </div>
            <small class="col text-muted">{{ $t('attributes.oneClick.priorityHelp') }}</small>
          </div>

          <hr>

          <div class="form-group row">
            <label for="expert-mode-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.expertMode') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="oneClick.expertMode" type="checkbox" class="custom-control-input" id="expert-mode-checkbox">
                <label class="custom-control-label" for="expert-mode-checkbox"></label>
              </div>
            </div>
          </div>

          <!-- Basic mode -->
          <template v-if="!oneClick.expertMode">
            <!-- Colors -->
            <div class="form-group row">
              <label for="input-colors-main-text" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.main_text') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="oneClick.colors.main_text" type="text" class="form-control" :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.colors && oneClickSubmitErrors.colors.main_text }" id="input-colors-main-text" :placeholder="$t('attributes.oneClick.colors.main_text')">
              </div>
            </div>

            <div class="form-group row">
              <label for="input-colors-loader" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.colors.loader') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="oneClick.colors.loader" type="text" class="form-control" :class="{ 'is-invalid' : oneClickSubmitErrors && oneClickSubmitErrors.colors && oneClickSubmitErrors.colors.loader }" id="input-colors-loader" :placeholder="$t('attributes.oneClick.colors.loader')">
              </div>
            </div>

            <!-- Images -->
            <div class="form-group row">
              <label for="retailer-logo-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.retailerLogo') }}</label>
              <div class="col-sm-10 col-12">
                <template v-if="oneClick.retailerLogo.url">
                  <div class="border mb-3 d-inline-block">
                    <img :src="oneClick.retailerLogo.url" class="img-fluid">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="oneClick.removeRetailerLogo" type="checkbox" class="custom-control-input" id="remove-retailer-logo-checkbox">
                      <label class="custom-control-label" for="remove-retailer-logo-checkbox">
                        {{ $t('attributes.oneClick.removeRetailerLogo') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="oneClick.retailerLogoFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="retailer-logo-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.retailerLogo" class="invalid-feedback d-block">{{ tErrors('oneClick', 'retailerLogo', oneClickSubmitErrors.retailerLogo) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="background-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.background') }}</label>
              <div class="col-sm-10 col-12">
                <template v-if="oneClick.background.url">
                  <div class="border mb-3 d-inline-block">
                    <img :src="oneClick.background.url" class="img-fluid">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="oneClick.removeBackground" type="checkbox" class="custom-control-input" id="remove-background-checkbox">
                      <label class="custom-control-label" for="remove-background-checkbox">
                        {{ $t('attributes.oneClick.removeBackground') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="oneClick.backgroundFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="background-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.background" class="invalid-feedback d-block">{{ tErrors('oneClick', 'background', oneClickSubmitErrors.background) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="background-mobile-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.backgroundMobile') }}</label>
              <div class="col-sm-10 col-12">
                <template v-if="oneClick.backgroundMobile.url">
                  <div class="border mb-3 d-inline-block">
                    <img :src="oneClick.backgroundMobile.url" class="img-fluid">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="oneClick.removeBackgroundMobile" type="checkbox" class="custom-control-input" id="remove-background-mobile-checkbox">
                      <label class="custom-control-label" for="remove-background-mobile-checkbox">
                        {{ $t('attributes.oneClick.removeBackgroundMobile') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="oneClick.backgroundMobileFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="background-mobile-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.backgroundMobile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'backgroundMobile', oneClickSubmitErrors.backgroundMobile) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="banner-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.banner') }}</label>
              <div class="col-sm-10 col-12">
                <template v-if="oneClick.banner.url">
                  <div class="border mb-3 d-inline-block">
                    <img :src="oneClick.banner.url" class="img-fluid">
                  </div>
                  <div class="mb-3">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="oneClick.removeBanner" type="checkbox" class="custom-control-input" id="remove-banner-checkbox">
                      <label class="custom-control-label" for="remove-banner-checkbox">
                        {{ $t('attributes.oneClick.removeBanner') }}
                      </label>
                    </div>
                  </div>
                </template>
                <b-form-file
                  v-model="oneClick.bannerFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="banner-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.banner" class="invalid-feedback d-block">{{ tErrors('oneClick', 'banner', oneClickSubmitErrors.banner) }}</div>
              </div>
            </div>

            <!-- Raw CSS -->
            <div class="form-group row">
              <label for="input-raw-css" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.rawCss') }}</label>
              <div class="col-sm-10 col-12">
                <codemirror
                  v-model="oneClick.rawCss"
                  :options="{ mode: 'text/css', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('oneClick', 'rawCss', oneClickSubmitErrors.rawCss) }}</div>
              </div>
            </div>

            <!-- Title -->
            <div class="form-group row">
              <label for="input-title" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.title') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="oneClick.title" type="string" class="form-control" id="input-title" :placeholder="$t('attributes.oneClick.title')">
              </div>
            </div>
            </template>

          <!-- Expert mode -->
          <template v-else>
            <!-- HTML file -->
            <div class="form-group row">
              <label for="html-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.htmlFile') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="oneClick.htmlFile.url" class="mb-3">
                  <a :href="oneClick.htmlFile.url" class="btn btn-primary" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                  </a>
                </div>
                <b-form-file
                  v-model="oneClick.htmlFileFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="html-file-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.htmlFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'htmlFile', oneClickSubmitErrors.htmlFile) }}</div>
              </div>
            </div>

            <!-- CSS file -->
            <div class="form-group row">
              <label for="css-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.cssFile') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="oneClick.cssFile.url" class="mb-3">
                  <a :href="oneClick.cssFile.url" class="btn btn-primary" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                  </a>
                </div>
                <b-form-file
                  v-model="oneClick.cssFileFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="css-file-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.cssFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'cssFile', oneClickSubmitErrors.cssFile) }}</div>
              </div>
            </div>

            <!-- JS file -->
            <div class="form-group row">
              <label for="js-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.oneClick.jsFile') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="oneClick.jsFile.url" class="mb-3">
                  <a :href="oneClick.jsFile.url" class="btn btn-primary" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-code fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 0 1-7.633.246l-64.866-60.812a5.4 5.4 0 0 1 0-7.879l64.866-60.812a5.4 5.4 0 0 1 7.633.246l19.579 20.885a5.4 5.4 0 0 1-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 0 1 .372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 0 1-3.681-6.692l61.44-211.626a5.402 5.402 0 0 1 6.692-3.681l27.452 7.97a5.4 5.4 0 0 1 3.68 6.692l-61.44 211.626a5.397 5.397 0 0 1-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 0 1-7.633-.246l-19.58-20.885a5.4 5.4 0 0 1 .372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 0 1-.372-7.747l19.58-20.885a5.4 5.4 0 0 1 7.633-.246l64.866 60.812a5.4 5.4 0 0 1-.001 7.879z" class=""></path></svg>
                  </a>
                </div>
                <b-form-file
                  v-model="oneClick.jsFileFile"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  id="js-file-input" />
                <div v-if="oneClickSubmitErrors && oneClickSubmitErrors.jsFile" class="invalid-feedback d-block">{{ tErrors('oneClick', 'jsFile', oneClickSubmitErrors.jsFile) }}</div>
              </div>
            </div>

            <!-- Show loader -->
            <div class="form-group row">
              <label for="show-loader-checkbox" class="col-sm-2">{{ $t('attributes.oneClick.showLoader') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="oneClick.showLoader" type="checkbox" class="custom-control-input" id="show-loader-checkbox">
                  <label class="custom-control-label" for="show-loader-checkbox"></label>
                </div>
              </div>
            </div>
          </template>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="oneClickSubmitLoading">
              <md-spinner v-if="oneClickSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BFormFile } from 'bootstrap-vue'
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/css/css.js'
import 'codemirror/addon/display/autorefresh.js'
import 'codemirror/lib/codemirror.css'

export default {
  components: { MdSpinner, KeyMultiselect, GroupModalSelect, RegionFlag, BFormFile, codemirror },
  props: ['action'],
  data: function() {
    return {
      oneClick: {
        id: null,
        active: true,
        allGroups: false,
        allRetailers: true,
        priority: false,
        group: null,
        retailerId: null,
        expertMode: false,
        colors: {
          main_text: null,
          loader: null
        },
        retailerLogo: {},
        retailerLogoFile: null,
        removeRetailerLogo: false,
        background: {},
        backgroundFile: null,
        removeBackground: false,
        backgroundMobile: {},
        backgroundMobileFile: null,
        removeBackgroundMobile: false,
        banner: {},
        bannerFile: null,
        removeBanner: false,
        htmlFile: {},
        htmlFileFile: null,
        cssFile: {},
        cssFileFile: null,
        jsFile: {},
        jsFileFile: null,
        showLoader: false,
        rawCss: null,
        title: null
      },
      oneClickLoading: false,
      oneClickError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      oneClickSubmitLoading: false,
      oneClickSubmitErrors: null
    }
  },
  methods: {
    // Load data
    loadData: function({ oneClick = true } = {}) {
      this.retailersLoading = true
      this.retailersError = null
      if (oneClick) {
        this.oneClickLoading = true
        this.oneClickError = null
      }

      const query = `query oneClicksForm ($oneClick: Boolean = true, $oneClickId: Int = null) {
        oneClick(id: $oneClickId) @include(if: $oneClick) {
          id
          active
          allGroups
          allRetailers
          priority
          group {
            id
            name
            region {
              id
              code
            }
          }
          retailer {
            id
          }
          expertMode
          colors
          retailerLogo
          background
          backgroundMobile
          banner
          rawCss
          title
          htmlFile
          cssFile
          jsFile
          showLoader
        }
        retailers {
          id
          name
          service
          imgSmallUrl
          region {
            id
            code
          }
        }
      }`

      const variables = {
        oneClick,
        oneClickId: oneClick ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          if (oneClick) this.oneClickLoading = false

          if (data.errors) {

          } else {
            this.retailers = Object.freeze(data.data.retailers)
            if (oneClick) {
              this.oneClick = {
                ...data.data.oneClick,
                retailerId: data.data.oneClick.retailer ? data.data.oneClick.retailer.id : null
              }
            }
          }
        })
    },
    // Form submit
    submit: async function() {
      this.oneClickSubmitLoading = true
      this.oneClickSubmitErrors = null

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            active: this.oneClick.active,
            allGroups: this.oneClick.allGroups,
            allRetailers: this.oneClick.allRetailers,
            priority: this.oneClick.priority,
            groupId: this.oneClick.allGroups ? null : this.oneClick.group.id,
            retailerId: this.oneClick.retailerId,
            expertMode: this.oneClick.expertMode
          }
        }
      }

      if (!this.oneClick.expertMode) {
        Object.assign(variables.input.attributes, {
          colors: this.oneClick.colors,
          rawCss: this.oneClick.rawCss,
          title: this.oneClick.title,
          retailerLogo: this.oneClick.retailerLogoFile ? await toBase64(this.oneClick.retailerLogoFile) : undefined,
          removeRetailerLogo: this.oneClick.removeRetailerLogo || undefined,
          background: this.oneClick.backgroundFile ? await toBase64(this.oneClick.backgroundFile) : undefined,
          removeBackground: this.oneClick.removeBackground || undefined,
          backgroundMobile: this.oneClick.backgroundMobileFile ? await toBase64(this.oneClick.backgroundMobileFile) : undefined,
          removeBackgroundMobile: this.oneClick.removeBackgroundMobile || undefined,
          banner: this.oneClick.bannerFile ? await toBase64(this.oneClick.bannerFile) : undefined,
          removeBanner: this.oneClick.removeBanner || undefined
        })
      } else {
        Object.assign(variables.input.attributes, {
          showLoader: this.oneClick.showLoader,
          htmlFile: this.oneClick.htmlFileFile ? await toBase64(this.oneClick.htmlFileFile) : undefined,
          cssFile: this.oneClick.cssFileFile ? await toBase64(this.oneClick.cssFileFile) : undefined,
          jsFile: this.oneClick.jsFileFile ? await toBase64(this.oneClick.jsFileFile) : undefined
        })
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateOneClickInput!) {
          createOneClick(input: $input) {
            oneClick {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.oneClickSubmitLoading = false

            if (data.data.createOneClick.errors) {
              this.oneClickSubmitErrors = data.data.createOneClick.errors
            } else {
              this.$router.push({ name: 'oneClick', params: { id: data.data.createOneClick.oneClick.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.oneClick.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.oneClickId = this.$route.params.id

        const mutation = `mutation($input: UpdateOneClickInput!) {
          updateOneClick(input: $input) {
            oneClick {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.oneClickSubmitLoading = false

            if (data.data.updateOneClick.errors) {
              this.oneClickSubmitErrors = data.data.updateOneClick.errors
            } else {
              this.$router.push({ name: 'oneClick', params: { id: data.data.updateOneClick.oneClick.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.oneClick.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ oneClick: false })
    }
  }
}
</script>
