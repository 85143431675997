<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="storeLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="storeError" class="alert alert-danger">
        <template v-if="storeError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ store.name }}</h5>
          <router-link :to="{ name: 'editStore', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <!-- Tabs -->
        <persistent-tabs content-class="mt-3">
          <!-- Infos -->
          <b-tab :title="$t('views.stores.show.tabs.infos')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.store.id') }}</th>
                  <td>{{ store.id }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.key') }}</th>
                  <td><code>{{ store.key }}</code></td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.url') }}</th>
                  <td>
                    <a :href="store.url" target="_blank">{{ store.url }}</a>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.name') }}</th>
                  <td>{{ store.name }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.active') }}</th>
                  <td>
                    <dot :active="store.active" />
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.group') }}</th>
                  <td>
                    <router-link :to="{ name: 'group', params: { id: store.group.id } }">
                      <region-flag :code="store.group.region.code" />
                      {{ store.group.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.groupActive') }}</th>
                  <td>
                    <dot :active="store.group.active" />
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Customizations -->
          <b-tab :title="$t('views.stores.show.tabs.customizations')">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th class="th-shrink text-nowrap">{{ $t('attributes.store.color') }}</th>
                  <td>
                    <span v-if="store.color" :style="`color: ${store.color};`">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" class=""></path></svg>
                    </span>
                    {{ store.color }}
                  </td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.borderRadius') }}</th>
                  <td>{{ store.borderRadius }}</td>
                </tr>
                <tr>
                  <th class="text-nowrap">{{ $t('attributes.store.customCss') }}</th>
                  <td>
                    <AceEditor
                      :style="{ height: '30rem' }"
                      v-model="store.customCss"
                      lang="css"
                      :options="editorOptions"
                    ></AceEditor>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>

          <!-- Products -->
          <b-tab :title="$t('views.stores.show.tabs.products')">
            <div class="form-group row">
              <label for="has-categories-checkbox" class="col-sm-2">{{ $t('attributes.store.hasCategories') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="store.hasCategories" type="checkbox" class="custom-control-input" id="has-categories-checkbox" disabled>
                  <label class="custom-control-label" for="has-categories-checkbox"></label>
                </div>
              </div>
            </div>
            <template v-if="!store.hasCategories">
              <div class="d-flex flex-wrap gutter">
                <div v-for="product in store.products" class="card product-card" :key="product.id">
                  <div class="card-body">
                    <div class="text-center mb-3">
                      <img :src="product.img.medium.url">
                    </div>
                    <div class="product-infos d-flex flex-column justify-content-center align-items-center">
                      <div class="text-center">
                        {{ product.productLanguageDatas[0].name }}
                      </div>
                      <div class="small text-center">
                        {{ product.productLanguageDatas[0].rawPackaging }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="category in store.productStoreCategories" :key="category.id" class="mb-3">
                <h5>
                  {{ category.name }}
                  <span class="badge badge-light">{{ category.products.length }}</span>
                </h5>
                <div class="d-flex flex-wrap gutter">
                  <div v-for="product in category.products" class="card product-card" :key="product.id">
                    <div class="card-body">
                      <div class="text-center mb-3">
                        <img :src="product.img.medium.url">
                      </div>
                      <div class="product-infos d-flex flex-column justify-content-center align-items-center">
                        <div class="text-center">
                          {{ product.productLanguageDatas[0].name }}
                        </div>
                        <div class="small text-center">
                          {{ product.productLanguageDatas[0].rawPackaging }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-tab>
        </persistent-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTab } from 'bootstrap-vue'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/less'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTab, AceEditor, PersistentTabs },
  data: function() {
    return {
      editorOptions: {
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false,
        readOnly: true
      },
      store: null,
      storeLoading: false,
      storeError: null
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.storeLoading = true
      this.storeError = null

      const query = `query storesShow ($id: Int!) {
        store(id: $id) {
          id
          name
          key
          url
          active
          hasCategories
          injectable
          borderRadius
          color
          customCss
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
          products {
            ...productFields
          }
          productStoreCategories {
            id
            name
            orderState
            products {
              ...productFields
            }
          }
        }
      }

      fragment productFields on Product {
        id
        img
        productLanguageDatas {
          id
          name
          rawPackaging
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.storeLoading = false

          if (data.errors) {
            this.storeError = { status: res.status, errors: data.errors }
          } else {
            this.store = Object.freeze(data.data.store)
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>

<style lang="scss">
.product-card {
  width: 160px;
  min-height: 220px;

  img {
    max-width: 100%;
    max-height: calc(160px - 2.5rem);
  }
}
</style>
