<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.group.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.group.name')">
          </div>

          <div>
            <label for="filter-active">{{ $t('attributes.group.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">Inactive</option>
              <option :value="true">Active</option>
            </select>
          </div>

          <div>
            <label for="filter-region">{{ $t('attributes.group.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </key-multiselect>
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="groupsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="groupsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.group.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink"></th>
              <th-sortable sort-key="name">{{ $t('attributes.group.name') }}</th-sortable>
              <th-sortable sort-key="region_id" class="table-col-shrink">{{ $t('attributes.group.region') }}</th-sortable>
              <th class="table-col-shrink">{{ $t('attributes.group.productsCount') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.group.widgetsCount') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.group.childrenCount') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="groupsError">
              <tr>
                <td colspan="9" class="text-center alert-danger">
                  <template v-if="groupsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internalServerError') }}
                    <a href="#" class="alert-link" @click.prevent="loadData">Retry</a>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-if="!groupsLoading && groups.length === 0">
                <td colspan="9" class="text-center alert-warning">
                  {{ $t('shared.warnings.noGroup') }}
                </td>
              </tr>
              <tr v-else v-for="group in groups" :key="group.id">
                <th>{{ group.id }}</th>
                <td class="text-center">
                  <dot :active="group.active" />
                </td>
                <td>
                  <template v-if="group.imgUrl">
                    <img :src="group.imgUrl" class="td-img" />
                  </template>
                </td>
                <td>{{ group.name }}</td>
                <td class="text-center">
                  <region-flag :code="group.region.code" />
                </td>
                <td class="text-center">{{ group.productsCount }}</td>
                <td class="text-center">{{ group.widgetsCount }}</td>
                <td class="text-center">{{ group.childrenCount }}</td>
                <td>
                  <router-link :to="{ name: 'group', params: { id: group.id } }" class="btn btn-primary btn-sm">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'

export default {
  components: { MdSpinner, KeyMultiselect, Pagination, Dot, RegionFlag, ThSortable },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        active: null,
        regionIds: []
      }
    }
  },
  computed: {
    currentPage: function() {
      return parseInt(this.$route.query.page) || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    }
  },
  methods: {
    // Load data
    loadData: function({ regions = true } = {}) {
      this.groupsLoading = true
      this.groupsError = null
      if (regions) {
        this.regionsLoading = true
        this.regionsError = null
      }

      const query = `query groupsIndex ($page: Int!, $perPage: Int!, $groupId: Int, $name: String, $active: Boolean, $regionIds: [Int!], $sort: String, $sortDirection: String, $regions: Boolean = true) {
        groupsPage(page: $page, perPage: $perPage, groupId: $groupId, name: $name, active: $active, regionIds: $regionIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          nodes {
            id
            name
            active
            imgUrl
            region {
              id
              code
            }
            productsCount
            widgetsCount
            childrenCount
          }
        }
        regions @include(if: $regions) {
          id
          code
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            page: this.currentPage,
            perPage: this.perPage,
            groupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : undefined,
            name: this.filters.name,
            active: this.filters.active,
            regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
            sort: this.$route.query.sort,
            sortDirection: this.$route.query.sort_direction,
            regions
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false
          if (regions) this.regionsLoading = false

          if (data.errors) {
            this.groupsError = { status: res.status, errors: data.errors }
            if (regions) this.regionsError = { status: res.status, errors: data.errors }
          } else {
            this.totalRows = data.data.groupsPage.nodesCount
            this.groups = Object.freeze(data.data.groupsPage.nodes)
            if (regions) this.regions = Object.freeze(data.data.regions)
          }
        })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      this.filters.name = this.$route.query.name
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }
    }
  },
  created: function() {
    this.updateFiltersFromQuery()
    this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadData({ regions: false })
    }
  }
}
</script>
