
<div>
  <transition name="fade" mode="out-in">
    <div v-if="widgetLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="widgetError" class="alert alert-danger">
      <template v-if="widgetError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <!-- Tabs -->
        <b-tabs content-class="mt-3">
          <!-- Infos -->
          <b-tab :title="$t('views.widgets.show.infos')" active>
            <div class="form-group row">
              <label for="input-name" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.widget.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="widget.name" type="text" class="form-control" :class="{ 'is-invalid' : widgetSubmitErrors && widgetSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.widget.name')">
                <div v-if="widgetSubmitErrors && widgetSubmitErrors.name" class="invalid-feedback">{{ tErrors('widget', 'name', widgetSubmitErrors.name) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-public-name" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.widget.publicName') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="widget.publicName" type="text" class="form-control" :class="{ 'is-invalid' : widgetSubmitErrors && widgetSubmitErrors.publicName }" id="input-public-name" :placeholder="$t('attributes.widget.publicName')">
                <div v-if="widgetSubmitErrors && widgetSubmitErrors.publicName" class="invalid-feedback">{{ tErrors('widget', 'publicName', widgetSubmitErrors.publicName) }}</div>
              </div>
            </div>

            <!-- Group -->
            <div class="form-group row">
              <label for="multiselect-group" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.widget.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <group-modal-select
                  v-model="widget.group"
                  id="multiselect-group" />
                <div v-if="widgetSubmitErrors && widgetSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('widget', 'group', widgetSubmitErrors.group) }}</div>
              </div>
            </div>

            <!-- Product -->
            <div class="form-group row">
              <label for="input-product-id" class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.widget.product') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <product-modal-select
                  v-model="widget.product"
                  :filter-group="widget.group"
                  id="select-product" />
                <div v-if="widgetSubmitErrors && widgetSubmitErrors.product" class="invalid-feedback">{{ tErrors('widget', 'product', widgetSubmitErrors.product) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="active-checkbox" class="col-md-2 col-sm-3">{{ $t('attributes.widget.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="widget.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                  <label class="custom-control-label" for="active-checkbox"></label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Retailers -->
          <b-tab :title="$t('views.widgets.show.retailers')">
            <!-- Retailer exceptions -->
            <div class="form-group row">
              <label class="col-md-2 col-sm-3 col-form-label">{{ $t('attributes.widget.retailerExceptions') }}</label>
              <div class="col-md-10 col-sm-9 col-12">
                <template v-if="retailersLoading">
                  <div class="text-center">
                    <md-spinner md-indeterminate></md-spinner>
                  </div>
                </template>
                <template v-else>
                  <b-tabs content-class="mt-3">
                    <b-tab v-for="distributionMethod in distributionMethods" :key="distributionMethod">
                      <template v-slot:title>
                        {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                        <span class="badge badge-primary">
                          {{ retailerExceptionsCount[distributionMethod] }}
                        </span>
                      </template>

                      <div
                        v-if="retailerExceptionsCount[distributionMethod] === 0"
                        class="alert alert-danger">
                        {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                      </div>

                      <table class="table">
                        <thead>
                          <tr>
                            <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                            <th class="th-shrink"></th>
                            <th class="th-shrink"></th>
                            <th>{{ $t('attributes.retailer.name') }}</th>
                            <th class="th-shrink"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="retailer in retailersWithStatus[distributionMethod]"
                            :key="retailer.id"
                            :class="{ 'table-danger alert-danger': retailer.exception }">
                            <th>{{ retailer.id }}</th>
                            <td><region-flag :code="retailer.region.code" /></td>
                            <td><img :src="retailer.img_small_url" style="max-height: 20px;"></td>
                            <td>{{ retailer.name }}</td>
                            <td class="text-right">
                              <button
                                v-if="retailer.exception"
                                type="button"
                                class="btn btn-sm btn-success text-nowrap"
                                @click="removeRetailer(retailer)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
                                {{ $t('shared.actions.enable') }}
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-sm btn-danger text-nowrap"
                                @click="addRetailer(retailer)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                                {{ $t('shared.actions.disable') }}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab>
                  </b-tabs>
                </template>
              </div>
            </div>
          </b-tab>

          <!-- Alternative widgets -->
          <b-tab :title="$t('views.widgets.show.alternativeWidgets')">
            <div v-if="widget.alternativeWidgetsWidgets.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noAlternativeWidget') }}
            </div>
            <table v-else class="table">
              <thead>
                <tr>
                  <th class="th-shrink"></th>
                  <th class="th-shrink">{{ $t('attributes.widget.id') }}</th>
                  <th>{{ $t('attributes.widget.name') }}</th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                v-model="widget.alternativeWidgetsWidgets"
                handle=".handle">
                <!-- Widget rows - draggable -->
                <tr
                  v-for="(alternativeWidgetsWidget, index) in widget.alternativeWidgetsWidgets"
                  :key="index"
                  :class="{ 'table-danger': alternativeWidgetsWidget._destroy }">
                  <th class="th-shrink text-center handle cursor-move">
                    <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                  </th>
                  <th class="th-shrink">
                    {{ alternativeWidgetsWidget.alternativeWidget.id }}
                  </th>
                  <td>
                    <div class="d-flex justify-content-between align-items-center">
                      <router-link :to="{ name: 'widget', params: { id: alternativeWidgetsWidget.alternativeWidget.id } }">
                        {{ alternativeWidgetsWidget.alternativeWidget.name }}
                      </router-link>
                      <span v-if="!alternativeWidgetsWidget.id" class="badge badge-warning">New</span>
                    </div>
                  </td>
                  <td class="td-shrink text-nowrap">
                    <template v-if="!alternativeWidgetsWidget._destroy">
                      <button type="button" class="btn btn-sm btn-danger" @click="removeAlternativeWidgetsWidget(alternativeWidgetsWidget)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success" @click="restoreAlternativeWidgetsWidget(alternativeWidgetsWidget)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </td>
                </tr>
              </draggable>
            </table>

            <multiselect
              :custom-label="widgetLabel"
              @input="addAlternativeWidget($event)"
              :options="availableWidgets"
              open-direction="bottom"
              :allow-empty="false"
              :clear-on-select="true"
              :placeholder="$t('views.widgets.form.addAlternativeWidget')"
              :close-on-select="false"
              :select-label="''"
              :max-height="300">
              <template v-slot:option="{ option }">
                <div class="d-flex justify-content-between">
                  <div>#{{ option.id }} - {{ option.name }}</div>
                  <span class="badge badge-info" v-if="option.isAlternativeWidgetSuggestion">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exchange-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-exchange-alt fa-w-16"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z" class=""></path></svg>
                    {{ $t('attributes.widget.isAlternativeWidgetSuggestion') }}
                  </span>
                </div>
              </template>
            </multiselect>
          </b-tab>
        </b-tabs>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="widgetSubmitLoading">
            <md-spinner v-if="widgetSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
