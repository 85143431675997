<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="multiproductWidgetCustomisationLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="multiproductWidgetCustomisationError" class="alert alert-danger">
        <template v-if="multiproductWidgetCustomisationError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else class="row">
        <!-- Form -->
        <div class="col-sm-12">
          <form @submit.prevent="submit">
            <persistent-tabs content-class="mt-3">
              <!-- Infos tab -->
              <b-tab :title="$t('views.customisations.tabs.infos')">
                <div class="form-group row">
                  <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.name') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input v-model="multiproductWidgetCustomisation.name" type="text" class="form-control" :class="{ 'is-invalid' : multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.customisation.name')">
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.name" class="invalid-feedback">{{ tErrors('customisation', 'name', multiproductWidgetCustomisationSubmitErrors.name) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="multiselect-groups" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.group') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <group-modal-select
                      v-model="multiproductWidgetCustomisation.group"
                      :class="{ 'is-invalid' : multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.group }"
                      id="multiselect-group" />
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('customisation', 'group', multiproductWidgetCustomisationSubmitErrors.group) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="default-checkbox" class="col-sm-2">{{ $t('attributes.customisation.default') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.default" type="checkbox" class="custom-control-input" id="default-checkbox">
                      <label class="custom-control-label" for="default-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="multiselect-domains" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.domains') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <multiselect
                      v-model="multiproductWidgetCustomisation.domains"
                      :options="multiproductWidgetCustomisation.domains"
                      :taggable="true"
                      :multiple="true"
                      :close-on-select="false"
                      :placeholder="$t('shared.placeholders.select')"
                      @tag="multiproductWidgetCustomisation.domains.push($event)"
                      id="multiselect-domains"
                      />
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.domains" class="invalid-feedback d-block">{{ tErrors('customisation', 'domains', multiproductWidgetCustomisationSubmitErrors.domains) }}</div>
                  </div>
                </div>
              </b-tab>

              <!-- Features tab -->
              <b-tab :title="$t('views.customisations.tabs.features')">
                <div class="form-group row">
                  <label for="select-products-layout" class="col-sm-3 col-form-label">{{ $t('attributes.multiproductWidgetCustomisation.productsLayout') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <b-form-radio-group
                      id="btn-radios-products-layout"
                      v-model="multiproductWidgetCustomisation.productsLayout"
                      buttons
                      button-variant="outline-primary">
                      <b-form-radio
                        v-for="value in ['grid', 'swiper']"
                        :key="value"
                        :value="value">
                        <div class="d-flex flex-column align-items-center">
                          <big>
                            <svg v-if="value === 'grid'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z"/></svg>
                            <svg v-else-if="value === 'swiper'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 680 512" class="svg-inline--fa"><path fill="currentColor" d="M 439.7871 296 v -106.4 c 0 -17.6292 -14.2908 -31.92 -31.92 -31.92 h -134.7742 c -17.6292 0 -31.92 14.2908 -31.92 31.92 v 106.4 c 0 17.6292 14.2908 31.92 31.92 31.92 h 134.7729 c 17.6305 0 31.9213 -14.2908 31.9213 -31.92 Z M 651.7 250 l -102.9608 103.8025 c -3.2964 3.2964 -8.6268 3.2964 -11.9233 0 l -13.8869 -13.8869 c -3.2964 -3.2964 -3.2964 -8.6268 0 -11.9233 l 83.1121 -83.9536 l -83.1121 -83.9536 c -3.2964 -3.2964 -3.2964 -8.6268 0 -11.9233 l 13.8869 -13.8869 c 3.2964 -3.2964 8.6268 -3.2964 11.9233 0 l 102.9608 103.8025 c 3.2964 3.2964 3.2964 8.6268 0 11.9233 z M 26.6 250 l 102.96 103.8018 c 3.2957 3.2957 8.6264 3.2957 11.9241 0 l 13.8872 -13.8872 c 3.2957 -3.2957 3.2957 -8.6264 0 -11.9241 l -83.1117 -83.9536 l 83.1117 -83.9536 c 3.2957 -3.2957 3.2957 -8.6264 0 -11.9241 l -13.8872 -13.8872 c -3.2957 -3.2957 -8.6264 -3.2957 -11.9241 0 l -102.96 103.8018 c -3.2957 3.2957 -3.2957 8.6264 0 11.9241 z" class=""></path></svg>
                          </big>
                          <small>{{ $t(`attributes.multiproductWidgetCustomisation.productsLayouts.${value}`) }}</small>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.productsLayout" class="invalid-feedback d-block">{{ tErrors('customisation', 'productsLayout', multiproductWidgetCustomisationSubmitErrors.productsLayout) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="select-layout" class="col-sm-3 col-form-label">{{ $t('attributes.customisation.layout') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <b-form-radio-group
                      id="btn-radios-layout"
                      v-model="multiproductWidgetCustomisation.layout"
                      buttons
                      button-variant="outline-primary">
                      <b-form-radio
                        v-for="value in ['columns', 'tabs']"
                        :key="value"
                        :value="value">
                        <div class="d-flex flex-column align-items-center">
                          <big>
                            <svg v-if="value === 'columns'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 h -112 c -8.837 0 -16 -7.163 -16 -16 v -250 h 128 v 266 z M 320 448 h -128 v -266 h 128 v 266 z M 480 182 v 250 c 0 8.837 -7.163 16 -16 16 h -112 v -266 h 128 z M 480 152 h -448 v -72 h 448 v 72 z" class=""></path></svg>
                            <svg v-else-if="value === 'tabs'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 H 48 c -8.837 0 -16 -7.163 -16 -16 v -160 h 448 v 160 c 0 8.837 -7.163 16 -16 16 h -416 z M 32 184 h 128 v 56 h -128 z M 192 184 h 128 v 56 h -128 z M 352 184 h 128 v 56 h -128 z M 32 80 h 448 v 72 h -448 z" class=""></path></svg>
                          </big>
                          <small>{{ $t(`attributes.customisation.layouts.${value}`) }}</small>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.layout" class="invalid-feedback d-block">{{ tErrors('customisation', 'layout', multiproductWidgetCustomisationSubmitErrors.layout) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="show-reference-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showReference') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.showReference" type="checkbox" class="custom-control-input" id="show-reference-checkbox">
                      <label class="custom-control-label" for="show-reference-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="is-vertically-centered-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isVerticallyCentered') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.isVerticallyCentered" type="checkbox" class="custom-control-input" id="is-vertically-centered-checkbox">
                      <label class="custom-control-label" for="is-vertically-centered-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="retailer-price-filter-checkbox"
                    class="col-sm-3"
                    v-b-tooltip.hover :title="$t('views.products.tooltip.retailerPriceFilter')">
                    {{ $t('attributes.customisation.retailerPriceFilter') }}
                    <sup>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                    </sup>
                  </label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.retailerPriceFilter" type="checkbox" class="custom-control-input" id="retailer-price-filter-checkbox">
                      <label class="custom-control-label" for="retailer-price-filter-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.mapFirst') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.mapFirst" type="checkbox" class="custom-control-input" id="map-first-checkbox">
                      <label class="custom-control-label" for="map-first-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="display-store-phone-on-list-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayStorePhoneOnList') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.displayStorePhoneOnList" type="checkbox" class="custom-control-input" id="display-store-phone-on-list-checkbox">
                      <label class="custom-control-label" for="display-store-phone-on-list-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="display-phone-in-info-window-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayPhoneInInfoWindow') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.displayPhoneInInfoWindow" type="checkbox" class="custom-control-input" id="display-phone-in-info-window-checkbox">
                      <label class="custom-control-label" for="display-phone-in-info-window-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="display-loading-placeholders-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayLoadingPlaceholders') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.displayLoadingPlaceholders" type="checkbox" class="custom-control-input" id="display-loading-placeholders-checkbox">
                      <label class="custom-control-label" for="display-loading-placeholders-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="show-drive-directions-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showDriveDirections') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.showDriveDirections" type="checkbox" class="custom-control-input" id="show-drive-directions-checkbox">
                      <label class="custom-control-label" for="show-drive-directions-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="browser-geolocation-checkbox" class="col-sm-3">{{ $t('attributes.customisation.browserGeolocation') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.browserGeolocation" type="checkbox" class="custom-control-input" id="browser-geolocation-checkbox">
                      <label class="custom-control-label" for="browser-geolocation-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="use-geoip-checkbox"
                    class="col-sm-3"
                  >
                    <span v-b-tooltip.hover :title="$t('views.products.tooltip.geoIp')">
                      {{ $t('attributes.customisation.useGeoip') }}
                      <sup>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                      </sup>
                    </span>
                  </label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.useGeoip" type="checkbox" class="custom-control-input" id="use-geoip-checkbox">
                      <label class="custom-control-label" for="use-geoip-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="side-opening-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isSideOpening') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.isSideOpening" type="checkbox" class="custom-control-input" id="side-opening-checkbox">
                      <label class="custom-control-label" for="side-opening-checkbox"></label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input-radius" class="col-sm-3 col-form-label">{{ $t('attributes.customisation.radius') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input
                      v-model.number="multiproductWidgetCustomisation.radius"
                      type="range"
                      min="0"
                      max="50"
                      step="5"
                      class="form-control-range"
                      id="input-radius"
                    />
                    {{ multiproductWidgetCustomisation.radius }} km
                  </div>
                </div>

                <hr>

                <h5 class="mb-3">{{ $t('views.customisations.additionalFields') }}</h5>

                <div class="form-group row">
                  <label for="price-checkbox" class="col-sm-3">{{ $t('attributes.customisation.price') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="multiproductWidgetCustomisation.price" type="checkbox" class="custom-control-input" id="price-checkbox">
                      <label class="custom-control-label" for="price-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Layout tabs only -->
                <template v-if="multiproductWidgetCustomisation.layout === 'tabs'">
                  <div class="form-group row">
                    <label for="show-shipping-cost-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingCost') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="custom-control custom-switch custom-switch-color">
                        <input v-model="multiproductWidgetCustomisation.showShippingCost" type="checkbox" class="custom-control-input" id="show-shipping-cost-checkbox">
                        <label class="custom-control-label" for="show-shipping-cost-checkbox"></label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="show-shipping-delay-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingDelay') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="custom-control custom-switch custom-switch-color">
                        <input v-model="multiproductWidgetCustomisation.showShippingDelay" type="checkbox" class="custom-control-input" id="show-shipping-delay-checkbox">
                        <label class="custom-control-label" for="show-shipping-delay-checkbox"></label>
                      </div>
                    </div>
                  </div>
                </template>
              </b-tab>

              <!-- Style tab -->
              <b-tab :title="$t('views.customisations.tabs.style')">
                <div class="form-group row">
                  <label for="input-color" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.color') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input v-model="multiproductWidgetCustomisation.color" type="text" class="form-control" :class="{ 'is-invalid' : multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.color }" id="input-color" :placeholder="$t('attributes.customisation.color')">
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.color" class="invalid-feedback">{{ tErrors('customisation', 'color', multiproductWidgetCustomisationSubmitErrors.color) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input-border-radius" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.borderRadius') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input v-model.number="multiproductWidgetCustomisation.borderRadius" type="number" class="form-control" :class="{ 'is-invalid' : multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.borderRadius }" id="input-border-radius" :placeholder="$t('attributes.customisation.borderRadius')">
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.borderRadius" class="invalid-feedback">{{ tErrors('customisation', 'borderRadius', multiproductWidgetCustomisationSubmitErrors.borderRadius) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="font-input" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.font') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <template v-if="multiproductWidgetCustomisation.font.url">
                      <div class="border mb-3 d-inline-block">
                        <a
                          :href="multiproductWidgetCustomisation.font.url"
                          class="btn btn-primary"
                          :class="{ 'opacity-50': multiproductWidgetCustomisation.removeFont }"
                          target="_blank">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" class=""></path></svg>
                          {{ filenameFromUrl(multiproductWidgetCustomisation.font.url) }}
                        </a>
                      </div>
                      <div class="mb-3">
                        <div class="custom-control custom-switch custom-switch-color">
                          <input v-model="multiproductWidgetCustomisation.removeFont" type="checkbox" class="custom-control-input" id="remove-font-checkbox">
                          <label class="custom-control-label" for="remove-font-checkbox">
                            {{ $t('attributes.customisation.removeFont') }}
                          </label>
                        </div>
                      </div>
                    </template>
                    <b-form-file
                      v-model="multiproductWidgetCustomisation.fontFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="font-input" />
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.font" class="invalid-feedback d-block">{{ tErrors('customisation', 'font', multiproductWidgetCustomisationSubmitErrors.font) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="logo-input" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.logo') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <template v-if="multiproductWidgetCustomisation.logo.url">
                      <div class="border mb-3 d-inline-block">
                        <img
                          :src="multiproductWidgetCustomisation.logo.url"
                          class="img-fluid"
                          :class="{ 'opacity-50': multiproductWidgetCustomisation.removeLogo }"
                        >
                      </div>
                      <div class="mb-3">
                        <div class="custom-control custom-switch custom-switch-color">
                          <input v-model="multiproductWidgetCustomisation.removeLogo" type="checkbox" class="custom-control-input" id="remove-logo-checkbox">
                          <label class="custom-control-label" for="remove-logo-checkbox">
                            {{ $t('attributes.customisation.removeLogo') }}
                          </label>
                        </div>
                      </div>
                    </template>
                    <b-form-file
                      v-model="multiproductWidgetCustomisation.logoFile"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="logo-input" />
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.logo" class="invalid-feedback d-block">{{ tErrors('customisation', 'logo', multiproductWidgetCustomisationSubmitErrors.logo) }}</div>
                  </div>
                </div>
              </b-tab>

              <!-- Retailers tab -->
              <b-tab :title="$t('views.customisations.tabs.retailers')">
                <!-- Ordered delivery ids -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.customisation.orderedDeliveryIds') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <RetailerMultiselect
                      :placeholder="$t('shared.placeholders.select')"
                      :skipRetailerIds="multiproductWidgetCustomisation.orderedDeliveries.map(retailer => retailer.id)"
                      :regionId="regionId"
                      @select="addRetailer"
                      class="mb-3"
                    />

                    <div v-if="multiproductWidgetCustomisation.orderedDeliveries.length === 0" class="alert alert-warning" role="alert">
                      {{ $t('shared.warnings.noRetailer') }}
                    </div>
                    <table
                      class="table table-hover"
                      v-else>
                      <thead>
                        <tr>
                          <th class="th-shrink"></th>
                          <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                          <th>{{ $t('attributes.retailer.name') }}</th>
                          <th class="th-shrink"></th>
                        </tr>
                      </thead>
                      <draggable
                        tag="tbody"
                        v-model="multiproductWidgetCustomisation.orderedDeliveries"
                        handle=".handle">
                        <tr v-for="retailer in multiproductWidgetCustomisation.orderedDeliveries" :key="retailer.id">
                          <th class="text-center handle cursor-move">
                            <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                          </th>
                          <th>{{ retailer.id }}</th>
                          <td>
                            <div class="gutter-x-xs">
                              <region-flag :code="retailer.region.code" />
                              <img :src="retailer.img_small_url" style="max-height: 20px;">
                              {{ retailer.name }}
                            </div>
                          </td>
                          <td class="text-nowrap">
                            <button type="button" class="btn btn-sm btn-danger" @click="removeRetailer(retailer)">
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                              {{ $t('shared.actions.delete') }}
                            </button>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>

                <!-- Delivery tab sort -->
                <div class="form-group row">
                  <label for="select-delivery-tab-sort" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.deliveryTabSort') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <b-form-radio-group
                      id="btn-radios-delivery-tab-sort"
                      v-model="multiproductWidgetCustomisation.deliveryTabSort"
                      stacked
                    >
                      <b-form-radio
                        v-for="value in deliveryTabSorts"
                        :key="value"
                        :value="value"
                      >
                        {{ $t(`attributes.customisation.deliveryTabSorts.${value}`) }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.deliveryTabSort" class="invalid-feedback d-block">{{ tErrors('multiproductWidgetCustomisation', 'deliveryTabSort', multiproductWidgetCustomisationSubmitErrors.deliveryTabSort) }}</div>
                  </div>
                </div>
              </b-tab>

              <!-- Texts tab -->
              <b-tab :title="$t('views.customisations.tabs.texts')">
                <div class="mb-3" style="max-width: 150px;">
                  <key-multiselect
                    v-model="languageTabIndex"
                    :options="locales"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    label="code"
                    track-by="id"
                    :allow-empty="false"
                    :show-labels="false">
                    <template v-slot:option="{ option }">
                      <region-flag :code="option.region.code" />
                      {{ option.code }}
                    </template>
                    <template v-slot:singleLabel="{ option }">
                      <region-flag :code="option.region.code" />
                      {{ option.code }}
                    </template>
                  </key-multiselect>
                </div>

                <b-tabs content-class="mt-3" v-model="languageTabIndex" nav-class="d-none">
                  <b-tab v-for="locale in locales" :key="locale.language.id">
                    <template v-slot:title>
                      <region-flag :code="locale.region.code" />
                      {{ locale.code }}
                    </template>

                    <div v-for="textKey in textKeys" :key="textKey" class="form-group row">
                      <label :for="`input-${locale.code}-${textKey}`" class="col-sm-2 col-form-label text-break">{{ textKey }}</label>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <input
                          v-model="multiproductWidgetCustomisation.texts[locale.code][textKey]"
                          type="text"
                          class="form-control"
                          :id="`input-${locale.code}-${textKey}`"
                          :placeholder="textKey">
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-tab>

              <!-- Tracking tab -->
              <b-tab :title="$t('views.customisations.tabs.tracking')">
                <div class="form-group row">
                  <label for="select-tracking-consent-request" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.trackingConsentRequest') }}</label>
                  <div class="col-sm-8 col-12">
                    <b-form-radio-group
                      class="d-flex"
                      id="btn-radios-tracking-consent-request"
                      v-model="multiproductWidgetCustomisation.trackingConsentRequest"
                      stacked
                    >
                      <b-form-radio
                        v-for="value in trackingConsentRequests"
                        :key="value"
                        :value="value"
                      >
                        {{ $t(`attributes.customisation.trackingConsentRequests.${value}`) }}
                        <small class="form-text text-muted mb-2">{{ $t(`attributes.customisation.trackingConsentRequestsHelp.${value}`) }}</small>
                      </b-form-radio>
                    </b-form-radio-group>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.trackingConsentRequest" class="invalid-feedback d-block">{{ tErrors('multiproductWidgetCustomisation', 'trackingConsentRequest', multiproductWidgetCustomisationSubmitErrors.trackingConsentRequest) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input-trackers-html" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.trackersHtml') }}</label>
                  <div class="col-12 mt-2">
                    <AceEditor
                      :style="{ height: '60vh' }"
                      v-model="multiproductWidgetCustomisation.trackersHtml"
                      lang="html"
                      :options="editorOptions"
                    ></AceEditor>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.trackersHtml" class="invalid-feedback">{{ tErrors('customisation', 'trackersHtml', multiproductWidgetCustomisationSubmitErrors.trackersHtml) }}</div>
                  </div>
                </div>
              </b-tab>

              <!-- CSS tab -->
              <b-tab :title="$t('views.customisations.tabs.css')">
                <div class="alert alert-warning col-12">
                  {{ $t('views.customisations.warnings.advanced') }}
                </div>
                <div class="form-group row">
                  <label for="input-raw-css" class="col-sm-4 col-form-label">
                    {{ $t('attributes.customisation.rawCss') }}
                    <button type="button" class="btn btn-primary btn-sm ml-auto" @click="updateLiveRawCss()" v-if="false">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sync-alt fa-w-16"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" class=""></path></svg>
                    </button>
                  </label>
                  <div class="col-12 mt-2">
                    <AceEditor
                      :style="{ height: '60vh' }"
                      v-model="multiproductWidgetCustomisation.rawCss"
                      lang="css"
                      :options="editorOptions"
                    ></AceEditor>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('customisation', 'rawCss', multiproductWidgetCustomisationSubmitErrors.rawCss) }}</div>
                  </div>
                </div>
              </b-tab>

              <!-- JS tab -->
              <b-tab :title="$t('views.customisations.tabs.javascript')">
                <div class="alert alert-warning col-12">
                  {{ $t('views.customisations.warnings.advanced') }}
                </div>

                <div class="form-group row">
                  <label for="input-raw-js" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawJs') }}</label>
                  <div class="col-12 mt-2">
                    <AceEditor
                      :style="{ height: '60vh' }"
                      v-model="multiproductWidgetCustomisation.rawJs"
                      lang="javascript"
                      :options="editorOptions"
                    ></AceEditor>
                    <div v-if="multiproductWidgetCustomisationSubmitErrors && multiproductWidgetCustomisationSubmitErrors.rawJs" class="invalid-feedback">{{ tErrors('customisation', 'rawJs', multiproductWidgetCustomisationSubmitErrors.rawJs) }}</div>
                  </div>
                </div>
              </b-tab>
            </persistent-tabs>

            <div class="form-group">
              <button type="submit" class="btn btn-primary" :disabled="multiproductWidgetCustomisationSubmitLoading">
                <md-spinner v-if="multiproductWidgetCustomisationSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
                <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
                <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTabs, BTab, BFormFile, BFormRadioGroup, BFormRadio, VBTooltip } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import RetailerMultiselect from '../shared/RetailerMultiselect.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/less'
import 'brace/snippets/javascript'

export default {
  components: { MdSpinner, Multiselect, KeyMultiselect, GroupModalSelect, RegionFlag, BTabs, BTab, BFormFile, BFormRadioGroup, BFormRadio, draggable, AceEditor, PersistentTabs, RetailerMultiselect },
  directives: { 'b-tooltip': VBTooltip },
  props: ['action'],
  data: function() {
    return {
      editorOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false
      },
      multiproductWidgetCustomisation: {
        id: null,
        name: null,
        group: null,
        default: false,
        productsLayout: 'swiper',
        layout: 'columns',
        price: false,
        showReference: false,
        isVerticallyCentered: false,
        retailerPriceFilter: false,
        mapFirst: false,
        displayStorePhoneOnList: false,
        displayPhoneInInfoWindow: false,
        displayLoadingPlaceholders: false,
        showDriveDirections: false,
        browserGeolocation: true,
        useGeoip: false,
        showShippingCost: false,
        showShippingDelay: false,
        color: null,
        borderRadius: null,
        font: {},
        fontFile: null,
        removeFont: false,
        logo: {},
        logoFile: null,
        removeLogo: false,
        rawCss: null,
        rawJs: null,
        trackersHtml: null,
        trackingConsentRequest: 'none',
        texts: {},
        orderedDeliveries: [],
        deliveryTabSort: 'nationalStockFirst',
        domains: [],
        radius: 10,
        isSideOpening: false
      },
      multiproductWidgetCustomisationLoading: false,
      multiproductWidgetCustomisationError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      multiproductWidgetCustomisationSubmitLoading: false,
      multiproductWidgetCustomisationSubmitErrors: null,
      deliveryTabSorts: ['nationalStockFirst', 'mixedNationalStockPriority'],
      textKeys: ['productSelectionInstructions', 'selectProduct', 'backToProductSelection', 'promptCity', 'drive', 'delivery', 'store', 'view', 'directions', 'info', 'visitSite', 'viewDirections', 'unavailable', 'list', 'map', 'shippingFees', 'freeShipping', 'shippingDelay', 'alternativeWidgets', 'warningNoResultsForYourSearch', 'warningNoGeolocation', 'warningNoGeolocationDelivery', 'warningNoRetailOutlets', 'warningNoRetailOutletsDelivery', 'infoUserDateIsHoliday', 'terms', 'termsUrl', 'gdprNotice', 'gdprDecline'],
      trackingConsentRequests: ['none', 'banner'],
      languageTabIndex: 0,
      fullscreenPreview: false
    }
  },
  computed: {
    // Locales for texts
    locales: function() {
      const locales = []
      let index = 0

      this.regions.forEach(region => {
        region.languages.forEach(language => {
          locales.push({
            region,
            language,
            code: `${language.code}-${region.code}`,
            id: index
          })
          index++
        })
      })

      return locales
    },
    regionId: function() {
      return this.multiproductWidgetCustomisation.group ? this.multiproductWidgetCustomisation.group.region.id : null
    }
  },
  methods: {
    // Get filename from url
    filenameFromUrl: function(url) {
      return new URL(url).pathname.split('/').pop()
    },
    // Load data
    loadData: function({ multiproductWidgetCustomisation = true } = {}) {
      this.retailersLoading = true
      this.retailersError = null
      this.regionsLoading = true
      this.regionsError = null
      if (multiproductWidgetCustomisation) {
        this.multiproductWidgetCustomisationLoading = true
        this.multiproductWidgetCustomisationError = null
      }

      const query = `query multiproductWidgetCustomisationsForm ($multiproductWidgetCustomisation: Boolean = true, $multiproductWidgetCustomisationId: Int = null) {
        multiproductWidgetCustomisation(id: $multiproductWidgetCustomisationId) @include(if: $multiproductWidgetCustomisation) {
          id
          name
          default
          domains
          productsLayout
          layout
          price
          showReference
          isVerticallyCentered
          retailerPriceFilter
          mapFirst
          displayStorePhoneOnList
          displayPhoneInInfoWindow
          displayLoadingPlaceholders
          showDriveDirections
          browserGeolocation
          useGeoip
          showShippingCost
          showShippingDelay
          color
          borderRadius
          font
          logo
          texts
          trackersHtml
          trackingConsentRequest
          rawCss
          rawJs
          group {
            id
            name
            region {
              id
              code
            }
          }
          orderedDeliveries {
            id
            name
            service
            region {
              id
              code
            }
          }
          deliveryTabSort
          radius
          isSideOpening
        }
        regions {
          id
          code
          languages {
            id
            code
          }
        }
      }`

      const variables = {
        multiproductWidgetCustomisation,
        multiproductWidgetCustomisationId: multiproductWidgetCustomisation ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          this.regionsLoading = false
          if (multiproductWidgetCustomisation) this.multiproductWidgetCustomisationLoading = false

          if (data.errors) {

          } else {
            this.retailers = Object.freeze(data.data.retailers)
            this.regions = Object.freeze(data.data.regions)
            if (multiproductWidgetCustomisation) {
              this.multiproductWidgetCustomisation = {
                ...data.data.multiproductWidgetCustomisation,
                texts: data.data.multiproductWidgetCustomisation.texts || {}
              }
            }
            this.initCustomisationTextLanguages()
          }
        })
    },
    // Initialize customisation text languages when both customisations & regions are loaded
    initCustomisationTextLanguages: function() {
      if (this.regions.length > 0) {
        this.regions.forEach(region => {
          region.languages.forEach(language => {
            const locale = `${language.code}-${region.code}`
            this.multiproductWidgetCustomisation.texts[locale] = this.multiproductWidgetCustomisation.texts[locale] || {}
          })
        })
      }
    },
    // Add retailer to ordered deliveries
    addRetailer: function(retailer) {
      this.multiproductWidgetCustomisation.orderedDeliveries.push(retailer)
    },
    // Remove retailer from ordered deliveries
    removeRetailer: function(retailer) {
      const index = this.multiproductWidgetCustomisation.orderedDeliveries.indexOf(retailer)
      this.multiproductWidgetCustomisation.orderedDeliveries.splice(index, 1)
    },
    // Update live preview
    updateLivePreview: function() {
      const iframe = document.querySelector('#multiproduct-widget-customisation-preview-frame')
      if (iframe) {
        const message = {
          multiproductWidgetCustomisation: {
            productsLayout: this.multiproductWidgetCustomisation.productsLayout,
            layout: this.multiproductWidgetCustomisation.layout,
            price: this.multiproductWidgetCustomisation.price,
            is_vertically_centered: this.multiproductWidgetCustomisation.isVerticallyCentered,
            retailer_price_filter: this.multiproductWidgetCustomisation.retailerPriceFilter,
            show_reference: this.multiproductWidgetCustomisation.showReference,
            show_shipping_cost: this.customisation.showShippingCost,
            show_shipping_delay: this.customisation.showShippingDelay,
            border_radius: parseInt(this.multiproductWidgetCustomisation.borderRadius),
            color: this.multiproductWidgetCustomisation.color,
            font: { url: this.multiproductWidgetCustomisation.font.url },
            texts: this.multiproductWidgetCustomisation.texts
          }
        }
        iframe.contentWindow.postMessage(message, '*')
      }
    },
    // Update only raw css - broken on the widget side
    updateLiveRawCss: function() {
      const iframe = document.querySelector('#multiproduct-widget-customisation-preview-frame')
      if (iframe) {
        const message = {
          multiproductWidgetCustomisation: {
            raw_css: this.multiproductWidgetCustomisation.rawCss
          }
        }
        iframe.contentWindow.postMessage(message, '*')
      }
    },
    // Form submit
    submit: async function() {
      this.multiproductWidgetCustomisationSubmitLoading = true
      this.multiproductWidgetCustomisationSubmitErrors = null

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            name: this.multiproductWidgetCustomisation.name,
            groupId: this.multiproductWidgetCustomisation.group.id,
            default: this.multiproductWidgetCustomisation.default,
            productsLayout: this.multiproductWidgetCustomisation.productsLayout,
            layout: this.multiproductWidgetCustomisation.layout,
            price: this.multiproductWidgetCustomisation.price,
            showReference: this.multiproductWidgetCustomisation.showReference,
            isVerticallyCentered: this.multiproductWidgetCustomisation.isVerticallyCentered,
            retailerPriceFilter: this.multiproductWidgetCustomisation.retailerPriceFilter,
            mapFirst: this.multiproductWidgetCustomisation.mapFirst,
            displayStorePhoneOnList: this.multiproductWidgetCustomisation.displayStorePhoneOnList,
            displayPhoneInInfoWindow: this.multiproductWidgetCustomisation.displayPhoneInInfoWindow,
            displayLoadingPlaceholders: this.multiproductWidgetCustomisation.displayLoadingPlaceholders,
            showDriveDirections: this.multiproductWidgetCustomisation.showDriveDirections,
            browserGeolocation: this.multiproductWidgetCustomisation.browserGeolocation,
            useGeoip: this.multiproductWidgetCustomisation.useGeoip,
            showShippingCost: this.multiproductWidgetCustomisation.showShippingCost,
            showShippingDelay: this.multiproductWidgetCustomisation.showShippingDelay,
            color: this.multiproductWidgetCustomisation.color,
            borderRadius: this.multiproductWidgetCustomisation.borderRadius,
            rawCss: this.multiproductWidgetCustomisation.rawCss,
            rawJs: this.multiproductWidgetCustomisation.rawJs,
            trackersHtml: this.multiproductWidgetCustomisation.trackersHtml,
            trackingConsentRequest: this.multiproductWidgetCustomisation.trackingConsentRequest,
            texts: this.multiproductWidgetCustomisation.texts,
            orderedDeliveryIds: this.multiproductWidgetCustomisation.orderedDeliveries.map(retailer => retailer.id),
            deliveryTabSort: this.multiproductWidgetCustomisation.deliveryTabSort,
            domains: this.multiproductWidgetCustomisation.domains,
            font: this.multiproductWidgetCustomisation.fontFile ? await toBase64(this.multiproductWidgetCustomisation.fontFile) : undefined,
            removeFont: this.multiproductWidgetCustomisation.removeFont || undefined,
            logo: this.multiproductWidgetCustomisation.logoFile ? await toBase64(this.multiproductWidgetCustomisation.logoFile) : undefined,
            removeLogo: this.multiproductWidgetCustomisation.removeLogo || undefined,
            radius: this.multiproductWidgetCustomisation.radius,
            isSideOpening: this.multiproductWidgetCustomisation.isSideOpening
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateMultiproductWidgetCustomisationInput!) {
          createMultiproductWidgetCustomisation(input: $input) {
            multiproductWidgetCustomisation {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.multiproductWidgetCustomisationSubmitLoading = false

            if (data.data.createMultiproductWidgetCustomisation.errors) {
              this.multiproductWidgetCustomisationSubmitErrors = data.data.createMultiproductWidgetCustomisation.errors
            } else {
              this.$router.push({ name: 'multiproductWidgetCustomisation', params: { id: data.data.createMultiproductWidgetCustomisation.multiproductWidgetCustomisation.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.multiproductWidgetCustomisation.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.multiproductWidgetCustomisationId = this.$route.params.id

        const mutation = `mutation($input: UpdateMultiproductWidgetCustomisationInput!) {
          updateMultiproductWidgetCustomisation(input: $input) {
            multiproductWidgetCustomisation {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.multiproductWidgetCustomisationSubmitLoading = false

            if (data.data.updateMultiproductWidgetCustomisation.errors) {
              this.multiproductWidgetCustomisationSubmitErrors = data.data.updateMultiproductWidgetCustomisation.errors
            } else {
              this.$router.push({ name: 'multiproductWidgetCustomisation', params: { id: data.data.updateMultiproductWidgetCustomisation.multiproductWidgetCustomisation.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.multiproductWidgetCustomisation.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ multiproductWidgetCustomisation: false })
    }

    const props = ['layout', 'productsLayout', 'price', 'isVerticallyCentered', 'retailerPriceFilter', 'mapFirst', 'displayStorePhoneOnList', 'displayPhoneInInfoWindow', 'displayLoadingPlaceholders', 'showDriveDirections', 'browserGeolocation', 'showShippingCost', 'showShippingDelay', 'showReference', 'borderRadius', 'color', 'font']
    props.forEach(prop => {
      this.$watch(`multiproductWidgetCustomisation.${prop}`, this.updateLivePreview)
    })
    this.$watch('multiproductWidgetCustomisation.texts', { handler: this.updateLivePreview, deep: true })
  }
}
</script>
