
<div>
  <transition name="fade" mode="out-in">
    <div v-if="customServiceLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="customServiceError" class="alert alert-danger">
      <template v-if="customServiceError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="form-group row">
          <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.customService.name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="customService.name" type="text" class="form-control" :class="{ 'is-invalid' : customServiceSubmitErrors && customServiceSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.customService.name')">
            <div v-if="customServiceSubmitErrors && customServiceSubmitErrors.name" class="invalid-feedback">{{ tErrors('customService', 'name', customServiceSubmitErrors.name) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.customService.group') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <group-modal-select
              v-model="customService.group"
              :class="{ 'is-invalid' : customServiceSubmitErrors && customServiceSubmitErrors.group }"
              id="multiselect-group" />
            <div v-if="customServiceSubmitErrors && customServiceSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('customService', 'group', customServiceSubmitErrors.group) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.customService.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="customService.active" type="checkbox" class="custom-control-input" id="active-checkbox">
              <label class="custom-control-label" for="active-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="customServiceSubmitLoading">
            <md-spinner v-if="customServiceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
