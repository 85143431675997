
<div>
  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="pimDataSourcesLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="pimDataSourcesLoading">
        <thead>
          <tr>
            <th class="th-shrink"></th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.name') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.startedAt') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.updatedAt') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.duration') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.productsCount') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.countErrors') }}</th>
            <th
              class="th-shrink text-center"
              v-b-tooltip.hover :title="$t('views.pimDataSources.index.tooltip.disableProducts')">
              {{ $t('attributes.pimDataSource.disableProducts') }}
              <sup>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
              </sup>
            </th>
            <th
              class="th-shrink text-center"
              v-b-tooltip.hover :title="$t('views.pimDataSources.index.tooltip.disableThreshold')">
              {{ $t('attributes.pimDataSource.disableThreshold') }}
              <sup>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
              </sup>
            </th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.status') }}</th>
            <th class="th-shrink text-center">{{ $t('attributes.pimDataSource.action') }}</th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="pimDataSourcesError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="pimDataSourcesError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!pimDataSourcesLoading && pimDataSources.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noPimDataSource') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="pimDataSource in pimDataSources" :key="pimDataSource.id">
              <td class="text-center"><dot :active="pimDataSource.active" /></td>
              <td class="text-center">{{ pimDataSource.name }}</td>
              <template v-if="pimDataSource.lastJob">
                <td class="text-center">{{ pimDataSource.lastJob.startedAt | date }}</td>
                <td class="text-center">{{ pimDataSource.lastJob.endedAt | date }}</td>
                <td class="text-center">{{ pimDataSourceDuration(pimDataSource) | duration }}</td>
                <td class="text-center">{{ pimDataSource.lastJob.countProducts }}</td>
                <td class="text-center">{{ pimDataSource.lastJob.countErrors }}</td>
                <td class="text-center"><dot :active="pimDataSource.disableProducts" /></td>
                <td class="text-center">{{ pimDataSource.disableThreshold }}%</td>
                <td class="text-center">
                  <span class="badge" :class="{
                    'badge-success': pimDataSource.lastJob.status === 'success',
                    'badge-warning': pimDataSource.lastJob.status === 'running',
                    'badge-danger': pimDataSource.lastJob.status === 'error'
                  }">
                    {{ pimDataSource.lastJob.status }}
                  </span>
                </td>
              </template>
              <template v-else>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center"><dot :active="pimDataSource.disableProducts" /></td>
                <td class="text-center">{{ pimDataSource.disableThreshold }}%</td>
                <td class="text-center">
                  <span class="badge badge-success">success
                  </span>
                </td>
              </template>
              <td class="text-center">
                <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                  <template v-slot:button-content>
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                  </template>
                  <router-link :to="{ name: 'showPimDataSource', params: { id: pimDataSource.id } }" class="dropdown-item">
                    {{ $t('shared.actions.show') }}
                  </router-link>
                  <router-link :to="{ name: 'editPimDataSource', params: { id: pimDataSource.id } }" class="dropdown-item">
                    {{ $t('shared.actions.edit') }}
                  </router-link>
                  <button
                    class="dropdown-item"
                    :disabled="pimDataSourcesRunning"
                    @click="confirmProcessPimDataSource(pimDataSource)">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-play fa-w-14"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" class=""></path></svg>
                    {{ $t('attributes.pimDataSource.processImport') }}
                  </button>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>
  <b-modal
        id="confirmProcessPimDataSource"
        ref="confirmProcessPimDataSource"
        :title="$t('shared.actions.processPimDataSource')"
        @ok="processPimDataSource(pimDataSourceToBeProccessed)"
        no-fade>
        <form>
        <p v-if="pimDataSourceToBeProccessed">{{ $t('shared.actions.processPimDataSourceConfirmation') }}  {{ pimDataSourceToBeProccessed.name }} ?</p>
        </form>
      </b-modal>
</div>
