<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="pimJobLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="pimJobError" class="alert alert-danger">
        <template v-if="pimJobError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else-if="pimJob.logs.length === 0" class="alert alert-warning d-flex justify-content-between">
        {{ $t('attributes.pimDataSource.logError') }}
        <router-link :to="{ name: 'showPimDataSource', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.back') }}
        </router-link>
      </div>
      <div v-else>
        <div class="mb-3 d-flex">
          <p class="badge" :class="{ 'badge-success': pimJob.status === 'success', 'badge-warning': pimJob.status === 'running', 'badge-danger': pimJob.status === 'error' }">{{ pimJob.status }}</p>
          <h5 class="ml-2"> {{ pimJob.pimDataSource.name }} Logs #{{ pimJob.id }}</h5>
          <router-link :to="{ name: 'showPimDataSource', params: { id: $route.params.id } }"
            class="btn btn-warning ml-auto">
            {{ $t('shared.actions.back') }}
          </router-link>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-hover" id="table-stats-retailers">
            <thead>
              <tr>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.message') }}</th>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.productName') }}</th>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.productEan') }}</th>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.productUpc') }}</th>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.productImageUrl') }}</th>
                <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.groupId') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pimLog in pimJob.logs" :key="pimLog.id">
                <td class="ellipsis" style="max-width: 400px;">{{ pimLog.message }}</td>
                <td class="ellipsis" style="max-width: 400px;">{{ pimLog.productName }}</td>
                <td>{{ pimLog.productEan }}</td>
                <td>{{ pimLog.productUpc }}</td>
                <td class="ellipsis" style="max-width: 700px;">{{ pimLog.productImageUrl }}</td>
                <td>
                  <router-link :to="{ name: 'group', params: { id: pimLog.groupId } }">
                    {{ pimLog.groupId }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import { VBTooltip } from 'bootstrap-vue'
import i18n from '../../i18n'

export default {
  components: { MdSpinner },
  directives: {
    'b-tooltip': VBTooltip
  },
  data: function() {
    return {
      pimJob: {
        id: null,
        status: null,
        startedAt: null,
        endedAt: null,
        pimDataSource: {
          id: null,
          name: null
        },
        logs: []
      },
      pimJobLoading: false,
      pimJobError: null
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/user'
    })
  },
  methods: {
    // Load data
    loadData: function() {
      this.pimJobLoading = true
      this.pimJobError = null
      const query = `query PimJobShow ($pimJobId: Int!) {
      pimJob(id: $pimJobId) {
        id
        status
        startedAt
        endedAt
        pimDataSource {
          id
          name
        }
        logs {
          id
          message
          productName
          productEan
          productImageUrl
          groupId
        }
      }
    }`
      const variables = {
        pimJobId: parseInt(this.$route.params.job_id)
      }
      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({
                res,
                data
              })
            }).catch(() => {
              resolve({
                res
              })
            })
          })
        }).then(({ res, data }) => {
          this.pimJobLoading = false

          if (data.errors) {
            this.pimJobError = data.errors
          } else {
            this.pimJob = data.data.pimJob
          }
        })
    }
  },
  filters: {
    date: function(dateString) {
      return dateString ? new Date(dateString).toLocaleString(i18n.locale) : '–'
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
