
<div>
  <transition name="fade" mode="out-in">
    <div v-if="groupLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="groupError" class="alert alert-danger">
      <template v-if="groupError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <h5 class="mb-3">
        <region-flag :code="group.region.code" />
        {{ group.name }}
      </h5>

      <form @submit.prevent="submit">
        <b-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.groups.show.tabs.infos')" active>
            <div class="form-group row">
              <label for="input-baseline-validation-rate" class="col-sm-2 text-break col-form-label">{{ $t('attributes.group.baselineValidationRate') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="position-relative">
                  <input v-model="group.baselineValidationRate" type="number" step="0.01" min="0" class="form-control" :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors.baselineValidationRate }" id="input-baseline-validation-rate" :placeholder="$t('attributes.group.baselineValidationRate')" style="padding-right: 2rem;">
                  <span class="percentage-input-icon">%</span>
                </div>

                <div v-if="groupSubmitErrors && groupSubmitErrors.baselineValidationRate" class="invalid-feedback">{{ tErrors('group', 'baselineValidationRate', groupSubmitErrors.baselineValidationRate) }}</div>
              </div>
            </div>
          </b-tab>

          <!-- Retailers tab -->
          <b-tab :title="$t('views.groups.show.tabs.retailers')">
            <div class="row">
              <label class="col-sm-2 col-form-label">{{ $t('attributes.group.distributionMethods') }}</label>
              <div class="col-sm-10">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="th-shrink"></th>
                      <th class="th-shrink"></th>
                      <th></th>
                      <th>{{ $t('views.groups.inheritedFrom') }}</th>
                    </tr>
                  </thead>
                  <draggable
                    tag="tbody"
                    v-model="group.groupDeliveryMethods"
                    handle=".handle">
                    <tr
                      v-for="groupDistributionMethod in group.groupDeliveryMethods"
                      :key="groupDistributionMethod.id">
                      <th class="text-center handle cursor-move">
                        <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                      </th>
                      <td>
                        <div class="custom-control custom-switch custom-switch-color">
                          <input
                            v-model="groupDistributionMethod.active"
                            type="checkbox"
                            class="custom-control-input"
                            :id="`group-distribution-method-${groupDistributionMethod.id}-checkbox`"
                            :disabled="groupDistributionMethod.inheritedFrom">
                          <label class="custom-control-label" :for="`group-distribution-method-${groupDistributionMethod.id}-checkbox`"></label>
                        </div>
                      </td>
                      <td>
                        {{ $t(`shared.retailerDistributions.${groupDistributionMethod.deliveryMethod.name}`) }}
                      </td>
                      <td>
                        <ul v-if="groupDistributionMethod.inheritedFrom" class="list-unstyled mb-0">
                          <li v-for="group in groupDistributionMethod.inheritedFrom" :key="group.id">
                            <router-link :to="{ name: 'group', params: { id: group.id } }">
                              <region-flag :code="group.region.code" />
                              {{ group.name }}
                            </router-link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </div>
            </div>

            <!-- Retailer exceptions -->
            <div class="row">
              <label class="col-sm-2 col-form-label">{{ $t('attributes.group.retailerExceptions') }}</label>
              <div class="col-sm-10">
                <b-tabs content-class="mt-3">
                  <b-tab v-for="distributionMethod in activeDistributionMethods" :key="distributionMethod">
                    <template v-slot:title>
                      {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                      <span class="badge badge-primary">{{ retailerExceptionsCount[distributionMethod] }}</span>
                    </template>

                    <div
                      v-if="retailerExceptionsCount[distributionMethod] === 0"
                      class="alert alert-danger">
                      {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                    </div>

                    <table class="table">
                      <thead>
                        <tr>
                          <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                          <th class="th-shrink"></th>
                          <th class="th-shrink"></th>
                          <th>{{ $t('attributes.retailer.name') }}</th>
                          <th>{{ $t('views.groups.inheritedFrom') }}</th>
                          <th class="th-shrink"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="retailer in retailersWithStatus[distributionMethod]"
                          :key="retailer.id"
                          :class="{ 'table-danger alert-danger': retailer.exception }">
                          <th>{{ retailer.id }}</th>
                          <td><region-flag :code="retailer.region.code" /></td>
                          <td><img :src="retailer.img_small_url" style="max-height: 20px;"></td>
                          <td>{{ retailer.name }}</td>
                          <td>
                            <ul v-if="retailer.inheritedFrom" class="list-unstyled mb-0">
                              <li v-for="group in retailer.inheritedFrom" :key="group.id">
                                <router-link :to="{ name: 'group', params: { id: group.id } }" class="alert-link">
                                  <region-flag :code="group.region.code" />
                                  {{ group.name }}
                                </router-link>
                              </li>
                            </ul>
                          </td>
                          <td class="text-right">
                            <template v-if="!retailer.inheritedFrom">
                              <button
                                v-if="retailer.exception"
                                type="button"
                                class="btn btn-sm btn-success text-nowrap"
                                @click="removeRetailer(retailer)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
                                {{ $t('shared.actions.enable') }}
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-sm btn-danger text-nowrap"
                                @click="addRetailer(retailer)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                                {{ $t('shared.actions.disable') }}
                              </button>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-tab>

          <!-- Custom pages tab -->
          <b-tab :title="$t('views.groups.show.tabs.customPages')">
            <div class="mb-3">
              <button type="button" class="btn btn-success" @click="newCustomPage()">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                {{ $t('shared.actions.newCustomPage') }}
              </button>
            </div>

            <!-- Custom pages list -->
            <div v-if="group.customPages.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noCustomPage') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.customPage.id') }}</th>
                  <th>{{ $t('attributes.customPage.name') }}</th>
                  <th class="th-shrink">{{ $t('attributes.customPage.icon') }}</th>
                  <th class="th-shrink">{{ $t('attributes.customPage.url') }}</th>
                  <th class="th-shrink">{{ $t('attributes.customPage.active') }}</th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="customPage in group.customPages"
                  :key="customPage.id"
                  :class="{ 'table-danger': customPage._destroy }">
                  <th>{{ customPage.id }}</th>
                  <td>{{ customPage.name }}</td>
                  <td class="text-nowrap"><span class="custom-page-icon" v-html="customPage.icon"></span></td>
                  <td>{{ customPage.url }}</td>
                  <td class="text-center"><dot :active="customPage.active" /></td>
                  <td class="text-center">
                    <template v-if="customPageHasError(customPage)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </template>
                  </td>
                  <td>
                    <template v-if="!customPage.id">
                      <span class="badge badge-warning">New</span>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <div class="gutter-x-xs">
                      <button type="button" class="btn btn-sm btn-warning" @click="editCustomPage(customPage)">
                        {{ $t('shared.actions.edit') }}
                      </button>
                      <template v-if="!customPage._destroy">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteCustomPage(customPage)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-sm btn-success" @click="restoreCustomPage(customPage)">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          {{ $t('shared.actions.restore') }}
                        </button>
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Custom page modal -->
            <b-modal
              id="customPageModal"
              ref="customPageModal"
              size="xl"
              :title="$t(`shared.actions.${customPageModalAction}CustomPage`)"
              @ok="customPageModalOk"
              @shown="$refs.modalCustomPageNameInput.focus()"
              no-fade>
              <form @submit.stop.prevent="customPageModalSubmit()">
                <div class="form-group row">
                  <label for="input-custom-page-name" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.name') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input
                      v-model="customPageModal.name"
                      type="text"
                      class="form-control"
                      id="input-custom-page-name"
                      ref="modalCustomPageNameInput"
                      :placeholder="$t('attributes.customPage.name')"
                      :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].name`] }">
                    <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].name`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'name', groupSubmitErrors[`customPages[${customPageModalIndex}].name`]) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input-custom-page-icon" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.icon') }}</label>
                  <div class="col-sm-10 col-12">
                    <textarea
                      v-model="customPageModal.icon"
                      class="form-control"
                      id="input-custom-page-icon"
                      :placeholder="$t('attributes.customPage.icon')"
                      rows="3"
                      :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].icon`] }"></textarea>
                    <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].icon`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'icon', groupSubmitErrors[`customPages[${customPageModalIndex}].icon`]) }}</div>
                    <small class="mt-2 d-block text-muted">{{ $t('attributes.customPage.iconHelp') }}</small>
                    <i18n path="attributes.customPage.iconHelp2" tag="small" class="d-block text-muted">
                      <a href="https://fontawesome.com/search" target="_blank">
                        Font Awesome
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                          role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                          class="svg-inline--fa fa-external-link-alt fa-w-16">
                          <path fill="currentColor"
                            d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                            class=""></path>
                        </svg>
                      </a>
                    </i18n>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input-custom-page-url" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.url') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <input
                      v-model="customPageModal.url"
                      type="text"
                      class="form-control"
                      id="input-custom-page-url"
                      :placeholder="$t('attributes.customPage.url')"
                      :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].url`] }">
                    <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].url`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'url', groupSubmitErrors[`customPages[${customPageModalIndex}].url`]) }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="custom-page-active-checkbox" class="col-sm-2">{{ $t('attributes.customPage.active') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="customPageModal.active" type="checkbox" class="custom-control-input" id="custom-page-active-checkbox">
                      <label class="custom-control-label" for="custom-page-active-checkbox"></label>
                    </div>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-tab>

          <!-- Tracking Params tab -->
          <b-tab :title="$t('views.groups.show.tabs.trackingParams')" active>
            <div class="mb-3">
              <button type="button" class="btn btn-success" @click="newTrackingParam()">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                {{ $t('shared.actions.newTrackingParam') }}
              </button>
            </div>

            <!-- Tracking Params list -->
            <div v-if="group.allTrackingParams.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noTrackingParam') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.trackingParam.id') }}</th>
                  <th>{{ $t('attributes.trackingParam.key') }}</th>
                  <th>{{ $t('views.groups.inheritedFrom') }}</th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="trackingParam in trackingParamsSorted"
                  :key="trackingParam.id"
                  :class="{ 'table-danger': trackingParam._destroy }"
                >
                  <th>{{ trackingParam.id }}</th>
                  <td>{{ trackingParam.key }}</td>
                  <td>
                    <template v-if="trackingParam.group && trackingParam.group.id !== group.id">
                      <router-link
                        :to="{ name: 'group', params: { id: trackingParam.group.id } }"
                        class="alert-link"
                      >
                        <region-flag :code="trackingParam.group.region.code" />
                        {{ trackingParam.group.name }}
                      </router-link>
                    </template>
                  </td>
                  <td class="text-center">
                    <template v-if="trackingParamHasError(trackingParam)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </template>
                  </td>
                  <td>
                    <template v-if="!trackingParam.id">
                      <span class="badge badge-warning">New</span>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <div v-if="!trackingParam.group || trackingParam.group.id === group.id" class="gutter-x-xs">
                      <button type="button" class="btn btn-sm btn-warning" @click="editTrackingParam(trackingParam)">
                        {{ $t('shared.actions.edit') }}
                      </button>
                      <template v-if="!trackingParam._destroy">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteTrackingParam(trackingParam)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-sm btn-success" @click="restoreTrackingParam(trackingParam)">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          {{ $t('shared.actions.restore') }}
                        </button>
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Tracking Params modal -->
            <b-modal
              id="trackingParamModal"
              ref="trackingParamModal"
              size="md"
              :title="$t(`shared.actions.${trackingParamModalAction}TrackingParam`)"
              @ok="trackingParamModalOk"
              @shown="$refs.modalTrackingParamInput.focus()"
              no-fade
            >
              <form @submit.stop.prevent="trackingParamModalSubmit()">
                <div class="form-group row">
                  <label for="input-tracking-param-key" class="col-sm-2 col-form-label">
                    <span
                      v-b-tooltip.hover
                      :title="$t('views.groups.tooltip.trackingParamPrefixes', { prefixes: trackingParamPrefixes.join(', ') })"
                    >
                      {{ $t('attributes.trackingParam.key') }}
                      <sup>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                      </sup>
                    </span>
                  </label>
                  <div class="col-8">
                    <input
                      v-model="trackingParamModal.key"
                      type="text"
                      class="form-control"
                      id="input-tracking-param-key"
                      ref="modalTrackingParamInput"
                      :placeholder="$t('attributes.trackingParam.key')"
                      :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`] }"
                    >
                    <div v-if="groupSubmitErrors && groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`]" class="invalid-feedback d-block">{{ tErrors('trackingParam', 'key', groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`]) }}</div>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-tab>
        </b-tabs>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="groupSubmitLoading">
            <md-spinner v-if="groupSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            {{ $t('shared.submit.update') }}
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
