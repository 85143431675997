<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="groupApiKeyLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="groupApiKeyError" class="alert alert-danger">
        <template v-if="groupApiKeyError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.groupApiKey.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="groupApiKey.name" type="text" class="form-control" :class="{ 'is-invalid' : groupApiKeySubmitErrors && groupApiKeySubmitErrors.name }" id="input-name" :placeholder="$t('attributes.groupApiKey.name')">
              <div v-if="groupApiKeySubmitErrors && groupApiKeySubmitErrors.name" class="invalid-feedback">{{ tErrors('groupApiKey', 'name', groupApiKeySubmitErrors.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.groupApiKey.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="groupApiKey.group"
                :class="{ 'is-invalid' : groupApiKeySubmitErrors && groupApiKeySubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="groupApiKeySubmitErrors && groupApiKeySubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('groupApiKey', 'group', groupApiKeySubmitErrors.group) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-api-endpoint" class="col-sm-2 col-form-label">{{ $t('attributes.groupApiKey.apiEndpoint') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="groupApiKey.apiEndpointId"
                :options="apiEndpoints"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                label="name"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :loading="apiEndpointsLoading"
                id="multiselect-api-endpoint">
              </key-multiselect>
              <div v-if="groupApiKeySubmitErrors && groupApiKeySubmitErrors.apiEndpoint" class="invalid-feedback d-block">{{ tErrors('groupApiKey', 'apiEndpoint', groupApiKeySubmitErrors.apiEndpoint) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.groupApiKey.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="groupApiKey.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="groupApiKeySubmitLoading">
              <md-spinner v-if="groupApiKeySubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'

export default {
  components: { MdSpinner, GroupModalSelect, KeyMultiselect },
  props: ['action'],
  data: function() {
    return {
      groupApiKey: {
        name: null,
        active: true,
        group: null
      },
      groupApiKeyLoading: false,
      groupApiKeyError: null,
      apiEndpoints: [],
      apiEndpointsLoading: false,
      apiEndpointsError: null,
      groupApiKeySubmitLoading: false,
      groupApiKeySubmitErrors: null
    }
  },
  methods: {
    // Load data
    loadData: function({ groupApiKey = true } = {}) {
      this.apiEndpointsLoading = true
      this.apiEndpointsError = null
      if (groupApiKey) {
        this.groupApiKeyLoading = true
        this.groupApiKeyError = null
      }

      const query = `query groupApiKeysForm ($groupApiKey: Boolean = true, $groupApiKeyId: Int = null) {
        groupApiKey(id: $groupApiKeyId) @include(if: $groupApiKey) {
          id
          name
          active
          group {
            id
            name
            region {
              id
              code
            }
          }
          apiEndpoint {
            id
          }
        }
        apiEndpoints {
          id
          name
        }
      }`

      const variables = {
        groupApiKey,
        groupApiKeyId: groupApiKey ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.apiEndpointsLoading = false
          if (groupApiKey) this.groupApiKeyLoading = false

          if (data.errors) {

          } else {
            this.apiEndpoints = Object.freeze(data.data.apiEndpoints)
            if (groupApiKey) {
              this.groupApiKey = {
                ...data.data.groupApiKey,
                apiEndpointId: data.data.groupApiKey.apiEndpoint.id
              }
            }
          }
        })
    },
    // Form submit
    submit: function() {
      this.groupApiKeySubmitLoading = true
      this.groupApiKeySubmitErrors = null

      const variables = {
        input: {
          attributes: {
            name: this.groupApiKey.name,
            active: this.groupApiKey.active,
            groupId: this.groupApiKey.group.id,
            apiEndpointId: this.groupApiKey.apiEndpointId
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateGroupAPIKeyInput!) {
          createGroupApiKey(input: $input) {
            groupApiKey {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.groupApiKeySubmitLoading = false

            if (data.data.createGroupApiKey.errors) {
              this.groupApiKeySubmitErrors = data.data.createGroupApiKey.errors
            } else {
              this.$router.push({ name: 'groupApiKeys' })
              this.$root.$bvToast.toast(this.$t('shared.success.groupApiKey.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.groupApiKeyId = this.$route.params.id

        const mutation = `mutation($input: UpdateGroupAPIKeyInput!) {
          updateGroupApiKey(input: $input) {
            groupApiKey {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.groupApiKeySubmitLoading = false

            if (data.data.updateGroupApiKey.errors) {
              this.groupApiKeySubmitErrors = data.data.updateGroupApiKey.errors
            } else {
              this.$router.push({ name: 'groupApiKeys' })
              this.$root.$bvToast.toast(this.$t('shared.success.groupApiKey.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ groupApiKey: false })
    }
  }
}
</script>

<style lang="scss" scoped>
td input {
  margin-top: -10px;
  margin-bottom: -6px;
}
</style>
