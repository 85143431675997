<template>
  <multiselect
    :value="value"
    :options="landingPages"
    :multiple="true"
    track-by="id"
    :custom-label="landingPages => landingPages.name"
    :allow-empty="true"
    :clear-on-select="false"
    :placeholder="placeholder"
    :close-on-select="false"
    :loading="landingPagesLoading"
    @search-change="handleSearchChange"
    @open="handleOpen"
    @input="emitSelectEvent"
  >
    <template v-slot:option="{ option }">
      <region-flag :code="option.group.region.code" />
      {{ option.group.name }}
      -
      {{ option.name }}
    </template>
    <template slot="afterList" v-if="landingPagesHasNextPage">
      <button class="btn btn-primary col-12" type="button" @click="loadMoreClick" :disabled="landingPagesLoading">
        {{ $t('views.retailerMultiselect.loadMore') }}
      </button>
    </template>
    <template v-slot:tag="{ option, remove }">
      <div class="btn-group" style="margin: 2.5px 5px;">
        <div class="btn btn-light btn-xs text-left">
          <region-flag :code="option.group.region.code" />
          {{ option.group.name }}
          -
          {{ option.name }}
        </div>
        <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
        </div>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import RegionFlag from '../shared/RegionFlag.vue'

export default {
  components: { Multiselect, RegionFlag },
  props: {
    placeholder: String,
    regionId: Number,
    value: Array
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      landingPages: [],
      landingPagesPage: 1,
      landingPagesHasNextPage: false,
      landingPagesLoading: false,
      landingPagesError: null,
      searchQuery: null,
      abortController: false
    }
  },
  methods: {
    emitSelectEvent: function(landingPages) {
      this.$emit('change', landingPages)
    },
    fetchMultiproductLandingPages: async function(searchQuery) {
      this.landingPagesLoading = true
      this.landingPagesError = null

      const query = `query landingPagesMultiselect($name: String, $regionIds: [Int!], $page: Int) {
        multiproductLandingPagesPage(name: $name, regionIds: $regionIds, page: $page) {
          nodes {
            id
            name
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
          hasNextPage
        }
      }`

      const variables = {
        name: searchQuery,
        regionIds: [this.regionId],
        page: this.landingPagesPage
      }

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables }),
          signal: this.abortController.signal
        })
        const data = await res.json()
        if (!data.errors) {
          this.landingPagesHasNextPage = data.data.multiproductLandingPagesPage.hasNextPage
          if (this.landingPagesPage === 1) {
            this.landingPages = data.data.multiproductLandingPagesPage.nodes
          } else {
            this.landingPages.push(...data.data.multiproductLandingPagesPage.nodes)
          }
        } else {
          this.landingPagesError = data.errors
          console.log(this.landingPagesError)
        }
      } catch (error) {
        this.landingPagesError = error
        console.log(this.landingPagesError)
      } finally {
        this.landingPagesLoading = false
      }
    },
    // Fetch landing pages before the user types anything
    handleOpen: async function() {
      if (!this.initialized) {
        this.initialized = true
        await this.fetchMultiproductLandingPages()
      }
    },
    // Handle the input search
    handleSearchChange: async function(searchQuery) {
      this.searchQuery = searchQuery
      this.landingPagesPage = 1

      if (searchQuery.length > 0) {
        await this.fetchMultiproductLandingPages(searchQuery)
      } else {
        this.initialized = false
        this.landingPages = []
      }
    },
    // Load more click
    loadMoreClick: async function() {
      this.landingPagesPage++
      await this.fetchMultiproductLandingPages(this.searchQuery)
    }
  }
}
</script>
