<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="michelinOfferLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="michelinOfferError" class="alert alert-danger">
        <template v-if="michelinOfferError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <b-tabs content-class="mt-3">
            <!-- Infos tab -->
            <b-tab :title="$t('views.michelinOffers.tabs.infos')" active>
              <div class="form-group row">
                <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="michelinOffer.name" type="text" class="form-control" :class="{ 'is-invalid' : michelinOfferSubmitErrors && michelinOfferSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.michelinOffer.name')">
                  <div v-if="michelinOfferSubmitErrors && michelinOfferSubmitErrors.name" class="invalid-feedback">{{ tErrors('michelinOffer', 'name', michelinOfferSubmitErrors.name) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.group') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <group-modal-select v-model="michelinOffer.group" />
                  <div v-if="michelinOfferSubmitErrors && michelinOfferSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('michelinOffer', 'group', michelinOfferSubmitErrors.group) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="multiselect-retailer" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.retailer') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <multiselect
                    v-model="michelinOffer.retailer"
                    :options="groupRetailers"
                    :allow-empty="false"
                    :clear-on-select="false"
                    :placeholder="$t('shared.placeholders.select')"
                    :loading="retailersLoading"
                    open-direction="bottom"
                    :class="{ 'is-invalid': michelinOfferSubmitErrors && michelinOfferSubmitErrors.retailer }"
                    id="multiselect-retailer">
                    <template v-slot:singleLabel="{ option }">
                      <div class="gutter-x-xs">
                        <region-flag :code="option.region.code" />
                        <img :src="option.imgSmallUrl" style="max-height: 20px;">
                        {{ option.name }}
                      </div>
                    </template>
                    <template v-slot:option="{ option }">
                      <div class="gutter-x-xs">
                        <region-flag :code="option.region.code" />
                        <img :src="option.imgSmallUrl" style="max-height: 20px;">
                        {{ option.name }}
                      </div>
                    </template>
                  </multiselect>
                  <div v-if="michelinOfferSubmitErrors && michelinOfferSubmitErrors.retailer" class="invalid-feedback d-block">{{ tErrors('michelinOffer', 'retailer', michelinOfferSubmitErrors.retailer) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="multiselect-michelin-widget-customisations" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.michelinWidgetCustomisations') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <multiselect
                    v-model="michelinOffer.michelinWidgetCustomisations"
                    :multiple="true"
                    :options="michelinWidgetCustomisations"
                    track-by="id"
                    :allow-empty="true"
                    :clear-on-select="false"
                    :placeholder="$t('shared.placeholders.select')"
                    :loading="michelinWidgetCustomisationsLoading"
                    open-direction="bottom"
                    class="mb-3"
                    id="multiselect-michelin-widget-customisations">
                    <template v-slot:option="{ option }">
                      <b>#{{ option.id }}</b> {{ option.name }}
                    </template>
                    <template v-slot:tag="{ option, remove }">
                      <div class="btn-group" style="margin: 2.5px 5px;">
                        <div class="btn btn-light btn-xs text-left">
                          <b>#{{ option.id }}</b> {{ option.name }}
                        </div>
                        <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.michelinOffer.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="michelinOffer.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                    <label class="custom-control-label" for="active-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="daterange-picker" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.dateRange') }}</label>
                <div class="col-sm-10 col-12">
                  <date-range-picker v-model="michelinOffer.dateRange" :time-picker="true">
                    <div slot="input" slot-scope="picker" class="btn btn-block btn-primary">
                      {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                    </div>
                  </date-range-picker>
                </div>
              </div>

              <div class="form-group row">
                <label for="img-input" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.img') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <img v-if="michelinOffer.imgUrl" :src="michelinOffer.imgUrl" class="border mb-3" style="max-width: 100%;" />
                  <b-form-file
                    v-model="michelinOffer.imgFile"
                    placeholder="Choose a file..."
                    drop-placeholder="Drop file here..."
                    id="img-input" />
                </div>
              </div>

              <div class="form-group row">
                <label for="input-tooltip" class="col-sm-2 col-form-label">{{ $t('attributes.michelinOffer.tooltip') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="michelinOffer.tooltip" type="text" class="form-control" :class="{ 'is-invalid' : michelinOfferSubmitErrors && michelinOfferSubmitErrors.tooltip }" id="input-tooltip" :placeholder="$t('attributes.michelinOffer.tooltip')">
                  <div v-if="michelinOfferSubmitErrors && michelinOfferSubmitErrors.tooltip" class="invalid-feedback">{{ tErrors('michelinOffer', 'tooltip', michelinOfferSubmitErrors.tooltip) }}</div>
                </div>
              </div>
            </b-tab>

            <!-- Products tab -->
            <b-tab>
              <template v-slot:title>
                {{ $t('views.michelinOffers.tabs.products') }}
                <template v-if="!michelinOffer.allProducts">
                  <span class="badge badge-primary">{{ michelinOffer.products.length }}</span>
                </template>
              </template>

              <div class="form-group row">
                <label for="all-products-checkbox" class="col-sm-2">{{ $t('attributes.michelinOffer.allProducts') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="michelinOffer.allProducts" type="checkbox" class="custom-control-input" id="all-products-checkbox">
                    <label class="custom-control-label" for="all-products-checkbox"></label>
                  </div>
                </div>
              </div>

              <template v-if="!michelinOffer.allProducts">
                <div v-if="michelinOffer.products.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noProduct') }}
                </div>

                <table
                  class="table table-hover"
                  v-else>
                  <tbody>
                    <tr
                      v-for="product in michelinOffer.products"
                      :key="product.id"
                      class="table-col-shrink">
                      <th class="table-col-shrink">{{ product.id }}</th>
                      <td>{{ product.productLanguageDatas[0].name }}</td>
                      <td>{{ product.productLanguageDatas[0].rawPackaging }}</td>
                      <td>{{ product.ean }}</td>
                      <td>{{ product.upc }}</td>
                      <td>
                        <region-flag :code="product.group.region.code" />
                        {{ product.group.name }}
                      </td>
                      <td class="text-nowrap table-col-shrink">
                        <button type="button" class="btn btn-sm btn-danger" @click="removeProduct(product)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="form-group">
                  <product-modal-select
                    v-model="michelinOffer.products"
                    multiple
                    :group-id="michelinOffer.group?.id"
                    id="multiselect-product">
                    <template v-slot:element>
                      <button type="button" class="btn btn-primary">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tasks" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-tasks fa-fw"><path fill="currentColor" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" class=""></path></svg>
                        {{ $t('views.michelinOffers.form.addProduct') }}
                      </button>
                    </template>
                  </product-modal-select>
                </div>

                <div class="form-group">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="$refs.productModal.show()">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-upload fa-fw"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
                    {{ $t('views.michelinOffers.form.addProductList') }}
                  </button>
                </div>

                <!-- Product list file import modal -->
                <b-modal
                  id="productModal"
                  ref="productModal"
                  size="xl"
                  :title="$t('views.michelinOffers.form.addProductList')"
                  :ok-disabled="productsLength === 0"
                  @ok="modalOk"
                  no-fade>
                  <form @submit.stop.prevent="modalSubmit()">
                    <div class="alert alert-info">
                      {{ $t('views.michelinOffers.form.productFileInfo') }}
                    </div>
                    <div class="form-group row">
                      <label for="product-file-input" class="col-sm-2 col-form-label">{{ $t('views.michelinOffers.form.productFile') }}</label>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <b-form-file
                          v-model="productFile"
                          placeholder="Choose a file..."
                          drop-placeholder="Drop file here..."
                          id="product-file-input" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="product-file-input" class="col-sm-2 col-form-label">{{ $t('views.michelinOffers.form.productReferenceType') }}</label>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <multiselect
                          v-model="productReferenceType"
                          :options="productReferenceTypes"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :placeholder="$t('shared.placeholders.select')"
                          :show-labels="false">
                        </multiselect>
                      </div>
                    </div>

                    <button
                      type="button"
                      class="btn btn-primary"
                      :disabled="productFile === null || productReferenceType === null || productFileProcessing"
                      @click="processProductFile">
                      <md-spinner v-if="productFileProcessing" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
                      {{ $t('views.michelinOffers.form.processProductFile') }}
                    </button>

                    <hr>

                    <!-- Products -->
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('views.michelinOffers.form.products') }}
                        <span v-if="productsLength > 0" class="badge badge-primary">{{ productsLength }}</span>
                      </label>
                      <div class="col-sm-10 col-12">
                        <div v-if="!productFileProcessing && productsLength === 0" class="alert alert-warning">
                          {{ $t('shared.warnings.noProduct') }}
                        </div>
                        <div v-else class="overflow-auto" style="max-height: 400px;">
                          <table class="table table-hover" :aria-busy="productFileProcessing">
                            <tbody>
                              <tr
                                v-for="(product, reference) in productsMap"
                                :key="reference"
                                class="table-col-shrink"
                                :class="{ 'table-danger': product === null }">
                                <th class="table-col-shrink">{{ reference }}</th>
                                <template v-if="product !== null">
                                  <th class="table-col-shrink">{{ product.id }}</th>
                                  <td>{{ product.productLanguageDatas[0].name }}</td>
                                  <td>{{ product.productLanguageDatas[0].rawPackaging }}</td>
                                  <td>{{ product.ean }}</td>
                                  <td>{{ product.upc }}</td>
                                </template>
                                <td v-else colspan="5"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <!-- References not found -->
                    <div v-if="referencesNotFound.length > 0" class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('views.michelinOffers.form.referencesNotFound') }}
                        <span v-if="referencesNotFound.length > 0" class="badge badge-primary">{{ referencesNotFound.length }}</span>
                      </label>
                      <div class="col-sm-10 col-12">
                        <ul class="overflow-auto" style="max-height: 400px;">
                          <li
                            v-for="reference in referencesNotFound"
                            :key="reference"
                          >
                            {{ reference }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                </b-modal>
              </template>
            </b-tab>
          </b-tabs>

          <hr>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="michelinOfferSubmitLoading">
              <md-spinner v-if="michelinOfferSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import ProductModalSelect from '../shared/ProductModalSelect.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTabs, BTab, BFormFile, BModal } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  components: { Multiselect, MdSpinner, GroupModalSelect, ProductModalSelect, RegionFlag, BTabs, BTab, BFormFile, BModal, DateRangePicker },
  props: ['action'],
  data: function() {
    return {
      michelinOffer: {
        name: null,
        active: true,
        allProducts: true,
        group: null,
        retailer: null,
        products: [],
        michelinWidgetCustomisations: [],
        dateRange: {
          startDate: moment.utc().startOf('day').add(1, 'days').toDate(),
          endDate: moment.utc().startOf('day').add(30, 'days').toDate()
        },
        imgUrl: null,
        imgFile: null,
        tooltip: null
      },
      michelinOfferLoading: false,
      michelinOfferError: null,
      groups: [],
      groupsLoading: false,
      groupsError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      michelinWidgetCustomisations: [],
      michelinWidgetCustomisationsLoading: false,
      michelinWidgetCustomisationsError: null,
      productFile: null,
      productFileProcessing: false,
      productReferenceType: null,
      productReferenceTypes: ['ean', 'cai', 'upc', 'mspn'],
      productsMap: {},
      referencesNotFound: [],
      michelinOfferSubmitLoading: false,
      michelinOfferSubmitErrors: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'auth/group',
      roles: 'auth/roles'
    }),
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    groupIsUserGroup: function() {
      return this.group.id === this.user.group.id
    },
    // Retailers for the selected group
    groupRetailers: function() {
      const michelinOfferGroup = this.groups.find(group => this.michelinOffer.group && group.id === this.michelinOffer.group.id)

      if (michelinOfferGroup && this.retailers.length > 0) {
        const groupRetailerExceptionIds = michelinOfferGroup.allRetailerExceptions.map(retailer => retailer.id)
        return this.retailers.filter(retailer => {
          return retailer.region.id === michelinOfferGroup.region.id &&
            !retailer.disabled &&
            !groupRetailerExceptionIds.includes(retailer.id)
        })
      } else {
        return []
      }
    },
    // Products length
    productsLength: function() {
      return Object.values(this.productsMap).length
    }
  },
  methods: {
    // Load data
    loadData: async function({ michelinOffer = true } = {}) {
      this.groupsLoading = true
      this.groupsError = null
      this.retailersLoading = true
      this.retailersError = null
      if (michelinOffer) {
        this.michelinOfferLoading = true
        this.michelinOfferError = null
      }

      const query = `query michelinOffersForm (
        $michelinOffer: Boolean = true,
        $michelinOfferId: Int = null,
        $groupId: Int,
        $services: [String!]
      ) {
        michelinOffer(id: $michelinOfferId) @include(if: $michelinOffer) {
          id
          name
          active
          allProducts
          startDate
          endDate
          group {
            id
            name
            region {
              id
              code
              languages {
                id
                code
              }
            }
            allRetailerExceptions {
              id
            }
          }
          retailer {
            ...retailerFields
          }
          products {
            id
            active
            reference
            ean
            upc
            active
            img
            hasImg
            productLanguageDatas {
              id
              name
              rawPackaging
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
          michelinWidgetCustomisations {
            id
            name
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
          imgUrl
          tooltip
        }
        groups(id: $groupId) {
          id
          name
          region {
            id
            code
            languages {
              id
              code
            }
          }
          allRetailerExceptions {
            id
          }
        }
        retailers(disabled: false, services: $services) {
          ...retailerFields
        }
      }

      fragment retailerFields on Retailer {
        id
        name
        service
        region {
          id
          code
        }
        imgSmallUrl
      }`

      const variables = {
        michelinOffer,
        michelinOfferId: michelinOffer ? parseInt(this.$route.params.id) : undefined,
        groupId: this.userIsAdmin ? 919 : this.user.group.id,
        services: ['drive', 'delivery']
      }

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
      const json = await res.json()

      this.groupsLoading = false
      this.retailersLoading = false
      if (michelinOffer) this.michelinOfferLoading = false

      this.groups = Object.freeze(json.data.groups)
      this.retailers = Object.freeze(json.data.retailers)
      if (michelinOffer) {
        this.michelinOffer = {
          ...json.data.michelinOffer,
          dateRange: {
            startDate: json.data.michelinOffer.startDate,
            endDate: json.data.michelinOffer.endDate
          }
        }
      }
    },
    // Load Michelin widget customisations
    loadMichelinWidgetCustomisations: async function() {
      this.michelinWidgetCustomisationsLoading = true
      this.michelinWidgetCustomisationsError = null

      const query = `query michelinOffersFormMichelinWidgetCustomisations ($groupId: Int!) {
        michelinWidgetCustomisations(groupId: $groupId) {
          id
          name
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
      }`

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: this.michelinOffer.group?.id
          }
        })
      })
      const json = await res.json()

      this.michelinWidgetCustomisationsLoading = false

      if (json.errors) {
        this.michelinWidgetCustomisationsError = { status: res.status, errors: json.errors }
      } else {
        this.michelinWidgetCustomisations = Object.freeze(json.data.michelinWidgetCustomisations)
      }
    },
    // Add product
    addProduct: function(product) {
      this.michelinOffer.products.push(product)
    },
    // Remove product
    removeProduct: function(product) {
      const index = this.michelinOffer.products.map(product_ => product_.id).indexOf(product.id)
      this.michelinOffer.products.splice(index, 1)
    },
    // Process product file
    processProductFile: async function() {
      this.productFileProcessing = true
      this.productsMap = {}
      this.referencesNotFound = []

      // Read file content
      const readFile = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const fileString = await readFile(this.productFile)
      const fileReferences = fileString.split('\n').filter(Boolean).map(reference => reference.trim())
      let referenceArgument

      // Prepare arguments for query
      if (this.productReferenceType === 'ean' || this.productReferenceType === 'cai') {
        referenceArgument = 'ean'
      } else if (this.productReferenceType === 'upc' || this.productReferenceType === 'mspn') {
        referenceArgument = 'upc'
      }

      // Build references to use
      let referencesMap
      if (this.productReferenceType === 'cai') {
        referencesMap = new Map(fileReferences.map(reference => [reference, this.caiToEan(reference)]))
      } else if (this.productReferenceType === 'mspn') {
        referencesMap = new Map(fileReferences.map(reference => [reference, this.mspnToUpc(reference)]))
      } else {
        referencesMap = new Map(fileReferences.map(reference => [reference, reference]))
      }

      const references = Array.from(referencesMap.values())

      // Fetch products
      const query = `query michelinOfferProductImportSearch ($groupId: Int!, $references: [String!]) {
        products(groupId: $groupId, ${referenceArgument}s: $references) {
          id
          ean
          upc
          imgUrl
          productLanguageDatas {
            id
            name
            rawPackaging
          }
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
      }`

      const variables = {
        groupId: this.michelinOffer.group?.id,
        references
      }

      const res = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
      const json = await res.json()

      // Convert products array to map ean/upc => product
      const resultProductsMap = new Map()
      json.data.products.forEach(product => {
        resultProductsMap.set(product[referenceArgument], product)
      })

      // Update products map
      fileReferences.forEach(fileReference => {
        const reference = referencesMap.get(fileReference)
        const product = resultProductsMap.get(reference)
        if (product) {
          this.$set(this.productsMap, fileReference, product)
        } else {
          this.referencesNotFound.push(fileReference)
        }
      })

      this.productFileProcessing = false
    },
    // Modal ok click
    modalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.modalSubmit()
    },
    // Modal form submit
    modalSubmit() {
      const products = Object.values(this.productsMap).filter(Boolean)

      // Add product to michelin offer products if not already in
      products.forEach(product => {
        if (!this.michelinOffer.products.find(product_ => product_.id === product.id)) {
          this.michelinOffer.products.push(product)
        }
      })

      this.$nextTick(() => {
        this.$refs.productModal.hide()
      })
    },
    // Form submit
    submit: async function() {
      this.michelinOfferSubmitLoading = true
      this.michelinOfferSubmitErrors = null

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            name: this.michelinOffer.name,
            groupId: this.michelinOffer.group?.id,
            retailerId: this.michelinOffer.retailer?.id,
            productIds: this.michelinOffer.products.map(product => product.id),
            michelinWidgetCustomisationIds: this.michelinOffer.michelinWidgetCustomisations.map(michelinWidgetCustomisation => michelinWidgetCustomisation.id),
            active: this.michelinOffer.active,
            allProducts: this.michelinOffer.allProducts,
            startDate: this.michelinOffer.dateRange.startDate,
            endDate: this.michelinOffer.dateRange.endDate,
            img: this.michelinOffer.imgFile ? await toBase64(this.michelinOffer.imgFile) : undefined,
            tooltip: this.michelinOffer.tooltip
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateMichelinOfferInput!) {
          createMichelinOffer(input: $input) {
            michelinOffer {
              id
            }
            errors
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
        const json = await res.json()

        this.michelinOfferSubmitLoading = false

        if (json.data.createMichelinOffer.errors) {
          this.michelinOfferSubmitErrors = json.data.createMichelinOffer.errors
        } else {
          this.redirectToIndex()
          this.$root.$bvToast.toast(this.$t('shared.success.michelinOffer.create'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        }
      } else if (this.action === 'edit') {
        variables.input.michelinOfferId = this.$route.params.id

        const mutation = `mutation($input: UpdateMichelinOfferInput!) {
          updateMichelinOffer(input: $input) {
            michelinOffer {
              id
            }
            errors
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
        const json = await res.json()

        this.michelinOfferSubmitLoading = false

        if (json.data.updateMichelinOffer.errors) {
          this.michelinOfferSubmitErrors = json.data.updateMichelinOffer.errors
        } else {
          this.redirectToIndex()
          this.$root.$bvToast.toast(this.$t('shared.success.michelinOffer.update'), {
            variant: 'success',
            noCloseButton: true,
            autoHideDelay: 3000
          })
        }
      }
    },
    redirectToIndex: function() {
      if (this.userIsAdmin && this.groupIsUserGroup) {
        this.$router.push({ name: 'michelinOffers' })
      } else {
        this.$router.push({ name: 'groupMichelinOffers', params: { groupId: this.group.id } })
      }
    },
    // Convert CAI to EAN
    caiToEan: function(cai) {
      // Add zeroes to have a 6 digits CAI
      let ean = `000000${cai}`.slice(-6)

      // Convert 6 digits CAI into EAN
      ean = `352870${ean}`
      ean += this.ean13Checksum(ean)
      return `0${ean}`
    },
    // Calculate EAN13 checksum
    ean13Checksum(ean12) {
      const chunks = ean12.split('').map(n => parseInt(n, 10)).reverse()
      let checksum = 0

      chunks.forEach((number, index) => {
        checksum += ((index % 2) === 1) ? number : number * 3
      })

      checksum %= 10
      checksum = (checksum === 0) ? 0 : (10 - checksum)

      return checksum
    },
    // Convert MSPN to UPC
    mspnToUpc: function(mspn) {
      // Add zeroes to have a 5 digits MSPN
      let upc = `00000${mspn}`.slice(-5)

      // Convert 5 digits MSPN into UPC
      upc = `086699${mspn}`
      upc += this.upcCheckDigit(upc)
      return upc
    },
    // Calculate UPC checksum
    upcCheckDigit(s) {
      let result = 0
      let i = 1
      for (let counter = s.length - 1; counter >= 0; counter--) {
        result = result + parseInt(s.charAt(counter)) * (1 + (2 * (i % 2)))
        i++
      }
      return (10 - (result % 10)) % 10
    }
  },
  filters: {
    percentage: function(percentage) {
      return percentage.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })
    }
  },
  watch: {
    // Load group customisations when group changes
    'michelinOffer.group': function(group) {
      if (group) {
        this.loadMichelinWidgetCustomisations()
      } else {
        this.michelinWidgetCustomisations = []
      }
    },
    // Update michelin offer imgUrl when a file is selected
    'michelinOffer.imgFile': function(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.michelinOffer.imgUrl = e.target.result
        }
        reader.readAsDataURL(file)
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    } else {
      this.loadData({ michelinOffer: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-valign-middle {
  td {
    vertical-align: middle;
  }
}
</style>
