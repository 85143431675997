<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.productLanguageData.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.productLanguageData.name')">
          </div>

          <div>
            <label for="filter-raw_packaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</label>
            <input
              v-model="filters.raw_packaging"
              type="text"
              class="form-control"
              id="filter-raw_packaging"
              :placeholder="$t('attributes.productLanguageData.rawPackaging')">
          </div>

          <div>
            <label for="filter-reference">{{ $t('attributes.product.reference') }}</label>
            <input
              v-model="filters.reference"
              type="text"
              class="form-control"
              id="filter-reference"
              :placeholder="$t('attributes.product.reference')">
          </div>

          <div>
            <label for="filter-ean">{{ $t('attributes.product.ean') }}</label>
            <input
              v-model="filters.ean"
              type="text"
              class="form-control"
              id="filter-ean"
              :placeholder="$t('attributes.product.ean')">
          </div>

          <div>
            <label for="filter-upc">{{ $t('attributes.product.upc') }}</label>
            <input
              v-model="filters.upc"
              type="text"
              class="form-control"
              id="filter-upc"
              :placeholder="$t('attributes.product.upc')">
          </div>

          <div>
            <label for="filter-active">{{ $t('attributes.product.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">Inactive</option>
              <option :value="true">Active</option>
            </select>
          </div>

          <div>
            <label for="filter-has-img">{{ $t('attributes.product.img') }}</label>
            <select v-model="filters.hasImg" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">{{ $t('views.products.index.withoutImage') }}</option>
              <option :value="true">{{ $t('views.products.index.withImage') }}</option>
            </select>
          </div>

          <div>
            <label for="filter-region">{{ $t('attributes.product.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </key-multiselect>
          </div>

          <div>
            <label for="filter-group">{{ $t('attributes.product.group') }}</label>
            <group-modal-select v-model="filters.groups" multiple />
          </div>

          <div v-if="userIsAdmin">
            <label for="filter-pim-data-source">{{ $t('attributes.product.pimDataSource') }}</label>
            <key-multiselect
              v-model="filters.pimDataSourceIds"
              :multiple="true"
              :close-on-select="false"
              :options="pimDataSources"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="pimDataSourcesLoading" />
          </div>

          <div>
            <label for="daterange-picker">{{ $t('attributes.product.creationDateRange') }}</label>
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <div class="custom-control custom-checkbox" style="margin-right: -0.5rem;">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="filters.createdAtFilter">
                      <label class="custom-control-label" for="customCheck1"></label>
                    </div>
                  </div>
                </div>
                <date-range-picker :date-range="createdAtDateRangerPickerValue" @update="inputCreatedAtDateRangerPickerValue">
                  <button type="button" slot="input" slot-scope="picker" class="form-control text-nowrap" :disabled="!filters.createdAtFilter">
                    {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
                  </button>
                </date-range-picker>
              </div>
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div></div>
      <div class="gutter-x-xs">
        <!-- Display mode button -->
        <button
          type="button"
          class="btn btn-primary"
          @click="displayMode = (displayMode === 'list' ? 'grid' : 'list')">
          <template v-if="displayMode === 'list'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="th" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-th fa-w-16"><path fill="currentColor" d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z" class=""></path></svg>
            {{ $t('views.products.index.grid')}}
          </template>
          <template v-else>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="th-list" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-th-list fa-w-16"><path fill="currentColor" d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z" class=""></path></svg>
            {{ $t('views.products.index.list')}}
          </template>
        </button>
        <!-- Export button -->
        <button type="button" class="btn btn-secondary" :disabled="!hasFilters" v-b-modal.export-modal>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
        <!-- New button -->
        <router-link v-if="userIsAdmin" :to="{ name: 'newProduct' }" class="btn btn-success">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newProduct') }}
        </router-link>
      </div>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="productsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <!-- Products list -->
      <template v-if="displayMode === 'list'">
        <div class="table-responsive">
          <table class="table" :aria-busy="productsLoading">
            <thead>
              <tr>
                <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.product.id') }}</th-sortable>
                <th-sortable sort-key="created_at">{{ $t('attributes.product.createdAt') }}</th-sortable>
                <th class="table-col-shrink"></th>
                <th class="table-col-shrink">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                </th>
                <th-sortable sort-key="name">{{ $t('attributes.productLanguageData.name') }}</th-sortable>
                <th-sortable sort-key="raw_packaging" class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th-sortable>
                <th-sortable sort-key="reference">{{ $t('attributes.product.reference') }}</th-sortable>
                <th-sortable sort-key="ean">{{ $t('attributes.product.ean') }}</th-sortable>
                <th-sortable sort-key="upc">{{ $t('attributes.product.upc') }}</th-sortable>
                <th class="ellipsis" style="max-width: 60px;" v-if="userIsAdmin">{{ $t('attributes.product.declensions') }}</th>
                <th class="table-col-shrink">{{ $t('attributes.product.widget') }}</th>
                <th-sortable sort-key="group_id" class="table-col-shrink">{{ $t('attributes.product.group') }}</th-sortable>
                <th class="table-col-shrink" v-if="userIsAdmin"></th>
              </tr>
            </thead>
            <transition name="fade" mode="out-in">
              <tbody v-if="productsError">
                <tr>
                  <td :colspan="colspan" class="text-center alert-danger">
                    <template v-if="productsError.status === 403">
                      {{ $t('errors.unauthorized.manage.all') }}
                    </template>
                    <template v-else>
                      {{ $t('errors.internalServerError') }}
                    </template>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="!productsLoading && products.length === 0">
                <tr>
                  <td :colspan="colspan" class="text-center alert-warning">
                    {{ $t('shared.warnings.noProduct') }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="product in products" :key="product.id">
                  <th>{{ product.id }}</th>
                  <td class="text-center">{{ product.createdAt | showFormattedDate }}</td>
                  <td class="text-center">
                    <dot :active="product.active" />
                  </td>
                  <td :id="`product-${product.id}-img`">
                    <span v-if="product.hasImg">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                      <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                        <img :src="product.img.medium.url" style="height: 200px;" />
                      </b-tooltip>
                    </span>
                    <span v-else class="text-danger">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                    </span>
                  </td>
                  <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                    <img :src="product.img.medium.url" style="height: 200px;" />
                  </b-tooltip>
                  <td class="ellipsis" style="max-width: 200px;">
                    {{ product.productLanguageDatas[0].name }}
                  </td>
                  <td class="ellipsis" style="max-width: 120px;">
                    <small>{{ product.productLanguageDatas[0].rawPackaging }}</small>
                  </td>
                  <td><small>{{ product.reference }}</small></td>
                  <td><small>{{ product.ean }}</small></td>
                  <td><small>{{ product.upc }}</small></td>
                  <td v-if="userIsAdmin" class="text-center">
                    {{ product.declensionsCount }}
                  </td>
                  <td class="text-nowrap">
                    <template v-if="product.widget">
                      <router-link v-if="userIsAdmin" :to="{ name: 'widget', params: { id: product.widget.id } }" class="small">
                        {{ product.widget.key }}
                      </router-link>
                      <code v-else class="small">
                        {{ product.widget.key}}
                      </code>
                    </template>
                  </td>
                  <td class="ellipsis" style="max-width: 150px;">
                    <template v-if="userIsAdmin">
                      <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                        <region-flag :code="product.group.region.code" />
                        {{ product.group.name }}
                      </router-link>
                    </template>
                    <template v-else>
                      <region-flag :code="product.group.region.code" />
                      {{ product.group.name }}
                    </template>
                  </td>
                  <td v-if="userIsAdmin">
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                      </template>
                      <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </transition>
          </table>
        </div>
      </template>

      <!-- Products grid -->
      <template v-else-if="displayMode === 'grid'">
        <div v-if="productsError" class="alert alert-danger">
          <template v-if="productsError.status === 403">
            {{ $t('errors.unauthorized.manage.all') }}
          </template>
          <template v-else>
            {{ $t('errors.internalServerError') }}
          </template>
        </div>
        <div v-else-if="!productsLoading && products.length === 0" class="alert alert-warning">
          {{ $t('shared.warnings.noProduct') }}
        </div>
        <div
          v-else
          class="custom-grid mb-3"
          :aria-busy="productsLoading">
          <div
            v-for="product in products"
            :key="product.id"
            class="card">
            <div class="card-body">
              <img :src="product.img.medium.url" class="w-100 mb-3">
              <div class="card-text">
                <template v-if="userIsAdmin">
                  <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                    <region-flag :code="product.group.region.code" />
                    {{ product.group.name }}
                  </router-link>
                </template>
                <template v-else>
                  <region-flag :code="product.group.region.code" />
                  {{ product.group.name }}
                </template>
              </div>
              <div>{{ product.productLanguageDatas[0].name }}</div>
              <div class="small">{{ product.productLanguageDatas[0].rawPackaging }}</div>
              <div class="small">{{ product.reference }}</div>
              <div class="small">{{ product.ean }}</div>
              <div class="small">{{ product.upc }}</div>
              <div v-if="userIsAdmin" style="position: absolute; top: 0; right: 0;">
                <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                  <template v-slot:button-content>
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                  </template>
                  <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                    {{ $t('shared.actions.show') }}
                  </router-link>
                  <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                    {{ $t('shared.actions.edit') }}
                  </router-link>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />

    <!-- Export modal -->
    <b-modal
      id="export-modal"
      size="sm"
      title="Select attributes..."
      scrollable
      no-fade>
      <div class="form-group text-center">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary" @click="selectAllExportAttributes">Select all</button>
          <button type="button" class="btn btn-secondary" @click="unselectAllExportAttributes">Unselect all</button>
        </div>
      </div>
      <template v-for="(attributeValue, attribute) in selectedExportAttributes">
        <div :key="attribute" class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              v-model="selectedExportAttributes[attribute]"
              type="checkbox" class="custom-control-input" :id="`export-attribute-${attribute}`">
            <label class="custom-control-label" :for="`export-attribute-${attribute}`">
              {{ attribute }}
            </label>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer="{ close }">
        <a :href="exportUrl" class="btn btn-primary" @click="close()">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </a>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import Pagination from '../shared/Pagination.vue'
import { BTooltip, BDropdown, BModal, VBModal } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'

export default {
  components: { MdSpinner, KeyMultiselect, GroupModalSelect, Pagination, BTooltip, BDropdown, BModal, Dot, RegionFlag, ThSortable, DateRangePicker },
  directives: { 'b-modal': VBModal },
  data: function() {
    return {
      products: [],
      productsLoading: false,
      productsError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      pimDataSources: [],
      pimDataSourcesLoading: false,
      pimDataSourcesError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        raw_packaging: null,
        reference: null,
        ean: null,
        upc: null,
        active: null,
        hasImg: null,
        regionIds: [],
        groups: [],
        pimDataSourceIds: [],
        createdAtFilter: false,
        createdAt: {
          startDate: moment('2014-01-01'),
          endDate: moment.utc().startOf('day')
        }
      },
      displayMode: 'list',
      selectedExportAttributes: {}
    }
  },
  filters: {
    showFormattedDate: function(date) {
      return moment.utc(date).format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    // Pagination
    currentPage: function() {
      return parseInt(this.$route.query.page) || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Table colspan
    colspan: function() {
      return 12
    },
    // Check if there is at least a filter
    hasFilters: function() {
      return !!((
        this.$route.params.groupId ||
        this.$route.query.name ||
        this.$route.query.raw_packaging ||
        this.$route.query.reference ||
        this.$route.query.ean ||
        this.$route.query.upc ||
        this.$route.query.active ||
        this.$route.query.hasImg ||
        this.$route.query.regionIds ||
        this.$route.query.groupIds ||
        this.$route.query.pimDataSourceIds ||
        this.$route.query.createdAtStartDate ||
        this.$route.query.createdAtEndDate
      ))
    },
    // Date range picker value
    createdAtDateRangerPickerValue: function() {
      return {
        startDate: this.filters.createdAt.startDate.toDate(),
        endDate: this.filters.createdAt.endDate.toDate()
      }
    },
    // Get CSV export url
    exportUrl: function() {
      const url = new URL('/api/interface/products/export', window.location.origin)

      // Route groupId
      if (this.$route.params.groupId) url.searchParams.set('group_id', this.$route.params.groupId)

      // Filters
      if (this.$route.query.name) url.searchParams.set('name', this.$route.query.name)
      if (this.$route.query.raw_packaging) url.searchParams.set('raw_packaging', this.$route.query.raw_packaging)
      if (this.$route.query.reference) url.searchParams.set('reference', this.$route.query.reference)
      if (this.$route.query.ean) url.searchParams.set('ean', this.$route.query.ean)
      if (this.$route.query.upc) url.searchParams.set('upc', this.$route.query.upc)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)
      if (this.$route.query.hasImg) url.searchParams.set('has_img', this.$route.query.hasImg)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)
      if (this.$route.query.groupIds) url.searchParams.set('group_ids', this.$route.query.groupIds)
      if (this.$route.query.pimDataSourceIds) url.searchParams.set('pim_data_source_ids', this.$route.query.pimDataSourceIds)
      if (this.$route.query.createdAtFilter === 'true' && this.$route.query.createdAtStartDate && this.$route.query.createdAtEndDate) {
        url.searchParams.set('created_at_start_date', moment.utc(this.$route.query.createdAtStartDate))
        url.searchParams.set('created_at_end_date', moment.utc(this.$route.query.createdAtEndDate).set({ hour: 23, minute: 59, second: 59 }))
      }

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      // Attributes
      const attributes = Object.entries(this.selectedExportAttributes).filter(entry => entry[1] === true).map(entry => entry[0])
      url.searchParams.set('export_attributes', attributes)

      return url.href
    }
  },
  methods: {
    // Created at date range picker update
    inputCreatedAtDateRangerPickerValue: function(dateRange) {
      this.filters.createdAt.startDate = moment.utc(dateRange.startDate)
      this.filters.createdAt.endDate = moment.utc(dateRange.endDate)
    },
    // Load data
    loadData: function({ regions = true } = {}) {
      this.productsLoading = true
      this.productsError = null
      if (regions) {
        this.regionsLoading = true
        this.regionsError = null
      }

      const query = `query productsIndex ($page: Int!, $perPage: Int!, $name: String, $rawPackaging: String, $reference: String, $ean: String, $upc: String, $active: Boolean, $hasImg: Boolean, $groupId: Int, $groupIds: [Int!], $regionIds: [Int!], $pimDataSourceIds: [Int!], $sort: String, $sortDirection: String, $regions: Boolean = true, $createdAtStartDate: ISO8601DateTime, $createdAtEndDate: ISO8601DateTime) {
        productsPage(page: $page, perPage: $perPage, name: $name, rawPackaging: $rawPackaging, reference: $reference, ean: $ean, upc: $upc, active: $active, hasImg: $hasImg, groupId: $groupId, groupIds: $groupIds, regionIds: $regionIds, pimDataSourceIds: $pimDataSourceIds, sort: $sort, sortDirection: $sortDirection, createdAtStartDate: $createdAtStartDate, createdAtEndDate: $createdAtEndDate) {
          nodesCount
          nodes {
            id
            active
            reference
            ean
            upc
            active
            img
            hasImg
            createdAt
            productLanguageDatas {
              id
              name
              rawPackaging
            }
            declensionsCount
            widget {
              id
              key
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
        regions @include(if: $regions) {
          id
          code
        }
      }`

      const variables = {
        page: this.currentPage,
        perPage: this.perPage,
        name: this.filters.name,
        rawPackaging: this.filters.raw_packaging,
        reference: this.filters.reference,
        ean: this.filters.ean,
        upc: this.filters.upc,
        active: this.filters.active,
        hasImg: this.filters.hasImg,
        groupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : undefined,
        groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
        regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
        pimDataSourceIds: this.filters.pimDataSourceIds.length > 0 ? this.filters.pimDataSourceIds : undefined,
        sort: this.$route.query.sort,
        sortDirection: this.$route.query.sort_direction,
        regions
      }

      if (this.filters.createdAtFilter) {
        variables.createdAtStartDate = this.filters.createdAt.startDate
        variables.createdAtEndDate = this.filters.createdAt.endDate.set({ hour: 23, minute: 59, second: 59 })
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.productsLoading = false
          if (regions) this.regionsLoading = false

          if (data.errors) {
            this.productsError = { status: res.status, errors: data.errors }
            if (regions) this.regionsError = { status: res.status, errors: data.errors }
          } else {
            this.totalRows = data.data.productsPage.nodesCount
            this.products = Object.freeze(data.data.productsPage.nodes)
            if (regions) this.regions = Object.freeze(data.data.regions)
          }
        })
    },
    // Load pim data sources
    loadPimDataSources: function() {
      this.pimDataSourcesLoading = true
      this.pimDataSourcesError = null

      const query = `query productsIndexPimDataSources {
        pimDataSources {
          id
          name
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query })
      })
        .then(res => res.json())
        .then(res => {
          this.pimDataSourcesLoading = false

          if (res.errors) {
            this.pimDataSourcesError = { errors: res.errors }
          } else {
            this.pimDataSources = Object.freeze(res.data.pimDataSources)
          }
        })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.raw_packaging) query.raw_packaging = this.filters.raw_packaging
      if (this.filters.reference) query.reference = this.filters.reference
      if (this.filters.ean) query.ean = this.filters.ean
      if (this.filters.upc) query.upc = this.filters.upc
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.hasImg !== null) query.hasImg = this.filters.hasImg.toString()
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.groups.length > 0) query.groupIds = this.filters.groups.map(group => group.id)
      if (this.filters.pimDataSourceIds.length > 0) query.pimDataSourceIds = this.filters.pimDataSourceIds

      if (this.filters.createdAtFilter) {
        query.createdAtFilter = this.filters.createdAtFilter
        if (this.filters.createdAt.startDate) query.createdAtStartDate = this.filters.createdAt.startDate.format('YYYY-MM-DD')
        if (this.filters.createdAt.endDate) query.createdAtEndDate = this.filters.createdAt.endDate.format('YYYY-MM-DD')
      }

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: async function() {
      this.filters.name = this.$route.query.name
      this.filters.raw_packaging = this.$route.query.raw_packaging
      this.filters.reference = this.$route.query.reference
      this.filters.ean = this.$route.query.ean
      this.filters.upc = this.$route.query.upc
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null
      this.filters.hasImg = this.$route.query.hasImg ? this.$route.query.hasImg === 'true' : null
      this.filters.createdAtFilter = this.$route.query.createdAtFilter === 'true'

      if (this.filters.createdAtFilter) {
        this.filters.createdAt.startDate = moment.utc(this.$route.query.createdAtStartDate)
        this.filters.createdAt.endDate = moment.utc(this.$route.query.createdAtEndDate)
      }

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.groupIds) {
        let groupIds
        if (this.$route.query.groupIds instanceof Array) {
          groupIds = this.$route.query.groupIds.map(groupId => parseInt(groupId))
        } else {
          groupIds = [parseInt(this.$route.query.groupIds)]
        }

        // Load groups from url groupIds
        const query = `query groupsFilter ($groupIds: [Int!]) {
          groups(ids: $groupIds) {
            id
            name
            active
            region {
              id
              code
            }
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables: { groupIds } })
        })
        const json = await res.json()
        this.filters.groups = json.data.groups
      } else {
        this.filters.groups = []
      }

      if (this.$route.query.pimDataSourceIds) {
        if (this.$route.query.pimDataSourceIds instanceof Array) {
          this.filters.pimDataSourceIds = this.$route.query.pimDataSourceIds.map(groupId => parseInt(groupId))
        } else {
          this.filters.pimDataSourceIds = [parseInt(this.$route.query.pimDataSourceIds)]
        }
      } else {
        this.filters.pimDataSourceIds = []
      }
    },
    // Initialize selectedExportAttributes based on user roles
    initExportAttributes: function() {
      // Available export attributes
      let exportAttributes
      if (this.userIsAdmin) {
        exportAttributes = ['id', 'name', 'raw_packaging', 'reference', 'ean', 'upc', 'active', 'group_id', 'group_name', 'group_region_code', 'img_url', 'raw_product', 'repository_product_id', 'widget_key', 'created_at', 'updated_at', 'disabled_at']
      } else {
        exportAttributes = ['name', 'raw_packaging', 'reference', 'ean', 'group_name', 'group_region_code', 'widget_key']
      }

      // Default export attributes selected
      const selectedExportAttributes = {}
      exportAttributes.forEach(attribute => {
        selectedExportAttributes[attribute] = ['id', 'name', 'raw_packaging', 'ean', 'group_id', 'group_name', 'group_region_code', 'widget_key'].includes(attribute)
      })

      this.selectedExportAttributes = selectedExportAttributes
    },
    // Select all export attributes
    selectAllExportAttributes: function() {
      Object.keys(this.selectedExportAttributes).forEach(attribute => {
        this.selectedExportAttributes[attribute] = true
      })
    },
    // Unselect all export attributes
    unselectAllExportAttributes: function() {
      Object.keys(this.selectedExportAttributes).forEach(attribute => {
        this.selectedExportAttributes[attribute] = false
      })
    }
  },
  created: async function() {
    await this.updateFiltersFromQuery()
    this.loadData()
    if (this.userIsAdmin) this.loadPimDataSources()
    this.initExportAttributes()
  },
  watch: {
    // Update data when the route has changed
    $route: async function(to, from) {
      await this.updateFiltersFromQuery()
      await this.loadData({ regions: false })
    },
    // Update export attributes when roles are updated
    userIsAdmin: function() {
      this.initExportAttributes()
    }
  }
}
</script>
