
<div>
  <template v-if="userIsAdmin">
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.group.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.group.name')">
          </div>

          <div>
            <label for="filter-active">{{ $t('attributes.group.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">Inactive</option>
              <option :value="true">Active</option>
            </select>
          </div>

          <div>
            <label for="filter-region">{{ $t('attributes.group.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </key-multiselect>
          </div>

          <div>
            <label for="filter-group">{{ $t('attributes.groupApiKey.group') }}</label>
            <group-modal-select v-model="filters.groups" multiple />
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div></div>
      <div>
        <!-- New button -->
        <router-link :to="{ name: 'newGroupApiKey' }" class="btn btn-success">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newGroupApiKey') }}
        </router-link>
      </div>
    </div>
  </template>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="groupApiKeysLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table" :aria-busy="groupApiKeysLoading">
        <thead>
          <tr>
            <th-sortable v-if="userIsAdmin" sort-key="id" class="table-col-shrink">{{ $t('attributes.groupApiKey.id') }}</th-sortable>
            <th class="table-col-shrink"></th>
            <th-sortable sort-key="name">{{ $t('attributes.groupApiKey.name') }}</th-sortable>
            <th-sortable sort-key="key" colspan="2">{{ $t('attributes.groupApiKey.key') }}</th-sortable>
            <th>
              <div>{{ $t('attributes.groupApiKey.usage') }}</div>
              <div>{{ $t('attributes.groupApiKey.usageLast30Days') }}</div>
            </th>
            <th-sortable sort-key="group_id" class="th-shrink">{{ $t('attributes.groupApiKey.group') }}</th-sortable>
            <th-sortable sort-key="api_endpoint_id">{{ $t('attributes.groupApiKey.apiEndpoint') }}</th-sortable>
            <th class="table-col-shrink"></th>
            <th v-if="userIsAdmin" class="table-col-shrink"></th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tbody v-if="groupApiKeysError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="groupApiKeysError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-if="!groupApiKeysLoading && groupApiKeys.length === 0">
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noGroupApiKey') }}
              </td>
            </tr>
            <tr v-else v-for="groupApiKey in groupApiKeys" :key="groupApiKey.id">
              <th v-if="userIsAdmin">{{ groupApiKey.id }}</th>
              <td class="text-center">
                <dot :active="groupApiKey.active" />
              </td>
              <td>{{ groupApiKey.name }}</td>
              <td class="text-nowrap">
                <code>
                  {{ groupApiKey.key }}
                </code>
              </td>
              <td class="td-shrink">
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  :id="`copy-key-btn-${groupApiKey.id}`"
                  v-clipboard:copy="groupApiKey.key"
                  v-clipboard:success="onCopy"
                  v-b-tooltip.manual="$t('shared.actions.copied')">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
                </button>
              </td>
              <td>
                {{ groupApiKey.last30DaysUsageCount }}
              </td>
              <td class="text-nowrap">
                <router-link :to="{ name: 'group', params: { id: groupApiKey.group.id } }">
                  <region-flag :code="groupApiKey.group.region.code" />
                  {{ groupApiKey.group.name }}
                </router-link>
              </td>
              <td>{{ groupApiKey.apiEndpoint.name }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary btn-sm text-nowrap"
                  v-b-modal.apiDocumentationModal
                  @click="modalGroupApiKey = groupApiKey">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-book fa-w-14"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" class=""></path></svg>
                  {{ $t('attributes.groupApiKey.apiEndpointDocumentation') }}
                </button>
              </td>
              <td v-if="userIsAdmin">
                <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                  <template v-slot:button-content>
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                  </template>
                  <router-link :to="{ name: 'groupApiKey', params: { id: groupApiKey.id } }" class="dropdown-item">
                    {{ $t('shared.actions.show') }}
                  </router-link>
                  <router-link :to="{ name: 'editGroupApiKey', params: { id: groupApiKey.id } }" class="dropdown-item">
                    {{ $t('shared.actions.edit') }}
                  </router-link>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
    </div>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />

  <!-- API documentation modal -->
  <b-modal
    v-if="modalGroupApiKey"
    id="apiDocumentationModal"
    ref="apiDocumentationModal"
    :title="`${$t('attributes.groupApiKey.apiEndpointDocumentation')} - ${modalGroupApiKey.apiEndpoint.name}`"
    size="lg"
    hide-footer
    no-fade>
    <p>
      Our APIs use GraphQL. New to GraphQL? Check out GraphQL's <a href="https://www.graphql.org/" target="new">official documentation</a>!
    </p>
    <div>The endpoint of this API is:</div>
    <p>
      <a class="disabled">{{ apiEndpointUrl }}</a>
      <button
        type="button"
        class="btn btn-light btn-sm"
        :id="`copy-graphql-endpoint-url-btn-${modalGroupApiKey.id}`"
        v-clipboard:copy="apiEndpointUrl"
        v-clipboard:success="onCopy"
        v-b-tooltip.manual="$t('shared.actions.copied')">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
      </button>
    </p>
    <div>Use a HTTP Bearer Token header to authorize your requests:</div>
    <p>
      <code>Authorization: Token token={{ modalGroupApiKey.key }}</code>
    </p>
    <p>We also provide a GraphiQL interface to explore the schema and build queries:</p>
    <div class="text-center">
      <a :href="modalGroupApiKeyDocumentationUrl" target="_blank" class="btn btn-primary btn-lg">
        GraphiQL
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-external-link-alt fa-w-16"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" class=""></path></svg>
      </a>
    </div>

    <h6>
      Query example
      <button
        type="button"
        class="btn btn-light btn-sm"
        :id="`copy-query-example-btn-${modalGroupApiKey.id}`"
        v-clipboard:copy="modalGroupApiKey.apiEndpoint.queryExample"
        v-clipboard:success="onCopy"
        v-b-tooltip.manual="$t('shared.actions.copied')">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
      </button>
    </h6>
    <codemirror
      :value="modalGroupApiKey.apiEndpoint.queryExample"
      class="codemirror-readonly"
      :options="{ mode: 'graphql', lineNumbers: true, tabSize: 2, autoRefresh: true, readOnly: true }" />
  </b-modal>
</div>
