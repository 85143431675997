
<div>
  <!-- Filters -->
  <div class="mb-4">
    <form @submit.prevent="updateFilters">
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.productLanguageData.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            :placeholder="$t('attributes.productLanguageData.name')">
        </div>

        <div>
          <label for="filter-raw_packaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</label>
          <input
            v-model="filters.raw_packaging"
            type="text"
            class="form-control"
            id="filter-raw_packaging"
            :placeholder="$t('attributes.productLanguageData.rawPackaging')">
        </div>

        <div>
          <label for="filter-reference">{{ $t('attributes.product.reference') }}</label>
          <input
            v-model="filters.reference"
            type="text"
            class="form-control"
            id="filter-reference"
            :placeholder="$t('attributes.product.reference')">
        </div>

        <div>
          <label for="filter-ean">{{ $t('attributes.product.ean') }}</label>
          <input
            v-model="filters.ean"
            type="text"
            class="form-control"
            id="filter-ean"
            :placeholder="$t('attributes.product.ean')">
        </div>

        <div>
          <label for="filter-upc">{{ $t('attributes.product.upc') }}</label>
          <input
            v-model="filters.upc"
            type="text"
            class="form-control"
            id="filter-upc"
            :placeholder="$t('attributes.product.upc')">
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.product.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-has-img">{{ $t('attributes.product.img') }}</label>
          <select v-model="filters.hasImg" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">{{ $t('views.products.index.withoutImage') }}</option>
            <option :value="true">{{ $t('views.products.index.withImage') }}</option>
          </select>
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.product.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <div class="btn-group" style="margin: 2.5px 5px;">
                <div class="btn btn-light btn-xs text-left">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                </div>
                <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                </div>
              </div>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.product.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>

        <div v-if="userIsAdmin">
          <label for="filter-pim-data-source">{{ $t('attributes.product.pimDataSource') }}</label>
          <key-multiselect
            v-model="filters.pimDataSourceIds"
            :multiple="true"
            :close-on-select="false"
            :options="pimDataSources"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="pimDataSourcesLoading" />
        </div>

        <div>
          <label for="daterange-picker">{{ $t('attributes.product.creationDateRange') }}</label>
            <div class="input-group flex-nowrap">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <div class="custom-control custom-checkbox" style="margin-right: -0.5rem;">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="filters.createdAtFilter">
                    <label class="custom-control-label" for="customCheck1"></label>
                  </div>
                </div>
              </div>
              <date-range-picker :date-range="createdAtDateRangerPickerValue" @update="inputCreatedAtDateRangerPickerValue">
                <button type="button" slot="input" slot-scope="picker" class="form-control text-nowrap" :disabled="!filters.createdAtFilter">
                  {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
                </button>
              </date-range-picker>
            </div>
        </div>
      </div>

      <div class="gutter-x-xs">
        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </div>
    </form>
  </div>

  <!-- Global buttons -->
  <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
    <div></div>
    <div class="gutter-x-xs">
      <!-- Display mode button -->
      <button
        type="button"
        class="btn btn-primary"
        @click="displayMode = (displayMode === 'list' ? 'grid' : 'list')">
        <template v-if="displayMode === 'list'">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="th" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-th fa-w-16"><path fill="currentColor" d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z" class=""></path></svg>
          {{ $t('views.products.index.grid')}}
        </template>
        <template v-else>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="th-list" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-th-list fa-w-16"><path fill="currentColor" d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z" class=""></path></svg>
          {{ $t('views.products.index.list')}}
        </template>
      </button>
      <!-- Export button -->
      <button type="button" class="btn btn-secondary" :disabled="!hasFilters" v-b-modal.export-modal>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
      <!-- New button -->
      <router-link v-if="userIsAdmin" :to="{ name: 'newProduct' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newProduct') }}
      </router-link>
    </div>
  </div>

  <div class="position-relative">
    <!-- Table spinner -->
    <div v-if="productsLoading" style="position: absolute; width: 100%; height: 100%;">
      <div style="position: sticky; top: 0; text-align: center;">
        <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
      </div>
    </div>

    <!-- Products list -->
    <template v-if="displayMode === 'list'">
      <div class="table-responsive">
        <table class="table" :aria-busy="productsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.product.id') }}</th-sortable>
              <th-sortable sort-key="created_at">{{ $t('attributes.product.createdAt') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
              </th>
              <th-sortable sort-key="name">{{ $t('attributes.productLanguageData.name') }}</th-sortable>
              <th-sortable sort-key="raw_packaging" class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th-sortable>
              <th-sortable sort-key="reference">{{ $t('attributes.product.reference') }}</th-sortable>
              <th-sortable sort-key="ean">{{ $t('attributes.product.ean') }}</th-sortable>
              <th-sortable sort-key="upc">{{ $t('attributes.product.upc') }}</th-sortable>
              <th class="ellipsis" style="max-width: 60px;" v-if="userIsAdmin">{{ $t('attributes.product.declensions') }}</th>
              <th class="table-col-shrink">{{ $t('attributes.product.widget') }}</th>
              <th-sortable sort-key="group_id" class="table-col-shrink">{{ $t('attributes.product.group') }}</th-sortable>
              <th class="table-col-shrink" v-if="userIsAdmin"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="productsError">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="productsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internalServerError') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!productsLoading && products.length === 0">
              <tr>
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.noProduct') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="product in products" :key="product.id">
                <th>{{ product.id }}</th>
                <td class="text-center">{{ product.createdAt | showFormattedDate }}</td>
                <td class="text-center">
                  <dot :active="product.active" />
                </td>
                <td :id="`product-${product.id}-img`">
                  <span v-if="product.hasImg">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                    <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                      <img :src="product.img.medium.url" style="height: 200px;" />
                    </b-tooltip>
                  </span>
                  <span v-else class="text-danger">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                  </span>
                </td>
                <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                  <img :src="product.img.medium.url" style="height: 200px;" />
                </b-tooltip>
                <td class="ellipsis" style="max-width: 200px;">
                  {{ product.productLanguageDatas[0].name }}
                </td>
                <td class="ellipsis" style="max-width: 120px;">
                  <small>{{ product.productLanguageDatas[0].rawPackaging }}</small>
                </td>
                <td><small>{{ product.reference }}</small></td>
                <td><small>{{ product.ean }}</small></td>
                <td><small>{{ product.upc }}</small></td>
                <td v-if="userIsAdmin" class="text-center">
                  {{ product.declensionsCount }}
                </td>
                <td class="text-nowrap">
                  <template v-if="product.widget">
                    <router-link v-if="userIsAdmin" :to="{ name: 'widget', params: { id: product.widget.id } }" class="small">
                      {{ product.widget.key }}
                    </router-link>
                    <code v-else class="small">
                      {{ product.widget.key}}
                    </code>
                  </template>
                </td>
                <td class="ellipsis" style="max-width: 150px;">
                  <template v-if="userIsAdmin">
                    <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                      <region-flag :code="product.group.region.code" />
                      {{ product.group.name }}
                    </router-link>
                  </template>
                  <template v-else>
                    <region-flag :code="product.group.region.code" />
                    {{ product.group.name }}
                  </template>
                </td>
                <td v-if="userIsAdmin">
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                    </template>
                    <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </template>

    <!-- Products grid -->
    <template v-else-if="displayMode === 'grid'">
      <div v-if="productsError" class="alert alert-danger">
        <template v-if="productsError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else-if="!productsLoading && products.length === 0" class="alert alert-warning">
        {{ $t('shared.warnings.noProduct') }}
      </div>
      <div
        v-else
        class="custom-grid mb-3"
        :aria-busy="productsLoading">
        <div
          v-for="product in products"
          :key="product.id"
          class="card">
          <div class="card-body">
            <img :src="product.img.medium.url" class="w-100 mb-3">
            <div class="card-text">
              <template v-if="userIsAdmin">
                <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                  <region-flag :code="product.group.region.code" />
                  {{ product.group.name }}
                </router-link>
              </template>
              <template v-else>
                <region-flag :code="product.group.region.code" />
                {{ product.group.name }}
              </template>
            </div>
            <div>{{ product.productLanguageDatas[0].name }}</div>
            <div class="small">{{ product.productLanguageDatas[0].rawPackaging }}</div>
            <div class="small">{{ product.reference }}</div>
            <div class="small">{{ product.ean }}</div>
            <div class="small">{{ product.upc }}</div>
            <div v-if="userIsAdmin" style="position: absolute; top: 0; right: 0;">
              <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                <template v-slot:button-content>
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                </template>
                <router-link :to="{ name: 'product', params: { id: product.id } }" class="dropdown-item">
                  {{ $t('shared.actions.show') }}
                </router-link>
                <router-link :to="{ name: 'editProduct', params: { id: product.id } }" class="dropdown-item">
                  {{ $t('shared.actions.edit') }}
                </router-link>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <pagination :total-rows="totalRows" :per-page="perPage" />

  <!-- Export modal -->
  <b-modal
    id="export-modal"
    size="sm"
    title="Select attributes..."
    scrollable
    no-fade>
    <div class="form-group text-center">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" @click="selectAllExportAttributes">Select all</button>
        <button type="button" class="btn btn-secondary" @click="unselectAllExportAttributes">Unselect all</button>
      </div>
    </div>
    <template v-for="(attributeValue, attribute) in selectedExportAttributes">
      <div :key="attribute" class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            v-model="selectedExportAttributes[attribute]"
            type="checkbox" class="custom-control-input" :id="`export-attribute-${attribute}`">
          <label class="custom-control-label" :for="`export-attribute-${attribute}`">
            {{ attribute }}
          </label>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer="{ close }">
      <a :href="exportUrl" class="btn btn-primary" @click="close()">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-download fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </a>
    </template>
  </b-modal>
</div>
