
<div>
  <transition name="fade" mode="out-in">
    <div v-if="productLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="productError" class="alert alert-danger">
      <template v-if="productError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ product.productLanguageDatas[0].name }}</h5>
        <router-link :to="{ name: 'editProduct', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <!-- Tabs -->
      <b-tabs content-class="mt-3">
        <!-- Infos -->
        <b-tab :title="$t('views.products.show.infos')" active>
          <div class="d-flex flex-wrap gutter">
            <div class="flex-fill">
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.id') }}</th>
                    <td>{{ product.id }}</td>
                  </tr>
                  <template v-for="languageData in product.productLanguageDatas">
                    <tr v-for="(attribute, index) in languageAttributes" :key="`language-data-${languageData.id}-attr-${attribute}`">
                      <th v-if="index === 0" :rowspan="languageAttributes.length" class="th-shrink"><language-badge :language="languageData.language" /></th>
                      <th class="th-shrink text-nowrap">{{ $t(`attributes.productLanguageData.${attribute}`) }}</th>
                      <td>
                        <template v-if="attribute === 'repositoryProductId'">
                          {{ languageData[attribute] }}
                          <a :href="`https://product-repository.clic2drive.com/admin/products/${languageData[attribute]}`" target="_blank">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-external-link-alt fa-w-16"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" class=""></path></svg>
                          </a>
                        </template>
                        <template v-else>
                          {{ languageData[attribute] }}
                        </template>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.reference') }}</th>
                    <td>{{ product.reference }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.ean') }}</th>
                    <td>{{ product.ean }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.upc') }}</th>
                    <td>{{ product.upc }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.rawProduct') }}</th>
                    <td>{{ product.rawProduct }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.minPrice') }}</th>
                    <td>
                      <template v-if="product.minPrice">
                        {{ product.minPrice | currency(currencyCode) }}
                      </template>
                      <template v-else>–</template>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.maxPrice') }}</th>
                    <td>
                      <template v-if="product.maxPrice">
                        {{ product.maxPrice | currency(currencyCode) }}
                      </template>
                      <template v-else>–</template>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.createdAt') }}</th>
                    <td>{{ product.createdAt | showFormattedDate }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.updatedAt') }}</th>
                    <td>{{ product.updatedAt | showFormattedDate }}</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.disabledAt') }}</th>
                    <td v-if="product.disabledAt">{{ product.disabledAt | showFormattedDate }}</td>
                    <td v-else>–</td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.active') }}</th>
                    <td>
                      <dot :active="product.active" />
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.group') }}</th>
                    <td>
                      <router-link :to="{ name: 'group', params: { id: product.group.id } }">
                        <region-flag :code="product.group.region.code" />
                        {{ product.group.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.groupActive') }}</th>
                    <td>
                      <dot :active="product.group.active" />
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.importTrackers') }}</th>
                    <td>
                      <template v-if="product.importTrackers.length > 0">
                        <ul class="list-unstyled mb-0">
                          <li v-for="importTracker in product.importTrackers" :key="importTracker.id">
                            <router-link :to="{ name: 'importTracker', params: { id: importTracker.id } }">
                              #{{ importTracker.id }}
                            </router-link>
                          </li>
                        </ul>
                      </template>
                      <template v-else>–</template>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.pimDataSource') }}</th>
                    <td>
                      <template v-if="product.pimDataSource">
                        {{ product.pimDataSource.name }}
                      </template>
                      <template v-else>–</template>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t('attributes.product.metadata') }}</th>
                    <td>
                      <template v-if="product.metadata">
                        <codemirror
                          :value="JSON.stringify(product.metadata, null, 2)"
                          class="codemirror-readonly"
                          :options="{ mode: 'application/json', tabSize: 2, autoRefresh: true, readOnly: true }" />
                      </template>
                      <template v-else>–</template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <a :href="product.img.url" target="_blank">
                <img :src="product.img.medium.url" class="border">
              </a>
            </div>
          </div>
        </b-tab>

        <!-- Declensions -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.products.show.declensions') }}
            <span v-if="!productLoading" class="badge badge-primary">{{ product.declensions.length }}</span>
          </template>

          <template v-if="product.declensions.length === 0">
            <div class="alert alert-warning">
              {{ $t('shared.warnings.noDeclension') }}
            </div>
          </template>

          <table v-else class="table">
            <thead>
              <tr>
                <th>{{ $t('attributes.product.id') }}</th>
                <th>{{ $t('attributes.productLanguageData.name') }}</th>
                <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                <th>{{ $t('attributes.product.ean') }}</th>
                <th class="th-shrink"></th>
              </tr>
            </thead>
            <tbody v-for="declension in product.declensions" :key="declension.id">
              <tr v-for="languageData in declension.productLanguageDatas" :key="languageData.id">
                <th>{{ declension.id }} </th>
                <td>{{ languageData.name }}</td>
                <td><small>{{ languageData.rawPackaging }}</small></td>
                <td><small>{{ declension.ean }}</small></td>
                <td>
                  <router-link :to="{ name: 'product', params: { id: declension.id } }" class="btn btn-primary btn-sm">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- Services -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.products.show.services') }}
            <span v-if="!productLoading" class="badge badge-primary">{{ serviceCount }}</span>
          </template>

          <template v-if="serviceTypes.length === 0">
            <div class="alert alert-warning">
              {{ $t('shared.warnings.noService') }}
            </div>
          </template>

          <template v-else>
            <!-- Services sub tabs -->
            <b-tabs content-class="mt-3">
              <b-tab v-for="serviceType in serviceTypes" :key="serviceType">
                <template v-slot:title>
                  {{ $t(`views.products.show.serviceLabels.${serviceType}`) }}
                  <span class="badge badge-primary">{{ product[serviceType].length }}</span>
                </template>

                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t('views.products.show.serviceId') }}</th>
                      <th>{{ $t('views.products.show.serviceName') }}</th>
                      <th v-if="serviceType === 'widgets'">{{ $t('attributes.widget.group') }}</th>
                      <th class="th-shrink"></th>
                    </tr>
                  </thead>
                  <tr v-for="service in product[serviceType]" :key="service.id">
                    <th>{{ service.id }}</th>
                    <td>{{ service.name }}</td>
                    <td v-if="serviceType === 'widgets'">
                      <router-link :to="{ name: 'group', params: { id: service.group.id } }">
                        <region-flag :code="service.group.region.code" />
                        {{ service.group.name }}
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="serviceRoute(serviceType, service.id)" class="btn btn-primary btn-sm">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                      </router-link>
                    </td>
                  </tr>
                </table>
              </b-tab>
            </b-tabs>
          </template>
        </b-tab>

        <!-- Foreign products -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.products.show.foreignProducts') }}
            <span v-if="!productLoading" class="badge badge-primary">{{ foreignProductsSorted.length }}</span>
          </template>

          <template v-if="foreignProductsSorted.length === 0">
            <div class="alert alert-warning">
              {{ $t('shared.warnings.noForeignProduct') }}
            </div>
          </template>

          <table v-else class="table">
            <thead>
              <tr>
                <th>{{ $t('attributes.product.id') }}</th>
                <th></th>
                <th>{{ $t('attributes.productLanguageData.name') }}</th>
                <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                <th>{{ $t('attributes.product.ean') }}</th>
                <th>{{ $t('attributes.product.upc') }}</th>
                <th>{{ $t('attributes.product.group') }}</th>
                <th class="th-shrink"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="foreignProduct in foreignProductsSorted" :key="foreignProduct.id">
                <th>{{ foreignProduct.id }} </th>
                <td>
                  <dot :active="foreignProduct.active" />
                </td>
                <td>{{ foreignProduct.productLanguageDatas[0].name }}</td>
                <td><small>{{ foreignProduct.productLanguageDatas[0].rawPackaging }}</small></td>
                <td><small>{{ foreignProduct.ean }}</small></td>
                <td><small>{{ foreignProduct.upc }}</small></td>
                <td>
                  <router-link :to="{ name: 'group', params: { id: foreignProduct.group.id } }">
                    <region-flag :code="foreignProduct.group.region.code" />
                    {{ foreignProduct.group.name }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'product', params: { id: foreignProduct.id } }" class="btn btn-primary btn-sm">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- Fallback products -->
        <b-tab>
          <template v-slot:title>
            {{ $t('views.products.show.fallbackProducts') }}
            <span v-if="!productLoading" class="badge badge-primary">{{ fallbackProductsProductsSorted.length }}</span>
          </template>

          <template v-if="fallbackProductsProductsSorted.length === 0">
            <div class="alert alert-warning">
              {{ $t('shared.warnings.noFallbackProduct') }}
            </div>
          </template>

          <table v-else class="table">
            <thead>
              <tr>
                <th>{{ $t('attributes.product.id') }}</th>
                <th></th>
                <th>{{ $t('attributes.productLanguageData.name') }}</th>
                <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                <th>{{ $t('attributes.product.ean') }}</th>
                <th>{{ $t('attributes.product.upc') }}</th>
                <th>{{ $t('attributes.product.group') }}</th>
                <th class="th-shrink"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fallbackProductsProduct in fallbackProductsProductsSorted" :key="fallbackProductsProduct.id">
                <th>{{ fallbackProductsProduct.fallbackProduct.id }} </th>
                <td>
                  <dot :active="fallbackProductsProduct.fallbackProduct.active" />
                </td>
                <td>{{ fallbackProductsProduct.fallbackProduct.productLanguageDatas[0].name }}</td>
                <td><small>{{ fallbackProductsProduct.fallbackProduct.productLanguageDatas[0].rawPackaging }}</small></td>
                <td><small>{{ fallbackProductsProduct.fallbackProduct.ean }}</small></td>
                <td><small>{{ fallbackProductsProduct.fallbackProduct.upc }}</small></td>
                <td>
                  <router-link :to="{ name: 'group', params: { id: fallbackProductsProduct.fallbackProduct.group.id } }">
                    <region-flag :code="fallbackProductsProduct.fallbackProduct.group.region.code" />
                    {{ fallbackProductsProduct.fallbackProduct.group.name }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'product', params: { id: fallbackProductsProduct.fallbackProduct.id } }" class="btn btn-primary btn-sm">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
