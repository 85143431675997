<template>
  <div class="pb-2">
    <transition name="fade" mode="out-in">
      <div v-if="landingPageLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="landingPageError" class="alert alert-danger">
        <template v-if="landingPageError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <persistent-tabs content-class="mt-3" :value="autoselectEditorTab ? 2 : undefined">
            <!-- Infos tab -->
            <b-tab :title="$t('views.landingPages.tabs.infos')">
              <!-- Name -->
              <div class="form-group row">
                <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="landingPage.name" type="text" class="form-control" :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.landingPage.name')">
                  <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.name" class="invalid-feedback">{{ tErrors('landingPage', 'name', landingPageSubmitErrors.name) }}</div>
                </div>
              </div>

              <!-- Group -->
              <div class="form-group row">
                <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.group') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <group-modal-select
                    v-model="landingPage.group"
                    :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.group }"
                    id="multiselect-group" />
                  <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('landingPage', 'group', landingPageSubmitErrors.group) }}</div>
                </div>
              </div>

              <!-- Active -->
              <div class="form-group row">
                <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.landingPage.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="landingPage.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                    <label class="custom-control-label" for="active-checkbox"></label>
                  </div>
                </div>
              </div>

              <!-- Title -->
              <div class="form-group row">
                <label for="input-title" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.title') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="landingPage.title" type="text" class="form-control" :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.title }" id="input-title" :placeholder="$t('attributes.landingPage.title')">
                  <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.title" class="invalid-feedback">{{ tErrors('landingPage', 'title', landingPageSubmitErrors.title) }}</div>
                </div>
              </div>

              <!-- Favicon -->
              <div class="form-group row">
                <label for="favicon-input" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.favicon') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <template v-if="landingPage.favicon.url">
                    <div class="border mb-3 d-inline-block">
                      <img
                        :src="landingPage.favicon.url"
                        class="img-fluid"
                        :class="{ 'opacity-50': landingPage.removeFavicon }">
                    </div>
                    <div class="mb-3">
                      <div class="custom-control custom-switch custom-switch-color">
                        <input v-model="landingPage.removeFavicon" type="checkbox" class="custom-control-input" id="remove-favicon-checkbox">
                        <label class="custom-control-label" for="remove-favicon-checkbox">
                          {{ $t('attributes.landingPage.removeFavicon') }}
                        </label>
                      </div>
                    </div>
                  </template>
                  <b-form-file
                    v-model="landingPage.faviconFile"
                    placeholder="Choose a file..."
                    drop-placeholder="Drop file here..."
                    id="favicon-input" />
                  <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.favicon" class="invalid-feedback d-block">{{ tErrors('landingPage', 'favicon', landingPageSubmitErrors.favicon) }}</div>
                </div>
              </div>

              <!-- Path -->
              <div class="form-group row">
                <label for="input-custom-path" class="col-sm-2 col-form-label">{{ $t('attributes.landingPage.customPath') }}</label>
                <div class="col-sm-10 col-12">
                  <input
                    v-model="landingPage.customPath"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid' : landingPageSubmitErrors && landingPageSubmitErrors.customPath }"
                    id="input-custom-path"
                    :placeholder="$t('attributes.landingPage.customPath')"
                  >
                  <div v-if="landingPageSubmitErrors && landingPageSubmitErrors.customPath" class="invalid-feedback">
                    {{ tErrors('landingPage', 'customPath', landingPageSubmitErrors.customPath) }}
                  </div>
                  <small class="form-text text-muted">
                    {{ $t('attributes.landingPage.customPathHelp') }}
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-10 col-12">
                </div>
              </div>
            </b-tab>

            <!-- Fonts tab -->
            <b-tab :title="$t('views.landingPages.tabs.fonts')">
              <!-- Fonts list -->
              <div v-if="landingPage.fonts.length === 0" class="alert alert-warning">
                {{ $t('attributes.landingPage.fonts.noFile') }}
              </div>
              <table v-else class="table table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('attributes.landingPage.fonts.name') }}</th>
                    <th>{{ $t('attributes.landingPage.fonts.url') }}</th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(font, index) in landingPage.fonts"
                    :key="index"
                    :class="{ 'table-danger': font._destroy }"
                  >
                    <td class="align-middle" style="max-width: 40%; word-break: break-all;">
                      {{ font.identifier }}
                    </td>
                    <td class="align-middle" style="max-width: 40%; word-break: break-all;">
                      {{ font.url }}
                    </td>
                    <td class="align-middle text-nowrap">
                      <template v-if="!font._destroy">
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteFont(font)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-sm btn-success" @click="restoreFont(font)">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                          {{ $t('shared.actions.restore') }}
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Fonts form-->
              <div class="form-group row">
                <label for="font-files-input" class="col-form-label col-sm-2">{{ $t('attributes.landingPage.fonts.inputLabel') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <b-form-file
                    v-model="landingPage.fontFiles"
                    placeholder="Choose a file..."
                    drop-placeholder="Drop file here..."
                    id="font-files-input"
                    multiple />
                </div>
              </div>
            </b-tab>

            <!-- Editor tab -->
            <b-tab :title="$t('views.landingPages.tabs.editor')" :disabled="action === 'new'">
              <div id="gjs" class="mb-3"></div>
            </b-tab>
          </persistent-tabs>

          <hr>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="landingPageSubmitLoading">
              <md-spinner v-if="landingPageSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('views.landingPages.submit.createAndAccessEditor') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import PersistentTabs from '../shared/PersistentTabs.vue'
import { BTab, BFormFile } from 'bootstrap-vue'
import cloneDeep from 'lodash-es/cloneDeep'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

import grapesjs from 'grapesjs'
import en from 'grapesjs/locale/en'
import fr from 'grapesjs/locale/fr'
import presetWebpagePlugin from 'grapesjs-preset-webpage'
import blocksBasicPlugin from 'grapesjs-blocks-basic'
import customCodePlugin from 'grapesjs-custom-code'
import c2bWidgetPlugin from './c2bWidgetPlugin'
import templateManagerPlugin from './templateManagerPlugin'
import 'grapesjs/dist/css/grapes.min.css'
import i18n from '../../i18n'

export default {
  props: {
    action: String
  },
  components: { MdSpinner, GroupModalSelect, PersistentTabs, BTab, BFormFile },
  data() {
    return {
      landingPage: {
        id: null,
        name: null,
        group: null,
        active: true,
        title: null,
        favicon: {},
        faviconFile: null,
        removeFavicon: false,
        customPath: null,
        assets: [],
        fonts: [],
        fontFiles: []
      },
      landingPageLoading: false,
      landingPageError: null,
      landingPageSubmitLoading: false,
      landingPageSubmitErrors: null,
      editor: null,
      isEnterPressedInEditor: false
    }
  },
  methods: {
    loadData: async function({ landingPage = true } = {}) {
      if (landingPage) {
        this.landingPageLoading = true
        this.landingPageError = null
      }

      const query = gql`
        query landingPagesForm ($landingPage: Boolean = true, $landingPageId: Int = null) {
          landingPage(id: $landingPageId) @include(if: $landingPage) {
            id
            name
            active
            title
            favicon
            customPath
            data
            assets {
              identifier
              url
            }
            fonts {
              identifier
              url
            }
            group {
              id
              name
              region {
                id
                code
                firstLocale
              }
            }
          }
        }
      `

      const variables = {
        landingPage,
        landingPageId: landingPage ? parseInt(this.$route.params.id) : undefined
      }

      try {
        const { data } = await client.query({ query, variables })

        if (landingPage) {
          this.landingPage = {
            ...this.landingPage,
            ...cloneDeep(data.landingPage)
          }
        }
      } catch (error) {
        if (landingPage) {
          this.landingPageError = error
        }
      } finally {
        if (landingPage) {
          this.landingPageLoading = false
        }
      }
    },
    initGjs: function() {
      const projectData = this.landingPage.data || null

      if (projectData) {
        // Replace GJS assets (retrieved from projectData, which may not be up-to-date) with the latest assets
        projectData.assets = this.landingPage.assets.map(asset => {
          return {
            src: asset.url
          }
        })
      }

      // Load vue-i18n translations into grapesjs
      const textKeys = ['sw-visibility', 'preview', 'fullscreen', 'undo', 'redo', 'gjs-open-import-webpage', 'canvas-clear', 'toggle-c2b-widget-lock', 'open-templates', 'open-sm', 'open-tm', 'open-layers', 'open-blocks']
      textKeys.forEach(key => {
        en.panels.buttons.titles[key] = i18n.t(`views.landingPages.grapesjs.buttonTitles.${key}`, 'en')
        fr.panels.buttons.titles[key] = i18n.t(`views.landingPages.grapesjs.buttonTitles.${key}`, 'fr')
      })

      // Init grapesjs editor
      this.editor = grapesjs.init({
        container: '#gjs',
        fromElement: true,
        showOffsets: true,
        selectorManager: {
          componentFirst: true
        },
        i18n: {
          locale: i18n.languageCode(),
          detectLocale: false,
          messages: { en, fr }
        },
        plugins: [
          editor => presetWebpagePlugin(editor, {
            useCustomTheme: false,
            blocks: []
          }),
          editor => blocksBasicPlugin(editor, {
            blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video'],
            flexGrid: true
          }),
          editor => c2bWidgetPlugin(editor, {
            groupId: () => this.landingPage.group?.id
          }),
          editor => templateManagerPlugin(editor),
          editor => customCodePlugin(editor),
          editor => {
            // Make body font/color editable
            editor.Components.addType('wrapper', {
              model: {
                defaults: {
                  stylable: ['background', 'background-color', 'font-family', 'color']
                }
              }
            })

            editor.on('load', () => {
              // Add flex gap to style manager
              editor.StyleManager.addProperty('flex', {
                label: 'Gap',
                property: 'gap',
                type: 'integer',
                units: ['px', 'em', '%', 'rem', 'vh', 'vw'],
                default: 0
              })

              // Add custom fonts to editors list of fonts
              const initialFonts = editor.StyleManager.getBuiltIn('font-family').options
              const fonts = [...initialFonts, ...this.fonts.map(font => ({ id: font.name, label: font.name }))].sort((a, b) => a.label.localeCompare(b.label))
              editor.StyleManager.getProperty('typography', 'font-family').setOptions(fonts)

              // Apply custom fonts
              this.fonts.forEach(font => {
                const rule = `@font-face { font-family: '${font.name}'; src: url('${font.url}'); }`
                const alreadyExists = editor.Css.getRules().some(r => r.getAtRule() === '@font-face' && r.attributes.style['font-family'] === font.name)
                if (!alreadyExists) {
                  editor.Css.addRules(rule)
                }
              })
            })
          }
        ],
        storageManager: {
          type: 'remote',
          autoload: false,
          autosave: false
        },
        assetManager: {
          // Handle asset upload
          uploadFile: async (event) => {
            const files = event.dataTransfer ? event.dataTransfer.files : event.target.files

            const variables = {
              input: {
                landingPageId: this.landingPage.id,
                attributes: {
                  assets: files
                }
              }
            }

            const mutation = gql`
              mutation createLandingPageAsset($input: UpdateLandingPageInput!) {
                updateLandingPage(input: $input) {
                  landingPage {
                    id
                    assets {
                      identifier
                      url
                    }
                  }
                  errors
                }
              }
            `

            try {
              const result = await client.mutate({
                mutation,
                variables,
                context: {
                  hasUpload: true
                }
              })

              const assets = result.data.updateLandingPage.landingPage.assets

              // Add assets to editor (note: assets already added are added again but not duplicated in the editor)
              assets.forEach(asset => {
                this.editor.AssetManager.add(asset.url)
              })
            } catch (error) {
              console.error(error)
            }
          }
        },
        projectData,
        canvas: {
          scripts: [
            `${import.meta.env.VITE_APP_WIDGET_BASE_URL}/c2b.js?locale=${this.landingPage.group.region.firstLocale}&noStat=1`
          ]
        }
      })

      // Handle asset remove
      this.editor.on('asset:remove', async (asset) => {
        const variables = {
          input: {
            landingPageId: this.landingPage.id,
            attributes: {
              removeAssets: [asset.getFilename()]
            }
          }
        }

        const mutation = gql`
          mutation updateLandingPageAssets($input: UpdateLandingPageInput!) {
            updateLandingPage(input: $input) {
              landingPage {
                id
                assets {
                  identifier
                  url
                }
              }
              errors
            }
          }
        `

        try {
          await client.mutate({
            mutation,
            variables
          })
        } catch (error) {
          console.error(error)
        }
      })

      // Remove "view code" button from preset-webpage
      this.editor.Panels.removeButton('options', 'export-template')

      // Update `isEnterPressedInEditor` when enter key is pressed in editor
      const editorContainer = this.editor.getContainer()
      editorContainer.addEventListener('keydown', e => {
        this.isEnterPressedInEditor = e.keyCode === 13 && editorContainer.contains(e.target)
      })
      editorContainer.addEventListener('keyup', e => {
        this.isEnterPressedInEditor = false
      })

      // Tooltip data attributes from grapesjs demo
      const optionItems = ['sw-visibility', 'preview', 'fullscreen', 'undo', 'redo', 'gjs-open-import-webpage', 'canvas-clear', 'toggle-c2b-widget-lock', 'open-templates']
      optionItems.forEach(item => {
        this.editor.Panels.getButton('options', item).set('attributes', { 'data-tooltip-pos': 'bottom' })
      })

      const viewItems = ['open-sm', 'open-tm', 'open-layers', 'open-blocks']
      viewItems.forEach(item => {
        this.editor.Panels.getButton('views', item).set('attributes', { 'data-tooltip-pos': 'bottom' })
      })

      const titles = document.querySelectorAll('*[title]')
      for (var i = 0; i < titles.length; i++) {
        var el = titles[i]
        var title = el.getAttribute('title')
        title = title ? title.trim() : ''
        if (!title) break
        el.setAttribute('data-tooltip', title)
        el.setAttribute('title', '')
      }

      // Add block to create a div
      this.editor.BlockManager.add('div', {
        id: 'div',
        label: 'Div',
        category: 'Basic',
        type: 'div',
        media: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 4H4v3m0 4v2m7-9h2m-2 16h2m7-9v2m-3-9h3v3M7 20H4v-3m13 3h3v-3"/></svg>',
        content: '<div style="min-height: 50px;"></div>',
        select: true
      })

      // Add "responsive-container" custom type & block to create a div with container class
      this.editor.DomComponents.addType('responsive-container', {
        isComponent: el => el.classList && el.classList.contains('container')
      })
      this.editor.BlockManager.add('responsive-container', {
        id: 'responsive-container',
        label: 'Responsive Container',
        category: 'Basic',
        type: 'responsive-container',
        media: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M2 1.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0M5 13h5V2H5zm-1 0a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1zm9.5-11a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 3.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 5.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 7.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 9.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 11.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M2 13.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m11.5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1" clip-rule="evenodd"/></svg>',
        content: '<div class="container"></div>',
        select: true
      })

      // Initial data
      if (!projectData) {
        this.editor.getWrapper().set('style', { 'font-family': 'Arial' })
        this.editor.addComponents({
          tagName: 'h1',
          type: 'text',
          content: 'My landing page'
        })
        this.editor.addComponents({
          type: 'text',
          content: 'You can start building your landing page from this blank page, or use the <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block; width: 1.25em; vertical-align: -0.125em; font-size: inherit;" viewBox="0 0 32 32"><path fill="currentColor" d="M26 6v4H6V6zm0-2H6a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2M10 16v10H6V16zm0-2H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2m16 2v10H16V16zm0-2H16a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2"></path></svg> button to select a template.'
        })
        this.editor.addStyle(`
          /* Bootstrap container */
          .container,
          .container-fluid,
          .container-xxl,
          .container-xl,
          .container-lg,
          .container-md,
          .container-sm {
            width: 100%;
            padding-right: var(--bs-gutter-x, 0.75rem);
            padding-left: var(--bs-gutter-x, 0.75rem);
            margin-right: auto;
            margin-left: auto;
            min-height: 75px; /* Make container visible when added */
          }

          @media (min-width: 576px) {
            .container-sm, .container {
              max-width: 540px;
            }
          }
          @media (min-width: 768px) {
            .container-md, .container-sm, .container {
              max-width: 720px;
            }
          }
          @media (min-width: 992px) {
            .container-lg, .container-md, .container-sm, .container {
              max-width: 960px;
            }
          }
          @media (min-width: 1200px) {
            .container-xl, .container-lg, .container-md, .container-sm, .container {
              max-width: 1140px;
            }
          }
          @media (min-width: 1400px) {
            .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
              max-width: 1320px;
            }
          }
        `)
      }
    },
    deleteFont: function(font) {
      this.$set(font, '_destroy', true)
    },
    restoreFont: function(font) {
      this.$set(font, '_destroy', false)
    },
    submit: async function() {
      if (this.isEnterPressedInEditor) return

      this.landingPageSubmitLoading = true
      this.landingPageSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            name: this.landingPage.name,
            groupId: this.landingPage.group?.id,
            active: this.landingPage.active,
            title: this.landingPage.title,
            favicon: this.landingPage.faviconFile ? this.landingPage.faviconFile : undefined,
            removeFavicon: this.landingPage.removeFavicon,
            customPath: this.landingPage.customPath,
            fonts: this.landingPage.fontFiles ? this.landingPage.fontFiles : [],
            removeFonts: this.landingPage.fonts.filter(font => font._destroy === true).map(font => font.identifier) || undefined
          }
        }
      }

      if (this.action === 'new') {
        const mutation = gql`
          mutation createLandingPage($input: CreateLandingPageInput!) {
            createLandingPage(input: $input) {
              landingPage {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables, context: { hasUpload: true } })

          if (data.createLandingPage.errors) {
            this.landingPageSubmitErrors = data.createLandingPage.errors
          } else {
            this.$router.push({ name: 'editLandingPage', params: { id: data.createLandingPage.landingPage.id }, query: { tab: 'editor' } })
          }
        } catch (error) {
          this.landingPageSubmitErrors = error
        } finally {
          this.landingPageSubmitLoading = false
        }
      } else if (this.action === 'edit') {
        variables.input.landingPageId = parseInt(this.$route.params.id)
        variables.input.attributes.data = this.editor.getProjectData()
        variables.input.attributes.html = this.editor.getHtml()
        variables.input.attributes.css = this.editor.getCss()

        const mutation = gql`
          mutation updateLandingPage($input: UpdateLandingPageInput!) {
            updateLandingPage(input: $input) {
              landingPage {
                id
              }
              errors
            }
          }
        `

        try {
          const { data } = await client.mutate({ mutation, variables, context: { hasUpload: true } })

          if (data.updateLandingPage.errors) {
            this.landingPageSubmitErrors = data.updateLandingPage.errors
          } else {
            this.$router.push({ name: 'landingPage', params: { id: data.updateLandingPage.landingPage.id } })
          }
        } catch (error) {
          this.landingPageSubmitErrors = error
        } finally {
          this.landingPageSubmitLoading = false
        }
      }
    }
  },
  computed: {
    autoselectEditorTab: function() {
      return this.$route.query.tab === 'editor'
    },
    fonts: function() {
      return this.landingPage.fonts.map(font => {
        // Remove file extension
        const name = font.identifier.split('.').slice(0, -1).join('.')
        return {
          ...font,
          name
        }
      })
    }
  },
  mounted: async function() {
    if (this.action === 'edit') {
      await this.loadData()
      this.initGjs()
    } else {
      await this.loadData({ landingPage: false })
    }
  }
}
</script>

<style>
/* Make grapesjs look flat and clean */
:root {
  --gjs-primary-color: #eee;
  --gjs-secondary-color: #212529;
  --gjs-secondary-dark-color: #e1e1e1;
  --gjs-tertiary-color: #304269;
  --gjs-quaternary-color: #212529;
  --gjs-main-light-color: #d8d9e6;
  --gjs-main-font: initial;
  --gjs-canvas-top: 50px;
}

.gjs-pn-views-container {
  padding-top: 52px;
}

.gjs-pn-btn {
  min-height: 40px;
  min-width: 40px;
  line-height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 26px !important;
  }
}

.gjs-clm-tag {
  color: #fff;
}

.gjs-pn-commands, .gjs-pn-views-container {
  box-shadow: none;
}

.gjs-pn-views, .gjs-category-open, .gjs-block-category.gjs-open, .gjs-sm-sector.gjs-sm-open, .gjs-trait-category.gjs-open {
  border-bottom: none;
}

.gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title, .gjs-trait-category .gjs-title {
  border-bottom: none;
}

.gjs-title {
  border-bottom: 0 !important;
}

.gjs-blocks-c {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding: 0.5rem;
}

.gjs-block {
  box-shadow: none;
  background-color: #fff;
  border: 0;
  margin: 0;
  width: auto;
  font-weight: normal;
}

.gjs-block:hover {
  box-shadow: none;
}

.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title, .gjs-trait-category .gjs-title, .gjs-mdl-dialog {
  font-weight: normal;
  letter-spacing: normal;
}

.gjs-mdl-header {
  padding: 1rem;
}

/* Make traits label wrap */
.gjs-trt-trait {
  flex-direction: column;
  align-items: start;
}

.gjs-trt-trait .gjs-label-wrp, .gjs-trt-trait .gjs-field-wrp {
  width: 100%;
}

/* Template manager */
.gjs-templates {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.gjs-template {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

.gjs-template img {
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
}

/* Tooltip from grapesjs demo */
[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  background: rgba(51, 51, 51, 0.9);
  background: rgba(55, 61, 73, 0.9);
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  display: block;
  font-size: 12px;
  left: 50%;
  line-height: normal;
  max-width: 32rem;
  opacity: 0;
  overflow: hidden;
  padding: 0.6rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: all 0.216s ease;
  z-index: 99;
}

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -0.5rem);
  -ms-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem);
}

[data-tooltip][disabled],
[data-tooltip].disabled {
  pointer-events: auto;
}

[data-tooltip-pos=right]::after {
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos=right]:focus::after,
[data-tooltip-pos=right]:hover::after {
  -webkit-transform: translate(0.5rem, 50%);
  -ms-transform: translate(0.5rem, 50%);
  transform: translate(0.5rem, 50%);
}

[data-tooltip-pos=bottom]::after {
  bottom: auto;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-tooltip-pos=bottom]:focus::after,
[data-tooltip-pos=bottom]:hover::after {
  -webkit-transform: translate(-50%, 0.5rem);
  -ms-transform: translate(-50%, 0.5rem);
  transform: translate(-50%, 0.5rem);
}

[data-tooltip-pos=left]::after {
  bottom: 50%;
  left: auto;
  right: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos=left]:focus::after,
[data-tooltip-pos=left]:hover::after {
  -webkit-transform: translate(-0.5rem, 50%);
  -ms-transform: translate(-0.5rem, 50%);
  transform: translate(-0.5rem, 50%);
}
</style>
