<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.customisation.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.customisation.name')">
          </div>

          <div>
            <label for="filter-region">{{ $t('attributes.customisation.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="option.code" />
                    {{ option.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </key-multiselect>
          </div>

          <div>
            <label for="filter-group">{{ $t('attributes.customisation.group') }}</label>
            <group-modal-select v-model="filters.groups" multiple />
          </div>

          <div>
            <label for="filter-features">{{ $t('attributes.customisation.features') }}</label>
            <multiselect
              v-model="filters.featureIds"
              :multiple="true"
              :close-on-select="false"
              :options="featureOptions"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false">
              <template slot="option" slot-scope="props">
                {{ $t(`attributes.customisation.featuresList.${props.option}`) }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <div class="btn-group" style="margin: 2.5px 5px;">
                  <div class="btn btn-light btn-xs text-left">
                    {{ $t(`attributes.customisation.featuresList.${option}`) }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div></div>
      <div>
        <!-- TODO: export button -->
        <!-- New button -->
        <router-link :to="{ name: 'newCustomisation' }" class="btn btn-success">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newCustomisation') }}
        </router-link>
      </div>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="customisationsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="customisationsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.customisation.id') }}</th-sortable>
              <th-sortable sort-key="name">{{ $t('attributes.customisation.name') }}</th-sortable>
              <th-sortable sort-key="group_id">{{ $t('attributes.customisation.group') }}</th-sortable>
              <th class="th-shrink">{{ $t('attributes.customisation.default') }}</th>
              <th class="th-shrink">{{ $t('attributes.customisation.domains') }}</th>
              <th class="th-shrink">{{ $t('attributes.customisation.multiproductLandingPages') }}</th>
              <th class="th-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="customisationsError">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="customisationsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internalServerError') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!customisationsLoading && customisations.length === 0">
              <tr>
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.noCustomisation') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="customisation in customisations" :key="customisation.id">
                <th>{{ customisation.id }}</th>
                <td>{{ customisation.name }}</td>
                <td class="text-nowrap">
                  <router-link :to="{ name: 'group', params: { id: customisation.group.id } }">
                    <region-flag :code="customisation.group.region.code" />
                    {{ customisation.group.name }}
                  </router-link>
                </td>
                <td class="text-center"><dot :active="customisation.default" /></td>
                <td class="text-center">{{ customisation.domains.length }}</td>
                <td class="text-center">{{ customisation.multiproductLandingPagesCount }}</td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                    </template>
                    <router-link :to="{ name: 'customisation', params: { id: customisation.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link :to="{ name: 'editCustomisation', params: { id: customisation.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Multiselect from 'vue-multiselect'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown } from 'bootstrap-vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'

export default {
  components: { MdSpinner, KeyMultiselect, Multiselect, GroupModalSelect, Pagination, BDropdown, Dot, RegionFlag, ThSortable },
  data: function() {
    return {
      customisations: [],
      customisationsLoading: false,
      customisationsError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        regionIds: [],
        groups: [],
        featureIds: []
      },
      colspan: 12,
      featureOptions: [
        'layoutColumns',
        'layoutTabs',
        'showPrice',
        'showShippingCost',
        'showShippingDelay',
        'retailerPriceFilter'
      ]
    }
  },
  computed: {
    currentPage: function() {
      return parseInt(this.$route.query.page) || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    }
  },
  methods: {
    // Load data
    loadData: function({ regions = true } = {}) {
      this.customisationsLoading = true
      this.customisationsError = null
      if (regions) {
        this.regionsLoading = true
        this.regionsError = null
      }

      const query = `query customisationsIndex ($page: Int!, $perPage: Int!, $name: String, $groupId: Int, $groupIds: [Int!], $regionIds: [Int!], $featureIds: [String!], $sort: String, $sortDirection: String, $regions: Boolean = true) {
        customisationsPage(page: $page, perPage: $perPage, name: $name, groupId: $groupId, groupIds: $groupIds, regionIds: $regionIds, featureIds: $featureIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          nodes {
            id
            name
            default
            domains
            multiproductLandingPagesCount
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
        regions @include(if: $regions) {
          id
          code
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            page: this.currentPage,
            perPage: this.perPage,
            name: this.filters.name,
            groupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : undefined,
            groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
            regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
            featureIds: this.filters.featureIds.length > 0 ? this.filters.featureIds : undefined,
            sort: this.$route.query.sort,
            sortDirection: this.$route.query.sort_direction,
            regions
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.customisationsLoading = false
          if (regions) this.regionsLoading = false

          if (data.errors) {
            this.customisationsError = { status: res.status, errors: data.errors }
            if (regions) this.regionsError = { status: res.status, errors: data.errors }
          } else {
            this.totalRows = data.data.customisationsPage.nodesCount
            this.customisations = Object.freeze(data.data.customisationsPage.nodes)
            if (regions) this.regions = Object.freeze(data.data.regions)
          }
        })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.featureIds.length > 0) query.featureIds = this.filters.featureIds
      if (this.filters.groups.length > 0) query.groupIds = this.filters.groups.map(group => group.id)

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: async function() {
      this.filters.name = this.$route.query.name

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.featureIds) {
        if (this.$route.query.featureIds instanceof Array) {
          this.filters.featureIds = this.$route.query.featureIds.map(featureId => featureId)
        } else {
          this.filters.featureIds = [this.$route.query.featureIds]
        }
      } else {
        this.filters.featureIds = []
      }

      if (this.$route.query.groupIds) {
        let groupIds
        if (this.$route.query.groupIds instanceof Array) {
          groupIds = this.$route.query.groupIds.map(groupId => parseInt(groupId))
        } else {
          groupIds = [parseInt(this.$route.query.groupIds)]
        }

        // Load groups from url groupIds
        const query = `query groupsFilter ($groupIds: [Int!]) {
          groups(ids: $groupIds) {
            id
            name
            active
            region {
              id
              code
            }
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables: { groupIds } })
        })
        const json = await res.json()
        this.filters.groups = json.data.groups
      } else {
        this.filters.groups = []
      }
    }
  },
  created: async function() {
    await this.updateFiltersFromQuery()
    await this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: async function(to, from) {
      await this.updateFiltersFromQuery()
      await this.loadData({ regions: false })
    }
  }
}
</script>
