<template>
  <span
    :class="`flag-icon flag-icon-${code.toLowerCase()}`"
    :alt="regionName(code)"
    :title="regionName(code)"
    v-b-tooltip.hover />
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: { 'b-tooltip': VBTooltip },
  props: ['code']
}
</script>

<style lang="scss">
.flag-icon {
  width: calc(1em + 2px) !important;
  height: calc(.75em + 2px);
  line-height: .75em !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.flag-icon-international {
  background-image: url(../../assets/flag-international.svg);
}
</style>
