
<div>
  <!-- Form element -->
  <div @click="showModal">
    <slot name="element" v-bind="{ group: value, groups: value }">
      <!-- Single -->
      <template v-if="!multiple">
        <div class="form-control" :class="{ 'is-invalid': isInvalid }">
          <div class="d-flex align-items-center justify-content-between">
            <div v-if="!value">
              {{ $t('shared.placeholders.select') }}
            </div>
            <div v-else>
              <region-flag :code="value.region.code" />
              {{ value.name }}
            </div>
            <div>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
            </div>
          </div>
        </div>
      </template>

      <!-- Multiple -->
      <template v-else>
        <div class="form-control h-auto" :class="{ 'is-invalid': isInvalid }">
          <div class="d-flex align-items-center justify-content-between gutter-x-sm">
            <div v-if="value.length === 0">
              {{ $t('shared.placeholders.select') }}
            </div>
            <div v-else style="min-width: 0;">
              <div class="d-flex flex-wrap gutter-xs">
                <div
                  v-for="group in value"
                  :key="group.id"
                  class="btn-group">
                  <div class="btn btn-light btn-xs text-left">
                    <region-flag :code="group.region.code" />
                    {{ group.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center" @click.stop="unselectGroup(group)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
            </div>
          </div>
        </div>
      </template>
    </slot>
  </div>

  <!-- Modal -->
  <b-modal
    :id="`group-modal-${_uid}`"
    size="lg"
    :title="$t('views.groupModalSelect.title')"
    hide-footer
    no-fade
    @shown="$refs.filterName.focus()">
    <!-- Filters -->
    <div class="custom-grid mb-3">
      <div>
        <label for="filter-name">{{ $t('attributes.group.name') }}</label>
        <input
          v-model="filters.name"
          type="search"
          class="form-control"
          id="filter-name"
          ref="filterName"
          :placeholder="$t('attributes.group.name')">
      </div>

      <div>
        <label for="filter-active">{{ $t('attributes.group.active') }}</label>
        <select v-model="filters.active" class="custom-select">
          <option :value="null">{{ $t('shared.placeholders.select') }}</option>
          <option :value="false">Inactive</option>
          <option :value="true">Active</option>
        </select>
      </div>

      <div>
        <label for="filter-region">{{ $t('attributes.group.region') }}</label>
        <key-multiselect
          v-model="filters.regionIds"
          :multiple="true"
          :close-on-select="false"
          :options="regionOptions"
          label="name"
          track-by="id"
          :placeholder="$t('shared.placeholders.select')"
          :show-labels="false"
          :loading="regionsLoading">
          <template slot="option" slot-scope="props">
            <region-flag :code="props.option.code" />
            {{ props.option.name }}
          </template>
          <template v-slot:tag="{ option, remove }">
            <div class="btn-group" style="margin: 2.5px 5px;">
              <div class="btn btn-light btn-xs text-left">
                <region-flag :code="option.code" />
                {{ option.name }}
              </div>
              <div class="btn btn-light btn-xs px-2 d-flex align-items-center"  @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
              </div>
            </div>
          </template>
        </key-multiselect>
      </div>
    </div>

    <hr>

    <!-- Groups -->
    <div v-if="!groupsLoading && groups.length === 0" class="alert alert-warning">
      {{ $t('shared.warnings.noGroup') }}
    </div>
    <template v-else>
      <h6>{{ $tc('views.groupModalSelect.results', groupsCount) }}</h6>

      <div class="list-group custom-scrollbar" style="overflow: auto; max-height: 246px;">
        <a
          v-for="{ group, selected } in groupsWithStatus"
          :key="group.id"
          href="javascript:void(0);"
          class="list-group-item list-group-item-action"
          :class="{ 'bg-light': selected }"
          @click="groupClick(group)"
          :data-group-id="group.id">
          <div class="d-flex justify-content-between">
            <div>
              <dot :active="group.active" />
              <region-flag :code="group.region.code" />
              {{ group.name }}
            </div>
            <div v-if="selected">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
            </div>
          </div>
        </a>

        <!-- Next page -->
        <template v-if="groupsHasNextPage">
          <div v-if="groupsLoading" class="list-group-item text-center">
            <md-spinner md-indeterminate></md-spinner>
          </div>
          <template v-else>
            <button type="button" class="list-group-item list-group-item-action active" @click="loadGroupsPage">
              {{ $t('views.groupModalSelect.loadMore') }}
            </button>
          </template>
        </template>
      </div>
    </template>
  </b-modal>
</div>
