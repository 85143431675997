<template>
  <div>
    <!-- LinkedIn link -->
    <div class="mb-3 d-flex flex-wrap gutter-sm">
      <a :href="c2bBlogUrl" target="_blank" class="btn btn-primary">
        {{ $t('views.posts.index.checkOurBlog') }}
      </a>
      <a href="https://www.linkedin.com/company/clic2buy/" target="_blank" class="btn btn-linkedin" style="background-color: #204ecf; border-color: #204ecf;">
        <div class="d-inline-flex gutter-x-xs align-items-center">
          {{ $t('views.posts.index.followUsOn') }}
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 84 21" preserveAspectRatio="xMinYMin meet" version="1.1" focusable="false" class="lazy-loaded" style="height: 18px; vertical-align: -0.25em;">
            <g class="inbug" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M19.479,0 L1.583,0 C0.727,0 0,0.677 0,1.511 L0,19.488 C0,20.323 0.477,21 1.333,21 L19.229,21 C20.086,21 21,20.323 21,19.488 L21,1.511 C21,0.677 20.336,0 19.479,0" class="bug-text-color" transform="translate(63.000000, 0.000000)"></path>
              <path d="M82.479,0 L64.583,0 C63.727,0 63,0.677 63,1.511 L63,19.488 C63,20.323 63.477,21 64.333,21 L82.229,21 C83.086,21 84,20.323 84,19.488 L84,1.511 C84,0.677 83.336,0 82.479,0 Z M71,8 L73.827,8 L73.827,9.441 L73.858,9.441 C74.289,8.664 75.562,7.875 77.136,7.875 C80.157,7.875 81,9.479 81,12.45 L81,18 L78,18 L78,12.997 C78,11.667 77.469,10.5 76.227,10.5 C74.719,10.5 74,11.521 74,13.197 L74,18 L71,18 L71,8 Z M66,18 L69,18 L69,8 L66,8 L66,18 Z M69.375,4.5 C69.375,5.536 68.536,6.375 67.5,6.375 C66.464,6.375 65.625,5.536 65.625,4.5 C65.625,3.464 66.464,2.625 67.5,2.625 C68.536,2.625 69.375,3.464 69.375,4.5 Z" class="background" fill="currentColor"></path>
            </g>
            <g class="linkedin-text">
              <path d="M60,18 L57.2,18 L57.2,16.809 L57.17,16.809 C56.547,17.531 55.465,18.125 53.631,18.125 C51.131,18.125 48.978,16.244 48.978,13.011 C48.978,9.931 51.1,7.875 53.725,7.875 C55.35,7.875 56.359,8.453 56.97,9.191 L57,9.191 L57,3 L60,3 L60,18 Z M54.479,10.125 C52.764,10.125 51.8,11.348 51.8,12.974 C51.8,14.601 52.764,15.875 54.479,15.875 C56.196,15.875 57.2,14.634 57.2,12.974 C57.2,11.268 56.196,10.125 54.479,10.125 L54.479,10.125 Z" fill="currentColor"></path>
              <path d="M47.6611,16.3889 C46.9531,17.3059 45.4951,18.1249 43.1411,18.1249 C40.0001,18.1249 38.0001,16.0459 38.0001,12.7779 C38.0001,9.8749 39.8121,7.8749 43.2291,7.8749 C46.1801,7.8749 48.0001,9.8129 48.0001,13.2219 C48.0001,13.5629 47.9451,13.8999 47.9451,13.8999 L40.8311,13.8999 L40.8481,14.2089 C41.0451,15.0709 41.6961,16.1249 43.1901,16.1249 C44.4941,16.1249 45.3881,15.4239 45.7921,14.8749 L47.6611,16.3889 Z M45.1131,11.9999 C45.1331,10.9449 44.3591,9.8749 43.1391,9.8749 C41.6871,9.8749 40.9121,11.0089 40.8311,11.9999 L45.1131,11.9999 Z" fill="currentColor"></path>
              <polygon fill="currentColor" points="38 8 34.5 8 31 12 31 3 28 3 28 18 31 18 31 13 34.699 18 38.241 18 34 12.533"></polygon>
              <path d="M16,8 L18.827,8 L18.827,9.441 L18.858,9.441 C19.289,8.664 20.562,7.875 22.136,7.875 C25.157,7.875 26,9.792 26,12.45 L26,18 L23,18 L23,12.997 C23,11.525 22.469,10.5 21.227,10.5 C19.719,10.5 19,11.694 19,13.197 L19,18 L16,18 L16,8 Z" fill="currentColor"></path>
              <path d="M11,18 L14,18 L14,8 L11,8 L11,18 Z M12.501,6.3 C13.495,6.3 14.3,5.494 14.3,4.5 C14.3,3.506 13.495,2.7 12.501,2.7 C11.508,2.7 10.7,3.506 10.7,4.5 C10.7,5.494 11.508,6.3 12.501,6.3 Z" fill="currentColor"></path>
              <polygon fill="currentColor" points="3 3 0 3 0 18 9 18 9 15 3 15"></polygon>
            </g>
          </svg>
        </div>
      </a>
    </div>

    <hr>

    <!-- Month stats -->
    <h4 class="mb-3">
      {{ $t('views.posts.index.monthStats') }}
      <sup
        class="text-secondary"
        v-b-tooltip.hover :title="monthTooltip">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
      </sup>
    </h4>

    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <number-card
        v-for="action in ['print_button', 'print', 'redirection']"
        :key="action"
        class="flex-fill flex-basis-0"
        :number="actionCount('main', action)"
        :loading="analyticsDataLoading"
        :label="$t(`shared.eventActions.widget.${action}`)">
        <template v-slot:caption>
          <span class="text-primary">
            <md-spinner v-if="analyticsDataLoading" md-indeterminate :diameter="14" :stroke-width="5" />
            <template v-else>{{ actionCount('compare', action) | number }}</template>
          </span>
          <span class="text-secondary">
            {{ $t('views.posts.index.lastMonth') }}
          </span>
        </template>
        <template v-slot:icon>
          <template v-if="action === 'print_button'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-w-18"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
          </template>
          <template v-else-if="action === 'print'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mouse" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-mouse fa-w-12"><path fill="currentColor" d="M0 352a160 160 0 0 0 160 160h64a160 160 0 0 0 160-160V224H0zM176 0h-16A160 160 0 0 0 0 160v32h176zm48 0h-16v192h176v-32A160 160 0 0 0 224 0z" class=""></path></svg>
          </template>
          <template v-else-if="action === 'redirection'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-arrow-alt-circle-right fa-w-16"><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z" class=""></path></svg>
          </template>
        </template>
      </number-card>
    </div>

    <!-- Monitoring -->
    <div v-if="showMonitor && !demoMode" class="mb-3">
      <widget-monitor />
    </div>

    <!-- Posts -->
    <div class="mb-3">
      <div class="d-flex align-items-end justify-content-between mb-3">
        <h4 class="mb-0">{{ $t('views.posts.index.posts') | capitalize }}</h4>
        <router-link v-if="userIsAdmin && !demoMode" :to="{ name: 'newPost' }" class="btn btn-success">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newPost') }}
        </router-link>
      </div>

      <div name="post-list-animation" is="transition-group">
        <div class="card mb-3" v-for="post in posts" :key="post.id">
          <div class="card-body">
            <div class="d-flex gutter-sm justify-content-between mb-1">
              <div>
                <div class="d-flex flex-wrap gutter-sm small text-secondary">
                  <div>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" class=""></path></svg>
                    {{ post.createdAt | date }}
                  </div>
                  <div>
                    <svg v-if="post.category === 'info'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                    <svg v-else-if="post.category === 'feed'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="database" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-database fa-w-14"><path fill="currentColor" d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z" class=""></path></svg>
                    <svg v-else-if="post.category === 'injection'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-basket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-shopping-basket fa-w-18"><path fill="currentColor" d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z" class=""></path></svg>
                    {{ post.category | capitalize }}
                  </div>
                  <div v-if="post.status && post.status.length > 0">
                    <template v-if="post.status === 'in_progress'">
                      <svg :class="post.status === 'solved' ? 'text-success' :  'text-warning'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-exclamation-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg>
                      {{ $t('views.posts.index.inProgress') }}
                    </template>
                    <template v-else-if="post.status === 'solved'">
                      <svg :class="post.status === 'solved' ? 'text-success' :  'text-warning'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
                      {{ $t('views.posts.index.solved') }}
                    </template>
                  </div>
                </div>
                <div class="font-weight-semibold">{{ post.title }}</div>
              </div>

              <div v-if="userIsAdmin && !demoMode" class="text-nowrap gutter-x-xs">
                <router-link
                  :to="{ name: 'editPost', params: { id: post.id } }"
                  class="btn btn-sm btn-warning text-nowrap">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-edit fa-w-18"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class=""></path></svg>
                  {{ $t('shared.actions.edit') }}
                </router-link>
                <button type="button" class="btn btn-sm btn-danger" @click="deletePost(post)">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                  {{ $t('shared.actions.delete') }}
                </button>
              </div>
            </div>
            <div>{{ post.body }}</div>
          </div>
        </div>
      </div>

      <infinite-loading @infinite="infinitePostsHandler" force-use-infinite-wrapper="true">
        <div slot="spinner">
          <md-spinner md-indeterminate />
        </div>
        <div slot="no-more">{{ $t('views.posts.index.endPosts') }}</div>
        <div slot="no-results">{{ $t('views.posts.index.noPosts') }}</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMonitor from './WidgetMonitor.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import NumberCard from '../shared/NumberCard.vue'
import { VBTooltip } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import i18n from '../../i18n'
import 'flag-icon-css/css/flag-icon.css'

export default {
  name: 'Index',
  components: { WidgetMonitor, MdSpinner, NumberCard, InfiniteLoading },
  directives: { 'b-tooltip': VBTooltip },
  data: function() {
    const params = new URL(location).searchParams
    const defaultStartDate = moment
      .utc()
      .startOf('day')
      .subtract(30, 'days')
    const defaultEndDate = moment
      .utc()
      .startOf('day')
      .subtract(1, 'days')
    const startDate = params.get('startDate')
      ? moment.utc(params.get('startDate'))
      : moment.utc(defaultStartDate)
    const endDate = params.get('endDate')
      ? moment.utc(params.get('endDate'))
      : moment.utc(defaultEndDate)
    const startDateToCompare = moment.utc(startDate).subtract(1, 'month').startOf('month')
    const endDateToCompare = moment.utc(startDate).subtract(1, 'month').endOf('month').startOf('day')

    const c2bBlogUrl = i18n.locale === 'fr' ? 'https://www.click2buy.com/fr/blog/?utm_source=bbo&utm_medium=cta_bbo' : 'https://www.click2buy.com/blog/?utm_source=bbo&utm_medium=cta_bbo'

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const monthTooltip = `${this.$t('views.posts.index.since')} ${startDate.toDate().toLocaleDateString(i18n.locale, dateOptions)}`

    return {
      defaultStartDate: defaultStartDate,
      defaultEndDate: defaultEndDate,
      dates: {
        main: {
          startDate: startDate,
          endDate: endDate
        },
        compare: {
          startDate: startDateToCompare,
          endDate: endDateToCompare
        }
      },
      analyticsDataLoading: false,
      analyticsDataError: null,
      analyticsData: {
        events: {
          main: [],
          compare: []
        }
      },
      michelinIds: [919, 2973, 2974, 2945, 2947, 3202, 2984, 924, 861, 862, 863, 864, 866, 867, 868, 869, 870, 859, 3201, 2972, 2985, 2948, 2975, 3007, 860, 925, 865], // TODO: check this with an API call? use roles?
      posts: [],
      postsHasNextPage: null,
      postsEndCursor: null,
      postsLoading: false,
      postsError: null,
      groupPosts: [],
      groupPostsHasNextPage: null,
      groupPostsEndCursor: null,
      groupPostsLoading: false,
      groupPostsError: null,
      c2bBlogUrl,
      monthTooltip
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'auth/group',
      roles: 'auth/roles'
    }),
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    demoMode: function() {
      return this.$route.query.demo === 'true'
    },
    userLanguageCode: function() {
      return i18n.languageCode()
    },
    // Get event stats as a hash
    analyticsDataEventsMainHash: function() {
      const hash = {}

      this.analyticsData.events.main.forEach(item => {
        hash[item._id.action] = hash[item._id.action] || {}
        hash[item._id.action][item._id.mediumId] = item.count
      })

      return hash
    },
    analyticsDataEventsCompareHash: function() {
      const hash = {}

      this.analyticsData.events.compare.forEach(item => {
        hash[item._id.action] = hash[item._id.action] || {}
        hash[item._id.action][item._id.mediumId] = item.count
      })

      return hash
    },
    showMonitor: function() {
      return this.michelinIds.includes(this.group.id)
    }
  },
  methods: {
    // Load analytics data
    loadAnalyticsData: function() {
      this.analyticsDataLoading = true
      Promise.all([
        this.loadAnalyticsDataEvents('main'),
        this.loadAnalyticsDataEvents('compare')
      ])
        .then(() => {
          this.analyticsDataLoading = false
        })
    },
    // Load events data
    loadAnalyticsDataEvents: function(dataset) {
      this.analyticsDataError = null

      const body = {
        mediums: ['widget'],
        actions: ['print_button', 'print', 'redirection'],
        start_date: this.dates[dataset].startDate.format('x'),
        end_date: moment.utc(this.dates[dataset].endDate).endOf('day').format('x'),
        interval: 'total',
        group_keys: ['action'],
        group_id: this.group.id
      }

      return fetch('/api/interface/stats/events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          if (!res.ok) {
            this.analyticsDataError = { status: res.status, data }
          } else {
            this.analyticsData.events[dataset] = Object.freeze(data)
          }
        })
    },
    /* Stats */
    // Get hashes for a dataset
    analyticsDataEventsHash: function(dataset) {
      return dataset === 'main' ? this.analyticsDataEventsMainHash : this.analyticsDataEventsCompareHash
    },
    // Count for an action
    actionCount: function(dataset, action) {
      const hash = this.analyticsDataEventsHash(dataset)
      let actionCount = 0
      if (hash[action]) {
        actionCount = Object.values(hash[action]).reduce((acc, count) => acc + count)
      }

      return actionCount
    },
    // Load next page of posts
    loadPostsPage: function({ group = false } = {}) {
      const query = `query postsIndex ($groupId: Int = null, $regionId: Int = null, $first: Int!, $after: String = null) {
        postsConnection(groupId: $groupId, regionId: $regionId, first: $first, after: $after) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            id
            createdAt
            category
            status
            title
            body
            region {
              id
              code
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: group ? this.group.id : undefined,
            regionId: group ? undefined : this.group.region.id,
            first: 10,
            after: this.postsEndCursor
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          if (data.errors) {
            console.error({ status: res.status, data })
          } else {
            if (!group) {
              this.posts = this.posts.concat(data.data.postsConnection.nodes)
              this.postsHasNextPage = data.data.postsConnection.pageInfo.hasNextPage
              this.postsEndCursor = data.data.postsConnection.pageInfo.endCursor
            } else {
              this.groupPosts = this.groupPosts.concat(data.data.postsConnection.nodes)
              this.groupPostsHasNextPage = data.data.postsConnection.pageInfo.hasNextPage
              this.groupPostsEndCursor = data.data.postsConnection.pageInfo.endCursor
            }
          }
        })
    },
    // Delete a post
    deletePost: function(post) {
      if (confirm(this.$t('shared.confirm.delete'))) {
        const variables = {
          input: {
            postId: post.id
          }
        }

        const mutation = `mutation($input: DestroyPostInput!) {
          destroyPost(input: $input) {
            post {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            if (data.data.destroyPost.errors) {
              const text = data.data.createPost.errors
              this.$root.$bvToast.toast(text, {
                variant: 'danger',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            } else {
            // Remove post from vue data
              if (!post.group_id) {
                const index = this.posts.indexOf(post)
                this.posts.splice(index, 1)
              } else {
                const index = this.groupPosts.indexOf(post)
                this.groupPosts.splice(index, 1)
              }

              this.$root.$bvToast.toast(this.$t('shared.success.post.delete'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    },
    infinitePostsHandler($state) {
      this.loadPostsPage().then(() => {
        if (this.postsHasNextPage) {
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    infiniteGroupPostsHandler($state) {
      this.loadPostsPage({ group: true }).then(() => {
        if (this.groupPostsHasNextPage) {
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    dateRange: function(value) {
      return value[0] === value[1] ? value[0] : `${value[0]} - ${value[1]}`
    },
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    },
    date: function(value) {
      if (value) {
        const date = new Date(value)
        return moment(date).format('DD/MM/YYYY')
      }
    }
  },
  mounted: function() {
    this.loadAnalyticsData()
  },
  created: function() {
    /* this.loadPostsPage()
    this.loadPostsPage({ group: true }) */
    document.body.setAttribute('infinite-wrapper', '')
  }
}
</script>

<style lang="scss" scoped>
.btn-linkedin {
  background-color: #204ecf;
  border-color: #204ecf;
  color: #fff;

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 78, 207, 0.25);
  }
}

.post-list-animation-enter-active, .post-list-animation-leave-active {
  transition: opacity 0.5s ease-out;
}

.post-list-animation-enter, .post-list-animation-leave-to {
  opacity: 0;
}
</style>
