<template>
  <th
    v-bind="$attrs"
    v-on="$listeners"
    class="text-nowrap"
    @click="setSort"
    :aria-sort="ariaSort">
    <slot />
  </th>
</template>

<script>
export default {
  props: ['sortKey'],
  computed: {
    sort: function() {
      return this.$route.query.sort === this.sortKey
    },
    sortDirection: function() {
      return this.$route.query.sort_direction
    },
    ariaSort: function() {
      if (!this.sort) {
        return 'none'
      } else if (this.sortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    }
  },
  methods: {
    setSort: function() {
      const query = { ...this.$route.query }

      if (query.sort !== this.sortKey) {
        query.sort = this.sortKey
        query.sort_direction = 'desc'
      } else if (query.sort_direction === 'asc') {
        query.sort_direction = 'desc'
      } else {
        query.sort_direction = 'asc'
      }

      this.$router.push({ query: query })
    }
  }
}
</script>

<style>
th[aria-sort] {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}

th[aria-sort]::after {
  position: absolute;
  display: block;
  bottom: 0;
  right: .35em;
  padding-bottom: inherit;
  font-size: inherit;
  line-height: inherit;
  opacity: .4;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" height="12"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>');
}

th[aria-sort][aria-sort=ascending]:after {
  opacity: 1;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" height="12"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>');
}

th[aria-sort][aria-sort=descending]:after {
  opacity: 1;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" height="12"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>');
}
</style>
