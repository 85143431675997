<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="landingPageLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="landingPageError" class="alert alert-danger">
        <template v-if="landingPageError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ landingPage.name }}</h5>
          <router-link :to="{ name: 'editLandingPage', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <div class="table-responsive">
          <table class="table">
            <tr>
              <th style="width: 20%;">{{ $t('attributes.landingPage.name') }}</th>
              <td>{{ landingPage.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.active') }}</th>
              <td><dot :active="landingPage.active" /></td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.title') }}</th>
              <td>
                <template v-if="landingPage.title">
                  {{ landingPage.title }}
                </template>
                <template v-else>–</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.favicon') }}</th>
              <td>
                <div v-if="landingPage.favicon.url" class="border mb-3 d-inline-block">
                  <img :src="landingPage.favicon.url" class="img-fluid">
                </div>
                <template v-else>–</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.key') }}</th>
              <td><code>{{ landingPage.key }}</code></td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.url') }}</th>
              <td>
                <a :href="landingPage.url" target="_blank">
                  {{ landingPage.url }}
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-external-link-alt fa-w-16"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" class=""></path></svg>
                </a>
              </td>
            </tr>
            <tr v-if="landingPage.customUrl">
              <th>{{ $t('attributes.landingPage.customUrl') }}</th>
              <td>
                <a :href="landingPage.customUrl" target="_blank">
                  {{ landingPage.customUrl }}
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-external-link-alt fa-w-16"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" class=""></path></svg>
                </a>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.landingPage.group') }}</th>
              <td>
                <router-link :to="{ name: 'group', params: { id: landingPage.group.id } }">
                  <region-flag :code="landingPage.group.region.code" />
                  {{ landingPage.group.name }}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import client from '../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, Dot, RegionFlag },
  data: function() {
    return {
      landingPage: null,
      landingPageLoading: false,
      landingPageError: null
    }
  },
  methods: {
    // Load data
    loadData: async function() {
      this.landingPageLoading = true
      this.landingPageError = null

      const query = gql`
        query landingPagesShow ($id: Int!) {
          landingPage(id: $id) {
            id
            name
            key
            active
            title
            favicon
            customUrl
            url
            group {
              id
              name
              active
              region {
                id
                code
              }
            }
          }
        }
      `

      const variables = {
        id: parseInt(this.$route.params.id)
      }

      try {
        const { data } = await client.query({ query, variables })

        this.landingPage = Object.freeze(data.landingPage)
      } catch (error) {
        this.landingPageError = error
      } finally {
        this.landingPageLoading = false
      }
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
