<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="groupLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="groupError" class="alert alert-danger">
        <template v-if="groupError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <h5 class="mb-3">
          <region-flag :code="group.region.code" />
          {{ group.name }}
        </h5>

        <form @submit.prevent="submit">
          <b-tabs content-class="mt-3">
            <!-- Infos tab -->
            <b-tab :title="$t('views.groups.show.tabs.infos')" active>
              <div class="form-group row">
                <label for="input-baseline-validation-rate" class="col-sm-2 text-break col-form-label">{{ $t('attributes.group.baselineValidationRate') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="position-relative">
                    <input v-model="group.baselineValidationRate" type="number" step="0.01" min="0" class="form-control" :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors.baselineValidationRate }" id="input-baseline-validation-rate" :placeholder="$t('attributes.group.baselineValidationRate')" style="padding-right: 2rem;">
                    <span class="percentage-input-icon">%</span>
                  </div>

                  <div v-if="groupSubmitErrors && groupSubmitErrors.baselineValidationRate" class="invalid-feedback">{{ tErrors('group', 'baselineValidationRate', groupSubmitErrors.baselineValidationRate) }}</div>
                </div>
              </div>
            </b-tab>

            <!-- Retailers tab -->
            <b-tab :title="$t('views.groups.show.tabs.retailers')">
              <div class="row">
                <label class="col-sm-2 col-form-label">{{ $t('attributes.group.distributionMethods') }}</label>
                <div class="col-sm-10">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="th-shrink"></th>
                        <th class="th-shrink"></th>
                        <th></th>
                        <th>{{ $t('views.groups.inheritedFrom') }}</th>
                      </tr>
                    </thead>
                    <draggable
                      tag="tbody"
                      v-model="group.groupDeliveryMethods"
                      handle=".handle">
                      <tr
                        v-for="groupDistributionMethod in group.groupDeliveryMethods"
                        :key="groupDistributionMethod.id">
                        <th class="text-center handle cursor-move">
                          <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                        </th>
                        <td>
                          <div class="custom-control custom-switch custom-switch-color">
                            <input
                              v-model="groupDistributionMethod.active"
                              type="checkbox"
                              class="custom-control-input"
                              :id="`group-distribution-method-${groupDistributionMethod.id}-checkbox`"
                              :disabled="groupDistributionMethod.inheritedFrom">
                            <label class="custom-control-label" :for="`group-distribution-method-${groupDistributionMethod.id}-checkbox`"></label>
                          </div>
                        </td>
                        <td>
                          {{ $t(`shared.retailerDistributions.${groupDistributionMethod.deliveryMethod.name}`) }}
                        </td>
                        <td>
                          <ul v-if="groupDistributionMethod.inheritedFrom" class="list-unstyled mb-0">
                            <li v-for="group in groupDistributionMethod.inheritedFrom" :key="group.id">
                              <router-link :to="{ name: 'group', params: { id: group.id } }">
                                <region-flag :code="group.region.code" />
                                {{ group.name }}
                              </router-link>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>

              <!-- Retailer exceptions -->
              <div class="row">
                <label class="col-sm-2 col-form-label">{{ $t('attributes.group.retailerExceptions') }}</label>
                <div class="col-sm-10">
                  <b-tabs content-class="mt-3">
                    <b-tab v-for="distributionMethod in activeDistributionMethods" :key="distributionMethod">
                      <template v-slot:title>
                        {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                        <span class="badge badge-primary">{{ retailerExceptionsCount[distributionMethod] }}</span>
                      </template>

                      <div
                        v-if="retailerExceptionsCount[distributionMethod] === 0"
                        class="alert alert-danger">
                        {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                      </div>

                      <table class="table">
                        <thead>
                          <tr>
                            <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                            <th class="th-shrink"></th>
                            <th class="th-shrink"></th>
                            <th>{{ $t('attributes.retailer.name') }}</th>
                            <th>{{ $t('views.groups.inheritedFrom') }}</th>
                            <th class="th-shrink"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="retailer in retailersWithStatus[distributionMethod]"
                            :key="retailer.id"
                            :class="{ 'table-danger alert-danger': retailer.exception }">
                            <th>{{ retailer.id }}</th>
                            <td><region-flag :code="retailer.region.code" /></td>
                            <td><img :src="retailer.img_small_url" style="max-height: 20px;"></td>
                            <td>{{ retailer.name }}</td>
                            <td>
                              <ul v-if="retailer.inheritedFrom" class="list-unstyled mb-0">
                                <li v-for="group in retailer.inheritedFrom" :key="group.id">
                                  <router-link :to="{ name: 'group', params: { id: group.id } }" class="alert-link">
                                    <region-flag :code="group.region.code" />
                                    {{ group.name }}
                                  </router-link>
                                </li>
                              </ul>
                            </td>
                            <td class="text-right">
                              <template v-if="!retailer.inheritedFrom">
                                <button
                                  v-if="retailer.exception"
                                  type="button"
                                  class="btn btn-sm btn-success text-nowrap"
                                  @click="removeRetailer(retailer)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
                                  {{ $t('shared.actions.enable') }}
                                </button>
                                <button
                                  v-else
                                  type="button"
                                  class="btn btn-sm btn-danger text-nowrap"
                                  @click="addRetailer(retailer)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                                  {{ $t('shared.actions.disable') }}
                                </button>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </b-tab>

            <!-- Custom pages tab -->
            <b-tab :title="$t('views.groups.show.tabs.customPages')">
              <div class="mb-3">
                <button type="button" class="btn btn-success" @click="newCustomPage()">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                  {{ $t('shared.actions.newCustomPage') }}
                </button>
              </div>

              <!-- Custom pages list -->
              <div v-if="group.customPages.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noCustomPage') }}
              </div>
              <table v-else class="table table-hover">
                <thead>
                  <tr>
                    <th class="th-shrink">{{ $t('attributes.customPage.id') }}</th>
                    <th>{{ $t('attributes.customPage.name') }}</th>
                    <th class="th-shrink">{{ $t('attributes.customPage.icon') }}</th>
                    <th class="th-shrink">{{ $t('attributes.customPage.url') }}</th>
                    <th class="th-shrink">{{ $t('attributes.customPage.active') }}</th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="customPage in group.customPages"
                    :key="customPage.id"
                    :class="{ 'table-danger': customPage._destroy }">
                    <th>{{ customPage.id }}</th>
                    <td>{{ customPage.name }}</td>
                    <td class="text-nowrap"><span class="custom-page-icon" v-html="customPage.icon"></span></td>
                    <td>{{ customPage.url }}</td>
                    <td class="text-center"><dot :active="customPage.active" /></td>
                    <td class="text-center">
                      <template v-if="customPageHasError(customPage)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                      </template>
                    </td>
                    <td>
                      <template v-if="!customPage.id">
                        <span class="badge badge-warning">New</span>
                      </template>
                    </td>
                    <td class="text-nowrap">
                      <div class="gutter-x-xs">
                        <button type="button" class="btn btn-sm btn-warning" @click="editCustomPage(customPage)">
                          {{ $t('shared.actions.edit') }}
                        </button>
                        <template v-if="!customPage._destroy">
                          <button type="button" class="btn btn-sm btn-danger" @click="deleteCustomPage(customPage)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-success" @click="restoreCustomPage(customPage)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Custom page modal -->
              <b-modal
                id="customPageModal"
                ref="customPageModal"
                size="xl"
                :title="$t(`shared.actions.${customPageModalAction}CustomPage`)"
                @ok="customPageModalOk"
                @shown="$refs.modalCustomPageNameInput.focus()"
                no-fade>
                <form @submit.stop.prevent="customPageModalSubmit()">
                  <div class="form-group row">
                    <label for="input-custom-page-name" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.name') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <input
                        v-model="customPageModal.name"
                        type="text"
                        class="form-control"
                        id="input-custom-page-name"
                        ref="modalCustomPageNameInput"
                        :placeholder="$t('attributes.customPage.name')"
                        :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].name`] }">
                      <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].name`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'name', groupSubmitErrors[`customPages[${customPageModalIndex}].name`]) }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="input-custom-page-icon" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.icon') }}</label>
                    <div class="col-sm-10 col-12">
                      <textarea
                        v-model="customPageModal.icon"
                        class="form-control"
                        id="input-custom-page-icon"
                        :placeholder="$t('attributes.customPage.icon')"
                        rows="3"
                        :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].icon`] }"></textarea>
                      <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].icon`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'icon', groupSubmitErrors[`customPages[${customPageModalIndex}].icon`]) }}</div>
                      <small class="mt-2 d-block text-muted">{{ $t('attributes.customPage.iconHelp') }}</small>
                      <i18n path="attributes.customPage.iconHelp2" tag="small" class="d-block text-muted">
                        <a href="https://fontawesome.com/search" target="_blank">
                          Font Awesome
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                            class="svg-inline--fa fa-external-link-alt fa-w-16">
                            <path fill="currentColor"
                              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                              class=""></path>
                          </svg>
                        </a>
                      </i18n>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="input-custom-page-url" class="col-sm-2 col-form-label">{{ $t('attributes.customPage.url') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <input
                        v-model="customPageModal.url"
                        type="text"
                        class="form-control"
                        id="input-custom-page-url"
                        :placeholder="$t('attributes.customPage.url')"
                        :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].url`] }">
                      <div v-if="groupSubmitErrors && groupSubmitErrors[`customPages[${customPageModalIndex}].url`]" class="invalid-feedback d-block">{{ tErrors('customPage', 'url', groupSubmitErrors[`customPages[${customPageModalIndex}].url`]) }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="custom-page-active-checkbox" class="col-sm-2">{{ $t('attributes.customPage.active') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="custom-control custom-switch custom-switch-color">
                        <input v-model="customPageModal.active" type="checkbox" class="custom-control-input" id="custom-page-active-checkbox">
                        <label class="custom-control-label" for="custom-page-active-checkbox"></label>
                      </div>
                    </div>
                  </div>
                </form>
              </b-modal>
            </b-tab>

            <!-- Tracking Params tab -->
            <b-tab :title="$t('views.groups.show.tabs.trackingParams')" active>
              <div class="mb-3">
                <button type="button" class="btn btn-success" @click="newTrackingParam()">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                  {{ $t('shared.actions.newTrackingParam') }}
                </button>
              </div>

              <!-- Tracking Params list -->
              <div v-if="group.allTrackingParams.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noTrackingParam') }}
              </div>
              <table v-else class="table table-hover">
                <thead>
                  <tr>
                    <th class="th-shrink">{{ $t('attributes.trackingParam.id') }}</th>
                    <th>{{ $t('attributes.trackingParam.key') }}</th>
                    <th>{{ $t('views.groups.inheritedFrom') }}</th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="trackingParam in trackingParamsSorted"
                    :key="trackingParam.id"
                    :class="{ 'table-danger': trackingParam._destroy }"
                  >
                    <th>{{ trackingParam.id }}</th>
                    <td>{{ trackingParam.key }}</td>
                    <td>
                      <template v-if="trackingParam.group && trackingParam.group.id !== group.id">
                        <router-link
                          :to="{ name: 'group', params: { id: trackingParam.group.id } }"
                          class="alert-link"
                        >
                          <region-flag :code="trackingParam.group.region.code" />
                          {{ trackingParam.group.name }}
                        </router-link>
                      </template>
                    </td>
                    <td class="text-center">
                      <template v-if="trackingParamHasError(trackingParam)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                      </template>
                    </td>
                    <td>
                      <template v-if="!trackingParam.id">
                        <span class="badge badge-warning">New</span>
                      </template>
                    </td>
                    <td class="text-nowrap">
                      <div v-if="!trackingParam.group || trackingParam.group.id === group.id" class="gutter-x-xs">
                        <button type="button" class="btn btn-sm btn-warning" @click="editTrackingParam(trackingParam)">
                          {{ $t('shared.actions.edit') }}
                        </button>
                        <template v-if="!trackingParam._destroy">
                          <button type="button" class="btn btn-sm btn-danger" @click="deleteTrackingParam(trackingParam)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-success" @click="restoreTrackingParam(trackingParam)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Tracking Params modal -->
              <b-modal
                id="trackingParamModal"
                ref="trackingParamModal"
                size="md"
                :title="$t(`shared.actions.${trackingParamModalAction}TrackingParam`)"
                @ok="trackingParamModalOk"
                @shown="$refs.modalTrackingParamInput.focus()"
                no-fade
              >
                <form @submit.stop.prevent="trackingParamModalSubmit()">
                  <div class="form-group row">
                    <label for="input-tracking-param-key" class="col-sm-2 col-form-label">
                      <span
                        v-b-tooltip.hover
                        :title="$t('views.groups.tooltip.trackingParamPrefixes', { prefixes: trackingParamPrefixes.join(', ') })"
                      >
                        {{ $t('attributes.trackingParam.key') }}
                        <sup>
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                        </sup>
                      </span>
                    </label>
                    <div class="col-8">
                      <input
                        v-model="trackingParamModal.key"
                        type="text"
                        class="form-control"
                        id="input-tracking-param-key"
                        ref="modalTrackingParamInput"
                        :placeholder="$t('attributes.trackingParam.key')"
                        :class="{ 'is-invalid' : groupSubmitErrors && groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`] }"
                      >
                      <div v-if="groupSubmitErrors && groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`]" class="invalid-feedback d-block">{{ tErrors('trackingParam', 'key', groupSubmitErrors[`trackingParams[${trackingParamModalIndex}].key`]) }}</div>
                    </div>
                  </div>
                </form>
              </b-modal>
            </b-tab>
          </b-tabs>

          <hr>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="groupSubmitLoading">
              <md-spinner v-if="groupSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              {{ $t('shared.submit.update') }}
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTabs, BTab, BModal, VBTooltip } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTabs, BTab, BModal, draggable },
  directives: { 'b-tooltip': VBTooltip },
  data: function() {
    return {
      group: {
        id: null,
        name: null,
        region: null,
        baselineValidationRate: null,
        groupDeliveryMethods: [],
        retailerExceptionIds: [],
        customPages: [],
        allTrackingParams: []
      },
      groupLoading: false,
      groupError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      groupSubmitLoading: false,
      groupSubmitErrors: null,
      distributionMethods: ['drive', 'delivery', 'store'],
      customPageModalAction: 'new',
      customPageModal: {
        name: null,
        icon: null,
        url: null,
        active: true,
        _destroy: false
      },
      customPageModalRef: null,
      trackingParamModalAction: 'new',
      trackingParamModal: {
        name: null,
        _destroy: false
      },
      trackingParamModalRef: null,
      trackingParamPrefixes: ['utm', 'mtm', 'wt', 'dc', 'hs', 'fb', 'ga', 'sc', 'mkto', 'pm', 'xt', 'rmkt', 'lnkd', 'tw', 'aw', 'bp', 'ig', 'pin', 'snap', 'ad', 'gc', 'gd']
    }
  },
  computed: {
    // Active distribution methods
    activeDistributionMethods: function() {
      return this.group.groupDeliveryMethods
        .filter(groupDeliveryMethod => groupDeliveryMethod.active)
        .map(groupDeliveryMethod => groupDeliveryMethod.deliveryMethod.name)
    },
    // Retailers with exception info
    retailersWithStatus: function() {
      const retailersWithStatus = {}

      this.distributionMethods.forEach(distributionMethod => {
        retailersWithStatus[distributionMethod] = this.retailers.filter(retailer => {
          return retailer.service === distributionMethod &&
            retailer.region.id === this.group.region.id
        }).map(retailer => {
          const retailerWithStatus = { ...retailer }

          const directException = this.group.retailerExceptionIds.includes(retailer.id)
          const ancestorRetailerException = this.group.ancestorsRetailerExceptions.find(ancestorRetailerException => ancestorRetailerException.id === retailer.id)

          retailerWithStatus.exception = directException || !!ancestorRetailerException

          if (ancestorRetailerException) {
            retailerWithStatus.inheritedFrom = ancestorRetailerException.groups
          }

          return retailerWithStatus
        }).sort((a, b) => {
          if ((a.exception && b.exception) || (!a.exception && !b.exception)) {
            return a.name.localeCompare(b.name)
          } else {
            return b.exception - a.exception
          }
        })
      })

      return retailersWithStatus
    },
    // Retailer exceptions count by distribution method
    retailerExceptionsCount: function() {
      const retailerExceptionsCount = {}

      this.distributionMethods.forEach(distributionMethod => {
        retailerExceptionsCount[distributionMethod] = this.retailersWithStatus[distributionMethod].filter(retailer => retailer.exception).length
      })

      return retailerExceptionsCount
    },
    // Get customPageModal index to get errors
    customPageModalIndex: function() {
      return this.group.customPages.indexOf(this.customPageModalRef)
    },
    // Get trackingParamModal index to get errors
    trackingParamModalIndex: function() {
      return this.trackingParamsSorted.indexOf(this.trackingParamModalRef)
    },
    trackingParamsSorted: function() {
      if (!this.group) return []

      // Sort tracking params by group and key
      return this.group.allTrackingParams.slice(0).sort((a, b) => {
        if (!a.group || !b.group) return -1

        if (a.group.id !== this.group.id && b.group.id === this.group.id) {
          return 1
        } else if (a.group.id === this.group.id && b.group.id !== this.group.id) {
          return -1
        } else {
          return a.key.localeCompare(b.key)
        }
      })
    },
    groupTrackingParams: function() {
      return this.group.allTrackingParams.filter(trackingParam => !trackingParam.group || trackingParam.group.id === this.group.id)
    }
  },
  methods: {
    // Load data
    loadData: function({ group = true } = {}) {
      this.retailersLoading = true
      this.retailersError = null
      if (group) {
        this.groupLoading = true
        this.groupError = null
      }

      const query = `query groupsForm ($group: Boolean = true, $groupId: Int = null) {
        group(id: $groupId) @include(if: $group) {
          id
          name
          baselineValidationRate
          region {
            id
            code
          }
          customPages {
            id
            name
            icon
            url
            active
          }
          groupDeliveryMethods {
            id
            active
            orderState
            deliveryMethod {
              id
              name
            }
          }
          ancestorsInactiveDeliveryMethods {
            id
            name
            groups {
              id
              name
              region {
                id
                code
              }
            }
          }
          retailerExceptions {
            id
          }
          ancestorsRetailerExceptions {
            id
            groups {
              id
              name
              region {
                id
                code
              }
            }
          }
          allTrackingParams {
            id
            key
            group {
              id
              name
              region {
                code
              }
            }
          }
        }
        retailers(disabled: false) {
          id
          name
          service
          imgSmallUrl
          region {
            id
            code
          }
        }
      }`

      const variables = {
        group,
        groupId: group ? parseInt(this.$route.params.id) : undefined
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          if (group) this.groupLoading = false

          if (data.errors) {

          } else {
            this.retailers = Object.freeze(data.data.retailers)
            if (group) {
              this.group = {
                ...data.data.group,
                sendReport: true,
                groupDeliveryMethods: data.data.group.groupDeliveryMethods
                  .sort((a, b) => a.orderState - b.orderState)
                  .map(groupDeliveryMethod => {
                    const ancestorInactiveDeliveryMethod = data.data.group.ancestorsInactiveDeliveryMethods.find(deliveryMethod => deliveryMethod.name === groupDeliveryMethod.deliveryMethod.name)

                    groupDeliveryMethod.active = groupDeliveryMethod.active && !ancestorInactiveDeliveryMethod

                    if (ancestorInactiveDeliveryMethod) {
                      groupDeliveryMethod.inheritedFrom = ancestorInactiveDeliveryMethod.groups
                    }

                    return groupDeliveryMethod
                  }),
                retailerExceptionIds: data.data.group.retailerExceptions.map(retailer => retailer.id),
                baselineValidationRate: data.data.group.baselineValidationRate ? (data.data.group.baselineValidationRate * 100).toFixed(2) : null
              }
            }
          }
        })
    },
    // Add retailer to group retailer exceptions
    addRetailer: function(retailer) {
      this.group.retailerExceptionIds.push(retailer.id)
    },
    // Remove retailer from group retailer exceptions
    removeRetailer: function(retailer) {
      const index = this.group.retailerExceptionIds.indexOf(retailer.id)
      this.group.retailerExceptionIds.splice(index, 1)
    },
    // New custom page
    newCustomPage: function() {
      this.customPageModalAction = 'new'
      this.customPageModal = {
        name: null,
        icon: null,
        url: null,
        active: true,
        _destroy: false
      }
      this.$refs.customPageModal.show()
    },
    // Edit custom page
    editCustomPage: function(customPage) {
      this.customPageModalAction = 'edit'
      this.customPageModal = cloneDeep(customPage)
      this.customPageModalRef = customPage
      this.$refs.customPageModal.show()
    },
    // Delete custom page
    deleteCustomPage: function(customPage) {
      if (customPage.id) {
        // If custom page exists in database, set its _destroy to true
        this.$set(customPage, '_destroy', true)
      } else {
        // Otherwise, simply remove array item
        const index = this.group.customPages.indexOf(customPage)
        this.group.customPages.splice(index, 1)
      }
    },
    // Restore custom page
    restoreCustomPage: function(customPage) {
      this.$set(customPage, '_destroy', false)
    },
    // Modal ok click
    customPageModalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.customPageModalSubmit()
    },
    // Modal form submit
    customPageModalSubmit() {
      if (this.customPageModalAction === 'new') {
        this.group.customPages.push({
          name: this.customPageModal.name,
          icon: this.customPageModal.icon,
          url: this.customPageModal.url,
          active: this.customPageModal.active,
          _destroy: this.customPageModal._destroy
        })
      } else {
        this.customPageModalRef.name = this.customPageModal.name
        this.customPageModalRef.icon = this.customPageModal.icon
        this.customPageModalRef.url = this.customPageModal.url
        this.customPageModalRef.active = this.customPageModal.active
      }

      this.$nextTick(() => {
        this.$refs.customPageModal.hide()
      })
    },
    // Check if there is an error for a field of a customPage
    customPageHasError: function(customPage) {
      const index = this.group.customPages.indexOf(customPage)
      if (this.groupSubmitErrors) {
        return Object.keys(this.groupSubmitErrors).some(key => {
          return new RegExp(`customPages\\[${index}\\]`).test(key)
        })
      } else {
        return false
      }
    },
    // New tracking param
    newTrackingParam: function() {
      this.trackingParamModalAction = 'new'
      this.trackingParamModal = {
        key: null,
        _destroy: false
      }
      this.$refs.trackingParamModal.show()
    },
    // Edit tracking param
    editTrackingParam: function(trackingParam) {
      this.trackingParamModalAction = 'edit'
      this.trackingParamModal = cloneDeep(trackingParam)
      this.trackingParamModalRef = trackingParam
      this.$refs.trackingParamModal.show()
    },
    // Delete tracking param
    deleteTrackingParam: function(trackingParam) {
      if (trackingParam.id) {
        // If custom page exists in database, set its _destroy to true
        this.$set(trackingParam, '_destroy', true)
      } else {
        // Otherwise, simply remove array item
        const index = this.group.allTrackingParams.indexOf(trackingParam)
        this.group.allTrackingParams.splice(index, 1)
      }
    },
    // Restore tracking param
    restoreTrackingParam: function(trackingParam) {
      this.$set(trackingParam, '_destroy', false)
    },
    // Trakcing param modal ok click
    trackingParamModalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.trackingParamModalSubmit()
    },
    // Tracking param modal form submit
    trackingParamModalSubmit() {
      if (this.trackingParamModalAction === 'new') {
        this.group.allTrackingParams.push({
          key: this.trackingParamModal.key,
          _destroy: this.trackingParamModal._destroy
        })
      } else {
        this.trackingParamModalRef.key = this.trackingParamModal.key
      }

      this.$nextTick(() => {
        this.$refs.trackingParamModal.hide()
      })
    },
    // Check if there is an error for a param of a trackingParam
    trackingParamHasError: function(trackingParam) {
      const index = this.group.allTrackingParams.indexOf(trackingParam)
      if (this.groupSubmitErrors) {
        return Object.keys(this.groupSubmitErrors).some(key => {
          return new RegExp(`trackingParams\\[${index}\\]`).test(key)
        })
      } else {
        return false
      }
    },
    // Form submit
    submit: function() {
      this.groupSubmitLoading = true
      this.groupSubmitErrors = null

      const variables = {
        input: {
          groupId: this.$route.params.id,
          attributes: {
            groupDeliveryMethodsAttributes: this.group.groupDeliveryMethods.map((groupDeliveryMethod, index) => {
              return {
                id: groupDeliveryMethod.id,
                orderState: index,
                active: groupDeliveryMethod.active
              }
            }),
            retailerExceptionIds: this.group.retailerExceptionIds,
            customPagesAttributes: this.group.customPages.map(customPage => {
              return {
                id: customPage.id,
                name: customPage.name,
                icon: customPage.icon,
                url: customPage.url,
                active: customPage.active,
                _destroy: customPage._destroy
              }
            }),
            trackingParamsAttributes: this.groupTrackingParams.map(trackingParam => {
              return {
                id: trackingParam.id,
                key: trackingParam.key,
                _destroy: trackingParam._destroy
              }
            }),
            baselineValidationRate: this.group.baselineValidationRate ? this.group.baselineValidationRate / 100 : null
          }
        }
      }

      const mutation = `mutation($input: UpdateGroupInput!) {
        updateGroup(input: $input) {
          group {
            id
          }
          errors
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query: mutation, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupSubmitLoading = false

          if (data.data.updateGroup.errors) {
            this.groupSubmitErrors = data.data.updateGroup.errors
          } else {
            this.$router.push({ name: 'group', params: { id: data.data.updateGroup.group.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.group.update'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>

<style>
.percentage-input-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
