import templates from './templates'
import template1ImgUrl from '@/assets/landing-page-template-1.png'
import template2ImgUrl from '@/assets/landing-page-template-2.png'

export default function templateManagerPlugin(editor, options) {
  editor.Panels.addButton('options', {
    id: 'open-templates',
    command: 'open-templates',
    label: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2m0 2l.001 4H5V5zM5 11h8v8H5zm10 8v-8h4.001l.001 8z"/></svg>'
  })

  editor.Commands.add('open-templates', {
    run(editor, sender) {
      const modal = editor.Modal
      const modalContent = document.createElement('div')
      modalContent.innerHTML = `
        <div class="gjs-templates">
          ${templates.map(template => {
            const imgUrl = template.id === '1' ? template1ImgUrl : template2ImgUrl
            return `
              <div class="gjs-template cursor-pointer" data-template-id="${template.id}">
                <img src="${imgUrl}" alt="${template.name}" />
                ${template.name}
              </div>
            `
          }).join('')}
        </div>
      `

      templates.forEach(template => {
        const templateElement = modalContent.querySelector(`.gjs-template[data-template-id="${template.id}"]`)
        templateElement.addEventListener('click', event => {
          event.preventDefault()

          // Load template
          editor.loadProjectData(template.data)

          modal.close()
        })
      })

      modal.open({
        title: 'Templates',
        content: modalContent
      })
    }
  })
}
