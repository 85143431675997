<template>
  <div class="d-flex h-100 align-items-center justify-content-center">
    <form class="auth-form" @submit.prevent="resetPasswordByToken({ password, passwordConfirmation, resetPasswordToken: $route.query.reset_password_token })">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="auth-logo" />
        BRANDS
      </div>

      <input
        v-model="password"
        type="password"
        class="form-control rounded-0 rounded-top"
        :class="{ 'is-invalid' : resetPasswordByTokenError && resetPasswordByTokenError.password }"
        :placeholder="$t('attributes.user.newPassword')"
        required
        autofocus
      >

      <input
        v-model="passwordConfirmation"
        type="password"
        class="form-control rounded-0 rounded-bottom"
        :class="{ 'is-invalid' : resetPasswordByTokenError && resetPasswordByTokenError.passwordConfirmation }"
        :placeholder="$t('attributes.user.newPasswordConfirmation')"
        required
      >

      <div v-if="resetPasswordByTokenError" class="invalid-feedback d-block">
        <div v-for="(error, key) in resetPasswordByTokenError" :key="key">
          {{ tErrors('user', key, error) }}
        </div>
      </div>

      <div class="d-grid mt-3">
        <button class="btn btn-primary btn-block position-relative" type="submit" :disabled="resetPasswordByTokenLoading">
          <div v-if="resetPasswordByTokenLoading" class="position-absolute">
            <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
          </div>
          {{ $t('auth.passwords.editAction') }}
        </button>
      </div>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }">
          {{ $t('auth.links.login') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import C2bLogo from '../shared/C2bLogo.vue'
import { mapGetters, mapActions } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: {
    C2bLogo,
    MdSpinner
  },
  data: function() {
    return {
      password: null,
      passwordConfirmation: null
    }
  },
  computed: {
    ...mapGetters({
      resetPasswordByTokenLoading: 'auth/resetPasswordByTokenLoading',
      resetPasswordByTokenError: 'auth/resetPasswordByTokenError'
    })
  },
  methods: {
    ...mapActions({
      resetPasswordByToken: 'auth/resetPasswordByToken'
    })
  }
}
</script>

<style lang="scss">
@import '../../assets/auth.scss';
</style>
