
<div>
  <transition name="fade" mode="out-in">
    <div v-if="groupApiKeyLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="groupApiKeyError" class="alert alert-danger">
      <template v-if="groupApiKeyError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ groupApiKey.name }}</h5>
        <router-link :to="{ name: 'editGroupApiKey', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <div class="table-responsive">
        <table class="table">
          <tr>
            <th>{{ $t('attributes.groupApiKey.id') }}</th>
            <td>{{ groupApiKey.id }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.name') }}</th>
            <td>{{ groupApiKey.name }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.key') }}</th>
            <td>
              <code>{{ groupApiKey.key }}</code>
              <button
                type="button"
                class="btn btn-light btn-sm"
                id="copy-key-btn"
                v-clipboard:copy="groupApiKey.key"
                v-clipboard:success="onCopy"
                v-b-tooltip.manual.top="$t('shared.actions.copied')">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" class=""></path></svg>
              </button>
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.active') }}</th>
            <td><dot :active="groupApiKey.active" /></td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.apiEndpoint') }}</th>
            <td>{{ groupApiKey.apiEndpoint.name }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.group') }}</th>
            <td>
              <router-link :to="{ name: 'group', params: { id: groupApiKey.group.id } }">
                <region-flag :code="groupApiKey.group.region.code" />
                {{ groupApiKey.group.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.groupApiKey.groupActive') }}</th>
            <td><dot :active="groupApiKey.group.active" /></td>
          </tr>
        </table>
      </div>
    </div>
  </transition>
</div>
