<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// Highcharts modules
import Highcharts from 'highcharts'
import highchartsPieConnectors from './highcharts-pie-connectors'
import noData from 'highcharts/modules/no-data-to-display'
highchartsPieConnectors(Highcharts)
noData(Highcharts)

export default {}
</script>

<style lang="scss">
@import './app.scss';
</style>
