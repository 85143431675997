<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="eventAggregatesError && eventAggregatesError.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Groups selector + daterangepicker -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Group tree select -->
      <div class="flex-fill flex-basis-0">
        <treeselect
          :limit-text="treeSelectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelectOptions"
          :searchable="false"
          style="max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Date range picker -->
      <div class="flex-fill flex-basis-0 text-center">
        <stats-date-range-picker v-model="dateRange" />
      </div>

      <!-- Right zone (empty) -->
      <div class="flex-fill flex-basis-0 text-right"></div>
    </div>

    <!-- Linechart -->
    <div class="mb-3">
      <div class="card">
        <div class="card-body">
          <div class="position-relative">
            <highcharts
              :options="chartOptions"
              class="chart"
              :aria-busy="eventAggregatesLoading"
              style="width: 100%; height: 300px;" />
            <div v-if="groupsLoading || eventAggregatesLoading" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
              <md-spinner md-indeterminate />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Export button -->
    <div class="mb-3 text-right">
      <button @click="exportXLSX" type="button" class="btn btn-primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover-tbody table-vertical-align-top" id="table-stats-landing-pages">
        <thead>
          <tr>
            <th class="cell-md">
              {{ $t('views.stats.landingPages.group') }}
            </th>
            <th>
              {{ $t('views.stats.landingPages.landingPage') }}
            </th>
            <th class="data-cell" v-for="action in landingActions" :key="`landing-${action}`">
              {{ $t(`shared.eventActions.landingPage.${action}`) }}
            </th>
            <th class="cell-md">
              {{ $t('views.stats.landingPages.landingPageCategory') }}
            </th>
            <th class="data-cell" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
              {{ $t(`shared.eventActions.landingCategory.${action}`) }}
            </th>
            <th class="cell-md">
              {{ $t('views.stats.landingPages.widget') }}
            </th>
            <th class="data-cell text-nowrap th-shield" v-for="action in widgetActions" :key="`widget-${action}`">
              {{ $t(`shared.eventActions.widget.${action}`) }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="groupsLoading || eventAggregatesLoading">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="groupsWithLandingPages.length === 0">
          <tr>
            <td :colspan="colspan" class="alert-warning">
              {{ $t('shared.warnings.noMultiproductLandingPage') }}
            </td>
          </tr>
        </tbody>
        <template v-else v-for="group in displayedGroups">
          <!-- Group total -->
          <tbody :key="group.id">
            <tr class="font-weight-semibold bg-light">
              <td
                @click="toggleExpandedGroup(group.id)"
                :class="{ 'cursor-pointer': group.children || group.multiproductLandingPages.length > 0 }">
                <div class="d-flex gutter-sm justify-content-between align-items-center">
                  <div v-if="group.depth > 0">
                    <template v-for="n in group.depth">
                      <svg :key="n" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                    </template>
                  </div>

                  <div class="flex-fill">
                    <region-flag v-if="international" :code="group.region.code" />
                    {{ group.name }}
                  </div>

                  <template v-if="group.children || group.multiproductLandingPages.length > 0">
                    <template v-if="!expandedGroup(group.id)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                    </template>
                  </template>
                </div>
              </td>
              <td>Total</td>
              <td class="text-right" v-for="action in landingActions" :key="`landing-${action}`">
                {{ groupLandingActionCount(group.id, action) | number }}
              </td>
              <td>Total</td>
              <td class="text-right" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
                {{ groupLandingCategoryActionCount(group.id, action) | number }}
              </td>
              <td>Total</td>
              <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
                {{ groupLandingCategoryWidgetActionCount(group.id, action) | number }}
              </td>
            </tr>
          </tbody>

          <!-- Group landing pages -->
          <template v-if="expandedGroup(group.id)">
            <tbody v-for="landingPage in group.multiproductLandingPages" :key="`landing-${landingPage.id}`">
              <template v-for="(category, categoryIndex) in landingPage.multiproductLandingPageCategories">
                <!-- Category row -->
                <tr :key="`category-${category.id}`">
                  <template v-if="categoryIndex === 0">
                    <td :rowspan="landingPageRowspan(landingPage)" class="ellipsis" style="max-width: 150px;">{{ group.name }}</td>
                    <td :rowspan="landingPageRowspan(landingPage)" class="ellipsis" style="max-width: 150px;">{{ landingPage.name }}</td>
                    <td :rowspan="landingPageRowspan(landingPage)" class="text-right" v-for="action in landingActions" :key="`landing-${action}`">
                      {{ mediumActionCount('landing', landingPage.id, action) | number }}
                    </td>
                  </template>
                  <td :rowspan="categoryRowspan(category)" class="ellipsis" style="max-width: 150px;">{{ category.name }}</td>
                  <td :rowspan="categoryRowspan(category)" class="text-right" v-for="action in landingCategoryActions" :key="`landing-category-${action}`">
                    {{ mediumActionCount('landing_category', category.id, action) | number }}
                  </td>
                  <td @click="toggleExpandedCategory(category.id)" class="cursor-pointer">
                    <div class="d-flex gutter-sm justify-content-between align-items-center">
                      <div class="flex-fill">Total</div>

                      <template v-if="!expandedCategoryIdsSet.has(category.id)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                      </template>
                      <template v-else>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                      </template>
                    </div>
                  </td>
                  <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
                    {{ categoryWidgetsActionCount(category, action, landingPage.id) }}
                  </td>
                </tr>
                <!-- Category widgets rows -->
                <template v-if="expandedCategoryIdsSet.has(category.id)">
                  <tr v-for="multiproductLandingPageCategoryWidget in category.multiproductLandingPageCategoryWidgets" :key="`category-${category.id}-widget-${multiproductLandingPageCategoryWidget.widget.id}`">
                    <td class="ellipsis" style="max-width: 150px;">{{ multiproductLandingPageCategoryWidget.widget.name }}</td>
                    <td class="text-right" v-for="action in widgetActions" :key="`widget-${action}`">
                      {{ mediumActionCount('widget', multiproductLandingPageCategoryWidget.widget.id, action, landingPage.id) | number }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import MdSpinner from '../shared/MdSpinner.vue'
import StatsDateRangePicker from '../shared/StatsDateRangePicker.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import Treeselect from '@riophae/vue-treeselect'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'

export default {
  components: { MdSpinner, StatsDateRangePicker, RegionFlag, Treeselect, highcharts: Chart },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      eventAggregates: [],
      eventAggregatesLoading: false,
      eventAggregatesError: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedGroupIds: [],
      expandedGroupIds: [parseInt(this.$route.params.groupId)],
      expandedCategoryIds: [],
      landingActions: ['print'],
      landingCategoryActions: ['print']
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userIsValidationReader: function() {
      return this.roles.includes('validations_reader')
    },
    userCanReadValidations: function() {
      return this.userIsAdmin || this.userIsValidationReader
    },
    // Table colspan
    colspan: function() {
      return 4 + this.landingActions.length + this.landingCategoryActions.length + this.widgetActions.length
    },
    // Widget actions
    widgetActions: function() {
      const widgetActions = ['print', 'redirection']
      if (this.userCanReadValidations) widgetActions.push('validation')
      return widgetActions
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Group helpers
    groupIds: function() {
      return this.groups.map(group => group.id)
    },
    // Groups tree filtered to remove groups without a landing page
    groupsTreeWithLandingPages: function() {
      let groupsTree = []

      // Convert groups array into tree structure
      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })
      }

      // Filter recursively to keep groups with at least one landing page (and its parents)
      const filter = tree => {
        return tree.filter(node => {
          if (node.children && node.children.length > 0) {
            node.children = filter(node.children)
          }
          return (node.children && node.children.length > 0) || node.multiproductLandingPages.length > 0
        })
      }

      const groupsTreeWithLandingPages = filter(groupsTree)

      return groupsTreeWithLandingPages
    },
    // Groups flat array from the groupsTreeWithLandingPages
    groupsWithLandingPages: function() {
      const groups = []

      const recursiveExtractGroups = tree => {
        tree.forEach(node => {
          if (node.children) {
            recursiveExtractGroups(node.children)
          }
          const group = { ...node }
          delete group.children
          groups.push(group)
        })
      }
      recursiveExtractGroups(this.groupsTreeWithLandingPages)

      return groups
    },
    // Format group tree for vue treeselect component
    groupsTreeSelectOptions: function() {
      const recursiveMap = (tree, func) => {
        return tree.map(node => {
          const newNode = func(node)
          if (node.children) newNode.children = recursiveMap(node.children, func)
          return newNode
        })
      }

      const serialize = group => {
        return {
          id: group.id,
          label: {
            name: group.name,
            regionCode: group.region.code
          },
          parent_id: group.parentId,
          multiproduct_landing_pages: group.multiproductLandingPages
        }
      }

      return recursiveMap(this.groupsTreeWithLandingPages, serialize)
    },
    // Used to fastly check if a group is selected (avoid iterating over selectedGroupIds again and again)
    selectedGroupIdsSet: function() {
      return new Set(this.selectedGroupIds)
    },
    // Used to fastly check if a group is expanded (avoid iterating over expandedGroupIds again and again)
    expandedGroupIdsSet: function() {
      return new Set(this.expandedGroupIds)
    },
    // Used to fastly check if a category is expanded (avoid iterating over expandedCategoryIds again and again)
    expandedCategoryIdsSet: function() {
      return new Set(this.expandedCategoryIds)
    },
    // Get selected groups
    selectedGroups: function() {
      return this.groups.filter(group => this.selectedGroupIdsSet.has(group.id))
    },
    // Get groups to display (= selected & expanded & parents expanded) & add depth property
    displayedGroups: function() {
      const groups = []

      const appendGroup = (group, depth) => {
        if (this.selectedGroupIdsSet.has(group.id)) {
          group.depth = depth
          groups.push(group)
        }
        if (this.expandedGroup(group.id) && group.children) {
          group.children.map(group => appendGroup(group, depth + 1))
        }
      }
      appendGroup(this.groupsTreeWithLandingPages[0], 0)
      return groups
    },
    selectedLandingPages: function() {
      return this.selectedGroups.map(group => group.multiproductLandingPages).flat(Infinity)
    },
    selectedLandingPageIds: function() {
      return this.selectedLandingPages.map(landingPage => landingPage.id)
    },
    selectedLandingCategoryIds: function() {
      return this.selectedLandingPages.map(landingPage => landingPage.multiproductLandingPageCategories.map(category => category.id)).flat(Infinity)
    },
    selectedWidgetIds: function() {
      return this.selectedLandingPages.map(landingPage => {
        return landingPage.multiproductLandingPageCategories.map(category => {
          return category.multiproductLandingPageCategoryWidgets.map(multiproductLandingPageCategoryWidget => multiproductLandingPageCategoryWidget.widget.id)
        }).flat(Infinity)
      }).flat(Infinity)
    },
    // Get {group & descendants) landing pages (precomputed Map)
    groupLandingPagesMap: function() {
      const groupLandingPagesMap = new Map()

      // Explore group tree recursively and collect landing pages
      const exploreTree = group => {
        if (group.children) {
          const landingPages = [...group.multiproductLandingPages]

          group.children.forEach(child => {
            exploreTree(child)

            landingPages.push(...groupLandingPagesMap.get(child.id))
          })

          groupLandingPagesMap.set(group.id, landingPages)
        } else {
          groupLandingPagesMap.set(group.id, group.multiproductLandingPages)
        }
      }

      exploreTree(this.groupsTreeWithLandingPages[0])

      return groupLandingPagesMap
    },
    // Get {group & descendants) landing categories (precomputed Map)
    groupLandingCategoriesMap: function() {
      const groupLandingCategoriesMap = new Map()

      // Explore group tree recursively and collect landing categories
      const exploreTree = group => {
        if (group.children) {
          const landingCategories = [...group.multiproductLandingPages.map(landing => landing.multiproductLandingPageCategories).flat(Infinity)]

          group.children.forEach(child => {
            exploreTree(child)

            landingCategories.push(...groupLandingCategoriesMap.get(child.id))
          })

          groupLandingCategoriesMap.set(group.id, landingCategories)
        } else {
          groupLandingCategoriesMap.set(group.id, group.multiproductLandingPages.map(landing => landing.multiproductLandingPageCategories).flat(Infinity))
        }
      }

      exploreTree(this.groupsTreeWithLandingPages[0])

      return groupLandingCategoriesMap
    },
    // Convert daily event stats into total values as a hash
    eventAggregatesHash: function() {
      return this.eventAggregates.reduce((acc, dailyItem) => {
        dailyItem.data.forEach(item => {
          acc[item.medium] = acc[item.medium] || {}
          acc[item.medium][item.action] = acc[item.medium][item.action] || {}
          if (item.medium === 'widget') {
            acc[item.medium][item.action][item.mediumId] = acc[item.medium][item.action][item.mediumId] || {}
            acc[item.medium][item.action][item.mediumId][item.mlpId] = acc[item.medium][item.action][item.mediumId][item.mlpId] || 0
            acc[item.medium][item.action][item.mediumId][item.mlpId] += item.count
          } else {
            acc[item.medium][item.action][item.mediumId] = acc[item.medium][item.action][item.mediumId] || 0
            acc[item.medium][item.action][item.mediumId] += item.count
          }
        })
        return acc
      }, {})
    },
    // Chart
    chartOptions: function() {
      const chartOptions = {
        chart: { type: 'line' },
        xAxis: [{
          type: 'datetime',
          id: 'x1'
        }, {
          type: 'datetime',
          id: 'x2',
          opposite: true
        }],
        yAxis: {
          title: null,
          min: 0
        },
        title: null,
        credits: { enabled: false },
        series: []
      }

      this.landingActions.forEach(action => {
        chartOptions.series.push({
          name: `${this.$t('views.stats.landingPages.landingPage')} - ${this.$t(`shared.eventActions.landingPage.${action}`)}`,
          data: this.actionTimeSerie('landing', action),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' }
        })
      })

      this.landingCategoryActions.forEach(action => {
        chartOptions.series.push({
          name: `${this.$t('views.stats.landingPages.landingPageCategory')} - ${this.$t(`shared.eventActions.landingCategory.${action}`)}`,
          data: this.actionTimeSerie('landing_category', action),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' }
        })
      })

      this.widgetActions.forEach(action => {
        chartOptions.series.push({
          name: `${this.$t('views.stats.landingPages.widget')} - ${this.$t(`shared.eventActions.widget.${action}`)}`,
          data: this.actionTimeSerie('widget', action),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' }
        })
      })

      return chartOptions
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Treeselect text limit formatter
    treeSelectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    // Load groups
    loadGroups: function() {
      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsLandingPagesGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
          multiproductLandingPages {
            id
            name
            multiproductLandingPageCategories {
              id
              name
              multiproductLandingPageCategoryWidgets {
                widget {
                  id
                  name
                }
              }
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { id: parseInt(this.$route.params.groupId) }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false
          if (!res.ok) {
            this.groupsError = { status: res.status, data }
          } else {
            this.groups = Object.freeze(data.data.groups)

            // Init group treeselect value
            this.selectedGroupIds = this.groups.map(group => group.id)
          }
        })
    },
    // Update analytics data when the date range has changed
    loadAnalyticsData: function() {
      return this.loadAnalyticsDataEvents()
    },
    // Update events data
    loadAnalyticsDataEvents: async function() {
      this.eventAggregatesLoading = true
      this.eventAggregatesError = null

      const baseBody = {
        start_date: moment.utc(this.dateRange.startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRange.endDate).endOf('day').format('x'),
        interval: 'daily',
        group_id: this.$route.params.groupId
      }

      const loadLandingData = async () => {
        const body = {
          mediums: ['landing'],
          actions: this.landingActions,
          group_keys: ['action', 'mediumId'],
          ...baseBody
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadLandingCategoryData = async () => {
        const body = {
          mediums: ['landing_category'],
          actions: this.landingCategoryActions,
          group_keys: ['action', 'mediumId'],
          ...baseBody
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadWidgetData = async () => {
        const body = {
          mediums: ['landing_widget'],
          actions: ['print', 'redirection', 'validation'],
          group_keys: ['action', 'mediumId', 'mlpId'],
          ...baseBody
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [landingData, landingCategoryData, widgetData] = await Promise.all([loadLandingData(), loadLandingCategoryData(), loadWidgetData()])

        landingData.forEach(dailyItem => {
          dailyItem.data.forEach(item => {
            item.medium = 'landing'
          })
        })

        landingCategoryData.forEach(dailyItem => {
          // Add medium on events
          dailyItem.data.forEach(item => {
            item.medium = 'landing_category'
          })

          // Inject into landing events
          const eventsDailyItem = landingData.find(event => {
            return event._id.year === dailyItem._id.year && event._id.month === dailyItem._id.month && event._id.day === dailyItem._id.day
          })

          if (eventsDailyItem) {
            eventsDailyItem.data = eventsDailyItem.data.concat(dailyItem.data)
          } else {
            landingData.push(dailyItem)
          }
        })

        widgetData.forEach(dailyItem => {
          dailyItem.data.forEach(item => {
            // Add medium on events
            item.medium = 'widget'
          })

          // Inject into landing events
          const eventsDailyItem = landingData.find(event => {
            return event._id.year === dailyItem._id.year && event._id.month === dailyItem._id.month && event._id.day === dailyItem._id.day
          })

          if (eventsDailyItem) {
            eventsDailyItem.data = eventsDailyItem.data.concat(dailyItem.data)
          } else {
            landingData.push(dailyItem)
          }
        })

        // Set events in store
        this.eventAggregates = Object.freeze(landingData)
      } catch (err) {
        this.eventAggregatesError = err
      } finally {
        this.eventAggregatesLoading = false
      }
    },
    // Check if a group is expanded
    expandedGroup: function(groupId) {
      return this.expandedGroupIdsSet.has(groupId)
    },
    // Expand a group
    toggleExpandedGroup: function(groupId) {
      if (this.expandedGroupIdsSet.has(groupId)) {
        this.expandedGroupIds = this.expandedGroupIds.filter(id => id !== groupId)
      } else {
        this.expandedGroupIds.push(groupId)
      }
    },
    // Expand a category
    toggleExpandedCategory: function(categoryId) {
      if (this.expandedCategoryIdsSet.has(categoryId)) {
        this.expandedCategoryIds = this.expandedCategoryIds.filter(id => id !== categoryId)
      } else {
        this.expandedCategoryIds.push(categoryId)
      }
    },
    landingPageRowspan: function(landingPage) {
      return landingPage.multiproductLandingPageCategories.map(category => {
        return this.categoryRowspan(category)
      }).reduce((pv, cv) => pv + cv, 0)
    },
    categoryRowspan: function(category) {
      return this.expandedCategoryIdsSet.has(category.id) ? category.multiproductLandingPageCategoryWidgets.length + 1 : 1
    },
    // Export table as XLSX
    exportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_landing_pages_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('#table-stats-landing-pages'))
      XLSX.writeFile(wb, filename)
    },

    /* Stats */
    // Count for a medium and action
    mediumActionCount: function(medium, mediumId, action, mlpId) {
      const hash = this.eventAggregatesHash
      if (medium !== 'widget') {
        return (hash[medium] && hash[medium][action] && hash[medium][action][mediumId]) ? hash[medium][action][mediumId] : 0
      } else {
        return (hash[medium] && hash[medium][action] && hash[medium][action][mediumId] && hash[medium][action][mediumId][mlpId]) ? hash[medium][action][mediumId][mlpId] : 0
      }
    },
    // Count for a group landings and action
    groupLandingActionCount: function(groupId, action) {
      let groupLandingActionCount = 0
      this.groupLandingPagesMap.get(groupId).forEach(landingPage => {
        groupLandingActionCount += this.mediumActionCount('landing', landingPage.id, action)
      })
      return groupLandingActionCount
    },
    // Count for a group landing categories and action
    groupLandingCategoryActionCount: function(groupId, action) {
      let groupLandingCategoryActionCount = 0
      this.groupLandingCategoriesMap.get(groupId).forEach(category => {
        groupLandingCategoryActionCount += this.mediumActionCount('landing_category', category.id, action)
      })
      return groupLandingCategoryActionCount
    },
    // Count for category widgets and action
    groupLandingCategoryWidgetActionCount: function(groupId, action) {
      let groupLandingCategoryWidgetActionCount = 0
      this.groupLandingPagesMap.get(groupId).forEach(landingPage => {
        landingPage.multiproductLandingPageCategories.forEach(category => {
          groupLandingCategoryWidgetActionCount += this.categoryWidgetsActionCount(category, action, landingPage.id)
        })
      })
      return groupLandingCategoryWidgetActionCount
    },
    // Count for a group landing category widgets and action
    categoryWidgetsActionCount: function(category, action, mlpId) {
      let categoryWidgetsActionCount = 0
      category.multiproductLandingPageCategoryWidgets.forEach(multiproductLandingPageCategoryWidget => {
        categoryWidgetsActionCount += this.mediumActionCount('widget', multiproductLandingPageCategoryWidget.widget.id, action, mlpId)
      })
      return categoryWidgetsActionCount
    },
    // Time serie for a medium and action
    actionTimeSerie: function(medium, action) {
      let mediumIds

      switch (medium) {
        case 'landing':
          mediumIds = this.selectedLandingPageIds
          break
        case 'landing_category':
          mediumIds = this.selectedLandingCategoryIds
          break
        case 'widget':
          mediumIds = this.selectedWidgetIds
          break
      }

      return this.eventAggregates.map(dailyItem => {
        return [
          Date.UTC(dailyItem._id.year, dailyItem._id.month - 1, dailyItem._id.day),
          dailyItem.data.filter(item => item.medium === medium && item.action === action && mediumIds.includes(item.mediumId)).reduce((acc, item) => acc + item.count, 0)
        ]
      }).sort((a, b) => a[0] - b[0])
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    }
  },
  watch: {
    // Update analytics data when date range changes
    dateRange: function(dateRange) {
      this.loadAnalyticsData()
    },
    // Select all groups with landing pages
    groupsWithLandingPages: function(groups) {
      this.selectedGroupIds = groups.map(group => group.id)
    }
  },
  created: function() {
    this.loadGroups()
  }
}
</script>
