<template>
  <span class="badge badge-light">
    {{ language.code }}-{{ language.region.code }}
  </span>
</template>

<script>
export default {
  props: ['language']
}
</script>
