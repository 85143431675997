
<div>
  <transition name="fade" mode="out-in">
    <div v-if="userLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="userError" class="alert alert-danger">
      <template v-if="userError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ user.email }}</h5>
        <router-link :to="{ name: 'editUser', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <div class="table-responsive">
        <table class="table">
          <tr>
            <th>{{ $t('attributes.user.id') }}</th>
            <td>{{ user.id }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.email') }}</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.roles') }}</th>
            <td class="gutter-xs">
              <span class="badge badge-secondary" v-for="role in user.roles" :key="role">
                {{ role }}
              </span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.active') }}</th>
            <td>
              <dot :active="user.active" />
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.group') }}</th>
            <td>
              <router-link :to="{ name: 'group', params: { id: user.group.id } }">
                <region-flag :code="user.group.region.code" />
                {{ user.group.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.locale') }}</th>
            <td>{{ languageName(user.locale, user.locale) }}</td>
          </tr>
          <tr>
            <th>{{ $t('attributes.user.unauthorizedGroups') }}</th>
            <td>
              <ul v-if="user.unauthorizedGroups" class="list-unstyled mb-0">
                <li v-for="group in user.unauthorizedGroups" :key="group.id">
                  <router-link :to="{ name: 'group', params: { id: group.id } }">
                    <region-flag :code="group.region.code" />
                    {{ group.name }}
                  </router-link>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </transition>
</div>
