<template>
  <li class="nav-item">
    <router-link :to="path" :exact="path === '/'" class="nav-link" :class="activeClass">
      <div class="d-flex align-items-center gutter-sm">
        <slot></slot>
      </div>
    </router-link>
  </li>
</template>

<script>
// https://github.com/vuejs/vue-router/blob/dev/src/util/route.js#L115
const trailingSlashRE = /\/?$/

function isIncludedRoute(current, target) {
  return (
    current.path.replace(trailingSlashRE, '/').indexOf(
      target.path.replace(trailingSlashRE, '/')
    ) === 0
  )
}

export default {
  props: ['path', 'additionalActivePaths', 'exceptionActivePaths'],
  computed: {
    // By default the router-link will be active if the `to` prop matches the route
    // We reuse the same code (copy/paste since not exported...)
    // Prop `additionalActivePaths` allows to provide additional paths that trigger the active class
    // Prop `exceptionActivePaths` allows to provide paths that will not trigger the active class
    activeClass: function() {
      // Basic vue router-link active check
      let active = isIncludedRoute(this.$route, this.$router.resolve(this.path).route)

      // Additional active paths
      if (this.additionalActivePaths &&
          this.additionalActivePaths.some(path => isIncludedRoute(this.$route, this.$router.resolve(path).route))) {
        active = true
      }

      // Exception paths
      if (this.exceptionActivePaths &&
          this.exceptionActivePaths.some(path => isIncludedRoute(this.$route, this.$router.resolve(path).route))) {
        active = false
      }

      return active ? 'active' : null
    }
  }
}
</script>
