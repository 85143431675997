
<div>
  <transition name="fade" mode="out-in">
    <div v-if="widgetLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="widgetError" class="alert alert-danger">
      <template v-if="widgetError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ widget.name }}</h5>
        <router-link :to="{ name: 'editWidget', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <!-- Tabs -->
      <b-tabs content-class="mt-3">
        <!-- Infos -->
        <b-tab :title="$t('views.widgets.show.infos')" active>
          <div class="d-flex flex-wrap gutter">
            <div class="flex-fill">
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <th>{{ $t('attributes.widget.id') }}</th>
                    <td>{{ widget.id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.key') }}</th>
                    <td><code>{{ widget.key }}</code></td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.url') }}</th>
                    <td>
                      <a :href="widgetUrl" target="_blank">{{ widgetUrl }}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.name') }}</th>
                    <td>{{ widget.name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.publicName') }}</th>
                    <td>{{ widget.publicName }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.active') }}</th>
                    <td>
                      <dot :active="widget.active" />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.group') }}</th>
                    <td>
                      <router-link :to="{ name: 'group', params: { id: widget.group.id } }">
                        <region-flag :code="widget.group.region.code" />
                        {{ widget.group.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.groupActive') }}</th>
                    <td>
                      <dot :active="widget.group.active" />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.product') }}</th>
                    <td>
                      <router-link :to="{ name: 'product', params: { id: widget.product.id } }">
                        {{ widget.product.productLanguageDatas[0].name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.productActive') }}</th>
                    <td>
                      <dot :active="widget.product.active" />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.productGroup') }}</th>
                    <td>
                      <router-link :to="{ name: 'group', params: { id: widget.product.group.id } }">
                        <region-flag :code="widget.product.group.region.code" />
                        {{ widget.product.group.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('attributes.widget.productGroupActive') }}</th>
                    <td>
                      <dot :active="widget.product.group.active" />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <a :href="widget.product.img.medium.url" target="_blank">
                <img :src="widget.product.img.medium.url" class="border">
              </a>
            </div>
          </div>
        </b-tab>

        <!-- Retailers -->
        <b-tab :title="$t('views.widgets.show.retailers')">
          <!-- Retailer exceptions -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ $t('attributes.widget.retailerExceptions') }}</label>
            <div class="col-sm-10 col-12">
              <template v-if="retailersLoading">
                <div class="text-center">
                  <md-spinner md-indeterminate></md-spinner>
                </div>
              </template>
              <template v-else>
                <b-tabs content-class="mt-3">
                  <b-tab v-for="distributionMethod in distributionMethods" :key="distributionMethod">
                    <template v-slot:title>
                      {{ $t(`shared.retailerDistributions.${distributionMethod}`) }}
                      <span class="badge badge-primary">
                        {{ retailerExceptionsCount[distributionMethod] }}
                      </span>
                    </template>

                    <div
                      v-if="retailerExceptionsCount[distributionMethod] === 0"
                      class="alert alert-danger">
                      {{ $t('views.groups.show.warning.noRetailerDisabled') }}
                    </div>

                    <table class="table">
                      <thead>
                        <tr>
                          <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                          <th class="th-shrink"></th>
                          <th class="th-shrink"></th>
                          <th>{{ $t('attributes.retailer.name') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="retailer in retailersWithStatus[distributionMethod]"
                          :key="retailer.id"
                          :class="{ 'table-danger alert-danger': retailer.exception }">
                          <th>{{ retailer.id }}</th>
                          <td><region-flag :code="retailer.region.code" /></td>
                          <td><img :src="retailer.img_small_url" style="max-height: 20px;"></td>
                          <td>{{ retailer.name }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-tab>
                </b-tabs>
              </template>
            </div>
          </div>
        </b-tab>

        <!-- Alternative widgets -->
        <b-tab :title="$t('views.widgets.show.alternativeWidgets')">
          <div v-if="widget.alternativeWidgetsWidgets.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noAlternativeWidget') }}
          </div>
          <div v-else class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.widget.id') }}</th>
                  <th>{{ $t('attributes.widget.name') }}</th>
                </tr>
              </thead>
              <tbody
                v-for="alternativeWidgetsWidget in widget.alternativeWidgetsWidgets"
                :key="alternativeWidgetsWidget.id">
                <tr>
                  <th>{{ alternativeWidgetsWidget.alternativeWidget.id }}</th>
                  <td>
                    <router-link :to="{ name: 'widget', params: { id: alternativeWidgetsWidget.alternativeWidget.id } }">
                      {{ alternativeWidgetsWidget.alternativeWidget.name }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </transition>
</div>
