<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="popinImplementationLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="popinImplementationError" class="alert alert-danger">
        <template v-if="popinImplementationError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>

        <form @submit.prevent="submit">
          <persistent-tabs content-class="mt-3">
            <!-- Infos tab -->
            <b-tab :title="$t('views.popinImplementations.tabs.infos')">
              <div class="form-group row">
                <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.name') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="popinImplementation.name" type="text" class="form-control" :class="{ 'is-invalid' : popinImplementationSubmitErrors && popinImplementationSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.popinImplementation.name')">
                  <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors.name" class="invalid-feedback">{{ tErrors('popinImplementation', 'name', popinImplementationSubmitErrors.name) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.group') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <group-modal-select
                    v-model="popinImplementation.group"
                    :class="{ 'is-invalid' : popinImplementationSubmitErrors && popinImplementationSubmitErrors.group }"
                    id="multiselect-group" />
                  <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('popinImplementation', 'group', popinImplementationSubmitErrors.group) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.popinImplementation.active') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="popinImplementation.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                    <label class="custom-control-label" for="active-checkbox"></label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="auto-migration-checkbox" class="col-sm-2">{{ $t('attributes.popinImplementation.autoMigration') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="custom-control custom-switch custom-switch-color">
                    <input v-model="popinImplementation.autoMigration" type="checkbox" class="custom-control-input" id="auto-migration-checkbox">
                    <label class="custom-control-label" for="auto-migration-checkbox"></label>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-- Popins tab -->
            <b-tab>
              <template v-slot:title>
                {{ $t('views.popinImplementations.tabs.popins') }}
                <span class="badge badge-primary">{{ popinImplementation.popins.length }}</span>
              </template>

              <div class="mb-3">
                <button type="button" class="btn btn-success" @click="newPopin()">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                  {{ $t('shared.actions.newPopin') }}
                </button>
              </div>

              <!-- Popins list -->
              <div v-if="popinImplementation.popins.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noPopin') }}
              </div>
              <table v-else class="table table-hover">
                <thead>
                  <tr>
                    <th class="th-shrink">{{ $t('attributes.popin.id') }}</th>
                    <th>{{ $t('attributes.popin.name') }}</th>
                    <th class="th-shrink">{{ $t('attributes.popin.key') }}</th>
                    <th class="th-shrink">{{ $t('attributes.popin.active') }}</th>
                    <th class="th-shrink">{{ $t('attributes.popin.widgets') }}</th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="popin in popinImplementation.popins"
                    :key="popin.id"
                    :class="{ 'table-danger': popin._destroy }">
                    <th>{{ popin.id }}</th>
                    <td>{{ popin.name }}</td>
                    <td class="text-nowrap"><code>{{ popin.key }}</code></td>
                    <td class="text-center"><dot :active="popin.active" /></td>
                    <td class="text-center">{{ popin.popinWidgets.length }}</td>
                    <td class="text-center">
                      <template v-if="popinHasError(popin)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                      </template>
                    </td>
                    <td>
                      <template v-if="!popin.id">
                        <span class="badge badge-warning">New</span>
                      </template>
                    </td>
                    <td class="text-nowrap">
                      <div class="gutter-x-xs">
                        <button type="button" class="btn btn-sm btn-warning" @click="editPopin(popin)">
                          {{ $t('shared.actions.edit') }}
                        </button>
                        <template v-if="!popin._destroy">
                          <button type="button" class="btn btn-sm btn-danger" @click="deletePopin(popin)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-success" @click="restorePopin(popin)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Popin modal -->
              <b-modal
                id="popinModal"
                ref="popinModal"
                size="xl"
                :title="$t(`shared.actions.${popinModalAction}Popin`)"
                @ok="popinModalOk"
                @shown="$refs.modalPopinNameInput.focus()"
                no-fade>
                <form @submit.stop.prevent="popinModalSubmit()">
                  <div class="form-group row">
                    <label for="input-popin-name" class="col-sm-2 col-form-label">{{ $t('attributes.popin.name') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <input
                        v-model="popinModal.name"
                        type="text"
                        class="form-control"
                        id="input-popin-name"
                        ref="modalPopinNameInput"
                        :placeholder="$t('attributes.popin.name')"
                        :class="{ 'is-invalid' : popinImplementationSubmitErrors && popinImplementationSubmitErrors[`popins[${popinModalIndex}].name`] }">
                      <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors[`popins[${popinModalIndex}].name`]" class="invalid-feedback d-block">{{ tErrors('popin', 'name', popinImplementationSubmitErrors[`popins[${popinModalIndex}].name`]) }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="input-popin-key" class="col-sm-2 col-form-label">{{ $t('attributes.popin.key') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <input
                        v-model="popinModal.key"
                        type="text"
                        class="form-control"
                        id="input-popin-key"
                        :placeholder="$t('attributes.popin.key')"
                        :class="{ 'is-invalid' : popinImplementationSubmitErrors && popinImplementationSubmitErrors[`popins[${popinModalIndex}].key`] }">
                      <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors[`popins[${popinModalIndex}].key`]" class="invalid-feedback d-block">{{ tErrors('popin', 'key', popinImplementationSubmitErrors[`popins[${popinModalIndex}].key`]) }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="popin-active-checkbox" class="col-sm-2">{{ $t('attributes.popin.active') }}</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="custom-control custom-switch custom-switch-color">
                        <input v-model="popinModal.active" type="checkbox" class="custom-control-input" id="popin-active-checkbox">
                        <label class="custom-control-label" for="popin-active-checkbox"></label>
                      </div>
                    </div>
                  </div>

                  <!-- Popin widgets -->
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">{{ $t('attributes.popin.widgets') }}</label>
                    <div class="col-sm-10 col-12">
                      <multiselect
                        @input="addWidget"
                        :options="availableWidgets"
                        :custom-label="widgetLabel"
                        :allow-empty="false"
                        :clear-on-select="false"
                        :placeholder="$t('shared.placeholders.select')"
                        :close-on-select="false"
                        :loading="widgetsLoading"
                        class="mb-3">
                        <template slot="option" slot-scope="props">
                          <div class="d-table">
                            <div class="d-table-cell" style="padding-right: 10px;">#{{ props.option.id }}</div>
                            <div class="d-table-cell" style="padding-right: 10px;">
                              <template v-if="props.option.product.ean !== ''">
                                EAN {{ props.option.product.ean }}
                              </template>
                            </div>
                            <div class="d-table-cell">{{ props.option.name }}</div>
                          </div>
                        </template>
                      </multiselect>

                      <div v-if="popinModal.popinWidgets.length === 0" class="alert alert-warning" role="alert">
                        {{ $t('shared.warnings.noWidget') }}
                      </div>
                      <table
                        class="table table-hover"
                        v-else>
                        <draggable
                          tag="tbody"
                          v-model="popinModal.popinWidgets"
                          handle=".handle">
                          <tr
                            v-for="popinWidget in popinModal.popinWidgets"
                            :key="popinWidget.widget.id"
                            class="table-col-shrink"
                            :class="{ 'table-danger': popinWidget._destroy }">
                            <th class="text-center handle cursor-move">
                              <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                            </th>
                            <th class="table-col-shrink">{{ popinWidget.widget.id }}</th>
                            <td>
                              <table
                                v-if="widgetsExpanded[popinWidget.widget.id]"
                                class="table m-n2">
                                <tr>
                                  <th class="text-nowrap th-shrink">{{ $t('attributes.widget.name') }}</th>
                                  <td>{{ popinWidget.widget.name }}</td>
                                </tr>
                                <tr>
                                  <th class="text-nowrap th-shrink">{{ $t('attributes.widget.publicName') }}</th>
                                  <td>{{ popinWidget.widget.publicName }}</td>
                                </tr>
                                <tr>
                                  <th class="text-nowrap th-shrink">{{ $t('attributes.popinWidget.labelHtml') }}</th>
                                  <td>
                                    <AceEditor
                                      :style="{ height: '8vh' }"
                                      v-model="popinWidget.labelHtml"
                                      lang="html"
                                      :options="editorOptions"
                                    ></AceEditor>
                                  </td>
                                </tr>
                              </table>
                              <template v-else>
                                {{ popinWidget.widget.name }}
                              </template>
                            </td>
                            <td class="table-col-shrink">
                              <button type="button" class="btn btn-secondary btn-sm" @click="toggleWidgetExpanded(popinWidget.widget.id)">
                                <template v-if="!widgetsExpanded[popinWidget.widget.id]">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                                </template>
                                <template v-else>
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                                </template>
                              </button>
                            </td>
                            <td class="table-col-shrink">
                              <template v-if="!popinWidget.id">
                                <span class="badge badge-warning">New</span>
                              </template>
                            </td>
                            <td class="text-nowrap table-col-shrink">
                              <template v-if="!popinWidget._destroy">
                                <button type="button" class="btn btn-sm btn-danger" @click="removeWidget(popinWidget)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                                  {{ $t('shared.actions.delete') }}
                                </button>
                              </template>
                              <template v-else>
                                <button type="button" class="btn btn-sm btn-success" @click="restoreWidget(popinWidget)">
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                                  {{ $t('shared.actions.restore') }}
                                </button>
                              </template>
                            </td>
                          </tr>
                        </draggable>
                      </table>
                    </div>
                  </div>
                </form>
              </b-modal>
            </b-tab>

            <!-- Style tab -->
            <b-tab :title="$t('views.popinImplementations.tabs.style')">
              <div class="form-group row">
                <label for="input-customisation-button-background-color" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.customisation.button_background_color') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="popinImplementation.customisation['button-background-color']" type="text" class="form-control" id="input-customisation-button-background-color" :placeholder="$t('attributes.popinImplementation.customisation.button_background_color')">
                </div>
              </div>

              <div class="form-group row">
                <label for="input-customisation-button-text-color" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.customisation.button_text_color') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="popinImplementation.customisation['button-text-color']" type="text" class="form-control" id="input-customisation-button-text-color" :placeholder="$t('attributes.popinImplementation.customisation.button_text_color')">
                </div>
              </div>

              <div class="form-group row">
                <label for="input-customisation-button-cart-color" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.customisation.button_cart_color') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="popinImplementation.customisation['button-cart-color']" type="cart" class="form-control" id="input-customisation-button-cart-color" :placeholder="$t('attributes.popinImplementation.customisation.button_cart_color')">
                </div>
              </div>

              <div class="form-group row">
                <label for="input-cta-text" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.ctaText') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input v-model="popinImplementation.ctaText" type="text" class="form-control" :class="{ 'is-invalid' : popinImplementationSubmitErrors && popinImplementationSubmitErrors.ctaText }" id="input-cta-text" :placeholder="$t('attributes.popinImplementation.ctaText')">
                  <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors.ctaText" class="invalid-feedback">{{ tErrors('popinImplementation', 'ctaText', popinImplementationSubmitErrors.ctaText) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="input-cta-icon" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.ctaIcon') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div v-if="popinImplementation.ctaIcon.url" class="border mb-3 d-inline-block">
                    <img :src="popinImplementation.ctaIcon.url" class="img-fluid">
                  </div>
                  <b-form-file
                    v-model="popinImplementation.ctaIconFile"
                    placeholder="Choose a file..."
                    drop-placeholder="Drop file here..."
                    id="input-cta-icon" />
                  <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors.ctaIcon" class="invalid-feedback d-block">{{ tErrors('popinImplementation', 'ctaIcon', popinImplementationSubmitErrors.ctaIcon) }}</div>
                </div>
              </div>

              <div class="form-group row">
                <label for="input-raw-css" class="col-sm-2 col-form-label">{{ $t('attributes.popinImplementation.rawCss') }}</label>
                <div class="col-sm-10 col-12">
                  <AceEditor
                    :style="{ height: '30vh' }"
                    v-model="popinImplementation.rawCss"
                    lang="css"
                    :options="editorOptions"
                  ></AceEditor>
                  <div v-if="popinImplementationSubmitErrors && popinImplementationSubmitErrors.rawCss" class="invalid-feedback">{{ tErrors('popinImplementation', 'rawCss', popinImplementationSubmitErrors.rawCss) }}</div>
                </div>
              </div>
            </b-tab>
          </persistent-tabs>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="popinImplementationSubmitLoading">
              <md-spinner v-if="popinImplementationSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import Multiselect from 'vue-multiselect'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BTab, BFormFile, BModal } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/less'
import kebabCase from 'lodash-es/kebabCase'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  props: {
    action: String
  },
  components: { MdSpinner, Dot, Multiselect, GroupModalSelect, BTab, BFormFile, BModal, draggable, AceEditor, PersistentTabs },
  data() {
    return {
      editorOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false
      },
      popinImplementation: {
        id: null,
        name: null,
        group: null,
        active: true,
        customisation: {
          'button-background-color': '',
          'button-text-color': '',
          'button-cart-color': ''
        },
        ctaText: null,
        ctaIcon: {},
        ctaIconFile: null,
        rawCss: null,
        popins: [],
        autoMigration: false
      },
      widgetsExpanded: {},
      popinImplementationLoading: false,
      popinImplementationError: null,
      widgets: [],
      widgetsLoading: false,
      widgetsError: null,
      products: [],
      productsLoading: false,
      productsError: null,
      popinModalAction: 'new',
      popinModal: {
        name: null,
        key: null,
        active: true,
        popinWidgets: [],
        _destroy: false
      },
      popinModalRef: null,
      popinImplementationSubmitLoading: false,
      popinImplementationSubmitErrors: null
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.popinImplementationLoading = true
      this.popinImplementationError = null

      const query = `query popinImplementationsForm ($popinImplementationId: Int!) {
        popinImplementation(id: $popinImplementationId) {
          id
          name
          active
          group {
            id
            name
            region {
              id
              code
            }
          }
          customisation
          ctaText
          ctaIcon
          rawCss
          popins {
            id
            key
            name
            active
            popinWidgets {
              id
              position
              labelHtml
              widget {
                id
                name
                publicName
              }
            }
          }
          autoMigration
        }
      }`

      const variables = {
        popinImplementationId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.popinImplementationLoading = false

          if (data.errors) {

          } else {
            this.popinImplementation = data.data.popinImplementation
          }
        })
    },
    // Load facebook app group widgets
    loadWidgets: function() {
      this.widgetsLoading = true
      this.widgetsError = null

      const query = `query popinImplementationFormWidgets ($groupId: Int!) {
        widgets(groupId: $groupId) {
          id
          name
          publicName
          product {
            id
            ean
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            groupId: this.popinImplementation.group.id
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.widgetsLoading = false

          if (data.errors) {
            this.widgetsError = { status: res.status, errors: data.errors }
          } else {
            this.widgets = Object.freeze(data.data.widgets)
          }
        })
    },
    // Search function for widget multiselect
    widgetLabel: function(widget) {
      return [widget.id, widget.product.ean, widget.name]
    },
    // New popin
    newPopin: function() {
      this.popinModalAction = 'new'
      this.popinModal = {
        name: null,
        key: null,
        active: true,
        popinWidgets: [],
        _destroy: false
      }
      this.$refs.popinModal.show()
    },
    // Edit popin
    editPopin: function(popin) {
      this.popinModalAction = 'edit'
      this.popinModal = cloneDeep(popin)
      this.popinModalRef = popin
      this.$refs.popinModal.show()
    },
    // Delete popin
    deletePopin: function(popin) {
      if (popin.id) {
        // If product language data exists in database, set its _destroy to true
        this.$set(popin, '_destroy', true)
      } else {
        // Otherwise, simply remove array item
        const index = this.popinImplementation.popins.indexOf(popin)
        this.popinImplementation.popins.splice(index, 1)
      }
    },
    // Restore popin
    restorePopin: function(popin) {
      this.$set(popin, '_destroy', false)
    },
    // Modal ok click
    popinModalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.popinModalSubmit()
    },
    // Modal form submit
    popinModalSubmit() {
      if (this.popinModalAction === 'new') {
        this.popinImplementation.popins.push({
          name: this.popinModal.name,
          key: this.popinModal.key,
          active: this.popinModal.active,
          popinWidgets: this.popinModal.popinWidgets,
          _destroy: this.popinModal._destroy
        })
      } else {
        this.popinModalRef.name = this.popinModal.name
        this.popinModalRef.key = this.popinModal.key
        this.popinModalRef.active = this.popinModal.active
        this.popinModalRef.popinWidgets = this.popinModal.popinWidgets
      }

      this.$nextTick(() => {
        this.$refs.popinModal.hide()
      })
    },
    // Check if there is an error for a field of a popin
    popinHasError: function(popin) {
      const index = this.popinImplementation.popins.indexOf(popin)
      if (this.popinImplementationSubmitErrors) {
        return Object.keys(this.popinImplementationSubmitErrors).some(key => {
          return new RegExp(`popins\\[${index}\\]`).test(key)
        })
      } else {
        return false
      }
    },
    // Add a widget
    addWidget: function(widget) {
      this.popinModal.popinWidgets.push({ widget })
    },
    // Remove a widget
    removeWidget: function(popinWidget) {
      if (popinWidget.id) {
        this.$set(popinWidget, '_destroy', true)
      } else {
        const index = this.popinModal.popinWidgets.indexOf(popinWidget)
        this.popinModal.popinWidgets.splice(index, 1)
      }
    },
    // Restore widget
    restoreWidget: function(popinWidget) {
      this.$set(popinWidget, '_destroy', false)
    },
    // Toggle expand widget
    toggleWidgetExpanded: function(widgetId) {
      this.$set(this.widgetsExpanded, widgetId, !this.widgetsExpanded[widgetId])
    },
    // Form submit
    submit: async function() {
      this.popinImplementationSubmitLoading = true
      this.popinImplementationSubmitErrors = null

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            name: this.popinImplementation.name,
            groupId: this.popinImplementation.group.id,
            active: this.popinImplementation.active,
            customisation: this.popinImplementation.customisation,
            ctaText: this.popinImplementation.ctaText,
            ctaIcon: this.popinImplementation.ctaIconFile ? await toBase64(this.popinImplementation.ctaIconFile) : undefined,
            rawCss: this.popinImplementation.rawCss,
            popinsAttributes: this.popinImplementation.popins.map(popin => {
              return {
                id: popin.id,
                key: popin.key,
                name: popin.name,
                active: popin.active,
                popinWidgetsAttributes: popin.popinWidgets.map((popinWidget, index) => {
                  return {
                    id: popinWidget.id,
                    position: index,
                    widgetId: popinWidget.widget.id,
                    labelHtml: popinWidget.labelHtml,
                    _destroy: popinWidget._destroy
                  }
                }),
                _destroy: popin._destroy
              }
            }),
            autoMigration: this.popinImplementation.autoMigration
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreatePopinImplementationInput!) {
          createPopinImplementation(input: $input) {
            popinImplementation {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.popinImplementationSubmitLoading = false

            if (data.data.createPopinImplementation.errors) {
              this.popinImplementationSubmitErrors = data.data.createPopinImplementation.errors
            } else {
              this.$router.push({ name: 'popinImplementation', params: { id: data.data.createPopinImplementation.popinImplementation.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.popinImplementation.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.popinImplementationId = this.$route.params.id

        const mutation = `mutation($input: UpdatePopinImplementationInput!) {
          updatePopinImplementation(input: $input) {
            popinImplementation {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.popinImplementationSubmitLoading = false

            if (data.data.updatePopinImplementation.errors) {
              this.popinImplementationSubmitErrors = data.data.updatePopinImplementation.errors
            } else {
              this.$router.push({ name: 'popinImplementation', params: { id: data.data.updatePopinImplementation.popinImplementation.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.popinImplementation.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  computed: {
    // Get widgets not already associated to the modal popin
    availableWidgets: function() {
      return this.widgets.filter(widget => !this.popinModal.popinWidgets.map(popinWidget => popinWidget.widget.id).includes(widget.id))
    },
    // Get popinModal index to get errors
    popinModalIndex: function() {
      return this.popinImplementation.popins.indexOf(this.popinModalRef)
    }
  },
  watch: {
    // Load group widgets when group changes
    'popinImplementation.group': function() {
      this.loadWidgets()
    },
    // Generate popin key based on popin name when a popin was not already saved
    'popinModal.name': function(name) {
      if (!this.popinModal.id) {
        this.popinModal.key = kebabCase(name)
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    }
  }
}
</script>
