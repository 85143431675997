<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="pimDataSourceLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="pimDataSourceError" class="alert alert-danger">
        <template v-if="pimDataSourceError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ pimDataSource.name }}</h5>
          <router-link :to="{ name: 'editPimDataSource', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('views.pimDataSources.show.infos')" active>
            <div class="d-flex flex-wrap gutter">
              <div class="flex-fill col-md-10">
                <div class="table-responsive">
                  <table class="table">
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.id') }}</th>
                      <td>{{ pimDataSource.id }}</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.name') }}</th>
                      <td>{{ pimDataSource.name }}</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.active') }}</th>
                      <td>
                        <dot :active="pimDataSource.active" />
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.disableProducts') }}</th>
                      <td>
                        <dot :active="pimDataSource.disableProducts" />
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.disableThreshold') }}</th>
                      <td v-if="pimDataSource.disableProducts">{{ pimDataSource.disableThreshold }}</td>
                      <td v-else>–</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.associatedProducts') }}</th>
                      <td>
                        {{ pimDataSource.productsCount }}
                        <router-link :to="{ name: 'products', query: { pimDataSourceIds: pimDataSource.id } }">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                            class="svg-inline--fa fa-external-link-alt fa-w-16">
                            <path fill="currentColor"
                              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                              class=""></path>
                          </svg>
                        </router-link>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t('attributes.pimDataSource.unknownGroups.label') }}</th>
                      <td>
                        <template v-if="pimDataSource.unknownGroups">
                          <codemirror :value="JSON.stringify(pimDataSource.unknownGroups, null, 2)"
                            class="codemirror-readonly"
                            :options="{ mode: 'application/json', tabSize: 2, autoRefresh: true, readOnly: true }" />
                        </template>
                        <template v-else>–</template>
                        <router-link v-if="pimDataSource.name === 'Tti' && pimDataSource.unknownGroups.list.length > 0"
                          class="btn btn-primary mt-3"
                          :to="{ name: 'pimDataSourceUnknownGroups', params: { id: pimDataSource.id } }">
                          {{ $t('attributes.pimDataSource.createUnknownGroups') }}
                        </router-link>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('views.pimDataSources.show.jobs')">
            <!-- Line chart -->
            <div class="mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="position-relative">
                    <highcharts
                      :options="chartOptions"
                      class="chart"
                      :aria-busy="pimDataSourceLoading"
                      style="width: 100%; height: 300px;" />
                    <div v-if="pimDataSourceLoading" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
                      <md-spinner md-indeterminate />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover" id="table-stats-retailers">
                <thead>
                  <tr>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.id') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.status') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.startedAt') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.updatedAt') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.duration') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.productsCount') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.newProductsCount') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.updatedProductsCount') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.countErrors') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.disabledProductsCount') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.unknownGroupProductsCount') }}</th>
                    <th class="text-center align-middle">{{ $t('attributes.pimDataSource.logs.label') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pimJob in pimDataSource.pimJobs" :key="pimJob.id">
                    <td class="text-center">{{ pimJob.id }}</td>
                    <td class="text-center">
                      <span class="badge" :class="{
                        'badge-success': pimJob.status === 'success',
                        'badge-warning': pimJob.status === 'running',
                        'badge-danger': pimJob.status === 'error'
                      }">
                      {{ pimJob.status }}
                      </span>
                    </td>
                    <td class="text-center">{{ pimJob.startedAt | date }}</td>
                    <td class="text-center">{{ pimJob.endedAt | date }}</td>
                    <td class="text-center">{{ pimJobDuration(pimJob) | duration }}</td>
                    <td class="text-right">{{ pimJob.countProducts }}</td>
                    <td class="text-right">{{ pimJob.countNewProducts }}</td>
                    <td class="text-right">{{ pimJob.countUpdatedProducts }}</td>
                    <td class="text-right">{{ pimJob.countErrors }}</td>
                    <td class="text-right">{{ pimJob.countDisabledProducts }}</td>
                    <td class="text-right">{{ pimJob.countUnknownGroupProducts }}</td>
                    <td v-if="pimJob.logsCount != 0" class="text-center align-middle">
                      <router-link :to="{ name: 'showPimJobLogs', params: { id: $route.params.id, job_id: pimJob.id } }" class="btn btn-primary btn-sm">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-12"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../shared/MdSpinner.vue'
import { Chart } from 'highcharts-vue'
import Dot from '../shared/Dot.vue'
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/addon/display/autorefresh.js'
import { VBTooltip, BTabs, BTab } from 'bootstrap-vue'
import i18n from '../../i18n'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export default {
  components: {
    MdSpinner,
    Dot,
    codemirror,
    BTabs,
    BTab,
    highcharts: Chart
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data: function() {
    return {
      pimDataSource: {
        name: '',
        active: true,
        disableProducts: true,
        disableThreshold: 100
      },
      pimDataSourceLoading: false,
      pimDataSourceError: null
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/user'
    }),
    // Chart
    chartOptions: function() {
      return {
        chart: { type: 'line' },
        xAxis: [{
          type: 'datetime',
          id: 'x1'
        }, {
          type: 'datetime',
          id: 'x2',
          opposite: true
        }],
        yAxis: {
          title: null,
          min: 0
        },
        title: null,
        credits: { enabled: false },
        series: [{
          name: this.$t('attributes.pimDataSource.productsCount'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#304269',
          data: this.pimDataSource.pimJobs.map(pimJob => [new Date(pimJob.startedAt).getTime(), pimJob.countProducts])
        }, {
          name: this.$t('attributes.pimDataSource.newProductsCount'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#10b541',
          data: this.pimDataSource.pimJobs.map(pimJob => [new Date(pimJob.startedAt).getTime(), pimJob.countNewProducts])
        }, {
          name: this.$t('attributes.pimDataSource.countErrors'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#b51010',
          data: this.pimDataSource.pimJobs.map(pimJob => [new Date(pimJob.startedAt).getTime(), pimJob.countErrors])
        }]
      }
    }
  },
  methods: {
    // Pim Job duration
    pimJobDuration: function(pimJob) {
      if (pimJob.status === 'running') {
        return new Date() - new Date(pimJob.startedAt)
      }

      return new Date(pimJob.endedAt) - new Date(pimJob.startedAt)
    },
    // Load data
    loadData: function() {
      this.pimDataSourceLoading = true
      this.pimDataSourceError = null

      const query = `query PimDataSourcesShow ($pimDataSourceId: Int!) {
        pimDataSource(id: $pimDataSourceId) {
          id
          name
          active
          productsCount
          disableProducts
          disableThreshold
          unknownGroups
          pimJobs {
            id
            startedAt
            endedAt
            countProducts
            countUpdatedProducts
            countNewProducts
            countErrors
            countDisabledProducts
            countUnknownGroupProducts
            status
            logsCount
          }
        }
      }`

      const variables = {
        pimDataSourceId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({
                res,
                data
              })
            }).catch(() => {
              resolve({
                res
              })
            })
          })
        }).then(({
          res,
          data
        }) => {
          this.pimDataSourceLoading = false

          if (data.errors) {
            this.pimDataSourceError = data.errors
          } else {
            this.pimDataSource = data.data.pimDataSource
          }
        })
    }
  },
  filters: {
    date: function(dateString) {
      return dateString ? new Date(dateString).toLocaleString(i18n.locale) : '–'
    },
    duration: function(d) {
      return moment.duration(d, 'milliseconds').format()
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
