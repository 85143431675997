<template>
  <div class="btn-group text-nowrap">
    <button type="button" @click="moveToPrevDateRange()" class="btn" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-left fa-w-10"><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" class=""></path></svg>
    </button>
    <date-range-picker v-model="dateRange" :ranges="ranges" style="min-width: 220px;">
      <div slot="input" slot-scope="picker" class="btn btn-block" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
        {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
      </div>
    </date-range-picker>
    <button type="button" @click="moveToNextDateRange()" class="btn" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-10"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
    </button>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
  components: { DateRangePicker },
  model: {
    prop: 'parentDateRange',
    event: 'update'
  },
  props: {
    parentDateRange: {
      default: null,
      required: true
    },
    compare: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    let defaultDateRange, dateRange

    if (!this.compare) {
      // Default date range: last 30 days
      defaultDateRange = {
        startDate: moment.utc().startOf('day').subtract(30, 'days').toDate(),
        endDate: moment.utc().startOf('day').subtract(1, 'days').toDate()
      }

      // Init date range from url params or default date range
      dateRange = {
        startDate: this.$route.query.startDate
          ? new Date(this.$route.query.startDate)
          : defaultDateRange.startDate,
        endDate: this.$route.query.endDate
          ? new Date(this.$route.query.endDate)
          : defaultDateRange.endDate
      }
    } else {
      // Default date range: 31-60
      defaultDateRange = {
        startDate: moment.utc().startOf('day').subtract(60, 'days').toDate(),
        endDate: moment.utc().startOf('day').subtract(31, 'days').toDate()
      }

      // Init date range to default date range
      dateRange = { ...defaultDateRange }
    }

    this.$emit('update', dateRange)

    return {
      dateRange: dateRange,
      defaultDateRange: defaultDateRange,
      ranges: {
        'This month': [moment.utc().startOf('month'), moment.utc().endOf('month').startOf('day')],
        'Last month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month').startOf('day')],
        'This year': [moment.utc().startOf('year'), moment.utc().endOf('year').startOf('day')],
        'Last year': [moment.utc().subtract(1, 'year').startOf('year'), moment.utc().subtract(1, 'year').endOf('year').startOf('day')]
      }
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Move to previous date range
    moveToPrevDateRange: function() {
      const duration = moment.duration(moment.utc(this.dateRange.endDate).diff(moment.utc(this.dateRange.startDate)))
      const newDate = moment.utc(this.dateRange.startDate).subtract(1, 'day')
      const startDate = this.dateRange.startDate
      const endDate = this.dateRange.endDate

      const newDateRange = {}

      // If the previous date range was a (or n) full month(s), move to a full period, otherwise keep the duration
      if (moment.utc(startDate).isSame(moment.utc(startDate).startOf('month')) && moment.utc(endDate).isSame(moment.utc(endDate).endOf('month').startOf('day'))) {
        newDateRange.startDate = moment.utc(newDate).startOf('month').subtract(Math.round(duration.asMonths()) - 1, 'months').toDate()
      } else {
        newDateRange.startDate = moment.utc(newDate).subtract(duration).toDate()
      }
      newDateRange.endDate = moment.utc(newDate).toDate()

      this.dateRange = newDateRange
    },
    // Move to next date range
    moveToNextDateRange: function() {
      const duration = moment.duration(moment.utc(this.dateRange.endDate).diff(moment.utc(this.dateRange.startDate)))
      const newDate = moment.utc(this.dateRange.endDate).add(1, 'day')
      const startDate = this.dateRange.startDate
      const endDate = this.dateRange.endDate

      const newDateRange = {}

      // If the previous date range was a (or n) full month(s), move to a full period, otherwise keep the duration
      if (moment.utc(startDate).isSame(moment.utc(startDate).startOf('month')) && moment.utc(endDate).isSame(moment.utc(endDate).endOf('month').startOf('day'))) {
        newDateRange.endDate = moment.utc(newDate).endOf('month').startOf('day').add(Math.round(duration.asMonths()) - 1, 'months').toDate()
      } else {
        newDateRange.endDate = moment.utc(newDate).add(duration).toDate()
      }
      newDateRange.startDate = moment.utc(newDate).toDate()

      this.dateRange = newDateRange
    }
  },
  watch: {
    // Page previous/next
    $route: function(to, from) {
      if (!this.compare) {
        // Update date range if startDate/endDate do not match
        const dateRange = { ...this.dateRange }
        let changed = false

        if (to.query.startDate !== this.dateSerializer(dateRange.startDate)) {
          changed = true
          dateRange.startDate = to.query.startDate
            ? moment.utc(to.query.startDate).toDate()
            : this.defaultDateRange.startDate
        }

        if (to.query.endDate !== this.dateSerializer(dateRange.endDate)) {
          changed = true
          dateRange.endDate = to.query.endDate
            ? moment.utc(to.query.endDate).toDate()
            : this.defaultDateRange.endDate
        }

        if (changed) {
          this.dateRange = dateRange
        }
      }
    },
    // When date range changes
    dateRange: function(dateRange) {
      // Update url
      if (!this.compare) {
        if (this.dateSerializer(dateRange.startDate) === this.dateSerializer(this.defaultDateRange.startDate) &&
            this.dateSerializer(dateRange.endDate) === this.dateSerializer(this.defaultDateRange.endDate)) {
          if (this.$route.query.startDate && this.$route.query.endDate) {
            this.$router.push({
              query: {}
            })
          }
        } else {
          if (this.dateSerializer(dateRange.startDate) !== this.$route.query.startDate ||
              this.dateSerializer(dateRange.endDate) !== this.$route.query.endDate) {
            this.$router.push({
              query: {
                ...this.$route.query,
                startDate: this.dateSerializer(dateRange.startDate),
                endDate: this.dateSerializer(dateRange.endDate)
              }
            })
          }
        }
      }

      // Update parent
      this.$emit('update', dateRange)
    }
  }
}
</script>

<style lang="scss">
// Vue daterangepicker
.reportrange-text {
  padding: 0 !important;
  border: 0 !important;
  background: transparent !important;
}
</style>
