<template>
  <multiselect
    :options="availableRetailers"
    :custom-label="retailer => retailer.name"
    :allow-empty="false"
    :clear-on-select="false"
    :placeholder="placeholder"
    :close-on-select="false"
    :loading="retailersLoading"
    @select="emitSelectEvent"
    @search-change="handleSearchChange"
    @open="handleOpen"
  >
    <template v-slot:option="{ option }">
      <div class="gutter-x-xs">
        #{{ option.id }}
        <region-flag :code="option.region.code" />
        <img :src="option.img_small_url" style="max-height: 20px;">
        {{ option.name }}
        <span class="badge badge-light">{{ $t(`shared.retailerDistributions.${option.service}`) }}</span>
      </div>
    </template>
    <template slot="afterList" v-if="retailersHasNextPage">
      <button class="btn btn-primary col-12" type="button" @click="loadMoreClick" :disabled="retailersLoading">
        {{ $t('views.retailerMultiselect.loadMore') }}
      </button>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import RegionFlag from '../shared/RegionFlag.vue'

export default {
  components: { Multiselect, RegionFlag },
  props: {
    placeholder: null,
    skipRetailerIds: null,
    regionId: null
  },
  data() {
    return {
      retailers: [],
      retailersPage: 1,
      retailersHasNextPage: false,
      retailersLoading: false,
      retailersError: null,
      searchQuery: null,
      initialized: false,
      abortController: false
    }
  },
  computed: {
    availableRetailers: function() {
      return this.retailers.filter(retailer => {
        return !this.skipRetailerIds.includes(retailer.id)
      })
    }
  },
  methods: {
    // Tell the parent component that a retailer has been added
    emitSelectEvent: function(retailer) {
      this.$emit('select', retailer)
    },
    // Fetch retailers
    fetchRetailers: async function(searchQuery) {
      this.retailersLoading = true
      this.retailersError = null

      const query = `query retailersMultiselect($regionIds: [Int!], $name: String, $page: Int!) {
        retailersPage(page: $page, regionIds: $regionIds, name: $name) {
          nodes {
            id
            name
            service
            region {
              id
              code
            }
          }
          hasNextPage
        }
      }`

      const variables = {
        regionIds: [this.regionId],
        name: searchQuery,
        page: this.retailersPage
      }

      this.abortController = new AbortController()

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables }),
          signal: this.abortController.signal
        })
        const data = await res.json()
        if (!data.errors) {
          this.retailersHasNextPage = data.data.retailersPage.hasNextPage
          if (this.retailersPage === 1) {
            this.retailers = data.data.retailersPage.nodes
          } else {
            this.retailers.push(...data.data.retailersPage.nodes)
          }
        } else {
          this.retailersError = data.errors
          console.log(this.retailersError)
        }
      } catch (error) {
        this.retailersError = error
        console.log(this.retailersError)
      } finally {
        this.retailersLoading = false
      }
    },
    // Fetch retailers before the user types anything
    handleOpen: async function() {
      if (!this.initialized) {
        this.initialized = true
        await this.fetchRetailers()
      }
    },
    // Handle the input search
    handleSearchChange: async function(searchQuery) {
      this.searchQuery = searchQuery
      this.retailersPage = 1

      if (searchQuery.length > 0) {
        await this.fetchRetailers(searchQuery)
      } else {
        this.initialized = false
        this.retailers = []
      }
    },
    // Load more click
    loadMoreClick: async function() {
      this.retailersPage++
      await this.fetchRetailers(this.searchQuery)
    }
  }
}
</script>
