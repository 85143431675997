
<div>
  <!-- Alert for unauthorized users -->
  <div
    v-if="eventAggregatesError.main && eventAggregatesError.main.status === 403"
    class="alert alert-danger"
  >
    {{ $t('errors.unauthorized.manage.all') }}
  </div>

  <!-- First row with group selector, date range pickers, compare button -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <!-- Group tree select -->
    <div class="flex-fill flex-basis-0">
      <treeselect
        :limit-text="treeSelectLimitText"
        :limit="0"
        :multiple="true"
        :options="groupsTreeSelect"
        :searchable="false"
        style="max-width: 275px;"
        v-model="selectedGroupIds"
        value-consists-of="ALL_WITH_INDETERMINATE"
        :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
        <template slot="option-label" slot-scope="{ node }">
          <region-flag v-if="international" :code="node.label.regionCode" />
          {{ node.label.name }}
        </template>
      </treeselect>
    </div>

    <!-- Date range pickers -->
    <div class="flex-fill flex-basis-0 text-center">
      <div class="btn-group text-nowrap">
        <stats-date-range-picker v-model="dateRanges.main" />
      </div>
      <div class="mt-2" v-if="compare">
        <stats-date-range-picker v-model="dateRanges.compare" :compare="true" />
      </div>
    </div>

    <!-- Compare button -->
    <div class="flex-fill flex-basis-0 text-right">
      <button @click="toggleCompare()" class="btn btn-primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="columns" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-columns fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64V160h160v256zm224 0H288V160h160v256z" class=""></path></svg>
        {{ $t('views.stats.tunnel.compare') }}
      </button>
    </div>
  </div>

  <!-- Dashboard-style big numbers -->
  <div class="mb-3 d-flex flex-sm-row flex-column gutter">
    <div v-for="action in ['print_button', 'print', 'redirection']" :key="action" class="card flex-fill flex-basis-0">
      <div class="card-body">
        <div class="position-relative">
          <div class="pt-3 mb-0">
            <!-- Main count -->
            <template v-if="groupsLoading || eventAggregatesLoading.main">
              <md-spinner md-indeterminate :diameter="27" :stroke-width="5" />
            </template>
            <template v-else>
              <h3 class="text-primary mb-0 d-inline">{{ actionCount('main', action) | number }}</h3>
            </template>

            <!-- Compare count -->
            <template v-if="compare">
              <h5 class="mb-0 d-inline">
              /
              <template v-if="groupsLoading || eventAggregatesLoading.compare">
                <md-spinner md-indeterminate :diameter="16" :stroke-width="5" />
              </template>
              <template v-else>
                <span class="text-orange">{{ actionCount('compare', action) | number }}</span>
              </template>
              </h5>
            </template>
          </div>
          <div class="pb-3">
            {{ $t(`shared.eventActions.widget.${action}`) }}

            <!-- Compare percentage -->
            <div v-if="compare">
              <template v-if="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare">
                <md-spinner md-indeterminate :diameter="16" :stroke-width="5" />
              </template>
              <template v-else>
                <small class="font-weight-bold" :class="variationClass(variation(actionCount('main', action), actionCount('compare', action)))">
                  {{ variation(actionCount('main', action), actionCount('compare', action)) | variation }}
                </small>
              </template>
            </div>
          </div>
          <h1 class="position-absolute w-100 text-right mb-0 text-secondary" style="bottom: 0;">
            <template v-if="action === 'print_button'">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-w-18"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
            </template>
            <template v-else-if="action === 'print'">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mouse" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-mouse fa-w-12"><path fill="currentColor" d="M0 352a160 160 0 0 0 160 160h64a160 160 0 0 0 160-160V224H0zM176 0h-16A160 160 0 0 0 0 160v32h176zm48 0h-16v192h176v-32A160 160 0 0 0 224 0z" class=""></path></svg>
            </template>
            <template v-else-if="action === 'redirection'">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-arrow-alt-circle-right fa-w-16"><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z" class=""></path></svg>
            </template>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <!-- Line chart -->
  <div class="mb-3">
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <highcharts
            :options="chartOptions"
            class="chart"
            :aria-busy="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare"
            style="width: 100%; height: 300px;" />
          <div v-if="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
            <md-spinner md-indeterminate />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Columns + export button -->
  <div class="mb-3 d-flex justify-content-between align-items-center gutter">
    <div>
      <div class="d-flex gutter">
        <!-- Columns button -->
        <button type="button" class="btn btn-primary" v-b-modal.columns-modal>
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM160 448H48c-8.837 0-16-7.163-16-16v-80h128v96zm0-128H32v-96h128v96zm0-128H32V96h128v96zm160 256H192v-96h128v96zm0-128H192v-96h128v96zm0-128H192V96h128v96zm160 160v80c0 8.837-7.163 16-16 16H352v-96h128zm0-32H352v-96h128v96zm0-128H352V96h128v96z" class=""></path></svg>
          {{ $t('shared.actions.manageColumns') }}
        </button>
      </div>
    </div>
    <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
      <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
        {{ $t('shared.actions.xlsxExport') }}
      </button>
    </span>
  </div>

  <!-- Columns modal -->
  <b-modal
    id="columns-modal"
    size="sm"
    :title="$t('shared.actions.manageColumns')"
    hide-footer
    scrollable
    no-fade>
    <template v-for="columnSetting in availableColumnSettings">
      <div class="form-group" :key="columnSetting">
        <div class="custom-control custom-checkbox">
          <input
            v-model="columnSettings[columnSetting]"
            type="checkbox" class="custom-control-input" :id="`column-${columnSetting}`">
          <label class="custom-control-label" :for="`column-${columnSetting}`">
            <template v-if="columnSetting === 'rawPackaging'">
              {{ $t(`attributes.productLanguageData.${columnSetting}`) }}
            </template>
            <template v-else-if="['reference', 'ean', 'upc'].includes(columnSetting)">
              {{ $t(`attributes.product.${columnSetting}`) }}
            </template>
            <template v-else-if="columnSetting === 'rates'">
              {{ $t('views.stats.tunnel.rates') }}
            </template>
            <template v-else-if="columnSetting === 'redirectionProduct'">
              {{ $t('shared.eventActions.widget.redirection_product') }}
            </template>
          </label>
        </div>
      </div>
    </template>
  </b-modal>

  <!-- Table -->
  <div class="table-responsive">
    <table
      class="table table-bordered table-hover-tbody"
      :aria-busy="eventAggregatesLoading.main || eventAggregatesLoading.compare"><!-- TODO: spinner somewhere -->
      <thead>
        <tr>
          <th class="cell-md">{{ $t('views.stats.tunnel.group') }}</th>
          <th class="cell-lg">{{ $t('views.stats.tunnel.widget') }}</th>
          <th class="cell-md" v-if="columnSettings.rawPackaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
          <th class="cell-md" v-if="columnSettings.reference">{{ $t('attributes.product.reference') }}</th>
          <th class="cell-md" v-if="columnSettings.ean">{{ $t('attributes.product.ean') }}</th>
          <th class="cell-md" v-if="columnSettings.upc">{{ $t('attributes.product.upc') }}</th>
          <th @click="setSort('printButton')" :aria-sort="ariaSort('printButton')" class="data-cell cursor-pointer">
            {{ $t('shared.eventActions.widget.print_button') | capitalize }}
          </th>
          <th @click="setSort('print')" :aria-sort="ariaSort('print')" class="data-cell cursor-pointer">
            {{ $t('shared.eventActions.widget.print') | capitalize }}
          </th>
          <template v-if="columnSettings.rates">
            <th @click="setSort('printRate')" :aria-sort="ariaSort('printRate')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.print_rate') | capitalize }}
            </th>
          </template>
          <template v-if="hasStoreActivated">
            <th @click="setSort('storeSelection')" :aria-sort="ariaSort('storeSelection')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.store_selection') | capitalize }}
            </th>
            <template v-if="columnSettings.rates">
              <th @click="setSort('storeSelectionRate')" :aria-sort="ariaSort('storeSelectionRate')" class="data-cell cursor-pointer">
                {{  $t('shared.eventActions.widget.store_selection_rate') | capitalize }}
              </th>
            </template>
          </template>
          <th @click="setSort('redirection')" :aria-sort="ariaSort('redirection')" class="data-cell cursor-pointer">
            {{ $t('shared.eventActions.widget.redirection') | capitalize }}
          </th>
          <template v-if="columnSettings.rates">
            <th @click="setSort('redirectionRate')" :aria-sort="ariaSort('redirectionRate')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.redirection_rate') | capitalize }}
            </th>
          </template>
          <template v-if="columnSettings.redirectionProduct">
            <th @click="setSort('redirectionProduct')" :aria-sort="ariaSort('redirectionProduct')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.redirection_product') | capitalize }}
            </th>
          </template>
          <template v-if="userCanReadEngagementAmount">
            <th @click="setSort('engagedRevenue')" :aria-sort="ariaSort('engagedRevenue')" class="data-cell cursor-pointer th-shield" ref="thEngagedRevenue">
              {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize  }}
              <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <b-tooltip v-if="userIsAdmin" :target="() => $refs['thEngagedRevenue']" boundary="viewport">
              {{ $t('views.stats.tunnel.tooltip.engaged_revenue') }}
              <sup class="text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </sup>
            </b-tooltip>
          </template>
          <template v-if="userCanReadValidations">
            <th @click="setSort('validation')" :aria-sort="ariaSort('validation')" class="data-cell cursor-pointer th-shield">
              {{ $t('shared.eventActions.widget.validation') | capitalize }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <template v-if="columnSettings.rates">
              <th @click="setSort('validationRate')" :aria-sort="ariaSort('validationRate')" class="data-cell cursor-pointer">
                {{ $t('shared.eventActions.widget.validation_rate') | capitalize }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
            <th @click="setSort('revenue')" :aria-sort="ariaSort('revenue')" class="data-cell cursor-pointer th-shield" ref="thRevenue">
              {{ $t('shared.eventActions.widget.revenue') | capitalize }}
              <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </div>
            </th>
            <b-tooltip v-if="userIsAdmin && !demoMode" :target="() => $refs['thRevenue']" boundary="viewport">
              {{ $t('views.stats.tunnel.tooltip.revenue') }}
              <sup class="text-warning">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
              </sup>
            </b-tooltip>
          </template>
        </tr>
      </thead>
      <template v-if="groupsLoading">
        <tbody>
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else v-for="group in displayedGroups">
        <!-- Group total -->
        <tbody :key="`group-${group.id}`">
          <tr v-for="dataset in datasets" :key="dataset" class="font-weight-semibold bg-light">
            <td
              :rowspan="compare ? 2 : 1"
              @click="toggleExpandedGroup(group.id)"
              v-if="!compare || compare && (dataset === 'main')"
              :class="{ 'cursor-pointer': group.children || group.widgets.length > 0 }">
              <div class="d-flex gutter-sm justify-content-between align-items-center">
                <div v-if="group.depth > 0">
                  <template v-for="n in group.depth">
                    <svg :key="n" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                  </template>
                </div>

                <div class="flex-fill">
                  <region-flag v-if="international" :code="group.region.code" />
                  {{ group.name }}
                </div>

                <template v-if="group.children || group.widgets.length > 0">
                  <template v-if="!expandedGroup(group.id)">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                  </template>
                  <template v-else>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                  </template>
                </template>
              </div>
            </td>
            <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">Total</td>
            <td :rowspan="compare ? 2 : 1" v-if="columnSettings.rawPackaging && (!compare || compare && (dataset === 'main'))"></td>
            <td :rowspan="compare ? 2 : 1" v-if="columnSettings.reference && (!compare || compare && (dataset === 'main'))"></td>
            <td :rowspan="compare ? 2 : 1" v-if="columnSettings.ean && (!compare || compare && (dataset === 'main'))"></td>
            <td :rowspan="compare ? 2 : 1" v-if="columnSettings.upc && (!compare || compare && (dataset === 'main'))"></td>
            <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'print_button') | number }}</td>
            <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'print') | number }}</td>
            <template v-if="columnSettings.rates">
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'print', 'print_button') | percentage }}</td>
            </template>
            <template v-if="hasStoreActivated">
              <td class="text-right" :class="rowClass(dataset)">
                <template v-if="group.hasStoreActivated">
                  {{ groupActionCount(dataset, group.id, 'store_selection') | number }}
                </template>
                <template v-else>
                  –
                </template>
              </td>
              <template v-if="columnSettings.rates">
                <td class="text-right" :class="rowClass(dataset)">
                  <template v-if="group.hasStoreActivated">
                    {{ groupActionRate(dataset, group.id, 'store_selection', 'print') | percentage }}
                  </template>
                  <template v-else>
                    –
                  </template>
                </td>
              </template>
            </template>
            <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'redirection') | number }}</td>
            <template v-if="columnSettings.rates">
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'redirection', 'print') | percentage }}</td>
            </template>
            <template v-if="columnSettings.redirectionProduct">
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'redirection_product') | number }}</td>
            </template>
            <template v-if="userCanReadEngagementAmount">
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionAmount(dataset, group.id, 'redirection_product') | price(group.region) }}</td>
            </template>
            <template v-if="userCanReadValidations">
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'validation') | number }}</td>
              <template v-if="columnSettings.rates">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'validation', 'redirection') | percentage }}</td>
              </template>
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionAmount(dataset, group.id, 'validation_product') | price(group.region) }}</td>
            </template>
          </tr>
        </tbody>

        <!-- Group widgets -->
        <template v-if="expandedGroup(group.id)">
          <tbody v-for="widget in sortWidgets(group.widgets)" :key="`widget-${widget.id}`">
            <template v-for="dataset in datasets">
              <tr :key="dataset">
                <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">{{ group.name }}</td>
                <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">
                  <div class="d-flex justify-content-between gutter-sm">
                    <div>
                      {{ widget.name }}
                    </div>
                    <div v-if="userIsAdmin">
                      <router-link :to="{ name: 'widget', params: { id: widget.id } }" class="text-light">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16"><path fill="currentColor" d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" class=""></path></svg>
                      </router-link>
                    </div>
                  </div>
                </td>
                <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.rawPackaging && (!compare || compare && (dataset === 'main'))">
                  <small>{{ widget.product.productLanguageDatas[0].rawPackaging }}</small>
                </td>
                <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.reference && (!compare || compare && (dataset === 'main'))">
                  <small>{{ widget.product.reference }}</small>
                </td>
                <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.ean && (!compare || compare && (dataset === 'main'))">
                  <small>{{ widget.product.ean }}</small>
                </td>
                <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.upc && (!compare || compare && (dataset === 'main'))">
                  <small>{{ widget.product.upc }}</small>
                </td>
                <td class="text-right" :class="rowClass(dataset)">{{ widgetData('printButton', dataset, widget) | number }}</td>
                <td class="text-right" :class="rowClass(dataset)">{{ widgetData('print', dataset, widget) | number }}</td>
                <template v-if="columnSettings.rates">
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('printRate', dataset, widget) | percentage }}</td>
                </template>
                <template v-if="hasStoreActivated">
                  <td class="text-right" :class="rowClass(dataset)">
                    <template v-if="group.hasStoreActivated">
                      {{ widgetData('storeSelection', dataset, widget) | number }}
                    </template>
                    <template v-else>
                      –
                    </template>
                  </td>
                  <template v-if="columnSettings.rates">
                    <td class="text-right" :class="rowClass(dataset)">
                      <template v-if="group.hasStoreActivated">
                        {{ widgetData('storeSelectionRate', dataset, widget) | percentage }}
                      </template>
                      <template v-else>
                        –
                      </template>
                    </td>
                  </template>
                </template>
                <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirection', dataset, widget) | number }}</td>
                <template v-if="columnSettings.rates">
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirectionRate', dataset, widget) | percentage }}</td>
                </template>
                <template v-if="columnSettings.redirectionProduct">
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirectionProduct', dataset, widget) | number }}</td>
                </template>
                <template v-if="userCanReadEngagementAmount">
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('engagedRevenue', dataset, widget) | price(group.region) }}</td>
                </template>
                <template v-if="userCanReadValidations">
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('validation', dataset, widget) | number }}</td>
                  <template v-if="columnSettings.rates">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('validationRate', dataset, widget) | percentage }}</td>
                  </template>
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('revenue', dataset, widget) | price(group.region) }}</td>
                </template>
              </tr>
            </template>
          </tbody>
        </template>
      </template>
    </table>
  </div>
</div>
