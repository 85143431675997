
<div>
  <div class="d-flex justify-content-between align-items-end mb-3">
    <h4 class="mb-0">
      <span>Widget status:</span>
      <span v-if="statusesLoading">
        <md-spinner md-indeterminate :diameter="20" :stroke-width="5" />
      </span>
      <span v-else :class="status === 1 ? 'text-success' : 'text-danger'">
        <template v-if="status === 1">Online</template>
        <template v-else>Down</template>
      </span>
    </h4>
    <div>
      <multiselect
        v-model="selectedRegion"
        :options="regions"
        open-direction="bottom"
        :allow-empty="false"
        :clear-on-select="false"
        :close-on-select="true"
        select-label=""
        deselect-label=""
        :max-height="200">
      </multiselect>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <highcharts :options="chartOptions" style="width: 100%;"></highcharts>
    </div>
  </div>
</div>
