
<div class="btn-group text-nowrap">
  <button type="button" @click="moveToPrevDateRange()" class="btn" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-left fa-w-10"><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" class=""></path></svg>
  </button>
  <date-range-picker v-model="dateRange" :ranges="ranges" style="min-width: 220px;">
    <div slot="input" slot-scope="picker" class="btn btn-block" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
      {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
    </div>
  </date-range-picker>
  <button type="button" @click="moveToNextDateRange()" class="btn" :class="{ 'btn-primary': !compare, 'btn-orange': compare }">
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-10"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
  </button>
</div>
