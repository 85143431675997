
<div>
  <transition name="fade" mode="out-in">
    <div v-if="abTestLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="abTestError" class="alert alert-danger">
      <template v-if="abTestError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div class="form-group row">
          <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.name') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <input v-model="abTest.name" type="text" class="form-control" :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.abTest.name')">
            <div v-if="abTestSubmitErrors && abTestSubmitErrors.name" class="invalid-feedback">{{ tErrors('abTest', 'name', abTestSubmitErrors.name) }}</div>
          </div>
        </div>

        <div class="form-group row">
          <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.group') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <group-modal-select
              v-model="abTest.group"
              root-group
              :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.group }"
              id="multiselect-group" />
            <div v-if="abTestSubmitErrors && abTestSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('abTest', 'group', abTestSubmitErrors.group) }}</div>
          </div>
        </div>

        <!-- Service -->
        <div class="form-group row">
          <label for="multiselect-service" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.service') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <multiselect
              v-model="abTest.service"
              :options="services"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :placeholder="$t('shared.placeholders.select')"
              :custom-label="service => $t(`views.abTests.services.${service}`)"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.abTest.active') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="abTest.active" type="checkbox" class="custom-control-input" id="active-checkbox">
              <label class="custom-control-label" for="active-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="daterange-picker" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.dateRange') }}</label>
          <div class="col-sm-10 col-12">
            <date-range-picker v-model="abTest.dateRange" :time-picker="true">
              <div slot="input" slot-scope="picker" class="btn btn-block btn-primary">
                {{ picker.startDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }} - {{ picker.endDate.toLocaleDateString(undefined, { timeZone: 'UTC' }) }}
              </div>
            </date-range-picker>
          </div>
        </div>

        <div class="form-group row">
          <label for="uniform-checkbox" class="col-sm-2">{{ $t('attributes.abTest.uniform') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="abTest.uniform" type="checkbox" class="custom-control-input" id="uniform-checkbox">
              <label class="custom-control-label" for="uniform-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="daterange-picker" class="col-sm-2 col-form-label">{{ $t('attributes.abTest.abTestsVariants') }}</label>
          <div class="col-sm-10 col-12">
            <div class="alert alert-warning" v-if="abTest.abTestsVariants.length === 0">
              {{ $t('shared.warnings.noVariant') }}
            </div>

            <div class="alert alert-danger" v-if="abTestSubmitErrors && abTestSubmitErrors.abTestsVariants">
              {{ tErrors('abTest', 'abTestsVariants', abTestSubmitErrors.abTestsVariants) }}
            </div>

            <table class="table table-hover mb-0 table-valign-middle">
              <tr
                v-for="abTestsVariant in abTest.abTestsVariants"
                :key="abTestsVariant.id"
                :class="{ 'table-danger': abTestsVariant._destroy }">
                <td class="font-weight-semibold">{{ abTestsVariant.variant.name }}</td>
                <td>
                  <!-- Widget customisation selection -->
                  <multiselect
                    v-if="abTestsVariant.variant.id === variantWithCustomisationId"
                    v-model="abTestsVariant.customisation"
                    :options="customisations"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.placeholders.select')"
                    :show-labels="false"
                    :loading="customisationsLoading">
                    <template v-slot:singleLabel="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                    <template v-slot:option="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                  </multiselect>

                  <!-- Multiproduct widget customisation selection -->
                  <multiselect
                    v-else-if="abTestsVariant.variant.id === variantWithMultiproductWidgetCustomisationId"
                    v-model="abTestsVariant.multiproductWidgetCustomisation"
                    :options="multiproductWidgetCustomisations"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.placeholders.select')"
                    :show-labels="false"
                    :loading="customisationsLoading">
                    <template v-slot:singleLabel="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                    <template v-slot:option="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                  </multiselect>

                  <!-- Michelin widget customisation selection -->
                  <multiselect
                    v-else-if="abTestsVariant.variant.id === variantWithMichelinWidgetCustomisationId"
                    v-model="abTestsVariant.michelinWidgetCustomisation"
                    :options="michelinWidgetCustomisations"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.placeholders.select')"
                    :show-labels="false"
                    :loading="customisationsLoading">
                    <template v-slot:singleLabel="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                    <template v-slot:option="{ option }">
                      #{{ option.id }} - {{ option.name }}
                    </template>
                  </multiselect>
                </td>
                <td class="td-shrink text-nowrap">
                  <template v-if="!abTestsVariant._destroy">
                    <template v-if="abTest.uniform">
                      {{ uniformPercentage | percentage }}
                    </template>
                    <template v-else>
                      <input
                        type="number"
                        v-model.number="abTestsVariant.weight"
                        class="form-control d-inline"
                        style="width: 70px !important;"
                        :class="{ 'is-invalid' : abTestSubmitErrors && abTestSubmitErrors.abTestsVariants }">
                      %
                    </template>
                  </template>
                </td>
                <td class="td-shrink text-nowrap">
                  <template v-if="abTestsVariant.variant.id !== 1">
                    <template v-if="!abTestsVariant._destroy">
                      <button type="button" class="btn btn-sm btn-danger" @click="deleteVariant(abTestsVariant)">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                        {{ $t('shared.actions.delete') }}
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-sm btn-success" @click="restoreVariant(abTestsVariant)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                        {{ $t('shared.actions.restore') }}
                      </button>
                    </template>
                  </template>
                </td>
              </tr>
            </table>

            <multiselect
              v-if="availableVariants.length > 0"
              @input="addVariant"
              :options="availableVariants"
              :custom-label="variant => variant.name"
              :clear-on-select="true"
              :close-on-select="true"
              open-direction="bottom"
              :placeholder="$t('shared.placeholders.addVariant')"
              :loading="variantsLoading">
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="abTestSubmitLoading">
            <md-spinner v-if="abTestSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
