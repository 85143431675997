export default [
  {
    id: '1',
    name: 'Template 1',
    data: {
      assets: [],
      styles: [
        {
          selectors: [
            'container'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-fluid'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-xxl'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '540px'
          },
          mediaText: '(min-width: 576px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '540px'
          },
          mediaText: '(min-width: 576px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xxl'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            '#i17r'
          ],
          style: {
            'font-family': 'Arial, Helvetica, sans-serif',
            'background-color': '#fbf8f0'
          },
          wrapper: 1
        },
        {
          selectors: [
            '#iqz2'
          ],
          style: {
            margin: '0px 0px 2rem 0px',
            'font-size': '18px',
            'font-family': 'Georgia, serif',
            'font-weight': '500'
          }
        },
        {
          selectors: [
            '#idxq'
          ],
          style: {
            color: '#3c3c3c',
            margin: '0px 0px 1rem 0px',
            'font-size': '3rem',
            'font-family': 'Georgia, serif'
          }
        },
        {
          selectors: [
            '#iylt'
          ],
          style: {
            margin: '4rem auto 0px auto'
          }
        },
        {
          selectors: [
            {
              name: 'gjs-row',
              private: 1
            }
          ],
          style: {
            display: 'flex',
            'flex-wrap': 'nowrap',
            'align-items': 'stretch',
            'padding-top': '10px',
            'padding-left': '10px',
            'padding-right': '10px',
            'padding-bottom': '10px',
            'justify-content': 'flex-start'
          }
        },
        {
          selectors: [
            {
              name: 'gjs-row',
              private: 1
            }
          ],
          style: {
            'flex-wrap': 'wrap'
          },
          mediaText: '(max-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            {
              name: 'gjs-cell',
              private: 1
            }
          ],
          style: {
            'flex-grow': '1',
            'flex-basis': '100%',
            'min-height': '75px'
          }
        },
        {
          selectors: [
            'gjs-cell30'
          ],
          style: {
            width: '30%'
          }
        },
        {
          selectors: [
            'gjs-cell70'
          ],
          style: {
            width: '70%'
          }
        },
        {
          selectors: [
            '#ixhc'
          ],
          style: {
            'flex-basis': '70%'
          }
        },
        {
          selectors: [
            '#i6ec'
          ],
          style: {
            'flex-basis': '30%'
          }
        },
        {
          selectors: [
            '#ise8'
          ],
          style: {
            gap: '2rem',
            padding: '0px 0px 0px 0px'
          }
        },
        {
          selectors: [
            '#i3od7'
          ],
          style: {
            color: 'black',
            width: '100%',
            height: '300px'
          }
        },
        {
          selectors: [
            '#ijr6z'
          ],
          style: {
            color: 'black',
            width: '211px',
            height: '60px',
            margin: '0 0 1rem 0'
          }
        },
        {
          selectors: [
            '#imho6'
          ],
          style: {
            margin: '0 0 1rem 0',
            'font-family': 'Georgia, serif'
          }
        }
      ],
      pages: [
        {
          frames: [
            {
              component: {
                type: 'wrapper',
                attributes: {
                  id: 'i17r'
                },
                components: [
                  {
                    type: 'responsive-container',
                    classes: [
                      'container'
                    ],
                    attributes: {
                      id: 'iylt'
                    },
                    components: [
                      {
                        name: 'Row',
                        droppable: '.gjs-cell',
                        resizable: {
                          bl: 0,
                          br: 0,
                          cl: 0,
                          cr: 0,
                          tc: 0,
                          tl: 0,
                          tr: 0,
                          minDim: 1
                        },
                        classes: [
                          {
                            name: 'gjs-row',
                            private: 1
                          }
                        ],
                        attributes: {
                          id: 'ise8'
                        },
                        components: [
                          {
                            name: 'Cell',
                            draggable: '.gjs-row',
                            'stylable-require': [
                              'flex-basis'
                            ],
                            unstylable: [
                              'width'
                            ],
                            resizable: {
                              bc: 0,
                              bl: 0,
                              br: 0,
                              cl: 0,
                              cr: 1,
                              tc: 0,
                              tl: 0,
                              tr: 0,
                              step: 0.2,
                              minDim: 1,
                              keyWidth: 'flex-basis',
                              currentUnit: 1
                            },
                            classes: [
                              {
                                name: 'gjs-cell',
                                private: 1
                              }
                            ],
                            attributes: {
                              id: 'ixhc'
                            },
                            components: [
                              {
                                type: 'image',
                                resizable: {
                                  ratioDefault: 1
                                },
                                attributes: {
                                  id: 'ijr6z',
                                  src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+'
                                }
                              },
                              {
                                tagName: 'h1',
                                type: 'text',
                                attributes: {
                                  id: 'idxq'
                                },
                                components: [
                                  {
                                    type: 'textnode',
                                    content: 'The new product'
                                  }
                                ]
                              },
                              {
                                type: 'text',
                                attributes: {
                                  id: 'iqz2'
                                },
                                components: [
                                  {
                                    type: 'textnode',
                                    content: 'We like well-crafted products and the new product is one of them.'
                                  }
                                ]
                              },
                              {
                                type: 'text',
                                attributes: {
                                  id: 'imho6'
                                },
                                components: [
                                  {
                                    type: 'textnode',
                                    content: 'Find easily nearest shops and online shops to buy our new product.'
                                  }
                                ]
                              },
                              {
                                type: 'c2b-widget',
                                attributes: {
                                  id: 'ird6',
                                  'data-inline-container-auto-open': ''
                                }
                              }
                            ]
                          },
                          {
                            name: 'Cell',
                            draggable: '.gjs-row',
                            'stylable-require': [
                              'flex-basis'
                            ],
                            unstylable: [
                              'width'
                            ],
                            resizable: {
                              bc: 0,
                              bl: 0,
                              br: 0,
                              cl: 0,
                              cr: 1,
                              tc: 0,
                              tl: 0,
                              tr: 0,
                              step: 0.2,
                              minDim: 1,
                              keyWidth: 'flex-basis',
                              currentUnit: 1
                            },
                            classes: [
                              {
                                name: 'gjs-cell',
                                private: 1
                              }
                            ],
                            attributes: {
                              id: 'i6ec'
                            },
                            components: [
                              {
                                type: 'image',
                                resizable: {
                                  ratioDefault: 1
                                },
                                attributes: {
                                  id: 'i3od7',
                                  src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+'
                                }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              id: 'TuGl5OOVWF1DUMQz'
            }
          ],
          id: 'bQI3J4n7l0nocc0O',
          type: 'main'
        }
      ]
    }
  },
  {
    id: '2',
    name: 'Template 2',
    data: {
      assets: [],
      styles: [
        {
          selectors: [
            'container'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-fluid'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-xxl'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            width: '100%',
            'min-height': '75px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding-left': 'var(--bs-gutter-x, 0.75rem)',
            'padding-right': 'var(--bs-gutter-x, 0.75rem)'
          }
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '540px'
          },
          mediaText: '(min-width: 576px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '540px'
          },
          mediaText: '(min-width: 576px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '720px'
          },
          mediaText: '(min-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '960px'
          },
          mediaText: '(min-width: 992px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '1140px'
          },
          mediaText: '(min-width: 1200px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xxl'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-xl'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-lg'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-md'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container-sm'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            'container'
          ],
          style: {
            'max-width': '1320px'
          },
          mediaText: '(min-width: 1400px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            '#i17r'
          ],
          style: {
            'font-family': 'Helvetica, sans-serif',
            'background-color': '#0f266c'
          },
          wrapper: 1
        },
        {
          selectors: [
            '#iqz2'
          ],
          style: {
            color: '#ffffff',
            margin: '0px 0px 3rem 0px',
            'font-size': '20px',
            'font-weight': '300'
          }
        },
        {
          selectors: [
            '#idxq'
          ],
          style: {
            color: '#ffffff',
            margin: '0px 0px 1.5rem 0px',
            'font-size': '3rem',
            'font-weight': '300'
          }
        },
        {
          selectors: [
            '#iylt'
          ],
          style: {
            margin: '4rem auto 0rem auto'
          }
        },
        {
          selectors: [
            {
              name: 'gjs-row',
              private: 1
            }
          ],
          style: {
            display: 'flex',
            'flex-wrap': 'nowrap',
            'align-items': 'stretch',
            'padding-top': '10px',
            'padding-left': '10px',
            'padding-right': '10px',
            'padding-bottom': '10px',
            'justify-content': 'flex-start'
          }
        },
        {
          selectors: [
            {
              name: 'gjs-row',
              private: 1
            }
          ],
          style: {
            'flex-wrap': 'wrap'
          },
          mediaText: '(max-width: 768px)',
          atRuleType: 'media'
        },
        {
          selectors: [
            {
              name: 'gjs-cell',
              private: 1
            }
          ],
          style: {
            'flex-grow': '1',
            'flex-basis': '100%',
            'min-height': '75px'
          }
        },
        {
          selectors: [
            '#iyg8'
          ],
          style: {
            'text-align': 'center'
          }
        },
        {
          selectors: [
            '#iy3ji'
          ],
          style: {
            color: '#fff',
            display: 'inline-block',
            padding: '1rem 3rem 1rem 3rem',
            'background-color': '#53b5a9'
          }
        },
        {
          selectors: [
            '#i8zli'
          ],
          style: {
            color: 'black',
            width: '150px',
            height: '150px',
            display: 'inline-block',
            'text-align': 'left'
          }
        },
        {
          selectors: [
            '#id3ol'
          ],
          style: {
            color: '#ffffff',
            padding: '1rem 1rem 1rem 1rem',
            'font-weight': '300'
          }
        },
        {
          selectors: [
            '#i0s8x'
          ],
          style: {
            cursor: 'pointer'
          }
        },
        {
          selectors: [
            '#i44m'
          ],
          style: {
            gap: '2rem',
            padding: '0px 0px 0px 0px'
          }
        },
        {
          selectors: [
            '#i8zla'
          ],
          style: {
            padding: '1rem 1rem 1rem 1rem'
          }
        },
        {
          selectors: [
            '#ij0g5'
          ],
          style: {
            color: 'black',
            width: '150px',
            height: '150px',
            display: 'inline-block',
            'text-align': 'left'
          }
        },
        {
          selectors: [
            '#io7nl'
          ],
          style: {
            color: '#ffffff',
            padding: '1rem 1rem 1rem 1rem',
            'font-weight': '300'
          }
        },
        {
          selectors: [
            '#ijy52'
          ],
          style: {
            padding: '1rem 1rem 1rem 1rem'
          }
        },
        {
          selectors: [
            '#i7nli'
          ],
          style: {
            cursor: 'pointer'
          }
        },
        {
          selectors: [
            '#iuk2b'
          ],
          style: {
            'text-align': 'center'
          }
        },
        {
          selectors: [
            '#ifzm7'
          ],
          style: {
            color: 'black',
            width: '150px',
            height: '150px',
            display: 'inline-block',
            'text-align': 'left'
          }
        },
        {
          selectors: [
            '#iwgrw'
          ],
          style: {
            color: '#ffffff',
            padding: '1rem 1rem 1rem 1rem',
            'font-weight': '300'
          }
        },
        {
          selectors: [
            '#ia4r1'
          ],
          style: {
            padding: '1rem 1rem 1rem 1rem'
          }
        },
        {
          selectors: [
            '#ioauh'
          ],
          style: {
            cursor: 'pointer'
          }
        },
        {
          selectors: [
            '#ikijd'
          ],
          style: {
            'text-align': 'center'
          }
        },
        {
          selectors: [
            '#i7l9g'
          ],
          style: {
            color: '#fff',
            display: 'inline-block',
            padding: '1rem 3rem 1rem 3rem',
            'background-color': '#53b5a9'
          }
        },
        {
          selectors: [
            '#iqp9c'
          ],
          style: {
            color: '#fff',
            display: 'inline-block',
            padding: '1rem 3rem 1rem 3rem',
            'background-color': '#53b5a9'
          }
        }
      ],
      pages: [
        {
          frames: [
            {
              component: {
                type: 'wrapper',
                attributes: {
                  id: 'i17r'
                },
                components: [
                  {
                    type: 'responsive-container',
                    classes: [
                      'container'
                    ],
                    attributes: {
                      id: 'iylt'
                    },
                    components: [
                      {
                        tagName: 'h1',
                        type: 'text',
                        attributes: {
                          id: 'idxq'
                        },
                        components: [
                          {
                            type: 'textnode',
                            content: 'The new products'
                          }
                        ]
                      },
                      {
                        type: 'text',
                        attributes: {
                          id: 'iqz2'
                        },
                        components: [
                          {
                            type: 'textnode',
                            content: 'Find easily nearest shops and online shops to buy our new products.'
                          }
                        ]
                      },
                      {
                        name: 'Row',
                        droppable: '.gjs-cell',
                        resizable: {
                          bl: 0,
                          br: 0,
                          cl: 0,
                          cr: 0,
                          tc: 0,
                          tl: 0,
                          tr: 0,
                          minDim: 1
                        },
                        classes: [
                          {
                            name: 'gjs-row',
                            private: 1
                          }
                        ],
                        attributes: {
                          id: 'i44m'
                        },
                        components: [
                          {
                            name: 'Cell',
                            draggable: '.gjs-row',
                            'stylable-require': [
                              'flex-basis'
                            ],
                            unstylable: [
                              'width'
                            ],
                            resizable: {
                              bc: 0,
                              bl: 0,
                              br: 0,
                              cl: 0,
                              cr: 1,
                              tc: 0,
                              tl: 0,
                              tr: 0,
                              step: 0.2,
                              minDim: 1,
                              keyWidth: 'flex-basis',
                              currentUnit: 1
                            },
                            classes: [
                              {
                                name: 'gjs-cell',
                                private: 1
                              }
                            ],
                            attributes: {
                              id: 'iyg8'
                            },
                            components: [
                              {
                                type: 'c2b-widget-button',
                                attributes: {
                                  id: 'i0s8x',
                                  'data-widget-element': ''
                                },
                                components: [
                                  {
                                    attributes: {
                                      id: 'i8zla'
                                    },
                                    components: [
                                      {
                                        type: 'image',
                                        resizable: {
                                          ratioDefault: 1
                                        },
                                        attributes: {
                                          id: 'i8zli',
                                          src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+'
                                        }
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'id3ol'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'The first product'
                                          }
                                        ]
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'iy3ji'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'Where to buy?'
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            name: 'Cell',
                            draggable: '.gjs-row',
                            'stylable-require': [
                              'flex-basis'
                            ],
                            unstylable: [
                              'width'
                            ],
                            resizable: {
                              bc: 0,
                              bl: 0,
                              br: 0,
                              cl: 0,
                              cr: 1,
                              tc: 0,
                              tl: 0,
                              tr: 0,
                              step: 0.2,
                              minDim: 1,
                              keyWidth: 'flex-basis',
                              currentUnit: 1
                            },
                            classes: [
                              {
                                name: 'gjs-cell',
                                private: 1
                              }
                            ],
                            attributes: {
                              id: 'ikijd'
                            },
                            components: [
                              {
                                type: 'c2b-widget-button',
                                attributes: {
                                  id: 'ioauh',
                                  'data-widget-element': ''
                                },
                                components: [
                                  {
                                    attributes: {
                                      id: 'ia4r1'
                                    },
                                    components: [
                                      {
                                        type: 'image',
                                        resizable: {
                                          ratioDefault: 1
                                        },
                                        attributes: {
                                          id: 'ifzm7',
                                          src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+'
                                        }
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'iwgrw'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'The second product'
                                          }
                                        ]
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'i7l9g'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'Where to buy?'
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            name: 'Cell',
                            draggable: '.gjs-row',
                            'stylable-require': [
                              'flex-basis'
                            ],
                            unstylable: [
                              'width'
                            ],
                            resizable: {
                              bc: 0,
                              bl: 0,
                              br: 0,
                              cl: 0,
                              cr: 1,
                              tc: 0,
                              tl: 0,
                              tr: 0,
                              step: 0.2,
                              minDim: 1,
                              keyWidth: 'flex-basis',
                              currentUnit: 1
                            },
                            classes: [
                              {
                                name: 'gjs-cell',
                                private: 1
                              }
                            ],
                            attributes: {
                              id: 'iuk2b'
                            },
                            components: [
                              {
                                type: 'c2b-widget-button',
                                attributes: {
                                  id: 'i7nli',
                                  'data-widget-element': ''
                                },
                                components: [
                                  {
                                    attributes: {
                                      id: 'ijy52'
                                    },
                                    components: [
                                      {
                                        type: 'image',
                                        resizable: {
                                          ratioDefault: 1
                                        },
                                        attributes: {
                                          id: 'ij0g5',
                                          src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+'
                                        }
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'io7nl'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'The third product'
                                          }
                                        ]
                                      },
                                      {
                                        type: 'text',
                                        attributes: {
                                          id: 'iqp9c'
                                        },
                                        components: [
                                          {
                                            type: 'textnode',
                                            content: 'Where to buy?'
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              id: 'TuGl5OOVWF1DUMQz'
            }
          ],
          id: 'bQI3J4n7l0nocc0O',
          type: 'main'
        }
      ]
    }
  }
]
