<template>
  <div>
    <!-- Form element -->
    <div @click="showModal">
      <slot name="element" v-bind="{ product: value, products: value }">
        <!-- Single -->
        <template v-if="!multiple">
          <div class="form-control h-auto">
            <div class="d-flex align-items-center justify-content-between gutter-x-sm">
              <div v-if="!value">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else class="flex-fill d-flex justify-content-between">
                {{ value.productLanguageDatas[0].name }}
                <div>
                  <region-flag :code="value.group.region.code" />
                  {{ value.group.name }}
                </div>
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>

        <!-- Multiple -->
        <template v-else>
          <div class="form-control h-auto">
            <div class="d-flex align-items-center justify-content-between gutter-x-sm">
              <div v-if="value.length === 0">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else style="min-width: 0;">
                <div class="d-flex flex-wrap gutter-xs">
                  <div
                    v-for="product in value"
                    :key="product.id"
                    class="btn-group">
                    <div class="btn btn-light btn-xs text-left">
                      {{ product.productLanguageDatas[0].name }}
                    </div>
                    <div class="btn btn-light btn-xs px-2 d-flex align-items-center" @click.stop="unselectProduct(product)">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>
      </slot>
    </div>

    <!-- Modal -->
    <b-modal
      :id="`product-modal-${_uid}`"
      size="xl"
      :title="$t('views.productModalSelect.title')"
      hide-footer
      no-fade
      @shown="$refs.filterName.focus()">
      <!-- Filters -->
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.productLanguageData.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            ref="filterName"
            :placeholder="$t('attributes.productLanguageData.name')">
        </div>

        <div>
          <label for="filter-raw_packaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</label>
          <input
            v-model="filters.raw_packaging"
            type="text"
            class="form-control"
            id="filter-raw_packaging"
            :placeholder="$t('attributes.productLanguageData.rawPackaging')">
        </div>

        <div>
          <label for="filter-reference">{{ $t('attributes.product.reference') }}</label>
          <input
            v-model="filters.reference"
            type="text"
            class="form-control"
            id="filter-reference"
            :placeholder="$t('attributes.product.reference')">
        </div>

        <div>
          <label for="filter-ean">{{ $t('attributes.product.ean') }}</label>
          <input
            v-model="filters.ean"
            type="text"
            class="form-control"
            id="filter-ean"
            :placeholder="$t('attributes.product.ean')">
        </div>

        <div>
          <label for="filter-upc">{{ $t('attributes.product.upc') }}</label>
          <input
            v-model="filters.upc"
            type="text"
            class="form-control"
            id="filter-upc"
            :placeholder="$t('attributes.product.upc')">
        </div>

        <div>
          <label for="filter-active">{{ $t('attributes.product.active') }}</label>
          <select v-model="filters.active" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">Inactive</option>
            <option :value="true">Active</option>
          </select>
        </div>

        <div>
          <label for="filter-has-img">{{ $t('attributes.product.img') }}</label>
          <select v-model="filters.hasImg" class="custom-select">
            <option :value="null">{{ $t('shared.placeholders.select') }}</option>
            <option :value="false">{{ $t('views.products.index.withoutImage') }}</option>
            <option :value="true">{{ $t('views.products.index.withImage') }}</option>
          </select>
        </div>

        <div>
          <label for="filter-region">{{ $t('attributes.product.region') }}</label>
          <key-multiselect
            v-model="filters.regionIds"
            :multiple="true"
            :close-on-select="false"
            :options="regionOptions"
            label="name"
            track-by="id"
            :placeholder="$t('shared.placeholders.select')"
            :show-labels="false"
            :loading="regionsLoading">
            <template slot="option" slot-scope="props">
              <region-flag :code="props.option.code" />
              {{ props.option.name }}
            </template>
            <template v-slot:tag="{ option, remove }">
              <span class="multiselect__tag">
                <region-flag :code="option.code" />
                {{ option.name }}
                <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
              </span>
            </template>
          </key-multiselect>
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.product.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>
      </div>

      <hr>

      <!-- Products -->
      <h6>{{ $tc('views.productModalSelect.results', productsCount) }}</h6>

      <div class="table-responsive">
        <table class="table table-hover" :aria-busy="productsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.product.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th class="table-col-shrink">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
              </th>
              <th-sortable sort-key="name">{{ $t('attributes.productLanguageData.name') }}</th-sortable>
              <th-sortable sort-key="raw_packaging" class="ellipsis" style="max-width: 120px;">{{ $t('attributes.productLanguageData.rawPackaging') }}</th-sortable>
              <th-sortable sort-key="reference">{{ $t('attributes.product.reference') }}</th-sortable>
              <th-sortable sort-key="ean">{{ $t('attributes.product.ean') }}</th-sortable>
              <th-sortable sort-key="upc">{{ $t('attributes.product.upc') }}</th-sortable>
              <th class="table-col-shrink">{{ $t('attributes.product.group') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <tbody v-if="productsError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="productsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!productsLoading && products.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noProduct') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <!-- Product rows -->
            <tr
              v-for="{ product, selected } in productsWithStatus"
              :key="product.id"
              @click="productClick(product)"
              class="cursor-pointer"
              :class="{ 'bg-light': selected }">
              <th>{{ product.id }}</th>
              <td class="text-center">
                <dot :active="product.active" />
              </td>
              <td :id="`product-${product.id}-img`">
                <span v-if="product.hasImg">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-fw"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                  <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                    <img :src="product.img.medium.url" style="height: 200px;" />
                  </b-tooltip>
                </span>
                <span v-else class="text-danger">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-fw"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                </span>
              </td>
              <b-tooltip :target="`product-${product.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                <img :src="product.img.medium.url" style="height: 200px;" />
              </b-tooltip>
              <td class="ellipsis" style="max-width: 200px;">
                {{ product.productLanguageDatas[0].name }}
              </td>
              <td class="ellipsis" style="max-width: 120px;">
                <small>{{ product.productLanguageDatas[0].rawPackaging }}</small>
              </td>
              <td><small>{{ product.reference }}</small></td>
              <td><small>{{ product.ean }}</small></td>
              <td><small>{{ product.upc }}</small></td>
              <td class="ellipsis" style="max-width: 150px;">
                <region-flag :code="product.group.region.code" />
                {{ product.group.name }}
              </td>
              <td>
                <svg v-if="selected" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
              </td>
            </tr>

            <!-- Loading -->
            <template v-if="productsLoading">
              <tr>
                <td :colspan="colspan" class="text-center">
                  <md-spinner md-indeterminate></md-spinner>
                </td>
              </tr>
            </template>

            <!-- Next page -->
            <tr v-if="productsHasNextPage && !productsLoading">
              <td :colspan="colspan" class="bg-primary text-white cursor-pointer" @click="loadProductsPage">
                {{ $t('views.productModalSelect.loadMore') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MdSpinner from './MdSpinner.vue'
import RegionFlag from './RegionFlag.vue'
import Dot from './Dot.vue'
import KeyMultiselect from './KeyMultiselect.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BModal, VBModal, BTooltip } from 'bootstrap-vue'
import ThSortable from '../shared/ThSortable.vue'
import debounce from 'lodash-es/debounce'

export default {
  name: 'ProductModalSelect',
  inheritAttrs: false,
  components: { MdSpinner, RegionFlag, Dot, KeyMultiselect, GroupModalSelect, BModal, BTooltip, ThSortable },
  directives: { 'b-modal': VBModal },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: undefined
    },
    filterGroup: {
      type: Object,
      default: undefined
    },
    exceptProductIds: {
      type: Array,
      default: () => []
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      modalInitialized: false,
      products: [],
      productsLoading: false,
      productsError: null,
      productsCount: 0,
      productsPage: 1,
      productsPerPage: 20,
      productsHasNextPage: false,
      productsAbortController: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      filters: {
        name: null,
        raw_packaging: null,
        reference: null,
        ean: null,
        upc: null,
        active: null,
        hasImg: null,
        regionIds: [],
        groups: []
      },
      colspan: 10
    }
  },
  computed: {
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    },
    // Products with selected status
    productsWithStatus: function() {
      return this.products.map(product => {
        let selected
        if (!this.multiple) {
          selected = this.value && this.value.id === product.id
        } else {
          selected = this.value.map(product => product.id).includes(product.id)
        }

        return {
          product,
          selected
        }
      })
    }
  },
  methods: {
    // Show modal
    showModal: function() {
      this.$root.$emit('bv::show::modal', `product-modal-${this._uid}`)
      if (!this.modalInitialized) {
        this.modalInitialized = true
        this.loadRegions()
        this.loadProductsPage()
      }
    },
    // Load regions
    loadRegions: async function() {
      this.regionsLoading = true
      this.regionsError = null

      const query = `query productModalSelectRegions {
        regions {
          id
          code
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query })
        })
        const json = await res.json()

        if (!res.ok) {
          this.regionsError = json.errors
        } else {
          this.regions = Object.freeze(json.data.regions)
        }
      } catch (err) {
        this.error = err
      } finally {
        this.regionsLoading = false
      }
    },
    // Load products page
    loadProductsPage: async function() {
      if (this.productsAbortController) {
        this.productsAbortController.abort()
      }
      this.productsAbortController = new AbortController()

      this.productsLoading = true
      this.productsError = null

      const query = `query productModalSelectProducts ($page: Int!, $perPage: Int!, $groupId: Int, $name: String, $rawPackaging: String, $reference: String, $ean: String, $upc: String, $active: Boolean, $regionIds: [Int!], $groupIds: [Int!], $exceptProductIds: [Int!], $sort: String, $sortDirection: String) {
        productsPage(page: $page, perPage: $perPage, groupId: $groupId, name: $name, rawPackaging: $rawPackaging, reference: $reference, ean: $ean, upc: $upc, active: $active, regionIds: $regionIds, groupIds: $groupIds, exceptProductIds: $exceptProductIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          hasNextPage
          nodes {
            id
            active
            reference
            ean
            upc
            active
            img
            hasImg
            productLanguageDatas {
              id
              name
              rawPackaging
            }
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          signal: this.productsAbortController.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: {
              page: this.productsPage,
              perPage: this.productsPerPage,
              groupId: this.groupId,
              name: this.filters.name,
              rawPackaging: this.filters.raw_packaging,
              reference: this.filters.reference,
              ean: this.filters.ean,
              upc: this.filters.upc,
              active: this.filters.active,
              hasImg: this.filters.hasImg,
              regionIds: this.filters.regionIds.length > 0 ? this.filters.regionIds : undefined,
              groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
              exceptProductIds: this.exceptProductIds,
              sort: this.$route.query.sort,
              sortDirection: this.$route.query.sort_direction
            }
          })
        })
        const json = await res.json()

        if (!res.ok) {
          this.productsError = json.errors
        } else {
          this.products = this.products.concat(json.data.productsPage.nodes)
          this.productsCount = json.data.productsPage.nodesCount
          this.productsHasNextPage = json.data.productsPage.hasNextPage
          if (this.productsHasNextPage) this.productsPage++
        }
      } catch (err) {
        this.error = err
      } finally {
        this.productsLoading = false
      }
    },
    // Load products debounced
    loadProductsPageDebounced: debounce(function() {
      this.products = []
      this.productsPage = 1
      this.loadProductsPage()
    }, 200),
    // Product click
    productClick: function(product) {
      if (!this.multiple) {
        // Single: emit input & close
        this.$emit('input', product)

        if (this.closeOnSelect) {
          this.$root.$emit('bv::hide::modal', `product-modal-${this._uid}`)
        }
      } else {
        // Multiple: toggle product & emit input
        let products
        const index = this.value.map(product => product.id).indexOf(product.id)

        if (index === -1) {
          products = this.value.concat(product)
        } else {
          products = this.value.slice()
          products.splice(index, 1)
        }
        this.$emit('input', products)
      }
    },
    // Unselect a product
    unselectProduct: function(product) {
      const index = this.value.map(product => product.id).indexOf(product.id)

      if (index === -1) {
        throw new Error(`Unselect product #${product.id} failed`)
      } else {
        const products = this.value.slice()
        products.splice(index, 1)
        this.$emit('input', products)
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function() {
        this.loadProductsPageDebounced()
      }
    },
    filterGroup: function(group) {
      this.filters.groups = [group]
    },
    exceptProductIds: function() {
      this.loadProductsPageDebounced()
    },
    $route: function() {
      this.loadProductsPageDebounced()
    }
  }
}
</script>
