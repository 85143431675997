
<div>
  <transition name="fade" mode="out-in">
    <div v-if="userLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="userError" class="alert alert-danger">
      <template v-if="userError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <template v-if="action === 'new' || action === 'edit'">
          <div class="form-group row">
            <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('attributes.user.email') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="user.email" type="email" class="form-control" :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.email }" id="inputEmail" :placeholder="$t('attributes.user.email')">
              <div v-if="userSubmitErrors && userSubmitErrors.email" class="invalid-feedback">{{ tErrors('user', 'email', userSubmitErrors.email) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ $t('attributes.user.roles') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <multiselect
                v-model="user.roles"
                :multiple="true"
                :close-on-select="false"
                :options="['client', 'admin', 'investor', 'news_reader', 'michelin', 'validations_reader', 'product_distribution_reader', 'estimations_reader']"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.roles }"
                />
              <div v-if="userSubmitErrors && userSubmitErrors.roles" class="invalid-feedback d-block">{{ tErrors('user', 'roles', userSubmitErrors.roles) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="checkboxActive" class="col-sm-2">{{ $t('attributes.user.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="user.active" type="checkbox" class="custom-control-input" id="checkboxActive">
                <label class="custom-control-label" for="checkboxActive"></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.user.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="user.group"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="userSubmitErrors && userSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('user', 'group', userSubmitErrors.group) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="select-locale" class="col-sm-2 col-form-label">{{ $t('attributes.user.locale') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="user.locale" class="custom-select">
                <option v-for="locale in locales" :key="locale" :value="locale">{{ languageName(locale, locale) }}</option>
              </select>
              <div v-if="userSubmitErrors && userSubmitErrors.locale" class="invalid-feedback">{{ tErrors('user', 'locale', userSubmitErrors.locale) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-unauthorized-groups" class="col-sm-2 col-form-label">{{ $t('attributes.user.unauthorizedGroups') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="user.unauthorizedGroups"
                :multiple="true"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.unauthorizedGroups }"
                id="multiselect-unauthorized-groups" />
              <div v-if="userSubmitErrors && userSubmitErrors.unauthorizedGroups" class="invalid-feedback d-block">{{ tErrors('user', 'unauthorizedGroups', userSubmitErrors.unauthorizedGroups) }}</div>
            </div>
            <div class="col col-form-label">
              <small class="text-muted">{{ $t('attributes.user.unauthorizedGroupsHelp') }}</small>
            </div>
          </div>
        </template>

        <template v-else-if="action === 'selfEdit'">
          <div class="form-group row">
            <label for="input-password" class="col-sm-2 col-form-label">{{ $t('attributes.user.newPassword') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input
                v-model="user.password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.password }"
                id="input-password"
                :placeholder="$t('attributes.user.newPassword')">
              <div v-if="userSubmitErrors && userSubmitErrors.password" class="invalid-feedback">{{ tErrors('user', 'password', userSubmitErrors.password) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="input-password-confirmation" class="col-sm-2 col-form-label">{{ $t('attributes.user.passwordConfirmation') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input
                v-model="user.passwordConfirmation"
                type="password"
                class="form-control"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.password_confirmation }"
                id="input-password-confirmation"
                :placeholder="$t('attributes.user.passwordConfirmation')">
              <div v-if="userSubmitErrors && userSubmitErrors.password_confirmation" class="invalid-feedback">{{ tErrors('user', 'password_confirmation', userSubmitErrors.password_confirmation) }}</div>
            </div>
          </div>

          <hr>

          <div class="form-group row">
            <label for="select-locale" class="col-sm-2 col-form-label">{{ $t('attributes.user.locale') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <select v-model="user.locale" class="custom-select">
                <option v-for="locale in locales" :key="locale" :value="locale">{{ languageName(locale, locale) }}</option>
              </select>
              <div v-if="userSubmitErrors && userSubmitErrors.locale" class="invalid-feedback">{{ tErrors('user', 'locale', userSubmitErrors.locale) }}</div>
            </div>
          </div>

          <div class="form-group row" v-if="(!demoMode && user.roles.includes('admin'))">
            <label for="multiselect-act-as-group" class="col-sm-2 col-form-label">{{ $t('attributes.user.demoGroup') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="user.actAsGroup"
                :class="{ 'is-invalid' : userSubmitErrors && userSubmitErrors.actAsGroup }"
                id="multiselect-act-as-group" />
              <div v-if="userSubmitErrors && userSubmitErrors.actAsGroup" class="invalid-feedback d-block">{{ tErrors('user', 'actAsGroup', userSubmitErrors.actAsGroup) }}</div>
            </div>
          </div>
        </template>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="userSubmitLoading">
            <md-spinner v-if="userSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit' || action === 'selfEdit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>

    </div>
  </transition>
</div>
