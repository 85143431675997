<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="eventAggregatesError && eventAggregatesError.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- First row with group selector, date range picker, export button -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Group tree select -->
      <div class="flex-fill flex-basis-0">
        <treeselect
          :limit-text="treeSelectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelect"
          :searchable="false"
          style="max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Date range picker -->
      <div class="flex-fill flex-basis-0 text-center">
        <stats-date-range-picker v-model="dateRange" />
      </div>

      <!-- Export button -->
      <div class="flex-fill flex-basis-0 text-right">
        <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
          <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
            {{ $t('shared.actions.xlsxExport') }}
          </button>
        </span>
      </div>
    </div>

    <!-- Drive/delivery table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover" id="table-stats-retailers">
        <thead>
          <tr>
            <th @click="setSort('retailer')" :aria-sort="ariaSort('retailer')">{{ $t('views.stats.retailers.retailer') | capitalize }}</th>
            <!-- Add hidden region column only for export  -->
            <th class="d-none">{{ $t('views.stats.retailers.region') }}</th>
            <th @click="setSort('service')" :aria-sort="ariaSort('service')">{{ $t('views.stats.retailers.service') | capitalize }}</th>
            <th @click="setSort('redirection')" :aria-sort="ariaSort('redirection')" class="data-cell">{{ $t('shared.eventActions.widget.redirection') }}</th>
            <th @click="setSort('redirection_percentage')" :aria-sort="ariaSort('redirection_percentage')" class="data-cell">{{ $t('shared.eventActions.widget.redirection_percentage') }}</th>
            <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
              <th @click="setSort('redirection_revenues')" :aria-sort="ariaSort('redirection_revenues')" class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
            <template v-if="userCanReadValidations">
              <th @click="setSort('validation')" :aria-sort="ariaSort('validation')" class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.validation') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th @click="setSort('validation_percentage')" :aria-sort="ariaSort('validation_percentage')" class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.validation_percentage') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th @click="setSort('validation_rate')" :aria-sort="ariaSort('validation_rate')" class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.validation_rate') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th @click="setSort('validation_revenues')" :aria-sort="ariaSort('validation_revenues')" class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.revenue') | capitalize }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
            <template v-if="userCanReadEstimations">
              <th @click="setSort('estimated_sales')" :aria-sort="ariaSort('estimated_sales')" class="data-cell th-shield">
                {{ $t('views.stats.retailers.estimatedSales') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th @click="setSort('estimated_sales_rate')" :aria-sort="ariaSort('estimated_sales_rate')" class="data-cell th-shield">
                {{ $t('views.stats.retailers.estimatedSalesRate') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th @click="setSort('estimated_revenues')" :aria-sort="ariaSort('estimated_revenues')" class="data-cell th-shield">
                {{ $t('views.stats.retailers.estimatedRevenues') }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="groupsLoading || tableDataLoading">
            <tr>
              <td :colspan="colspan" class="text-center">
                <md-spinner md-indeterminate />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="font-weight-semibold bg-light">
              <td>Total</td>
              <td class="d-none"></td>
              <td></td>
              <td class="text-right">{{ tableDataTotals.redirection | number }}</td>
              <td class="text-right">{{ 1 | percentage }}</td>
              <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
                <td class="text-right">
                  <!-- can't sum amounts with different currencies -->
                  <template v-if="group && !international">
                    {{ tableDataTotals.redirection_revenues | price(group.region) }}
                  </template>
                </td>
              </template>
              <template v-if="userCanReadValidations">
                <td class="text-right">{{ tableDataTotals.validation | number }}</td>
                <td class="text-right">{{ 1 | percentage }}</td>
                <td class="text-right">{{ tableDataTotals.validation_rate | percentage }}</td>
                <td class="text-right">
                  <!-- can't sum amounts with different currencies -->
                  <template v-if="group && !international">
                    {{ tableDataTotals.validation_revenues | price(group.region) }}
                  </template>
                </td>
              </template>
              <template v-if="userCanReadEstimations">
                <td class="text-right">
                  {{ tableDataTotals.estimated_sales | number }}
                </td>
                <td class="text-right">
                  {{ tableDataTotals.estimated_sales_rate | percentage }}
                </td>
                <td class="text-right">
                  <!-- can't sum amounts with different currencies -->
                  <template v-if="group && !international">
                    {{ tableDataTotals.estimated_revenues | price(group.region) }}
                  </template>
                </td>
              </template>
            </tr>
            <tr v-for="retailer in onlineRetailersSorted" :key="retailer.id">
              <td>
                <div class="d-inline-block position-relative mr-1">
                  <img :src="retailer.imgSmallUrl" class="td-img-sm">
                  <region-flag v-if="international || hasForeignRetailers" :code="retailer.region.code" class="sub-flag" />
                </div>
                {{ retailer.name }}
              </td>
              <!-- Add hidden data region column only for export -->
              <td class="d-none">
                {{ retailer.region.code }}
              </td>
              <td><span class="badge badge-light">{{ retailer.service | retailerService }}</span></td>
              <td class="text-right">
                {{ retailer.stats.redirection | number }}
              </td>
              <td class="text-right">
                {{ retailer.stats.redirection_percentage | percentage }}
              </td>
              <template v-if="(userIsAdmin && !demoMode) || userIsMichelin">
                <td class="text-right">
                  {{ retailer.stats.redirection_revenues | price(retailer.region) }}
                </td>
              </template>
              <template v-if="userCanReadValidations">
                <td class="text-right">
                  {{ retailer.stats.validation | number }}
                </td>
                <td class="text-right">
                  {{ retailer.stats.validation_percentage | percentage }}
                </td>
                <td class="text-right">
                  {{ retailer.stats.validation_rate | percentage }}
                </td>
                <td class="text-right">
                  {{ retailer.stats.validation_revenues | price(retailer.region) }}
                </td>
              </template>
              <template v-if="userCanReadEstimations">
                <td class="text-right">
                  {{ retailer.stats.estimated_sales | number }}
                </td>
                <td class="text-right">
                  {{ retailer.stats.estimated_sales_rate | percentage }}
                </td>
                <td class="text-right">
                  {{ retailer.stats.estimated_revenues | price(retailer.region) }}
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Store Export button -->
    <div v-if="hasStoreActivated" class="mb-3 d-flex">
      <div class="flex-fill flex-basis-0 text-right">
        <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
          <button @click="storeExportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
            {{ $t('shared.actions.xlsxExport') }}
          </button>
        </span>
      </div>
    </div>

    <!-- Store table -->
    <div v-if="hasStoreActivated" class="table-responsive">
      <table class="table table-bordered table-hover" id="table-stats-retailers-store">
        <thead>
          <tr>
            <th @click="setStoresSort('retailer')" :aria-sort="storesAriaSort('retailer')">{{ $t('views.stats.retailers.retailer') | capitalize }}</th>
            <!-- Add hidden region column only for export  -->
            <th class="d-none">{{ $t('views.stats.retailers.region') }}</th>
            <th @click="setStoresSort('store_selection')" :aria-sort="storesAriaSort('store_selection')" class="data-cell">{{ $t('shared.eventActions.widget.store_selection') | capitalize }}</th>
            <th @click="setStoresSort('store_selection_percentage')" :aria-sort="storesAriaSort('store_selection_percentage')" class="text-center data-cell">{{ $t('shared.eventActions.widget.store_selection_percentage') | capitalize }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="groupsLoading || tableDataLoading">
            <tr>
              <td colspan="3" class="text-center">
                <md-spinner md-indeterminate />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="font-weight-semibold bg-light">
              <td>Total</td>
              <td class="d-none"></td>
              <td class="text-right">{{ tableDataTotals.store_selection | number }}</td>
              <td class="text-right">{{ 1 | percentage }}</td>
            </tr>
            <tr v-for="retailer in offlineRetailersSorted" :key="retailer.id">
              <td>
                <div class="d-inline-block position-relative mr-1">
                  <img :src="retailer.imgSmallUrl" class="td-img-sm">
                  <region-flag v-if="international || hasForeignRetailers" :code="retailer.region.code" class="sub-flag" />
                </div>
                {{ retailer.name }}
              </td>
              <td class="d-none">
                {{ retailer.region.code }}
              </td>
              <td class="text-right">
                {{ retailer.stats.store_selection | number }}
              </td>
              <td class="text-right">
                {{ retailer.stats.store_selection_percentage | percentage }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Pie charts -->
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <div
            v-if="groupsLoading"
            class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
            style="z-index: 1;">
          <md-spinner md-indeterminate />
          </div>
          <div class="d-flex flex-column gutter" style="min-height: 240px;">
            <div v-for="(pieChart, index) in pieChartList" :key="index">
              <highcharts
                :options="chartOptions[index]"
                class="chart"
                :aria-busy="eventAggregatesLoading"
                style="width: 100%; height: 350px;" />
              <div class="text-center">
                <b>
                  {{ pieChart.label }}
                  <sup v-if="pieChart.action === 'validation' && userIsAdmin" class="text-warning">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                  </sup>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../../shared/MdSpinner.vue'
import RegionFlag from '../../shared/RegionFlag.vue'
import StatsDateRangePicker from '../../shared/StatsDateRangePicker.vue'
import Treeselect from '@riophae/vue-treeselect'
import { VBTooltip } from 'bootstrap-vue'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import LocaleCurrency from 'locale-currency'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import i18n from '../../../i18n'

export default {
  components: { MdSpinner, RegionFlag, StatsDateRangePicker, Treeselect, highcharts: Chart },
  directives: { 'b-tooltip': VBTooltip },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      eventAggregates: [],
      eventAggregatesLoading: false,
      eventAggregatesError: null,
      eventAggregatesDaily: [],
      eventAggregatesDailyLoading: false,
      eventAggregatesDailyError: null,
      tableDataLoading: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedGroupIds: [],
      chartColors: {}, // Colors dictionary for pie charts
      colorSeq: 0, // Colors sequence to assign new colors
      sortKey: null,
      sortDirection: null,
      storesSortKey: null,
      storesSortDirection: null
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userCanReadEstimations: function() {
      return this.userIsAdmin || this.roles.includes('estimations_reader')
    },
    demoMode: function() {
      return this.$route.query.demo === 'true'
    },
    userCanReadValidations: function() {
      return this.userIsAdmin || this.roles.includes('validations_reader')
    },
    userIsMichelin: function() {
      return this.roles.includes('michelin')
    },
    hasStoreActivated: function() {
      return this.group ? this.group.hasStoreActivated : null
    },
    colspan: function() {
      let tableColsLength = 5

      if (this.userIsAdmin || this.userIsMichelin) {
        tableColsLength += 1
      }

      if (this.userCanReadValidations) {
        tableColsLength += 4
      }

      if (this.userIsAdmin) {
        tableColsLength += 3
      }

      return tableColsLength
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Check the presence of a foreign retailer
    hasForeignRetailers: function() {
      return this.retailers.some(retailer => retailer.region.code !== this.group.region.code)
    },
    // Group helpers
    groupsTree: function() {
      let groupsTree = {}

      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })[0]
      }

      return groupsTree
    },
    // Format group tree for vue treeselect component
    groupsTreeSelect: function() {
      let groupsTreeSelect = []

      if (this.groups.length > 0) {
        groupsTreeSelect = arrayToTree(this.groups.map(group => {
          return {
            id: group.id,
            label: {
              name: group.name,
              regionCode: group.region.code
            },
            parent_id: group.parentId
          }
        }))
      }

      return groupsTreeSelect
    },
    // Used to fastly check if a group is selected (avoid iterating over selectedGroupIds again and again)
    selectedGroupIdsSet: function() {
      return new Set(this.selectedGroupIds)
    },
    groupsMap: function() {
      return this.groups.reduce((acc, group) => {
        acc[group.id] = group
        return acc
      }, {})
    },
    // Convert daily event stats into total values as a hash
    analyticsDataEventsHash: function() {
      const hash = {}

      this.eventAggregates.forEach(item => {
        const groupId = item._id.groupId
        hash[item._id.retailerId] = hash[item._id.retailerId] || {}
        hash[item._id.retailerId][item._id.action] = hash[item._id.retailerId][item._id.action] || {}
        hash[item._id.retailerId][item._id.action][groupId] = hash[item._id.retailerId][item._id.action][groupId] || { count: 0, amount: 0 }
        hash[item._id.retailerId][item._id.action][groupId].count += item.count
        if (item.amount) hash[item._id.retailerId][item._id.action][groupId].amount += item.amount
      })

      return hash
    },
    analyticsDataDailyEventsHash: function() {
      const hash = {}

      this.eventAggregatesDaily.forEach(item => {
        const date = `${item._id.year}-${item._id.month}-${item._id.day}`
        item.data.forEach(data => {
          const { retailerId, groupId, action, count, amount } = data

          hash[date] = hash[date] || {}
          hash[date][retailerId] = hash[date][retailerId] || {}
          hash[date][retailerId][groupId] = hash[date][retailerId][groupId] || {}
          hash[date][retailerId][groupId][action] = hash[date][retailerId][groupId][action] || { count: 0, amount: 0 }
          hash[date][retailerId][groupId][action].count += count
          if (amount) hash[date][retailerId][groupId][action].amount += amount
        })
      })

      return hash
    },
    retailersSalesRateDailyHash: function() {
      const hash = {}

      for (const date in this.analyticsDataDailyEventsHash) {
        hash[date] = hash[date] || { count: 0, amount: 0 }

        let validationSumCount = 0
        let redirectionSumCount = 0

        let validationSumAmount = 0
        let redirectionSumAmount = 0

        for (const retailerId in this.analyticsDataDailyEventsHash[date]) {
          for (const groupId in this.analyticsDataDailyEventsHash[date][retailerId]) {
            if (!this.selectedGroupIdsSet.has(parseInt(groupId))) continue

            for (const action in this.analyticsDataDailyEventsHash[date][retailerId][groupId]) {
              if (action === 'validation') {
                const data = this.analyticsDataDailyEventsHash[date][retailerId][groupId]

                validationSumCount += data.count

                if (data.amount) validationSumAmount += data.amount
              }

              if (action === 'redirection') {
                const data = this.analyticsDataDailyEventsHash[date][retailerId][groupId]
                if (data.validation && data.validation.count > 0) {
                  redirectionSumCount += data.redirection.count

                  if (data.redirection.amount) redirectionSumAmount += data.redirection.amount
                }
              }
            }
          }
        }

        if (validationSumCount > 0 && redirectionSumCount > 0) {
          hash[date].count = parseFloat((validationSumCount / redirectionSumCount).toFixed(4))
        }

        if (validationSumAmount > 0 && redirectionSumAmount > 0) {
          hash[date].amount = parseFloat((validationSumAmount / redirectionSumAmount).toFixed(4))
        }
      }

      return hash
    },
    retailersEstimatedSalesHash: function() {
      const hash = {}

      for (const retailer of this.retailers) {
        hash[retailer.id] = hash[retailer.id] || { count: 0, amount: 0 }
        hash[retailer.id].count = this.retailerTotalEstimatedSales(retailer.id, 'count')
        hash[retailer.id].amount = this.retailerTotalEstimatedSales(retailer.id, 'amount')
      }

      return hash
    },
    // Table data: depends on analyticsDataEventsHash and selectedGroupIds
    tableData: function() {
      const tableData = {}

      for (const retailerId in this.analyticsDataEventsHash) {
        for (const action in this.analyticsDataEventsHash[retailerId]) {
          for (const groupId in this.analyticsDataEventsHash[retailerId][action]) {
            if (this.selectedGroupIdsSet.has(parseInt(groupId))) {
              // Retailer total
              const item = this.analyticsDataEventsHash[retailerId][action][groupId]
              tableData[retailerId] = tableData[retailerId] || {}
              tableData[retailerId][action] = tableData[retailerId][action] || { count: 0, amount: 0 }
              tableData[retailerId][action].count += item.count
              if (item.amount) tableData[retailerId][action].amount += item.amount
            }
          }
        }
      }

      return tableData
    },
    retailersFiltered: function() {
      return this.retailers.filter(retailer => {
        return !!this.tableData[retailer.id]
      })
    },
    // Retailers with stats from event aggregates
    retailersWithStats: function() {
      return this.retailersFiltered.map(retailer => {
        return {
          ...retailer,
          stats: {
            redirection: this.retailerActionCount(retailer.id, 'redirection'),
            redirection_percentage: this.retailerActionPercentage(retailer.id, 'redirection'),
            redirection_revenues: this.retailerActionAmount(retailer.id, 'redirection_product'),
            validation: this.retailerActionCount(retailer.id, 'validation'),
            validation_percentage: this.retailerActionPercentage(retailer.id, 'validation'),
            validation_rate: this.retailerActionRate(retailer.id, 'validation', 'redirection'),
            validation_revenues: this.retailerActionAmount(retailer.id, 'validation_product'),
            store_selection: this.retailerActionCount(retailer.id, 'store_selection'),
            store_selection_percentage: this.retailerActionPercentage(retailer.id, 'store_selection'),
            estimated_sales: this.retailersEstimatedSalesHash[retailer.id].count,
            estimated_sales_rate: this.retailerEstimatedSalesRate(retailer.id),
            estimated_revenues: this.retailersEstimatedSalesHash[retailer.id].amount
          }
        }
      })
    },
    // Table data with totals
    tableDataTotals: function() {
      return {
        redirection: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.redirection, 0),
        redirection_revenues: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.redirection_revenues, 0),
        validation: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.validation, 0),
        validation_rate: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.validation, 0) / this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.redirection, 0),
        validation_revenues: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.validation_revenues, 0),
        store_selection: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.store_selection, 0),
        estimated_sales: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.estimated_sales, 0),
        estimated_sales_rate: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.estimated_sales, 0) / this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.redirection, 0),
        estimated_revenues: this.retailersWithStats.reduce((acc, retailer) => acc + retailer.stats.estimated_revenues, 0)
      }
    },
    onlineRetailersSorted: function() {
      const retailersSorted = this.retailersWithStats.filter(retailer => ['drive', 'delivery'].includes(retailer.service))

      const sortKey = this.sortKey || 'redirection'
      const sortDirection = this.sortDirection || 'desc'

      retailersSorted.sort((retailer1, retailer2) => {
        const sortDirectionFactor = sortDirection === 'asc' ? 1 : -1

        if (sortKey === 'retailer') return sortDirectionFactor * retailer1.name.localeCompare(retailer2.name)
        else if (sortKey === 'service') return sortDirectionFactor * retailer1.service.localeCompare(retailer2.service)
        else {
          const val1 = retailer1.stats[sortKey] || 0
          const val2 = retailer2.stats[sortKey] || 0

          return sortDirectionFactor * (val1 - val2)
        }
      })

      return retailersSorted
    },
    offlineRetailersSorted: function() {
      const retailersSorted = this.retailersWithStats.filter(retailer => retailer.service === 'store')

      const sortKey = this.storesSortKey || 'store_selection'
      const sortDirection = this.storesSortDirection || 'desc'

      retailersSorted.sort((retailer1, retailer2) => {
        const sortDirectionFactor = sortDirection === 'asc' ? 1 : -1

        if (sortKey === 'retailer') return sortDirectionFactor * retailer1.name.localeCompare(retailer2.name)
        else {
          const val1 = retailer1.stats[sortKey] || 0
          const val2 = retailer2.stats[sortKey] || 0

          return sortDirectionFactor * (val1 - val2)
        }
      })

      return retailersSorted
    },
    // Pie chart list
    pieChartList: function() {
      const pieChartList = []

      if (this.group) {
        if (this.hasStoreActivated) {
          pieChartList.push({
            action: 'store_selection',
            label: this.$t('shared.eventActions.widget.store_selection'),
            showServiceBadge: false
          })
        }

        pieChartList.push({
          action: 'redirection',
          label: this.$t('shared.eventActions.widget.redirection'),
          showServiceBadge: true
        })

        if (this.userCanReadValidations) {
          pieChartList.push({
            action: 'validation',
            label: this.$t('shared.eventActions.widget.validation'),
            showServiceBadge: true
          })
        }
      }

      return pieChartList
    },
    // Chart options
    chartOptions: function() {
      const chartOptions = []
      const percentageFilter = this.$options.filters.percentage

      for (const [, pieChart] of this.pieChartList.entries()) {
        chartOptions.push({
          chart: { type: 'pie' },
          title: null,
          credits: { enabled: false },
          lang: {
            noData: this.$t('views.stats.retailers.noData')
          },
          series: [{
            name: pieChart.label,
            data: this.actionPieChartData(pieChart.action)
          }],
          plotOptions: {
            pie: {
              size: '75%',
              dataLabels: {
                formatter: function() {
                  if (this.percentage > 2) {
                    let format = `${this.point.name}`
                    format += ` <span class="flag-icon flag-icon-${this.point.options.region.toLowerCase()}"></span>`
                    if (pieChart.showServiceBadge) format += ` <span class="badge badge-light">${this.point.service}</span>`
                    format += ` ${percentageFilter(this.percentage / 100)}`
                    return format
                  }
                },
                useHTML: true
              }
            }
          }
        })
      }

      return chartOptions
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Treeselect text limit formatter
    treeSelectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    // Load groups
    loadGroups: function() {
      this.groupsLoading = true
      this.groupsError = null

      return fetch(`/api/interface/groups?group_id=${this.$route.params.groupId}&widgets=0`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false
          if (!res.ok) {
            this.groupsError = { status: res.status, data }
          } else {
            if (this.demoMode) {
              this.groups = Object.freeze(data.map((group, index) => {
                group.name = `group-${index + 1}`

                return group
              }))
            } else {
              this.groups = Object.freeze(data)
            }

            // Init group treeselect value
            this.selectedGroupIds = data.map(group => group.id)
          }
        })
    },
    // Load groups
    loadGroupsGql: function() {
      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsRetailersGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { id: parseInt(this.$route.params.groupId) }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false
          if (!res.ok) {
            this.groupsError = { status: res.status, data }
          } else {
            this.groups = Object.freeze(data.data.groups)

            // Init group treeselect value
            this.selectedGroupIds = this.groups.map(group => group.id)
          }
        })
    },
    // Load retailers
    loadRetailers: function() {
      this.retailersLoading = true
      this.retailersError = null

      const retailerIds = this.eventAggregates
        .map(event => event._id.retailerId)
        .filter((elem, pos, arr) => arr.indexOf(elem) === pos)

      const query = `query statsRetailersGroups($retailerIds: [Int!]) {
        retailers(ids: $retailerIds) {
          id
          name
          service
          imgSmallUrl
          region {
            id
            code
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { retailerIds }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.retailersLoading = false
          if (!res.ok) {
            this.retailersError = { status: res.status, data }
          } else {
            this.retailers = Object.freeze(data.data.retailers)
          }
        })
    },
    // Update analytics data when the date range has changed
    loadAnalyticsData: async function() {
      await Promise.all([
        this.loadAnalyticsDataEvents(),
        this.loadDailyAnalyticsDataEvents()
      ])
    },
    // Update events data
    loadAnalyticsDataEvents: async function() {
      this.eventAggregatesLoading = true
      this.eventAggregatesError = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRange.startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRange.endDate).endOf('day').format('x'),
        interval: 'total',
        group_keys: ['action', 'groupId', 'retailerId'],
        group_id: this.$route.params.groupId
      }

      // Get regular events
      const loadClassicActions = async () => {
        const body = {
          ...baseBody,
          actions: ['redirection', 'redirection_product', 'validation', 'validation_product']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      // Get store selection data
      const loadStoreSelections = async () => {
        const body = {
          ...baseBody,
          actions: ['retail_outlet_selection'],
          store: true
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [data, storeSelectionData] = await Promise.all([loadClassicActions(), loadStoreSelections()])

        storeSelectionData.forEach(item => {
          item._id.action = 'store_selection'
        })

        // Set events in store
        this.eventAggregates = Object.freeze(data.concat(storeSelectionData))
      } catch (err) {
        this.eventAggregatesError = err
      } finally {
        this.eventAggregatesLoading = false
      }
    },
    loadDailyAnalyticsDataEvents: async function() {
      this.eventAggregatesDailyLoading = true
      this.eventAggregatesDailyError = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRange.startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRange.endDate).endOf('day').format('x'),
        interval: 'daily',
        group_keys: ['action', 'groupId', 'retailerId'],
        group_id: this.$route.params.groupId
      }

      // Get regular events
      const loadClassicActions = async () => {
        const body = {
          ...baseBody,
          actions: ['redirection', 'redirection_product', 'validation', 'validation_product']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const data = await loadClassicActions()

        // Set events in store
        this.eventAggregatesDaily = Object.freeze(data)
      } catch (err) {
        this.eventAggregatesDailyError = err
      } finally {
        this.eventAggregatesDailyLoading = false
      }
    },
    // Export table as XLSX
    exportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_retailers_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('#table-stats-retailers'), { raw: true })
      XLSX.writeFile(wb, filename)
    },
    // Export store table as XLSX
    storeExportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_retailers_store_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('#table-stats-retailers-store'), { raw: true })
      XLSX.writeFile(wb, filename)
    },

    /* Stats */
    // Get the action by type of daily data for a retailer, group and date
    retailerDailyAction: function(retailerId, groupId, action, date, type) {
      if (!this.analyticsDataDailyEventsHash[date][retailerId] || !this.analyticsDataDailyEventsHash[date][retailerId][groupId]) return 0

      const data = this.analyticsDataDailyEventsHash[date][retailerId][groupId][action]
      if (!data) return 0

      if (type === 'count') {
        return data.count
      } else if (type === 'amount' && data.amount) {
        return data.amount
      }
      return 0
    },
    // Get the estimated sales count for a retailer, group and date
    retailerEstimatedSalesDaily: function(retailerId, groupId, date, type) {
      // Action type (count or amount)
      const validationAction = type === 'count' ? 'validation' : 'validation_product'
      const redirectionAction = type === 'count' ? 'redirection' : 'redirection_product'

      const retailerValidation = this.retailerDailyAction(retailerId, groupId, validationAction, date, type)
      const retailerRedirection = this.retailerDailyAction(retailerId, groupId, redirectionAction, date, type)

      if (retailerValidation === 0) {
        let totalRedirection = retailerRedirection

        let rateSales = this.retailersSalesRateDailyHash[date][type]
        if (rateSales === 0) {
          const group = this.groupsMap[groupId]
          rateSales = group.effectiveBaselineValidationRate || 0.1
        }

        totalRedirection *= rateSales

        return totalRedirection * 1.75
      } else {
        return retailerValidation * 1.75
      }
    },
    // Get the total estimated sales count for a retailer and a date
    retailerTotalEstimatedSalesDaily: function(retailerId, date, type) {
      const count = this.selectedGroupIds.reduce((acc, groupId) => {
        return acc + this.retailerEstimatedSalesDaily(retailerId, groupId, date, type)
      }, 0)

      return Math.round(count)
    },
    // Get the total estimated sales count for a retailer
    retailerTotalEstimatedSales: function(retailerId, type) {
      const count = this.eventAggregatesDaily.reduce((acc, ev) => {
        return acc + this.retailerTotalEstimatedSalesDaily(retailerId, `${ev._id.year}-${ev._id.month}-${ev._id.day}`, type)
      }, 0)

      return Math.round(count)
    },
    // Get the estimated sales rate for a retailer
    retailerEstimatedSalesRate: function(retailerId) {
      const validationCount = this.retailersEstimatedSalesHash[retailerId].count
      const redirectionCount = this.retailerActionCount(retailerId, 'redirection')

      let ratio
      if (validationCount > 0 && redirectionCount > 0) {
        ratio = validationCount / redirectionCount
      }

      return ratio
    },
    // Count for a retailer and action
    retailerActionCount: function(retailerId, action) {
      const data = this.tableData[retailerId][action]
      return data ? data.count : 0
    },
    // Amount for a retailer and action
    retailerActionAmount: function(retailerId, action) {
      const data = this.tableData[retailerId][action]
      return data ? data.amount : 0
    },
    // Percentage for a retailer and action
    retailerActionPercentage: function(retailerId, action) {
      const retailerActionCount = this.retailerActionCount(retailerId, action)
      return retailerActionCount > 0 ? retailerActionCount / this.actionCount(action) : null
    },
    // Rate for a retailer and action
    retailerActionRate: function(retailerId, action1, action2) {
      const retailerActionCount1 = this.retailerActionCount(retailerId, action1)
      const retailerActionCount2 = this.retailerActionCount(retailerId, action2)
      return retailerActionCount2 > 0 ? retailerActionCount1 / retailerActionCount2 : null
    },
    // Count for an action
    actionCount: function(action) {
      let actionCount = 0

      this.retailersFiltered.forEach(retailer => {
        const data = this.tableData[retailer.id][action]
        if (data) {
          actionCount += data.count
        }
      })

      return actionCount
    },
    // Pie chart data for an action
    actionPieChartData: function(action) {
      const data = []

      this.retailersFiltered.forEach(retailer => {
        const key = `${retailer.name} - ${this.$options.filters.retailerService(retailer.service)}`

        // Assign a color if there isn't already
        if (!this.chartColors[key]) {
          this.chartColors[key] = Highcharts.getOptions().colors[this.colorSeq]

          this.colorSeq++
          if (this.colorSeq === Highcharts.getOptions().colors.length) {
            this.colorSeq = 0
          }
        }

        // Add retailer stats to data array
        const y = this.retailerActionCount(retailer.id, action)
        if (y > 0) {
          data.push({
            name: retailer.name,
            region: retailer.region.code,
            service: retailer.service,
            y,
            color: this.chartColors[key]
          })
        }
      })

      data.sort((a, b) => (a.y < b.y) ? 1 : ((b.y < a.y) ? -1 : 0))

      return data
    },

    // Set sort & direction
    setSort: function(key) {
      if (this.sortKey !== key) {
        this.sortKey = key
        this.sortDirection = 'asc'
      } else if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    // aria-sort value for table th
    ariaSort: function(key) {
      if (this.sortKey !== key) {
        return 'none'
      } else if (this.sortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    },
    // Set stores sort & direction
    setStoresSort: function(key) {
      if (this.storesSortKey !== key) {
        this.storesSortKey = key
        this.storesSortDirection = 'asc'
      } else if (this.storesSortDirection === 'asc') {
        this.storesSortDirection = 'desc'
      } else {
        this.storesSortDirection = 'asc'
      }
    },
    // aria-sort value for stores table th
    storesAriaSort: function(key) {
      if (this.storesSortKey !== key) {
        return 'none'
      } else if (this.storesSortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    percentage: function(value) {
      return value ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '–'
    },
    price: function(value, region) {
      const currency = LocaleCurrency.getCurrency(region.code)
      if (value !== null && value > 0 && currency) {
        return value.toLocaleString(i18n.locale, {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2
        })
      } else {
        return '–'
      }
    },
    retailerService: function(value) {
      return i18n.t('shared.retailerDistributions.' + value)
    },
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    }
  },
  watch: {
    // Load analytics data when date range changes
    dateRange: function() {
      this.tableDataLoading = true
      this.loadAnalyticsData().then(() => {
        this.loadRetailers().then(() => {
          this.tableDataLoading = false
        })
      })
    }
  },
  created: function() {
    this.loadGroups()
  }
}
</script>

<style lang="scss">
.sub-flag {
  position: absolute;
  width: calc(1em + 2px);
  height: calc(.75em + 2px);
  top: calc(50% - 5px + 8px);
  left: calc(50% - 8px + 8px);
}

// Highcharts hides my labels for no reason :'(
.highcharts-data-labels {
  opacity: 1 !important;
}
</style>
