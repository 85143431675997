<template>
  <div>
    <form @submit.prevent="submit">
      <div class="form-group row">
        <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.importTracker.group') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <group-modal-select
            v-model="importTracker.group"
            languages
            :class="{ 'is-invalid' : importSubmitError && importSubmitError.data && importSubmitError.data.group }"
            id="multiselect-group" />
          <div v-if="importSubmitError && importSubmitError.data && importSubmitError.data.group" class="invalid-feedback d-block">{{ tErrors('importTracker', 'group', importSubmitError.data.group) }}</div>
        </div>
      </div>

      <div class="form-group row">
        <label for="csv-file-input" class="col-sm-2 col-form-label">{{ $t('attributes.importTracker.csvFile') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <b-form-file
            v-model="importTracker.csvFile"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            :state="(importSubmitError && importSubmitError.data && importSubmitError.data.csvFile) ? false : null"
            id="csv-file-input" />
          <div v-if="importSubmitError && importSubmitError.data && importSubmitError.data.csvFile" class="invalid-feedback d-block">{{ tErrors('importTracker', 'csvFile', importSubmitError.data.csvFile) }}</div>
        </div>
        <div class="col-lg-6 col-sm-4 col-12" v-if="importTracker.group">
          <button type="button" class="btn btn-secondary" @click="downloadCsvTemplate">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-csv" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-csv fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
            {{ $t('shared.actions.downloadCsvTemplate') }}
          </button>
        </div>
      </div>

      <div class="form-group row">
        <label for="csv-separator-select" class="col-sm-2 col-form-label">{{ $t('attributes.importTracker.csvSeparator') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <select v-model="importTracker.csvSeparator" class="custom-select" id="csv-separator-select" style="max-width: 65px;">
            <option value=";">;</option>
            <option value=",">,</option>
            <option value="tab">tab</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="default-checkbox" class="col-sm-2">{{ $t('attributes.importTracker.savedWithExcel') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="custom-control custom-switch custom-switch-color">
            <input v-model="importTracker.savedWithExcel" type="checkbox" class="custom-control-input" id="default-checkbox">
            <label class="custom-control-label" for="default-checkbox"></label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary" :disabled="importSubmitLoading">
          <md-spinner v-if="importSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
          {{ $t('shared.submit.import') }}
        </button>
      </div>

      <template v-if="importSubmitError && importSubmitError.data && importSubmitError.data.csv">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th v-for="key in Object.keys(importSubmitError.data.csv.lines[0])" :key="key">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in importSubmitError.data.csv.lines" :key="index">
              <th>{{ index + 1 }}</th>
              <td
                v-for="key in Object.keys(line)"
                :key="key"
                :class="{
                  'table-danger': csvError(index, key),
                  'table-warning': csvWarning(index, key)
                }">
                {{ line[key] }}
                <div
                  v-if="csvError(index, key)"
                  class="small text-danger">
                  <template v-if="['name', 'raw_packaging'].includes(effectiveKey(key))">
                    {{ tErrors('productLanguageData', effectiveKey(key), csvError(index, key)) }}
                  </template>
                  <template v-else>
                    {{ tErrors('product', effectiveKey(key), csvError(index, key)) }}
                  </template>
                </div>
                <div
                  v-if="csvWarning(index, key)"
                  class="small text-warning">
                  {{ tErrors('product', effectiveKey(key), csvWarning(index, key)) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </form>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BFormFile } from 'bootstrap-vue'
import jsonToFormData from 'json-form-data'
import * as XLSX from 'xlsx'

export default {
  components: { MdSpinner, GroupModalSelect, BFormFile },
  data: function() {
    return {
      importTracker: {
        csvFile: null,
        group: null,
        csvSeparator: ';',
        savedWithExcel: false
      },
      importSubmitLoading: false,
      importSubmitError: null
    }
  },
  methods: {
    // Csv error helper
    effectiveKey: function(key) {
      if (key === 'group_id') {
        return 'group'
      } else if (/name_/.test(key) || /raw_packaging_/.test(key)) {
        return `${key.replace(/_[a-z]{2}$/, '')}`
      } else {
        return key
      }
    },
    csvError: function(index, key) {
      const effectiveKey = this.effectiveKey(key)
      let errorKey
      if (['name', 'raw_packaging'].includes(effectiveKey)) {
        errorKey = `productLanguageDatas.${effectiveKey}`
      } else {
        errorKey = key
      }

      return this.importSubmitError.data.csv.errors[index + 1] && this.importSubmitError.data.csv.errors[index + 1][errorKey]
    },
    csvWarning: function(index, key) {
      return this.importSubmitError.data.csv.warnings[index + 1] && this.importSubmitError.data.csv.warnings[index + 1][this.effectiveKey(key)]
    },
    // Csv template
    downloadCsvTemplate: function() {
      const group = this.importTracker.group
      const filename = `template_products_${group.region.code}.csv`
      const headers = ['ean', 'upc', 'reference', 'image_tmp_name', 'raw_product', 'generate_widget', 'group_id', 'min_price', 'max_price']
      if (group.region.languages.length === 1) {
        headers.push('name')
        headers.push('raw_packaging')
      } else {
        group.region.languages.forEach(language => {
          headers.push(`name_${language.code}`)
          headers.push(`raw_packaging_${language.code}`)
        })
      }

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet([], { header: headers })

      XLSX.utils.book_append_sheet(wb, ws)
      XLSX.writeFile(wb, filename)
    },
    // Import form submit
    submit: function() {
      this.importSubmitLoading = true
      this.importSubmitError = null

      const body = {
        import_tracker: {
          group_id: this.importTracker.group.id,
          csv: this.importTracker.csvFile,
          csv_separator: this.importTracker.csvSeparator,
          saved_with_excel: this.importTracker.savedWithExcel
        }
      }

      return fetch('/api/interface/import_trackers', {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: jsonToFormData(body, { showLeafArrayIndexes: false })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.importSubmitLoading = false

          if (!res.ok) {
            this.importSubmitError = { status: res.status, data }
          } else {
            this.$router.push({ name: 'importTracker', params: { id: data.id } })
            this.$root.$bvToast.toast(this.$t('shared.success.importTracker.create'), {
              variant: 'success',
              noCloseButton: true,
              autoHideDelay: 3000
            })
          }
        })
    }
  }
}
</script>
