<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="productLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="productError" class="alert alert-danger">
        <template v-if="productError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>

        <form @submit.prevent="submit">
          <!-- Group -->
          <div class="form-group row">
            <label for="multiselect-group" class="col-sm-3 col-form-label">{{ $t('attributes.product.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="product.group"
                languages
                :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="productSubmitErrors && productSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('product', 'group', productSubmitErrors.group) }}</div>
            </div>
          </div>

          <hr>

          <!-- Product language datas -->
          <div
            v-if="productSubmitErrors && productSubmitErrors.productLanguageDatas"
            class="alert alert-danger">
            {{ tErrors('product', 'productLanguageDatas', productSubmitErrors.productLanguageDatas) }}
          </div>
          <template v-if="product.productLanguageDatas.length === 0 && !product.group">
            <div class="alert alert-warning">
              {{ $t('views.products.form.selectGroup') }}
            </div>
          </template>
          <template v-else v-for="(productLanguageData, productLanguageDataIndex) in product.productLanguageDatas">
            <div :key="productLanguageData.id" class="form-group row">
              <div class="col-sm-1 col-form-label">
                <language-badge
                  :language="productLanguageData.language"
                  :class="{ 'text-decoration-line-through': productLanguageData._destroy }" />
              </div>
              <div class="col-lg-6 col-sm-8">
                <div class="form-group row">
                  <label
                    :for="`input-name-${productLanguageData.language.id}`"
                    class="col-lg-4 col-sm-3 col-form-label"
                    :class="{ 'text-decoration-line-through': productLanguageData._destroy }">
                    {{ $t('attributes.productLanguageData.name') }}
                  </label>
                  <div class="col-lg-8 col-sm-9">
                    <input
                      v-model="productLanguageData.name"
                      type="text"
                      class="form-control"
                      :id="`input-name-${productLanguageData.language.id}`"
                      :disabled="productLanguageData._destroy"
                      :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].name`] }"
                      :placeholder="$t('attributes.productLanguageData.name')">
                    <div
                      v-if="productSubmitErrors && productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].name`]"
                      class="invalid-feedback">
                      {{ tErrors('productLanguageData', 'name', productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].name`]) }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label
                    :for="`input-raw-packaging-${productLanguageData.language.id}`"
                    class="col-lg-4 col-sm-3 col-form-label"
                    :class="{ 'text-decoration-line-through': productLanguageData._destroy }">
                    {{ $t('attributes.productLanguageData.rawPackaging') }}
                  </label>
                  <div class="col-lg-8 col-sm-9">
                    <input
                      v-model="productLanguageData.rawPackaging"
                      type="text"
                      class="form-control"
                      :id="`input-raw-packaging-${productLanguageData.language.id}`"
                      :disabled="productLanguageData._destroy"
                      :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].rawPackaging`] }"
                      :placeholder="$t('attributes.productLanguageData.rawPackaging')">
                    <div
                      v-if="productSubmitErrors && productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].rawPackaging`]"
                      class="invalid-feedback">
                      {{ tErrors('productLanguageData', 'rawPackaging', productSubmitErrors[`productLanguageDatas[${productLanguageDataIndex}].rawPackaging`]) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-sm-3">
                <template v-if="!productLanguageData._destroy">
                  <button type="button" class="btn btn-danger" @click="deleteProductLanguageData(productLanguageData)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                    {{ $t('shared.actions.deleteProductLanguageData') }}
                  </button>
                </template>
                <template v-else>
                  <button type="button" class="btn btn-success" @click="restoreProductLanguageData(productLanguageData)">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                    {{ $t('shared.actions.restoreProductLanguageData') }}
                  </button>
                </template>
              </div>
            </div>
          </template>

          <button
            type="button"
            class="btn btn-success"
            v-if="languageOptions.length > 0"
            @click="newProductLanguageData()">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
            {{ $t('shared.actions.newProductLanguageData') }}
          </button>

          <hr>

          <!-- Reference -->
          <div class="form-group row">
            <label for="input-reference" class="col-sm-3 col-form-label">{{ $t('attributes.product.reference') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="product.reference" type="text" class="form-control" :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.reference }" id="input-reference" :placeholder="$t('attributes.product.reference')">
              <div v-if="productSubmitErrors && productSubmitErrors.reference" class="invalid-feedback">{{ tErrors('product', 'reference', productSubmitErrors.reference) }}</div>
            </div>
          </div>

          <!-- EAN -->
          <div class="form-group row">
            <label for="input-ean" class="col-sm-3 col-form-label">{{ $t('attributes.product.ean') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="product.ean" type="text" class="form-control" :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.ean }" id="input-ean" :placeholder="$t('attributes.product.ean')">
              <div v-if="productSubmitErrors && productSubmitErrors.ean" class="invalid-feedback">{{ tErrors('product', 'ean', productSubmitErrors.ean) }}</div>
            </div>
          </div>

          <!-- UPC -->
          <div class="form-group row">
            <label for="input-upc" class="col-sm-3 col-form-label">{{ $t('attributes.product.upc') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="product.upc" type="text" class="form-control" :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.upc }" id="input-upc" :placeholder="$t('attributes.product.upc')">
              <div v-if="productSubmitErrors && productSubmitErrors.upc" class="invalid-feedback">{{ tErrors('product', 'upc', productSubmitErrors.upc) }}</div>
            </div>
          </div>

          <!-- Active -->
          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-3">{{ $t('attributes.product.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="product.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <!-- Raw product -->
          <div class="form-group row">
            <label for="input-raw_product" class="col-sm-3 col-form-label">{{ $t('attributes.product.rawProduct') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <vue-simple-suggest
                v-model="product.rawProduct"
                :list="rawProducts"
                :max-suggestions="0"
                :filter-by-query="true"
                :styles="{
                  vueSimpleSuggest: 'position-relative',
                  inputWrapper: '',
                  defaultInput : productSubmitErrors && productSubmitErrors.rawProduct ? 'form-control is-invalid' : 'form-control',
                  suggestions: 'position-absolute list-group z-1000',
                  suggestItem: 'list-group-item text-nowrap'
                }">
              </vue-simple-suggest>
              <div v-if="productSubmitErrors && productSubmitErrors.rawProduct" class="invalid-feedback d-block">{{ tErrors('product', 'rawProduct', productSubmitErrors.rawProduct) }}</div>
            </div>
          </div>

          <!-- Image -->
          <div class="form-group row">
            <label for="img-input" class="col-sm-3 col-form-label">{{ $t('attributes.product.img') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <img v-if="action === 'edit' && product.img" :src="product.img.medium.url" class="border mb-3" />
              <b-form-file
                v-model="product.imgFile"
                placeholder="Choose a file..."
                drop-placeholder="Drop file here..."
                id="img-input" />
            </div>
          </div>

          <!-- Min price -->
          <div class="form-group row">
            <label for="input-min-price" class="col-sm-3 col-form-label">{{ $t('attributes.product.minPrice') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <currency-input
                v-model="product.minPrice"
                :options="{ currency: currencyCode }"
                class="form-control"
                :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.minPrice }"
                id="input-min-price"
                :placeholder="$t('attributes.product.minPrice')"
                :key="currencyCode" />
              <div v-if="productSubmitErrors && productSubmitErrors.minPrice" class="invalid-feedback">{{ tErrors('product', 'minPrice', productSubmitErrors.minPrice) }}</div>
            </div>
          </div>

          <!-- Max price -->
          <div class="form-group row">
            <label for="input-max-price" class="col-sm-3 col-form-label">{{ $t('attributes.product.maxPrice') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <currency-input
                v-model="product.maxPrice"
                :options="{ currency: currencyCode }"
                class="form-control"
                :class="{ 'is-invalid' : productSubmitErrors && productSubmitErrors.maxPrice }"
                id="input-max-price"
                :placeholder="$t('attributes.product.maxPrice')"
                :key="currencyCode" />
              <div v-if="productSubmitErrors && productSubmitErrors.maxPrice" class="invalid-feedback">{{ tErrors('product', 'maxPrice', productSubmitErrors.maxPrice) }}</div>
            </div>
          </div>

          <!-- Generate widget -->
          <div class="form-group row" v-if="action === 'new'">
            <label for="generate-widget-checkbox" class="col-sm-3">{{ $t('views.products.form.generateWidget') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch">
                <input v-model="generateWidget" type="checkbox" class="custom-control-input" id="generate-widget-checkbox">
                <label class="custom-control-label" for="generate-widget-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="productSubmitLoading">
              <md-spinner v-if="productSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>

        <b-modal
          id="newProductLanguageDataModal"
          ref="newProductLanguageDataModal"
          :title="$t('shared.actions.newProductLanguageData')"
          @show="modalLanguage = null"
          @shown="$refs.languageMultiselect.$el.focus()"
          @ok="handleOk"
          no-fade>
          <form @submit.stop.prevent="handleSubmit()">
            <div class="form-group row mb-0">
              <label for="mail-input" class="col-sm-2 col-form-label">{{ $t('attributes.productLanguageData.language') }}</label>
              <div class="col-sm-10 col-12">
                <multiselect
                  ref="languageMultiselect"
                  v-model="modalLanguage"
                  :options="languageOptions"
                  open-direction="bottom"
                  :allow-empty="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  select-label=""
                  deselect-label="">
                  <template v-slot:singleLabel="{ option }">
                    <language-badge :language="option" />
                  </template>
                  <template v-slot:option="{ option }">
                    <language-badge :language="option" />
                  </template>
                </multiselect>
              </div>
            </div>
          </form>
        </b-modal>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import { BFormFile, BModal, VBModal } from 'bootstrap-vue'
import VueSimpleSuggest from 'vue-simple-suggest'
import CurrencyInput from '../shared/CurrencyInput.vue'
import LocaleCurrency from 'locale-currency'

export default {
  components: { MdSpinner, Multiselect, GroupModalSelect, LanguageBadge, BFormFile, BModal, VueSimpleSuggest, CurrencyInput },
  directives: { 'b-modal': VBModal },
  props: ['action'],
  data: function() {
    return {
      product: {
        productLanguageDatas: [],
        reference: null,
        ean: null,
        upc: null,
        rawProduct: null,
        active: true,
        group: null,
        img: null,
        imgFile: null,
        minPrice: null,
        maxPrice: null
      },
      productLoading: false,
      productError: null,
      rawProducts: [],
      rawProductsLoading: false,
      rawProductsError: null,
      generateWidget: this.action === 'new' ? true : null,
      modalLanguage: null,
      productSubmitLoading: false,
      productSubmitErrors: null
    }
  },
  computed: {
    // Available languages for product language datas
    languageOptions: function() {
      if (!this.product.group) {
        return []
      } else {
        return this.product.group.region.languages
          .filter(language => {
          // Remove languages having already a pld for this language
            return !this.product.productLanguageDatas
              .some(productLanguageData => productLanguageData.language.id === language.id)
          })
          .map(language => {
          // Add region on language to generate language-badge
            return { ...language, region: this.product.group.region }
          })
      }
    },
    // Currency code
    currencyCode: function() {
      const regionCode = this.product.group ? this.product.group.region.code : 'FR'
      const currency = LocaleCurrency.getCurrency(regionCode)
      return currency
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.productLoading = true
      this.productError = null

      const query = `query productsForm ($productId: Int!) {
        product(id: $productId) {
          id
          reference
          ean
          upc
          active
          rawProduct
          img
          minPrice
          maxPrice
          productLanguageDatas {
            id
            name
            rawPackaging
            language {
              id
              code
              region {
                id
                code
              }
            }
          }
          group {
            id
            name
            region {
              id
              code
              languages {
                id
                code
              }
            }
          }
        }
      }`

      const variables = {
        productId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.productLoading = false

          if (data.errors) {

          } else {
            this.product = data.data.product
          }
        })
    },
    // Load raw products for selected group
    loadRawProducts: function() {
      this.rawProductsLoading = true
      this.rawProductsError = null

      const query = `query productsFormGroupRawProducts ($groupId: Int!) {
        group(id: $groupId) {
          products {
            rawProduct
          }
        }
      }`

      const variables = {
        groupId: this.product.group.id
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.rawProductsLoading = false

          if (data.errors) {

          } else {
            this.rawProducts = Object.freeze(data.data.group.products.map(product => product.rawProduct))
          }
        })
    },
    // New product language data
    newProductLanguageData: function() {
      if (this.languageOptions.length === 1) {
        this.createProductLanguageData(this.languageOptions[0])
      } else {
        this.$refs.newProductLanguageDataModal.show()
      }
    },
    // Modal ok click
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    // Modal form submit
    handleSubmit() {
      // Validation
      if (this.modalLanguage === null) {
        // User must choose a language first...
      } else {
        // Add new product language data with selected language
        this.createProductLanguageData(this.modalLanguage)

        this.$nextTick(() => {
          this.$refs.newProductLanguageDataModal.hide()
        })
      }
    },
    // Create a product language data
    createProductLanguageData: function(language) {
      this.product.productLanguageDatas.push({
        language: language,
        name: null,
        rawPackaging: null
      })
    },
    // Remove a product language data
    deleteProductLanguageData: function(productLanguageData) {
      if (productLanguageData.id) {
        // If product language data exists in database, set its _destroy to true
        this.$set(productLanguageData, '_destroy', true)
      } else {
        // Otherwise, simply remove array item
        const index = this.product.productLanguageDatas.indexOf(productLanguageData)
        this.product.productLanguageDatas.splice(index, 1)
      }
    },
    // Restore a product language data
    restoreProductLanguageData: function(productLanguageData) {
      this.$set(productLanguageData, '_destroy', false)
    },
    // Form submit
    submit: async function() {
      this.productSubmitLoading = true
      this.productSubmitErrors = null

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const variables = {
        input: {
          attributes: {
            groupId: this.product.group.id,
            reference: this.product.reference,
            ean: this.product.ean,
            upc: this.product.upc,
            rawProduct: this.product.rawProduct,
            active: this.product.active,
            img: this.product.imgFile ? await toBase64(this.product.imgFile) : undefined,
            minPrice: this.product.minPrice,
            maxPrice: this.product.maxPrice,
            productLanguageDatasAttributes: []
          }
        }
      }

      this.product.productLanguageDatas.forEach(productLanguageData => {
        variables.input.attributes.productLanguageDatasAttributes.push({
          id: productLanguageData.id,
          languageId: productLanguageData.language.id,
          name: productLanguageData.name,
          rawPackaging: productLanguageData.rawPackaging,
          _destroy: productLanguageData._destroy
        })
      })

      if (this.action === 'new') {
        variables.input.generateWidget = this.generateWidget

        const mutation = `mutation($input: CreateProductInput!) {
          createProduct(input: $input) {
            product {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.productSubmitLoading = false

            if (data.data.createProduct.errors) {
              this.productSubmitErrors = data.data.createProduct.errors
            } else {
              this.$router.push({ name: 'product', params: { id: data.data.createProduct.product.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.product.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.productId = this.$route.params.id

        const mutation = `mutation($input: UpdateProductInput!) {
          updateProduct(input: $input) {
            product {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.productSubmitLoading = false

            if (data.data.updateProduct.errors) {
              this.productSubmitErrors = data.data.updateProduct.errors
            } else {
              this.$router.push({ name: 'product', params: { id: data.data.updateProduct.product.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.product.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    }
  },
  watch: {
    'product.group': function(group, oldGroup) {
      // When a group with a different region is selected, create language data automatically if possible
      const isNewRegion = (group && !oldGroup) || (group && oldGroup && group.region.id !== oldGroup.region.id)
      const usedLanguageIds = this.product.productLanguageDatas.map(productLanguageData => productLanguageData.language.id)

      if (isNewRegion && group.region.languages.length === 1 && !usedLanguageIds.includes(group.region.languages[0].id)) {
        this.createProductLanguageData({
          ...group.region.languages[0],
          region: group.region
        })
      }

      // Load group raw_products
      this.loadRawProducts()
    }
  }
}
</script>
