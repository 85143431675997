
<div>
  <transition name="fade" mode="out-in">
    <div v-if="widgetBulkUpdateLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="widgetBulkUpdateError" class="alert alert-danger">
      <template v-if="widgetBulkUpdateError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-6">
          <table class="table">
            <tbody>
              <tr>
                <th>{{ $t('attributes.widgetBulkUpdate.createdAt') }}</th>
                <td>{{ widgetBulkUpdate.createdAt | date }}</td>
              </tr>
              <tr>
                <th>{{ $t('attributes.widgetBulkUpdate.user') }}</th>
                <td>
                  <template v-if="widgetBulkUpdate.user">
                    <router-link :to="{ name: 'user', params: { id: widgetBulkUpdate.user.id } }">
                      {{ widgetBulkUpdate.user.email }}
                    </router-link>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Widgets -->
      <table class="table">
        <thead>
          <tr>
            <th class="table-col-shrink">{{ $t('attributes.widget.id') }}</th>
            <th class="table-col-shrink"></th>
            <th class="table-col-shrink"></th>
            <th>{{ $t('attributes.widget.name') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.widget.group') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.widget.product') }}</th>
            <th class="table-col-shrink">{{ $t('attributes.widget.key') }}</th>
            <th class="table-col-shrink"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="widget in widgetBulkUpdate.widgets" :key="widget.id">
            <th>{{ widget.id }}</th>
            <td class="text-center">
              <dot :active="widget.active" />
            </td>
            <td :id="`widget-${widget.id}-img`">
              <template v-if="widget.product && widget.product.img && widget.product.img.url">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-image fa-w-12"><path fill="currentColor" d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z" class=""></path></svg>
                <b-tooltip :target="`widget-${widget.id}-img`" boundary="viewport" placement="right" triggers="hover click" custom-class="tooltip-lg">
                  <img :src="widget.product.img.medium.url" style="height: 200px;" />
                </b-tooltip>
              </template>
            </td>
            <td class="ellipsis" style="max-width: 200px;">
              <router-link :to="{ name: 'widget', params: { id: widget.id } }">
                {{ widget.name }}
              </router-link>
            </td>
            <td class="ellipsis" style="max-width: 150px;">
              <template v-if="widget.product">
                <router-link :to="{ name: 'group', params: { id: widget.group.id } }">
                  <region-flag :code="widget.group.region.code" />
                  {{ widget.group.name }}
                </router-link>
              </template>
            </td>
            <td class="ellipsis" style="max-width: 150px;">
              <template v-if="widget.product">
                <router-link :to="{ name: 'product', params: { id: widget.product.id } }">
                  {{ widget.product.productLanguageDatas[0].name }}
                </router-link>
              </template>
            </td>
            <td class="text-nowrap">
              <code>{{ widget.key }}</code>
            </td>
            <td>
              <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                <template v-slot:button-content>
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                </template>
                <router-link :to="{ name: 'widget', params: { id: widget.id } }" class="dropdown-item">
                  {{ $t('shared.actions.show') }}
                </router-link>
                <router-link :to="{ name: 'editWidget', params: { id: widget.id } }" class="dropdown-item">
                  {{ $t('shared.actions.edit') }}
                </router-link>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </transition>
</div>
