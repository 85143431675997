<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="analyticsDataError.validations"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Groups selector + daterangepicker -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Group tree select -->
      <div class="flex-fill flex-basis-0">
        <treeselect
          :limit-text="treeSelectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelectOptions"
          :searchable="false"
          style="max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Date range picker -->
      <div class="flex-fill flex-basis-0 text-center">
        <stats-date-range-picker v-model="dateRange" />
      </div>

      <!-- Right zone - Export button -->
      <div class="flex-fill flex-basis-0 text-right">
        <!-- Export button -->
        <button @click="exportXLSX" type="button" class="btn btn-primary">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-vertical-align-top" id="table-stats-custom-services">
        <thead>
          <tr>
            <th>{{ $t('views.stats.customServices.sales.date') }}</th>
            <th>{{ $t('views.stats.customServices.sales.retailer') }}</th>
            <th>{{ $t('views.stats.customServices.sales.customService') }}</th>
            <th>{{ $t('attributes.productLanguageData.name') }}</th>
            <th>{{ $t('attributes.product.reference') }}</th>
            <th>{{ $t('attributes.product.ean') }}</th>
            <th>{{ $t('attributes.product.upc') }}</th>
            <th>{{ $t('views.stats.customServices.sales.quantity') }}</th>
            <th>{{ $t('views.stats.customServices.sales.amount') }}</th>
          </tr>
        </thead>
        <tbody v-if="groupsLoading || analyticsDataLoading.validations">
          <tr>
            <td :colspan="colspan" class="text-center">
              <md-spinner md-indeterminate />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="groupsWithCustomServices.length === 0">
          <tr>
            <td :colspan="colspan" class="alert-warning">
              {{ $t('shared.warnings.noCustomService') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="validationsFiltered.length === 0">
            <td :colspan="colspan" class="alert-warning">
              {{ $t('shared.warnings.noValidationEvent') }}
            </td>
          </tr>
          <template v-else v-for="validation in validationsFiltered">
            <tr v-for="(transferredProduct, index) in validation.dimensions.transferredProducts" :key="transferredProduct.id">
              <td v-if="index === 0" :rowspan="validation.dimensions.transferredProducts.length">
                <small>
                  {{ validation.date | formatDate }}
                </small>
              </td>
              <td v-if="index === 0" :rowspan="validation.dimensions.transferredProducts.length">
                <region-flag v-if="international" :code="validation.dimensions.retailer.region.code" />
                <img :src="validation.dimensions.retailer.imgSmallUrl" style="max-height: 20px;">
                {{ validation.dimensions.retailer.name }}
                <span class="badge badge-light">
                  {{ $t(`shared.retailerDistributions.${validation.dimensions.retailer.service}`) }}
                </span>
              </td>
              <td
                v-if="index === 0"
                :rowspan="validation.dimensions.transferredProducts.length"
                :data-custom-service-id="validation.dimensions.mediumId">
                {{ validation.customService.name }}
              </td>
              <td>{{ transferredProduct.product.productLanguageDatas[0].name }}</td>
              <td>{{ transferredProduct.product.reference || '–' }}</td>
              <td>{{ transferredProduct.product.ean || '–' }}</td>
              <td>{{ transferredProduct.product.upc || '–' }}</td>
              <td class="text-right">{{ transferredProduct.quantity }}</td>
              <td class="text-right">{{ transferredProduct.amount * transferredProduct.quantity | currency(transferredProduct.currency) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../../shared/MdSpinner.vue'
import StatsDateRangePicker from '../../shared/StatsDateRangePicker.vue'
import Treeselect from '@riophae/vue-treeselect'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import client from '../../../apollo-client'
import { gql } from '@apollo/client/core'

export default {
  components: { MdSpinner, StatsDateRangePicker, Treeselect },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      analyticsData: {
        validations: []
      },
      analyticsDataLoading: {
        validations: false
      },
      analyticsDataError: {
        validations: null
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedGroupIds: []
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // Table colspan
    colspan: function() {
      return 9
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Custom services array
    customServices: function() {
      const customServices = []

      this.groups.forEach(group => {
        customServices.push(...group.customServices)
      })

      return customServices
    },
    // Custom services map
    customServicesMap: function() {
      return new Map(this.customServices.map(customService => [customService.id, customService]))
    },
    // Groups tree filtered to remove groups without a custom service
    groupsTreeWithCustomServices: function() {
      let groupsTree = []

      // Convert groups array into tree structure
      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })
      }

      // Filter recursively to keep groups with at least one custom service (and its parents)
      const filter = tree => {
        return tree.filter(node => {
          if (node.children && node.children.length > 0) {
            node.children = filter(node.children)
          }
          return (node.children && node.children.length > 0) || node.customServices.length > 0
        })
      }

      const groupsTreeWithCustomServices = filter(groupsTree)

      return groupsTreeWithCustomServices
    },
    // Groups flat array from the groupsTreeWithCustomServices
    groupsWithCustomServices: function() {
      const groups = []

      const recursiveExtractGroups = tree => {
        tree.forEach(node => {
          if (node.children) {
            recursiveExtractGroups(node.children)
          }
          const group = { ...node }
          delete group.children
          groups.push(group)
        })
      }
      recursiveExtractGroups(this.groupsTreeWithCustomServices)

      return groups
    },
    // Format group tree for vue treeselect component
    groupsTreeSelectOptions: function() {
      const recursiveMap = (tree, func) => {
        return tree.map(node => {
          const newNode = func(node)
          if (node.children) newNode.children = recursiveMap(node.children, func)
          return newNode
        })
      }

      const serialize = group => {
        return {
          id: group.id,
          label: {
            name: group.name,
            regionCode: group.region.code
          },
          parent_id: group.parentId,
          custom_services: group.customServices
        }
      }

      return recursiveMap(this.groupsTreeWithCustomServices, serialize)
    },
    // Used to fastly check if a group is selected (avoid iterating over selectedGroupIds again and again)
    selectedGroupIdsSet: function() {
      return new Set(this.selectedGroupIds)
    },
    // Get selected groups
    selectedGroups: function() {
      return this.groups.filter(group => this.selectedGroupIdsSet.has(group.id))
    },
    // Get groups to display
    displayedGroups: function() {
      const groups = []

      const appendGroup = (group, depth) => {
        if (this.selectedGroupIdsSet.has(group.id)) {
          group.depth = depth
          groups.push(group)
        }
        if (group.children) {
          group.children.map(group => appendGroup(group, depth + 1))
        }
      }
      appendGroup(this.groupsTreeWithCustomServices[0], 0)
      return groups
    },
    // Get custom services to display
    displayedCustomServices: function() {
      const customServices = []

      this.displayedGroups.forEach(group => {
        customServices.push(...group.customServices)
      })

      return customServices
    },
    // Get custom service ids to display as Set
    displayedCustomServiceIdsSet: function() {
      return new Set(this.displayedCustomServices.map(customService => customService.id))
    },
    validationsFiltered: function() {
      return this.analyticsData.validations
        .filter(validation => this.displayedCustomServiceIdsSet.has(validation.dimensions.mediumId))
        .map(validation => {
          const customService = this.customServicesMap.get(validation.dimensions.mediumId)
          return {
            ...validation,
            customService
          }
        })
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Treeselect text limit formatter
    treeSelectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    // Load groups
    loadGroups: function() {
      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsCustomServicesGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
          customServices {
            id
            name
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { id: parseInt(this.$route.params.groupId) }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.groupsLoading = false
          if (!res.ok) {
            this.groupsError = { status: res.status, data }
          } else {
            this.groups = Object.freeze(data.data.groups)

            // Init group treeselect value
            this.selectedGroupIds = this.groups.map(group => group.id)
          }
        })
    },
    // Update analytics data when the date range has changed
    loadAnalyticsData: function() {
      return this.loadAnalyticsDataValidations()
    },
    // Update validations data
    loadAnalyticsDataValidations: async function() {
      this.analyticsDataLoading.validations = true
      this.analyticsDataError.validations = null

      const query = gql`
        query validationEvents ($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $medium: String!, $groupId: Int!) {
          validationEvents(startDate: $startDate, endDate: $endDate, medium: $medium, groupId: $groupId) {
            date
            clickDate
            dimensions {
              medium
              mediumId
              retailer {
                id
                name
                service
                region {
                  id
                  code
                }
                imgSmallUrl
              }
              transferredProducts {
                quantity
                amount
                currency
                product {
                  id
                  ean
                  upc
                  reference
                  productLanguageDatas {
                    id
                    name
                    rawPackaging
                  }
                }
              }
            }
          }
        }
      `

      const variables = {
        startDate: this.dateRange.startDate,
        endDate: moment.utc(this.dateRange.endDate).endOf('day').toDate(),
        groupId: parseInt(this.$route.params.groupId),
        medium: 'custom'
      }

      try {
        const { data } = await client.query({ query, variables })

        this.analyticsData.validations = Object.freeze(data.validationEvents)
      } catch (error) {
        this.analyticsDataError.validations = error
        throw error
      } finally {
        this.analyticsDataLoading.validations = false
      }
    },
    // Export table as XLSX
    exportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_custom_services_validations_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('#table-stats-custom-services'))
      XLSX.writeFile(wb, filename)
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    dateRange: function(value) {
      return value[0] === value[1] ? value[0] : `${value[0]} - ${value[1]}`
    },
    formatDate: function(dateString) {
      return new Date(dateString).toLocaleString()
    },
    currency: function(amount, currencyCode) {
      return amount ? amount.toLocaleString(undefined, { style: 'currency', currency: currencyCode }) : null
    }
  },
  watch: {
    // Update analytics data when date range changes
    dateRange: function(dateRange) {
      this.loadAnalyticsData()
    },
    // Select all groups with custom services
    groupsWithCustomServices: function(groups) {
      this.selectedGroupIds = groups.map(group => group.id)
    }
  },
  created: function() {
    this.loadGroups()
  }
}
</script>
