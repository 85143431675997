<template>
  <div class="card">
    <div class="card-body position-relative h-100">
      <div class="position-relative h-100 pr-5">
        <div>
          <span style="font-size: 1.1rem; font-weight: normal;">{{ label }}</span>
          <h3 style="font-weight: normal;">
            <span class="text-primary">
              <template v-if="loading">
                <md-spinner md-indeterminate :diameter="27" :stroke-width="5" />
              </template>
              <template v-else>
                {{ number | number }}
              </template>
            </span>
          </h3>
        </div>
        <div>
          <slot name="caption" />
        </div>
        <h1 class="position-absolute mb-0 text-secondary" style="bottom: 0; right: 0;">
          <slot name="icon" />
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  name: 'NumberCard',
  components: { MdSpinner },
  props: {
    number: {
      type: Number
    },
    label: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    }
  }
}
</script>
