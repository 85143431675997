
<div>
  <b-tabs v-model="tabIndex" content-class="mt-3">
    <!-- File selection -->
    <b-tab :title="$t('views.widgetBulkUpdates.form.tabs.fileSelection')">
      <form @submit.prevent="importFile">
        <div class="form-group row">
          <label for="file-input" class="col-sm-2 col-form-label">{{ $t('attributes.widgetBulkUpdate.file') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <b-form-file
              v-model="widgetBulkUpdate.file"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
              :state="widgetBulkUpdateImportErrors ? false : null"
              id="file-input" />
            <div v-if="widgetBulkUpdateImportErrors" class="invalid-feedback d-block">
              <div v-if="widgetBulkUpdateImportErrors.file">
                {{ widgetBulkUpdateImportErrors.file.map(item => item.error).join(', ') }}
              </div>
              <div v-if="widgetBulkUpdateImportErrors.widgets">
                <template v-for="(widgetErrors, widgetIndex) in widgetBulkUpdateImportErrors.widgets">
                  Widget #{{ widgets[widgetIndex].id }}: {{ widgetErrors }}
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="widgetBulkUpdateImportLoading">
            <md-spinner v-if="widgetBulkUpdateImportLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            {{ $t('views.widgetBulkUpdates.form.actions.checkFile') }}
          </button>
        </div>
      </form>
    </b-tab>

    <!-- Summary before submit -->
    <b-tab :title="$t('views.widgetBulkUpdates.form.tabs.summary')" :disabled="widgetBulkUpdateImportErrors !== null">
      <form @submit.prevent="submit">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t('attributes.widget.id') }}</th>
              <th>{{ $t('attributes.widget.name') }}</th>
              <th class="text-center">{{ $t('attributes.widget.active') }}</th>
              <th>{{ $t('attributes.widget.alternativeWidgets') }}</th>
            </tr>
          </thead>
          <tbody v-for="(widget, widgetIndex) in widgets" :key="widget.id">
            <!-- Initial widget -->
            <tr v-if="initialWidgets" class="table-secondary">
              <td><i>{{ $t('views.widgetBulkUpdates.form.before') }}</i></td>
              <td>{{ widget.id }}</td>
              <td>{{ initialWidgets[widgetIndex].name }}</td>
              <td class="text-center"><dot :active="initialWidgets[widgetIndex].active" /></td>
              <td>{{ initialWidgets[widgetIndex].alternativeWidgetsWidgets.slice(0).sort((a, b) => a.order - b.order).map(alternativeWidgetsWidget => alternativeWidgetsWidget.alternativeWidget.id).join(', ') }}</td>
            </tr>
            <!-- Widget -->
            <tr>
              <td></td>
              <td>{{ widget.id }}</td>
              <td>{{ widget.name }}</td>
              <td class="text-center"><dot :active="widget.active" /></td>
              <td>{{ widget.alternativeWidgetsWidgets.slice(0).sort((a, b) => a.order - b.order).map(alternativeWidgetsWidget => alternativeWidgetsWidget.alternativeWidget.id).join(', ') }}</td>
            </tr>
          </tbody>
        </table>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="widgetBulkUpdateSubmitLoading">
            <md-spinner v-if="widgetBulkUpdateSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            {{ $t('shared.submit.import') }}
          </button>
        </div>
      </form>
    </b-tab>
  </b-tabs>
</div>
