<template>
  <b-tabs
    v-model="activeTabIndex"
    v-bind="$attrs"
  >
    <slot></slot>
  </b-tabs>
</template>

<script>
import { BTabs } from 'bootstrap-vue'

export default {
  name: 'PersistentTabs',
  components: {
    BTabs
  },
  props: {
    value: Number
  },
  data() {
    return {
      activeTabIndex: 0
    }
  },
  computed: {
    localStorageKey: function() {
      return `activeTabIndex-${this.$route.path}`
    }
  },
  watch: {
    value(newIndex) {
      this.activeTabIndex = newIndex
    },
    activeTabIndex(newIndex) {
      localStorage.setItem(this.localStorageKey, newIndex)
      this.$emit('input', newIndex)
    }
  },
  created() {
    if (this.value !== undefined) {
      this.activeTabIndex = this.value
    } else {
      const savedIndex = localStorage.getItem(this.localStorageKey)
      if (savedIndex !== null) {
        this.activeTabIndex = parseInt(savedIndex)
      }
    }
  }
}
</script>
