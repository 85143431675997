<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="customServiceLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="customServiceError" class="alert alert-danger">
        <template v-if="customServiceError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.customService.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="customService.name" type="text" class="form-control" :class="{ 'is-invalid' : customServiceSubmitErrors && customServiceSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.customService.name')">
              <div v-if="customServiceSubmitErrors && customServiceSubmitErrors.name" class="invalid-feedback">{{ tErrors('customService', 'name', customServiceSubmitErrors.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-group" class="col-sm-2 col-form-label">{{ $t('attributes.customService.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="customService.group"
                :class="{ 'is-invalid' : customServiceSubmitErrors && customServiceSubmitErrors.group }"
                id="multiselect-group" />
              <div v-if="customServiceSubmitErrors && customServiceSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('customService', 'group', customServiceSubmitErrors.group) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="active-checkbox" class="col-sm-2">{{ $t('attributes.customService.active') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="custom-control custom-switch custom-switch-color">
                <input v-model="customService.active" type="checkbox" class="custom-control-input" id="active-checkbox">
                <label class="custom-control-label" for="active-checkbox"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="customServiceSubmitLoading">
              <md-spinner v-if="customServiceSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'

export default {
  components: { MdSpinner, GroupModalSelect },
  props: ['action'],
  data: function() {
    return {
      customService: {
        name: null,
        active: true,
        group: null
      },
      customServiceLoading: false,
      customServiceError: null,
      customServiceSubmitLoading: false,
      customServiceSubmitErrors: null
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.customServiceLoading = true
      this.customServiceError = null

      const query = `query customServicesForm ($customServiceId: Int!) {
        customService(id: $customServiceId) {
          id
          name
          key
          active
          group {
            id
            name
            region {
              id
              code
            }
          }
        }
      }`

      const variables = {
        customServiceId: parseInt(this.$route.params.id)
      }

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ query, variables })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.customServiceLoading = false

          if (data.errors) {

          } else {
            this.customService = data.data.customService
          }
        })
    },
    // Form submit
    submit: function() {
      this.customServiceSubmitLoading = true
      this.customServiceSubmitErrors = null

      const variables = {
        input: {
          attributes: {
            name: this.customService.name,
            active: this.customService.active,
            groupId: this.customService.group.id
          }
        }
      }

      if (this.action === 'new') {
        const mutation = `mutation($input: CreateCustomServiceInput!) {
          createCustomService(input: $input) {
            customService {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.customServiceSubmitLoading = false

            if (data.data.createCustomService.errors) {
              this.customServiceSubmitErrors = data.data.createCustomService.errors
            } else {
              this.$router.push({ name: 'customService', params: { id: data.data.createCustomService.customService.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.customService.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else if (this.action === 'edit') {
        variables.input.customServiceId = this.$route.params.id

        const mutation = `mutation($input: UpdateCustomServiceInput!) {
          updateCustomService(input: $input) {
            customService {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.customServiceSubmitLoading = false

            if (data.data.updateCustomService.errors) {
              this.customServiceSubmitErrors = data.data.updateCustomService.errors
            } else {
              this.$router.push({ name: 'customService', params: { id: data.data.updateCustomService.customService.id } })
              this.$root.$bvToast.toast(this.$t('shared.success.customService.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  created: function() {
    if (this.action === 'edit') {
      this.loadData()
    }
  }
}
</script>
