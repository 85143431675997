<script>
import i18n from '../../i18n'

export default {
  name: 'PivotTableValue',
  functional: true,
  props: {
    value: {},
    labels: {
      type: Array,
      default: () => []
    }
  },
  render: function(createElement, context) {
    const labelsMap = new Map(context.props.labels.map(label => [label.field.key, label.value]))
    const hasCount = !labelsMap.has('action') || !['redirection_product_amount', 'validation_product_amount'].includes(labelsMap.get('action'))
    const hasAmount = !labelsMap.has('action') || ['redirection_product_amount', 'validation_product_amount'].includes(labelsMap.get('action'))
    const result = []

    if (hasCount) {
      result.push(createElement('div', context.props.value.count.toLocaleString()))
    }
    if (hasAmount) {
      if (Object.keys(context.props.value.amount).length === 0) {
        result.push(createElement('div', '-'))
      } else {
        const price = function(value, currency) {
          if (value !== null && value > 0 && currency) {
            return value.toLocaleString(i18n.locale, {
              style: 'currency',
              currency,
              maximumFractionDigits: 2
            })
          } else {
            return '–'
          }
        }
        for (const [currency, amount] of Object.entries(context.props.value.amount)) {
          result.push(createElement('div', price(amount, currency)))
        }
      }
    }

    return result
  }
}
</script>
